import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardBody, CardActions } from "@progress/kendo-react-layout";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { AppNotification, NotificationMessage } from "../../shared/components/Notifications";
import { AppLoader } from "../../shared/components/Indicators";
import { LinkButton } from "../../shared/components/Buttons";
import { FormInput } from "../../shared/components/Inputs";
import { emailValidator, requiredValidator } from "../../shared/validators";
import accountService from "../services/accountService";
import accountContextService from "../services/accountContextService";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>();
  const navigate = useNavigate();

  async function handleLogin(model: any) {
    setIsLoading(true);

    try {
      const loginResult = await accountService.login(model);
      accountContextService.finishLogin(loginResult);
      navigate("/");
    } catch (error: any) {
      setNotificationMessage(NotificationMessage.error(error));
    }

    setIsLoading(false);
  }

  if (isLoading) {
    return (<AppLoader />);
  }

  return (
    <section className="container my-5">
      <Card className="col-md-6 offset-md-3">
        <CardHeader>
          <CardTitle>Login</CardTitle>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={handleLogin}
            render={(formRenderProps) => (
              <FormElement id={"form"}>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                    <Field name={"userName"} type={"Email"} component={FormInput} label={"Email"}
                      validator={[requiredValidator, emailValidator]} />
                  </div>
                </FieldWrapper>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                    <Field name={"password"} type={"Password"} component={FormInput} label={"Password"}
                      validator={(requiredValidator)} />
                  </div>
                </FieldWrapper>
              </FormElement>
            )}
          />
          <div className="mt-3 d-flex justify-content-end">
            <Link to="../forgot-password">Forgot Password?</Link>
          </div>
        </CardBody>
        <CardActions className="justify-content-center">
          <Button themeColor={"primary"} type={"submit"} form={"form"}>Log In</Button>
          <LinkButton to="../register">Sign Up</LinkButton>
        </CardActions>
      </Card>

      <AppNotification notificationMessage={notificationMessage}></AppNotification>
    </section>
  );
}
