import { AnyAction, PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SceneMode } from "cesium";
import { Project } from "../models/ProjectModels"
import viewApiService from "../api/viewApiService";
import { LegendItemSettings, EntityType } from "../models/LegendModels";

type ViewState = {
  project?: Project;
  viewSettings: LegendItemSettings[];
  mapMode: SceneMode;
  isLoading: boolean;
  error: any;
}

export const fetchProject = createAsyncThunk<Project, string, { rejectValue: any }>(
  'view/fetchProject',
  async function (id, { rejectWithValue }) {
    try {
      const { data } = await viewApiService.getProject(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchLatestProject = createAsyncThunk<Project | undefined, undefined, { rejectValue: any }>(
  'view/fetchLatestProject',
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await viewApiService.getLatestProject();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSharedProject = createAsyncThunk<Project, string, { rejectValue: any }>(
  'view/fetchSharedProject',
  async function (code, { rejectWithValue }) {
    try {
      const { data } = await viewApiService.getSharedProject(code);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: ViewState = {
  project: undefined,
  viewSettings: [],
  mapMode: SceneMode.SCENE3D,
  isLoading: false,
  error: null
}

const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    changeMapMode(state, action: PayloadAction<SceneMode>) {
      state.mapMode = action.payload;
    },
    changeViewEntitySetting(state, action: PayloadAction<LegendItemSettings>) {
      const settings = state.viewSettings.find(x => x.type === action.payload.type);
      if (settings) {
        settings.isShown = action.payload.isShown;
        settings.opacity = action.payload.opacity;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProject.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.project = action.payload;
        if (action.payload) {
          state.viewSettings = LegendItemSettings.getLegendItemsSettings(action.payload);
        }
        state.isLoading = false;
      })
      .addCase(fetchLatestProject.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLatestProject.fulfilled, (state, action) => {
        state.project = action.payload;
        if (action.payload) {
          state.viewSettings = LegendItemSettings.getLegendItemsSettings(action.payload);
        }
        state.isLoading = false;
      })
      .addCase(fetchSharedProject.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSharedProject.fulfilled, (state, action) => {
        state.project = action.payload;
        if (action.payload) {
          state.viewSettings = LegendItemSettings.getLegendItemsSettings(action.payload);
        }
        state.isLoading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
      })
  },
});

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}

export const { changeMapMode, changeViewEntitySetting } = viewSlice.actions;
export default viewSlice.reducer;
