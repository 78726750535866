import { randomString } from "../../shared/services/random";
import { Account, LoginResultModel } from "../models/AccountModel";

class AccountContextService {
  get account(): Account | undefined {
    return this.accessToken ? {} : undefined;
  }

  get accessToken(): string | undefined {
    const accessToken = localStorage.getItem('accessToken');
    return accessToken || undefined;
  }

  get guestCode(): string | undefined {
    let guestCode = localStorage.getItem('guestCode');
    if (!guestCode) {
      guestCode = randomString(8);
      localStorage.setItem('guestCode', guestCode);
    }

    return guestCode;
  }

  finishLogin(loginResult: LoginResultModel) {
    localStorage.setItem('accessToken', loginResult.accessToken);
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('guestCode');
  }
}

export default new AccountContextService();
