import { Window } from '@progress/kendo-react-dialogs';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/commonHooks';
import LegendItem from './LegendItem';
import { LegendItemSettings } from '../models/LegendModels';
import { changeViewEntitySetting } from '../reducers/viewSlice';

const Legend = () => {
  const { viewSettings } = useAppSelector(state => state.view);
  const dispatch = useAppDispatch();

  function handleShowClick(setting: LegendItemSettings) {
    dispatch(changeViewEntitySetting({...setting, isShown: !setting.isShown}));
  }

  function handleOpacityChange(setting: LegendItemSettings, value: number) {
    dispatch(changeViewEntitySetting({...setting, opacity: value}));
  }

  const items = viewSettings.map(x => 
    <LegendItem key={x.type} setting={x} handleShowClick={handleShowClick} handleOpacityChange={handleOpacityChange}/>);

  return (
    <div>
        <Window 
          title={'Legend'}
          initialLeft={10}
          initialTop={140}
          draggable={false}
          resizable={false}
          height={700}
          closeButton={() => null}
          maximizeButton={() => null}
          >
            <div className='h-100'>
              {items}
            </div>
        </Window>
    </div>
  )
}

export default Legend;