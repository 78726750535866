import { AnyAction, PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import projectsService from "../api/projectsService";
import { ProjectsListItem } from "../models/ProjectsModel";

type ManageState = {
  projectsList: ProjectsListItem[];
  isLoading: boolean;
  error: any;
}

const initialState: ManageState = {
  projectsList: [],
  isLoading: false,
  error: null
}

export const fetchProjectsList = createAsyncThunk<ProjectsListItem[], undefined, { rejectValue: any }>(
  'manage/fetchProjectsList',
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await projectsService.getProjectsList();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProject = createAsyncThunk<undefined, string, { rejectValue: any }>(
  'manage/deleteProject',
  async function (id, { rejectWithValue }) {
    try {
      await projectsService.deleteProject(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

const manageSlice = createSlice({
  name: 'manage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProjectsList.fulfilled, (state, action) => {
        state.projectsList = action.payload;
        state.isLoading = false;
      })
      .addCase(deleteProject.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteProject.fulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(isError, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
      })
  }
});

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}

export default manageSlice.reducer;
