import { axios } from "../../../shared/api/axios";
import { Project } from "../models/ProjectModels";

interface GetProjectResponse {
  data: Project;
}

interface GetLatestProjectResponse {
  data: Project | undefined;
}

class ViewApiService {
  getProject(id: string): Promise<GetProjectResponse> {
    return axios.get(`/projects/${id}`);
  }

  getLatestProject(): Promise<GetLatestProjectResponse> {
    return axios.get('/projects/latest');
  }

  getSharedProject(code: string): Promise<GetProjectResponse> {
    return axios.get('/projects/shared', { params: { code } });
  }
}

export default new ViewApiService();