import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Card, CardHeader, CardTitle, CardBody, CardActions } from "@progress/kendo-react-layout";
import { Field, FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { AppNotification, NotificationMessage } from "../../shared/components/Notifications";
import { AppLoader } from "../../shared/components/Indicators";
import { FormInput } from "../../shared/components/Inputs";
import { emailValidator, requiredValidator } from "../../shared/validators";
import accountService from "../services/accountService";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>();

  async function handleSubmit(model: any) {
    setIsLoading(true);

    try {
      await accountService.forgotPassword(model);
      setNotificationMessage(NotificationMessage.success("Reset password link has been sent to your email"));
    } catch (error: any) {
      setNotificationMessage(NotificationMessage.error(error));
    }

    setIsLoading(false);
  }

  if (isLoading) {
    return (<AppLoader />);
  }

  return (
    <section className="container my-5">
      <Card className="col-md-6 offset-md-3">
        <CardHeader>
          <CardTitle>Restore Password</CardTitle>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={handleSubmit}
            render={(props) => (
              <FormElement id={"form"}>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                    <Field name={"userName"} type={"Email"} component={FormInput} label={"Email"}
                      validator={[requiredValidator, emailValidator]} />
                  </div>
                </FieldWrapper>
              </FormElement>
            )}
          />

          <div className="mt-3 d-flex justify-content-end">
            <Link to='../login'>Back to Login</Link>
          </div>
        </CardBody>
        <CardActions className="justify-content-center">
          <Button themeColor={"primary"} type={"submit"} form={"form"}>Restore Password</Button>
        </CardActions>
      </Card>

      <AppNotification notificationMessage={notificationMessage}></AppNotification>
    </section>
  );
}
