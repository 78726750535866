import { Button } from "@progress/kendo-react-buttons";
import { AppBarSection } from "@progress/kendo-react-layout";
import { AppBarSpacer } from "@progress/kendo-react-layout";
import { AppBar } from "@progress/kendo-react-layout";
import { NavLink } from "react-router-dom";
import { editIcon, homeIcon, magicWandIcon } from "../../../shared/components/Icons";
import ViewAppBar from "./ViewAppBar";
import ManageAppBar from "./ManageAppBar";

export function ProjectsAppBar() {
  const navItems = [
    { path: "/projects", icon: homeIcon, title: "Projects" },
    { path: "/projects/view", icon: magicWandIcon, title: "View" },
    { path: "/projects/edit", icon: editIcon, title: "Edit", disabled: true }
  ];

  const navLinks = navItems.map(navItem =>
    <NavLink key={navItem.path} to={navItem.path} end
      onClick={(ev) => navItem.disabled && ev.preventDefault()}
      className={({ isActive }) => navItem.disabled ? "disabled" : isActive ? "active" : ""}>
      <Button svgIcon={navItem.icon}>{navItem.title}</Button>
    </NavLink>
  );

  return (
    <AppBar className="secondary-app-bar">
      <AppBarSection className="nav-appbar-section">
        {navLinks}
      </AppBarSection>
      <AppBarSpacer />
      <ManageAppBar />
      <ViewAppBar />
    </AppBar>
  )
}
