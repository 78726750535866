import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Window } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import UploadProject from '../../manage/components/UploadProject/UploadProject';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/commonHooks';
import { fetchLatestProject, fetchProject, fetchSharedProject } from '../reducers/viewSlice';
import { AppLoader } from '../../../shared/components/Indicators';
import ViewerWrapper from '../components/ViewerWrapper';
import { AppNotification, NotificationMessage } from '../../../shared/components/Notifications';
import Legend from '../components/Legend';
import DefaultMap from '../components/DefaultMap';

const ViewProject = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { project, mapMode, isLoading, error } = useAppSelector(state => state.view);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const code = searchParams.get("code");
    if (id) {
      dispatch(fetchProject(id));
    } else if (code) {
      dispatch(fetchSharedProject(code));
    } else {
      dispatch(fetchLatestProject());
    }
  }, []);

  function handleUpload() {
    dispatch(fetchLatestProject());
  }

  if (isLoading) {
    return <AppLoader />
  }

  return (
    <>
      {project && <Legend />}

      {project && <ViewerWrapper />}

      {error && <AppNotification notificationMessage={NotificationMessage.error(error)}></AppNotification>}

      {!project &&
        <>
          <DefaultMap />

          <Window minimizeButton={() => null} maximizeButton={() => null} closeButton={() => null} resizable={false}
            className={"welcome-window"} width={350} height={140}>
            <p className="mb-0 text-center"><strong>Welcome to TNM Viewer,</strong></p>
            <p className="text-center mb-3">upload a TNM XML or TXF file to get started</p>

            <div className='d-flex justify-content-center'>
              <UploadProject
                renderUploadButton={handleClick => (
                  <Button themeColor={"primary"} fillMode={"solid"} onClick={handleClick}>
                    Upload New Project
                  </Button>
                )}
                onSuccessfulUpload={handleUpload} />
            </div>
          </Window>
        </>}
    </>
  );
}

export default ViewProject;