import { ArcType, Cartesian2, Cartesian3, Cartographic, ClassificationType, Color, Globe, HeightReference, PolylineArrowMaterialProperty, PolylineDashMaterialProperty, SceneMode, createGuid } from "cesium";
import { BoxGraphics, Entity, Label, LabelCollection, PointGraphics, PolygonGraphics, PolylineGraphics, WallGraphics } from "resium";
import { Barrier, BuildingRow, PointBase, Project, Receiver } from "../models/ProjectModels";
import projectionService from "../../../shared/services/projectionService";
import { LegendItemSettings } from "../models/LegendModels";

interface MappingConfig {
  mapMode: SceneMode;
  globe?: Globe;
}

interface ZoneProps {
  material: Color;
  pointsMaterial?: Color;
  pointsLabelMaterial?: Color;
  labelMaterial?: Color;
  outline: boolean;
  outlineColor: Color;
  outlineWidth: number;
  height?: number;
  extrudedHeight?: number;
  heightReference: HeightReference;
}

interface PolylineProps {
  id: string;
  name: string;
  points: PointBase[];
  width?: number;
  clampToGround?: boolean;
  labelPixelOffset: Cartesian2;
  polylineMaterial: PolylineDashMaterialProperty | PolylineArrowMaterialProperty;
  labelMaterial: Color;
}

const labelProps = {
  font: '20px sans-serif',
  fillColor: Color.BLACK,
  outline: true,
  outlineWidth: 1.5,
  outlineColor: Color.BLACK,
  scale: 0.8,
  heightReference: HeightReference.CLAMP_TO_GROUND
};

const pointLabelProps = { ...labelProps, pixelOffset: Cartesian2.fromElements(15, 15) };

const pointProps = {
  pixelSize: 8,
  color: Color.RED,
  outlineColor: Color.BLACK,
  outlineWidth: 3,
  heightReference: HeightReference.CLAMP_TO_GROUND
};

const zoneProps: ZoneProps = {
  material: Color.GREEN,
  outline: true,
  outlineColor: Color.GRAY,
  outlineWidth: 2,
  heightReference: HeightReference.CLAMP_TO_GROUND
}

const CAMERA_HEIGHT = 500;

class MapperService {
  getCameraPoint(project: Project): Cartesian3 | undefined {
    if (project.buildingRows.length && project.buildingRows[0].points.length) {
      return this.transformCameraPoint(project, project.buildingRows[0].points[0]);
    }

    if (project.receivers.length) {
      return this.transformCameraPoint(project, project.receivers[0].points[0]);
    }

    if (project.treeZones.length && project.treeZones[0].points.length) {
      return this.transformCameraPoint(project, project.treeZones[0].points[0]);
    }

    if (project.groundZones.length && project.groundZones[0].points.length) {
      return this.transformCameraPoint(project, project.groundZones[0].points[0]);
    }

    if (project.contourZones.length && project.contourZones[0].points.length) {
      return this.transformCameraPoint(project, project.contourZones[0].points[0]);
    }

    if (project.roadways.length && project.roadways[0].points.length) {
      return this.transformCameraPoint(project, project.roadways[0].points[0]);
    }

    if (project.barriers.length && project.barriers[0].points.length) {
      return this.transformCameraPoint(project, project.barriers[0].points[0]);
    }

    return undefined;
  }

  private transformCameraPoint(project: Project, point: PointBase) {
    const projected = projectionService.projectToLatLong({...project}, point.theX, point.theY);
    return Cartesian3.fromDegrees(projected[0], projected[1], CAMERA_HEIGHT);
  }

  mapReceivers(project: Project, config: MappingConfig, settings: LegendItemSettings): JSX.Element {
    const receivers = project.receivers.map(x => this.mapReceiver(x, project, settings));

    return (
      <>
        {receivers}
      </>
    );
  }

  mapReceiver(receiver: Receiver, project: Project, settings: LegendItemSettings) {
    const points = this.transformPoints(project, receiver.points);
    const alpha = settings.opacity / 100;
    const material = Color.fromAlpha(Color.BLUE, alpha);
    const labelMaterial = Color.fromAlpha(Color.BLACK, alpha);

    return (
      <>
        <Entity key={receiver.id} position={points[0]}>
          <BoxGraphics 
            key={createGuid()} 
            heightReference={HeightReference.CLAMP_TO_GROUND} 
            material={material} 
            dimensions={new Cartesian3(2, 2, 2)} 
          />
        </Entity>
        <LabelCollection key={receiver.name + receiver.id}>
          <Label 
            key={createGuid()}
            {...labelProps} 
            position={points[0]} 
            text={receiver.name}
            fillColor={labelMaterial}
            pixelOffset={new Cartesian2(15, 0)} 
          />
        </LabelCollection>
      </>
    );
  }

  mapBuildingRows(project: Project, config: MappingConfig, settings: LegendItemSettings) {
    return (
      <>
        {this.mapBuildingRows2D(project, settings)}
        {this.mapBuildingRows3D(project, config, settings)}
      </>
    )
  }

  mapBuildingRows2D(project: Project, settings: LegendItemSettings): JSX.Element {
    const alpha = settings.opacity / 100;
    const buildingRowsElements = project.buildingRows.map(x => this.mapPolyline(
      {
        ...x,
        clampToGround: true,
        labelPixelOffset: new Cartesian2(25, -50),
        labelMaterial: Color.fromAlpha(Color.BLACK, alpha),
        polylineMaterial: new PolylineDashMaterialProperty({ color: Color.fromAlpha(Color.RED, alpha) })
      }, project, Color.fromAlpha(Color.BLACK, alpha))
    );

    return (
      <>
        {buildingRowsElements}
      </>
    );
  }

  mapBuildingRows3D(project: Project, config: MappingConfig, settings: LegendItemSettings) {
    const buildingRows3D = project.buildingRows.map(x => this.mapBuildingRow3D(project, x, config, settings));

    return (
      <>
        {buildingRows3D}
      </>
    )
  }

  mapBuildingRow3D(project: Project, buildingRow: BuildingRow, config: MappingConfig, settings: LegendItemSettings) {
    let maxHeight = buildingRow.averageHeight;
    const points = this.transformPointsLatLong(project, buildingRow.points);
    const minHeights: number[] = [];
    const pointsFlatArray: number[] = [];
    points.forEach(x => {
      let minHeight = 0;
      if (config.globe) {
        const height = config.globe.getHeight(Cartographic.fromDegrees(x[0], x[1]));
        if (height && height > 0) {
          minHeight += height;
        }
      }

      pointsFlatArray.push(x[0], x[1], minHeight + maxHeight);


      minHeights.push(minHeight);
    });

    const positionsArray = Cartesian3.fromDegreesArrayHeights(pointsFlatArray);
    const material = Color.fromAlpha(Color.DARKGRAY, settings.opacity / 100);

    return (
      <>
        <Entity key={buildingRow.id}>
          <WallGraphics key={createGuid()}
            positions={positionsArray}
            material={material}
            minimumHeights={minHeights}
          />
        </Entity>
      </>
    )
  }

  mapRoadways(project: Project, settings: LegendItemSettings) {
    const alpha = settings.opacity / 100;
    const roadways = project.roadways.map(x => this.mapPolyline(
      {
        ...x,
        clampToGround: true,
        width: 10,
        labelPixelOffset: new Cartesian2(25, -30),
        labelMaterial: Color.fromAlpha(Color.BLACK, alpha),
        polylineMaterial:  new PolylineArrowMaterialProperty(Color.fromAlpha(Color.BLACK, alpha))
      }, project, Color.fromAlpha(Color.BLACK, alpha))
    );

    return (
      <>
        {roadways}
      </>
    );
  }

  mapTerrainLine(project: Project, settings: LegendItemSettings) {
    const alpha = settings.opacity / 100;
    const terrainLines = project.terrainLines.map(x => this.mapPolyline(
      {
        ...x,
        clampToGround: true,
        width: 5,
        labelPixelOffset: new Cartesian2(25, -30),
        labelMaterial: Color.fromAlpha(Color.BLACK, alpha),
        polylineMaterial: new PolylineDashMaterialProperty({color: Color.fromAlpha(Color.GREEN, alpha)})
      }, project, Color.fromAlpha(Color.BLACK, alpha)
    ));

    return <>{terrainLines}</>;
  }

  mapBarriers(project: Project, config: MappingConfig, settings: LegendItemSettings) {
    return (
      <>
        {this.mapBarriers2D(project, settings)}
        {this.mapBarriers3D(project, config, settings)}
      </>
    );
  }

  mapBarriers2D(project: Project, settings: LegendItemSettings) {
    const alpha = settings.opacity / 100;
    const barriers = project.barriers.map(x => this.mapPolyline(
      {
        ...x,
        clampToGround: true,
        width: 10,
        labelPixelOffset: new Cartesian2(25, -30),
        labelMaterial: Color.fromAlpha(Color.BLACK, alpha),
        polylineMaterial: new PolylineArrowMaterialProperty(Color.fromAlpha(Color.RED, alpha))
      }, 
      project,
      Color.fromAlpha(Color.BLACK, alpha))
    );

    return (
      <>
        {barriers}
      </>
    );
  }

  mapBarriers3D(project: Project, config: MappingConfig, settings: LegendItemSettings) {
    const barriers = project.barriers.map(x => this.mapBarrier3D(project, x, config, settings));

    return (
      <>
        {barriers}
      </>
    )
  }
  
  mapBarrier3D(project: Project, barrier: Barrier, config: MappingConfig, settings: LegendItemSettings) {
    const height = barrier.baseHeight;
    const points = this.transformPointsLatLong(project, barrier.points);
    const minHeights: number[] = [];
    const pointsFlatArray: number[] = [];
    points.forEach(x => {
      let minHeight = 0;
      if (config.globe) {
        const height = config.globe.getHeight(Cartographic.fromDegrees(x[0], x[1]));
        if (height && height > 0) {
          minHeight += height;
        }
      }

      pointsFlatArray.push(x[0], x[1], minHeight + height);
      minHeights.push(minHeight);
    });

    const positionsArray = Cartesian3.fromDegreesArrayHeights(pointsFlatArray);
    const material = Color.fromAlpha(Color.GREEN, settings.opacity / 100);

    return (
      <>
        <Entity key={barrier.id}>
          <WallGraphics key={createGuid()}
            positions={positionsArray}
            material={material}
            minimumHeights={minHeights}
          />
        </Entity>
      </>
    )
  }

  mapTreeZones(project: Project, config: MappingConfig, settings: LegendItemSettings): JSX.Element {
    const props: ZoneProps = {...zoneProps, material: Color.fromAlpha(Color.GREEN, settings.opacity / 100)};
    const treeZones = project.treeZones.map(treeZone => {
      if (config.mapMode === SceneMode.SCENE3D) {
        props.extrudedHeight = treeZone.averageHeight;
      }
      return this.mapZone(treeZone.id, treeZone.name, treeZone.points, props, project,
        Color.fromAlpha(Color.BLACK, settings.opacity / 100));
    });

    return (
      <>
        {treeZones}
      </>
    );
  }

  mapGroundZones(project: Project, setting: LegendItemSettings): JSX.Element {
    const props: ZoneProps = {...zoneProps, material: Color.fromAlpha(Color.GRAY, setting.opacity / 100)};
    const groundZones = project.groundZones.map(
      groundZone => this.mapZone(groundZone.id, groundZone.name, groundZone.points, props, project,
        Color.fromAlpha(Color.BLACK, setting.opacity / 100)));
    
    return (
      <>
        {groundZones}
      </>
    )
  }

  mapContourZones(project: Project, settings: LegendItemSettings): JSX.Element {
    const props: ZoneProps = {...zoneProps, material: Color.fromAlpha(Color.LIGHTBLUE, settings.opacity / 100)};
    const contourZones = project.contourZones.map(
      contourZone => this.mapZone(contourZone.id, contourZone.name, contourZone.points, props, project,
        Color.fromAlpha(Color.BLACK, settings.opacity / 100)));
    
    return (
      <>
        {contourZones}
      </>
    )
  }

  private mapPolyline(props: PolylineProps, project: Project, material: Color): JSX.Element {
    const projectedPoints = this.transformPoints(project, props.points);

    const entityLabel = <Label 
      key={props.id} 
      {...labelProps} 
      position={projectedPoints[0]} 
      text={props.name} 
      pixelOffset={props.labelPixelOffset} />
    const pointsElements = this.mapPoints(props.points, projectedPoints, material);

    return (
      <>
        {pointsElements}
        <Entity key={props.id}>
          <PolylineGraphics 
            key={createGuid()} 
            clampToGround={props.clampToGround} 
            positions={projectedPoints} 
            width={props.width} 
            material={props.polylineMaterial} 
          />
          <LabelCollection key={createGuid()}>
            {entityLabel}
          </LabelCollection>
        </Entity>
      </>
    );
  }

  private mapZone(id: string, name: string, entityPoints: PointBase[], props: ZoneProps, project: Project,
    pointsMaterial: Color) {
    const points = this.transformPoints(project, entityPoints);
    const pointsElements = this.mapPoints(entityPoints, points, pointsMaterial);

    return (
      <>
        {pointsElements}
        <Entity key={id}>
          <PolygonGraphics key={createGuid()} {...props} outline={false} hierarchy={points} />
        </Entity>
        <LabelCollection key={createGuid()}>
          <Label 
            key={createGuid()} 
            {...labelProps} 
            position={points[0]}
            fillColor={Color.fromAlpha(Color.BLACK, pointsMaterial.alpha)}
            text={name} 
            pixelOffset={new Cartesian2(30, -30)} 
          />
        </LabelCollection>
      </>
    );
  }

  private mapPoints(entityPoints: PointBase[], transformedPoints: Cartesian3[], material: Color): JSX.Element {
    const pointsLabels = transformedPoints.map((point, index) =>
      <Label 
        key={entityPoints[index].id} 
        {...pointLabelProps} 
        position={point}
        fillColor={material}
        text={entityPoints[index].name} />);

    const pointsElements = transformedPoints.map((point, index) =>
      <Entity key={entityPoints[index].id} position={point}>
        <PointGraphics 
          key={createGuid()} 
          {...pointProps} 
          color={Color.fromAlpha(Color.RED, material.alpha)}
          outlineColor={Color.fromAlpha(Color.BLACK, material.alpha)}/>
      </Entity>);

    return (
      <>
        {pointsElements}
        <LabelCollection key={createGuid()}>
          {pointsLabels}
        </LabelCollection>
      </>
    );
  }

  private transformPoints(project: Project, points: PointBase[]): Cartesian3[] {
    const transformedPoints = points.map(
      point => [...projectionService.projectToLatLong({...project}, point.theX, point.theY), point.theZ]);

    const cesiumPoints = transformedPoints.map(
      points => Cartesian3.fromDegrees(points[0], points[1], points[2]));

    return cesiumPoints;
  }

  private transformPointsLatLong(project: Project, points: PointBase[]): number[][] {
    const transformedPoints = points.map(
      point => [...projectionService.projectToLatLong({...project}, point.theX, point.theY), point.theZ]);
    return transformedPoints;
  }
}

export default new MapperService();