import { 
  Cesium3DTileset as TileSet,
  SceneMode, 
  createWorldTerrainAsync, 
  CesiumTerrainProvider,
  Viewer as CesiumViewer,
  IonImageryProvider,
  ImageryLayer as CesiumImageryLayer
} from 'cesium';
import { CameraFlyTo, CesiumComponentRef, Globe, ImageryLayer, Scene, Viewer } from 'resium';
import mapperService from '../services/mapperService';
import { useAppSelector } from '../../../shared/hooks/commonHooks';
import { useEffect, useRef, useState } from 'react';
import { Cesium3DTileset } from 'resium';
import { EntityType } from '../models/LegendModels';

const ViewerWrapper = () => {
  const { project, mapMode, viewSettings } = useAppSelector(state => state.view);
  const [ imageryProvider, setImageryProvider ] = useState<IonImageryProvider>();
  const [ terrainProvider, setTerrainProvider ] = useState<CesiumTerrainProvider>();
  const [ tileset, setTileset ] = useState<TileSet>();
  const [ content, setContent ] = useState<JSX.Element>();
  const [ isInitialRender, setIsInitialRender ] = useState(true);

  const viewerRef = useRef<CesiumComponentRef<CesiumViewer>>(null);
  const layerRef = useRef<CesiumComponentRef<CesiumImageryLayer>>(null);

  useEffect(() => {
    async function loadImageryProvider() {
      try {
        const provider = await IonImageryProvider.fromAssetId(4, {});
        setImageryProvider(provider);
      } catch (error) {
        console.log(`Error loading imagery provider: ${error}`);
      }
    }

    async function loadTerrainProvider() {
      try {
        const provider = await createWorldTerrainAsync();
        setTerrainProvider(provider);
      } catch (error) {
        console.log(`Error creating world terrain: ${error}`);
      }
    }

    async function loadTileset() {
      try {
        const loadedTileset = await TileSet.fromIonAssetId(96188, {});
        setTileset(loadedTileset);
      } catch (error) {
        console.log(`Error loading tileset: ${error}`);
      }
    }
    
    Promise.all([
      loadImageryProvider(),
      loadTerrainProvider(),
      loadTileset()
    ]).then(() => {
      const interval = setInterval(() => {
        if (viewerRef.current?.cesiumElement?.scene.globe.tilesLoaded) {
          if (project) {
            initialRender();
            viewerRef.current?.cesiumElement?.scene.requestRender();

            clearInterval(interval);
          }
        }
      }, 10);
    })
  }, []);

  function initialRender() {
    setContent(
      <>
        {renderReceivers()}
        {renderBuildingRows()}
        {renderRoadways()}
        {renderBarriers()}
        {renderTreeZones()}
        {renderGroundZones()}
        {renderContourZones()}
        {requestRender()}
      </>
    );

    setIsInitialRender(false);
  }

  function requestRender(): undefined {
    const baseMapSettings = viewSettings.find(x => x.type === EntityType.baseMap);
    if (baseMapSettings && layerRef.current?.cesiumElement) {
      layerRef.current.cesiumElement.alpha = baseMapSettings?.opacity / 100;
      layerRef.current.cesiumElement.show = baseMapSettings.isShown;
    }
    viewerRef.current?.cesiumElement?.scene.requestRender();
    return undefined;
  }

  function renderReceivers() {
    const receiverSettings = viewSettings.find(x => x.type === EntityType.receivers);
    if (receiverSettings?.isShown === true && project) {
      const element = mapperService.mapReceivers(project, 
        { mapMode, globe: viewerRef.current?.cesiumElement?.scene.globe }, receiverSettings)
      return element;
    }

    return undefined;
  }

  function renderBuildingRows() {
    const buildingRowsSettings = viewSettings.find(x => x.type === EntityType.buildingRows);
    if (buildingRowsSettings?.isShown === true && project) {
      return mapperService.mapBuildingRows(project,
        { mapMode, globe: viewerRef.current?.cesiumElement?.scene.globe }, buildingRowsSettings);
    }

    return undefined;
  }

  function renderRoadways() {
    const settings = viewSettings.find(x => x.type === EntityType.roadways);
    if (settings?.isShown === true && project) {
      return mapperService.mapRoadways(project, settings);
    }

    return undefined;
  }

  function renderBarriers() {
    const settings = viewSettings.find(x => x.type === EntityType.barriers);
    if (settings?.isShown === true && project) {
      return mapperService.mapBarriers(project,
        { mapMode, globe: viewerRef.current?.cesiumElement?.scene.globe }, settings);
    }

    return undefined;
  }

  function renderTreeZones() {
    const settings = viewSettings.find(x => x.type === EntityType.treeZones);
    if (settings?.isShown === true && project) {
      return mapperService.mapTreeZones(project,
        { mapMode, globe: viewerRef.current?.cesiumElement?.scene.globe }, settings);
    }

    return undefined;
  }

  function renderGroundZones() {
    const settings = viewSettings.find(x => x.type === EntityType.groundZones);
    if (settings?.isShown === true && project) {
      return mapperService.mapGroundZones(project, settings);
    }

    return undefined;
  }

  function renderContourZones() {
    const settings = viewSettings.find(x => x.type === EntityType.contourZones);
    if (settings?.isShown === true && project) {
      return mapperService.mapContourZones(project, settings);
    }

    return undefined;
  }

  function renderTerrainLines() {
    const settings = viewSettings.find(x => x.type === EntityType.terrainLines);
    if (settings?.isShown === true && project) {
      return mapperService.mapTerrainLine(project, settings);
    }

    return undefined;
  }

  if (!project) {
    return (<Viewer></Viewer>)
  }

  const cameraPoint = mapperService.getCameraPoint(project);

  return (
    <>
      {(terrainProvider && imageryProvider && tileset) &&
        <Viewer 
          terrainProvider={terrainProvider}
          baseLayer={false}
          ref={viewerRef} 
          requestRenderMode={true} 
          animation={false} 
          timeline={false}
          vrButton={false}
          geocoder={false}
          homeButton={false}
          fullscreenButton={false}
          baseLayerPicker={false}
          infoBox={false}
          sceneModePicker={false}
          navigationHelpButton={false}
          >
          <ImageryLayer ref={layerRef} imageryProvider={imageryProvider} />

          {mapMode == SceneMode.SCENE3D && <Cesium3DTileset url={tileset.resource} />}

          <Globe depthTestAgainstTerrain={true} />
          <Scene mode={mapMode} />

          {isInitialRender === true && 
              <>
                {cameraPoint && <CameraFlyTo destination={cameraPoint} />}
                {content && content}
              </>
            }
            {isInitialRender === false && 
              <>
                {renderReceivers()}
                {renderBuildingRows()}
                {renderRoadways()}
                {renderBarriers()}
                {renderTreeZones()}
                {renderGroundZones()}
                {renderContourZones()}
                {renderTerrainLines()}
                {requestRender()}
              </>
            }
            {requestRender()}
        </Viewer>}
    </>
  )
}

export default ViewerWrapper;