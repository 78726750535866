export const projectionsDefinitions = [
  [
    "Antigua1943BritishWestIndiesGrid",
    `PROJCS["Antigua_1943_British_West_Indies_Grid",GEOGCS["GCS_Antigua_1943",DATUM["D_Antigua_1943",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-62.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Dominica1945BritishWestIndiesGrid",
    `PROJCS["Dominica_1945_British_West_Indies_Grid",GEOGCS["GCS_Dominica_1945",DATUM["D_Dominica_1945",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-62.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Grenada1953BritishWestIndiesGrid",
    `PROJCS["Grenada_1953_British_West_Indies_Grid",GEOGCS["GCS_Grenada_1953",DATUM["D_Grenada_1953",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-62.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Montserrat1958BritishWestIndiesGrid",
    `PROJCS["Montserrat_1958_British_West_Indies_Grid",GEOGCS["GCS_Montserrat_1958",DATUM["D_Montserrat_1958",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-62.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "StKitts1955BritishWestIndiesGrid",
    `PROJCS["St_Kitts_1955_British_West_Indies_Grid",GEOGCS["GCS_St_Kitts_1955",DATUM["D_St_Kitts_1955",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-62.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "StLucia1955BritishWestIndiesGrid",
    `PROJCS["St_Lucia_1955_British_West_Indies_Grid",GEOGCS["GCS_St_Lucia_1955",DATUM["D_St_Lucia_1955",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-62.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "StVincent1945BritishWestIndiesGrid",
    `PROJCS["St_Vincent_1945_British_West_Indies_Grid",GEOGCS["GCS_St_Vincent_1945",DATUM["D_St_Vincent_1945",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-62.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77MTM2SCoPQ",
    `PROJCS["NAD_1927_CGQ77_MTM_2_SCoPQ",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-55.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77MTM3SCoPQ",
    `PROJCS["NAD_1927_CGQ77_MTM_3_SCoPQ",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-58.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77MTM4SCoPQ",
    `PROJCS["NAD_1927_CGQ77_MTM_4_SCoPQ",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-61.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77MTM5SCoPQ",
    `PROJCS["NAD_1927_CGQ77_MTM_5_SCoPQ",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-64.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77MTM6SCoPQ",
    `PROJCS["NAD_1927_CGQ77_MTM_6_SCoPQ",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77MTM7SCoPQ",
    `PROJCS["NAD_1927_CGQ77_MTM_7_SCoPQ",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77MTM8SCoPQ",
    `PROJCS["NAD_1927_CGQ77_MTM_8_SCoPQ",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-73.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77MTM9SCoPQ",
    `PROJCS["NAD_1927_CGQ77_MTM_9_SCoPQ",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77MTM10SCoPQ",
    `PROJCS["NAD_1927_CGQ77_MTM_10_SCoPQ",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976MTM8",
    `PROJCS["NAD_1927_DEF_1976_MTM_8",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-73.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976MTM9",
    `PROJCS["NAD_1927_DEF_1976_MTM_9",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976MTM10",
    `PROJCS["NAD_1927_DEF_1976_MTM_10",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976MTM11",
    `PROJCS["NAD_1927_DEF_1976_MTM_11",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976MTM12",
    `PROJCS["NAD_1927_DEF_1976_MTM_12",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976MTM13",
    `PROJCS["NAD_1927_DEF_1976_MTM_13",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976MTM14",
    `PROJCS["NAD_1927_DEF_1976_MTM_14",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976MTM15",
    `PROJCS["NAD_1927_DEF_1976_MTM_15",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976MTM16",
    `PROJCS["NAD_1927_DEF_1976_MTM_16",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976MTM17",
    `PROJCS["NAD_1927_DEF_1976_MTM_17",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976UTMZone15N",
    `PROJCS["NAD_1927_DEF_1976_UTM_Zone_15N",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976UTMZone16N",
    `PROJCS["NAD_1927_DEF_1976_UTM_Zone_16N",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976UTMZone17N",
    `PROJCS["NAD_1927_DEF_1976_UTM_Zone_17N",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927DEF1976UTMZone18N",
    `PROJCS["NAD_1927_DEF_1976_UTM_Zone_18N",GEOGCS["GCS_NAD_1927_Definition_1976",DATUM["D_NAD_1927_Definition_1976",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77UTMZone17N",
    `PROJCS["NAD_1927_CGQ77_UTM_Zone_17N",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77UTMZone18N",
    `PROJCS["NAD_1927_CGQ77_UTM_Zone_18N",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77UTMZone19N",
    `PROJCS["NAD_1927_CGQ77_UTM_Zone_19N",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77UTMZone20N",
    `PROJCS["NAD_1927_CGQ77_UTM_Zone_20N",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77UTMZone21N",
    `PROJCS["NAD_1927_CGQ77_UTM_Zone_21N",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSNewBrunswickStereographic",
    `PROJCS["NAD_1983_CSRS_New_Brunswick_Stereographic",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",7500000.0],PARAMETER["Central_Meridian",-66.5],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone19N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_19N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone20N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_20N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IsraelTMGrid",
    `PROJCS["Israel_TM_Grid",GEOGCS["GCS_Israel",DATUM["D_Israel",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",219529.584],PARAMETER["False_Northing",626907.39],PARAMETER["Central_Meridian",35.20451694444445],PARAMETER["Scale_Factor",1.0000067],PARAMETER["Latitude_Of_Origin",31.73439361111111],UNIT["Meter",1.0]]`
  ],
  [
    "Locodjo1965UTMZone30N",
    `PROJCS["Locodjo_1965_UTM_Zone_30N",GEOGCS["GCS_Locodjo_1965",DATUM["D_Locodjo_1965",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Abidjan1987UTMZone30N",
    `PROJCS["Abidjan_1987_UTM_Zone_30N",GEOGCS["GCS_Abidjan_1987",DATUM["D_Abidjan_1987",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Locodjo1965UTMZone29N",
    `PROJCS["Locodjo_1965_UTM_Zone_29N",GEOGCS["GCS_Locodjo_1965",DATUM["D_Locodjo_1965",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Abidjan1987UTMZone29N",
    `PROJCS["Abidjan_1987_UTM_Zone_29N",GEOGCS["GCS_Abidjan_1987",DATUM["D_Abidjan_1987",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hanoi1972GKZone18",
    `PROJCS["Hanoi_1972_GK_Zone_18",GEOGCS["GCS_Hanoi_1972",DATUM["D_Hanoi_1972",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hanoi1972GKZone19",
    `PROJCS["Hanoi_1972_GK_Zone_19",GEOGCS["GCS_Hanoi_1972",DATUM["D_Hanoi_1972",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CH1903+LV95",
    `PROJCS["CH1903+_LV95",GEOGCS["GCS_CH1903+",DATUM["D_CH1903+",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Center"],PARAMETER["False_Easting",2600000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",90.0],PARAMETER["Longitude_Of_Center",7.439583333333333],PARAMETER["Latitude_Of_Center",46.95240555555556],UNIT["Meter",1.0]]`
  ],
  [
    "RassadiranNakhleTaqi",
    `PROJCS["Rassadiran_Nakhl_e_Taqi",GEOGCS["GCS_Rassadiran",DATUM["D_Rassadiran",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Center"],PARAMETER["False_Easting",658377.437],PARAMETER["False_Northing",3044969.194],PARAMETER["Scale_Factor",0.999895934],PARAMETER["Azimuth",0.5716611944444444],PARAMETER["Longitude_Of_Center",52.60353916666667],PARAMETER["Latitude_Of_Center",27.51882880555555],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950ED77UTMZone38N",
    `PROJCS["ED_1950_ED77_UTM_Zone_38N",GEOGCS["GCS_European_1950_ED77",DATUM["D_European_1950_ED77",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950ED77UTMZone39N",
    `PROJCS["ED_1950_ED77_UTM_Zone_39N",GEOGCS["GCS_European_1950_ED77",DATUM["D_European_1950_ED77",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950ED77UTMZone40N",
    `PROJCS["ED_1950_ED77_UTM_Zone_40N",GEOGCS["GCS_European_1950_ED77",DATUM["D_European_1950_ED77",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950ED77UTMZone41N",
    `PROJCS["ED_1950_ED77_UTM_Zone_41N",GEOGCS["GCS_European_1950_ED77",DATUM["D_European_1950_ED77",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Madrid1870MadridSpain",
    `PROJCS["Madrid_1870_Madrid_Spain",GEOGCS["GCS_Madrid_1870_Madrid",DATUM["D_Madrid_1870",SPHEROID["Struve_1860",6378298.3,294.73]],PRIMEM["Madrid",-3.687375],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Scale_Factor",0.9988085293],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "Dabola1981UTMZone28N",
    `PROJCS["Dabola_1981_UTM_Zone_28N",GEOGCS["GCS_Dabola_1981",DATUM["D_Dabola_1981",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Dabola1981UTMZone29N",
    `PROJCS["Dabola_1981_UTM_Zone_29N",GEOGCS["GCS_Dabola_1981",DATUM["D_Dabola_1981",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "S-JTSKFerroKrovak",
    `PROJCS["S-JTSK_Ferro_Krovak",GEOGCS["GCS_S_JTSK_Ferro",DATUM["D_S_JTSK",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Krovak"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Pseudo_Standard_Parallel_1",78.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",30.28813975277778],PARAMETER["Longitude_Of_Center",42.5],PARAMETER["Latitude_Of_Center",49.5],PARAMETER["X_Scale",1.0],PARAMETER["Y_Scale",1.0],PARAMETER["XY_Plane_Rotation",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MountDillonTobagoGrid",
    `PROJCS["Mount_Dillon_Tobago_Grid",GEOGCS["GCS_Mount_Dillon",DATUM["D_Mount_Dillon",SPHEROID["Clarke_1858",6378293.645208759,294.260676369]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",187500.0],PARAMETER["False_Northing",180000.0],PARAMETER["Central_Meridian",-60.68600888888889],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",11.25217861111111],UNIT["Link_Clarke",0.201166195164]]`
  ],
  [
    "Naparima1955UTMZone20N",
    `PROJCS["Naparima_1955_UTM_Zone_20N",GEOGCS["GCS_Naparima_1955",DATUM["D_Naparima_1955",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979Libya5",
    `PROJCS["ELD_1979_Libya_5",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979Libya6",
    `PROJCS["ELD_1979_Libya_6",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",11.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979Libya7",
    `PROJCS["ELD_1979_Libya_7",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",13.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979Libya8",
    `PROJCS["ELD_1979_Libya_8",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979Libya9",
    `PROJCS["ELD_1979_Libya_9",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",17.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979Libya10",
    `PROJCS["ELD_1979_Libya_10",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",19.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979Libya11",
    `PROJCS["ELD_1979_Libya_11",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979Libya12",
    `PROJCS["ELD_1979_Libya_12",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",23.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979Libya13",
    `PROJCS["ELD_1979_Libya_13",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979UTMZone32N",
    `PROJCS["ELD_1979_UTM_Zone_32N",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979UTMZone33N",
    `PROJCS["ELD_1979_UTM_Zone_33N",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979UTMZone34N",
    `PROJCS["ELD_1979_UTM_Zone_34N",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979UTMZone35N",
    `PROJCS["ELD_1979_UTM_Zone_35N",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ChosMalal1914Argentina2",
    `PROJCS["Chos_Malal_1914_Argentina_2",GEOGCS["GCS_Chos_Malal_1914",DATUM["D_Chos_Malal_1914",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "PampadelCastilloArgentina2",
    `PROJCS["Pampa_del_Castillo_Argentina_2",GEOGCS["GCS_Pampa_del_Castillo",DATUM["D_Pampa_del_Castillo",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "HitoXVIII1963Argentina2",
    `PROJCS["Hito_XVIII_1963_Argentina_2",GEOGCS["GCS_Hito_XVIII_1963",DATUM["D_Hito_XVIII_1963",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "HitoXVIII1963UTM19S",
    `PROJCS["Hito_XVIII_1963_UTM_19S",GEOGCS["GCS_Hito_XVIII_1963",DATUM["D_Hito_XVIII_1963",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CubaNorte",
    `PROJCS["NAD_1927_Cuba_Norte",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",280296.016],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",22.35],PARAMETER["Scale_Factor",0.99993602],PARAMETER["Latitude_Of_Origin",22.35],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CubaSur",
    `PROJCS["NAD_1927_Cuba_Sur",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",229126.939],PARAMETER["Central_Meridian",-76.83333333333333],PARAMETER["Standard_Parallel_1",20.71666666666667],PARAMETER["Scale_Factor",0.99994848],PARAMETER["Latitude_Of_Origin",20.71666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "ELD1979TM12NE",
    `PROJCS["ELD_1979_TM_12_NE",GEOGCS["GCS_European_Libyan_Datum_1979",DATUM["D_European_Libyan_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CarthageTM11NE",
    `PROJCS["Carthage_TM_11_NE",GEOGCS["GCS_Carthage",DATUM["D_Carthage",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",11.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "YemenNGN1996UTMZone38N",
    `PROJCS["Yemen_NGN_1996_UTM_Zone_38N",GEOGCS["GCS_Yemen_NGN_1996",DATUM["D_Yemen_NGN_1996",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "YemenNGN1996UTMZone39N",
    `PROJCS["Yemen_NGN_1996_UTM_Zone_39N",GEOGCS["GCS_Yemen_NGN_1996",DATUM["D_Yemen_NGN_1996",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SouthYemenGKZone8",
    `PROJCS["South_Yemen_GK_Zone_8",GEOGCS["GCS_South_Yemen",DATUM["D_South_Yemen",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SouthYemenGKZone9",
    `PROJCS["South_Yemen_GK_Zone_9",GEOGCS["GCS_South_Yemen",DATUM["D_South_Yemen",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hanoi1972GK106NE",
    `PROJCS["Hanoi_1972_GK_106_NE",GEOGCS["GCS_Hanoi_1972",DATUM["D_Hanoi_1972",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",106.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BETM106NE",
    `PROJCS["WGS_1972_BE_TM_106_NE",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",106.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BissauUTMZone28N",
    `PROJCS["Bissau_UTM_Zone_28N",GEOGCS["GCS_Bissau",DATUM["D_Bissau",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985KoreaEastBelt",
    `PROJCS["Korean_1985_Korea_East_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985KoreaCentralBelt",
    `PROJCS["Korean_1985_Korea_Central_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",127.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985KoreaWestBelt",
    `PROJCS["Korean_1985_Korea_West_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",125.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Qatar1948QatarGrid",
    `PROJCS["Qatar_1948_Qatar_Grid",GEOGCS["GCS_Qatar_1948",DATUM["D_Qatar_1948",SPHEROID["Helmert_1906",6378200.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",50.76138888888889],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",25.38236111111111],UNIT["Meter",1.0]]`
  ],
  [
    "GreekGrid",
    `PROJCS["Greek_Grid",GEOGCS["GCS_GGRS_1987",DATUM["D_GGRS_1987",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LakeMaracaiboGridM1",
    `PROJCS["Lake_Maracaibo_Grid_M1",GEOGCS["GCS_Lake",DATUM["D_Lake",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-52684.972],PARAMETER["Central_Meridian",-71.60561777777777],PARAMETER["Standard_Parallel_1",10.16666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",10.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "LakeMaracaiboGrid",
    `PROJCS["Lake_Maracaibo_Grid",GEOGCS["GCS_Lake",DATUM["D_Lake",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",147315.028],PARAMETER["Central_Meridian",-71.60561777777777],PARAMETER["Standard_Parallel_1",10.16666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",10.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "LakeMaracaiboGridM3",
    `PROJCS["Lake_Maracaibo_Grid_M3",GEOGCS["GCS_Lake",DATUM["D_Lake",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",447315.028],PARAMETER["Central_Meridian",-71.60561777777777],PARAMETER["Standard_Parallel_1",10.16666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",10.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "LakeMaracaiboLaRosaGrid",
    `PROJCS["Lake_Maracaibo_La_Rosa_Grid",GEOGCS["GCS_Lake",DATUM["D_Lake",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",-17044.0],PARAMETER["False_Northing",-23139.97],PARAMETER["Central_Meridian",-71.60561777777777],PARAMETER["Standard_Parallel_1",10.16666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",10.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000MountEdenCircuit",
    `PROJCS["NZGD_2000_Mount_Eden_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",174.7641666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",-36.87972222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000BayofPlentyCircuit",
    `PROJCS["NZGD_2000_Bay_of_Plenty_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",176.4661111111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-37.76111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000PovertyBayCircuit",
    `PROJCS["NZGD_2000_Poverty_Bay_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",177.8855555555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-38.62444444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000HawkesBayCircuit",
    `PROJCS["NZGD_2000_Hawkes_Bay_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",176.6736111111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-39.65083333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000TaranakiCircuit",
    `PROJCS["NZGD_2000_Taranaki_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",174.2277777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-39.13555555555556],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000TuhirangiCircuit",
    `PROJCS["NZGD_2000_Tuhirangi_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",175.64],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-39.51222222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000WanganuiCircuit",
    `PROJCS["NZGD_2000_Wanganui_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",175.4880555555555],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-40.24194444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000WairarapaCircuit",
    `PROJCS["NZGD_2000_Wairarapa_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",175.6472222222222],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-40.92527777777777],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000WellingtonCircuit",
    `PROJCS["NZGD_2000_Wellington_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",174.7763888888889],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-41.3011111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000CollingwoodCircuit",
    `PROJCS["NZGD_2000_Collingwood_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",172.6719444444444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-40.71472222222223],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000NelsonCircuit",
    `PROJCS["NZGD_2000_Nelson_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",173.2991666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-41.27444444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000KarameaCircuit",
    `PROJCS["NZGD_2000_Karamea_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",172.1088888888889],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-41.28972222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000BullerCircuit",
    `PROJCS["NZGD_2000_Buller_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",171.5811111111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-41.81055555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000GreyCircuit",
    `PROJCS["NZGD_2000_Grey_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",171.5497222222222],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-42.33361111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000AmuriCircuit",
    `PROJCS["NZGD_2000_Amuri_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",173.01],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-42.68888888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000MarlboroughCircuit",
    `PROJCS["NZGD_2000_Marlborough_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",173.8019444444444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-41.54444444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000HokitikaCircuit",
    `PROJCS["NZGD_2000_Hokitika_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",170.9797222222222],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-42.88611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000OkaritoCircuit",
    `PROJCS["NZGD_2000_Okarito_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",170.2608333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-43.11],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000JacksonsBayCircuit",
    `PROJCS["NZGD_2000_Jacksons_Bay_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",168.6061111111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-43.97777777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000MountPleasantCircuit",
    `PROJCS["NZGD_2000_Mount_Pleasant_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",172.7269444444445],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-43.59055555555556],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000GawlerCircuit",
    `PROJCS["NZGD_2000_Gawler_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",171.3605555555555],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-43.74861111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000TimaruCircuit",
    `PROJCS["NZGD_2000_Timaru_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",171.0572222222222],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-44.40194444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000LindisPeakCircuit",
    `PROJCS["NZGD_2000_Lindis_Peak_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",169.4675],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-44.735],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000MountNicholasCircuit",
    `PROJCS["NZGD_2000_Mount_Nicholas_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",168.3986111111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-45.13277777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000MountYorkCircuit",
    `PROJCS["NZGD_2000_Mount_York_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",167.7386111111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-45.56361111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000ObservationPointCircuit",
    `PROJCS["NZGD_2000_Observation_Point_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",170.6283333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-45.81611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000NorthTaieriCircuit",
    `PROJCS["NZGD_2000_North_Taieri_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",170.2825],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",-45.86138888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000BluffCircuit",
    `PROJCS["NZGD_2000_Bluff_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",168.3427777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-46.6],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000UTMZone58S",
    `PROJCS["NZGD_2000_UTM_Zone_58S",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000UTMZone59S",
    `PROJCS["NZGD_2000_UTM_Zone_59S",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000UTMZone60S",
    `PROJCS["NZGD_2000_UTM_Zone_60S",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AccraGhanaGrid",
    `PROJCS["Accra_Ghana_Grid",GEOGCS["GCS_Accra",DATUM["D_Accra",SPHEROID["War_Office",6378300.0,296.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-1.0],PARAMETER["Scale_Factor",0.99975],PARAMETER["Latitude_Of_Origin",4.666666666666667],UNIT["Foot_Gold_Coast",0.3047997101815088]]`
  ],
  [
    "AccraTM1NW",
    `PROJCS["Accra_TM_1_NW",GEOGCS["GCS_Accra",DATUM["D_Accra",SPHEROID["War_Office",6378300.0,296.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-1.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CGQ77QuebecLambert",
    `PROJCS["NAD_1927_CGQ77_Quebec_Lambert",GEOGCS["GCS_NAD_1927_CGQ77",DATUM["D_NAD_1927_CGQ77",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM2SCoPQ",
    `PROJCS["NAD_1983_CSRS_MTM_2_SCoPQ",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-55.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM3",
    `PROJCS["NAD_1983_CSRS_MTM_3",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-58.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM4",
    `PROJCS["NAD_1983_CSRS_MTM_4",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-61.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM5",
    `PROJCS["NAD_1983_CSRS_MTM_5",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-64.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM6",
    `PROJCS["NAD_1983_CSRS_MTM_6",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM7",
    `PROJCS["NAD_1983_CSRS_MTM_7",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM8",
    `PROJCS["NAD_1983_CSRS_MTM_8",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-73.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM9",
    `PROJCS["NAD_1983_CSRS_MTM_9",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM10",
    `PROJCS["NAD_1983_CSRS_MTM_10",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone21N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_21N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone18N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_18N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone17N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_17N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone13N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_13N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone12N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_12N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone11N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_11N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993Lambert93",
    `PROJCS["RGF_1993_Lambert_93",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",6600000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",44.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "Samoa1962SamoaLambert",
    `PROJCS["Samoa_1962_Samoa_Lambert",GEOGCS["GCS_American_Samoa_1962",DATUM["D_American_Samoa_1962",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",312234.65],PARAMETER["Central_Meridian",-170.0],PARAMETER["Standard_Parallel_1",-14.26666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-14.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "IRENET95IrishTransverseMercator",
    `PROJCS["IRENET95_Irish_Transverse_Mercator",GEOGCS["GCS_IRENET95",DATUM["D_IRENET95",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",750000.0],PARAMETER["Central_Meridian",-8.0],PARAMETER["Scale_Factor",0.99982],PARAMETER["Latitude_Of_Origin",53.5],UNIT["Meter",1.0]]`
  ],
  [
    "IRENET95UTMZone29N",
    `PROJCS["IRENET95_UTM_Zone_29N",GEOGCS["GCS_IRENET95",DATUM["D_IRENET95",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SierraLeone1924NewColonyGrid",
    `PROJCS["Sierra_Leone_1924_New_Colony_Grid",GEOGCS["GCS_Sierra_Leone_1924",DATUM["D_Sierra_Leone_1924",SPHEROID["War_Office",6378300.0,296.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",6.666666666666667],UNIT["Foot_Gold_Coast",0.3047997101815088]]`
  ],
  [
    "SierraLeone1924NewWarOfficeGrid",
    `PROJCS["Sierra_Leone_1924_New_War_Office_Grid",GEOGCS["GCS_Sierra_Leone_1924",DATUM["D_Sierra_Leone_1924",SPHEROID["War_Office",6378300.0,296.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",-12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",6.666666666666667],UNIT["Foot_Gold_Coast",0.3047997101815088]]`
  ],
  [
    "SierraLeone1968UTMZone28N",
    `PROJCS["Sierra_Leone_1968_UTM_Zone_28N",GEOGCS["GCS_Sierra_Leone_1968",DATUM["D_Sierra_Leone_1968",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SierraLeone1968UTMZone29N",
    `PROJCS["Sierra_Leone_1968_UTM_Zone_29N",GEOGCS["GCS_Sierra_Leone_1968",DATUM["D_Sierra_Leone_1968",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "USNationalAtlasEqualArea",
    `PROJCS["US_National_Atlas_Equal_Area",GEOGCS["GCS_Sphere_Clarke_1866_Authalic",DATUM["D_Sphere_Clarke_1866_Authalic",SPHEROID["Sphere_Clarke_1866_Authalic",6370997.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "Locodjo1965TM5NW",
    `PROJCS["Locodjo_1965_TM_5_NW",GEOGCS["GCS_Locodjo_1965",DATUM["D_Locodjo_1965",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-5.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Abidjan1987TM5NW",
    `PROJCS["Abidjan_1987_TM_5_NW",GEOGCS["GCS_Abidjan_1987",DATUM["D_Abidjan_1987",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-5.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone3",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_3",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone4",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_4",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone5",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_5",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LUREFLuxembourgTM",
    `PROJCS["LUREF_Luxembourg_TM",GEOGCS["GCS_LUREF",DATUM["D_Luxembourg_Reference_Frame",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",6.166666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",49.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "MGISloveniaGrid",
    `PROJCS["MGI_Slovenia_Grid",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958PolandZoneII",
    `PROJCS["Pulkovo_1942_Adj_1958_Poland_Zone_II",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",4603000.0],PARAMETER["False_Northing",5806000.0],PARAMETER["Central_Meridian",21.50277777777778],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",53.00194444444445],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958PolandZoneIII",
    `PROJCS["Pulkovo_1942_Adj_1958_Poland_Zone_III",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",3501000.0],PARAMETER["False_Northing",5999000.0],PARAMETER["Central_Meridian",17.00833333333333],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",53.58333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958PolandZoneIV",
    `PROJCS["Pulkovo_1942_Adj_1958_Poland_Zone_IV",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",3703000.0],PARAMETER["False_Northing",5627000.0],PARAMETER["Central_Meridian",16.67222222222222],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",51.67083333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958PolandZoneV",
    `PROJCS["Pulkovo_1942_Adj_1958_Poland_Zone_V",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",237000.0],PARAMETER["False_Northing",-4700000.0],PARAMETER["Central_Meridian",18.95833333333333],PARAMETER["Scale_Factor",0.999983],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF2000-PLCS200015Zone5",
    `PROJCS["ETRF2000-PL_CS2000_15_Zone_5",GEOGCS["ETRF2000-PL",DATUM["ETRF2000_Poland",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF2000-PLCS200018Zone6",
    `PROJCS["ETRF2000-PL_CS2000_18_Zone_6",GEOGCS["ETRF2000-PL",DATUM["ETRF2000_Poland",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF2000-PLCS200021Zone7",
    `PROJCS["ETRF2000-PL_CS2000_21_Zone_7",GEOGCS["ETRF2000-PL",DATUM["ETRF2000_Poland",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF2000-PLCS200024Zone8",
    `PROJCS["ETRF2000-PL_CS2000_24_Zone_8",GEOGCS["ETRF2000-PL",DATUM["ETRF2000_Poland",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF2000-PLCS92",
    `PROJCS["ETRF2000-PL_CS92",GEOGCS["ETRF2000-PL",DATUM["ETRF2000_Poland",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-5300000.0],PARAMETER["Central_Meridian",19.0],PARAMETER["Scale_Factor",0.9993],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey9",
    `PROJCS["ED_1950_Turkey_9",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey10",
    `PROJCS["ED_1950_Turkey_10",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey11",
    `PROJCS["ED_1950_Turkey_11",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey12",
    `PROJCS["ED_1950_Turkey_12",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey13",
    `PROJCS["ED_1950_Turkey_13",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey14",
    `PROJCS["ED_1950_Turkey_14",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey15",
    `PROJCS["ED_1950_Turkey_15",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AzoresOccidental1939UTMZone25N",
    `PROJCS["Azores_Occidental_1939_UTM_Zone_25N",GEOGCS["GCS_Azores_Occidental_1939",DATUM["D_Azores_Occidental_Islands_1939",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AzoresCentral1948UTMZone26N",
    `PROJCS["Azores_Central_1948_UTM_Zone_26N",GEOGCS["GCS_Azores_Central_1948",DATUM["D_Azores_Central_Islands_1948",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AzoresOriental1940UTMZone26N",
    `PROJCS["Azores_Oriental_1940_UTM_Zone_26N",GEOGCS["GCS_Azores_Oriental_1940",DATUM["D_Azores_Oriental_Islands_1940",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Madeira1936UTMZone28N",
    `PROJCS["Madeira_1936_UTM_Zone_28N",GEOGCS["GCS_Madeira_1936",DATUM["D_Madeira_1936",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950FranceEuroLambert",
    `PROJCS["ED_1950_France_EuroLambert",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2200000.0],PARAMETER["Central_Meridian",2.337229166666667],PARAMETER["Standard_Parallel_1",46.8],PARAMETER["Scale_Factor",0.99987742],PARAMETER["Latitude_Of_Origin",46.8],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000NewZealandTransverseMercator",
    `PROJCS["NZGD_2000_New_Zealand_Transverse_Mercator",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1600000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",173.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone2S",
    `PROJCS["NAD_1983_HARN_UTM_Zone_2S",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989Kp2000Jutland",
    `PROJCS["ETRS_1989_Kp2000_Jutland",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.5],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989Kp2000Zealand",
    `PROJCS["ETRS_1989_Kp2000_Zealand",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989Kp2000Bornholm",
    `PROJCS["ETRS_1989_Kp2000_Bornholm",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ATS1977NewBrunswickStereographic",
    `PROJCS["ATS_1977_New_Brunswick_Stereographic",GEOGCS["GCS_ATS_1977",DATUM["D_ATS_1977",SPHEROID["ATS_1977",6378135.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",-66.5],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "REGVENUTMZone18N",
    `PROJCS["REGVEN_UTM_Zone_18N",GEOGCS["GCS_REGVEN",DATUM["D_REGVEN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "REGVENUTMZone19N",
    `PROJCS["REGVEN_UTM_Zone_19N",GEOGCS["GCS_REGVEN",DATUM["D_REGVEN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "REGVENUTMZone20N",
    `PROJCS["REGVEN_UTM_Zone_20N",GEOGCS["GCS_REGVEN",DATUM["D_REGVEN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927StatePlaneTennesseeFIPS4100",
    `PROJCS["NAD_1927_StatePlane_Tennessee_FIPS_4100",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneKentuckyNorthFIPS1601",
    `PROJCS["NAD_1983_StatePlane_Kentucky_North_FIPS_1601",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "ED19503DegreeGKZone9",
    `PROJCS["ED_1950_3_Degree_GK_Zone_9",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED19503DegreeGKZone10",
    `PROJCS["ED_1950_3_Degree_GK_Zone_10",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED19503DegreeGKZone11",
    `PROJCS["ED_1950_3_Degree_GK_Zone_11",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED19503DegreeGKZone12",
    `PROJCS["ED_1950_3_Degree_GK_Zone_12",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED19503DegreeGKZone13",
    `PROJCS["ED_1950_3_Degree_GK_Zone_13",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED19503DegreeGKZone14",
    `PROJCS["ED_1950_3_Degree_GK_Zone_14",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED19503DegreeGKZone15",
    `PROJCS["ED_1950_3_Degree_GK_Zone_15",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989TM30NE",
    `PROJCS["ETRS_1989_TM_30_NE",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Douala1948AEFWest",
    `PROJCS["Douala_1948_AEF_West",GEOGCS["GCS_Douala_1948",DATUM["D_Douala_1948",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",10.5],PARAMETER["Scale_Factor",0.999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Manoca1962UTMZone32N",
    `PROJCS["Manoca_1962_UTM_Zone_32N",GEOGCS["GCS_Manoca_1962",DATUM["D_Manoca_1962",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Qornoq1927UTMZone22N",
    `PROJCS["Qornoq_1927_UTM_Zone_22N",GEOGCS["GCS_Qornoq_1927",DATUM["D_Qornoq_1927",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Qornoq1927UTMZone23N",
    `PROJCS["Qornoq_1927_UTM_Zone_23N",GEOGCS["GCS_Qornoq_1927",DATUM["D_Qornoq_1927",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ATS1977UTMZone19N",
    `PROJCS["ATS_1977_UTM_Zone_19N",GEOGCS["GCS_ATS_1977",DATUM["D_ATS_1977",SPHEROID["ATS_1977",6378135.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ATS1977UTMZone20N",
    `PROJCS["ATS_1977_UTM_Zone_20N",GEOGCS["GCS_ATS_1977",DATUM["D_ATS_1977",SPHEROID["ATS_1977",6378135.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneArizonaEastFIPS0201FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Arizona_East_FIPS_0201_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneArizonaCentralFIPS0202FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Arizona_Central_FIPS_0202_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneArizonaWestFIPS0203FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Arizona_West_FIPS_0203_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIFIPS0401Feet",
    `PROJCS["NAD_1983_StatePlane_California_I_FIPS_0401_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIIFIPS0402Feet",
    `PROJCS["NAD_1983_StatePlane_California_II_FIPS_0402_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIIIFIPS0403Feet",
    `PROJCS["NAD_1983_StatePlane_California_III_FIPS_0403_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIVFIPS0404Feet",
    `PROJCS["NAD_1983_StatePlane_California_IV_FIPS_0404_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaVFIPS0405Feet",
    `PROJCS["NAD_1983_StatePlane_California_V_FIPS_0405_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaVIFIPS0406Feet",
    `PROJCS["NAD_1983_StatePlane_California_VI_FIPS_0406_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneColoradoNorthFIPS0501Feet",
    `PROJCS["NAD_1983_StatePlane_Colorado_North_FIPS_0501_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneColoradoCentralFIPS0502Feet",
    `PROJCS["NAD_1983_StatePlane_Colorado_Central_FIPS_0502_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneColoradoSouthFIPS0503Feet",
    `PROJCS["NAD_1983_StatePlane_Colorado_South_FIPS_0503_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneConnecticutFIPS0600Feet",
    `PROJCS["NAD_1983_StatePlane_Connecticut_FIPS_0600_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",999999.999996],PARAMETER["False_Northing",499999.999998],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneDelawareFIPS0700Feet",
    `PROJCS["NAD_1983_StatePlane_Delaware_FIPS_0700_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneFloridaEastFIPS0901Feet",
    `PROJCS["NAD_1983_StatePlane_Florida_East_FIPS_0901_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneFloridaWestFIPS0902Feet",
    `PROJCS["NAD_1983_StatePlane_Florida_West_FIPS_0902_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneFloridaNorthFIPS0903Feet",
    `PROJCS["NAD_1983_StatePlane_Florida_North_FIPS_0903_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneGeorgiaEastFIPS1001Feet",
    `PROJCS["NAD_1983_StatePlane_Georgia_East_FIPS_1001_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneGeorgiaWestFIPS1002Feet",
    `PROJCS["NAD_1983_StatePlane_Georgia_West_FIPS_1002_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIdahoEastFIPS1101Feet",
    `PROJCS["NAD_1983_StatePlane_Idaho_East_FIPS_1101_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIdahoCentralFIPS1102Feet",
    `PROJCS["NAD_1983_StatePlane_Idaho_Central_FIPS_1102_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIdahoWestFIPS1103Feet",
    `PROJCS["NAD_1983_StatePlane_Idaho_West_FIPS_1103_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIndianaEastFIPS1301Feet",
    `PROJCS["NAD_1983_StatePlane_Indiana_East_FIPS_1301_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIndianaWestFIPS1302Feet",
    `PROJCS["NAD_1983_StatePlane_Indiana_West_FIPS_1302_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneKentuckyNorthFIPS1601Feet",
    `PROJCS["NAD_1983_StatePlane_Kentucky_North_FIPS_1601_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneKentuckySouthFIPS1602Feet",
    `PROJCS["NAD_1983_StatePlane_Kentucky_South_FIPS_1602_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMarylandFIPS1900Feet",
    `PROJCS["NAD_1983_StatePlane_Maryland_FIPS_1900_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMassachusettsMainlandFIPS2001Feet",
    `PROJCS["NAD_1983_StatePlane_Massachusetts_Mainland_FIPS_2001_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",2460625.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMassachusettsIslandFIPS2002Feet",
    `PROJCS["NAD_1983_StatePlane_Massachusetts_Island_FIPS_2002_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMichiganNorthFIPS2111FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Michigan_North_FIPS_2111_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",26246719.16010498],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneMichiganCentralFIPS2112FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Michigan_Central_FIPS_2112_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",19685039.37007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneMichiganSouthFIPS2113FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Michigan_South_FIPS_2113_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",13123359.58005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneMississippiEastFIPS2301Feet",
    `PROJCS["NAD_1983_StatePlane_Mississippi_East_FIPS_2301_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMississippiWestFIPS2302Feet",
    `PROJCS["NAD_1983_StatePlane_Mississippi_West_FIPS_2302_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMontanaFIPS2500FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Montana_FIPS_2500_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneNewMexicoEastFIPS3001Feet",
    `PROJCS["NAD_1983_StatePlane_New_Mexico_East_FIPS_3001_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",541337.5],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewMexicoCentralFIPS3002Feet",
    `PROJCS["NAD_1983_StatePlane_New_Mexico_Central_FIPS_3002_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewMexicoWestFIPS3003Feet",
    `PROJCS["NAD_1983_StatePlane_New_Mexico_West_FIPS_3003_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2723091.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewYorkEastFIPS3101Feet",
    `PROJCS["NAD_1983_StatePlane_New_York_East_FIPS_3101_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewYorkCentralFIPS3102Feet",
    `PROJCS["NAD_1983_StatePlane_New_York_Central_FIPS_3102_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",820208.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewYorkWestFIPS3103Feet",
    `PROJCS["NAD_1983_StatePlane_New_York_West_FIPS_3103_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1148291.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewYorkLongIslandFIPS3104Feet",
    `PROJCS["NAD_1983_StatePlane_New_York_Long_Island_FIPS_3104_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNorthCarolinaFIPS3200Feet",
    `PROJCS["NAD_1983_StatePlane_North_Carolina_FIPS_3200_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.002616666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNorthDakotaNorthFIPS3301FeetIntl",
    `PROJCS["NAD_1983_StatePlane_North_Dakota_North_FIPS_3301_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneNorthDakotaSouthFIPS3302FeetIntl",
    `PROJCS["NAD_1983_StatePlane_North_Dakota_South_FIPS_3302_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneOklahomaNorthFIPS3501Feet",
    `PROJCS["NAD_1983_StatePlane_Oklahoma_North_FIPS_3501_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneOklahomaSouthFIPS3502Feet",
    `PROJCS["NAD_1983_StatePlane_Oklahoma_South_FIPS_3502_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneOregonNorthFIPS3601FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Oregon_North_FIPS_3601_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8202099.737532808],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneOregonSouthFIPS3602FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Oregon_South_FIPS_3602_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921259.842519685],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlanePennsylvaniaNorthFIPS3701Feet",
    `PROJCS["NAD_1983_StatePlane_Pennsylvania_North_FIPS_3701_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlanePennsylvaniaSouthFIPS3702Feet",
    `PROJCS["NAD_1983_StatePlane_Pennsylvania_South_FIPS_3702_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneSouthCarolinaFIPS3900FeetIntl",
    `PROJCS["NAD_1983_StatePlane_South_Carolina_FIPS_3900_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneTennesseeFIPS4100Feet",
    `PROJCS["NAD_1983_StatePlane_Tennessee_FIPS_4100_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTexasNorthFIPS4201Feet",
    `PROJCS["NAD_1983_StatePlane_Texas_North_FIPS_4201_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTexasNorthCentralFIPS4202Feet",
    `PROJCS["NAD_1983_StatePlane_Texas_North_Central_FIPS_4202_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTexasCentralFIPS4203Feet",
    `PROJCS["NAD_1983_StatePlane_Texas_Central_FIPS_4203_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTexasSouthCentralFIPS4204Feet",
    `PROJCS["NAD_1983_StatePlane_Texas_South_Central_FIPS_4204_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTexasSouthFIPS4205Feet",
    `PROJCS["NAD_1983_StatePlane_Texas_South_FIPS_4205_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",16404166.66666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneUtahNorthFIPS4301FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Utah_North_FIPS_4301_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",3280839.895013123],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneUtahCentralFIPS4302FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Utah_Central_FIPS_4302_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",6561679.790026246],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneUtahSouthFIPS4303FeetIntl",
    `PROJCS["NAD_1983_StatePlane_Utah_South_FIPS_4303_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",9842519.685039369],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlaneVirginiaNorthFIPS4501Feet",
    `PROJCS["NAD_1983_StatePlane_Virginia_North_FIPS_4501_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneVirginiaSouthFIPS4502Feet",
    `PROJCS["NAD_1983_StatePlane_Virginia_South_FIPS_4502_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWashingtonNorthFIPS4601Feet",
    `PROJCS["NAD_1983_StatePlane_Washington_North_FIPS_4601_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWashingtonSouthFIPS4602Feet",
    `PROJCS["NAD_1983_StatePlane_Washington_South_FIPS_4602_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWisconsinNorthFIPS4801Feet",
    `PROJCS["NAD_1983_StatePlane_Wisconsin_North_FIPS_4801_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWisconsinCentralFIPS4802Feet",
    `PROJCS["NAD_1983_StatePlane_Wisconsin_Central_FIPS_4802_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWisconsinSouthFIPS4803Feet",
    `PROJCS["NAD_1983_StatePlane_Wisconsin_South_FIPS_4803_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "PrinceEdwardIslandStereographic",
    `PROJCS["Prince_Edward_Island_Stereographic",GEOGCS["GCS_ATS_1977",DATUM["D_ATS_1977",SPHEROID["ATS_1977",6378135.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",47.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSPrinceEdwardIsland",
    `PROJCS["NAD_1983_CSRS_Prince_Edward_Island",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",47.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSPrinceEdwardIsland",
    `PROJCS["NAD_1983_CSRS_Prince_Edward_Island",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",47.25],UNIT["Meter",1.0]]`
  ],
  [
    "ATS1977MTM4NovaScotia",
    `PROJCS["ATS_1977_MTM_4_Nova_Scotia",GEOGCS["GCS_ATS_1977",DATUM["D_ATS_1977",SPHEROID["ATS_1977",6378135.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-61.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ATS1977MTM5NovaScotia",
    `PROJCS["ATS_1977_MTM_5_Nova_Scotia",GEOGCS["GCS_ATS_1977",DATUM["D_ATS_1977",SPHEROID["ATS_1977",6378135.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-64.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BataviaTM109SE",
    `PROJCS["Batavia_TM_109_SE",GEOGCS["GCS_Batavia",DATUM["D_Batavia",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",109.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984TM116SE",
    `PROJCS["WGS_1984_TM_116_SE",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",116.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984TM132SE",
    `PROJCS["WGS_1984_TM_132_SE",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984TM6NE",
    `PROJCS["WGS_1984_TM_6_NE",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",6.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GarouaUTMZone33N",
    `PROJCS["Garoua_UTM_Zone_33N",GEOGCS["GCS_Garoua",DATUM["D_Garoua",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KousseriUTMZone33N",
    `PROJCS["Kousseri_UTM_Zone_33N",GEOGCS["GCS_Kousseri",DATUM["D_Kousseri",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Trinidad1903TrinidadGridFeetClarke",
    `PROJCS["Trinidad_1903_Trinidad_Grid_Feet_Clarke",GEOGCS["GCS_Trinidad_1903",DATUM["D_Trinidad_1903",SPHEROID["Clarke_1858",6378293.645208759,294.260676369]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",283800.0],PARAMETER["False_Northing",214500.0],PARAMETER["Central_Meridian",-61.33333333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",10.44166666666667],UNIT["Foot_Clarke",0.3047972654]]`
  ],
  [
    "CampoInchauspeUTM19S",
    `PROJCS["Campo_Inchauspe_UTM_19S",GEOGCS["GCS_Campo_Inchauspe",DATUM["D_Campo_Inchauspe",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CampoInchauspeUTM20S",
    `PROJCS["Campo_Inchauspe_UTM_20S",GEOGCS["GCS_Campo_Inchauspe",DATUM["D_Campo_Inchauspe",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956ICNRegional",
    `PROJCS["PSAD_1956_ICN_Regional",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-66.0],PARAMETER["Standard_Parallel_1",3.0],PARAMETER["Standard_Parallel_2",9.0],PARAMETER["Latitude_Of_Origin",6.0],UNIT["Meter",1.0]]`
  ],
  [
    "AinelAbdAramcoLambert",
    `PROJCS["Ain_el_Abd_Aramco_Lambert",GEOGCS["GCS_Ain_el_Abd_1970",DATUM["D_Ain_el_Abd_1970",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",48.0],PARAMETER["Standard_Parallel_1",17.0],PARAMETER["Standard_Parallel_2",33.0],PARAMETER["Latitude_Of_Origin",25.08951],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950TM27",
    `PROJCS["ED_1950_TM27",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950TM30",
    `PROJCS["ED_1950_TM30",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950TM33",
    `PROJCS["ED_1950_TM33",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950TM36",
    `PROJCS["ED_1950_TM36",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950TM39",
    `PROJCS["ED_1950_TM39",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950TM42",
    `PROJCS["ED_1950_TM42",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950TM45",
    `PROJCS["ED_1950_TM45",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "HongKong1980Grid",
    `PROJCS["Hong_Kong_1980_Grid",GEOGCS["GCS_Hong_Kong_1980",DATUM["D_Hong_Kong_1980",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",836694.05],PARAMETER["False_Northing",819069.8],PARAMETER["Central_Meridian",114.1785555555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.31213333333334],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone13",
    `PROJCS["Xian_1980_GK_Zone_13",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone14",
    `PROJCS["Xian_1980_GK_Zone_14",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone15",
    `PROJCS["Xian_1980_GK_Zone_15",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone16",
    `PROJCS["Xian_1980_GK_Zone_16",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone17",
    `PROJCS["Xian_1980_GK_Zone_17",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone18",
    `PROJCS["Xian_1980_GK_Zone_18",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone19",
    `PROJCS["Xian_1980_GK_Zone_19",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone20",
    `PROJCS["Xian_1980_GK_Zone_20",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone21",
    `PROJCS["Xian_1980_GK_Zone_21",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone22",
    `PROJCS["Xian_1980_GK_Zone_22",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKZone23",
    `PROJCS["Xian_1980_GK_Zone_23",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM75E",
    `PROJCS["Xian_1980_GK_CM_75E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM81E",
    `PROJCS["Xian_1980_GK_CM_81E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM87E",
    `PROJCS["Xian_1980_GK_CM_87E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM93E",
    `PROJCS["Xian_1980_GK_CM_93E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM99E",
    `PROJCS["Xian_1980_GK_CM_99E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM105E",
    `PROJCS["Xian_1980_GK_CM_105E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM111E",
    `PROJCS["Xian_1980_GK_CM_111E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM117E",
    `PROJCS["Xian_1980_GK_CM_117E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM123E",
    `PROJCS["Xian_1980_GK_CM_123E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM129E",
    `PROJCS["Xian_1980_GK_CM_129E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian1980GKCM135E",
    `PROJCS["Xian_1980_GK_CM_135E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone25",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_25",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone26",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_26",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone27",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_27",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone28",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_28",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone29",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_29",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone30",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_30",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone31",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_31",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone32",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_32",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone33",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_33",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone34",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_34",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",34500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone35",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_35",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",35500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone36",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_36",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",36500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone37",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_37",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",37500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone38",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_38",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",38500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone39",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_39",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",39500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone40",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_40",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",40500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone41",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_41",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",41500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone42",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_42",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",42500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone43",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_43",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",43500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone44",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_44",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",44500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKZone45",
    `PROJCS["Xian_1980_3_Degree_GK_Zone_45",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",45500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM75E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_75E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM78E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_78E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM81E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_81E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM84E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_84E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM87E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_87E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM90E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_90E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM93E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_93E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM96E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_96E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM99E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_99E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM102E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_102E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM105E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_105E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM108E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_108E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM111E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_111E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM114E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_114E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM117E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_117E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM120E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_120E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM123E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_123E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM126E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_126E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM129E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_129E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM132E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_132E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Xian19803DegreeGKCM135E",
    `PROJCS["Xian_1980_3_Degree_GK_CM_135E",GEOGCS["GCS_Xian_1980",DATUM["D_Xian_1980",SPHEROID["Xian_1980",6378140.0,298.257]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "FinlandZone1",
    `PROJCS["Finland_Zone_1",GEOGCS["GCS_KKJ",DATUM["D_KKJ",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "FinlandZone2",
    `PROJCS["Finland_Zone_2",GEOGCS["GCS_KKJ",DATUM["D_KKJ",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "FinlandZone3",
    `PROJCS["Finland_Zone_3",GEOGCS["GCS_KKJ",DATUM["D_KKJ",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "FinlandZone4",
    `PROJCS["Finland_Zone_4",GEOGCS["GCS_KKJ",DATUM["D_KKJ",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SouthYemenGKZone8",
    `PROJCS["South_Yemen_GK_Zone_8",GEOGCS["GCS_South_Yemen",DATUM["D_South_Yemen",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SouthYemenGKZone9",
    `PROJCS["South_Yemen_GK_Zone_9",GEOGCS["GCS_South_Yemen",DATUM["D_South_Yemen",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone3",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_3",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone4",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_4",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone5",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_5",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT9025gonW",
    `PROJCS["RT90_25_gon_W",GEOGCS["GCS_RT_1990",DATUM["D_RT_1990",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.80827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone25",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_25",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone26",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_26",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone27",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_27",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone28",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_28",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone29",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_29",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone30",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_30",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone31",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_31",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone32",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_32",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone33",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_33",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone34",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_34",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",34500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone35",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_35",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",35500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone36",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_36",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",36500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone37",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_37",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",37500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone38",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_38",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",38500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone39",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_39",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",39500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone40",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_40",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",40500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone41",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_41",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",41500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone42",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_42",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",42500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone43",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_43",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",43500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone44",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_44",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",44500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKZone45",
    `PROJCS["Beijing_1954_3_Degree_GK_Zone_45",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",45500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM75E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_75E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM78E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_78E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM81E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_81E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM84E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_84E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM87E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_87E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM90E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_90E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM93E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_93E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM96E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_96E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM99E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_99E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM102E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_102E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM105E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_105E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM108E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_108E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM111E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_111E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM114E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_114E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM117E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_117E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM120E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_120E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM123E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_123E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM126E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_126E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM129E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_129E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM132E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_132E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing19543DegreeGKCM135E",
    `PROJCS["Beijing_1954_3_Degree_GK_CM_135E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone1",
    `PROJCS["JGD_2000_Japan_Zone_1",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone2",
    `PROJCS["JGD_2000_Japan_Zone_2",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone3",
    `PROJCS["JGD_2000_Japan_Zone_3",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone4",
    `PROJCS["JGD_2000_Japan_Zone_4",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",133.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone5",
    `PROJCS["JGD_2000_Japan_Zone_5",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",134.3333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone6",
    `PROJCS["JGD_2000_Japan_Zone_6",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",136.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone7",
    `PROJCS["JGD_2000_Japan_Zone_7",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",137.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone8",
    `PROJCS["JGD_2000_Japan_Zone_8",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",138.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone9",
    `PROJCS["JGD_2000_Japan_Zone_9",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",139.8333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone10",
    `PROJCS["JGD_2000_Japan_Zone_10",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",140.8333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone11",
    `PROJCS["JGD_2000_Japan_Zone_11",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",140.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone12",
    `PROJCS["JGD_2000_Japan_Zone_12",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",142.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone13",
    `PROJCS["JGD_2000_Japan_Zone_13",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",144.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone14",
    `PROJCS["JGD_2000_Japan_Zone_14",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone15",
    `PROJCS["JGD_2000_Japan_Zone_15",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",127.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone16",
    `PROJCS["JGD_2000_Japan_Zone_16",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",124.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone17",
    `PROJCS["JGD_2000_Japan_Zone_17",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone18",
    `PROJCS["JGD_2000_Japan_Zone_18",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",136.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",20.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000JapanZone19",
    `PROJCS["JGD_2000_Japan_Zone_19",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "Albanian1987GKZone4",
    `PROJCS["Albanian_1987_GK_Zone_4",GEOGCS["GCS_Albanian_1987",DATUM["D_Albanian_1987",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM21E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_21E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM27E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_27E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM33E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_33E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM39E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_39E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM45E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_45E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM51E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_51E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM57E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_57E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM63E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_63E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM69E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_69E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM75E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_75E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM81E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_81E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM87E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_87E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM93E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_93E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM99E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_99E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM105E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_105E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM111E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_111E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM117E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_117E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM123E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_123E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM129E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_129E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM135E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_135E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM141E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_141E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM147E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_147E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM153E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_153E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM159E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_159E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM165E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_165E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM171E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_171E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM177E",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_177E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM177W",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_177W",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995Gauss-KrugerCM171W",
    `PROJCS["Pulkovo_1995_Gauss-Kruger_CM_171W",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM21E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_21E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM27E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_27E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM33E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_33E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM39E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_39E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM45E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_45E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM51E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_51E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM57E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_57E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM63E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_63E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM69E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_69E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM75E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_75E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM81E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_81E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM87E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_87E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM93E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_93E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM99E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_99E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM105E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_105E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM111E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_111E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM117E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_117E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM123E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_123E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM129E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_129E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM135E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_135E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM141E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_141E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM147E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_147E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM153E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_153E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM159E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_159E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM165E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_165E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM171E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_171E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM177E",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_177E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM177W",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_177W",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Gauss-KrugerCM171W",
    `PROJCS["Pulkovo_1942_Gauss-Kruger_CM_171W",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone7",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_7",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone8",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_8",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone9",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_9",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone10",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_10",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone11",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_11",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone12",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_12",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone13",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_13",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone14",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_14",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone15",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_15",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone16",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_16",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",48.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone17",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_17",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone18",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_18",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",54.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone19",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_19",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone20",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_20",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone21",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_21",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone22",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_22",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone23",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_23",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone24",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_24",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone25",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_25",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone26",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_26",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone27",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_27",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone28",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_28",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone29",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_29",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone30",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_30",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone31",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_31",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone32",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_32",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone33",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_33",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SambojaUTMZone50S",
    `PROJCS["Samboja_UTM_Zone_50S",GEOGCS["GCS_Samboja",DATUM["D_Samboja",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone34",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_34",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",34500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone35",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_35",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",35500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone36",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_36",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",36500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone37",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_37",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",37500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone38",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_38",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",38500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone39",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_39",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",39500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone40",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_40",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",40500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone41",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_41",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",41500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone42",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_42",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",42500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone43",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_43",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",43500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone44",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_44",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",44500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone45",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_45",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",45500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone46",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_46",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",46500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",138.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone47",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_47",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",47500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone48",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_48",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",48500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",144.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone49",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_49",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",49500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone50",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_50",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",50500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",150.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone51",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_51",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",51500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone52",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_52",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",52500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",156.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone53",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_53",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",53500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone54",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_54",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",54500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",162.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone55",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_55",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",55500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone56",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_56",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",56500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone57",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_57",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",57500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone58",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_58",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",58500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone59",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_59",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",59500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone60",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_60",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",60500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone61",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_61",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",61500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone62",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_62",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",62500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone63",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_63",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",63500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone64",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_64",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",64500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM21E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_21E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM24E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_24E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM27E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_27E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM30E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_30E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM33E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_33E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM36E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_36E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM39E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_39E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM42E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_42E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM45E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_45E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM48E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_48E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",48.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM51E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_51E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM54E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_54E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",54.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM57E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_57E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM60E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_60E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM63E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_63E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM66E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_66E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM69E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_69E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM72E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_72E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LKS1994LithuaniaTM",
    `PROJCS["LKS_1994_Lithuania_TM",GEOGCS["GCS_LKS_1994",DATUM["D_Lithuania_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM75E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_75E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM78E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_78E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM81E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_81E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM84E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_84E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM87E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_87E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM90E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_90E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM93E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_93E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM96E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_96E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM99E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_99E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM102E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_102E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM105E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_105E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM108E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_108E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM111E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_111E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM114E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_114E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM117E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_117E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM120E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_120E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM123E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_123E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM126E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_126E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM129E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_129E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM132E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_132E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM135E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_135E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM138E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_138E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",138.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM141E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_141E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM144E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_144E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",144.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM147E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_147E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM150E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_150E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",150.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM153E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_153E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM156E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_156E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",156.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM159E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_159E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM162E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_162E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",162.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM165E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_165E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM168E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_168E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM171E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_171E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM174E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_174E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM177E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_177E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM180E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_180E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM177W",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_177W",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM174W",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_174W",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM171W",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_171W",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM168W",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_168W",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone7",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_7",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone8",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_8",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone9",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_9",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone10",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_10",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone11",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_11",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone12",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_12",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone13",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_13",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone14",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_14",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone15",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_15",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone16",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_16",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",48.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone17",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_17",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone18",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_18",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",54.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone19",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_19",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone20",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_20",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone21",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_21",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone22",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_22",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone23",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_23",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone24",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_24",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone25",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_25",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone26",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_26",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone27",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_27",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone28",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_28",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone29",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_29",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone30",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_30",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone31",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_31",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone32",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_32",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone33",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_33",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone34",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_34",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",34500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone35",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_35",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",35500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone36",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_36",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",36500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone37",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_37",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",37500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone38",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_38",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",38500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone39",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_39",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",39500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone40",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_40",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",40500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone41",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_41",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",41500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone42",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_42",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",42500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone43",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_43",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",43500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone44",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_44",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",44500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone45",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_45",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",45500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone46",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_46",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",46500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",138.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone47",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_47",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",47500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone48",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_48",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",48500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",144.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone49",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_49",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",49500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone50",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_50",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",50500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",150.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone51",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_51",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",51500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone52",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_52",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",52500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",156.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone53",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_53",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",53500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone54",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_54",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",54500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",162.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone55",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_55",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",55500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone56",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_56",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",56500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone57",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_57",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",57500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone58",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_58",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",58500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone59",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_59",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",59500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone60",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_60",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",60500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone61",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_61",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",61500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone62",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_62",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",62500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone63",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_63",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",63500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone64",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_64",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",64500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM21E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_21E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM24E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_24E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM27E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_27E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM30E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_30E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM33E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_33E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM36E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_36E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM39E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_39E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM42E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_42E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM45E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_45E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM48E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_48E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",48.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM51E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_51E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM54E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_54E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",54.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM57E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_57E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM60E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_60E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM63E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_63E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM66E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_66E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM69E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_69E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM72E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_72E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM75E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_75E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM78E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_78E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM81E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_81E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM84E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_84E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM87E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_87E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM90E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_90E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM93E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_93E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM96E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_96E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM99E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_99E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM102E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_102E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM105E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_105E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM108E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_108E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM111E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_111E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM114E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_114E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM117E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_117E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM120E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_120E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM123E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_123E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM126E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_126E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM129E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_129E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TeteUTMZone36S",
    `PROJCS["Tete_UTM_Zone_36S",GEOGCS["GCS_Tete",DATUM["D_Tete",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TeteUTMZone37S",
    `PROJCS["Tete_UTM_Zone_37S",GEOGCS["GCS_Tete",DATUM["D_Tete",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM132E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_132E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM135E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_135E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM138E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_138E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",138.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM141E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_141E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM144E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_144E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",144.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM147E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_147E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM150E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_150E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",150.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM153E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_153E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM156E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_156E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",156.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM159E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_159E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM162E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_162E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",162.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM165E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_165E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM168E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_168E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM171E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_171E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM174E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_174E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM177E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_177E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM180E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_180E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM177W",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_177W",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM174W",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_174W",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM171W",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_171W",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM168W",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_168W",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneAlabamaEastFIPS0101",
    `PROJCS["NAD_1983_HARN_StatePlane_Alabama_East_FIPS_0101",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneAlabamaWestFIPS0102",
    `PROJCS["NAD_1983_HARN_StatePlane_Alabama_West_FIPS_0102",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArizonaEastFIPS0201",
    `PROJCS["NAD_1983_HARN_StatePlane_Arizona_East_FIPS_0201",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArizonaCentralFIPS0202",
    `PROJCS["NAD_1983_HARN_StatePlane_Arizona_Central_FIPS_0202",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArizonaWestFIPS0203",
    `PROJCS["NAD_1983_HARN_StatePlane_Arizona_West_FIPS_0203",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArkansasNorthFIPS0301",
    `PROJCS["NAD_1983_HARN_StatePlane_Arkansas_North_FIPS_0301",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArkansasSouthFIPS0302",
    `PROJCS["NAD_1983_HARN_StatePlane_Arkansas_South_FIPS_0302",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIFIPS0401",
    `PROJCS["NAD_1983_HARN_StatePlane_California_I_FIPS_0401",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIIFIPS0402",
    `PROJCS["NAD_1983_HARN_StatePlane_California_II_FIPS_0402",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIIIFIPS0403",
    `PROJCS["NAD_1983_HARN_StatePlane_California_III_FIPS_0403",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIVFIPS0404",
    `PROJCS["NAD_1983_HARN_StatePlane_California_IV_FIPS_0404",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaVFIPS0405",
    `PROJCS["NAD_1983_HARN_StatePlane_California_V_FIPS_0405",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaVIFIPS0406",
    `PROJCS["NAD_1983_HARN_StatePlane_California_VI_FIPS_0406",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneColoradoNorthFIPS0501",
    `PROJCS["NAD_1983_HARN_StatePlane_Colorado_North_FIPS_0501",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneColoradoCentralFIPS0502",
    `PROJCS["NAD_1983_HARN_StatePlane_Colorado_Central_FIPS_0502",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneColoradoSouthFIPS0503",
    `PROJCS["NAD_1983_HARN_StatePlane_Colorado_South_FIPS_0503",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneConnecticutFIPS0600",
    `PROJCS["NAD_1983_HARN_StatePlane_Connecticut_FIPS_0600",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",304800.6096],PARAMETER["False_Northing",152400.3048],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneDelawareFIPS0700",
    `PROJCS["NAD_1983_HARN_StatePlane_Delaware_FIPS_0700",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneFloridaEastFIPS0901",
    `PROJCS["NAD_1983_HARN_StatePlane_Florida_East_FIPS_0901",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneFloridaWestFIPS0902",
    `PROJCS["NAD_1983_HARN_StatePlane_Florida_West_FIPS_0902",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneFloridaNorthFIPS0903",
    `PROJCS["NAD_1983_HARN_StatePlane_Florida_North_FIPS_0903",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneGeorgiaEastFIPS1001",
    `PROJCS["NAD_1983_HARN_StatePlane_Georgia_East_FIPS_1001",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneGeorgiaWestFIPS1002",
    `PROJCS["NAD_1983_HARN_StatePlane_Georgia_West_FIPS_1002",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii1FIPS5101",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_1_FIPS_5101",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-155.5],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",18.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii2FIPS5102",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_2_FIPS_5102",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-156.6666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",20.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii3FIPS5103",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_3_FIPS_5103",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii4FIPS5104",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_4_FIPS_5104",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.5],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii5FIPS5105",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_5_FIPS_5105",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.1666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",21.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIdahoEastFIPS1101",
    `PROJCS["NAD_1983_HARN_StatePlane_Idaho_East_FIPS_1101",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIdahoCentralFIPS1102",
    `PROJCS["NAD_1983_HARN_StatePlane_Idaho_Central_FIPS_1102",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIdahoWestFIPS1103",
    `PROJCS["NAD_1983_HARN_StatePlane_Idaho_West_FIPS_1103",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIllinoisEastFIPS1201",
    `PROJCS["NAD_1983_HARN_StatePlane_Illinois_East_FIPS_1201",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIllinoisWestFIPS1202",
    `PROJCS["NAD_1983_HARN_StatePlane_Illinois_West_FIPS_1202",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIndianaEastFIPS1301",
    `PROJCS["NAD_1983_HARN_StatePlane_Indiana_East_FIPS_1301",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIndianaWestFIPS1302",
    `PROJCS["NAD_1983_HARN_StatePlane_Indiana_West_FIPS_1302",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIowaNorthFIPS1401",
    `PROJCS["NAD_1983_HARN_StatePlane_Iowa_North_FIPS_1401",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIowaSouthFIPS1402",
    `PROJCS["NAD_1983_HARN_StatePlane_Iowa_South_FIPS_1402",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneKansasNorthFIPS1501",
    `PROJCS["NAD_1983_HARN_StatePlane_Kansas_North_FIPS_1501",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneKansasSouthFIPS1502",
    `PROJCS["NAD_1983_HARN_StatePlane_Kansas_South_FIPS_1502",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneKentuckyNorthFIPS1601",
    `PROJCS["NAD_1983_HARN_StatePlane_Kentucky_North_FIPS_1601",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneKentuckySouthFIPS1602",
    `PROJCS["NAD_1983_HARN_StatePlane_Kentucky_South_FIPS_1602",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneLouisianaNorthFIPS1701",
    `PROJCS["NAD_1983_HARN_StatePlane_Louisiana_North_FIPS_1701",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneLouisianaSouthFIPS1702",
    `PROJCS["NAD_1983_HARN_StatePlane_Louisiana_South_FIPS_1702",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMaineEastFIPS1801",
    `PROJCS["NAD_1983_HARN_StatePlane_Maine_East_FIPS_1801",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMaineWestFIPS1802",
    `PROJCS["NAD_1983_HARN_StatePlane_Maine_West_FIPS_1802",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMarylandFIPS1900",
    `PROJCS["NAD_1983_HARN_StatePlane_Maryland_FIPS_1900",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMassachusettsMainlandFIPS2001",
    `PROJCS["NAD_1983_HARN_StatePlane_Massachusetts_Mainland_FIPS_2001",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",750000.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMassachusettsIslandFIPS2002",
    `PROJCS["NAD_1983_HARN_StatePlane_Massachusetts_Island_FIPS_2002",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMichiganNorthFIPS2111",
    `PROJCS["NAD_1983_HARN_StatePlane_Michigan_North_FIPS_2111",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMichiganCentralFIPS2112",
    `PROJCS["NAD_1983_HARN_StatePlane_Michigan_Central_FIPS_2112",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMichiganSouthFIPS2113",
    `PROJCS["NAD_1983_HARN_StatePlane_Michigan_South_FIPS_2113",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaNorthFIPS2201",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_North_FIPS_2201",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaCentralFIPS2202",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_Central_FIPS_2202",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaSouthFIPS2203",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_South_FIPS_2203",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMississippiEastFIPS2301",
    `PROJCS["NAD_1983_HARN_StatePlane_Mississippi_East_FIPS_2301",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMississippiWestFIPS2302",
    `PROJCS["NAD_1983_HARN_StatePlane_Mississippi_West_FIPS_2302",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMissouriEastFIPS2401",
    `PROJCS["NAD_1983_HARN_StatePlane_Missouri_East_FIPS_2401",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMissouriCentralFIPS2402",
    `PROJCS["NAD_1983_HARN_StatePlane_Missouri_Central_FIPS_2402",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMissouriWestFIPS2403",
    `PROJCS["NAD_1983_HARN_StatePlane_Missouri_West_FIPS_2403",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",850000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.5],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMontanaFIPS2500",
    `PROJCS["NAD_1983_HARN_StatePlane_Montana_FIPS_2500",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNebraskaFIPS2600",
    `PROJCS["NAD_1983_HARN_StatePlane_Nebraska_FIPS_2600",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNevadaEastFIPS2701",
    `PROJCS["NAD_1983_HARN_StatePlane_Nevada_East_FIPS_2701",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",8000000.0],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNevadaCentralFIPS2702",
    `PROJCS["NAD_1983_HARN_StatePlane_Nevada_Central_FIPS_2702",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNevadaWestFIPS2703",
    `PROJCS["NAD_1983_HARN_StatePlane_Nevada_West_FIPS_2703",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewHampshireFIPS2800",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Hampshire_FIPS_2800",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewJerseyFIPS2900",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Jersey_FIPS_2900",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewMexicoEastFIPS3001",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Mexico_East_FIPS_3001",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewMexicoCentralFIPS3002",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Mexico_Central_FIPS_3002",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewMexicoWestFIPS3003",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Mexico_West_FIPS_3003",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",830000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkEastFIPS3101",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_East_FIPS_3101",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkCentralFIPS3102",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_Central_FIPS_3102",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkWestFIPS3103",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_West_FIPS_3103",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",350000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkLongIslandFIPS3104",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_Long_Island_FIPS_3104",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNorthDakotaNorthFIPS3301",
    `PROJCS["NAD_1983_HARN_StatePlane_North_Dakota_North_FIPS_3301",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNorthDakotaSouthFIPS3302",
    `PROJCS["NAD_1983_HARN_StatePlane_North_Dakota_South_FIPS_3302",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOhioNorthFIPS3401",
    `PROJCS["NAD_1983_HARN_StatePlane_Ohio_North_FIPS_3401",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOhioSouthFIPS3402",
    `PROJCS["NAD_1983_HARN_StatePlane_Ohio_South_FIPS_3402",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOklahomaNorthFIPS3501",
    `PROJCS["NAD_1983_HARN_StatePlane_Oklahoma_North_FIPS_3501",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOklahomaSouthFIPS3502",
    `PROJCS["NAD_1983_HARN_StatePlane_Oklahoma_South_FIPS_3502",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOregonNorthFIPS3601",
    `PROJCS["NAD_1983_HARN_StatePlane_Oregon_North_FIPS_3601",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOregonSouthFIPS3602",
    `PROJCS["NAD_1983_HARN_StatePlane_Oregon_South_FIPS_3602",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneRhodeIslandFIPS3800",
    `PROJCS["NAD_1983_HARN_StatePlane_Rhode_Island_FIPS_3800",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneSouthDakotaNorthFIPS4001",
    `PROJCS["NAD_1983_HARN_StatePlane_South_Dakota_North_FIPS_4001",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneSouthDakotaSouthFIPS4002",
    `PROJCS["NAD_1983_HARN_StatePlane_South_Dakota_South_FIPS_4002",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTennesseeFIPS4100",
    `PROJCS["NAD_1983_HARN_StatePlane_Tennessee_FIPS_4100",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasNorthFIPS4201",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_North_FIPS_4201",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasNorthCentralFIPS4202",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_North_Central_FIPS_4202",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasCentralFIPS4203",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_Central_FIPS_4203",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasSouthCentralFIPS4204",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_South_Central_FIPS_4204",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasSouthFIPS4205",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_South_FIPS_4205",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahNorthFIPS4301",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_North_FIPS_4301",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahCentralFIPS4302",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_Central_FIPS_4302",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahSouthFIPS4303",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_South_FIPS_4303",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneVermontFIPS4400",
    `PROJCS["NAD_1983_HARN_StatePlane_Vermont_FIPS_4400",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneVirginiaNorthFIPS4501",
    `PROJCS["NAD_1983_HARN_StatePlane_Virginia_North_FIPS_4501",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneVirginiaSouthFIPS4502",
    `PROJCS["NAD_1983_HARN_StatePlane_Virginia_South_FIPS_4502",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWashingtonNorthFIPS4601",
    `PROJCS["NAD_1983_HARN_StatePlane_Washington_North_FIPS_4601",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWashingtonSouthFIPS4602",
    `PROJCS["NAD_1983_HARN_StatePlane_Washington_South_FIPS_4602",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWestVirginiaNorthFIPS4701",
    `PROJCS["NAD_1983_HARN_StatePlane_West_Virginia_North_FIPS_4701",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWestVirginiaSouthFIPS4702",
    `PROJCS["NAD_1983_HARN_StatePlane_West_Virginia_South_FIPS_4702",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWisconsinNorthFIPS4801",
    `PROJCS["NAD_1983_HARN_StatePlane_Wisconsin_North_FIPS_4801",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWisconsinCentralFIPS4802",
    `PROJCS["NAD_1983_HARN_StatePlane_Wisconsin_Central_FIPS_4802",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWisconsinSouthFIPS4803",
    `PROJCS["NAD_1983_HARN_StatePlane_Wisconsin_South_FIPS_4803",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingEastFIPS4901",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_East_FIPS_4901",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingEastCentralFIPS4902",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_East_Central_FIPS_4902",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingWestCentralFIPS4903",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_West_Central_FIPS_4903",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingWestFIPS4904",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_West_FIPS_4904",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlanePuertoRicoVirginIslandsFIPS5200",
    `PROJCS["NAD_1983_HARN_StatePlane_Puerto_Rico_Virgin_Islands_FIPS_5200",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArizonaEastFIPS0201FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Arizona_East_FIPS_0201_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneArizonaCentralFIPS0202FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Arizona_Central_FIPS_0202_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneArizonaWestFIPS0203FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Arizona_West_FIPS_0203_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIFIPS0401Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_California_I_FIPS_0401_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIIFIPS0402Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_California_II_FIPS_0402_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIIIFIPS0403Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_California_III_FIPS_0403_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIVFIPS0404Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_California_IV_FIPS_0404_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaVFIPS0405Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_California_V_FIPS_0405_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaVIFIPS0406Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_California_VI_FIPS_0406_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneColoradoNorthFIPS0501Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Colorado_North_FIPS_0501_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneColoradoCentralFIPS0502Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Colorado_Central_FIPS_0502_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneColoradoSouthFIPS0503Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Colorado_South_FIPS_0503_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneConnecticutFIPS0600Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Connecticut_FIPS_0600_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",999999.999996],PARAMETER["False_Northing",499999.999998],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneDelawareFIPS0700Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Delaware_FIPS_0700_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneFloridaEastFIPS0901Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Florida_East_FIPS_0901_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneFloridaWestFIPS0902Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Florida_West_FIPS_0902_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneFloridaNorthFIPS0903Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Florida_North_FIPS_0903_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneGeorgiaEastFIPS1001Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Georgia_East_FIPS_1001_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneGeorgiaWestFIPS1002Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Georgia_West_FIPS_1002_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneIdahoEastFIPS1101Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Idaho_East_FIPS_1101_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneIdahoCentralFIPS1102Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Idaho_Central_FIPS_1102_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneIdahoWestFIPS1103Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Idaho_West_FIPS_1103_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneKentuckyNorthFIPS1601Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Kentucky_North_FIPS_1601_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneKentuckySouthFIPS1602Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Kentucky_South_FIPS_1602_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMarylandFIPS1900Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Maryland_FIPS_1900_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMassachusettsMainlandFIPS2001Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Massachusetts_Mainland_FIPS_2001_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",2460625.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMassachusettsIslandFIPS2002Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Massachusetts_Island_FIPS_2002_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMichiganNorthFIPS2111FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Michigan_North_FIPS_2111_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",26246719.16010498],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneMichiganCentralFIPS2112FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Michigan_Central_FIPS_2112_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",19685039.37007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneMichiganSouthFIPS2113FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Michigan_South_FIPS_2113_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",13123359.58005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneMississippiEastFIPS2301Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Mississippi_East_FIPS_2301_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMississippiWestFIPS2302Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Mississippi_West_FIPS_2302_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMontanaFIPS2500FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Montana_FIPS_2500_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneNewMexicoEastFIPS3001Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Mexico_East_FIPS_3001_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",541337.5],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNewMexicoCentralFIPS3002Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Mexico_Central_FIPS_3002_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNewMexicoWestFIPS3003Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Mexico_West_FIPS_3003_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2723091.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkEastFIPS3101Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_East_FIPS_3101_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkCentralFIPS3102Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_Central_FIPS_3102_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",820208.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkWestFIPS3103Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_West_FIPS_3103_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1148291.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkLongIslandFIPS3104Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_Long_Island_FIPS_3104_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNorthDakotaNorthFIPS3301FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_North_Dakota_North_FIPS_3301_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneNorthDakotaSouthFIPS3302FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_North_Dakota_South_FIPS_3302_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneOklahomaNorthFIPS3501Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Oklahoma_North_FIPS_3501_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneOklahomaSouthFIPS3502Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Oklahoma_South_FIPS_3502_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneOregonNorthFIPS3601FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Oregon_North_FIPS_3601_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8202099.737532808],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneOregonSouthFIPS3602FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Oregon_South_FIPS_3602_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921259.842519685],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneTennesseeFIPS4100Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Tennessee_FIPS_4100_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasNorthFIPS4201Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_North_FIPS_4201_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasNorthCentralFIPS4202Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_North_Central_FIPS_4202_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasCentralFIPS4203Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_Central_FIPS_4203_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasSouthCentralFIPS4204Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_South_Central_FIPS_4204_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasSouthFIPS4205Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_South_FIPS_4205_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",16404166.66666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahNorthFIPS4301FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_North_FIPS_4301_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",3280839.895013123],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahCentralFIPS4302FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_Central_FIPS_4302_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",6561679.790026246],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahSouthFIPS4303FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_South_FIPS_4303_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",9842519.685039369],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlaneVirginiaNorthFIPS4501Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Virginia_North_FIPS_4501_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneVirginiaSouthFIPS4502Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Virginia_South_FIPS_4502_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWashingtonNorthFIPS4601Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Washington_North_FIPS_4601_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWashingtonSouthFIPS4602Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Washington_South_FIPS_4602_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWisconsinNorthFIPS4801Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Wisconsin_North_FIPS_4801_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWisconsinCentralFIPS4802Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Wisconsin_Central_FIPS_4802_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWisconsinSouthFIPS4803Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Wisconsin_South_FIPS_4803_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "BeduaramTM13NE",
    `PROJCS["Beduaram_TM_13_NE",GEOGCS["GCS_Beduaram",DATUM["D_Beduaram",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",13.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "QND1995QatarNationalGrid",
    `PROJCS["QND_1995_Qatar_National_Grid",GEOGCS["GCS_QND_1995",DATUM["D_QND_1995",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",51.21666666666667],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",24.45],UNIT["Meter",1.0]]`
  ],
  [
    "GunungSegaraUTMZone50S",
    `PROJCS["Gunung_Segara_UTM_Zone_50S",GEOGCS["GCS_Gunung_Segara",DATUM["D_Gunung_Segara",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GunungSegaraJakartaNEIEZ",
    `PROJCS["Gunung_Segara_Jakarta_NEIEZ",GEOGCS["GCS_Gunung_Segara_Jakarta",DATUM["D_Gunung_Segara",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Jakarta",106.8077194444444],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",3900000.0],PARAMETER["False_Northing",900000.0],PARAMETER["Central_Meridian",3.192280555555556],PARAMETER["Standard_Parallel_1",4.45405154589751],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63ZoneA1",
    `PROJCS["Pulkovo_1942_CS63_Zone_A1",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",1300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",41.53333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.1166666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63ZoneA2",
    `PROJCS["Pulkovo_1942_CS63_Zone_A2",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",44.53333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.1166666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63ZoneA3",
    `PROJCS["Pulkovo_1942_CS63_Zone_A3",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",47.53333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.1166666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63ZoneA4",
    `PROJCS["Pulkovo_1942_CS63_Zone_A4",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",50.53333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.1166666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63ZoneK2",
    `PROJCS["Pulkovo_1942_CS63_Zone_K2",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",50.76666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.1333333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63ZoneK3",
    `PROJCS["Pulkovo_1942_CS63_Zone_K3",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",53.76666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.1333333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63ZoneK4",
    `PROJCS["Pulkovo_1942_CS63_Zone_K4",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",56.76666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.1333333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "PortoSanto1936UTMZone28N",
    `PROJCS["Porto_Santo_1936_UTM_Zone_28N",GEOGCS["GCS_Porto_Santo_1936",DATUM["D_Porto_Santo_1936",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SelvagemGrande1938UTMZone28N",
    `PROJCS["Selvagem_Grande_1938_UTM_Zone_28N",GEOGCS["GCS_Selvagem_Grande_1938",DATUM["D_Selvagem_Grande_1938",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM2SCoPQ",
    `PROJCS["NAD_1983_CSRS_MTM_2_SCoPQ",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-55.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM3",
    `PROJCS["NAD_1983_CSRS_MTM_3",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-58.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM4",
    `PROJCS["NAD_1983_CSRS_MTM_4",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-61.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM5",
    `PROJCS["NAD_1983_CSRS_MTM_5",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-64.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM6",
    `PROJCS["NAD_1983_CSRS_MTM_6",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM7",
    `PROJCS["NAD_1983_CSRS_MTM_7",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM8",
    `PROJCS["NAD_1983_CSRS_MTM_8",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-73.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM9",
    `PROJCS["NAD_1983_CSRS_MTM_9",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM10",
    `PROJCS["NAD_1983_CSRS_MTM_10",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSNewBrunswickStereographic",
    `PROJCS["NAD_1983_CSRS_New_Brunswick_Stereographic",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",7500000.0],PARAMETER["Central_Meridian",-66.5],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSPrinceEdwardIsland",
    `PROJCS["NAD_1983_CSRS_Prince_Edward_Island",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",47.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone11N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_11N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone12N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_12N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone13N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_13N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone17N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_17N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone18N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_18N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone19N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_19N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone20N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_20N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone21N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_21N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927AlaskaAlbersFeet",
    `PROJCS["NAD_1927_Alaska_Albers_Feet",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIndianaEastFIPS1301Feet",
    `PROJCS["NAD_1983_StatePlane_Indiana_East_FIPS_1301_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIndianaWestFIPS1302Feet",
    `PROJCS["NAD_1983_StatePlane_Indiana_West_FIPS_1302_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneIndianaEastFIPS1301Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Indiana_East_FIPS_1301_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneIndianaWestFIPS1302Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Indiana_West_FIPS_1302_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "FortMarigotUTM20N",
    `PROJCS["Fort_Marigot_UTM_20N",GEOGCS["GCS_Fort_Marigot",DATUM["D_Fort_Marigot",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SainteAnneUTM20N",
    `PROJCS["Sainte_Anne_UTM_20N",GEOGCS["GCS_Sainte_Anne",DATUM["D_Sainte_Anne",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CSG1967UTM22N",
    `PROJCS["CSG_1967_UTM_22N",GEOGCS["GCS_CSG_1967",DATUM["D_CSG_1967",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGFG1995UTM22N",
    `PROJCS["RGFG_1995_UTM_22N",GEOGCS["GCS_RGFG_1995",DATUM["D_RGFG_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "FortDesaixUTM20N",
    `PROJCS["Fort_Desaix_UTM_20N",GEOGCS["GCS_Fort_Desaix",DATUM["D_Fort_Desaix",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGR1992UTM40S",
    `PROJCS["RGR_1992_UTM_40S",GEOGCS["GCS_RGR_1992",DATUM["D_RGR_1992",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Tahiti1952UTM6S",
    `PROJCS["Tahiti_1952_UTM_6S",GEOGCS["GCS_Tahiti_1952",DATUM["D_Tahiti_1952",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Tahaa1954UTM5S",
    `PROJCS["Tahaa_1954_UTM_5S",GEOGCS["GCS_Tahaa_1954",DATUM["D_Tahaa_1954",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGN72NukuHivaUTM7S",
    `PROJCS["IGN72_Nuku_Hiva_UTM_7S",GEOGCS["GCS_IGN72_Nuku_Hiva",DATUM["D_IGN72_Nuku_Hiva",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KerguelenIsland1949UTM42S",
    `PROJCS["Kerguelen_Island_1949_UTM_42S",GEOGCS["GCS_Kerguelen_Island_1949",DATUM["D_Kerguelen_Island_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Combani1950UTM38S",
    `PROJCS["Combani_1950_UTM_38S",GEOGCS["GCS_Combani_1950",DATUM["D_Combani_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGN56LifouUTM58S",
    `PROJCS["IGN56_Lifou_UTM_58S",GEOGCS["GCS_IGN56_Lifou",DATUM["D_IGN56_Lifou",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGN72GrandeTerreUTM58S",
    `PROJCS["IGN72_Grande_Terre_UTM_58S",GEOGCS["GCS_IGN72_Grande_Terre",DATUM["D_IGN72_Grande_Terre",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGNC1991LambertNewCaledonia",
    `PROJCS["RGNC_1991_Lambert_New_Caledonia",GEOGCS["GCS_RGNC_1991",DATUM["D_RGNC_1991",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",166.0],PARAMETER["Standard_Parallel_1",-20.66666666666667],PARAMETER["Standard_Parallel_2",-22.33333333333333],PARAMETER["Latitude_Of_Origin",-21.5],UNIT["Meter",1.0]]`
  ],
  [
    "Petrels1972TerreAdeliePolarStereographic",
    `PROJCS["Petrels_1972_Terre_Adelie_Polar_Stereographic",GEOGCS["GCS_Petrels_1972",DATUM["D_Petrels_1972",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_South_Pole"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",-2299363.487823496],PARAMETER["Central_Meridian",140.0],PARAMETER["Standard_Parallel_1",-67.0],UNIT["Meter",1.0]]`
  ],
  [
    "Perroud1950TerreAdeliePolarStereographic",
    `PROJCS["Perroud_1950_Terre_Adelie_Polar_Stereographic",GEOGCS["GCS_Pointe_Geologie_Perroud_1950",DATUM["D_Pointe_Geologie_Perroud_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_South_Pole"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",-2299363.487823496],PARAMETER["Central_Meridian",140.0],PARAMETER["Standard_Parallel_1",-67.0],UNIT["Meter",1.0]]`
  ],
  [
    "SaintPierreetMiquelon1950UTM21N",
    `PROJCS["Saint_Pierre_et_Miquelon_1950_UTM_21N",GEOGCS["GCS_Saint_Pierre_et_Miquelon_1950",DATUM["D_Saint_Pierre_et_Miquelon_1950",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MOP78UTM1S",
    `PROJCS["MOP78_UTM_1S",GEOGCS["GCS_MOP78",DATUM["D_MOP78",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983OregonStatewideLambert",
    `PROJCS["NAD_1983_Oregon_Statewide_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983OregonStatewideLambertFeetIntl",
    `PROJCS["NAD_1983_Oregon_Statewide_Lambert_Feet_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312335.958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNOregonStatewideLambert",
    `PROJCS["NAD_1983_HARN_Oregon_Statewide_Lambert",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNOregonStatewideLambertFeetIntl",
    `PROJCS["NAD_1983_HARN_Oregon_Statewide_Lambert_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312335.958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "IGN53MareUTM58S",
    `PROJCS["IGN53_Mare_UTM_58S",GEOGCS["GCS_IGN53_Mare",DATUM["D_IGN53_Mare",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ST84IledesPinsUTM58S",
    `PROJCS["ST84_Ile_des_Pins_UTM_58S",GEOGCS["GCS_ST84_Ile_des_Pins",DATUM["D_ST84_Ile_des_Pins",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ST71BelepUTM58S",
    `PROJCS["ST71_Belep_UTM_58S",GEOGCS["GCS_ST71_Belep",DATUM["D_ST71_Belep",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NEA74NoumeaUTM58S",
    `PROJCS["NEA74_Noumea_UTM_58S",GEOGCS["GCS_NEA74_Noumea",DATUM["D_NEA74_Noumea",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GrandComorosUTM38S",
    `PROJCS["Grand_Comoros_UTM_38S",GEOGCS["GCS_Grand_Comoros",DATUM["D_Grand_Comoros",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GunungSegaraNEIEZ",
    `PROJCS["Gunung_Segara_NEIEZ",GEOGCS["GCS_Gunung_Segara",DATUM["D_Gunung_Segara",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",3900000.0],PARAMETER["False_Northing",900000.0],PARAMETER["Central_Meridian",110.0],PARAMETER["Standard_Parallel_1",4.45405154589751],UNIT["Meter",1.0]]`
  ],
  [
    "BataviaNEIEZ",
    `PROJCS["Batavia_NEIEZ",GEOGCS["GCS_Batavia",DATUM["D_Batavia",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",3900000.0],PARAMETER["False_Northing",900000.0],PARAMETER["Central_Meridian",110.0],PARAMETER["Standard_Parallel_1",4.45405154589751],UNIT["Meter",1.0]]`
  ],
  [
    "MakassarNEIEZ",
    `PROJCS["Makassar_NEIEZ",GEOGCS["GCS_Makassar",DATUM["D_Makassar",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",3900000.0],PARAMETER["False_Northing",900000.0],PARAMETER["Central_Meridian",110.0],PARAMETER["Standard_Parallel_1",4.45405154589751],UNIT["Meter",1.0]]`
  ],
  [
    "MonteMarioItaly1",
    `PROJCS["Monte_Mario_Italy_1",GEOGCS["GCS_Monte_Mario",DATUM["D_Monte_Mario",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MonteMarioItaly2",
    `PROJCS["Monte_Mario_Italy_2",GEOGCS["GCS_Monte_Mario",DATUM["D_Monte_Mario",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2520000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983BCEnvironmentAlbers",
    `PROJCS["NAD_1983_BC_Environment_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-126.0],PARAMETER["Standard_Parallel_1",50.0],PARAMETER["Standard_Parallel_2",58.5],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF99TM",
    `PROJCS["SWEREF99_TM",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF991200",
    `PROJCS["SWEREF99_12_00",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF991330",
    `PROJCS["SWEREF99_13_30",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",13.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF991500",
    `PROJCS["SWEREF99_15_00",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF991630",
    `PROJCS["SWEREF99_16_30",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",16.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF991800",
    `PROJCS["SWEREF99_18_00",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF991415",
    `PROJCS["SWEREF99_14_15",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",14.25],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF991545",
    `PROJCS["SWEREF99_15_45",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF991715",
    `PROJCS["SWEREF99_17_15",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",17.25],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF991845",
    `PROJCS["SWEREF99_18_45",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF992015",
    `PROJCS["SWEREF99_20_15",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",20.25],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF992145",
    `PROJCS["SWEREF99_21_45",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF992315",
    `PROJCS["SWEREF99_23_15",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",23.25],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT9075gonV",
    `PROJCS["RT90_75_gon_V",GEOGCS["GCS_RT_1990",DATUM["D_RT_1990",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",11.30827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT905gonV",
    `PROJCS["RT90_5_gon_V",GEOGCS["GCS_RT_1990",DATUM["D_RT_1990",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",13.55827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT9025gonV",
    `PROJCS["RT90_25_gon_V",GEOGCS["GCS_RT_1990",DATUM["D_RT_1990",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.80827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT900gon",
    `PROJCS["RT90_0_gon",GEOGCS["GCS_RT_1990",DATUM["D_RT_1990",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.05827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT9025gonO",
    `PROJCS["RT90_25_gon_O",GEOGCS["GCS_RT_1990",DATUM["D_RT_1990",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",20.30827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT905gonO",
    `PROJCS["RT90_5_gon_O",GEOGCS["GCS_RT_1990",DATUM["D_RT_1990",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",22.55827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT3875gonV",
    `PROJCS["RT38_75_gon_V",GEOGCS["GCS_RT38",DATUM["D_Stockholm_1938",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",11.30827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT385gonV",
    `PROJCS["RT38_5_gon_V",GEOGCS["GCS_RT38",DATUM["D_Stockholm_1938",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",13.55827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT3825gonV",
    `PROJCS["RT38_25_gon_V",GEOGCS["GCS_RT38",DATUM["D_Stockholm_1938",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.80827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT380gon",
    `PROJCS["RT38_0_gon",GEOGCS["GCS_RT38",DATUM["D_Stockholm_1938",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.05827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT3825gonO",
    `PROJCS["RT38_25_gon_O",GEOGCS["GCS_RT38",DATUM["D_Stockholm_1938",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",20.30827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RT385gonO",
    `PROJCS["RT38_5_gon_O",GEOGCS["GCS_RT38",DATUM["D_Stockholm_1938",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",22.55827777777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984AntarcticPolarStereographic",
    `PROJCS["WGS_1984_Antarctic_Polar_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_South_Pole"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-71.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984AustralianAntarcticPolarStereographic",
    `PROJCS["WGS_1984_Australian_Antarctic_Polar_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_South_Pole"],PARAMETER["False_Easting",6000000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",70.0],PARAMETER["Standard_Parallel_1",-71.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984AustralianAntarcticLambert",
    `PROJCS["WGS_1984_Australian_Antarctic_Lambert",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6000000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",70.0],PARAMETER["Standard_Parallel_1",-68.5],PARAMETER["Standard_Parallel_2",-74.5],PARAMETER["Latitude_Of_Origin",-50.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989LCC",
    `PROJCS["ETRS_1989_LCC",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",2800000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",35.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989LAEA",
    `PROJCS["ETRS_1989_LAEA",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",4321000.0],PARAMETER["False_Northing",3210000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "MoznetUTMZone36S",
    `PROJCS["Moznet_UTM_Zone_36S",GEOGCS["GCS_Moznet",DATUM["D_Moznet",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MoznetUTMZone37S",
    `PROJCS["Moznet_UTM_Zone_37S",GEOGCS["GCS_Moznet",DATUM["D_Moznet",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM26",
    `PROJCS["ETRS_1989_ETRS-TM26",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM27",
    `PROJCS["ETRS_1989_ETRS-TM27",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM28",
    `PROJCS["ETRS_1989_ETRS-TM28",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM29",
    `PROJCS["ETRS_1989_ETRS-TM29",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM30",
    `PROJCS["ETRS_1989_ETRS-TM30",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM31",
    `PROJCS["ETRS_1989_ETRS-TM31",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM32",
    `PROJCS["ETRS_1989_ETRS-TM32",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM33",
    `PROJCS["ETRS_1989_ETRS-TM33",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM34",
    `PROJCS["ETRS_1989_ETRS-TM34",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM35",
    `PROJCS["ETRS_1989_ETRS-TM35",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM36",
    `PROJCS["ETRS_1989_ETRS-TM36",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM37",
    `PROJCS["ETRS_1989_ETRS-TM37",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM38",
    `PROJCS["ETRS_1989_ETRS-TM38",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-TM39",
    `PROJCS["ETRS_1989_ETRS-TM39",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hjorsey1955UTMZone26N",
    `PROJCS["Hjorsey_1955_UTM_Zone_26N",GEOGCS["GCS_Hjorsey_1955",DATUM["D_Hjorsey_1955",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hjorsey1955UTMZone27N",
    `PROJCS["Hjorsey_1955_UTM_Zone_27N",GEOGCS["GCS_Hjorsey_1955",DATUM["D_Hjorsey_1955",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hjorsey1955UTMZone28N",
    `PROJCS["Hjorsey_1955_UTM_Zone_28N",GEOGCS["GCS_Hjorsey_1955",DATUM["D_Hjorsey_1955",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ISN1993Lambert1993",
    `PROJCS["ISN_1993_Lambert_1993",GEOGCS["GCS_ISN_1993",DATUM["D_Islands_Network_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-19.0],PARAMETER["Standard_Parallel_1",64.25],PARAMETER["Standard_Parallel_2",65.75],PARAMETER["Latitude_Of_Origin",65.0],UNIT["Meter",1.0]]`
  ],
  [
    "Helle1954JanMayenGrid",
    `PROJCS["Helle_1954_Jan_Mayen_Grid",GEOGCS["GCS_Helle_1954",DATUM["D_Helle_1954",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",-7800000.0],PARAMETER["Central_Meridian",-8.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LKS1992LatviaTM",
    `PROJCS["LKS_1992_Latvia_TM",GEOGCS["GCS_LKS_1992",DATUM["D_Latvia_1992",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-6000000.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGN72GrandeTerreUTM58S",
    `PROJCS["IGN72_Grande_Terre_UTM_58S",GEOGCS["GCS_IGN72_Grande_Terre",DATUM["D_IGN72_Grande_Terre",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PortoSanto1995UTMZone28N",
    `PROJCS["Porto_Santo_1995_UTM_Zone_28N",GEOGCS["GCS_Porto_Santo_1995",DATUM["D_Porto_Santo_1995",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AzoresOriental1995UTMZone26N",
    `PROJCS["Azores_Oriental_1995_UTM_Zone_26N",GEOGCS["GCS_Azores_Oriental_1995",DATUM["D_Azores_Oriental_Islands_1995",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AzoresCentral1995UTMZone26N",
    `PROJCS["Azores_Central_1995_UTM_Zone_26N",GEOGCS["GCS_Azores_Central_1995",DATUM["D_Azores_Central_Islands_1995",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGM1995UTMZone32N",
    `PROJCS["IGM_1995_UTM_Zone_32N",GEOGCS["GCS_IGM_1995",DATUM["D_IGM_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGM1995UTMZone33N",
    `PROJCS["IGM_1995_UTM_Zone_33N",GEOGCS["GCS_IGM_1995",DATUM["D_IGM_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950JordanTM",
    `PROJCS["ED_1950_Jordan_TM",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-3000000.0],PARAMETER["Central_Meridian",37.0],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "EUREFFINTM35FIN",
    `PROJCS["EUREF_FIN_TM35FIN",GEOGCS["GCS_EUREF_FIN",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDNSoldnerBerlin",
    `PROJCS["DHDN_Soldner_Berlin",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",10000.0],PARAMETER["Central_Meridian",13.62720366666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",52.41864827777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927WisconsinTM",
    `PROJCS["NAD_1927_Wisconsin_TM",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-4500000.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983WisconsinTM",
    `PROJCS["NAD_1983_Wisconsin_TM",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",520000.0],PARAMETER["False_Northing",-4480000.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWisconsinTM",
    `PROJCS["NAD_1983_HARN_Wisconsin_TM",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",520000.0],PARAMETER["False_Northing",-4480000.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983Maine2000EastZone",
    `PROJCS["NAD_1983_Maine_2000_East_Zone",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.875],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983Maine2000CentralZone",
    `PROJCS["NAD_1983_Maine_2000_Central_Zone",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.125],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983Maine2000WestZone",
    `PROJCS["NAD_1983_Maine_2000_West_Zone",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.375],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNMaine2000EastZone",
    `PROJCS["NAD_1983_HARN_Maine_2000_East_Zone",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.875],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNMaine2000CentralZone",
    `PROJCS["NAD_1983_HARN_Maine_2000_Central_Zone",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.125],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNMaine2000WestZone",
    `PROJCS["NAD_1983_HARN_Maine_2000_West_Zone",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.375],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MichiganGeoRefMeters",
    `PROJCS["NAD_1983_Michigan_GeoRef_Meters",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",2546731.496],PARAMETER["False_Northing",-4354009.816],PARAMETER["Scale_Factor",0.9996],PARAMETER["Azimuth",337.25556],PARAMETER["Longitude_Of_Center",-86.0],PARAMETER["Latitude_Of_Center",45.30916666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNMichiganGeoRefMeters",
    `PROJCS["NAD_1983_HARN_Michigan_GeoRef_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",2546731.496],PARAMETER["False_Northing",-4354009.816],PARAMETER["Scale_Factor",0.9996],PARAMETER["Azimuth",337.25556],PARAMETER["Longitude_Of_Center",-86.0],PARAMETER["Latitude_Of_Center",45.30916666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927TexasStatewideMappingSystem",
    `PROJCS["NAD_1927_Texas_Statewide_Mapping_System",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.41666666666667],PARAMETER["Standard_Parallel_2",34.91666666666666],PARAMETER["Latitude_Of_Origin",31.16666666666667],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983TexasStatewideMappingSystem",
    `PROJCS["NAD_1983_Texas_Statewide_Mapping_System",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.41666666666667],PARAMETER["Standard_Parallel_2",34.91666666666666],PARAMETER["Latitude_Of_Origin",31.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983TexasCentricMappingSystemLambert",
    `PROJCS["NAD_1983_Texas_Centric_Mapping_System_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983TexasCentricMappingSystemAlbers",
    `PROJCS["NAD_1983_Texas_Centric_Mapping_System_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNTexasCentricMappingSystemLambert",
    `PROJCS["NAD_1983_HARN_Texas_Centric_Mapping_System_Lambert",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNTexasCentricMappingSystemAlbers",
    `PROJCS["NAD_1983_HARN_Texas_Centric_Mapping_System_Albers",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983FloridaGDLAlbers",
    `PROJCS["NAD_1983_Florida_GDL_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Standard_Parallel_1",24.0],PARAMETER["Standard_Parallel_2",31.5],PARAMETER["Latitude_Of_Origin",24.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNFloridaGDLAlbers",
    `PROJCS["NAD_1983_HARN_Florida_GDL_Albers",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Standard_Parallel_1",24.0],PARAMETER["Standard_Parallel_2",31.5],PARAMETER["Latitude_Of_Origin",24.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneKentuckyFIPS1600",
    `PROJCS["NAD_1983_StatePlane_Kentucky_FIPS_1600",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneKentuckyFIPS1600Feet",
    `PROJCS["NAD_1983_StatePlane_Kentucky_FIPS_1600_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneKentuckyFIPS1600",
    `PROJCS["NAD_1983_HARN_StatePlane_Kentucky_FIPS_1600",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneKentuckyFIPS1600Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Kentucky_FIPS_1600_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "TokyoUTMZone51N",
    `PROJCS["Tokyo_UTM_Zone_51N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TokyoUTMZone52N",
    `PROJCS["Tokyo_UTM_Zone_52N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TokyoUTMZone53N",
    `PROJCS["Tokyo_UTM_Zone_53N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TokyoUTMZone54N",
    `PROJCS["Tokyo_UTM_Zone_54N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TokyoUTMZone55N",
    `PROJCS["Tokyo_UTM_Zone_55N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone51N",
    `PROJCS["JGD_2000_UTM_Zone_51N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone52N",
    `PROJCS["JGD_2000_UTM_Zone_52N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone53N",
    `PROJCS["JGD_2000_UTM_Zone_53N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone54N",
    `PROJCS["JGD_2000_UTM_Zone_54N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone55N",
    `PROJCS["JGD_2000_UTM_Zone_55N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Samoa1962SamoaLambert",
    `PROJCS["Samoa_1962_Samoa_Lambert",GEOGCS["GCS_American_Samoa_1962",DATUM["D_American_Samoa_1962",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",312234.65],PARAMETER["Central_Meridian",-170.0],PARAMETER["Standard_Parallel_1",-14.26666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-14.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Gulshan303BangladeshTM",
    `PROJCS["Gulshan_303_Bangladesh_TM",GEOGCS["GCS_Gulshan_303",DATUM["D_Gulshan_303",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994SouthAustraliaLambert",
    `PROJCS["GDA_1994_South_Australia_Lambert",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Standard_Parallel_1",-28.0],PARAMETER["Standard_Parallel_2",-36.0],PARAMETER["Latitude_Of_Origin",-32.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GuernseyGrid",
    `PROJCS["ETRS_1989_Guernsey_Grid",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",47000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",-2.416666666666667],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",49.5],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989JerseyTransverseMercator",
    `PROJCS["ETRS_1989_Jersey_Transverse_Mercator",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",70000.0],PARAMETER["Central_Meridian",-2.135],PARAMETER["Scale_Factor",0.9999999],PARAMETER["Latitude_Of_Origin",49.225],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966VICGRID",
    `PROJCS["AGD_1966_VICGRID",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",145.0],PARAMETER["Standard_Parallel_1",-36.0],PARAMETER["Standard_Parallel_2",-38.0],PARAMETER["Latitude_Of_Origin",-37.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994VICGRID94",
    `PROJCS["GDA_1994_VICGRID94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",145.0],PARAMETER["Standard_Parallel_1",-36.0],PARAMETER["Standard_Parallel_2",-38.0],PARAMETER["Latitude_Of_Origin",-37.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994GeoscienceAustraliaLambert",
    `PROJCS["GDA_1994_Geoscience_Australia_Lambert",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",134.0],PARAMETER["Standard_Parallel_1",-18.0],PARAMETER["Standard_Parallel_2",-36.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994BCSG02",
    `PROJCS["GDA_1994_BCSG02",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",-28.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAColombiaOesteOeste",
    `PROJCS["MAGNA_Colombia_Oeste_Oeste",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-80.07750791666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.596200416666666],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAColombiaOeste",
    `PROJCS["MAGNA_Colombia_Oeste",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-77.07750791666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.596200416666666],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAColombiaBogota",
    `PROJCS["MAGNA_Colombia_Bogota",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-74.07750791666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.596200416666666],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAColombiaEste",
    `PROJCS["MAGNA_Colombia_Este",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-71.07750791666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.596200416666666],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAColombiaEsteEste",
    `PROJCS["MAGNA_Colombia_Este_Este",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-68.07750791666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.596200416666666],UNIT["Meter",1.0]]`
  ],
  [
    "Douala1948AEFWest",
    `PROJCS["Douala_1948_AEF_West",GEOGCS["GCS_Douala_1948",DATUM["D_Douala_1948",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",10.5],PARAMETER["Scale_Factor",0.999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958PolandZoneI",
    `PROJCS["Pulkovo_1942_Adj_1958_Poland_Zone_I",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",4637000.0],PARAMETER["False_Northing",5467000.0],PARAMETER["Central_Meridian",21.08333333333333],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",50.625],UNIT["Meter",1.0]]`
  ],
  [
    "PRS1992PhilippinesZoneI",
    `PROJCS["PRS_1992_Philippines_Zone_I",GEOGCS["GCS_PRS_1992",DATUM["D_Philippine_Reference_System_1992",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PRS1992PhilippinesZoneII",
    `PROJCS["PRS_1992_Philippines_Zone_II",GEOGCS["GCS_PRS_1992",DATUM["D_Philippine_Reference_System_1992",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",119.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PRS1992PhilippinesZoneIII",
    `PROJCS["PRS_1992_Philippines_Zone_III",GEOGCS["GCS_PRS_1992",DATUM["D_Philippine_Reference_System_1992",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",121.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PRS1992PhilippinesZoneIV",
    `PROJCS["PRS_1992_Philippines_Zone_IV",GEOGCS["GCS_PRS_1992",DATUM["D_Philippine_Reference_System_1992",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PRS1992PhilippinesZoneV",
    `PROJCS["PRS_1992_Philippines_Zone_V",GEOGCS["GCS_PRS_1992",DATUM["D_Philippine_Reference_System_1992",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",125.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK19FIN",
    `PROJCS["ETRS_1989_ETRS-GK19FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",19.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK20FIN",
    `PROJCS["ETRS_1989_ETRS-GK20FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",20.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK21FIN",
    `PROJCS["ETRS_1989_ETRS-GK21FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK22FIN",
    `PROJCS["ETRS_1989_ETRS-GK22FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",22.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK23FIN",
    `PROJCS["ETRS_1989_ETRS-GK23FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",23.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK24FIN",
    `PROJCS["ETRS_1989_ETRS-GK24FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK25FIN",
    `PROJCS["ETRS_1989_ETRS-GK25FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK26FIN",
    `PROJCS["ETRS_1989_ETRS-GK26FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",26.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK27FIN",
    `PROJCS["ETRS_1989_ETRS-GK27FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK28FIN",
    `PROJCS["ETRS_1989_ETRS-GK28FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",28.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK29FIN",
    `PROJCS["ETRS_1989_ETRS-GK29FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",29.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK30FIN",
    `PROJCS["ETRS_1989_ETRS-GK30FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989ETRS-GK31FIN",
    `PROJCS["ETRS_1989_ETRS-GK31FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Fiji1956UTMZone60S",
    `PROJCS["Fiji_1956_UTM_Zone_60S",GEOGCS["GCS_Fiji_1956",DATUM["D_Fiji_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Fiji1956UTMZone1S",
    `PROJCS["Fiji_1956_UTM_Zone_1S",GEOGCS["GCS_Fiji_1956",DATUM["D_Fiji_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone6",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_6",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKCM18E",
    `PROJCS["Pulkovo_1942_3_Degree_GK_CM_18E",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indian1960UTMZone48N",
    `PROJCS["Indian_1960_UTM_Zone_48N",GEOGCS["GCS_Indian_1960",DATUM["D_Indian_1960",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indian1960UTMZone49N",
    `PROJCS["Indian_1960_UTM_Zone_49N",GEOGCS["GCS_Indian_1960",DATUM["D_Indian_1960",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone6",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_6",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKCM18E",
    `PROJCS["Pulkovo_1995_3_Degree_GK_CM_18E",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSBCEnvironmentAlbers",
    `PROJCS["NAD_1983_CSRS_BC_Environment_Albers",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-126.0],PARAMETER["Standard_Parallel_1",50.0],PARAMETER["Standard_Parallel_2",58.5],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone7N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_7N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone8N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_8N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone9N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_9N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone10N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_10N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone14N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_14N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone15N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_15N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone16N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_16N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983OntarioMNRLambert",
    `PROJCS["NAD_1983_Ontario_MNR_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",930000.0],PARAMETER["False_Northing",6430000.0],PARAMETER["Central_Meridian",-85.0],PARAMETER["Standard_Parallel_1",44.5],PARAMETER["Standard_Parallel_2",53.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSOntarioMNRLambert",
    `PROJCS["NAD_1983_CSRS_Ontario_MNR_Lambert",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",930000.0],PARAMETER["False_Northing",6430000.0],PARAMETER["Central_Meridian",-85.0],PARAMETER["Standard_Parallel_1",44.5],PARAMETER["Standard_Parallel_2",53.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGNC199193LambertNewCaledonia",
    `PROJCS["RGNC_1991_93_Lambert_New_Caledonia",GEOGCS["GCS_RGNC_1991-93",DATUM["D_Reseau_Geodesique_de_Nouvelle_Caledonie_1991-93",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",166.0],PARAMETER["Standard_Parallel_1",-20.66666666666667],PARAMETER["Standard_Parallel_2",-22.33333333333333],PARAMETER["Latitude_Of_Origin",-21.5],UNIT["Meter",1.0]]`
  ],
  [
    "ST87OuveaUTM58S",
    `PROJCS["ST87_Ouvea_UTM_58S",GEOGCS["GCS_ST87_Ouvea",DATUM["D_ST87_Ouvea",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NEA74NoumeaLambert",
    `PROJCS["NEA74_Noumea_Lambert",GEOGCS["GCS_NEA74_Noumea",DATUM["D_NEA74_Noumea",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.66],PARAMETER["False_Northing",1.02],PARAMETER["Central_Meridian",166.44242575],PARAMETER["Standard_Parallel_1",-22.24469175],PARAMETER["Standard_Parallel_2",-22.29469175],PARAMETER["Latitude_Of_Origin",-22.26969175],UNIT["Meter",1.0]]`
  ],
  [
    "NEA74NoumeaLambert2",
    `PROJCS["NEA74_Noumea_Lambert_2",GEOGCS["GCS_NEA74_Noumea",DATUM["D_NEA74_Noumea",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8.313],PARAMETER["False_Northing",-2.354],PARAMETER["Central_Meridian",166.4425],PARAMETER["Standard_Parallel_1",-22.24472222222222],PARAMETER["Standard_Parallel_2",-22.29472222222222],PARAMETER["Latitude_Of_Origin",-22.26972222222222],UNIT["Meter",1.0]]`
  ],
  [
    "KertauRSORSOMalayaChSears1922trunc",
    `PROJCS["Kertau_RSO_RSO_Malaya_ChSears1922trunc",GEOGCS["GCS_Kertau_RSO",DATUM["D_Kertau_RSO",SPHEROID["Everest_Modified_1969",6377295.664,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",323.0257905],PARAMETER["Longitude_Of_Center",102.25],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",323.1301023611111],UNIT["Chain_Sears_1922_Truncated",20.116756]]`
  ],
  [
    "KertauRSORSOMalaya",
    `PROJCS["Kertau_RSO_RSO_Malaya",GEOGCS["GCS_Kertau_RSO",DATUM["D_Kertau_RSO",SPHEROID["Everest_Modified_1969",6377295.664,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",804670.24],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",323.0257905],PARAMETER["Longitude_Of_Center",102.25],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",323.1301023611111],UNIT["Meter",1.0]]`
  ],
  [
    "RGNC1991-93UTMZone57S",
    `PROJCS["RGNC_1991-93_UTM_Zone_57S",GEOGCS["GCS_RGNC_1991-93",DATUM["D_Reseau_Geodesique_de_Nouvelle_Caledonie_1991-93",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGNC1991-93UTMZone58S",
    `PROJCS["RGNC_1991-93_UTM_Zone_58S",GEOGCS["GCS_RGNC_1991-93",DATUM["D_Reseau_Geodesique_de_Nouvelle_Caledonie_1991-93",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGNC1991-93UTMZone59S",
    `PROJCS["RGNC_1991-93_UTM_Zone_59S",GEOGCS["GCS_RGNC_1991-93",DATUM["D_Reseau_Geodesique_de_Nouvelle_Caledonie_1991-93",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGN53MareUTMZone59S",
    `PROJCS["IGN53_Mare_UTM_Zone_59S",GEOGCS["GCS_IGN53_Mare",DATUM["D_IGN53_Mare",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983GreatLakesBasinAlbers",
    `PROJCS["NAD_1983_Great_Lakes_Basin_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-84.455955],PARAMETER["Standard_Parallel_1",42.122774],PARAMETER["Standard_Parallel_2",49.01518],PARAMETER["Latitude_Of_Origin",45.568977],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983GreatLakesandStLawrenceAlbers",
    `PROJCS["NAD_1983_Great_Lakes_and_St_Lawrence_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-83.248627],PARAMETER["Standard_Parallel_1",42.122774],PARAMETER["Standard_Parallel_2",49.01518],PARAMETER["Latitude_Of_Origin",45.568977],UNIT["Meter",1.0]]`
  ],
  [
    "Indian1960TM106NE",
    `PROJCS["Indian_1960_TM_106NE",GEOGCS["GCS_Indian_1960",DATUM["D_Indian_1960",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",106.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006LibyaTM",
    `PROJCS["LGD2006_Libya_TM",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",17.0],PARAMETER["Scale_Factor",0.9965],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone18N",
    `PROJCS["Greenland_1996_UTM_Zone_18N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone19N",
    `PROJCS["Greenland_1996_UTM_Zone_19N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone20N",
    `PROJCS["Greenland_1996_UTM_Zone_20N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone21N",
    `PROJCS["Greenland_1996_UTM_Zone_21N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone22N",
    `PROJCS["Greenland_1996_UTM_Zone_22N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone23N",
    `PROJCS["Greenland_1996_UTM_Zone_23N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone24N",
    `PROJCS["Greenland_1996_UTM_Zone_24N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone25N",
    `PROJCS["Greenland_1996_UTM_Zone_25N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone26N",
    `PROJCS["Greenland_1996_UTM_Zone_26N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone27N",
    `PROJCS["Greenland_1996_UTM_Zone_27N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone28N",
    `PROJCS["Greenland_1996_UTM_Zone_28N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Greenland1996UTMZone29N",
    `PROJCS["Greenland_1996_UTM_Zone_29N",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006LibyaTMZone5",
    `PROJCS["LGD2006_Libya_TM_Zone_5",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006LibyaTMZone6",
    `PROJCS["LGD2006_Libya_TM_Zone_6",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",11.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006LibyaTMZone7",
    `PROJCS["LGD2006_Libya_TM_Zone_7",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",13.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006LibyaTMZone8",
    `PROJCS["LGD2006_Libya_TM_Zone_8",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006LibyaTMZone9",
    `PROJCS["LGD2006_Libya_TM_Zone_9",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",17.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006LibyaTMZone10",
    `PROJCS["LGD2006_Libya_TM_Zone_10",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",19.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006LibyaTMZone11",
    `PROJCS["LGD2006_Libya_TM_Zone_11",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006LibyaTMZone12",
    `PROJCS["LGD2006_Libya_TM_Zone_12",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",23.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006LibyaTMZone13",
    `PROJCS["LGD2006_Libya_TM_Zone_13",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006UTMZone32N",
    `PROJCS["LGD2006_UTM_Zone_32N",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "FD1958Iraq",
    `PROJCS["FD_1958_Iraq",GEOGCS["GCS_FD_1958",DATUM["D_FD_1958",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1166200.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Scale_Factor",0.99878640777],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006UTMZone33N",
    `PROJCS["LGD2006_UTM_Zone_33N",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006UTMZone34N",
    `PROJCS["LGD2006_UTM_Zone_34N",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LGD2006UTMZone35N",
    `PROJCS["LGD2006_UTM_Zone_35N",GEOGCS["GCS_LGD2006",DATUM["D_Libyan_Geodetic_Datum_2006",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984USGSTransantarcticMountains",
    `PROJCS["WGS_1984_USGS_Transantarctic_Mountains",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",162.0],PARAMETER["Standard_Parallel_1",-76.66666666666667],PARAMETER["Standard_Parallel_2",-79.33333333333333],PARAMETER["Latitude_Of_Origin",-78.0],UNIT["Meter",1.0]]`
  ],
  [
    "Guam1963YapIslands",
    `PROJCS["Guam_1963_Yap_Islands",GEOGCS["GCS_Guam_1963",DATUM["D_Guam_1963",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",60000.0],PARAMETER["Central_Meridian",138.1687444444444],PARAMETER["Latitude_Of_Origin",9.546708333333333],UNIT["Meter",1.0]]`
  ],
  [
    "RGPFUTMZone5S",
    `PROJCS["RGPF_UTM_Zone_5S",GEOGCS["GCS_RGPF",DATUM["D_Reseau_Geodesique_de_la_Polynesie_Francaise",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGPFUTMZone6S",
    `PROJCS["RGPF_UTM_Zone_6S",GEOGCS["GCS_RGPF",DATUM["D_Reseau_Geodesique_de_la_Polynesie_Francaise",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGPFUTMZone7S",
    `PROJCS["RGPF_UTM_Zone_7S",GEOGCS["GCS_RGPF",DATUM["D_Reseau_Geodesique_de_la_Polynesie_Francaise",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGPFUTMZone8S",
    `PROJCS["RGPF_UTM_Zone_8S",GEOGCS["GCS_RGPF",DATUM["D_Reseau_Geodesique_de_la_Polynesie_Francaise",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "EstonianCoordinateSystemof1992",
    `PROJCS["Estonian_Coordinate_System_of_1992",GEOGCS["GCS_Estonia_1992",DATUM["D_Estonia_1992",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",6375000.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Standard_Parallel_1",58.0],PARAMETER["Standard_Parallel_2",59.33333333333334],PARAMETER["Latitude_Of_Origin",57.51755393055556],UNIT["Meter",1.0]]`
  ],
  [
    "Estonia1997EstoniaNationalGrid",
    `PROJCS["Estonia_1997_Estonia_National_Grid",GEOGCS["GCS_Estonia_1997",DATUM["D_Estonia_1997",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",6375000.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Standard_Parallel_1",58.0],PARAMETER["Standard_Parallel_2",59.33333333333334],PARAMETER["Latitude_Of_Origin",57.51755393055556],UNIT["Meter",1.0]]`
  ],
  [
    "IGN63HivaOaUTMZone7S",
    `PROJCS["IGN63_Hiva_Oa_UTM_Zone_7S",GEOGCS["GCS_IGN63_Hiva_Oa",DATUM["D_IGN63_Hiva_Oa",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "FatuIva1972UTMZone7S",
    `PROJCS["Fatu_Iva_1972_UTM_Zone_7S",GEOGCS["GCS_Fatu_Iva_1972",DATUM["D_Fatu_Iva_1972",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Tahiti1979UTMZone6S",
    `PROJCS["Tahiti_1979_UTM_Zone_6S",GEOGCS["GCS_Tahiti_1979",DATUM["D_Tahiti_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Moorea1987UTMZone6S",
    `PROJCS["Moorea_1987_UTM_Zone_6S",GEOGCS["GCS_Moorea_1987",DATUM["D_Moorea_1987",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Maupiti1983UTMZone5S",
    `PROJCS["Maupiti_1983_UTM_Zone_5S",GEOGCS["GCS_Maupiti_1983",DATUM["D_Maupiti_1983",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Nakhl-eGhanemUTMZone39N",
    `PROJCS["Nakhl-e_Ghanem_UTM_Zone_39N",GEOGCS["GCS_Nakhl-e_Ghanem",DATUM["D_Nakhl-e_Ghanem",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994NSWLambert",
    `PROJCS["GDA_1994_NSW_Lambert",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",9300000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Standard_Parallel_1",-30.75],PARAMETER["Standard_Parallel_2",-35.75],PARAMETER["Latitude_Of_Origin",-33.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927CaliforniaTealeAlbers",
    `PROJCS["NAD_1927_California_Teale_Albers",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",34.0],PARAMETER["Standard_Parallel_2",40.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CaliforniaTealeAlbers",
    `PROJCS["NAD_1983_California_Teale_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",34.0],PARAMETER["Standard_Parallel_2",40.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNCaliforniaTealeAlbers",
    `PROJCS["NAD_1983_HARN_California_Teale_Albers",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",34.0],PARAMETER["Standard_Parallel_2",40.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CSG1967UTMZone21N",
    `PROJCS["CSG_1967_UTM_Zone_21N",GEOGCS["GCS_CSG_1967",DATUM["D_CSG_1967",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGFG1995UTMZone21N",
    `PROJCS["RGFG_1995_UTM_Zone_21N",GEOGCS["GCS_RGFG_1995",DATUM["D_RGFG_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Katanga1955KatangaTM",
    `PROJCS["Katanga_1955_Katanga_TM",GEOGCS["GCS_Katanga_1955",DATUM["D_Katanga_1955",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",26.0],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",-9.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kasai1953CongoTMZone22",
    `PROJCS["Kasai_1953_Congo_TM_Zone_22",GEOGCS["GCS_Kasai_1953",DATUM["D_Kasai_1953",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",22.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kasai1953CongoTMZone24",
    `PROJCS["Kasai_1953_Congo_TM_Zone_24",GEOGCS["GCS_Kasai_1953",DATUM["D_Kasai_1953",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGC1962CongoTMZone12",
    `PROJCS["IGC_1962_Congo_TM_Zone_12",GEOGCS["GCS_IGC_1962_6th_Parallel_South",DATUM["D_IGC_1962_Arc_of_the_6th_Parallel_South",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGC1962CongoTMZone14",
    `PROJCS["IGC_1962_Congo_TM_Zone_14",GEOGCS["GCS_IGC_1962_6th_Parallel_South",DATUM["D_IGC_1962_Arc_of_the_6th_Parallel_South",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",14.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGC1962CongoTMZone16",
    `PROJCS["IGC_1962_Congo_TM_Zone_16",GEOGCS["GCS_IGC_1962_6th_Parallel_South",DATUM["D_IGC_1962_Arc_of_the_6th_Parallel_South",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",16.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGC1962CongoTMZone18",
    `PROJCS["IGC_1962_Congo_TM_Zone_18",GEOGCS["GCS_IGC_1962_6th_Parallel_South",DATUM["D_IGC_1962_Arc_of_the_6th_Parallel_South",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGC1962CongoTMZone20",
    `PROJCS["IGC_1962_Congo_TM_Zone_20",GEOGCS["GCS_IGC_1962_6th_Parallel_South",DATUM["D_IGC_1962_Arc_of_the_6th_Parallel_South",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",20.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGC1962CongoTMZone22",
    `PROJCS["IGC_1962_Congo_TM_Zone_22",GEOGCS["GCS_IGC_1962_6th_Parallel_South",DATUM["D_IGC_1962_Arc_of_the_6th_Parallel_South",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",22.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGC1962CongoTMZone24",
    `PROJCS["IGC_1962_Congo_TM_Zone_24",GEOGCS["GCS_IGC_1962_6th_Parallel_South",DATUM["D_IGC_1962_Arc_of_the_6th_Parallel_South",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGC1962CongoTMZone26",
    `PROJCS["IGC_1962_Congo_TM_Zone_26",GEOGCS["GCS_IGC_1962_6th_Parallel_South",DATUM["D_IGC_1962_Arc_of_the_6th_Parallel_South",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",26.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGC1962CongoTMZone28",
    `PROJCS["IGC_1962_Congo_TM_Zone_28",GEOGCS["GCS_IGC_1962_6th_Parallel_South",DATUM["D_IGC_1962_Arc_of_the_6th_Parallel_South",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",28.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGC1962CongoTMZone30",
    `PROJCS["IGC_1962_Congo_TM_Zone_30",GEOGCS["GCS_IGC_1962_6th_Parallel_South",DATUM["D_IGC_1962_Arc_of_the_6th_Parallel_South",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958GUGiK-80",
    `PROJCS["Pulkovo_1942_Adj_1958_GUGiK-80",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",19.16666666666667],PARAMETER["Scale_Factor",0.9997143],PARAMETER["Latitude_Of_Origin",52.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone5",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_5",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone6",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_6",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone7",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_7",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone8",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_8",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958GKZone3",
    `PROJCS["Pulkovo_1942_Adj_1958_GK_Zone_3",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958GKZone4",
    `PROJCS["Pulkovo_1942_Adj_1958_GK_Zone_4",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958GKZone5",
    `PROJCS["Pulkovo_1942_Adj_1958_GK_Zone_5",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KerguelenIsland1949UTM42S",
    `PROJCS["Kerguelen_Island_1949_UTM_42S",GEOGCS["GCS_Kerguelen_Island_1949",DATUM["D_Kerguelen_Island_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LePouce1934MauritiusGrid",
    `PROJCS["Le_Pouce_1934_Mauritius_Grid",GEOGCS["GCS_Le_Pouce_1934",DATUM["D_Le_Pouce_1934",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",57.52182777777778],PARAMETER["Standard_Parallel_1",-20.19506944444445],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-20.19506944444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983AlaskaAlbers",
    `PROJCS["NAD_1983_Alaska_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGCB1955CongoTMZone12",
    `PROJCS["IGCB_1955_Congo_TM_Zone_12",GEOGCS["GCS_IGCB_1955",DATUM["D_Institut_Geographique_du_Congo_Belge_1955",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGCB1955CongoTMZone14",
    `PROJCS["IGCB_1955_Congo_TM_Zone_14",GEOGCS["GCS_IGCB_1955",DATUM["D_Institut_Geographique_du_Congo_Belge_1955",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",14.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGCB1955CongoTMZone16",
    `PROJCS["IGCB_1955_Congo_TM_Zone_16",GEOGCS["GCS_IGCB_1955",DATUM["D_Institut_Geographique_du_Congo_Belge_1955",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",16.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGCB1955UTMZone33S",
    `PROJCS["IGCB_1955_UTM_Zone_33S",GEOGCS["GCS_IGCB_1955",DATUM["D_Institut_Geographique_du_Congo_Belge_1955",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Mauritania1999UTMZone28N",
    `PROJCS["Mauritania_1999_UTM_Zone_28N",GEOGCS["GCS_Mauritania_1999",DATUM["D_Mauritania_1999",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Mauritania1999UTMZone29N",
    `PROJCS["Mauritania_1999_UTM_Zone_29N",GEOGCS["GCS_Mauritania_1999",DATUM["D_Mauritania_1999",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Mauritania1999UTMZone30N",
    `PROJCS["Mauritania_1999_UTM_Zone_30N",GEOGCS["GCS_Mauritania_1999",DATUM["D_Mauritania_1999",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LKS1994LithuaniaTM",
    `PROJCS["LKS_1994_Lithuania_TM",GEOGCS["GCS_LKS_1994",DATUM["D_Lithuania_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatisticsCanadaLambert",
    `PROJCS["NAD_1983_Statistics_Canada_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6200000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-91.86666666666666],PARAMETER["Standard_Parallel_1",49.0],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",63.390675],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSStatisticsCanadaLambert",
    `PROJCS["NAD_1983_CSRS_Statistics_Canada_Lambert",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6200000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-91.86666666666666],PARAMETER["Standard_Parallel_1",49.0],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",63.390675],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63ZoneC0",
    `PROJCS["Pulkovo_1942_CS63_Zone_C0",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.95],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.1],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63ZoneC1",
    `PROJCS["Pulkovo_1942_CS63_Zone_C1",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",1250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.95],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.1],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63ZoneC2",
    `PROJCS["Pulkovo_1942_CS63_Zone_C2",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.95],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.1],UNIT["Meter",1.0]]`
  ],
  [
    "MhastOnshoreUTMZone32S",
    `PROJCS["Mhast_Onshore_UTM_Zone_32S",GEOGCS["GCS_Mhast_Onshore",DATUM["D_Mhast_Onshore",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MhastOffshoreUTMZone32S",
    `PROJCS["Mhast_Offshore_UTM_Zone_32S",GEOGCS["GCS_Mhast_Offshore",DATUM["D_Mhast_Offshore",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "EgyptGulfofSuezS-650TLRedBelt",
    `PROJCS["Egypt_Gulf_of_Suez_S-650_TL_Red_Belt",GEOGCS["GCS_Egypt_Gulf_of_Suez_S-650_TL",DATUM["D_Egypt_Gulf_of_Suez_S-650_TL",SPHEROID["Helmert_1906",6378200.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",615000.0],PARAMETER["False_Northing",810000.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "GrandCayman1959UTMZone17N",
    `PROJCS["Grand_Cayman_1959_UTM_Zone_17N",GEOGCS["GCS_Grand_Cayman_1959",DATUM["D_Grand_Cayman_1959",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LittleCayman1961UTMZone17N",
    `PROJCS["Little_Cayman_1961_UTM_Zone_17N",GEOGCS["GCS_Little_Cayman_1961",DATUM["D_Little_Cayman_1961",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNorthCarolinaFIPS3200",
    `PROJCS["NAD_1983_HARN_StatePlane_North_Carolina_FIPS_3200",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609601.2192024384],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNorthCarolinaFIPS3200Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_North_Carolina_FIPS_3200_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneSouthCarolinaFIPS3900",
    `PROJCS["NAD_1983_HARN_StatePlane_South_Carolina_FIPS_3900",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609600.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneSouthCarolinaFIPS3900FeetIntl",
    `PROJCS["NAD_1983_HARN_StatePlane_South_Carolina_FIPS_3900_Feet_Intl",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNStatePlanePennsylvaniaNorthFIPS3701",
    `PROJCS["NAD_1983_HARN_StatePlane_Pennsylvania_North_FIPS_3701",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlanePennsylvaniaNorthFIPS3701Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Pennsylvania_North_FIPS_3701_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlanePennsylvaniaSouthFIPS3702",
    `PROJCS["NAD_1983_HARN_StatePlane_Pennsylvania_South_FIPS_3702",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlanePennsylvaniaSouthFIPS3702Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Pennsylvania_South_FIPS_3702_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "HongKong1963GridSystem",
    `PROJCS["Hong_Kong_1963_Grid_System",GEOGCS["GCS_Hong_Kong_1963",DATUM["D_Hong_Kong_1963",SPHEROID["Clarke_1858",6378293.645208759,294.260676369]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",132033.92],PARAMETER["False_Northing",62565.96],PARAMETER["Central_Meridian",114.1785555555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.31213333333334],UNIT["Foot_Clarke",0.3047972654]]`
  ],
  [
    "IGNAstro1960UTMZone28N",
    `PROJCS["IGN_Astro_1960_UTM_Zone_28N",GEOGCS["GCS_IGN_Astro_1960",DATUM["D_IGN_Astro_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGNAstro1960UTMZone29N",
    `PROJCS["IGN_Astro_1960_UTM_Zone_29N",GEOGCS["GCS_IGN_Astro_1960",DATUM["D_IGN_Astro_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGNAstro1960UTMZone30N",
    `PROJCS["IGN_Astro_1960_UTM_Zone_30N",GEOGCS["GCS_IGN_Astro_1960",DATUM["D_IGN_Astro_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone59N",
    `PROJCS["NAD_1927_UTM_Zone_59N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone60N",
    `PROJCS["NAD_1927_UTM_Zone_60N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone59N",
    `PROJCS["NAD_1983_UTM_Zone_59N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone60N",
    `PROJCS["NAD_1983_UTM_Zone_60N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "FD1954UTMZone29N",
    `PROJCS["FD_1954_UTM_Zone_29N",GEOGCS["GCS_FD_1954",DATUM["D_Faroe_Datum_1954",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000MRSOPeninsularMalaysia",
    `PROJCS["GDM_2000_MRSO_Peninsular_Malaysia",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",804671.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",323.0257964666666],PARAMETER["Longitude_Of_Center",102.25],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",-36.86989764584402],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000BRSOEastMalaysia",
    `PROJCS["GDM_2000_BRSO_East_Malaysia",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",53.31580995],PARAMETER["Longitude_Of_Center",115.0],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",53.13010235415598],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000StateCassiniJohor",
    `PROJCS["GDM_2000_State_Cassini_Johor",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",-14810.562],PARAMETER["False_Northing",8758.32],PARAMETER["Central_Meridian",103.4279362361111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",2.121679744444445],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000StateCassiniNegeriSembilanandMelaka",
    `PROJCS["GDM_2000_State_Cassini_Negeri_Sembilan_and_Melaka",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",3673.785],PARAMETER["False_Northing",-4240.573],PARAMETER["Central_Meridian",101.9749050416667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",2.682347636111111],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000StateCassiniPahang",
    `PROJCS["GDM_2000_State_Cassini_Pahang",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",-7368.228],PARAMETER["False_Northing",6485.858],PARAMETER["Central_Meridian",102.3682989833333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",3.769388088888889],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000StateCassiniSelangor",
    `PROJCS["GDM_2000_State_Cassini_Selangor",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",-34836.161],PARAMETER["False_Northing",56464.049],PARAMETER["Central_Meridian",101.3891079138889],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",3.68464905],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000StateCassiniTerengganu",
    `PROJCS["GDM_2000_State_Cassini_Terengganu",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",19594.245],PARAMETER["False_Northing",3371.895],PARAMETER["Central_Meridian",103.070275625],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.9762852],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000StateCassiniPulauPinangandSeberangPerai",
    `PROJCS["GDM_2000_State_Cassini_Pulau_Pinang_and_Seberang_Perai",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",-23.414],PARAMETER["False_Northing",62.283],PARAMETER["Central_Meridian",100.3443769638889],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",5.421517541666667],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000StateCassiniPerlis",
    `PROJCS["GDM_2000_State_Cassini_Perlis",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",100.6363711111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",5.964672713888889],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000StateCassiniPerak",
    `PROJCS["GDM_2000_State_Cassini_Perak",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",-1.769],PARAMETER["False_Northing",133454.779],PARAMETER["Central_Meridian",100.8154105861111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.859063022222222],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2000StateCassiniKelantan",
    `PROJCS["GDM_2000_State_Cassini_Kelantan",GEOGCS["GCS_GDM_2000",DATUM["D_GDM_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",13227.851],PARAMETER["False_Northing",8739.894],PARAMETER["Central_Meridian",102.2952416694444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",5.972543658333334],UNIT["Meter",1.0]]`
  ],
  [
    "KKJFinlandZone0",
    `PROJCS["KKJ_Finland_Zone_0",GEOGCS["GCS_KKJ",DATUM["D_KKJ",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KKJFinlandZone5",
    `PROJCS["KKJ_Finland_Zone_5",GEOGCS["GCS_KKJ",DATUM["D_KKJ",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CaspianSeaMercator",
    `PROJCS["Pulkovo_1942_Caspian_Sea_Mercator",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Standard_Parallel_1",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19423DegreeGKZone60",
    `PROJCS["Pulkovo_1942_3_Degree_GK_Zone_60",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",60500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo19953DegreeGKZone60",
    `PROJCS["Pulkovo_1995_3_Degree_GK_Zone_60",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",60500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Karbala1979PolserviceUTMZone37N",
    `PROJCS["Karbala_1979_Polservice_UTM_Zone_37N",GEOGCS["GCS_Karbala_1979_Polservice",DATUM["D_Karbala_1979_Polservice",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Karbala1979PolserviceUTMZone38N",
    `PROJCS["Karbala_1979_Polservice_UTM_Zone_38N",GEOGCS["GCS_Karbala_1979_Polservice",DATUM["D_Karbala_1979_Polservice",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Karbala1979PolserviceUTMZone39N",
    `PROJCS["Karbala_1979_Polservice_UTM_Zone_39N",GEOGCS["GCS_Karbala_1979_Polservice",DATUM["D_Karbala_1979_Polservice",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Nahrwan1934IraqZone",
    `PROJCS["Nahrwan_1934_Iraq_Zone",GEOGCS["GCS_Nahrwan_1934",DATUM["D_Nahrwan_1934",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1166200.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Scale_Factor",0.9987864078],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984WorldMercator",
    `PROJCS["WGS_1984_World_Mercator",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "PD/83GKZone3",
    `PROJCS["PD/83_GK_Zone_3",GEOGCS["GCS_PD/83",DATUM["D_Potsdam_1983",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PD/83GKZone4",
    `PROJCS["PD/83_GK_Zone_4",GEOGCS["GCS_PD/83",DATUM["D_Potsdam_1983",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RD/83GKZone4",
    `PROJCS["RD/83_GK_Zone_4",GEOGCS["GCS_RD/83",DATUM["D_Rauenberg_1983",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RD/83GKZone5",
    `PROJCS["RD/83_GK_Zone_5",GEOGCS["GCS_RD/83",DATUM["D_Rauenberg_1983",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD198310TMAEPForest",
    `PROJCS["NAD_1983_10TM_AEP_Forest",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD198310TMAEPResource",
    `PROJCS["NAD_1983_10TM_AEP_Resource",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRS10TMAEPForest",
    `PROJCS["NAD_1983_CSRS_10TM_AEP_Forest",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRS10TMAEPResource",
    `PROJCS["NAD_1983_CSRS_10TM_AEP_Resource",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNorthCarolinaFIPS3200Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_North_Carolina_FIPS_3200_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "VN2000UTMZone48N",
    `PROJCS["VN_2000_UTM_Zone_48N",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN2000UTMZone49N",
    `PROJCS["VN_2000_UTM_Zone_49N",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "HongKong1963GridSystem",
    `PROJCS["Hong_Kong_1963_Grid_System",GEOGCS["GCS_Hong_Kong_1963",DATUM["D_Hong_Kong_1963",SPHEROID["Clarke_1858",6378293.645208759,294.260676369]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",132033.92],PARAMETER["False_Northing",62565.96],PARAMETER["Central_Meridian",114.1785555555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.31213333333334],UNIT["Foot_Clarke",0.3047972654]]`
  ],
  [
    "NSIDCEASEGridNorth",
    `PROJCS["NSIDC_EASE_Grid_North",GEOGCS["GCS_Sphere_International_1924_Authalic",DATUM["D_Sphere_International_1924_Authalic",SPHEROID["Sphere_International_1924_Authalic",6371228.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "NSIDCEASEGridSouth",
    `PROJCS["NSIDC_EASE_Grid_South",GEOGCS["GCS_Sphere_International_1924_Authalic",DATUM["D_Sphere_International_1924_Authalic",SPHEROID["Sphere_International_1924_Authalic",6371228.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "NSIDCEASEGridGlobal",
    `PROJCS["NSIDC_EASE_Grid_Global",GEOGCS["GCS_Sphere_International_1924_Authalic",DATUM["D_Sphere_International_1924_Authalic",SPHEROID["Sphere_International_1924_Authalic",6371228.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cylindrical_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NSIDCSeaIcePolarStereographicNorth",
    `PROJCS["NSIDC_Sea_Ice_Polar_Stereographic_North",GEOGCS["GCS_Hughes_1980",DATUM["D_Hughes_1980",SPHEROID["Hughes_1980",6378273.0,298.279411123064]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_North_Pole"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Standard_Parallel_1",70.0],UNIT["Meter",1.0]]`
  ],
  [
    "NSIDCSeaIcePolarStereographicSouth",
    `PROJCS["NSIDC_Sea_Ice_Polar_Stereographic_South",GEOGCS["GCS_Hughes_1980",DATUM["D_Hughes_1980",SPHEROID["Hughes_1980",6378273.0,298.279411123064]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_South_Pole"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-70.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984NSIDCSeaIcePolarStereographicNorth",
    `PROJCS["WGS_1984_NSIDC_Sea_Ice_Polar_Stereographic_North",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_North_Pole"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Standard_Parallel_1",70.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SVY21SingaporeTM",
    `PROJCS["SVY21_Singapore_TM",GEOGCS["GCS_SVY21",DATUM["D_SVY21",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",28001.642],PARAMETER["False_Northing",38744.572],PARAMETER["Central_Meridian",103.8333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",1.366666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BESouthChinaSeaLambert",
    `PROJCS["WGS_1972_BE_South_China_Sea_Lambert",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Standard_Parallel_1",18.0],PARAMETER["Standard_Parallel_2",24.0],PARAMETER["Latitude_Of_Origin",21.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989AustriaLambert",
    `PROJCS["ETRS_1989_Austria_Lambert",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",13.33333333333333],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",47.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneIowaNorthFIPS1401Feet",
    `PROJCS["NAD_1983_StatePlane_Iowa_North_FIPS_1401_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIowaSouthFIPS1402Feet",
    `PROJCS["NAD_1983_StatePlane_Iowa_South_FIPS_1402_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneKansasNorthFIPS1501Feet",
    `PROJCS["NAD_1983_StatePlane_Kansas_North_FIPS_1501_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneKansasSouthFIPS1502Feet",
    `PROJCS["NAD_1983_StatePlane_Kansas_South_FIPS_1502_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNevadaEastFIPS2701Feet",
    `PROJCS["NAD_1983_StatePlane_Nevada_East_FIPS_2701_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",26246666.66666666],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNevadaCentralFIPS2702Feet",
    `PROJCS["NAD_1983_StatePlane_Nevada_Central_FIPS_2702_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",19685000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNevadaWestFIPS2703Feet",
    `PROJCS["NAD_1983_StatePlane_Nevada_West_FIPS_2703_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewJerseyFIPS2900Feet",
    `PROJCS["NAD_1983_StatePlane_New_Jersey_FIPS_2900_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneIowaNorthFIPS1401Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Iowa_North_FIPS_1401_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneIowaSouthFIPS1402Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Iowa_South_FIPS_1402_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneKansasNorthFIPS1501Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Kansas_North_FIPS_1501_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneKansasSouthFIPS1502Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Kansas_South_FIPS_1502_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNevadaEastFIPS2701Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Nevada_East_FIPS_2701_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",26246666.66666666],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNevadaCentralFIPS2702Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Nevada_Central_FIPS_2702_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",19685000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNevadaWestFIPS2703Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Nevada_West_FIPS_2703_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNewJerseyFIPS2900Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Jersey_FIPS_2900_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneArkansasNorthFIPS0301Feet",
    `PROJCS["NAD_1983_StatePlane_Arkansas_North_FIPS_0301_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneArkansasSouthFIPS0302Feet",
    `PROJCS["NAD_1983_StatePlane_Arkansas_South_FIPS_0302_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIllinoisEastFIPS1201Feet",
    `PROJCS["NAD_1983_StatePlane_Illinois_East_FIPS_1201_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIllinoisWestFIPS1202Feet",
    `PROJCS["NAD_1983_StatePlane_Illinois_West_FIPS_1202_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewHampshireFIPS2800Feet",
    `PROJCS["NAD_1983_StatePlane_New_Hampshire_FIPS_2800_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneRhodeIslandFIPS3800Feet",
    `PROJCS["NAD_1983_StatePlane_Rhode_Island_FIPS_3800_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "PDO1993UTMZone39N",
    `PROJCS["PDO_1993_UTM_Zone_39N",GEOGCS["GCS_PDO_1993",DATUM["D_PDO_1993",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PDO1993UTMZone40N",
    `PROJCS["PDO_1993_UTM_Zone_40N",GEOGCS["GCS_PDO_1993",DATUM["D_PDO_1993",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArkansasNorthFIPS0301Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Arkansas_North_FIPS_0301_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneArkansasSouthFIPS0302Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Arkansas_South_FIPS_0302_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneIllinoisEastFIPS1201Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Illinois_East_FIPS_1201_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneIllinoisWestFIPS1202Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Illinois_West_FIPS_1202_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNewHampshireFIPS2800Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Hampshire_FIPS_2800_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneRhodeIslandFIPS3800Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Rhode_Island_FIPS_3800_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "BelgeLambert2005",
    `PROJCS["Belge_Lambert_2005",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150328.0],PARAMETER["False_Northing",166262.0],PARAMETER["Central_Meridian",4.359215833333333],PARAMETER["Standard_Parallel_1",49.83333333333334],PARAMETER["Standard_Parallel_2",51.16666666666666],PARAMETER["Latitude_Of_Origin",50.797815],UNIT["Meter",1.0]]`
  ],
  [
    "JAD2001JamaicaGrid",
    `PROJCS["JAD_2001_Jamaica_Grid",GEOGCS["GCS_JAD_2001",DATUM["D_Jamaica_2001",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",750000.0],PARAMETER["False_Northing",650000.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "JAD2001UTMZone17N",
    `PROJCS["JAD_2001_UTM_Zone_17N",GEOGCS["GCS_JAD_2001",DATUM["D_Jamaica_2001",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JAD2001UTMZone18N",
    `PROJCS["JAD_2001_UTM_Zone_18N",GEOGCS["GCS_JAD_2001",DATUM["D_Jamaica_2001",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneLouisianaNorthFIPS1701Feet",
    `PROJCS["NAD_1983_StatePlane_Louisiana_North_FIPS_1701_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneLouisianaSouthFIPS1702Feet",
    `PROJCS["NAD_1983_StatePlane_Louisiana_South_FIPS_1702_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneLouisianaOffshoreFIPS1703Feet",
    `PROJCS["NAD_1983_StatePlane_Louisiana_Offshore_FIPS_1703_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneSouthDakotaNorthFIPS4001Feet",
    `PROJCS["NAD_1983_StatePlane_South_Dakota_North_FIPS_4001_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneSouthDakotaSouthFIPS4002Feet",
    `PROJCS["NAD_1983_StatePlane_South_Dakota_South_FIPS_4002_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneLouisianaNorthFIPS1701Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Louisiana_North_FIPS_1701_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneLouisianaSouthFIPS1702Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Louisiana_South_FIPS_1702_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneSouthDakotaNorthFIPS4001Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_South_Dakota_North_FIPS_4001_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneSouthDakotaSouthFIPS4002Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_South_Dakota_South_FIPS_4002_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Fiji1986FijiMapGrid",
    `PROJCS["Fiji_1986_Fiji_Map_Grid",GEOGCS["GCS_Fiji_1986",DATUM["D_Fiji_1986",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",178.75],PARAMETER["Scale_Factor",0.99985],PARAMETER["Latitude_Of_Origin",-17.0],UNIT["Meter",1.0]]`
  ],
  [
    "Dabola1981UTMZone28N",
    `PROJCS["Dabola_1981_UTM_Zone_28N",GEOGCS["GCS_Dabola_1981",DATUM["D_Dabola_1981",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Dabola1981UTMZone29N",
    `PROJCS["Dabola_1981_UTM_Zone_29N",GEOGCS["GCS_Dabola_1981",DATUM["D_Dabola_1981",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983Maine2000CentralZone",
    `PROJCS["NAD_1983_Maine_2000_Central_Zone",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.125],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNMaine2000CentralZone",
    `PROJCS["NAD_1983_HARN_Maine_2000_Central_Zone",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.125],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlabamaEastFIPS0101",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alabama_East_FIPS_0101",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlabamaWestFIPS0102",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alabama_West_FIPS_0102",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007AlaskaAlbers",
    `PROJCS["NAD_1983_NSRS2007_Alaska_Albers",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlaska1FIPS5001",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alaska_1_FIPS_5001",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",-36.86989764583333],PARAMETER["Longitude_Of_Center",-133.6666666666667],PARAMETER["Latitude_Of_Center",57.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlaska2FIPS5002",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alaska_2_FIPS_5002",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlaska3FIPS5003",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alaska_3_FIPS_5003",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-146.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlaska4FIPS5004",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alaska_4_FIPS_5004",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlaska5FIPS5005",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alaska_5_FIPS_5005",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlaska6FIPS5006",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alaska_6_FIPS_5006",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlaska7FIPS5007",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alaska_7_FIPS_5007",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-162.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlaska8FIPS5008",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alaska_8_FIPS_5008",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-166.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlaska9FIPS5009",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alaska_9_FIPS_5009",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-170.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneAlaska10FIPS5010",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Alaska_10_FIPS_5010",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-176.0],PARAMETER["Standard_Parallel_1",51.83333333333334],PARAMETER["Standard_Parallel_2",53.83333333333334],PARAMETER["Latitude_Of_Origin",51.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneArizonaCentralFIPS0202",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Arizona_Central_FIPS_0202",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneArizonaCentralFIPS0202FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Arizona_Central_FIPS_0202_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneArizonaEastFIPS0201",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Arizona_East_FIPS_0201",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneArizonaEastFIPS0201FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Arizona_East_FIPS_0201_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneArizonaWestFIPS0203",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Arizona_West_FIPS_0203",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneArizonaWestFIPS0203FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Arizona_West_FIPS_0203_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneArkansasNorthFIPS0301",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Arkansas_North_FIPS_0301",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneArkansasNorthFIPS0301FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Arkansas_North_FIPS_0301_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneArkansasSouthFIPS0302",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Arkansas_South_FIPS_0302",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneArkansasSouthFIPS0302FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Arkansas_South_FIPS_0302_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007CaliforniaTealeAlbers",
    `PROJCS["NAD_1983_NSRS2007_California_Teale_Albers",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",34.0],PARAMETER["Standard_Parallel_2",40.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaIFIPS0401",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_I_FIPS_0401",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaIFIPS0401FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_I_FIPS_0401_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaIIFIPS0402",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_II_FIPS_0402",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaIIFIPS0402FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_II_FIPS_0402_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaIIIFIPS0403",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_III_FIPS_0403",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaIIIFIPS0403FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_III_FIPS_0403_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaIVFIPS0404",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_IV_FIPS_0404",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaIVFIPS0404FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_IV_FIPS_0404_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaVFIPS0405",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_V_FIPS_0405",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaVFIPS0405FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_V_FIPS_0405_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaVIFIPS0406",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_VI_FIPS_0406",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneCaliforniaVIFIPS0406FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_California_VI_FIPS_0406_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneColoradoCentralFIPS0502",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Colorado_Central_FIPS_0502",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneColoradoCentralFIPS0502FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Colorado_Central_FIPS_0502_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneColoradoNorthFIPS0501",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Colorado_North_FIPS_0501",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneColoradoNorthFIPS0501FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Colorado_North_FIPS_0501_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneColoradoSouthFIPS0503",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Colorado_South_FIPS_0503",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneColoradoSouthFIPS0503FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Colorado_South_FIPS_0503_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneConnecticutFIPS0600",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Connecticut_FIPS_0600",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",304800.6096],PARAMETER["False_Northing",152400.3048],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneConnecticutFIPS0600FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Connecticut_FIPS_0600_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",999999.999996],PARAMETER["False_Northing",499999.999998],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneDelawareFIPS0700",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Delaware_FIPS_0700",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneDelawareFIPS0700FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Delaware_FIPS_0700_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneFloridaEastFIPS0901",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Florida_East_FIPS_0901",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneFloridaEastFIPS0901FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Florida_East_FIPS_0901_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007FloridaGDLAlbers",
    `PROJCS["NAD_1983_NSRS2007_Florida_GDL_Albers",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Standard_Parallel_1",24.0],PARAMETER["Standard_Parallel_2",31.5],PARAMETER["Latitude_Of_Origin",24.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneFloridaNorthFIPS0903",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Florida_North_FIPS_0903",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneFloridaNorthFIPS0903FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Florida_North_FIPS_0903_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneFloridaWestFIPS0902",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Florida_West_FIPS_0902",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneFloridaWestFIPS0902FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Florida_West_FIPS_0902_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneGeorgiaEastFIPS1001",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Georgia_East_FIPS_1001",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneGeorgiaEastFIPS1001FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Georgia_East_FIPS_1001_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneGeorgiaWestFIPS1002",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Georgia_West_FIPS_1002",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneGeorgiaWestFIPS1002FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Georgia_West_FIPS_1002_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIdahoCentralFIPS1102",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Idaho_Central_FIPS_1102",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIdahoCentralFIPS1102FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Idaho_Central_FIPS_1102_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIdahoEastFIPS1101",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Idaho_East_FIPS_1101",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIdahoEastFIPS1101FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Idaho_East_FIPS_1101_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIdahoWestFIPS1103",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Idaho_West_FIPS_1103",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIdahoWestFIPS1103FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Idaho_West_FIPS_1103_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIllinoisEastFIPS1201",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Illinois_East_FIPS_1201",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIllinoisEastFIPS1201FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Illinois_East_FIPS_1201_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIllinoisWestFIPS1202",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Illinois_West_FIPS_1202",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIllinoisWestFIPS1202FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Illinois_West_FIPS_1202_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIndianaEastFIPS1301",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Indiana_East_FIPS_1301",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIndianaEastFIPS1301FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Indiana_East_FIPS_1301_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIndianaWestFIPS1302",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Indiana_West_FIPS_1302",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIndianaWestFIPS1302FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Indiana_West_FIPS_1302_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIowaNorthFIPS1401",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Iowa_North_FIPS_1401",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIowaNorthFIPS1401FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Iowa_North_FIPS_1401_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIowaSouthFIPS1402",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Iowa_South_FIPS_1402",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneIowaSouthFIPS1402FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Iowa_South_FIPS_1402_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneKansasNorthFIPS1501",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Kansas_North_FIPS_1501",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneKansasNorthFIPS1501FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Kansas_North_FIPS_1501_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneKansasSouthFIPS1502",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Kansas_South_FIPS_1502",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneKansasSouthFIPS1502FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Kansas_South_FIPS_1502_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneKentuckyNorthFIPS1601",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Kentucky_North_FIPS_1601",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneKentuckyNorthFIPS1601FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Kentucky_North_FIPS_1601_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneKentuckyFIPS1600",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Kentucky_FIPS_1600",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneKentuckyFIPS1600FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Kentucky_FIPS_1600_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneKentuckySouthFIPS1602",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Kentucky_South_FIPS_1602",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneKentuckySouthFIPS1602FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Kentucky_South_FIPS_1602_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneLouisianaNorthFIPS1701",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Louisiana_North_FIPS_1701",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneLouisianaNorthFIPS1701FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Louisiana_North_FIPS_1701_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneLouisianaSouthFIPS1702",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Louisiana_South_FIPS_1702",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneLouisianaSouthFIPS1702FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Louisiana_South_FIPS_1702_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007Maine2000CentralZone",
    `PROJCS["NAD_1983_NSRS2007_Maine_2000_Central_Zone",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.125],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007Maine2000EastZone",
    `PROJCS["NAD_1983_NSRS2007_Maine_2000_East_Zone",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.875],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007Maine2000WestZone",
    `PROJCS["NAD_1983_NSRS2007_Maine_2000_West_Zone",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.375],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMaineEastFIPS1801",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Maine_East_FIPS_1801",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMaineWestFIPS1802",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Maine_West_FIPS_1802",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMarylandFIPS1900",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Maryland_FIPS_1900",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneUtahNorthFIPS4301Feet",
    `PROJCS["NAD_1983_StatePlane_Utah_North_FIPS_4301_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "OldHawaiianStatePlaneHawaii1FIPS5101",
    `PROJCS["Old_Hawaiian_StatePlane_Hawaii_1_FIPS_5101",GEOGCS["GCS_Old_Hawaiian",DATUM["D_Old_Hawaiian",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-155.5],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",18.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "OldHawaiianStatePlaneHawaii2FIPS5102",
    `PROJCS["Old_Hawaiian_StatePlane_Hawaii_2_FIPS_5102",GEOGCS["GCS_Old_Hawaiian",DATUM["D_Old_Hawaiian",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-156.6666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",20.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "OldHawaiianStatePlaneHawaii3FIPS5103",
    `PROJCS["Old_Hawaiian_StatePlane_Hawaii_3_FIPS_5103",GEOGCS["GCS_Old_Hawaiian",DATUM["D_Old_Hawaiian",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "OldHawaiianStatePlaneHawaii4FIPS5104",
    `PROJCS["Old_Hawaiian_StatePlane_Hawaii_4_FIPS_5104",GEOGCS["GCS_Old_Hawaiian",DATUM["D_Old_Hawaiian",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.5],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "OldHawaiianStatePlaneHawaii5FIPS5105",
    `PROJCS["Old_Hawaiian_StatePlane_Hawaii_5_FIPS_5105",GEOGCS["GCS_Old_Hawaiian",DATUM["D_Old_Hawaiian",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.1666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",21.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneUtahCentralFIPS4302Feet",
    `PROJCS["NAD_1983_StatePlane_Utah_Central_FIPS_4302_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneUtahSouthFIPS4303Feet",
    `PROJCS["NAD_1983_StatePlane_Utah_South_FIPS_4303_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahNorthFIPS4301Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_North_FIPS_4301_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahCentralFIPS4302Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_Central_FIPS_4302_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahSouthFIPS4303Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_South_FIPS_4303_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "WGS1984NorthPoleLAEABeringSea",
    `PROJCS["WGS_1984_North_Pole_LAEA_Bering_Sea",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984NorthPoleLAEAAlaska",
    `PROJCS["WGS_1984_North_Pole_LAEA_Alaska",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984NorthPoleLAEACanada",
    `PROJCS["WGS_1984_North_Pole_LAEA_Canada",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984NorthPoleLAEAAtlantic",
    `PROJCS["WGS_1984_North_Pole_LAEA_Atlantic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-40.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984NorthPoleLAEAEurope",
    `PROJCS["WGS_1984_North_Pole_LAEA_Europe",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984NorthPoleLAEARussia",
    `PROJCS["WGS_1984_North_Pole_LAEA_Russia",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "GDA1994AustraliaAlbers",
    `PROJCS["GDA_1994_Australia_Albers",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Standard_Parallel_1",-18.0],PARAMETER["Standard_Parallel_2",-36.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983YukonAlbers",
    `PROJCS["NAD_1983_Yukon_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-132.5],PARAMETER["Standard_Parallel_1",61.66666666666666],PARAMETER["Standard_Parallel_2",68.0],PARAMETER["Latitude_Of_Origin",59.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSYukonAlbers",
    `PROJCS["NAD_1983_CSRS_Yukon_Albers",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-132.5],PARAMETER["Standard_Parallel_1",61.66666666666666],PARAMETER["Standard_Parallel_2",68.0],PARAMETER["Latitude_Of_Origin",59.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NorthwestTerritoriesLambert",
    `PROJCS["NAD_1983_Northwest_Territories_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.0],PARAMETER["Standard_Parallel_1",62.0],PARAMETER["Standard_Parallel_2",70.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSNorthwestTerritoriesLambert",
    `PROJCS["NAD_1983_CSRS_Northwest_Territories_Lambert",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.0],PARAMETER["Standard_Parallel_1",62.0],PARAMETER["Standard_Parallel_2",70.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMarylandFIPS1900FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Maryland_FIPS_1900_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMassachusettsIslandFIPS2002",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Massachusetts_Island_FIPS_2002",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMassachusettsIslFIPS2002FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Massachusetts_Isl_FIPS_2002_FtUS",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMassachusettsMainlandFIPS2001",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Massachusetts_Mainland_FIPS_2001",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",750000.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMassachusettsMnldFIPS2001FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Massachusetts_Mnld_FIPS_2001_FtUS",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",2460625.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMichiganCentralFIPS2112",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Michigan_Central_FIPS_2112",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMichiganCentralFIPS2112FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Michigan_Central_FIPS_2112_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",19685039.37007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMichiganNorthFIPS2111",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Michigan_North_FIPS_2111",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMichiganNorthFIPS2111FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Michigan_North_FIPS_2111_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",26246719.16010498],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007MichiganGeoRefMeters",
    `PROJCS["NAD_1983_NSRS2007_Michigan_GeoRef_Meters",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",2546731.496],PARAMETER["False_Northing",-4354009.816],PARAMETER["Scale_Factor",0.9996],PARAMETER["Azimuth",337.25556],PARAMETER["Longitude_Of_Center",-86.0],PARAMETER["Latitude_Of_Center",45.30916666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMichiganSouthFIPS2113",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Michigan_South_FIPS_2113",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMichiganSouthFIPS2113FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Michigan_South_FIPS_2113_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",13123359.58005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMinnesotaCentralFIPS2202",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Minnesota_Central_FIPS_2202",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMinnesotaNorthFIPS2201",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Minnesota_North_FIPS_2201",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMinnesotaSouthFIPS2203",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Minnesota_South_FIPS_2203",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMississippiEastFIPS2301",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Mississippi_East_FIPS_2301",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMississippiEastFIPS2301FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Mississippi_East_FIPS_2301_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMississippiWestFIPS2302",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Mississippi_West_FIPS_2302",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMississippiWestFIPS2302FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Mississippi_West_FIPS_2302_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMissouriCentralFIPS2402",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Missouri_Central_FIPS_2402",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMissouriEastFIPS2401",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Missouri_East_FIPS_2401",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMissouriWestFIPS2403",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Missouri_West_FIPS_2403",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",850000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.5],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMontanaFIPS2500",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Montana_FIPS_2500",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMontanaFIPS2500FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Montana_FIPS_2500_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNebraskaFIPS2600",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Nebraska_FIPS_2600",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNevadaCentralFIPS2702",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Nevada_Central_FIPS_2702",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNevadaCentralFIPS2702FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Nevada_Central_FIPS_2702_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",19685000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNevadaEastFIPS2701",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Nevada_East_FIPS_2701",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",8000000.0],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNevadaEastFIPS2701FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Nevada_East_FIPS_2701_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",26246666.66666666],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNevadaWestFIPS2703",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Nevada_West_FIPS_2703",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNevadaWestFIPS2703FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Nevada_West_FIPS_2703_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewHampshireFIPS2800",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_Hampshire_FIPS_2800",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewHampshireFIPS2800FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_Hampshire_FIPS_2800_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewJerseyFIPS2900",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_Jersey_FIPS_2900",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewJerseyFIPS2900FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_Jersey_FIPS_2900_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewMexicoCentralFIPS3002",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_Mexico_Central_FIPS_3002",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewMexicoCentralFIPS3002FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_Mexico_Central_FIPS_3002_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewMexicoEastFIPS3001",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_Mexico_East_FIPS_3001",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewMexicoEastFIPS3001FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_Mexico_East_FIPS_3001_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",541337.5],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewMexicoWestFIPS3003",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_Mexico_West_FIPS_3003",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",830000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewMexicoWestFIPS3003FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_Mexico_West_FIPS_3003_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2723091.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewYorkCentralFIPS3102",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_York_Central_FIPS_3102",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewYorkCentralFIPS3102FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_York_Central_FIPS_3102_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",820208.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewYorkEastFIPS3101",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_York_East_FIPS_3101",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewYorkEastFIPS3101FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_York_East_FIPS_3101_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewYorkLongIslandFIPS3104",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_York_Long_Island_FIPS_3104",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewYorkLongIslFIPS3104FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_York_Long_Isl_FIPS_3104_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewYorkWestFIPS3103",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_York_West_FIPS_3103",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",350000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNewYorkWestFIPS3103FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_New_York_West_FIPS_3103_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1148291.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNorthCarolinaFIPS3200",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_North_Carolina_FIPS_3200",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609601.2192024384],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNorthCarolinaFIPS3200FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_North_Carolina_FIPS_3200_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNorthDakotaNorthFIPS3301",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_North_Dakota_North_FIPS_3301",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNorthDakotaNorthFIPS3301FtI",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_North_Dakota_North_FIPS_3301_FtI",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNorthDakotaSouthFIPS3302",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_North_Dakota_South_FIPS_3302",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNorthDakotaSouthFIPS3302FtI",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_North_Dakota_South_FIPS_3302_FtI",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOhioNorthFIPS3401",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Ohio_North_FIPS_3401",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOhioSouthFIPS3402",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Ohio_South_FIPS_3402",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOklahomaNorthFIPS3501",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Oklahoma_North_FIPS_3501",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOklahomaNorthFIPS3501FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Oklahoma_North_FIPS_3501_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOklahomaSouthFIPS3502",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Oklahoma_South_FIPS_3502",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOklahomaSouthFIPS3502FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Oklahoma_South_FIPS_3502_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007OregonStatewideLambert",
    `PROJCS["NAD_1983_NSRS2007_Oregon_Statewide_Lambert",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007OregonStatewideLambertFtIntl",
    `PROJCS["NAD_1983_NSRS2007_Oregon_Statewide_Lambert_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312335.958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOregonNorthFIPS3601",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Oregon_North_FIPS_3601",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOregonNorthFIPS3601FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Oregon_North_FIPS_3601_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8202099.737532808],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOregonSouthFIPS3602",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Oregon_South_FIPS_3602",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOregonSouthFIPS3602FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Oregon_South_FIPS_3602_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921259.842519685],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlanePennsylvaniaNorthFIPS3701",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Pennsylvania_North_FIPS_3701",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlanePennsylvaniaNorthFIPS3701FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Pennsylvania_North_FIPS_3701_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlanePennsylvaniaSouthFIPS3702",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Pennsylvania_South_FIPS_3702",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlanePennsylvaniaSouthFIPS3702FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Pennsylvania_South_FIPS_3702_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneRhodeIslandFIPS3800",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Rhode_Island_FIPS_3800",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneRhodeIslandFIPS3800FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Rhode_Island_FIPS_3800_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneSouthCarolinaFIPS3900",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_South_Carolina_FIPS_3900",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609600.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneSouthCarolinaFIPS3900FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_South_Carolina_FIPS_3900_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneSouthDakotaNorthFIPS4001",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_South_Dakota_North_FIPS_4001",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneSouthDakotaNorthFIPS4001FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_South_Dakota_North_FIPS_4001_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneSouthDakotaSouthFIPS4002",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_South_Dakota_South_FIPS_4002",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneSouthDakotaSouthFIPS4002FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_South_Dakota_South_FIPS_4002_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTennesseeFIPS4100",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Tennessee_FIPS_4100",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTennesseeFIPS4100FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Tennessee_FIPS_4100_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTexasCentralFIPS4203",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Texas_Central_FIPS_4203",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTexasCentralFIPS4203FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Texas_Central_FIPS_4203_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007TexasCentricMappingSystemAlbers",
    `PROJCS["NAD_1983_NSRS2007_Texas_Centric_Mapping_System_Albers",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007TexasCentricMappingSystemLambert",
    `PROJCS["NAD_1983_NSRS2007_Texas_Centric_Mapping_System_Lambert",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTexasNorthFIPS4201",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Texas_North_FIPS_4201",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTexasNorthFIPS4201FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Texas_North_FIPS_4201_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTexasNorthCentralFIPS4202",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Texas_North_Central_FIPS_4202",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTexasNorthCentralFIPS4202FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Texas_North_Central_FIPS_4202_FtUS",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTexasSouthFIPS4205",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Texas_South_FIPS_4205",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTexasSouthFIPS4205FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Texas_South_FIPS_4205_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",16404166.66666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTexasSouthCentralFIPS4204",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Texas_South_Central_FIPS_4204",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneTexasSouthCentralFIPS4204FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Texas_South_Central_FIPS_4204_FtUS",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneUtahCentralFIPS4302",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Utah_Central_FIPS_4302",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneUtahCentralFIPS4302FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Utah_Central_FIPS_4302_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",6561679.790026246],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneUtahCentralFIPS4302FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Utah_Central_FIPS_4302_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneUtahNorthFIPS4301",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Utah_North_FIPS_4301",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneUtahNorthFIPS4301FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Utah_North_FIPS_4301_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",3280839.895013123],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneUtahNorthFIPS4301FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Utah_North_FIPS_4301_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneUtahSouthFIPS4303",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Utah_South_FIPS_4303",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneUtahSouthFIPS4303FtIntl",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Utah_South_FIPS_4303_Ft_Intl",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",9842519.685039369],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneUtahSouthFIPS4303FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Utah_South_FIPS_4303_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneVermontFIPS4400",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Vermont_FIPS_4400",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneVirginiaNorthFIPS4501",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Virginia_North_FIPS_4501",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneVirginiaNorthFIPS4501FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Virginia_North_FIPS_4501_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneVirginiaSouthFIPS4502",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Virginia_South_FIPS_4502",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneVirginiaSouthFIPS4502FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Virginia_South_FIPS_4502_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWashingtonNorthFIPS4601",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Washington_North_FIPS_4601",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWashingtonNorthFIPS4601FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Washington_North_FIPS_4601_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWashingtonSouthFIPS4602",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Washington_South_FIPS_4602",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWashingtonSouthFIPS4602FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Washington_South_FIPS_4602_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWestVirginiaNorthFIPS4701",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_West_Virginia_North_FIPS_4701",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWestVirginiaSouthFIPS4702",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_West_Virginia_South_FIPS_4702",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWisconsinCentralFIPS4802",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wisconsin_Central_FIPS_4802",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWisconsinCentralFIPS4802FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wisconsin_Central_FIPS_4802_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWisconsinNorthFIPS4801",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wisconsin_North_FIPS_4801",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWisconsinNorthFIPS4801FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wisconsin_North_FIPS_4801_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWisconsinSouthFIPS4803",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wisconsin_South_FIPS_4803",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWisconsinSouthFIPS4803FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wisconsin_South_FIPS_4803_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007WisconsinTM",
    `PROJCS["NAD_1983_NSRS2007_Wisconsin_TM",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",520000.0],PARAMETER["False_Northing",-4480000.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWyomingEastFIPS4901",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wyoming_East_FIPS_4901",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWyomingEastCentralFIPS4902",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wyoming_East_Central_FIPS_4902",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWyomingWestCentralFIPS4903",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wyoming_West_Central_FIPS_4903",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWyomingWestFIPS4904",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wyoming_West_FIPS_4904",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone59N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_59N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone60N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_60N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone1N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_1N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone2N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_2N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone3N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_3N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone4N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_4N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone5N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_5N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone6N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_6N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone7N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_7N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone8N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_8N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone9N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_9N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone10N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_10N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone11N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_11N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone12N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_12N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone13N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_13N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone14N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_14N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone15N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_15N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone16N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_16N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone17N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_17N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone18N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_18N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone19N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_19N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Reunion1947TMReunion",
    `PROJCS["Reunion_1947_TM_Reunion",GEOGCS["GCS_Reunion_1947",DATUM["D_Reunion_1947",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",160000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",55.53333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-21.11666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOhioNorthFIPS3401FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Ohio_North_FIPS_3401_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneOhioSouthFIPS3402FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Ohio_South_FIPS_3402_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWyomingEastFIPS4901FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wyoming_East_FIPS_4901_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWyomingECentralFIPS4902FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wyoming_E_Central_FIPS_4902_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWyomingWCentralFIPS4903FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wyoming_W_Central_FIPS_4903_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWyomingWestFIPS4904FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Wyoming_West_FIPS_4904_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneOhioNorthFIPS3401Feet",
    `PROJCS["NAD_1983_StatePlane_Ohio_North_FIPS_3401_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneOhioSouthFIPS3402Feet",
    `PROJCS["NAD_1983_StatePlane_Ohio_South_FIPS_3402_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWyomingEastFIPS4901Feet",
    `PROJCS["NAD_1983_StatePlane_Wyoming_East_FIPS_4901_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWyomingEastCentralFIPS4902Feet",
    `PROJCS["NAD_1983_StatePlane_Wyoming_East_Central_FIPS_4902_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWyomingWestCentralFIPS4903Feet",
    `PROJCS["NAD_1983_StatePlane_Wyoming_West_Central_FIPS_4903_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWyomingWestFIPS4904Feet",
    `PROJCS["NAD_1983_StatePlane_Wyoming_West_FIPS_4904_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNUTMZone10N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_10N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone11N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_11N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone12N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_12N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone13N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_13N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone14N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_14N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone15N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_15N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone16N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_16N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone17N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_17N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone18N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_18N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone19N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_19N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone4N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_4N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone5N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_5N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOhioNorthFIPS3401Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Ohio_North_FIPS_3401_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneOhioSouthFIPS3402Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Ohio_South_FIPS_3402_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingEastFIPS4901Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_East_FIPS_4901_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingEastCentralFIPS4902Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_East_Central_FIPS_4902_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingWestCentralFIPS4903Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_West_Central_FIPS_4903_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingWestFIPS4904Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_West_FIPS_4904_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneHawaii3FIPS5103Feet",
    `PROJCS["NAD_1983_StatePlane_Hawaii_3_FIPS_5103_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii3FIPS5103Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_3_FIPS_5103_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CSRSUTMZone22N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_22N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984SouthGeorgiaLambert",
    `PROJCS["WGS_1984_South_Georgia_Lambert",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-37.0],PARAMETER["Standard_Parallel_1",-54.0],PARAMETER["Standard_Parallel_2",-54.75],PARAMETER["Latitude_Of_Origin",-55.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989PortugalTM06",
    `PROJCS["ETRS_1989_Portugal_TM06",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-8.133108333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",39.66825833333333],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000ChathamIslandCircuit",
    `PROJCS["NZGD_2000_Chatham_Island_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",-176.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-44.0],UNIT["Meter",1.0]]`
  ],
  [
    "HTRS96CroatiaTM",
    `PROJCS["HTRS96_Croatia_TM",GEOGCS["GCS_HTRS96",DATUM["D_Croatian_Terrestrial_Reference_System",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",16.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "HTRS96CroatiaLCC",
    `PROJCS["HTRS96_Croatia_LCC",GEOGCS["GCS_HTRS96",DATUM["D_Croatian_Terrestrial_Reference_System",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",16.5],PARAMETER["Standard_Parallel_1",43.08333333333334],PARAMETER["Standard_Parallel_2",45.91666666666666],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "HTRS96UTMZone33N",
    `PROJCS["HTRS96_UTM_Zone_33N",GEOGCS["GCS_HTRS96",DATUM["D_Croatian_Terrestrial_Reference_System",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "HTRS96UTMZone34N",
    `PROJCS["HTRS96_UTM_Zone_34N",GEOGCS["GCS_HTRS96",DATUM["D_Croatian_Terrestrial_Reference_System",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Bermuda1957UTMZone20N",
    `PROJCS["Bermuda_1957_UTM_Zone_20N",GEOGCS["GCS_Bermuda_1957",DATUM["D_Bermuda_1957",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Bermuda2000NationalGrid",
    `PROJCS["Bermuda_2000_National_Grid",GEOGCS["GCS_Bermuda_2000",DATUM["D_Bermuda_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",550000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-64.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",32.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19273TM111",
    `PROJCS["NAD_1927_3TM_111",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19273TM114",
    `PROJCS["NAD_1927_3TM_114",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19273TM117",
    `PROJCS["NAD_1927_3TM_117",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19833TM111",
    `PROJCS["NAD_1983_3TM_111",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19833TM114",
    `PROJCS["NAD_1983_3TM_114",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19833TM117",
    `PROJCS["NAD_1983_3TM_117",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRS3TM111",
    `PROJCS["NAD_1983_CSRS_3TM_111",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRS3TM114",
    `PROJCS["NAD_1983_CSRS_3TM_114",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRS3TM117",
    `PROJCS["NAD_1983_CSRS_3TM_117",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pitcairn2006PitcairnTM2006",
    `PROJCS["Pitcairn_2006_Pitcairn_TM_2006",GEOGCS["GCS_Pitcairn_2006",DATUM["D_Pitcairn_2006",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",14200.0],PARAMETER["False_Northing",15500.0],PARAMETER["Central_Meridian",-130.1129671111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-25.06855261111111],UNIT["Meter",1.0]]`
  ],
  [
    "Pitcairn1967UTMZone9S",
    `PROJCS["Pitcairn_1967_UTM_Zone_9S",GEOGCS["GCS_Pitcairn_1967",DATUM["D_Pitcairn_1967",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984WebMercator",
    `PROJCS["WGS_1984_Web_Mercator",GEOGCS["GCS_WGS_1984_Major_Auxiliary_Sphere",DATUM["D_WGS_1984_Major_Auxiliary_Sphere",SPHEROID["WGS_1984_Major_Auxiliary_Sphere",6378137.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000AucklandIslandsTM2000",
    `PROJCS["NZGD_2000_Auckland_Islands_TM_2000",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",166.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000CampbellIslandTM2000",
    `PROJCS["NZGD_2000_Campbell_Island_TM_2000",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",169.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000AntipodesIslandsTM2000",
    `PROJCS["NZGD_2000_Antipodes_Islands_TM_2000",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",179.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000RaoulIslandTM2000",
    `PROJCS["NZGD_2000_Raoul_Island_TM_2000",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-178.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000ChathamIslandsTM2000",
    `PROJCS["NZGD_2000_Chatham_Islands_TM_2000",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-176.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Slovenia1996SloveneNationalGrid",
    `PROJCS["Slovenia_1996_Slovene_National_Grid",GEOGCS["GCS_Slovenia_1996",DATUM["D_Slovenia_Geodetic_Datum_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927MTQLambert",
    `PROJCS["NAD_1927_MTQ_Lambert",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.0],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",50.0],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTQLambert",
    `PROJCS["NAD_1983_MTQ_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.0],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",50.0],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTQLambert",
    `PROJCS["NAD_1983_CSRS_MTQ_Lambert",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.0],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",50.0],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19273TM120",
    `PROJCS["NAD_1927_3TM_120",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19833TM120",
    `PROJCS["NAD_1983_3TM_120",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRS3TM120",
    `PROJCS["NAD_1983_CSRS_3TM_120",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BelgeLambert2008",
    `PROJCS["Belge_Lambert_2008",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",649328.0],PARAMETER["False_Northing",665262.0],PARAMETER["Central_Meridian",4.359215833333333],PARAMETER["Standard_Parallel_1",49.83333333333334],PARAMETER["Standard_Parallel_2",51.16666666666666],PARAMETER["Latitude_Of_Origin",50.797815],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MississippiTM",
    `PROJCS["NAD_1983_Mississippi_TM",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",-89.75],PARAMETER["Scale_Factor",0.9998335],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNMississippiTM",
    `PROJCS["NAD_1983_HARN_Mississippi_TM",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",-89.75],PARAMETER["Scale_Factor",0.9998335],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007MississippiTM",
    `PROJCS["NAD_1983_NSRS2007_Mississippi_TM",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",-89.75],PARAMETER["Scale_Factor",0.9998335],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "TWD1997TMPenghu",
    `PROJCS["TWD_1997_TM_Penghu",GEOGCS["GCS_TWD_1997",DATUM["D_TWD_1997",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",119.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TWD1997TMTaiwan",
    `PROJCS["TWD_1997_TM_Taiwan",GEOGCS["GCS_TWD_1997",DATUM["D_TWD_1997",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",121.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TWD1967TMPenghu",
    `PROJCS["TWD_1967_TM_Penghu",GEOGCS["GCS_TWD_1967",DATUM["D_TWD_1967",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",119.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TWD1967TMTaiwan",
    `PROJCS["TWD_1967_TM_Taiwan",GEOGCS["GCS_TWD_1967",DATUM["D_TWD_1967",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",121.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "HuTzuShanUTMZone51N",
    `PROJCS["Hu_Tzu_Shan_UTM_Zone_51N",GEOGCS["GCS_Hu_Tzu_Shan",DATUM["D_Hu_Tzu_Shan",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984PDCMercator",
    `PROJCS["WGS_1984_PDC_Mercator",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",150.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "Pulkovo1942Adj1958GKZone2",
    `PROJCS["Pulkovo_1942_Adj_1958_GK_Zone_2",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1983GKZone2",
    `PROJCS["Pulkovo_1942_Adj_1983_GK_Zone_2",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1983GKZone3",
    `PROJCS["Pulkovo_1942_Adj_1983_GK_Zone_3",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1983GKZone4",
    `PROJCS["Pulkovo_1942_Adj_1983_GK_Zone_4",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone3",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_3",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone4",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_4",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone9",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_9",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone10",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_10",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone6",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_6",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj58Stereo70",
    `PROJCS["Pulkovo_1942_Adj_58_Stereo_70",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Scale_Factor",0.99975],PARAMETER["Latitude_Of_Origin",46.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF99RT907.5gonVemulation",
    `PROJCS["SWEREF99_RT90_7.5_gon_V_emulation",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500025.141],PARAMETER["False_Northing",-667.282],PARAMETER["Central_Meridian",11.30625],PARAMETER["Scale_Factor",1.000006],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF99RT905gonVemulation",
    `PROJCS["SWEREF99_RT90_5_gon_V_emulation",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500044.695],PARAMETER["False_Northing",-667.13],PARAMETER["Central_Meridian",13.55626666666667],PARAMETER["Scale_Factor",1.0000058],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF99RT902.5gonVemulation",
    `PROJCS["SWEREF99_RT90_2.5_gon_V_emulation",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500064.274],PARAMETER["False_Northing",-667.711],PARAMETER["Central_Meridian",15.80628452944445],PARAMETER["Scale_Factor",1.00000561024],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF99RT900gonemulation",
    `PROJCS["SWEREF99_RT90_0_gon_emulation",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500083.521],PARAMETER["False_Northing",-668.844],PARAMETER["Central_Meridian",18.0563],PARAMETER["Scale_Factor",1.0000054],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF99RT902.5gonOemulation",
    `PROJCS["SWEREF99_RT90_2.5_gon_O_emulation",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500102.765],PARAMETER["False_Northing",-670.706],PARAMETER["Central_Meridian",20.30631666666667],PARAMETER["Scale_Factor",1.0000052],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF99RT905gonOemulation",
    `PROJCS["SWEREF99_RT90_5_gon_O_emulation",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500121.846],PARAMETER["False_Northing",-672.557],PARAMETER["Central_Meridian",22.55633333333333],PARAMETER["Scale_Factor",1.0000049],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000NZContinentalShelf2000",
    `PROJCS["NZGD_2000_NZ_Continental_Shelf_2000",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",7000000.0],PARAMETER["Central_Meridian",173.0],PARAMETER["Standard_Parallel_1",-37.5],PARAMETER["Standard_Parallel_2",-44.5],PARAMETER["Latitude_Of_Origin",-41.0],UNIT["Meter",1.0]]`
  ],
  [
    "RSRGD2000DGLC2000",
    `PROJCS["RSRGD2000_DGLC2000",GEOGCS["GCS_RSRGD2000",DATUM["D_Ross_Sea_Region_Geodetic_Datum_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",157.0],PARAMETER["Standard_Parallel_1",-76.66666666666667],PARAMETER["Standard_Parallel_2",-79.33333333333333],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "SWEREF99CountyST74",
    `PROJCS["SWEREF99_County_ST74",GEOGCS["GCS_SWEREF99",DATUM["D_SWEREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100182.7406],PARAMETER["False_Northing",-6500620.1207],PARAMETER["Central_Meridian",18.05787],PARAMETER["Scale_Factor",0.99999506],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984WebMercatorAuxiliarySphere",
    `PROJCS["WGS_1984_Web_Mercator_Auxiliary_Sphere",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator_Auxiliary_Sphere"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],PARAMETER["Auxiliary_Sphere_Type",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "ETRS1989GK19FIN",
    `PROJCS["ETRS_1989_GK19FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",19.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK20FIN",
    `PROJCS["ETRS_1989_GK20FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",20.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK21FIN",
    `PROJCS["ETRS_1989_GK21FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK22FIN",
    `PROJCS["ETRS_1989_GK22FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",22.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK23FIN",
    `PROJCS["ETRS_1989_GK23FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",23.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK24FIN",
    `PROJCS["ETRS_1989_GK24FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK25FIN",
    `PROJCS["ETRS_1989_GK25FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK26FIN",
    `PROJCS["ETRS_1989_GK26FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",26.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK27FIN",
    `PROJCS["ETRS_1989_GK27FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK28FIN",
    `PROJCS["ETRS_1989_GK28FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",28.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK29FIN",
    `PROJCS["ETRS_1989_GK29FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",29.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK30FIN",
    `PROJCS["ETRS_1989_GK30FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GK31FIN",
    `PROJCS["ETRS_1989_GK31FIN",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGRSUTMZone37N",
    `PROJCS["IGRS_UTM_Zone_37N",GEOGCS["GCS_IGRS",DATUM["D_Iraqi_Geospatial_Reference_System",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGRSUTMZone38N",
    `PROJCS["IGRS_UTM_Zone_38N",GEOGCS["GCS_IGRS",DATUM["D_Iraqi_Geospatial_Reference_System",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGRSUTMZone39N",
    `PROJCS["IGRS_UTM_Zone_39N",GEOGCS["GCS_IGRS",DATUM["D_Iraqi_Geospatial_Reference_System",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950IraqNationalGrid",
    `PROJCS["ED_1950_Iraq_National_Grid",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",46.5],PARAMETER["Scale_Factor",0.9994],PARAMETER["Latitude_Of_Origin",29.02626833333333],UNIT["Meter",1.0]]`
  ],
  [
    "MGI1901Balkans5NEdeprecated",
    `PROJCS["MGI_1901_Balkans_5_NE_deprecated",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI1901Balkans6NEdeprecated",
    `PROJCS["MGI_1901_Balkans_6_NE_deprecated",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI1901Balkans7NEdeprecated",
    `PROJCS["MGI_1901_Balkans_7_NE_deprecated",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI1901Balkans8NEdeprecated",
    `PROJCS["MGI_1901_Balkans_8_NE_deprecated",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI1901SloveniaGrid",
    `PROJCS["MGI_1901_Slovenia_Grid",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI1901SloveneNationalGrid",
    `PROJCS["MGI_1901_Slovene_National_Grid",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PuertoRicoUTMZone20N",
    `PROJCS["Puerto_Rico_UTM_Zone_20N",GEOGCS["GCS_Puerto_Rico",DATUM["D_Puerto_Rico",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993CC42",
    `PROJCS["RGF_1993_CC42",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",41.25],PARAMETER["Standard_Parallel_2",42.75],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993CC43",
    `PROJCS["RGF_1993_CC43",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",2200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",42.25],PARAMETER["Standard_Parallel_2",43.75],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993CC44",
    `PROJCS["RGF_1993_CC44",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",3200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",43.25],PARAMETER["Standard_Parallel_2",44.75],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993CC45",
    `PROJCS["RGF_1993_CC45",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",4200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.75],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993CC46",
    `PROJCS["RGF_1993_CC46",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",5200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",45.25],PARAMETER["Standard_Parallel_2",46.75],PARAMETER["Latitude_Of_Origin",46.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993CC47",
    `PROJCS["RGF_1993_CC47",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",6200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",46.25],PARAMETER["Standard_Parallel_2",47.75],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993CC48",
    `PROJCS["RGF_1993_CC48",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",7200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",47.25],PARAMETER["Standard_Parallel_2",48.75],PARAMETER["Latitude_Of_Origin",48.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993CC49",
    `PROJCS["RGF_1993_CC49",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",8200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",48.25],PARAMETER["Standard_Parallel_2",49.75],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993CC50",
    `PROJCS["RGF_1993_CC50",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",9200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",49.25],PARAMETER["Standard_Parallel_2",50.75],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983VirginiaLambert",
    `PROJCS["NAD_1983_Virginia_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",37.0],PARAMETER["Standard_Parallel_2",39.5],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNVirginiaLambert",
    `PROJCS["NAD_1983_HARN_Virginia_Lambert",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",37.0],PARAMETER["Standard_Parallel_2",39.5],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007VirginiaLambert",
    `PROJCS["NAD_1983_NSRS2007_Virginia_Lambert",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",37.0],PARAMETER["Standard_Parallel_2",39.5],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EASEGridNorth",
    `PROJCS["WGS_1984_EASE_Grid_North",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984EASEGridSouth",
    `PROJCS["WGS_1984_EASE_Grid_South",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984EASEGridGlobal",
    `PROJCS["WGS_1984_EASE_Grid_Global",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cylindrical_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",30.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984NSIDCSeaIcePolarStereographicSouth",
    `PROJCS["WGS_1984_NSIDC_Sea_Ice_Polar_Stereographic_South",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_South_Pole"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-70.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "NAD1983CanadaAtlasLambert",
    `PROJCS["NAD_1983_Canada_Atlas_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-95.0],PARAMETER["Standard_Parallel_1",49.0],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSCanadaAtlasLambert",
    `PROJCS["NAD_1983_CSRS_Canada_Atlas_Lambert",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-95.0],PARAMETER["Standard_Parallel_1",49.0],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "Katanga1955KatangaGaussZoneA",
    `PROJCS["Katanga_1955_Katanga_Gauss_Zone_A",GEOGCS["GCS_Katanga_1955",DATUM["D_Katanga_1955",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-9.0],UNIT["Meter",1.0]]`
  ],
  [
    "Katanga1955KatangaGaussZoneB",
    `PROJCS["Katanga_1955_Katanga_Gauss_Zone_B",GEOGCS["GCS_Katanga_1955",DATUM["D_Katanga_1955",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",28.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-9.0],UNIT["Meter",1.0]]`
  ],
  [
    "Katanga1955KatangaGaussZoneC",
    `PROJCS["Katanga_1955_Katanga_Gauss_Zone_C",GEOGCS["GCS_Katanga_1955",DATUM["D_Katanga_1955",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",26.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-9.0],UNIT["Meter",1.0]]`
  ],
  [
    "Katanga1955KatangaGaussZoneD",
    `PROJCS["Katanga_1955_Katanga_Gauss_Zone_D",GEOGCS["GCS_Katanga_1955",DATUM["D_Katanga_1955",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-9.0],UNIT["Meter",1.0]]`
  ],
  [
    "PuertoRicoStatePlanePuertoRicoFIPS5201",
    `PROJCS["Puerto_Rico_StatePlane_Puerto_Rico_FIPS_5201",GEOGCS["GCS_Puerto_Rico",DATUM["D_Puerto_Rico",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "PuertoRicoStatePlaneVirginIslandsStCroixFIPS5202",
    `PROJCS["Puerto_Rico_StatePlane_Virgin_Islands_St_Croix_FIPS_5202",GEOGCS["GCS_Puerto_Rico",DATUM["D_Puerto_Rico",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "WGS1984Mercator41",
    `PROJCS["WGS_1984_Mercator_41",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",100.0],PARAMETER["Standard_Parallel_1",-41.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ArcticPolarStereographic",
    `PROJCS["WGS_1984_Arctic_Polar_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_North_Pole"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",71.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984IBCAOPolarStereographic",
    `PROJCS["WGS_1984_IBCAO_Polar_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_North_Pole"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",75.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984DubaiLocalTM",
    `PROJCS["WGS_1984_Dubai_Local_TM",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",55.33333333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MOLDREF99MoldovaTM",
    `PROJCS["MOLDREF99_Moldova_TM",GEOGCS["GCS_MOLDREF99",DATUM["D_MOLDREF99",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",28.4],PARAMETER["Scale_Factor",0.99994],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984TMzn35N",
    `PROJCS["WGS_1984_TMzn35N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984TMzn36N",
    `PROJCS["WGS_1984_TMzn36N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone12",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_12",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone14",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_14",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",14.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone16",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_16",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",16.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone18",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_18",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone20",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_20",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",20.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone22",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_22",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",22.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone24",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_24",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone26",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_26",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",26.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone28",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_28",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",28.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005UTMZone33S",
    `PROJCS["RGRDC_2005_UTM_Zone_33S",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005UTMZone34S",
    `PROJCS["RGRDC_2005_UTM_Zone_34S",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005UTMZone35S",
    `PROJCS["RGRDC_2005_UTM_Zone_35S",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ChuaUTMZone23S",
    `PROJCS["Chua_UTM_Zone_23S",GEOGCS["GCS_Chua",DATUM["D_Chua",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "REGCAN95UTMZone27N",
    `PROJCS["REGCAN95_UTM_Zone_27N",GEOGCS["GCS_REGCAN95",DATUM["D_Red_Geodesica_de_Canarias_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "REGCAN95UTMZone28N",
    `PROJCS["REGCAN95_UTM_Zone_28N",GEOGCS["GCS_REGCAN95",DATUM["D_Red_Geodesica_de_Canarias_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984WorldEquidistantCylindrical",
    `PROJCS["WGS_1984_World_Equidistant_Cylindrical",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical_Ellipsoidal"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldEquidistantCylindrical(Sphere)",
    `PROJCS["World_Equidistant_Cylindrical_(Sphere)",GEOGCS["GCS_Sphere_GRS_1980_Authalic",DATUM["D_Sphere_GRS_1980_Authalic",SPHEROID["Sphere_GRS_1980_Authalic",6371007.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989DKTM1",
    `PROJCS["ETRS_1989_DKTM1",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989DKTM2",
    `PROJCS["ETRS_1989_DKTM2",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989DKTM3",
    `PROJCS["ETRS_1989_DKTM3",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",11.75],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989DKTM4",
    `PROJCS["ETRS_1989_DKTM4",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983BLMZone59N",
    `PROJCS["NAD_1983_BLM_Zone_59N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Kertau1968JohorGrid",
    `PROJCS["Kertau_1968_Johor_Grid",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",103.5627583333334],PARAMETER["Latitude_Of_Origin",2.042583333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Kertau1968SembilanandMelakaGrid",
    `PROJCS["Kertau_1968_Sembilan_and_Melaka_Grid",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",-242.005],PARAMETER["False_Northing",-948.547],PARAMETER["Central_Meridian",101.9411666666667],PARAMETER["Latitude_Of_Origin",2.712283333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Kertau1968PahangGrid",
    `PROJCS["Kertau_1968_Pahang_Grid",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.4361777777778],PARAMETER["Latitude_Of_Origin",3.710972222222222],UNIT["Meter",1.0]]`
  ],
  [
    "Kertau1968SelangorGrid",
    `PROJCS["Kertau_1968_Selangor_Grid",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",-21759.438],PARAMETER["False_Northing",55960.906],PARAMETER["Central_Meridian",101.5082444444444],PARAMETER["Latitude_Of_Origin",3.680344444444444],UNIT["Meter",1.0]]`
  ],
  [
    "Kertau1968TerengganuGrid",
    `PROJCS["Kertau_1968_Terengganu_Grid",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.8952083333333],PARAMETER["Latitude_Of_Origin",4.946141666666668],UNIT["Meter",1.0]]`
  ],
  [
    "Kertau1968PinangGrid",
    `PROJCS["Kertau_1968_Pinang_Grid",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",100.3458694444445],PARAMETER["Latitude_Of_Origin",5.421325],UNIT["Meter",1.0]]`
  ],
  [
    "Kertau1968KedahandPerlisGrid",
    `PROJCS["Kertau_1968_Kedah_and_Perlis_Grid",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",100.6375944444445],PARAMETER["Latitude_Of_Origin",5.965147222222222],UNIT["Meter",1.0]]`
  ],
  [
    "Kertau1968PerakRevisedGrid",
    `PROJCS["Kertau_1968_Perak_Revised_Grid",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",133453.669],PARAMETER["Central_Meridian",100.8167666666667],PARAMETER["Latitude_Of_Origin",4.859380555555557],UNIT["Meter",1.0]]`
  ],
  [
    "Kertau1968KelantanGrid",
    `PROJCS["Kertau_1968_Kelantan_Grid",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.1772916666667],PARAMETER["Latitude_Of_Origin",5.893922222222224],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927BLMZone59N",
    `PROJCS["NAD_1927_BLM_Zone_59N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone60N",
    `PROJCS["NAD_1927_BLM_Zone_60N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone1N",
    `PROJCS["NAD_1927_BLM_Zone_1N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone2N",
    `PROJCS["NAD_1927_BLM_Zone_2N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone3N",
    `PROJCS["NAD_1927_BLM_Zone_3N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone4N",
    `PROJCS["NAD_1927_BLM_Zone_4N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone5N",
    `PROJCS["NAD_1927_BLM_Zone_5N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone6N",
    `PROJCS["NAD_1927_BLM_Zone_6N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone7N",
    `PROJCS["NAD_1927_BLM_Zone_7N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone8N",
    `PROJCS["NAD_1927_BLM_Zone_8N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone9N",
    `PROJCS["NAD_1927_BLM_Zone_9N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone10N",
    `PROJCS["NAD_1927_BLM_Zone_10N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone11N",
    `PROJCS["NAD_1927_BLM_Zone_11N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone12N",
    `PROJCS["NAD_1927_BLM_Zone_12N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone13N",
    `PROJCS["NAD_1927_BLM_Zone_13N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNGuamMapGrid",
    `PROJCS["NAD_1983_HARN_Guam_Map_Grid",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",144.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",13.5],UNIT["Meter",1.0]]`
  ],
  [
    "Katanga1955KatangaLambert",
    `PROJCS["Katanga_1955_Katanga_Lambert",GEOGCS["GCS_Katanga_1955",DATUM["D_Katanga_1955",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",26.0],PARAMETER["Standard_Parallel_1",-6.5],PARAMETER["Standard_Parallel_2",-11.5],PARAMETER["Latitude_Of_Origin",-9.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone7",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_7",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927BLMZone18N",
    `PROJCS["NAD_1927_BLM_Zone_18N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone19N",
    `PROJCS["NAD_1927_BLM_Zone_19N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone60N",
    `PROJCS["NAD_1983_BLM_Zone_60N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone1N",
    `PROJCS["NAD_1983_BLM_Zone_1N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone2N",
    `PROJCS["NAD_1983_BLM_Zone_2N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone3N",
    `PROJCS["NAD_1983_BLM_Zone_3N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone4N",
    `PROJCS["NAD_1983_BLM_Zone_4N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone5N",
    `PROJCS["NAD_1983_BLM_Zone_5N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone6N",
    `PROJCS["NAD_1983_BLM_Zone_6N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone7N",
    `PROJCS["NAD_1983_BLM_Zone_7N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone8N",
    `PROJCS["NAD_1983_BLM_Zone_8N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone9N",
    `PROJCS["NAD_1983_BLM_Zone_9N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone10N",
    `PROJCS["NAD_1983_BLM_Zone_10N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone11N",
    `PROJCS["NAD_1983_BLM_Zone_11N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone12N",
    `PROJCS["NAD_1983_BLM_Zone_12N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone13N",
    `PROJCS["NAD_1983_BLM_Zone_13N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone8",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_8",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007StatePlanePuertoRicoVirginIslsFIPS5200",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Puerto_Rico_Virgin_Isls_FIPS_5200",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983BLMZone18N",
    `PROJCS["NAD_1983_BLM_Zone_18N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone19N",
    `PROJCS["NAD_1983_BLM_Zone_19N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlanePennsylvaniaSouthFIPS3702",
    `PROJCS["NAD_1927_StatePlane_Pennsylvania_South_FIPS_3702",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNewYorkLongIslandFIPS3104",
    `PROJCS["NAD_1927_StatePlane_New_York_Long_Island_FIPS_3104",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneSouthDakotaNorthFIPS4001Feet",
    `PROJCS["NAD_1983_StatePlane_South_Dakota_North_FIPS_4001_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "WGS1984AustralianCentreforRemoteSensingLambert",
    `PROJCS["WGS_1984_Australian_Centre_for_Remote_Sensing_Lambert",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Standard_Parallel_1",-18.0],PARAMETER["Standard_Parallel_2",-36.0],PARAMETER["Latitude_Of_Origin",-27.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGSPM2006UTMZone21N",
    `PROJCS["RGSPM_2006_UTM_Zone_21N",GEOGCS["GCS_RGSPM_2006",DATUM["D_Reseau_Geodesique_de_St_Pierre_et_Miquelon_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGM2004UTMZone38S",
    `PROJCS["RGM_2004_UTM_Zone_38S",GEOGCS["GCS_RGM_2004",DATUM["D_Reseau_Geodesique_de_Mayotte_2004",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Cadastre1997UTMZone38S",
    `PROJCS["Cadastre_1997_UTM_Zone_38S",GEOGCS["GCS_Cadastre_1997",DATUM["D_Cadastre_1997",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone11N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_11N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone12N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_12N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone13N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_13N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone14N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_14N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone15N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_15N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone16N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_16N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone13",
    `PROJCS["CGCS2000_GK_Zone_13",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone14",
    `PROJCS["CGCS2000_GK_Zone_14",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone15",
    `PROJCS["CGCS2000_GK_Zone_15",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone16",
    `PROJCS["CGCS2000_GK_Zone_16",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone17",
    `PROJCS["CGCS2000_GK_Zone_17",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone18",
    `PROJCS["CGCS2000_GK_Zone_18",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone19",
    `PROJCS["CGCS2000_GK_Zone_19",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone20",
    `PROJCS["CGCS2000_GK_Zone_20",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone21",
    `PROJCS["CGCS2000_GK_Zone_21",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone22",
    `PROJCS["CGCS2000_GK_Zone_22",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKZone23",
    `PROJCS["CGCS2000_GK_Zone_23",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM75E",
    `PROJCS["CGCS2000_GK_CM_75E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM81E",
    `PROJCS["CGCS2000_GK_CM_81E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM87E",
    `PROJCS["CGCS2000_GK_CM_87E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM93E",
    `PROJCS["CGCS2000_GK_CM_93E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM99E",
    `PROJCS["CGCS2000_GK_CM_99E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM105E",
    `PROJCS["CGCS2000_GK_CM_105E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM111E",
    `PROJCS["CGCS2000_GK_CM_111E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM117E",
    `PROJCS["CGCS2000_GK_CM_117E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM123E",
    `PROJCS["CGCS2000_GK_CM_123E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM129E",
    `PROJCS["CGCS2000_GK_CM_129E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS2000GKCM135E",
    `PROJCS["CGCS2000_GK_CM_135E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone25",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_25",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone26",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_26",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone27",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_27",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone28",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_28",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone29",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_29",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone30",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_30",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone31",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_31",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone32",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_32",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone33",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_33",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone34",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_34",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",34500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone35",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_35",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",35500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone36",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_36",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",36500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone37",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_37",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",37500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone38",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_38",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",38500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone39",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_39",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",39500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone40",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_40",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",40500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone41",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_41",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",41500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone42",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_42",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",42500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone43",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_43",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",43500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone44",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_44",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",44500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKZone45",
    `PROJCS["CGCS2000_3_Degree_GK_Zone_45",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",45500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM75E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_75E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM78E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_78E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM81E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_81E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM84E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_84E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM87E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_87E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM90E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_90E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM93E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_93E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM96E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_96E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM99E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_99E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM102E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_102E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM105E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_105E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM108E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_108E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM111E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_111E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM114E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_114E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM117E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_117E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM120E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_120E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM123E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_123E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM126E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_126E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM129E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_129E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM132E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_132E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CGCS20003DegreeGKCM135E",
    `PROJCS["CGCS2000_3_Degree_GK_CM_135E",GEOGCS["GCS_China_Geodetic_Coordinate_System_2000",DATUM["D_China_2000",SPHEROID["CGCS2000",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RRAF1991UTM20N",
    `PROJCS["RRAF_1991_UTM_20N",GEOGCS["GCS_RRAF_1991",DATUM["D_RRAF_1991",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone13",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_13",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone14",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_14",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone15",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_15",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone16",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_16",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone17",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_17",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone18",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_18",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone19",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_19",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone20",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_20",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone21",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_21",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone22",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_22",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerZone23",
    `PROJCS["New_Beijing_Gauss_Kruger_Zone_23",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM75E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_75E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM81E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_81E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM87E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_87E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM93E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_93E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM99E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_99E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM105E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_105E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM111E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_111E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM117E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_117E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM123E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_123E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM129E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_129E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijingGaussKrugerCM135E",
    `PROJCS["New_Beijing_Gauss_Kruger_CM_135E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZoneN32",
    `PROJCS["ETRS_1989_UTM_Zone_N32",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone25",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_25",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone26",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_26",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone27",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_27",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone28",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_28",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone29",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_29",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone30",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_30",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone31",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_31",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone32",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_32",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone33",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_33",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone34",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_34",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",34500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone35",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_35",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",35500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone36",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_36",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",36500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone37",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_37",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",37500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone38",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_38",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",38500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone39",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_39",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",39500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone40",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_40",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone41",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_41",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",41500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone42",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_42",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",42500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone43",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_43",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",43500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone44",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_44",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",44500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerZone45",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_Zone_45",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",45500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM75E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_75E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM78E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_78E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM81E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_81E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM84E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_84E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM87E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_87E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM90E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_90E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM93E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_93E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM96E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_96E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM99E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_99E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM102E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_102E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM105E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_105E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM108E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_108E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM111E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_111E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM114E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_114E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM117E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_117E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM120E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_120E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM123E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_123E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM126E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_126E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM129E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_129E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM135E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_135E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984CapeVerdeGrid",
    `PROJCS["WGS_1984_Cape_Verde_Grid",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",161587.83],PARAMETER["False_Northing",128511.202],PARAMETER["Central_Meridian",-24.0],PARAMETER["Standard_Parallel_1",15.0],PARAMETER["Standard_Parallel_2",16.66666666666667],PARAMETER["Latitude_Of_Origin",15.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989LCCGermanyN-E",
    `PROJCS["ETRS_1989_LCC_Germany_N-E",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.5],PARAMETER["Standard_Parallel_1",48.66666666666666],PARAMETER["Standard_Parallel_2",53.66666666666666],PARAMETER["Latitude_Of_Origin",51.0],UNIT["Meter",1.0]]`
  ],
  [
    "PTRA08UTMZone25N",
    `PROJCS["PTRA08_UTM_Zone_25N",GEOGCS["GCS_PTRA08",DATUM["D_PTRA08",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PTRA08UTMZone26N",
    `PROJCS["PTRA08_UTM_Zone_26N",GEOGCS["GCS_PTRA08",DATUM["D_PTRA08",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PTRA08UTMZone28N",
    `PROJCS["PTRA08_UTM_Zone_28N",GEOGCS["GCS_PTRA08",DATUM["D_PTRA08",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LisbonPortugueseGridNew",
    `PROJCS["Lisbon_Portuguese_Grid_New",GEOGCS["GCS_Lisbon",DATUM["D_Lisbon",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-8.131906111111112],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UPSNorth(E-N)",
    `PROJCS["WGS_1984_UPS_North_(E-N)",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polar_Stereographic_Variant_A"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Longitude_Of_Origin",0.0],PARAMETER["Scale_Factor",0.994],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984UPSSouth(E-N)",
    `PROJCS["WGS_1984_UPS_South_(E-N)",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polar_Stereographic_Variant_A"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Longitude_Of_Origin",0.0],PARAMETER["Scale_Factor",0.994],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "ETRS1989TM35FINNE",
    `PROJCS["ETRS_1989_TM35FIN_NE",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927ContiguousUSAAlbers",
    `PROJCS["NAD_1927_Contiguous_USA_Albers",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",29.5],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",23.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983ContiguousUSAAlbers",
    `PROJCS["NAD_1983_Contiguous_USA_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",29.5],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",23.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNContiguousUSAAlbers",
    `PROJCS["NAD_1983_HARN_Contiguous_USA_Albers",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",29.5],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",23.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007ContiguousUSAAlbers",
    `PROJCS["NAD_1983_NSRS2007_Contiguous_USA_Albers",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",29.5],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",23.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone5",
    `PROJCS["ETRS_1989_NTM_Zone_5",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",5.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone6",
    `PROJCS["ETRS_1989_NTM_Zone_6",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",6.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone7",
    `PROJCS["ETRS_1989_NTM_Zone_7",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",7.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone8",
    `PROJCS["ETRS_1989_NTM_Zone_8",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",8.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone9",
    `PROJCS["ETRS_1989_NTM_Zone_9",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",9.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone10",
    `PROJCS["ETRS_1989_NTM_Zone_10",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",10.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone11",
    `PROJCS["ETRS_1989_NTM_Zone_11",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",11.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone12",
    `PROJCS["ETRS_1989_NTM_Zone_12",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",12.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone13",
    `PROJCS["ETRS_1989_NTM_Zone_13",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",13.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone14",
    `PROJCS["ETRS_1989_NTM_Zone_14",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",14.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone15",
    `PROJCS["ETRS_1989_NTM_Zone_15",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",15.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone16",
    `PROJCS["ETRS_1989_NTM_Zone_16",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",16.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone17",
    `PROJCS["ETRS_1989_NTM_Zone_17",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",17.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone18",
    `PROJCS["ETRS_1989_NTM_Zone_18",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",18.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone19",
    `PROJCS["ETRS_1989_NTM_Zone_19",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",19.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone20",
    `PROJCS["ETRS_1989_NTM_Zone_20",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",20.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone21",
    `PROJCS["ETRS_1989_NTM_Zone_21",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",21.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone22",
    `PROJCS["ETRS_1989_NTM_Zone_22",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",22.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone23",
    `PROJCS["ETRS_1989_NTM_Zone_23",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",23.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone24",
    `PROJCS["ETRS_1989_NTM_Zone_24",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",24.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone25",
    `PROJCS["ETRS_1989_NTM_Zone_25",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",25.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone26",
    `PROJCS["ETRS_1989_NTM_Zone_26",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",26.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone27",
    `PROJCS["ETRS_1989_NTM_Zone_27",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",27.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone28",
    `PROJCS["ETRS_1989_NTM_Zone_28",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",28.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone29",
    `PROJCS["ETRS_1989_NTM_Zone_29",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",29.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989NTMZone30",
    `PROJCS["ETRS_1989_NTM_Zone_30",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",30.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985KoreaEastSeaBelt",
    `PROJCS["Korean_1985_Korea_East_Sea_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985KoreaCentralBeltJeju",
    `PROJCS["Korean_1985_Korea_Central_Belt_Jeju",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",550000.0],PARAMETER["Central_Meridian",127.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Tokyo1892KoreaWestBelt",
    `PROJCS["Tokyo_1892_Korea_West_Belt",GEOGCS["Tokyo_1892",DATUM["Tokyo_1892",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",125.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Tokyo1892KoreaCentralBelt",
    `PROJCS["Tokyo_1892_Korea_Central_Belt",GEOGCS["Tokyo_1892",DATUM["Tokyo_1892",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",127.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Tokyo1892KoreaEastBelt",
    `PROJCS["Tokyo_1892_Korea_East_Belt",GEOGCS["Tokyo_1892",DATUM["Tokyo_1892",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Tokyo1892KoreaEastSeaBelt",
    `PROJCS["Tokyo_1892_Korea_East_Sea_Belt",GEOGCS["Tokyo_1892",DATUM["Tokyo_1892",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985ModifiedKoreaWestBelt",
    `PROJCS["Korean_1985_Modified_Korea_West_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",125.0028902777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985ModifiedKoreaCentralBelt",
    `PROJCS["Korean_1985_Modified_Korea_Central_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",127.0028902777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985ModifiedKoreaCentralBeltJeju",
    `PROJCS["Korean_1985_Modified_Korea_Central_Belt_Jeju",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",550000.0],PARAMETER["Central_Meridian",127.0028902777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985ModifiedKoreaEastBelt",
    `PROJCS["Korean_1985_Modified_Korea_East_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",129.0028902777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985ModifiedKoreaEastSeaBelt",
    `PROJCS["Korean_1985_Modified_Korea_East_Sea_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",131.0028902777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985KoreaUnifiedCoordinateSystem",
    `PROJCS["Korean_1985_Korea_Unified_Coordinate_System",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",127.5],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002UnifiedCoordinateSystem",
    `PROJCS["KGD2002_Unified_Coordinate_System",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",127.5],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002WestBelt",
    `PROJCS["KGD2002_West_Belt",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",125.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002CentralBelt",
    `PROJCS["KGD2002_Central_Belt",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",127.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002CentralBeltJeju",
    `PROJCS["KGD2002_Central_Belt_Jeju",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",550000.0],PARAMETER["Central_Meridian",127.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002EastBelt",
    `PROJCS["KGD2002_East_Belt",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002EastSeaBelt",
    `PROJCS["KGD2002_East_Sea_Belt",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002WestBelt2010",
    `PROJCS["KGD2002_West_Belt_2010",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",125.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002CentralBelt2010",
    `PROJCS["KGD2002_Central_Belt_2010",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",127.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002EastBelt2010",
    `PROJCS["KGD2002_East_Belt_2010",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002EastSeaBelt2010",
    `PROJCS["KGD2002_East_Sea_Belt_2010",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "S-JTSKFerroKrovakEastNorth",
    `PROJCS["S-JTSK_Ferro_Krovak_East_North",GEOGCS["GCS_S_JTSK_Ferro",DATUM["D_S_JTSK",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Krovak"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Pseudo_Standard_Parallel_1",78.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",30.28813975277778],PARAMETER["Longitude_Of_Center",42.5],PARAMETER["Latitude_Of_Center",49.5],PARAMETER["X_Scale",-1.0],PARAMETER["Y_Scale",1.0],PARAMETER["XY_Plane_Rotation",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMGabonTM",
    `PROJCS["WGS_1984_UTM_Gabon_TM",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KandawalaSriLankaGrid",
    `PROJCS["Kandawala_Sri_Lanka_Grid",GEOGCS["GCS_Kandawala",DATUM["D_Kandawala",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",80.77171111111112],PARAMETER["Scale_Factor",0.9999238418],PARAMETER["Latitude_Of_Origin",7.000480277777778],UNIT["Meter",1.0]]`
  ],
  [
    "SLD99SriLankaGrid1999",
    `PROJCS["SLD99_Sri_Lanka_Grid_1999",GEOGCS["GCS_SLD99",DATUM["D_Sri_Lanka_Datum_1999",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",80.77171308333334],PARAMETER["Scale_Factor",0.9999238418],PARAMETER["Latitude_Of_Origin",7.000471527777778],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989LCCGermanyE-N",
    `PROJCS["ETRS_1989_LCC_Germany_E-N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.5],PARAMETER["Standard_Parallel_1",48.66666666666666],PARAMETER["Standard_Parallel_2",53.66666666666666],PARAMETER["Latitude_Of_Origin",51.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDBD2009GEORSO",
    `PROJCS["GDBD2009_GEORSO",GEOGCS["GCS_GDBD2009",DATUM["D_GDBD2009",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",53.31580995],PARAMETER["Longitude_Of_Center",115.0],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",53.13010235415598],UNIT["Meter",1.0]]`
  ],
  [
    "TUREFTM27",
    `PROJCS["TUREF_TM27",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREFTM30",
    `PROJCS["TUREF_TM30",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREFTM33",
    `PROJCS["TUREF_TM33",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREFTM36",
    `PROJCS["TUREF_TM36",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREFTM39",
    `PROJCS["TUREF_TM39",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREFTM42",
    `PROJCS["TUREF_TM42",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREFTM45",
    `PROJCS["TUREF_TM45",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03BhutanNationalGrid",
    `PROJCS["DRUKREF_03_Bhutan_National_Grid",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREF3DegreeGKZone9",
    `PROJCS["TUREF_3_Degree_GK_Zone_9",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREF3DegreeGKZone10",
    `PROJCS["TUREF_3_Degree_GK_Zone_10",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREF3DegreeGKZone11",
    `PROJCS["TUREF_3_Degree_GK_Zone_11",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREF3DegreeGKZone12",
    `PROJCS["TUREF_3_Degree_GK_Zone_12",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREF3DegreeGKZone13",
    `PROJCS["TUREF_3_Degree_GK_Zone_13",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREF3DegreeGKZone14",
    `PROJCS["TUREF_3_Degree_GK_Zone_14",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREF3DegreeGKZone15",
    `PROJCS["TUREF_3_Degree_GK_Zone_15",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03BumthangTM",
    `PROJCS["DRUKREF_03_Bumthang_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",90.73333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03ChhukhaTM",
    `PROJCS["DRUKREF_03_Chhukha_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",89.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03DaganaTM",
    `PROJCS["DRUKREF_03_Dagana_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",89.85],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03GasaTM",
    `PROJCS["DRUKREF_03_Gasa_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",90.03333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03HaTM",
    `PROJCS["DRUKREF_03_Ha_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",90.15],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03LhuentseTM",
    `PROJCS["DRUKREF_03_Lhuentse_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",91.13333333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03MongarTM",
    `PROJCS["DRUKREF_03_Mongar_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",91.23333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03ParoTM",
    `PROJCS["DRUKREF_03_Paro_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",89.35],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03PemagatshelTM",
    `PROJCS["DRUKREF_03_Pemagatshel_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",91.35],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03PunakhaTM",
    `PROJCS["DRUKREF_03_Punakha_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",89.85],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03SamdrupJongkharTM",
    `PROJCS["DRUKREF_03_Samdrup_Jongkhar_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",91.56666666666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03SamtseTM",
    `PROJCS["DRUKREF_03_Samtse_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",89.06666666666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03SarpangTM",
    `PROJCS["DRUKREF_03_Sarpang_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",90.26666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03ThimphuTM",
    `PROJCS["DRUKREF_03_Thimphu_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",89.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03TrashigangTM",
    `PROJCS["DRUKREF_03_Trashigang_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",91.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03TrongsaTM",
    `PROJCS["DRUKREF_03_Trongsa_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",90.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03TsirangTM",
    `PROJCS["DRUKREF_03_Tsirang_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",90.16666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03WangduePhodrangTM",
    `PROJCS["DRUKREF_03_Wangdue_Phodrang_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",90.11666666666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03YangtseTM",
    `PROJCS["DRUKREF_03_Yangtse_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",91.56666666666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DRUKREF03ZhemgangTM",
    `PROJCS["DRUKREF_03_Zhemgang_TM",GEOGCS["GCS_DRUKREF_03",DATUM["D_Bhutan_National_Geodetic_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",-2500000.0],PARAMETER["Central_Meridian",90.86666666666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989FAROETM",
    `PROJCS["ETRS_1989_FAROE_TM",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",-6000000.0],PARAMETER["Central_Meridian",-7.0],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983TeranetOntarioLambert",
    `PROJCS["NAD_1983_Teranet_Ontario_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Standard_Parallel_1",44.5],PARAMETER["Standard_Parallel_2",54.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSTeranetOntarioLambert",
    `PROJCS["NAD_1983_CSRS_Teranet_Ontario_Lambert",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Standard_Parallel_1",44.5],PARAMETER["Standard_Parallel_2",54.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ISN2004Lambert2004",
    `PROJCS["ISN_2004_Lambert_2004",GEOGCS["GCS_ISN_2004",DATUM["D_Islands_Network_2004",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",-19.0],PARAMETER["Standard_Parallel_1",64.25],PARAMETER["Standard_Parallel_2",65.75],PARAMETER["Latitude_Of_Origin",65.0],UNIT["Meter",1.0]]`
  ],
  [
    "GunungSegaraJakartaNEIEZ",
    `PROJCS["Gunung_Segara_Jakarta_NEIEZ",GEOGCS["GCS_Gunung_Segara_Jakarta",DATUM["D_Gunung_Segara",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Jakarta",106.8077194444444],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",3900000.0],PARAMETER["False_Northing",900000.0],PARAMETER["Central_Meridian",3.192280555555556],PARAMETER["Standard_Parallel_1",4.45405154589751],UNIT["Meter",1.0]]`
  ],
  [
    "BataviaJakartaNEIEZ",
    `PROJCS["Batavia_Jakarta_NEIEZ",GEOGCS["GCS_Batavia_Jakarta",DATUM["D_Batavia",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Jakarta",106.8077194444444],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",3900000.0],PARAMETER["False_Northing",900000.0],PARAMETER["Central_Meridian",3.192280555555556],PARAMETER["Standard_Parallel_1",4.45405154589751],UNIT["Meter",1.0]]`
  ],
  [
    "MakassarJakartaNEIEZ",
    `PROJCS["Makassar_Jakarta_NEIEZ",GEOGCS["GCS_Makassar_Jakarta",DATUM["D_Makassar",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Jakarta",106.8077194444444],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",3900000.0],PARAMETER["False_Northing",900000.0],PARAMETER["Central_Meridian",3.192280555555556],PARAMETER["Standard_Parallel_1",4.45405154589751],UNIT["Meter",1.0]]`
  ],
  [
    "AratuUTMZone25S",
    `PROJCS["Aratu_UTM_Zone_25S",GEOGCS["GCS_Aratu",DATUM["D_Aratu",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR2007ArgentinaZone1",
    `PROJCS["POSGAR_2007_Argentina_Zone_1",GEOGCS["GCS_POSGAR_2007",DATUM["D_POSGAR_2007",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR2007ArgentinaZone2",
    `PROJCS["POSGAR_2007_Argentina_Zone_2",GEOGCS["GCS_POSGAR_2007",DATUM["D_POSGAR_2007",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR2007ArgentinaZone3",
    `PROJCS["POSGAR_2007_Argentina_Zone_3",GEOGCS["GCS_POSGAR_2007",DATUM["D_POSGAR_2007",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR2007ArgentinaZone4",
    `PROJCS["POSGAR_2007_Argentina_Zone_4",GEOGCS["GCS_POSGAR_2007",DATUM["D_POSGAR_2007",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR2007ArgentinaZone5",
    `PROJCS["POSGAR_2007_Argentina_Zone_5",GEOGCS["GCS_POSGAR_2007",DATUM["D_POSGAR_2007",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR2007ArgentinaZone6",
    `PROJCS["POSGAR_2007_Argentina_Zone_6",GEOGCS["GCS_POSGAR_2007",DATUM["D_POSGAR_2007",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR2007ArgentinaZone7",
    `PROJCS["POSGAR_2007_Argentina_Zone_7",GEOGCS["GCS_POSGAR_2007",DATUM["D_POSGAR_2007",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-54.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "MARGENUTMZone20S",
    `PROJCS["MARGEN_UTM_Zone_20S",GEOGCS["GCS_MARGEN",DATUM["D_Marco_Geodesico_Nacional",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MARGENUTMZone19S",
    `PROJCS["MARGEN_UTM_Zone_19S",GEOGCS["GCS_MARGEN",DATUM["D_Marco_Geodesico_Nacional",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MARGENUTMZone21S",
    `PROJCS["MARGEN_UTM_Zone_21S",GEOGCS["GCS_MARGEN",DATUM["D_Marco_Geodesico_Nacional",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-Chile2002UTMZone19S",
    `PROJCS["SIRGAS-Chile_2002_UTM_Zone_19S",GEOGCS["GCS_SIRGAS-Chile_2002",DATUM["SIRGAS-Chile_realization_1_epoch_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-Chile2002UTMZone18S",
    `PROJCS["SIRGAS-Chile_2002_UTM_Zone_18S",GEOGCS["GCS_SIRGAS-Chile_2002",DATUM["SIRGAS-Chile_realization_1_epoch_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CRTM05",
    `PROJCS["CRTM05",GEOGCS["GCS_CR05",DATUM["D_Costa_Rica_2005",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-ROU98UTMZone21S",
    `PROJCS["SIRGAS-ROU98_UTM_Zone_21S",GEOGCS["GCS_SIRGAS-ROU98",DATUM["D_SIRGAS-ROU98",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-ROU98UTMZone22S",
    `PROJCS["SIRGAS-ROU98_UTM_Zone_22S",GEOGCS["GCS_SIRGAS-ROU98",DATUM["D_SIRGAS-ROU98",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Peru96UTMZone18S",
    `PROJCS["Peru96_UTM_Zone_18S",GEOGCS["GCS_Peru96",DATUM["D_Peru96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Peru96UTMZone17S",
    `PROJCS["Peru96_UTM_Zone_17S",GEOGCS["GCS_Peru96",DATUM["D_Peru96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Peru96UTMZone19S",
    `PROJCS["Peru96_UTM_Zone_19S",GEOGCS["GCS_Peru96",DATUM["D_Peru96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone26S",
    `PROJCS["SIRGAS_2000_UTM_Zone_26S",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ocotepeque1935CostaRicaNorte",
    `PROJCS["Ocotepeque_1935_Costa_Rica_Norte",GEOGCS["GCS_Ocotepeque_1935",DATUM["D_Ocotepeque_1935",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",271820.522],PARAMETER["Central_Meridian",-84.33333333333333],PARAMETER["Standard_Parallel_1",10.46666666666667],PARAMETER["Scale_Factor",0.99995696],PARAMETER["Latitude_Of_Origin",10.46666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "Ocotepeque1935CostaRicaSur",
    `PROJCS["Ocotepeque_1935_Costa_Rica_Sur",GEOGCS["GCS_Ocotepeque_1935",DATUM["D_Ocotepeque_1935",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",327987.436],PARAMETER["Central_Meridian",-83.66666666666667],PARAMETER["Standard_Parallel_1",9.0],PARAMETER["Scale_Factor",0.99995696],PARAMETER["Latitude_Of_Origin",9.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ocotepeque1935GuatemalaSur",
    `PROJCS["Ocotepeque_1935_Guatemala_Sur",GEOGCS["GCS_Ocotepeque_1935",DATUM["D_Ocotepeque_1935",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",325992.681],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Standard_Parallel_1",14.9],PARAMETER["Scale_Factor",0.99989906],PARAMETER["Latitude_Of_Origin",14.9],UNIT["Meter",1.0]]`
  ],
  [
    "Ocotepeque1935ElSalvadorLambert",
    `PROJCS["Ocotepeque_1935_El_Salvador_Lambert",GEOGCS["GCS_Ocotepeque_1935",DATUM["D_Ocotepeque_1935",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",295809.184],PARAMETER["Central_Meridian",-89.0],PARAMETER["Standard_Parallel_1",13.78333333333333],PARAMETER["Scale_Factor",0.99996704],PARAMETER["Latitude_Of_Origin",13.78333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Ocotepeque1935NicaraguaNorte",
    `PROJCS["Ocotepeque_1935_Nicaragua_Norte",GEOGCS["GCS_Ocotepeque_1935",DATUM["D_Ocotepeque_1935",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",359891.816],PARAMETER["Central_Meridian",-85.5],PARAMETER["Standard_Parallel_1",13.86666666666667],PARAMETER["Scale_Factor",0.99990314],PARAMETER["Latitude_Of_Origin",13.86666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "Ocotepeque1935NicaraguaSur",
    `PROJCS["Ocotepeque_1935_Nicaragua_Sur",GEOGCS["GCS_Ocotepeque_1935",DATUM["D_Ocotepeque_1935",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",288876.327],PARAMETER["Central_Meridian",-85.5],PARAMETER["Standard_Parallel_1",11.73333333333333],PARAMETER["Scale_Factor",0.99992228],PARAMETER["Latitude_Of_Origin",11.73333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone17N",
    `PROJCS["SAD_1969_UTM_Zone_17N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Panama-Colon1911PanamaLambert",
    `PROJCS["Panama-Colon_1911_Panama_Lambert",GEOGCS["GCS_Panama-Colon_1911",DATUM["D_Panama-Colon-1911",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",294865.303],PARAMETER["Central_Meridian",-80.0],PARAMETER["Standard_Parallel_1",8.416666666666666],PARAMETER["Scale_Factor",0.99989909],PARAMETER["Latitude_Of_Origin",8.416666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "Panama-Colon1911PanamaPolyconic",
    `PROJCS["Panama-Colon_1911_Panama_Polyconic",GEOGCS["GCS_Panama-Colon_1911",DATUM["D_Panama-Colon-1911",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polyconic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1092972.1],PARAMETER["Central_Meridian",-81.0],PARAMETER["Latitude_Of_Origin",8.25],UNIT["Yard_Clarke",0.9143917962]]`
  ],
  [
    "RSRGD2000MSLC2000",
    `PROJCS["RSRGD2000_MSLC2000",GEOGCS["GCS_RSRGD2000",DATUM["D_Ross_Sea_Region_Geodetic_Datum_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",7000000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",163.0],PARAMETER["Standard_Parallel_1",-76.66666666666667],PARAMETER["Standard_Parallel_2",-79.33333333333333],PARAMETER["Latitude_Of_Origin",-78.0],UNIT["Meter",1.0]]`
  ],
  [
    "RSRGD2000BCLC2000",
    `PROJCS["RSRGD2000_BCLC2000",GEOGCS["GCS_RSRGD2000",DATUM["D_Ross_Sea_Region_Geodetic_Datum_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Standard_Parallel_1",-73.66666666666667],PARAMETER["Standard_Parallel_2",-75.33333333333333],PARAMETER["Latitude_Of_Origin",-74.5],UNIT["Meter",1.0]]`
  ],
  [
    "RSRGD2000PCLC2000",
    `PROJCS["RSRGD2000_PCLC2000",GEOGCS["GCS_RSRGD2000",DATUM["D_Ross_Sea_Region_Geodetic_Datum_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",166.0],PARAMETER["Standard_Parallel_1",-70.66666666666667],PARAMETER["Standard_Parallel_2",-72.33333333333333],PARAMETER["Latitude_Of_Origin",-71.5],UNIT["Meter",1.0]]`
  ],
  [
    "RSRGD2000RSPS2000",
    `PROJCS["RSRGD2000_RSPS2000",GEOGCS["GCS_RSRGD2000",DATUM["D_Ross_Sea_Region_Geodetic_Datum_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Scale_Factor",0.994],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGAF09UTMZone20N",
    `PROJCS["RGAF09_UTM_Zone_20N",GEOGCS["GCS_RGAF09",DATUM["Reseau_Geodesique_des_Antilles_Francaises_2009",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "S-JTSKKrovak",
    `PROJCS["S-JTSK_Krovak",GEOGCS["GCS_S_JTSK",DATUM["D_S_JTSK",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Krovak"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Pseudo_Standard_Parallel_1",78.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",30.28813975277778],PARAMETER["Longitude_Of_Center",24.83333333333333],PARAMETER["Latitude_Of_Center",49.5],PARAMETER["X_Scale",1.0],PARAMETER["Y_Scale",1.0],PARAMETER["XY_Plane_Rotation",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "S-JTSKKrovakEastNorth",
    `PROJCS["S-JTSK_Krovak_East_North",GEOGCS["GCS_S_JTSK",DATUM["D_S_JTSK",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Krovak"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Pseudo_Standard_Parallel_1",78.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",30.28813975277778],PARAMETER["Longitude_Of_Center",24.83333333333333],PARAMETER["Latitude_Of_Center",49.5],PARAMETER["X_Scale",-1.0],PARAMETER["Y_Scale",1.0],PARAMETER["XY_Plane_Rotation",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "ChathamIsland1971MapGrid",
    `PROJCS["Chatham_Island_1971_Map_Grid",GEOGCS["GCS_Chatham_Island_1971",DATUM["D_Chatham_Island_1971",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",350000.0],PARAMETER["False_Northing",650000.0],PARAMETER["Central_Meridian",-176.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-44.0],UNIT["Meter",1.0]]`
  ],
  [
    "ChathamIslands1979MapGrid",
    `PROJCS["Chatham_Islands_1979_Map_Grid",GEOGCS["GCS_Chatham_Islands_1979",DATUM["D_Chatham_Islands_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",350000.0],PARAMETER["False_Northing",650000.0],PARAMETER["Central_Meridian",-176.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-44.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGaussZone1",
    `PROJCS["DHDN_3_Degree_Gauss_Zone_1",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMGabonTM2011",
    `PROJCS["WGS_1984_UTM_Gabon_TM_2011",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",11.5],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SAD196996BrazilPolyconic",
    `PROJCS["SAD_1969_96_Brazil_Polyconic",GEOGCS["GCS_SAD_1969_96",DATUM["D_South_American_Datum_1969_96",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polyconic"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-54.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD196996UTMZone21S",
    `PROJCS["SAD_1969_96_UTM_Zone_21S",GEOGCS["GCS_SAD_1969_96",DATUM["D_South_American_Datum_1969_96",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD196996UTMZone22S",
    `PROJCS["SAD_1969_96_UTM_Zone_22S",GEOGCS["GCS_SAD_1969_96",DATUM["D_South_American_Datum_1969_96",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD196996UTMZone23S",
    `PROJCS["SAD_1969_96_UTM_Zone_23S",GEOGCS["GCS_SAD_1969_96",DATUM["D_South_American_Datum_1969_96",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD196996UTMZone24S",
    `PROJCS["SAD_1969_96_UTM_Zone_24S",GEOGCS["GCS_SAD_1969_96",DATUM["D_South_American_Datum_1969_96",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD196996UTMZone25S",
    `PROJCS["SAD_1969_96_UTM_Zone_25S",GEOGCS["GCS_SAD_1969_96",DATUM["D_South_American_Datum_1969_96",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CorregoAlegre1961UTMZone21S",
    `PROJCS["Corrego_Alegre_1961_UTM_Zone_21S",GEOGCS["GCS_Corrego_Alegre_1961",DATUM["D_Corrego_Alegre_1961",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CorregoAlegre1961UTMZone22S",
    `PROJCS["Corrego_Alegre_1961_UTM_Zone_22S",GEOGCS["GCS_Corrego_Alegre_1961",DATUM["D_Corrego_Alegre_1961",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CorregoAlegre1961UTMZone23S",
    `PROJCS["Corrego_Alegre_1961_UTM_Zone_23S",GEOGCS["GCS_Corrego_Alegre_1961",DATUM["D_Corrego_Alegre_1961",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CorregoAlegre1961UTMZone24S",
    `PROJCS["Corrego_Alegre_1961_UTM_Zone_24S",GEOGCS["GCS_Corrego_Alegre_1961",DATUM["D_Corrego_Alegre_1961",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PNG94PNGMG94Zone54",
    `PROJCS["PNG94_PNGMG94_Zone_54",GEOGCS["GCS_PNG94",DATUM["D_Papua_New_Guinea_Geodetic_Datum_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PNG94PNGMG94Zone55",
    `PROJCS["PNG94_PNGMG94_Zone_55",GEOGCS["GCS_PNG94",DATUM["D_Papua_New_Guinea_Geodetic_Datum_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PNG94PNGMG94Zone56",
    `PROJCS["PNG94_PNGMG94_Zone_56",GEOGCS["GCS_PNG94",DATUM["D_Papua_New_Guinea_Geodetic_Datum_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ocotepeque1935GuatemalaNorte",
    `PROJCS["Ocotepeque_1935_Guatemala_Norte",GEOGCS["GCS_Ocotepeque_1935",DATUM["D_Ocotepeque_1935",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",292209.579],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Standard_Parallel_1",16.81666666666667],PARAMETER["Scale_Factor",0.99992226],PARAMETER["Latitude_Of_Origin",16.81666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000GKZone4",
    `PROJCS["Ukraine_2000_GK_Zone_4",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000GKZone5",
    `PROJCS["Ukraine_2000_GK_Zone_5",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000GKZone6",
    `PROJCS["Ukraine_2000_GK_Zone_6",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000GKZone7",
    `PROJCS["Ukraine_2000_GK_Zone_7",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000GKCM21E",
    `PROJCS["Ukraine_2000_GK_CM_21E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000GKCM27E",
    `PROJCS["Ukraine_2000_GK_CM_27E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000GKCM33E",
    `PROJCS["Ukraine_2000_GK_CM_33E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000GKCM39E",
    `PROJCS["Ukraine_2000_GK_CM_39E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKZone7",
    `PROJCS["Ukraine_2000_3_Degree_GK_Zone_7",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKZone8",
    `PROJCS["Ukraine_2000_3_Degree_GK_Zone_8",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKZone9",
    `PROJCS["Ukraine_2000_3_Degree_GK_Zone_9",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKZone10",
    `PROJCS["Ukraine_2000_3_Degree_GK_Zone_10",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKZone11",
    `PROJCS["Ukraine_2000_3_Degree_GK_Zone_11",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKZone12",
    `PROJCS["Ukraine_2000_3_Degree_GK_Zone_12",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKZone13",
    `PROJCS["Ukraine_2000_3_Degree_GK_Zone_13",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKCM21E",
    `PROJCS["Ukraine_2000_3_Degree_GK_CM_21E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKCM24E",
    `PROJCS["Ukraine_2000_3_Degree_GK_CM_24E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKCM27E",
    `PROJCS["Ukraine_2000_3_Degree_GK_CM_27E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKCM30E",
    `PROJCS["Ukraine_2000_3_Degree_GK_CM_30E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKCM33E",
    `PROJCS["Ukraine_2000_3_Degree_GK_CM_33E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKCM36E",
    `PROJCS["Ukraine_2000_3_Degree_GK_CM_36E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine20003DegreeGKCM39E",
    `PROJCS["Ukraine_2000_3_Degree_GK_CM_39E",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927NewBrunswickStereographic",
    `PROJCS["NAD_1927_New_Brunswick_Stereographic",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-66.5],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Foot",0.3048]]`
  ],
  [
    "SibunGorge1922ColonyGrid",
    `PROJCS["Sibun_Gorge_1922_Colony_Grid",GEOGCS["GCS_Sibun_Gorge_1922",DATUM["D_Sibun_Gorge_1922",SPHEROID["Clarke_1858",6378293.645208759,294.260676369]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",217259.26],PARAMETER["False_Northing",445474.83],PARAMETER["Central_Meridian",-88.6318575],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",17.06124194444444],UNIT["Foot_Clarke",0.3047972654]]`
  ],
  [
    "FEH2010FehmarnbeltTM",
    `PROJCS["FEH2010_Fehmarnbelt_TM",GEOGCS["GCS_FEH2010",DATUM["D_Fehmarnbelt_Datum_2010",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",11.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927StatePlaneMichiganEastOldFIPS2101",
    `PROJCS["NAD_1927_StatePlane_Michigan_East_Old_FIPS_2101",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-83.66666666666667],PARAMETER["Scale_Factor",0.9999428571],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMichiganCentralOldFIPS2102",
    `PROJCS["NAD_1927_StatePlane_Michigan_Central_Old_FIPS_2102",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Scale_Factor",0.9999090909],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMichiganWestOldFIPS2103",
    `PROJCS["NAD_1927_StatePlane_Michigan_West_Old_FIPS_2103",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.75],PARAMETER["Scale_Factor",0.9999090909],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "ED1950TM6NE",
    `PROJCS["ED_1950_TM_6_NE",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",6.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MoznetUTMZone38S",
    `PROJCS["Moznet_UTM_Zone_38S",GEOGCS["GCS_Moznet",DATUM["D_Moznet",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958GKZone2E-N",
    `PROJCS["Pulkovo_1942_Adj_1958_GK_Zone_2_E-N",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PTRA08LCCEurope",
    `PROJCS["PTRA08_LCC_Europe",GEOGCS["GCS_PTRA08",DATUM["D_PTRA08",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",2800000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",35.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "PTRA08LAEAEurope",
    `PROJCS["PTRA08_LAEA_Europe",GEOGCS["GCS_PTRA08",DATUM["D_PTRA08",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",4321000.0],PARAMETER["False_Northing",3210000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "REGCAN95LCCEurope",
    `PROJCS["REGCAN95_LCC_Europe",GEOGCS["GCS_REGCAN95",DATUM["D_Red_Geodesica_de_Canarias_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",2800000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",35.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "REGCAN95LAEAEurope",
    `PROJCS["REGCAN95_LAEA_Europe",GEOGCS["GCS_REGCAN95",DATUM["D_Red_Geodesica_de_Canarias_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",4321000.0],PARAMETER["False_Northing",3210000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREFLAEAEurope",
    `PROJCS["TUREF_LAEA_Europe",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",4321000.0],PARAMETER["False_Northing",3210000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "TUREFLCCEurope",
    `PROJCS["TUREF_LCC_Europe",GEOGCS["GCS_TUREF",DATUM["D_Turkish_National_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",2800000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",35.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "ISN2004LAEAEurope",
    `PROJCS["ISN_2004_LAEA_Europe",GEOGCS["GCS_ISN_2004",DATUM["D_Islands_Network_2004",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",4321000.0],PARAMETER["False_Northing",3210000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "ISN2004LCCEurope",
    `PROJCS["ISN_2004_LCC_Europe",GEOGCS["GCS_ISN_2004",DATUM["D_Islands_Network_2004",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",2800000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",35.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000BrazilMercator",
    `PROJCS["SIRGAS_2000_Brazil_Mercator",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-43.0],PARAMETER["Standard_Parallel_1",-2.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950SouthernPermianBasinLambert",
    `PROJCS["ED_1950_Southern_Permian_Basin_Lambert",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",815000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",52.66666666666666],PARAMETER["Standard_Parallel_2",54.33333333333334],PARAMETER["Latitude_Of_Origin",48.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGR1992UTM39S",
    `PROJCS["RGR_1992_UTM_39S",GEOGCS["GCS_RGR_1992",DATUM["D_RGR_1992",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneVermontFIPS4400Feet",
    `PROJCS["NAD_1983_StatePlane_Vermont_FIPS_4400_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "ETRS1989UTMZone31NzE-N",
    `PROJCS["ETRS_1989_UTM_Zone_31N_zE-N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone33NzE-N",
    `PROJCS["ETRS_1989_UTM_Zone_33N_zE-N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone31NN-zE",
    `PROJCS["ETRS_1989_UTM_Zone_31N_N-zE",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone32NN-zE",
    `PROJCS["ETRS_1989_UTM_Zone_32N_N-zE",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone33NN-zE",
    `PROJCS["ETRS_1989_UTM_Zone_33N_N-zE",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneVermontFIPS4400FtUS",
    `PROJCS["NAD_1983_HARN_StatePlane_Vermont_FIPS_4400_Ft_US",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneVermontFIPS4400FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Vermont_FIPS_4400_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "MonteMarioTMEmilia-Romagna",
    `PROJCS["Monte_Mario_TM_Emilia-Romagna",GEOGCS["GCS_Monte_Mario",DATUM["D_Monte_Mario",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500053.0],PARAMETER["False_Northing",-3999820.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1958GKZone3E-N",
    `PROJCS["Pulkovo_1942_Adj_1958_GK_Zone_3_E-N",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1983GKZone2E-N",
    `PROJCS["Pulkovo_1942_Adj_1983_GK_Zone_2_E-N",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj1983GKZone3E-N",
    `PROJCS["Pulkovo_1942_Adj_1983_GK_Zone_3_E-N",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PD/833DegreeGKZone3E-N",
    `PROJCS["PD/83_3_Degree_GK_Zone_3_E-N",GEOGCS["GCS_PD/83",DATUM["D_Potsdam_1983",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PD/833DegreeGKZone4E-N",
    `PROJCS["PD/83_3_Degree_GK_Zone_4_E-N",GEOGCS["GCS_PD/83",DATUM["D_Potsdam_1983",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RD/833DegreeGKZone4E-N",
    `PROJCS["RD/83_3_Degree_GK_Zone_4_E-N",GEOGCS["GCS_RD/83",DATUM["D_Rauenberg_1983",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RD/833DegreeGKZone5E-N",
    `PROJCS["RD/83_3_Degree_GK_Zone_5_E-N",GEOGCS["GCS_RD/83",DATUM["D_Rauenberg_1983",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone3E-N",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_3_E-N",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone4E-N",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_4_E-N",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19583DegreeGKZone5E-N",
    `PROJCS["Pulkovo_1942_Adj_1958_3_Degree_GK_Zone_5_E-N",GEOGCS["GCS_Pulkovo_1942_Adj_1958",DATUM["D_Pulkovo_1942_Adj_1958",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone3E-N",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_3_E-N",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone4E-N",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_4_E-N",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone5E-N",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_5_E-N",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGKZone2E-N",
    `PROJCS["DHDN_3_Degree_GK_Zone_2_E-N",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",6.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGKZone3E-N",
    `PROJCS["DHDN_3_Degree_GK_Zone_3_E-N",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGKZone4E-N",
    `PROJCS["DHDN_3_Degree_GK_Zone_4_E-N",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGKZone5E-N",
    `PROJCS["DHDN_3_Degree_GK_Zone_5_E-N",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGKZone1E-N",
    `PROJCS["DHDN_3_Degree_GK_Zone_1_E-N",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DBREF3-DegreeGKZone2(E-N)",
    `PROJCS["DB_REF_3-Degree_GK_Zone_2_(E-N)",GEOGCS["GCS_DB_REF",DATUM["D_Deutsche_Bahn_Reference_System",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",6.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DBREF3-DegreeGKZone3(E-N)",
    `PROJCS["DB_REF_3-Degree_GK_Zone_3_(E-N)",GEOGCS["GCS_DB_REF",DATUM["D_Deutsche_Bahn_Reference_System",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DBREF3-DegreeGKZone4(E-N)",
    `PROJCS["DB_REF_3-Degree_GK_Zone_4_(E-N)",GEOGCS["GCS_DB_REF",DATUM["D_Deutsche_Bahn_Reference_System",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DBREF3-DegreeGKZone5(E-N)",
    `PROJCS["DB_REF_3-Degree_GK_Zone_5_(E-N)",GEOGCS["GCS_DB_REF",DATUM["D_Deutsche_Bahn_Reference_System",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000UTMZone1S",
    `PROJCS["NZGD_2000_UTM_Zone_1S",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ACTStandardGrid",
    `PROJCS["AGD_1966_ACT_Standard_Grid",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",149.0092948305555],PARAMETER["Scale_Factor",1.000086],PARAMETER["Latitude_Of_Origin",-35.31773627777778],UNIT["Meter",1.0]]`
  ],
  [
    "YemenNGN1996UTMZone37N",
    `PROJCS["Yemen_NGN_1996_UTM_Zone_37N",GEOGCS["GCS_Yemen_NGN_1996",DATUM["D_Yemen_NGN_1996",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "YemenNGN1996UTMZone40N",
    `PROJCS["Yemen_NGN_1996_UTM_Zone_40N",GEOGCS["GCS_Yemen_NGN_1996",DATUM["D_Yemen_NGN_1996",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Peru96UTMZone17S",
    `PROJCS["Peru96_UTM_Zone_17S",GEOGCS["GCS_Peru96",DATUM["D_Peru96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984TM12SE",
    `PROJCS["WGS_1984_TM_12_SE",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone30",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_30",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD196996UTMZone22S",
    `PROJCS["SAD_1969_96_UTM_Zone_22S",GEOGCS["GCS_SAD_1969_96",DATUM["D_South_American_Datum_1969_96",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD196996UTMZone18S",
    `PROJCS["SAD_1969_96_UTM_Zone_18S",GEOGCS["GCS_SAD_1969_96",DATUM["D_South_American_Datum_1969_96",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD196996UTMZone19S",
    `PROJCS["SAD_1969_96_UTM_Zone_19S",GEOGCS["GCS_SAD_1969_96",DATUM["D_South_American_Datum_1969_96",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD196996UTMZone20S",
    `PROJCS["SAD_1969_96_UTM_Zone_20S",GEOGCS["GCS_SAD_1969_96",DATUM["D_South_American_Datum_1969_96",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Cadastre1997UTMZone38S",
    `PROJCS["Cadastre_1997_UTM_Zone_38S",GEOGCS["GCS_Cadastre_1997",DATUM["D_Cadastre_1997",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000BrazilPolyconic",
    `PROJCS["SIRGAS_2000_Brazil_Polyconic",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polyconic"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-54.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TGD2005TongaMapGrid",
    `PROJCS["TGD2005_Tonga_Map_Grid",GEOGCS["GCS_TGD2005",DATUM["D_Tonga_Geodetic_Datum_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JAXASnowDepthPolarStereographicNorth",
    `PROJCS["JAXA_Snow_Depth_Polar_Stereographic_North",GEOGCS["GCS_Hughes_1980",DATUM["D_Hughes_1980",SPHEROID["Hughes_1980",6378273.0,298.279411123064]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_North_Pole"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Standard_Parallel_1",70.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN2000TM-3zone481",
    `PROJCS["VN_2000_TM-3_zone_481",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN2000TM-3zone482",
    `PROJCS["VN_2000_TM-3_zone_482",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN2000TM-3zone491",
    `PROJCS["VN_2000_TM-3_zone_491",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN2000TM-3107-45",
    `PROJCS["VN_2000_TM-3_107-45",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",107.75],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneA1",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_A1",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",85.0],PARAMETER["Latitude_Of_Origin",81.317226],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneA2",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_A2",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",85.0],PARAMETER["Latitude_Of_Origin",81.317226],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneA3",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_A3",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",85.0],PARAMETER["Latitude_Of_Origin",81.317226],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneA4",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_A4",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",85.0],PARAMETER["Latitude_Of_Origin",81.317226],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneA5",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_A5",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",85.0],PARAMETER["Latitude_Of_Origin",81.317226],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneB1",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_B1",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Standard_Parallel_1",69.0],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",73.15574086111111],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneB2",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_B2",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Standard_Parallel_1",69.0],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",73.15574086111111],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneB3",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_B3",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Standard_Parallel_1",69.0],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",73.15574086111111],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneB4",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_B4",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Standard_Parallel_1",69.0],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",73.15574086111111],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneB5",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_B5",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Standard_Parallel_1",69.0],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",73.15574086111111],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneC1",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_C1",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Standard_Parallel_1",61.0],PARAMETER["Standard_Parallel_2",69.0],PARAMETER["Latitude_Of_Origin",65.10127088888889],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneC2",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_C2",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Standard_Parallel_1",61.0],PARAMETER["Standard_Parallel_2",69.0],PARAMETER["Latitude_Of_Origin",65.10127088888889],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneC3",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_C3",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Standard_Parallel_1",61.0],PARAMETER["Standard_Parallel_2",69.0],PARAMETER["Latitude_Of_Origin",65.10127088888889],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneC4",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_C4",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Standard_Parallel_1",61.0],PARAMETER["Standard_Parallel_2",69.0],PARAMETER["Latitude_Of_Origin",65.10127088888889],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticRegionalzoneC5",
    `PROJCS["WGS_1984_EPSG_Arctic_Regional_zone_C5",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Standard_Parallel_1",61.0],PARAMETER["Standard_Parallel_2",69.0],PARAMETER["Latitude_Of_Origin",65.10127088888889],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGAlaskaPolarStereographic",
    `PROJCS["WGS_1984_EPSG_Alaska_Polar_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Scale_Factor",0.994],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGCanadaPolarStereographic",
    `PROJCS["WGS_1984_EPSG_Canada_Polar_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Scale_Factor",0.994],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGGreenlandPolarStereographic",
    `PROJCS["WGS_1984_EPSG_Greenland_Polar_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.994],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGNorwayPolarStereographic",
    `PROJCS["WGS_1984_EPSG_Norway_Polar_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.994],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984EPSGRussiaPolarStereographic",
    `PROJCS["WGS_1984_EPSG_Russia_Polar_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.994],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "GR96EPSGArcticzone1-25",
    `PROJCS["GR96_EPSG_Arctic_zone_1-25",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",-30.0],PARAMETER["Standard_Parallel_1",83.66666666666667],PARAMETER["Standard_Parallel_2",87.0],PARAMETER["Latitude_Of_Origin",85.43711833333332],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone2-18",
    `PROJCS["GR96_EPSG_Arctic_zone_2-18",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",-52.0],PARAMETER["Standard_Parallel_1",80.33333333333333],PARAMETER["Standard_Parallel_2",83.66666666666667],PARAMETER["Latitude_Of_Origin",82.05842488888889],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone2-20",
    `PROJCS["GR96_EPSG_Arctic_zone_2-20",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",-12.0],PARAMETER["Standard_Parallel_1",80.33333333333333],PARAMETER["Standard_Parallel_2",83.66666666666667],PARAMETER["Latitude_Of_Origin",82.05842488888889],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone3-29",
    `PROJCS["GR96_EPSG_Arctic_zone_3-29",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone3-31",
    `PROJCS["GR96_EPSG_Arctic_zone_3-31",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone3-33",
    `PROJCS["GR96_EPSG_Arctic_zone_3-33",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",-10.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone4-20",
    `PROJCS["GR96_EPSG_Arctic_zone_4-20",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",-64.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone4-22",
    `PROJCS["GR96_EPSG_Arctic_zone_4-22",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone4-24",
    `PROJCS["GR96_EPSG_Arctic_zone_4-24",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",-14.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone5-41",
    `PROJCS["GR96_EPSG_Arctic_zone_5-41",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",41500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-62.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone5-43",
    `PROJCS["GR96_EPSG_Arctic_zone_5-43",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",43500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-42.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone5-45",
    `PROJCS["GR96_EPSG_Arctic_zone_5-45",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",45500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-22.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone6-26",
    `PROJCS["GR96_EPSG_Arctic_zone_6-26",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-56.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone6-28",
    `PROJCS["GR96_EPSG_Arctic_zone_6-28",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-38.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone6-30",
    `PROJCS["GR96_EPSG_Arctic_zone_6-30",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-20.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone7-11",
    `PROJCS["GR96_EPSG_Arctic_zone_7-11",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",7500000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Standard_Parallel_1",63.66666666666666],PARAMETER["Standard_Parallel_2",67.0],PARAMETER["Latitude_Of_Origin",65.35103930555557],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone7-13",
    `PROJCS["GR96_EPSG_Arctic_zone_7-13",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",7500000.0],PARAMETER["Central_Meridian",-34.0],PARAMETER["Standard_Parallel_1",63.66666666666666],PARAMETER["Standard_Parallel_2",67.0],PARAMETER["Latitude_Of_Origin",65.35103930555557],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone8-20",
    `PROJCS["GR96_EPSG_Arctic_zone_8-20",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",8500000.0],PARAMETER["Central_Meridian",-52.0],PARAMETER["Standard_Parallel_1",60.33333333333334],PARAMETER["Standard_Parallel_2",63.66666666666666],PARAMETER["Latitude_Of_Origin",62.01530688888887],UNIT["Meter",1.0]]`
  ],
  [
    "GR96EPSGArcticzone8-22",
    `PROJCS["GR96_EPSG_Arctic_zone_8-22",GEOGCS["GCS_Greenland_1996",DATUM["D_Greenland_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",8500000.0],PARAMETER["Central_Meridian",-37.0],PARAMETER["Standard_Parallel_1",60.33333333333334],PARAMETER["Standard_Parallel_2",63.66666666666666],PARAMETER["Latitude_Of_Origin",62.01530688888887],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989EPSGArcticzone2-22",
    `PROJCS["ETRS_1989_EPSG_Arctic_zone_2-22",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",16.0],PARAMETER["Standard_Parallel_1",80.33333333333333],PARAMETER["Standard_Parallel_2",83.66666666666667],PARAMETER["Latitude_Of_Origin",82.05842488888889],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989EPSGArcticzone3-11",
    `PROJCS["ETRS_1989_EPSG_Arctic_zone_3-11",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989EPSGArcticzone4-26",
    `PROJCS["ETRS_1989_EPSG_Arctic_zone_4-26",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989EPSGArcticzone4-28",
    `PROJCS["ETRS_1989_EPSG_Arctic_zone_4-28",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",34.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989EPSGArcticzone5-11",
    `PROJCS["ETRS_1989_EPSG_Arctic_zone_5-11",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",14.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989EPSGArcticzone5-13",
    `PROJCS["ETRS_1989_EPSG_Arctic_zone_5-13",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",34.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone2-24",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_2-24",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",53.0],PARAMETER["Standard_Parallel_1",80.33333333333333],PARAMETER["Standard_Parallel_2",83.66666666666667],PARAMETER["Latitude_Of_Origin",82.05842488888889],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone2-26",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_2-26",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Standard_Parallel_1",80.33333333333333],PARAMETER["Standard_Parallel_2",83.66666666666667],PARAMETER["Latitude_Of_Origin",82.05842488888889],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone3-13",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_3-13",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",52.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone3-15",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_3-15",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",83.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone3-17",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_3-17",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone3-19",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_3-19",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",145.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone4-30",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_4-30",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",58.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone4-32",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_4-32",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",82.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone4-34",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_4-34",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",34500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",106.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone4-36",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_4-36",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",36500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",130.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone4-38",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_4-38",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",38500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",154.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone4-40",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_4-40",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",40500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",179.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone5-15",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_5-15",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",54.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone5-17",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_5-17",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",74.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone5-19",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_5-19",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",95.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone5-21",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_5-21",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",116.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone5-23",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_5-23",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",137.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone5-25",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_5-25",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",158.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone5-27",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_5-27",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",179.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007EPSGArcticzone5-29",
    `PROJCS["NAD_1983_NSRS2007_EPSG_Arctic_zone_5-29",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-163.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007EPSGArcticzone5-31",
    `PROJCS["NAD_1983_NSRS2007_EPSG_Arctic_zone_5-31",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007EPSGArcticzone6-14",
    `PROJCS["NAD_1983_NSRS2007_EPSG_Arctic_zone_6-14",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007EPSGArcticzone6-16",
    `PROJCS["NAD_1983_NSRS2007_EPSG_Arctic_zone_6-16",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone1-23",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_1-23",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",83.66666666666667],PARAMETER["Standard_Parallel_2",87.0],PARAMETER["Latitude_Of_Origin",85.43711833333332],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone2-14",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_2-14",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",-115.0],PARAMETER["Standard_Parallel_1",80.33333333333333],PARAMETER["Standard_Parallel_2",83.66666666666667],PARAMETER["Latitude_Of_Origin",82.05842488888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone2-16",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_2-16",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Standard_Parallel_1",80.33333333333333],PARAMETER["Standard_Parallel_2",83.66666666666667],PARAMETER["Latitude_Of_Origin",82.05842488888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone3-25",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_3-25",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone3-27",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_3-27",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone3-29",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_3-29",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone4-14",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_4-14",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone4-16",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_4-16",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",-104.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone4-18",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_4-18",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone5-33",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_5-33",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-131.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone5-35",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_5-35",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",35500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone5-37",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_5-37",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",37500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-91.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone5-39",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_5-39",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",39500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-71.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone6-18",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_6-18",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-132.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone6-20",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_6-20",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-113.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone6-22",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_6-22",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSEPSGArcticzone6-24",
    `PROJCS["NAD_1983_CSRS_EPSG_Arctic_zone_6-24",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone1-27",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_1-27",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Standard_Parallel_1",83.66666666666667],PARAMETER["Standard_Parallel_2",87.0],PARAMETER["Latitude_Of_Origin",85.43711833333332],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone1-29",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_1-29",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Standard_Parallel_1",83.66666666666667],PARAMETER["Standard_Parallel_2",87.0],PARAMETER["Latitude_Of_Origin",85.43711833333332],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone1-31",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_1-31",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",150.0],PARAMETER["Standard_Parallel_1",83.66666666666667],PARAMETER["Standard_Parallel_2",87.0],PARAMETER["Latitude_Of_Origin",85.43711833333332],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone1-21",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_1-21",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Standard_Parallel_1",83.66666666666667],PARAMETER["Standard_Parallel_2",87.0],PARAMETER["Latitude_Of_Origin",85.43711833333332],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone2-28",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_2-28",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",133.0],PARAMETER["Standard_Parallel_1",80.33333333333333],PARAMETER["Standard_Parallel_2",83.66666666666667],PARAMETER["Latitude_Of_Origin",82.05842488888889],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone2-10",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_2-10",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",166.0],PARAMETER["Standard_Parallel_1",80.33333333333333],PARAMETER["Standard_Parallel_2",83.66666666666667],PARAMETER["Latitude_Of_Origin",82.05842488888889],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone2-12",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_2-12",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Standard_Parallel_1",80.33333333333333],PARAMETER["Standard_Parallel_2",83.66666666666667],PARAMETER["Latitude_Of_Origin",82.05842488888889],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone3-21",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_3-21",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",176.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone3-23",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_3-23",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",3500000.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Standard_Parallel_1",77.0],PARAMETER["Standard_Parallel_2",80.33333333333333],PARAMETER["Latitude_Of_Origin",78.70733752777778],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EPSGArcticzone4-12",
    `PROJCS["WGS_1984_EPSG_Arctic_zone_4-12",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",-155.0],PARAMETER["Standard_Parallel_1",73.66666666666669],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",75.36440330555556],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989EPSGArcticzone5-47",
    `PROJCS["ETRS_1989_EPSG_Arctic_zone_5-47",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",47500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-5.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "GrandCaymanNationalGrid1959",
    `PROJCS["Grand_Cayman_National_Grid_1959",GEOGCS["GCS_Grand_Cayman_1959",DATUM["D_Grand_Cayman_1959",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640419.9475],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot",0.3048]]`
  ],
  [
    "SisterIslandsNationalGrid1961",
    `PROJCS["Sister_Islands_National_Grid_1961",GEOGCS["GCS_Little_Cayman_1961",DATUM["D_Little_Cayman_1961",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640419.9475],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot",0.3048]]`
  ],
  [
    "CaymanIslandsNationalGrid2011",
    `PROJCS["Cayman_Islands_National_Grid_2011",GEOGCS["GCS_CIGD11",DATUM["D_Cayman_Islands_Geodetic_Datum_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2950000.0],PARAMETER["False_Northing",1900000.0],PARAMETER["Central_Meridian",-80.56666666666666],PARAMETER["Standard_Parallel_1",19.33333333333333],PARAMETER["Standard_Parallel_2",19.7],PARAMETER["Latitude_Of_Origin",19.33333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "MacedonianStateCoordinateSystem",
    `PROJCS["Macedonian_State_Coordinate_System",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone23N",
    `PROJCS["SIRGAS_2000_UTM_Zone_23N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone24N",
    `PROJCS["SIRGAS_2000_UTM_Zone_24N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAArauca2007",
    `PROJCS["MAGNA_Arauca_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1035263.443],PARAMETER["False_Northing",1275526.621],PARAMETER["Longitude_Of_Center",-70.75830965555555],PARAMETER["Latitude_Of_Center",7.087606391666666],PARAMETER["Height",100.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAArmeniaQuindio2006",
    `PROJCS["MAGNA_Armenia_Quindio_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1155824.666],PARAMETER["False_Northing",993087.465],PARAMETER["Longitude_Of_Center",-75.67348916666667],PARAMETER["Latitude_Of_Center",4.532325],PARAMETER["Height",1470.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNABarranquillaAtlantico1997",
    `PROJCS["MAGNA_Barranquilla_Atlantico_1997",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",917264.406],PARAMETER["False_Northing",1699839.935],PARAMETER["Longitude_Of_Center",-74.83433133333332],PARAMETER["Latitude_Of_Center",10.92318308333333],PARAMETER["Height",100.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNABogotaDC2005",
    `PROJCS["MAGNA_Bogota_DC_2005",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",92334.879],PARAMETER["False_Northing",109320.965],PARAMETER["Longitude_Of_Center",-74.14659166666668],PARAMETER["Latitude_Of_Center",4.680486111111112],PARAMETER["Height",2550.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNABucaramangaSantander2008",
    `PROJCS["MAGNA_Bucaramanga_Santander_2008",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1097241.305],PARAMETER["False_Northing",1274642.278],PARAMETER["Longitude_Of_Center",-73.19734322222223],PARAMETER["Latitude_Of_Center",7.078887141666667],PARAMETER["Height",931.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNACaliValledelCauca2009",
    `PROJCS["MAGNA_Cali_Valle_del_Cauca_2009",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1061900.18],PARAMETER["False_Northing",872364.63],PARAMETER["Longitude_Of_Center",-76.5205625],PARAMETER["Latitude_Of_Center",3.441883333333334],PARAMETER["Height",1000.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNACartagenaBolivar2005",
    `PROJCS["MAGNA_Cartagena_Bolivar_2005",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",842981.41],PARAMETER["False_Northing",1641887.09],PARAMETER["Longitude_Of_Center",-75.51120694444444],PARAMETER["Latitude_Of_Center",10.3970475],PARAMETER["Height",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNACucutaNortedeSantander2011",
    `PROJCS["MAGNA_Cucuta_Norte_de_Santander_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",842805.406],PARAMETER["False_Northing",1364404.57],PARAMETER["Longitude_Of_Center",-72.50287095],PARAMETER["Latitude_Of_Center",7.888936736111111],PARAMETER["Height",308.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAFlorenciaCaqueta2007",
    `PROJCS["MAGNA_Florencia_Caqueta_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1162300.348],PARAMETER["False_Northing",671068.716],PARAMETER["Longitude_Of_Center",-75.61911760277778],PARAMETER["Latitude_Of_Center",1.621012294444445],PARAMETER["Height",300.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAIbagueTolima2007",
    `PROJCS["MAGNA_Ibague_Tolima_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",877634.33],PARAMETER["False_Northing",980541.348],PARAMETER["Longitude_Of_Center",-75.17992593333334],PARAMETER["Latitude_Of_Center",4.419412827777778],PARAMETER["Height",1100.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAIniridaGuainia2008",
    `PROJCS["MAGNA_Inirida_Guainia_2008",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1019177.687],PARAMETER["False_Northing",491791.326],PARAMETER["Longitude_Of_Center",-67.90523208888889],PARAMETER["Latitude_Of_Center",3.845438183333334],PARAMETER["Height",96.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNALeticiaAmazonas1994",
    `PROJCS["MAGNA_Leticia_Amazonas_1994",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",25978.217],PARAMETER["False_Northing",27501.365],PARAMETER["Longitude_Of_Center",-69.94281105833333],PARAMETER["Latitude_Of_Center",-4.197684047222222],PARAMETER["Height",89.7],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAManizalesCaldas2011",
    `PROJCS["MAGNA_Manizales_Caldas_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1173727.04],PARAMETER["False_Northing",1052391.13],PARAMETER["Longitude_Of_Center",-75.51109472222223],PARAMETER["Latitude_Of_Center",5.068153888888888],PARAMETER["Height",2100.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAMedellinAntioquia2010",
    `PROJCS["MAGNA_Medellin_Antioquia_2010",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",835378.647],PARAMETER["False_Northing",1180816.875],PARAMETER["Longitude_Of_Center",-75.56488694444444],PARAMETER["Latitude_Of_Center",6.229208888888889],PARAMETER["Height",1510.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAMituVaupes2011",
    `PROJCS["MAGNA_Mitu_Vaupes_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1093717.398],PARAMETER["False_Northing",629997.236],PARAMETER["Longitude_Of_Center",-70.23546165555555],PARAMETER["Latitude_Of_Center",1.249969366666667],PARAMETER["Height",170.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAMocoaPutumayo2011",
    `PROJCS["MAGNA_Mocoa_Putumayo_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1047467.388],PARAMETER["False_Northing",617828.474],PARAMETER["Longitude_Of_Center",-76.65102121944444],PARAMETER["Latitude_Of_Center",1.140023358333333],PARAMETER["Height",655.2],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAMonteriaCordoba2006",
    `PROJCS["MAGNA_Monteria_Cordoba_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1131814.934],PARAMETER["False_Northing",1462131.119],PARAMETER["Longitude_Of_Center",-75.87955333055555],PARAMETER["Latitude_Of_Center",8.773085755555556],PARAMETER["Height",15.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNANeivaHuila2006",
    `PROJCS["MAGNA_Neiva_Huila_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",864476.923],PARAMETER["False_Northing",817199.827],PARAMETER["Longitude_Of_Center",-75.29643672222223],PARAMETER["Latitude_Of_Center",2.942415055555556],PARAMETER["Height",430.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAPastoNarino2008",
    `PROJCS["MAGNA_Pasto_Narino_2008",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",980469.695],PARAMETER["False_Northing",624555.332],PARAMETER["Longitude_Of_Center",-77.25312563333334],PARAMETER["Latitude_Of_Center",1.200989513888889],PARAMETER["Height",2530.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAPereiraRisaralda2007",
    `PROJCS["MAGNA_Pereira_Risaralda_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1153492.012],PARAMETER["False_Northing",1024195.255],PARAMETER["Longitude_Of_Center",-75.69395138888889],PARAMETER["Latitude_Of_Center",4.813593611111111],PARAMETER["Height",1500.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAPopayanCauca2006",
    `PROJCS["MAGNA_Popayan_Cauca_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1052430.525],PARAMETER["False_Northing",763366.548],PARAMETER["Longitude_Of_Center",-76.6060916361111],PARAMETER["Latitude_Of_Center",2.456159883333334],PARAMETER["Height",1740.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAPuertoCarrenoVichada2011",
    `PROJCS["MAGNA_Puerto_Carreno_Vichada_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1063834.703],PARAMETER["False_Northing",1175257.481],PARAMETER["Longitude_Of_Center",-67.50075024722223],PARAMETER["Latitude_Of_Center",6.18072141388889],PARAMETER["Height",51.58],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAQuibdoChoco2011",
    `PROJCS["MAGNA_Quibdo_Choco_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1047273.617],PARAMETER["False_Northing",1121443.09],PARAMETER["Longitude_Of_Center",-76.65075385833335],PARAMETER["Latitude_Of_Center",5.694247661111111],PARAMETER["Height",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNARiohachaLaGuajira2006",
    `PROJCS["MAGNA_Riohacha_La_Guajira_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1128154.73],PARAMETER["False_Northing",1767887.914],PARAMETER["Longitude_Of_Center",-72.90276886944444],PARAMETER["Latitude_Of_Center",11.53691332777778],PARAMETER["Height",6.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNASanAndres2007",
    `PROJCS["MAGNA_San_Andres_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",820439.298],PARAMETER["False_Northing",1877357.828],PARAMETER["Longitude_Of_Center",-81.72937595],PARAMETER["Latitude_Of_Center",12.523794325],PARAMETER["Height",6.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNASanJosedelGuaviare2011",
    `PROJCS["MAGNA_San_Jose_del_Guaviare_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1159876.62],PARAMETER["False_Northing",775380.342],PARAMETER["Longitude_Of_Center",-72.640033325],PARAMETER["Latitude_Of_Center",2.564078941666666],PARAMETER["Height",185.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNASantaMartaMagdalena2007",
    `PROJCS["MAGNA_Santa_Marta_Magdalena_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",983892.409],PARAMETER["False_Northing",1732533.518],PARAMETER["Longitude_Of_Center",-74.22500527777778],PARAMETER["Latitude_Of_Center",11.21964305555556],PARAMETER["Height",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNASucre2006",
    `PROJCS["MAGNA_Sucre_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",929043.607],PARAMETER["False_Northing",1466125.658],PARAMETER["Longitude_Of_Center",-74.722466825],PARAMETER["Latitude_Of_Center",8.810550366666668],PARAMETER["Height",20.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNATunjaBoyaca1997",
    `PROJCS["MAGNA_Tunja_Boyaca_1997",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1080514.91],PARAMETER["False_Northing",1103772.028],PARAMETER["Longitude_Of_Center",-73.3519389],PARAMETER["Latitude_Of_Center",5.534194738888889],PARAMETER["Height",2800.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAValleduparCesar2011",
    `PROJCS["MAGNA_Valledupar_Cesar_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1090979.66],PARAMETER["False_Northing",1647208.93],PARAMETER["Longitude_Of_Center",-73.2465713888889],PARAMETER["Latitude_Of_Center",10.44726111111111],PARAMETER["Height",200.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAVillavicencioMeta2011",
    `PROJCS["MAGNA_Villavicencio_Meta_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1050678.757],PARAMETER["False_Northing",950952.124],PARAMETER["Longitude_Of_Center",-73.62448598611111],PARAMETER["Latitude_Of_Center",4.1553751],PARAMETER["Height",427.19],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAYopalCasanare2006",
    `PROJCS["MAGNA_Yopal_Casanare_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",851184.177],PARAMETER["False_Northing",1083954.137],PARAMETER["Longitude_Of_Center",-72.42004027777779],PARAMETER["Latitude_Of_Center",5.353927222222222],PARAMETER["Height",300.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96SPCSPuertoRicoandVirginIslands",
    `PROJCS["NAD_1983_CORS96_SPCS_Puerto_Rico_and_Virgin_Islands",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-66.43333333333337],PARAMETER["Standard_Parallel_1",18.03333333333334],PARAMETER["Standard_Parallel_2",18.43333333333334],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "CGRS1993CyprusLocalTransverseMercator",
    `PROJCS["CGRS_1993_Cyprus_Local_Transverse_Mercator",GEOGCS["GCS_CGRS_1993",DATUM["D_Cyprus_Geodetic_Reference_System_1993",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",-3500000.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI1901Balkanszone7",
    `PROJCS["MGI_1901_Balkans_zone_7",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone59N",
    `PROJCS["NAD_1983_2011_UTM_Zone_59N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone60N",
    `PROJCS["NAD_1983_2011_UTM_Zone_60N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone1N",
    `PROJCS["NAD_1983_2011_UTM_Zone_1N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone2N",
    `PROJCS["NAD_1983_2011_UTM_Zone_2N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone3N",
    `PROJCS["NAD_1983_2011_UTM_Zone_3N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone4N",
    `PROJCS["NAD_1983_2011_UTM_Zone_4N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone5N",
    `PROJCS["NAD_1983_2011_UTM_Zone_5N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone6N",
    `PROJCS["NAD_1983_2011_UTM_Zone_6N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone7N",
    `PROJCS["NAD_1983_2011_UTM_Zone_7N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone8N",
    `PROJCS["NAD_1983_2011_UTM_Zone_8N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone9N",
    `PROJCS["NAD_1983_2011_UTM_Zone_9N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone10N",
    `PROJCS["NAD_1983_2011_UTM_Zone_10N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone11N",
    `PROJCS["NAD_1983_2011_UTM_Zone_11N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone12N",
    `PROJCS["NAD_1983_2011_UTM_Zone_12N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone13N",
    `PROJCS["NAD_1983_2011_UTM_Zone_13N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone14N",
    `PROJCS["NAD_1983_2011_UTM_Zone_14N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone15N",
    `PROJCS["NAD_1983_2011_UTM_Zone_15N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone16N",
    `PROJCS["NAD_1983_2011_UTM_Zone_16N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone17N",
    `PROJCS["NAD_1983_2011_UTM_Zone_17N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone18N",
    `PROJCS["NAD_1983_2011_UTM_Zone_18N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone19N",
    `PROJCS["NAD_1983_2011_UTM_Zone_19N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011ContiguousUSAAlbers",
    `PROJCS["NAD_1983_2011_Contiguous_USA_Albers",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",29.5],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",23.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011EPSGArcticzone5-29",
    `PROJCS["NAD_1983_2011_EPSG_Arctic_zone_5-29",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-163.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011EPSGArcticzone5-31",
    `PROJCS["NAD_1983_2011_EPSG_Arctic_zone_5-31",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",5500000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Standard_Parallel_1",70.33333333333333],PARAMETER["Standard_Parallel_2",73.66666666666669],PARAMETER["Latitude_Of_Origin",72.02500919444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011EPSGArcticzone6-14",
    `PROJCS["NAD_1983_2011_EPSG_Arctic_zone_6-14",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011EPSGArcticzone6-16",
    `PROJCS["NAD_1983_2011_EPSG_Arctic_zone_6-16",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",6500000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Standard_Parallel_1",67.0],PARAMETER["Standard_Parallel_2",70.33333333333333],PARAMETER["Latitude_Of_Origin",68.68747555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlabamaEastFIPS0101",
    `PROJCS["NAD_1983_2011_StatePlane_Alabama_East_FIPS_0101",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlabamaWestFIPS0102",
    `PROJCS["NAD_1983_2011_StatePlane_Alabama_West_FIPS_0102",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicoITRF92LCC",
    `PROJCS["Mexico_ITRF92_LCC",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-102.0],PARAMETER["Standard_Parallel_1",17.5],PARAMETER["Standard_Parallel_2",29.5],PARAMETER["Latitude_Of_Origin",12.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicoITRF2008UTMZone11N",
    `PROJCS["Mexico_ITRF2008_UTM_Zone_11N",GEOGCS["GCS_Mexico_ITRF2008",DATUM["D_Mexico_ITRF2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicoITRF2008UTMZone12N",
    `PROJCS["Mexico_ITRF2008_UTM_Zone_12N",GEOGCS["GCS_Mexico_ITRF2008",DATUM["D_Mexico_ITRF2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicoITRF2008UTMZone13N",
    `PROJCS["Mexico_ITRF2008_UTM_Zone_13N",GEOGCS["GCS_Mexico_ITRF2008",DATUM["D_Mexico_ITRF2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicoITRF2008UTMZone14N",
    `PROJCS["Mexico_ITRF2008_UTM_Zone_14N",GEOGCS["GCS_Mexico_ITRF2008",DATUM["D_Mexico_ITRF2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicoITRF2008UTMZone15N",
    `PROJCS["Mexico_ITRF2008_UTM_Zone_15N",GEOGCS["GCS_Mexico_ITRF2008",DATUM["D_Mexico_ITRF2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicoITRF2008UTMZone16N",
    `PROJCS["Mexico_ITRF2008_UTM_Zone_16N",GEOGCS["GCS_Mexico_ITRF2008",DATUM["D_Mexico_ITRF2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicoITRF2008LCC",
    `PROJCS["Mexico_ITRF2008_LCC",GEOGCS["GCS_Mexico_ITRF2008",DATUM["D_Mexico_ITRF2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-102.0],PARAMETER["Standard_Parallel_1",17.5],PARAMETER["Standard_Parallel_2",29.5],PARAMETER["Latitude_Of_Origin",12.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000TMZone7",
    `PROJCS["Ukraine_2000_TM_Zone_7",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000TMZone8",
    `PROJCS["Ukraine_2000_TM_Zone_8",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000TMZone9",
    `PROJCS["Ukraine_2000_TM_Zone_9",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000TMZone10",
    `PROJCS["Ukraine_2000_TM_Zone_10",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000TMZone11",
    `PROJCS["Ukraine_2000_TM_Zone_11",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000TMZone12",
    `PROJCS["Ukraine_2000_TM_Zone_12",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Ukraine2000TMZone13",
    `PROJCS["Ukraine_2000_TM_Zone_13",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CaymanIslandsNationalGrid2011",
    `PROJCS["Cayman_Islands_National_Grid_2011",GEOGCS["GCS_CIGD11",DATUM["D_Cayman_Islands_Geodetic_Datum_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2950000.0],PARAMETER["False_Northing",1900000.0],PARAMETER["Central_Meridian",-80.56666666666666],PARAMETER["Standard_Parallel_1",19.33333333333333],PARAMETER["Standard_Parallel_2",19.7],PARAMETER["Latitude_Of_Origin",19.33333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011AlaskaAlbers",
    `PROJCS["NAD_1983_2011_Alaska_Albers",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska1FIPS5001",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_1_FIPS_5001",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",-36.86989764583333],PARAMETER["Longitude_Of_Center",-133.6666666666667],PARAMETER["Latitude_Of_Center",57.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska2FIPS5002",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_2_FIPS_5002",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska3FIPS5003",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_3_FIPS_5003",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-146.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska4FIPS5004",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_4_FIPS_5004",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska5FIPS5005",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_5_FIPS_5005",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska6FIPS5006",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_6_FIPS_5006",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska7FIPS5007",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_7_FIPS_5007",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-162.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska8FIPS5008",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_8_FIPS_5008",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-166.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska9FIPS5009",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_9_FIPS_5009",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-170.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska10FIPS5010",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_10_FIPS_5010",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-176.0],PARAMETER["Standard_Parallel_1",51.83333333333334],PARAMETER["Standard_Parallel_2",53.83333333333334],PARAMETER["Latitude_Of_Origin",51.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArizonaCentralFIPS0202",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_Central_FIPS_0202",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArizonaCentralFIPS0202FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_Central_FIPS_0202_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneArizonaEastFIPS0201",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_East_FIPS_0201",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArizonaEastFIPS0201FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_East_FIPS_0201_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneArizonaWestFIPS0203",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_West_FIPS_0203",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArizonaWestFIPS0203FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_West_FIPS_0203_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneArkansasNorthFIPS0301",
    `PROJCS["NAD_1983_2011_StatePlane_Arkansas_North_FIPS_0301",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArkansasNorthFIPS0301FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Arkansas_North_FIPS_0301_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneArkansasSouthFIPS0302",
    `PROJCS["NAD_1983_2011_StatePlane_Arkansas_South_FIPS_0302",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArkansasSouthFIPS0302FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Arkansas_South_FIPS_0302_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011CaliforniaTealeAlbers",
    `PROJCS["NAD_1983_2011_California_Teale_Albers",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",34.0],PARAMETER["Standard_Parallel_2",40.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIFIPS0401",
    `PROJCS["NAD_1983_2011_StatePlane_California_I_FIPS_0401",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIFIPS0401FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_I_FIPS_0401_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIIFIPS0402",
    `PROJCS["NAD_1983_2011_StatePlane_California_II_FIPS_0402",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIIFIPS0402FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_II_FIPS_0402_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIIIFIPS0403",
    `PROJCS["NAD_1983_2011_StatePlane_California_III_FIPS_0403",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIIIFIPS0403FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_III_FIPS_0403_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIVFIPS0404",
    `PROJCS["NAD_1983_2011_StatePlane_California_IV_FIPS_0404",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIVFIPS0404FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_IV_FIPS_0404_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaVFIPS0405",
    `PROJCS["NAD_1983_2011_StatePlane_California_V_FIPS_0405",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaVFIPS0405FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_V_FIPS_0405_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaVIFIPS0406",
    `PROJCS["NAD_1983_2011_StatePlane_California_VI_FIPS_0406",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaVIFIPS0406FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_VI_FIPS_0406_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneColoradoCentralFIPS0502",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_Central_FIPS_0502",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneColoradoCentralFIPS0502FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_Central_FIPS_0502_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneColoradoNorthFIPS0501",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_North_FIPS_0501",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneColoradoNorthFIPS0501FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_North_FIPS_0501_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneColoradoSouthFIPS0503",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_South_FIPS_0503",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneColoradoSouthFIPS0503FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_South_FIPS_0503_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneConnecticutFIPS0600",
    `PROJCS["NAD_1983_2011_StatePlane_Connecticut_FIPS_0600",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",304800.6096],PARAMETER["False_Northing",152400.3048],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneConnecticutFIPS0600FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Connecticut_FIPS_0600_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",999999.999996],PARAMETER["False_Northing",499999.999998],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneDelawareFIPS0700",
    `PROJCS["NAD_1983_2011_StatePlane_Delaware_FIPS_0700",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneDelawareFIPS0700FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Delaware_FIPS_0700_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneFloridaEastFIPS0901",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_East_FIPS_0901",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneFloridaEastFIPS0901FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_East_FIPS_0901_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011FloridaGDLAlbers",
    `PROJCS["NAD_1983_2011_Florida_GDL_Albers",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Standard_Parallel_1",24.0],PARAMETER["Standard_Parallel_2",31.5],PARAMETER["Latitude_Of_Origin",24.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneFloridaNorthFIPS0903",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_North_FIPS_0903",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneFloridaNorthFIPS0903FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_North_FIPS_0903_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneFloridaWestFIPS0902",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_West_FIPS_0902",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneFloridaWestFIPS0902FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_West_FIPS_0902_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneGeorgiaEastFIPS1001",
    `PROJCS["NAD_1983_2011_StatePlane_Georgia_East_FIPS_1001",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneGeorgiaEastFIPS1001FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Georgia_East_FIPS_1001_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneGeorgiaWestFIPS1002",
    `PROJCS["NAD_1983_2011_StatePlane_Georgia_West_FIPS_1002",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneGeorgiaWestFIPS1002FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Georgia_West_FIPS_1002_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIdahoCentralFIPS1102",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_Central_FIPS_1102",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIdahoCentralFIPS1102FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_Central_FIPS_1102_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIdahoEastFIPS1101",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_East_FIPS_1101",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIdahoEastFIPS1101FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_East_FIPS_1101_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIdahoWestFIPS1103",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_West_FIPS_1103",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIdahoWestFIPS1103FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_West_FIPS_1103_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIllinoisEastFIPS1201",
    `PROJCS["NAD_1983_2011_StatePlane_Illinois_East_FIPS_1201",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIllinoisEastFIPS1201FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Illinois_East_FIPS_1201_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIllinoisWestFIPS1202",
    `PROJCS["NAD_1983_2011_StatePlane_Illinois_West_FIPS_1202",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIllinoisWestFIPS1202FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Illinois_West_FIPS_1202_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIndianaEastFIPS1301",
    `PROJCS["NAD_1983_2011_StatePlane_Indiana_East_FIPS_1301",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIndianaEastFIPS1301FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Indiana_East_FIPS_1301_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIndianaWestFIPS1302",
    `PROJCS["NAD_1983_2011_StatePlane_Indiana_West_FIPS_1302",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIndianaWestFIPS1302FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Indiana_West_FIPS_1302_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIowaNorthFIPS1401",
    `PROJCS["NAD_1983_2011_StatePlane_Iowa_North_FIPS_1401",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIowaNorthFIPS1401FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Iowa_North_FIPS_1401_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIowaSouthFIPS1402",
    `PROJCS["NAD_1983_2011_StatePlane_Iowa_South_FIPS_1402",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIowaSouthFIPS1402FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Iowa_South_FIPS_1402_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneKansasNorthFIPS1501",
    `PROJCS["NAD_1983_2011_StatePlane_Kansas_North_FIPS_1501",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneKansasNorthFIPS1501FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Kansas_North_FIPS_1501_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneKansasSouthFIPS1502",
    `PROJCS["NAD_1983_2011_StatePlane_Kansas_South_FIPS_1502",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneKansasSouthFIPS1502FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Kansas_South_FIPS_1502_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneKentuckyNorthFIPS1601",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_North_FIPS_1601",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneKentuckyNorthFIPS1601FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_North_FIPS_1601_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneKentuckyFIPS1600",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_FIPS_1600",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneKentuckyFIPS1600FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_FIPS_1600_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneKentuckySouthFIPS1602",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_South_FIPS_1602",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneKentuckySouthFIPS1602FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_South_FIPS_1602_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneLouisianaNorthFIPS1701",
    `PROJCS["NAD_1983_2011_StatePlane_Louisiana_North_FIPS_1701",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneLouisianaNorthFIPS1701FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Louisiana_North_FIPS_1701_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneLouisianaSouthFIPS1702",
    `PROJCS["NAD_1983_2011_StatePlane_Louisiana_South_FIPS_1702",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneLouisianaSouthFIPS1702FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Louisiana_South_FIPS_1702_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011Maine2000CentralZone",
    `PROJCS["NAD_1983_2011_Maine_2000_Central_Zone",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.125],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011Maine2000EastZone",
    `PROJCS["NAD_1983_2011_Maine_2000_East_Zone",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.875],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011Maine2000WestZone",
    `PROJCS["NAD_1983_2011_Maine_2000_West_Zone",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.375],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMaineEastFIPS1801",
    `PROJCS["NAD_1983_2011_StatePlane_Maine_East_FIPS_1801",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMaineEastFIPS1801FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Maine_East_FIPS_1801_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMaineWestFIPS1802",
    `PROJCS["NAD_1983_2011_StatePlane_Maine_West_FIPS_1802",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMaineWestFIPS1802FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Maine_West_FIPS_1802_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMarylandFIPS1900",
    `PROJCS["NAD_1983_2011_StatePlane_Maryland_FIPS_1900",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMarylandFIPS1900FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Maryland_FIPS_1900_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMassachusettsIslandFIPS2002",
    `PROJCS["NAD_1983_2011_StatePlane_Massachusetts_Island_FIPS_2002",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMassachusettsIslFIPS2002FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Massachusetts_Isl_FIPS_2002_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMassachusettsMainlandFIPS2001",
    `PROJCS["NAD_1983_2011_StatePlane_Massachusetts_Mainland_FIPS_2001",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",750000.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMassachusettsMnldFIPS2001FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Massachusetts_Mnld_FIPS_2001_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",2460625.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMichiganCentralFIPS2112",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_Central_FIPS_2112",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMichiganCentralFIPS2112FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_Central_FIPS_2112_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",19685039.37007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneMichiganNorthFIPS2111",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_North_FIPS_2111",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMichiganNorthFIPS2111FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_North_FIPS_2111_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",26246719.16010498],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011MichiganGeoRefMeters",
    `PROJCS["NAD_1983_2011_Michigan_GeoRef_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",2546731.496],PARAMETER["False_Northing",-4354009.816],PARAMETER["Scale_Factor",0.9996],PARAMETER["Azimuth",337.25556],PARAMETER["Longitude_Of_Center",-86.0],PARAMETER["Latitude_Of_Center",45.30916666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMichiganSouthFIPS2113",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_South_FIPS_2113",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMichiganSouthFIPS2113FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_South_FIPS_2113_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",13123359.58005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaCentralFIPS2202",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_Central_FIPS_2202",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaCentralFIPS2202FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_Central_FIPS_2202_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaNorthFIPS2201",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_North_FIPS_2201",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaNorthFIPS2201FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_North_FIPS_2201_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaSouthFIPS2203",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_South_FIPS_2203",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaSouthFIPS2203FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_South_FIPS_2203_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMississippiEastFIPS2301",
    `PROJCS["NAD_1983_2011_StatePlane_Mississippi_East_FIPS_2301",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMississippiEastFIPS2301FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Mississippi_East_FIPS_2301_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011MississippiTM",
    `PROJCS["NAD_1983_2011_Mississippi_TM",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",-89.75],PARAMETER["Scale_Factor",0.9998335],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMississippiWestFIPS2302",
    `PROJCS["NAD_1983_2011_StatePlane_Mississippi_West_FIPS_2302",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMississippiWestFIPS2302FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Mississippi_West_FIPS_2302_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMissouriCentralFIPS2402",
    `PROJCS["NAD_1983_2011_StatePlane_Missouri_Central_FIPS_2402",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMissouriEastFIPS2401",
    `PROJCS["NAD_1983_2011_StatePlane_Missouri_East_FIPS_2401",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMissouriWestFIPS2403",
    `PROJCS["NAD_1983_2011_StatePlane_Missouri_West_FIPS_2403",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",850000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.5],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMontanaFIPS2500",
    `PROJCS["NAD_1983_2011_StatePlane_Montana_FIPS_2500",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMontanaFIPS2500FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Montana_FIPS_2500_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneNebraskaFIPS2600",
    `PROJCS["NAD_1983_2011_StatePlane_Nebraska_FIPS_2600",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNevadaCentralFIPS2702",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_Central_FIPS_2702",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNevadaCentralFIPS2702FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_Central_FIPS_2702_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",19685000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNevadaEastFIPS2701",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_East_FIPS_2701",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",8000000.0],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNevadaEastFIPS2701FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_East_FIPS_2701_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",26246666.66666666],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNevadaWestFIPS2703",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_West_FIPS_2703",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNevadaWestFIPS2703FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_West_FIPS_2703_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewHampshireFIPS2800",
    `PROJCS["NAD_1983_2011_StatePlane_New_Hampshire_FIPS_2800",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewHampshireFIPS2800FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_Hampshire_FIPS_2800_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewJerseyFIPS2900",
    `PROJCS["NAD_1983_2011_StatePlane_New_Jersey_FIPS_2900",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewJerseyFIPS2900FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_Jersey_FIPS_2900_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoCentralFIPS3002",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_Central_FIPS_3002",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoCentralFIPS3002FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_Central_FIPS_3002_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoEastFIPS3001",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_East_FIPS_3001",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoEastFIPS3001FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_East_FIPS_3001_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",541337.5],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoWestFIPS3003",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_West_FIPS_3003",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",830000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoWestFIPS3003FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_West_FIPS_3003_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2723091.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkCentralFIPS3102",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_Central_FIPS_3102",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkCentralFIPS3102FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_Central_FIPS_3102_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",820208.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkEastFIPS3101",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_East_FIPS_3101",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkEastFIPS3101FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_East_FIPS_3101_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkLongIslandFIPS3104",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_Long_Island_FIPS_3104",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkLongIslFIPS3104FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_Long_Isl_FIPS_3104_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkWestFIPS3103",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_West_FIPS_3103",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",350000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkWestFIPS3103FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_West_FIPS_3103_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1148291.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNorthCarolinaFIPS3200",
    `PROJCS["NAD_1983_2011_StatePlane_North_Carolina_FIPS_3200",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609601.2192024384],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNorthCarolinaFIPS3200FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_North_Carolina_FIPS_3200_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNorthDakotaNorthFIPS3301",
    `PROJCS["NAD_1983_2011_StatePlane_North_Dakota_North_FIPS_3301",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNorthDakotaNorthFIPS3301FtI",
    `PROJCS["NAD_1983_2011_StatePlane_North_Dakota_North_FIPS_3301_FtI",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneNorthDakotaSouthFIPS3302",
    `PROJCS["NAD_1983_2011_StatePlane_North_Dakota_South_FIPS_3302",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNorthDakotaSouthFIPS3302FtI",
    `PROJCS["NAD_1983_2011_StatePlane_North_Dakota_South_FIPS_3302_FtI",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneOhioNorthFIPS3401",
    `PROJCS["NAD_1983_2011_StatePlane_Ohio_North_FIPS_3401",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOhioNorthFIPS3401FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Ohio_North_FIPS_3401_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneOhioSouthFIPS3402",
    `PROJCS["NAD_1983_2011_StatePlane_Ohio_South_FIPS_3402",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOhioSouthFIPS3402FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Ohio_South_FIPS_3402_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneOklahomaNorthFIPS3501",
    `PROJCS["NAD_1983_2011_StatePlane_Oklahoma_North_FIPS_3501",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOklahomaNorthFIPS3501FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Oklahoma_North_FIPS_3501_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneOklahomaSouthFIPS3502",
    `PROJCS["NAD_1983_2011_StatePlane_Oklahoma_South_FIPS_3502",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOklahomaSouthFIPS3502FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Oklahoma_South_FIPS_3502_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011OregonStatewideLambert",
    `PROJCS["NAD_1983_2011_Oregon_Statewide_Lambert",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011OregonStatewideLambertFtIntl",
    `PROJCS["NAD_1983_2011_Oregon_Statewide_Lambert_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312335.958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneOregonNorthFIPS3601",
    `PROJCS["NAD_1983_2011_StatePlane_Oregon_North_FIPS_3601",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOregonNorthFIPS3601FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Oregon_North_FIPS_3601_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8202099.737532808],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneOregonSouthFIPS3602",
    `PROJCS["NAD_1983_2011_StatePlane_Oregon_South_FIPS_3602",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOregonSouthFIPS3602FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Oregon_South_FIPS_3602_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921259.842519685],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlanePennsylvaniaNorthFIPS3701",
    `PROJCS["NAD_1983_2011_StatePlane_Pennsylvania_North_FIPS_3701",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlanePennsylvaniaNorthFIPS3701FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Pennsylvania_North_FIPS_3701_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlanePennsylvaniaSouthFIPS3702",
    `PROJCS["NAD_1983_2011_StatePlane_Pennsylvania_South_FIPS_3702",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlanePennsylvaniaSouthFIPS3702FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Pennsylvania_South_FIPS_3702_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlanePuertoRicoVirginIslsFIPS5200",
    `PROJCS["NAD_1983_2011_StatePlane_Puerto_Rico_Virgin_Isls_FIPS_5200",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneRhodeIslandFIPS3800",
    `PROJCS["NAD_1983_2011_StatePlane_Rhode_Island_FIPS_3800",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneRhodeIslandFIPS3800FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Rhode_Island_FIPS_3800_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneSouthCarolinaFIPS3900",
    `PROJCS["NAD_1983_2011_StatePlane_South_Carolina_FIPS_3900",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609600.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneSouthCarolinaFIPS3900FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_South_Carolina_FIPS_3900_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneSouthDakotaNorthFIPS4001",
    `PROJCS["NAD_1983_2011_StatePlane_South_Dakota_North_FIPS_4001",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneSouthDakotaNorthFIPS4001FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_South_Dakota_North_FIPS_4001_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneSouthDakotaSouthFIPS4002",
    `PROJCS["NAD_1983_2011_StatePlane_South_Dakota_South_FIPS_4002",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneSouthDakotaSouthFIPS4002FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_South_Dakota_South_FIPS_4002_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTennesseeFIPS4100",
    `PROJCS["NAD_1983_2011_StatePlane_Tennessee_FIPS_4100",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTennesseeFIPS4100FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Tennessee_FIPS_4100_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTexasCentralFIPS4203",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_Central_FIPS_4203",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasCentralFIPS4203FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_Central_FIPS_4203_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011TexasCentricMappingSystemAlbers",
    `PROJCS["NAD_1983_2011_Texas_Centric_Mapping_System_Albers",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011TexasCentricMappingSystemLambert",
    `PROJCS["NAD_1983_2011_Texas_Centric_Mapping_System_Lambert",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasNorthFIPS4201",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_North_FIPS_4201",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasNorthFIPS4201FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_North_FIPS_4201_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTexasNorthCentralFIPS4202",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_North_Central_FIPS_4202",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasNorthCentralFIPS4202FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_North_Central_FIPS_4202_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTexasSouthFIPS4205",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_South_FIPS_4205",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasSouthFIPS4205FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_South_FIPS_4205_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",16404166.66666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTexasSouthCentralFIPS4204",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_South_Central_FIPS_4204",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasSouthCentralFIPS4204FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_South_Central_FIPS_4204_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneVermontFIPS4400",
    `PROJCS["NAD_1983_2011_StatePlane_Vermont_FIPS_4400",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneVermontFIPS4400FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Vermont_FIPS_4400_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011VirginiaLambert",
    `PROJCS["NAD_1983_2011_Virginia_Lambert",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",37.0],PARAMETER["Standard_Parallel_2",39.5],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneVirginiaNorthFIPS4501",
    `PROJCS["NAD_1983_2011_StatePlane_Virginia_North_FIPS_4501",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneVirginiaNorthFIPS4501FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Virginia_North_FIPS_4501_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneVirginiaSouthFIPS4502",
    `PROJCS["NAD_1983_2011_StatePlane_Virginia_South_FIPS_4502",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneVirginiaSouthFIPS4502FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Virginia_South_FIPS_4502_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWashingtonNorthFIPS4601",
    `PROJCS["NAD_1983_2011_StatePlane_Washington_North_FIPS_4601",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWashingtonNorthFIPS4601FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Washington_North_FIPS_4601_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWashingtonSouthFIPS4602",
    `PROJCS["NAD_1983_2011_StatePlane_Washington_South_FIPS_4602",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWashingtonSouthFIPS4602FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Washington_South_FIPS_4602_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWestVirginiaNorthFIPS4701",
    `PROJCS["NAD_1983_2011_StatePlane_West_Virginia_North_FIPS_4701",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWestVirginiaNorthFIPS4701FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_West_Virginia_North_FIPS_4701_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWestVirginiaSouthFIPS4702",
    `PROJCS["NAD_1983_2011_StatePlane_West_Virginia_South_FIPS_4702",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWestVirginiaSouthFIPS4702FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_West_Virginia_South_FIPS_4702_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinCentralFIPS4802FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_Central_FIPS_4802_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinNorthFIPS4801",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_North_FIPS_4801",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinNorthFIPS4801FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_North_FIPS_4801_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinSouthFIPS4803",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_South_FIPS_4803",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinSouthFIPS4803FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_South_FIPS_4803_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WisconsinTM",
    `PROJCS["NAD_1983_2011_Wisconsin_TM",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",520000.0],PARAMETER["False_Northing",-4480000.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWyomingEastFIPS4901",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_East_FIPS_4901",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWyomingEastFIPS4901FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_East_FIPS_4901_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWyomingEastCentralFIPS4902",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_East_Central_FIPS_4902",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWyomingECentralFIPS4902FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_E_Central_FIPS_4902_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWyomingWestFIPS4904",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_West_FIPS_4904",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWyomingWestFIPS4904FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_West_FIPS_4904_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWyomingWestCentralFIPS4903",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_West_Central_FIPS_4903",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWyomingWCentralFIPS4903FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_W_Central_FIPS_4903_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneUtahCentralFIPS4302",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_Central_FIPS_4302",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneUtahNorthFIPS4301",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_North_FIPS_4301",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneUtahSouthFIPS4303",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_South_FIPS_4303",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2QuebecLambert",
    `PROJCS["NAD83(CSRS)v2_Quebec_Lambert",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983QuebecAlbers",
    `PROJCS["NAD_1983_Quebec_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2QuebecAlbers",
    `PROJCS["NAD83(CSRS)v2_Quebec_Albers",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneUtahCentralFIPS4302FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_Central_FIPS_4302_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneUtahNorthFIPS4301FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_North_FIPS_4301_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneUtahSouthFIPS4303FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_South_FIPS_4303_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii1FIPS5101",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_1_FIPS_5101",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-155.5],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",18.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii2FIPS5102",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_2_FIPS_5102",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-156.6666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",20.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii3FIPS5103",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_3_FIPS_5103",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii4FIPS5104",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_4_FIPS_5104",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.5],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii5FIPS5105",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_5_FIPS_5105",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.1666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",21.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii3FIPS5103Feet",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_3_FIPS_5103_Feet",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983PA11UTMZone4N",
    `PROJCS["NAD_1983_PA11_UTM_Zone_4N",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11UTMZone5N",
    `PROJCS["NAD_1983_PA11_UTM_Zone_5N",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11UTMZone2S",
    `PROJCS["NAD_1983_PA11_UTM_Zone_2S",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MA11GuamMapGrid",
    `PROJCS["NAD_1983_MA11_Guam_Map_Grid",GEOGCS["GCS_NAD_1983_MA11",DATUM["D_NAD_1983_MA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",144.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",13.5],UNIT["Meter",1.0]]`
  ],
  [
    "Karbala1979IraqNationalGrid",
    `PROJCS["Karbala_1979_Iraq_National_Grid",GEOGCS["GCS_Karbala_1979_Polservice",DATUM["D_Karbala_1979_Polservice",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",46.5],PARAMETER["Scale_Factor",0.9994],PARAMETER["Latitude_Of_Origin",29.02626833333333],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone1",
    `PROJCS["JGD_2011_Japan_Zone_1",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone2",
    `PROJCS["JGD_2011_Japan_Zone_2",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone3",
    `PROJCS["JGD_2011_Japan_Zone_3",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone4",
    `PROJCS["JGD_2011_Japan_Zone_4",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",133.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone5",
    `PROJCS["JGD_2011_Japan_Zone_5",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",134.3333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone6",
    `PROJCS["JGD_2011_Japan_Zone_6",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",136.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone7",
    `PROJCS["JGD_2011_Japan_Zone_7",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",137.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone8",
    `PROJCS["JGD_2011_Japan_Zone_8",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",138.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone9",
    `PROJCS["JGD_2011_Japan_Zone_9",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",139.8333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone10",
    `PROJCS["JGD_2011_Japan_Zone_10",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",140.8333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone11",
    `PROJCS["JGD_2011_Japan_Zone_11",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",140.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone12",
    `PROJCS["JGD_2011_Japan_Zone_12",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",142.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone13",
    `PROJCS["JGD_2011_Japan_Zone_13",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",144.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone14",
    `PROJCS["JGD_2011_Japan_Zone_14",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone15",
    `PROJCS["JGD_2011_Japan_Zone_15",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",127.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone16",
    `PROJCS["JGD_2011_Japan_Zone_16",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",124.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone17",
    `PROJCS["JGD_2011_Japan_Zone_17",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone18",
    `PROJCS["JGD_2011_Japan_Zone_18",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",136.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",20.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone19",
    `PROJCS["JGD_2011_Japan_Zone_19",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone51N",
    `PROJCS["JGD_2011_UTM_Zone_51N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone52N",
    `PROJCS["JGD_2011_UTM_Zone_52N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone53N",
    `PROJCS["JGD_2011_UTM_Zone_53N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone54N",
    `PROJCS["JGD_2011_UTM_Zone_54N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone55N",
    `PROJCS["JGD_2011_UTM_Zone_55N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984TM60SW",
    `PROJCS["WGS_1984_TM_60_SW",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-60.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RDN2008TM32",
    `PROJCS["RDN2008_TM32",GEOGCS["GCS_RDN2008",DATUM["D_Rete_Dinamica_Nazionale_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RDN2008TM33",
    `PROJCS["RDN2008_TM33",GEOGCS["GCS_RDN2008",DATUM["D_Rete_Dinamica_Nazionale_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RDN2008TM34",
    `PROJCS["RDN2008_TM34",GEOGCS["GCS_RDN2008",DATUM["D_Rete_Dinamica_Nazionale_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984CIG92",
    `PROJCS["WGS_1984_CIG92",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",105.625],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994CIG94",
    `PROJCS["GDA_1994_CIG94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",105.625],PARAMETER["Scale_Factor",1.00002514],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984CKIG92",
    `PROJCS["WGS_1984_CKIG92",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",1400000.0],PARAMETER["Central_Meridian",96.875],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994CKIG94",
    `PROJCS["GDA_1994_CKIG94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",96.875],PARAMETER["Scale_Factor",0.99999387],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAzone41",
    `PROJCS["GDA_1994_MGA_zone_41",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAzone42",
    `PROJCS["GDA_1994_MGA_zone_42",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAzone43",
    `PROJCS["GDA_1994_MGA_zone_43",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAzone44",
    `PROJCS["GDA_1994_MGA_zone_44",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone46",
    `PROJCS["GDA_1994_MGA_Zone_46",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone47",
    `PROJCS["GDA_1994_MGA_Zone_47",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone59",
    `PROJCS["GDA_1994_MGA_Zone_59",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBakerNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Baker_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.8333333333333],PARAMETER["Scale_Factor",1.00016],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBakerNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Baker_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",131233.5958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.8333333333333],PARAMETER["Scale_Factor",1.00016],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSBakerNAD19832011TMMeters",
    `PROJCS["OCRS_Baker_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.8333333333333],PARAMETER["Scale_Factor",1.00016],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBakerNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Baker_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",131233.5958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.8333333333333],PARAMETER["Scale_Factor",1.00016],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSBend-KlamathFallsNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Bend-Klamath_Falls_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.75],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBend-KlamathFallsNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Bend-Klamath_Falls_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.75],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSBend-KlamathFallsNAD19832011TMMeters",
    `PROJCS["OCRS_Bend-Klamath_Falls_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.75],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBend-KlamathFallsNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Bend-Klamath_Falls_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.75],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSBend-Redmond-PrinevilleNAD1983CORS96LCCMeters",
    `PROJCS["OCRS_Bend-Redmond-Prineville_NAD_1983_CORS96_LCC_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",130000.0],PARAMETER["Central_Meridian",-121.25],PARAMETER["Standard_Parallel_1",44.66666666666666],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",44.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBend-Redmond-PrinevilleNAD1983CORS96LCCFeetIntl",
    `PROJCS["OCRS_Bend-Redmond-Prineville_NAD_1983_CORS96_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",426509.186351706],PARAMETER["Central_Meridian",-121.25],PARAMETER["Standard_Parallel_1",44.66666666666666],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",44.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSBend-Redmond-PrinevilleNAD19832011LCCMeters",
    `PROJCS["OCRS_Bend-Redmond-Prineville_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",130000.0],PARAMETER["Central_Meridian",-121.25],PARAMETER["Standard_Parallel_1",44.66666666666666],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",44.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBend-Redmond-PrinevilleNAD19832011LCCFeetIntl",
    `PROJCS["OCRS_Bend-Redmond-Prineville_NAD_1983_2011_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",426509.186351706],PARAMETER["Central_Meridian",-121.25],PARAMETER["Standard_Parallel_1",44.66666666666666],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",44.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSBend-BurnsNAD1983CORS96LCCMeters",
    `PROJCS["OCRS_Bend-Burns_NAD_1983_CORS96_LCC_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120000.0],PARAMETER["False_Northing",60000.0],PARAMETER["Central_Meridian",-119.75],PARAMETER["Standard_Parallel_1",43.66666666666666],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBend-BurnsNAD1983CORS96LCCFeetIntl",
    `PROJCS["OCRS_Bend-Burns_NAD_1983_CORS96_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",393700.7874015748],PARAMETER["False_Northing",196850.3937007874],PARAMETER["Central_Meridian",-119.75],PARAMETER["Standard_Parallel_1",43.66666666666666],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSBend-BurnsNAD19832011LCCMeters",
    `PROJCS["OCRS_Bend-Burns_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120000.0],PARAMETER["False_Northing",60000.0],PARAMETER["Central_Meridian",-119.75],PARAMETER["Standard_Parallel_1",43.66666666666666],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBend-BurnsNAD19832011LCCFeetIntl",
    `PROJCS["OCRS_Bend-Burns_NAD_1983_2011_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",393700.7874015748],PARAMETER["False_Northing",196850.3937007874],PARAMETER["Central_Meridian",-119.75],PARAMETER["Standard_Parallel_1",43.66666666666666],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSCanyonville-GrantsPassNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Canyonville-Grants_Pass_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.00007],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSCanyonville-GrantsPassNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Canyonville-Grants_Pass_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",131233.5958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.00007],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSCanyonville-GrantsPassNAD19832011TMMeters",
    `PROJCS["OCRS_Canyonville-Grants_Pass_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.00007],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSCanyonville-GrantsPassNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Canyonville-Grants_Pass_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",131233.5958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.00007],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSColumbiaRiverEastNAD1983CORS96LCCMeters",
    `PROJCS["OCRS_Columbia_River_East_NAD_1983_CORS96_LCC_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",30000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.66666666666666],PARAMETER["Scale_Factor",1.000008],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSColumbiaRiverEastNAD1983CORS96LCCFeetIntl",
    `PROJCS["OCRS_Columbia_River_East_NAD_1983_CORS96_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",492125.9842519685],PARAMETER["False_Northing",98425.1968503937],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.66666666666666],PARAMETER["Scale_Factor",1.000008],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSColumbiaRiverEastNAD19832011LCCMeters",
    `PROJCS["OCRS_Columbia_River_East_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",30000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.66666666666666],PARAMETER["Scale_Factor",1.000008],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSColumbiaRiverEastNAD19832011LCCFeetIntl",
    `PROJCS["OCRS_Columbia_River_East_NAD_1983_2011_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",492125.9842519685],PARAMETER["False_Northing",98425.1968503937],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.66666666666666],PARAMETER["Scale_Factor",1.000008],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSColumbiaRiverWestNAD1983CORS96OMMeters",
    `PROJCS["OCRS_Columbia_River_West_NAD_1983_CORS96_OM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",7000000.0],PARAMETER["False_Northing",-3000000.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",-65.0],PARAMETER["Longitude_Of_Center",-123.0],PARAMETER["Latitude_Of_Center",45.91666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSColumbiaRiverWestNAD1983CORS96OMFeetIntl",
    `PROJCS["OCRS_Columbia_River_West_NAD_1983_CORS96_OM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",22965879.26509186],PARAMETER["False_Northing",-9842519.685039369],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",-65.0],PARAMETER["Longitude_Of_Center",-123.0],PARAMETER["Latitude_Of_Center",45.91666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSColumbiaRiverWestNAD19832011OMMeters",
    `PROJCS["OCRS_Columbia_River_West_NAD_1983_2011_OM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",7000000.0],PARAMETER["False_Northing",-3000000.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",-65.0],PARAMETER["Longitude_Of_Center",-123.0],PARAMETER["Latitude_Of_Center",45.91666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSColumbiaRiverWestNAD19832011OMFeetIntl",
    `PROJCS["OCRS_Columbia_River_West_NAD_1983_2011_OM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",22965879.26509186],PARAMETER["False_Northing",-9842519.685039369],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",-65.0],PARAMETER["Longitude_Of_Center",-123.0],PARAMETER["Latitude_Of_Center",45.91666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSCottageGrove-CanyonvilleNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Cottage_Grove-Canyonville_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSCottageGrove-CanyonvilleNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Cottage_Grove-Canyonville_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSCottageGrove-CanyonvilleNAD19832011TMMeters",
    `PROJCS["OCRS_Cottage_Grove-Canyonville_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSCottageGrove-CanyonvilleNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Cottage_Grove-Canyonville_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSDufur-MadrasNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Dufur-Madras_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.0],PARAMETER["Scale_Factor",1.00011],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSDufur-MadrasNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Dufur-Madras_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.0],PARAMETER["Scale_Factor",1.00011],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSDufur-MadrasNAD19832011TMMeters",
    `PROJCS["OCRS_Dufur-Madras_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.0],PARAMETER["Scale_Factor",1.00011],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSDufur-MadrasNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Dufur-Madras_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.0],PARAMETER["Scale_Factor",1.00011],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSEugeneNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Eugene_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.1666666666667],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",43.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSEugeneNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Eugene_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.1666666666667],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",43.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSEugeneNAD19832011TMMeters",
    `PROJCS["OCRS_Eugene_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.1666666666667],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",43.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSEugeneNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Eugene_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.1666666666667],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",43.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSGrantsPass-AshlandNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Grants_Pass-Ashland_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000043],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSGrantsPass-AshlandNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Grants_Pass-Ashland_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000043],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSGrantsPass-AshlandNAD19832011TMMeters",
    `PROJCS["OCRS_Grants_Pass-Ashland_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000043],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSGrantsPass-AshlandNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Grants_Pass-Ashland_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000043],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSGresham-WarmSpringsNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Gresham-Warm_Springs_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",10000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.3333333333333],PARAMETER["Scale_Factor",1.00005],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSGresham-WarmSpringsNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Gresham-Warm_Springs_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32808.39895013123],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.3333333333333],PARAMETER["Scale_Factor",1.00005],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSGresham-WarmSpringsNAD19832011TMMeters",
    `PROJCS["OCRS_Gresham-Warm_Springs_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",10000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.3333333333333],PARAMETER["Scale_Factor",1.00005],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSGresham-WarmSpringsNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Gresham-Warm_Springs_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32808.39895013123],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.3333333333333],PARAMETER["Scale_Factor",1.00005],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSLaGrandeNAD1983CORS96TMMeters",
    `PROJCS["OCRS_La_Grande_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Scale_Factor",1.00013],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSLaGrandeNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_La_Grande_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",131233.5958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Scale_Factor",1.00013],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSLaGrandeNAD19832011TMMeters",
    `PROJCS["OCRS_La_Grande_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Scale_Factor",1.00013],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSLaGrandeNAD19832011TMFeetIntl",
    `PROJCS["OCRS_La_Grande_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",131233.5958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Scale_Factor",1.00013],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSOntarioNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Ontario_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",1.0001],PARAMETER["Latitude_Of_Origin",43.25],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSOntarioNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Ontario_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",1.0001],PARAMETER["Latitude_Of_Origin",43.25],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSOntarioNAD19832011TMMeters",
    `PROJCS["OCRS_Ontario_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",1.0001],PARAMETER["Latitude_Of_Origin",43.25],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSOntarioNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Ontario_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",1.0001],PARAMETER["Latitude_Of_Origin",43.25],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSOregonCoastNAD1983CORS96OMMeters",
    `PROJCS["OCRS_Oregon_Coast_NAD_1983_CORS96_OM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",-300000.0],PARAMETER["False_Northing",-4600000.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",5.0],PARAMETER["Longitude_Of_Center",-124.05],PARAMETER["Latitude_Of_Center",44.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSOregonCoastNAD1983CORS96OMFeetIntl",
    `PROJCS["OCRS_Oregon_Coast_NAD_1983_CORS96_OM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",-984251.968503937],PARAMETER["False_Northing",-15091863.51706037],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",5.0],PARAMETER["Longitude_Of_Center",-124.05],PARAMETER["Latitude_Of_Center",44.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSOregonCoastNAD19832011OMMeters",
    `PROJCS["OCRS_Oregon_Coast_NAD_1983_2011_OM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",-300000.0],PARAMETER["False_Northing",-4600000.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",5.0],PARAMETER["Longitude_Of_Center",-124.05],PARAMETER["Latitude_Of_Center",44.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSOregonCoastNAD19832011OMFeetIntl",
    `PROJCS["OCRS_Oregon_Coast_NAD_1983_2011_OM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",-984251.968503937],PARAMETER["False_Northing",-15091863.51706037],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",5.0],PARAMETER["Longitude_Of_Center",-124.05],PARAMETER["Latitude_Of_Center",44.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPendletonNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Pendleton_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",60000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.1666666666667],PARAMETER["Scale_Factor",1.000045],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPendletonNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Pendleton_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",196850.3937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.1666666666667],PARAMETER["Scale_Factor",1.000045],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPendletonNAD19832011TMMeters",
    `PROJCS["OCRS_Pendleton_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",60000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.1666666666667],PARAMETER["Scale_Factor",1.000045],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPendletonNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Pendleton_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",196850.3937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.1666666666667],PARAMETER["Scale_Factor",1.000045],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPendleton-LaGrandeNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Pendleton-La_Grande_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",30000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.3333333333333],PARAMETER["Scale_Factor",1.000175],PARAMETER["Latitude_Of_Origin",45.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPendleton-LaGrandeNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Pendleton-La_Grande_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",98425.1968503937],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.3333333333333],PARAMETER["Scale_Factor",1.000175],PARAMETER["Latitude_Of_Origin",45.08333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPendleton-LaGrandeNAD19832011TMMeters",
    `PROJCS["OCRS_Pendleton-La_Grande_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",30000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.3333333333333],PARAMETER["Scale_Factor",1.000175],PARAMETER["Latitude_Of_Origin",45.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPendleton-LaGrandeNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Pendleton-La_Grande_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",98425.1968503937],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.3333333333333],PARAMETER["Scale_Factor",1.000175],PARAMETER["Latitude_Of_Origin",45.08333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPortlandNAD1983CORS96LCCMeters",
    `PROJCS["OCRS_Portland_NAD_1983_CORS96_LCC_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",-122.75],PARAMETER["Standard_Parallel_1",45.5],PARAMETER["Scale_Factor",1.000002],PARAMETER["Latitude_Of_Origin",45.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPortlandNAD1983CORS96LCCFeetIntl",
    `PROJCS["OCRS_Portland_NAD_1983_CORS96_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",328083.9895013123],PARAMETER["False_Northing",164041.9947506562],PARAMETER["Central_Meridian",-122.75],PARAMETER["Standard_Parallel_1",45.5],PARAMETER["Scale_Factor",1.000002],PARAMETER["Latitude_Of_Origin",45.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPortlandNAD19832011LCCMeters",
    `PROJCS["OCRS_Portland_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",-122.75],PARAMETER["Standard_Parallel_1",45.5],PARAMETER["Scale_Factor",1.000002],PARAMETER["Latitude_Of_Origin",45.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPortlandNAD19832011LCCFeetIntl",
    `PROJCS["OCRS_Portland_NAD_1983_2011_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",328083.9895013123],PARAMETER["False_Northing",164041.9947506562],PARAMETER["Central_Meridian",-122.75],PARAMETER["Standard_Parallel_1",45.5],PARAMETER["Scale_Factor",1.000002],PARAMETER["Latitude_Of_Origin",45.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSSalemNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Salem_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0833333333333],PARAMETER["Scale_Factor",1.00001],PARAMETER["Latitude_Of_Origin",44.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSSalemNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Salem_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0833333333333],PARAMETER["Scale_Factor",1.00001],PARAMETER["Latitude_Of_Origin",44.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSSalemNAD19832011TMMeters",
    `PROJCS["OCRS_Salem_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0833333333333],PARAMETER["Scale_Factor",1.00001],PARAMETER["Latitude_Of_Origin",44.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSSalemNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Salem_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0833333333333],PARAMETER["Scale_Factor",1.00001],PARAMETER["Latitude_Of_Origin",44.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSSantiamPassNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Santiam_Pass_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.5],PARAMETER["Scale_Factor",1.000155],PARAMETER["Latitude_Of_Origin",44.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSSantiamPassNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Santiam_Pass_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.5],PARAMETER["Scale_Factor",1.000155],PARAMETER["Latitude_Of_Origin",44.08333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSSantiamPassNAD19832011TMMeters",
    `PROJCS["OCRS_Santiam_Pass_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.5],PARAMETER["Scale_Factor",1.000155],PARAMETER["Latitude_Of_Origin",44.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSSantiamPassNAD19832011TMFeetIntl",
    `PROJCS["OCRS_Santiam_Pass_NAD_1983_2011_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.5],PARAMETER["Scale_Factor",1.000155],PARAMETER["Latitude_Of_Origin",44.08333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96OregonStatewideLambert",
    `PROJCS["NAD_1983_CORS96_Oregon_Statewide_Lambert",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96OregonStatewideLambertFtIntl",
    `PROJCS["NAD_1983_CORS96_Oregon_Statewide_Lambert_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312335.958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "ETRS1989Albania2010",
    `PROJCS["ETRS_1989_Albania_2010",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",20.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RDN2008Italyzone",
    `PROJCS["RDN2008_Italy_zone",GEOGCS["GCS_RDN2008",DATUM["D_Rete_Dinamica_Nazionale_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9985],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RDN2008Zone12",
    `PROJCS["RDN2008_Zone_12",GEOGCS["GCS_RDN2008",DATUM["D_Rete_Dinamica_Nazionale_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinCentralFIPS4802",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_Central_FIPS_4802",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNebraskaFIPS2600FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Nebraska_FIPS_2600_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneOregonNorthFIPS3601",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oregon_North_FIPS_3601",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneOregonNorthFIPS3601FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oregon_North_FIPS_3601_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8202099.737532808],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneOregonSouthFIPS3602",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oregon_South_FIPS_3602",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneOregonSouthFIPS3602FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oregon_South_FIPS_3602_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921259.842519685],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "SouthEastIsland1943UTMZone40N",
    `PROJCS["South_East_Island_1943_UTM_Zone_40N",GEOGCS["GCS_South_East_Island_1943",DATUM["D_South_East_Island_1943",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983KansasLCC",
    `PROJCS["NAD_1983_Kansas_LCC",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.25],PARAMETER["Standard_Parallel_1",37.5],PARAMETER["Standard_Parallel_2",39.5],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983KansasLCCftUS",
    `PROJCS["NAD_1983_Kansas_LCC_ftUS",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.3333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.25],PARAMETER["Standard_Parallel_1",37.5],PARAMETER["Standard_Parallel_2",39.5],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KansasLCC",
    `PROJCS["NAD_1983_2011_Kansas_LCC",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.25],PARAMETER["Standard_Parallel_1",37.5],PARAMETER["Standard_Parallel_2",39.5],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011KansasLCCftUS",
    `PROJCS["NAD_1983_2011_Kansas_LCC_ftUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.3333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.25],PARAMETER["Standard_Parallel_1",37.5],PARAMETER["Standard_Parallel_2",39.5],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "WGS1984EASE-Grid2.0North",
    `PROJCS["WGS_1984_EASE-Grid_2.0_North",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984EASE-Grid2.0South",
    `PROJCS["WGS_1984_EASE-Grid_2.0_South",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984EASE-Grid2.0Global",
    `PROJCS["WGS_1984_EASE-Grid_2.0_Global",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cylindrical_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",30.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "ETRS1989AlbaniaLCC2010",
    `PROJCS["ETRS_1989_Albania_LCC_2010",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",20.0],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "IsraeliGrid05",
    `PROJCS["Israeli_Grid_05",GEOGCS["IG05_Intermediate_CRS",DATUM["IG05_Intermediate_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",219529.584],PARAMETER["False_Northing",626907.39],PARAMETER["Central_Meridian",35.20451694444444],PARAMETER["Scale_Factor",1.0000067],PARAMETER["Latitude_Of_Origin",31.73439361111111],UNIT["Meter",1.0]]`
  ],
  [
    "IsraeliGrid05-12",
    `PROJCS["Israeli_Grid_05-12",GEOGCS["IG05(2012)_Intermediate_CRS",DATUM["IG05(2012)_Intermediate_Datum",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",219529.584],PARAMETER["False_Northing",626907.39],PARAMETER["Central_Meridian",35.20451694444444],PARAMETER["Scale_Factor",1.0000067],PARAMETER["Latitude_Of_Origin",31.73439361111111],UNIT["Meter",1.0]]`
  ],
  [
    "Nahrwan1934UTMzone37N",
    `PROJCS["Nahrwan_1934_UTM_zone_37N",GEOGCS["GCS_Nahrwan_1934",DATUM["D_Nahrwan_1934",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Nahrwan1934UTMzone38N",
    `PROJCS["Nahrwan_1934_UTM_zone_38N",GEOGCS["GCS_Nahrwan_1934",DATUM["D_Nahrwan_1934",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Nahrwan1934UTMzone39N",
    `PROJCS["Nahrwan_1934_UTM_zone_39N",GEOGCS["GCS_Nahrwan_1934",DATUM["D_Nahrwan_1934",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(2011)IaRCSzone1",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_1",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",9600000.0],PARAMETER["Central_Meridian",-95.25],PARAMETER["Standard_Parallel_1",43.2],PARAMETER["Scale_Factor",1.000052],PARAMETER["Latitude_Of_Origin",43.2],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone2",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_2",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",9800000.0],PARAMETER["Central_Meridian",-92.75],PARAMETER["Standard_Parallel_1",43.16666666666666],PARAMETER["Scale_Factor",1.000043],PARAMETER["Latitude_Of_Origin",43.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone3",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_3",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",8300000.0],PARAMETER["Central_Meridian",-91.2],PARAMETER["Scale_Factor",1.000035],PARAMETER["Latitude_Of_Origin",40.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone4",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_4",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",8600000.0],PARAMETER["Central_Meridian",-94.83333333333333],PARAMETER["Standard_Parallel_1",42.53333333333333],PARAMETER["Scale_Factor",1.000045],PARAMETER["Latitude_Of_Origin",42.53333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone5",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_5",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",8900000.0],PARAMETER["Central_Meridian",-92.25],PARAMETER["Standard_Parallel_1",42.65],PARAMETER["Scale_Factor",1.000032],PARAMETER["Latitude_Of_Origin",42.65],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone6",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_6",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",6600000.0],PARAMETER["Central_Meridian",-95.73333333333333],PARAMETER["Scale_Factor",1.000039],PARAMETER["Latitude_Of_Origin",40.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone7",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_7",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",6800000.0],PARAMETER["Central_Meridian",-94.63333333333334],PARAMETER["Scale_Factor",1.000045],PARAMETER["Latitude_Of_Origin",40.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone8",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_8",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",7000000.0],PARAMETER["Central_Meridian",-93.71666666666667],PARAMETER["Scale_Factor",1.000033],PARAMETER["Latitude_Of_Origin",40.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone9",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_9",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",7200000.0],PARAMETER["Central_Meridian",-92.81666666666666],PARAMETER["Scale_Factor",1.000027],PARAMETER["Latitude_Of_Origin",40.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone10",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_10",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",8000000.0],PARAMETER["Central_Meridian",-91.66666666666667],PARAMETER["Standard_Parallel_1",41.83333333333334],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",41.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone11",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_11",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",7600000.0],PARAMETER["Central_Meridian",-90.53333333333333],PARAMETER["Scale_Factor",1.000027],PARAMETER["Latitude_Of_Origin",40.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone12",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_12",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",6200000.0],PARAMETER["Central_Meridian",-93.75],PARAMETER["Standard_Parallel_1",40.91666666666666],PARAMETER["Scale_Factor",1.000037],PARAMETER["Latitude_Of_Origin",40.91666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone13",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_13",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",6400000.0],PARAMETER["Central_Meridian",-91.91666666666667],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",40.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)IaRCSzone14",
    `PROJCS["NAD_1983_(2011)_IaRCS_zone_14",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",6200000.0],PARAMETER["Central_Meridian",-91.25],PARAMETER["Scale_Factor",1.000018],PARAMETER["Latitude_Of_Origin",40.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "RGTAAF07UTMzone37S",
    `PROJCS["RGTAAF07_UTM_zone_37S",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGTAAF07UTMzone38S",
    `PROJCS["RGTAAF07_UTM_zone_38S",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGTAAF07UTMzone39S",
    `PROJCS["RGTAAF07_UTM_zone_39S",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGTAAF07UTMzone40S",
    `PROJCS["RGTAAF07_UTM_zone_40S",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGTAAF07UTMzone41S",
    `PROJCS["RGTAAF07_UTM_zone_41S",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGTAAF07UTMzone42S",
    `PROJCS["RGTAAF07_UTM_zone_42S",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGTAAF07UTMzone43S",
    `PROJCS["RGTAAF07_UTM_zone_43S",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGTAAF07UTMzone44S",
    `PROJCS["RGTAAF07_UTM_zone_44S",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGTAAF07TerreAdeliePolarStereographic",
    `PROJCS["RGTAAF07_Terre_Adelie_Polar_Stereographic",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polar_Stereographic_Variant_C"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Longitude_Of_Origin",140.0],PARAMETER["Standard_Parallel_1",-67.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSStMaryMeters",
    `PROJCS["NAD_1983_2011_RMTCRS_St_Mary_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.5],PARAMETER["Scale_Factor",1.00016],PARAMETER["Latitude_Of_Origin",48.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSBlackfeetMeters",
    `PROJCS["NAD_1983_2011_RMTCRS_Blackfeet_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.5],PARAMETER["Scale_Factor",1.00019],PARAMETER["Latitude_Of_Origin",48.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSMilkRiverMeters",
    `PROJCS["NAD_1983_2011_RMTCRS_Milk_River_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Standard_Parallel_1",48.5],PARAMETER["Scale_Factor",1.000145],PARAMETER["Latitude_Of_Origin",48.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSFortBelknapMeters",
    `PROJCS["NAD_1983_2011_RMTCRS_Fort_Belknap_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",150000.0],PARAMETER["Central_Meridian",-108.5],PARAMETER["Standard_Parallel_1",48.5],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",48.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSFortPeckAssiniboineMeters",
    `PROJCS["NAD_1983_2011_RMTCRS_Fort_Peck_Assiniboine_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",48.33333333333334],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",48.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSFortPeckSiouxMeters",
    `PROJCS["NAD_1983_2011_RMTCRS_Fort_Peck_Sioux_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",48.33333333333334],PARAMETER["Scale_Factor",1.00009],PARAMETER["Latitude_Of_Origin",48.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSCrowMeters",
    `PROJCS["NAD_1983_2011_RMTCRS_Crow_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.75],PARAMETER["Scale_Factor",1.000148],PARAMETER["Latitude_Of_Origin",44.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSBobcatMeters",
    `PROJCS["NAD_1983_2011_RMTCRS_Bobcat_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-111.25],PARAMETER["Standard_Parallel_1",46.25],PARAMETER["Scale_Factor",1.000185],PARAMETER["Latitude_Of_Origin",46.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSBillingsMeters",
    `PROJCS["NAD_1983_2011_RMTCRS_Billings_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",-108.4166666666667],PARAMETER["Standard_Parallel_1",45.78333333333333],PARAMETER["Scale_Factor",1.0001515],PARAMETER["Latitude_Of_Origin",45.78333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSWindRiverMeters",
    `PROJCS["NAD_1983_2011_RMTCRS_Wind_River_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.3333333333333],PARAMETER["Scale_Factor",1.00024],PARAMETER["Latitude_Of_Origin",42.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011RMTCRSStMaryFtIntl",
    `PROJCS["NAD_1983_2011_RMTCRS_St_Mary_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.9843],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.5],PARAMETER["Scale_Factor",1.00016],PARAMETER["Latitude_Of_Origin",48.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011RMTCRSBlackfeetFtIntl",
    `PROJCS["NAD_1983_2011_RMTCRS_Blackfeet_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.9895],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.5],PARAMETER["Scale_Factor",1.00019],PARAMETER["Latitude_Of_Origin",48.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011RMTCRSMilkRiverFtIntl",
    `PROJCS["NAD_1983_2011_RMTCRS_Milk_River_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",492125.9843],PARAMETER["False_Northing",656167.979],PARAMETER["Central_Meridian",-111.0],PARAMETER["Standard_Parallel_1",48.5],PARAMETER["Scale_Factor",1.000145],PARAMETER["Latitude_Of_Origin",48.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011RMTCRSFortBelknapFtIntl",
    `PROJCS["NAD_1983_2011_RMTCRS_Fort_Belknap_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656167.979],PARAMETER["False_Northing",492125.9843],PARAMETER["Central_Meridian",-108.5],PARAMETER["Standard_Parallel_1",48.5],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",48.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011RMTCRSFortPeckAssiniboineFtIntl",
    `PROJCS["NAD_1983_2011_RMTCRS_Fort_Peck_Assiniboine_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656167.979],PARAMETER["False_Northing",328083.9895],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",48.33333333333334],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",48.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011RMTCRSFortPeckSiouxFtIntl",
    `PROJCS["NAD_1983_2011_RMTCRS_Fort_Peck_Sioux_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",328083.9895],PARAMETER["False_Northing",164041.9938],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",48.33333333333334],PARAMETER["Scale_Factor",1.00009],PARAMETER["Latitude_Of_Origin",48.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011RMTCRSCrowFtIntl",
    `PROJCS["NAD_1983_2011_RMTCRS_Crow_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656167.979],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.75],PARAMETER["Scale_Factor",1.000148],PARAMETER["Latitude_Of_Origin",44.75],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011RMTCRSBobcatFtIntl",
    `PROJCS["NAD_1983_2011_RMTCRS_Bobcat_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",328083.9895],PARAMETER["False_Northing",328083.9895],PARAMETER["Central_Meridian",-111.25],PARAMETER["Standard_Parallel_1",46.25],PARAMETER["Scale_Factor",1.000185],PARAMETER["Latitude_Of_Origin",46.25],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011RMTCRSBillingsFtIntl",
    `PROJCS["NAD_1983_2011_RMTCRS_Billings_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656167.979],PARAMETER["False_Northing",164041.9948],PARAMETER["Central_Meridian",-108.4166666666667],PARAMETER["Standard_Parallel_1",45.78333333333333],PARAMETER["Scale_Factor",1.0001515],PARAMETER["Latitude_Of_Origin",45.78333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011RMTCRSWindRiver(ftUS)",
    `PROJCS["NAD_1983_2011_RMTCRS_Wind_River_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.3333333333333],PARAMETER["Scale_Factor",1.00024],PARAMETER["Latitude_Of_Origin",42.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011SanFranciscoCS13Meters",
    `PROJCS["NAD_1983_2011_San_Francisco_CS13_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",48000.0],PARAMETER["False_Northing",24000.0],PARAMETER["Central_Meridian",-122.45],PARAMETER["Scale_Factor",1.000007],PARAMETER["Latitude_Of_Origin",37.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011SanFranciscoCS13ftUS",
    `PROJCS["NAD_1983_2011_San_Francisco_CS13_ftUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",157480.0],PARAMETER["False_Northing",78740.0],PARAMETER["Central_Meridian",-122.45],PARAMETER["Scale_Factor",1.000007],PARAMETER["Latitude_Of_Origin",37.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Palestine1923PalestineGridTM",
    `PROJCS["Palestine_1923_Palestine_Grid_TM",GEOGCS["GCS_Palestine_1923",DATUM["D_Palestine_1923",SPHEROID["Clarke_1880_Benoit",6378300.789,293.4663155389802]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",170251.555],PARAMETER["False_Northing",126867.909],PARAMETER["Central_Meridian",35.21208055555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",31.73409694444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSAdams(m)",
    `PROJCS["NAD_1983_2011_InGCS_Adams_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-84.95],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",40.55],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSAdams(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Adams_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-84.95],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",40.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSAllen(m)",
    `PROJCS["NAD_1983_2011_InGCS_Allen_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.05],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",40.9],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSAllen(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Allen_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.05],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",40.9],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSBartholomew(m)",
    `PROJCS["NAD_1983_2011_InGCS_Bartholomew_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.85],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",39.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSBartholomew(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Bartholomew_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.85],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",39.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSBenton(m)",
    `PROJCS["NAD_1983_2011_InGCS_Benton_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.3],PARAMETER["Scale_Factor",1.000029],PARAMETER["Latitude_Of_Origin",40.45],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSBenton(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Benton_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.3],PARAMETER["Scale_Factor",1.000029],PARAMETER["Latitude_Of_Origin",40.45],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSBlackford-Delaware(m)",
    `PROJCS["NAD_1983_2011_InGCS_Blackford-Delaware_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.4],PARAMETER["Scale_Factor",1.000038],PARAMETER["Latitude_Of_Origin",40.05],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSBlackford-Delaware(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Blackford-Delaware_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.4],PARAMETER["Scale_Factor",1.000038],PARAMETER["Latitude_Of_Origin",40.05],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSBoone-Hendricks(m)",
    `PROJCS["NAD_1983_2011_InGCS_Boone-Hendricks_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.5],PARAMETER["Scale_Factor",1.000036],PARAMETER["Latitude_Of_Origin",39.6],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSBoone-Hendricks(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Boone-Hendricks_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.5],PARAMETER["Scale_Factor",1.000036],PARAMETER["Latitude_Of_Origin",39.6],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSBrown(m)",
    `PROJCS["NAD_1983_2011_InGCS_Brown_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.3],PARAMETER["Scale_Factor",1.00003],PARAMETER["Latitude_Of_Origin",39.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSBrown(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Brown_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.3],PARAMETER["Scale_Factor",1.00003],PARAMETER["Latitude_Of_Origin",39.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSCarroll(m)",
    `PROJCS["NAD_1983_2011_InGCS_Carroll_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.65],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",40.4],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSCarroll(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Carroll_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.65],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",40.4],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSCass(m)",
    `PROJCS["NAD_1983_2011_InGCS_Cass_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.4],PARAMETER["Scale_Factor",1.000028],PARAMETER["Latitude_Of_Origin",40.55],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSCass(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Cass_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.4],PARAMETER["Scale_Factor",1.000028],PARAMETER["Latitude_Of_Origin",40.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSClark-Floyd-Scott(m)",
    `PROJCS["NAD_1983_2011_InGCS_Clark-Floyd-Scott_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.6],PARAMETER["Scale_Factor",1.000021],PARAMETER["Latitude_Of_Origin",38.15],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSClark-Floyd-Scott(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Clark-Floyd-Scott_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.6],PARAMETER["Scale_Factor",1.000021],PARAMETER["Latitude_Of_Origin",38.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSClay(m)",
    `PROJCS["NAD_1983_2011_InGCS_Clay_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.15],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",39.15],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSClay(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Clay_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.15],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",39.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSClinton(m)",
    `PROJCS["NAD_1983_2011_InGCS_Clinton_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.6],PARAMETER["Scale_Factor",1.000032],PARAMETER["Latitude_Of_Origin",40.15],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSClinton(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Clinton_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.6],PARAMETER["Scale_Factor",1.000032],PARAMETER["Latitude_Of_Origin",40.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSCrawford-Lawrence-Orange(m)",
    `PROJCS["NAD_1983_2011_InGCS_Crawford-Lawrence-Orange_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.5],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",38.1],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSCrawford-Lawrence-Orange(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Crawford-Lawrence-Orange_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.5],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",38.1],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSDaviess-Greene(m)",
    `PROJCS["NAD_1983_2011_InGCS_Daviess-Greene_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.1],PARAMETER["Scale_Factor",1.000018],PARAMETER["Latitude_Of_Origin",38.45],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSDaviess-Greene(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Daviess-Greene_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.1],PARAMETER["Scale_Factor",1.000018],PARAMETER["Latitude_Of_Origin",38.45],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSDearborn-Ohio-Switzerland(m)",
    `PROJCS["NAD_1983_2011_InGCS_Dearborn-Ohio-Switzerland_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-84.9],PARAMETER["Scale_Factor",1.000029],PARAMETER["Latitude_Of_Origin",38.65],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSDearborn-Ohio-Switzerland(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Dearborn-Ohio-Switzerland_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-84.9],PARAMETER["Scale_Factor",1.000029],PARAMETER["Latitude_Of_Origin",38.65],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSDecatur-Rush(m)",
    `PROJCS["NAD_1983_2011_InGCS_Decatur-Rush_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.65],PARAMETER["Scale_Factor",1.000036],PARAMETER["Latitude_Of_Origin",39.1],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSDecatur-Rush(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Decatur-Rush_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.65],PARAMETER["Scale_Factor",1.000036],PARAMETER["Latitude_Of_Origin",39.1],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSDeKalb(m)",
    `PROJCS["NAD_1983_2011_InGCS_DeKalb_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-84.95],PARAMETER["Scale_Factor",1.000036],PARAMETER["Latitude_Of_Origin",41.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSDeKalb(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_DeKalb_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-84.95],PARAMETER["Scale_Factor",1.000036],PARAMETER["Latitude_Of_Origin",41.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSDubois-Martin(m)",
    `PROJCS["NAD_1983_2011_InGCS_Dubois-Martin_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.95],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",38.2],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSDubois-Martin(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Dubois-Martin_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.95],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",38.2],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSElkhart-Kosciusko-Wabash(m)",
    `PROJCS["NAD_1983_2011_InGCS_Elkhart-Kosciusko-Wabash_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.85],PARAMETER["Scale_Factor",1.000033],PARAMETER["Latitude_Of_Origin",40.65],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSElkhart-Kosciusko-Wabash(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Elkhart-Kosciusko-Wabash_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.85],PARAMETER["Scale_Factor",1.000033],PARAMETER["Latitude_Of_Origin",40.65],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSFayette-Franklin-Union(m)",
    `PROJCS["NAD_1983_2011_InGCS_Fayette-Franklin-Union_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.05],PARAMETER["Scale_Factor",1.000038],PARAMETER["Latitude_Of_Origin",39.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSFayette-Franklin-Union(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Fayette-Franklin-Union_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.05],PARAMETER["Scale_Factor",1.000038],PARAMETER["Latitude_Of_Origin",39.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSFountain-Warren(m)",
    `PROJCS["NAD_1983_2011_InGCS_Fountain-Warren_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.3],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",39.95],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSFountain-Warren(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Fountain-Warren_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.3],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",39.95],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSFulton-Marshall-StJoseph(m)",
    `PROJCS["NAD_1983_2011_InGCS_Fulton-Marshall-St_Joseph_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.3],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",40.9],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSFulton-Marshall-StJoseph(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Fulton-Marshall-St_Joseph_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.3],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",40.9],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSGibson(m)",
    `PROJCS["NAD_1983_2011_InGCS_Gibson_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.65],PARAMETER["Scale_Factor",1.000013],PARAMETER["Latitude_Of_Origin",38.15],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSGibson(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Gibson_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.65],PARAMETER["Scale_Factor",1.000013],PARAMETER["Latitude_Of_Origin",38.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSGrant(m)",
    `PROJCS["NAD_1983_2011_InGCS_Grant_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.7],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",40.35],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSGrant(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Grant_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.7],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",40.35],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSHamilton-Tipton(m)",
    `PROJCS["NAD_1983_2011_InGCS_Hamilton-Tipton_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",39.9],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSHamilton-Tipton(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Hamilton-Tipton_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",39.9],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSHancock-Madison(m)",
    `PROJCS["NAD_1983_2011_InGCS_Hancock-Madison_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.8],PARAMETER["Scale_Factor",1.000036],PARAMETER["Latitude_Of_Origin",39.65],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSHancock-Madison(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Hancock-Madison_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.8],PARAMETER["Scale_Factor",1.000036],PARAMETER["Latitude_Of_Origin",39.65],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSHarrison-Washington(m)",
    `PROJCS["NAD_1983_2011_InGCS_Harrison-Washington_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.15],PARAMETER["Scale_Factor",1.000027],PARAMETER["Latitude_Of_Origin",37.95],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSHarrison-Washington(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Harrison-Washington_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.15],PARAMETER["Scale_Factor",1.000027],PARAMETER["Latitude_Of_Origin",37.95],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSHenry(m)",
    `PROJCS["NAD_1983_2011_InGCS_Henry_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.45],PARAMETER["Scale_Factor",1.000043],PARAMETER["Latitude_Of_Origin",39.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSHenry(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Henry_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.45],PARAMETER["Scale_Factor",1.000043],PARAMETER["Latitude_Of_Origin",39.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSHoward-Miami(m)",
    `PROJCS["NAD_1983_2011_InGCS_Howard-Miami_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.15],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",40.35],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSHoward-Miami(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Howard-Miami_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.15],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",40.35],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSHuntington-Whitley(m)",
    `PROJCS["NAD_1983_2011_InGCS_Huntington-Whitley_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.5],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",40.65],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSHuntington-Whitley(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Huntington-Whitley_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.5],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",40.65],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSJackson(m)",
    `PROJCS["NAD_1983_2011_InGCS_Jackson_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.95],PARAMETER["Scale_Factor",1.000022],PARAMETER["Latitude_Of_Origin",38.7],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSJackson(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Jackson_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.95],PARAMETER["Scale_Factor",1.000022],PARAMETER["Latitude_Of_Origin",38.7],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSJasper-Porter(m)",
    `PROJCS["NAD_1983_2011_InGCS_Jasper-Porter_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.1],PARAMETER["Scale_Factor",1.000027],PARAMETER["Latitude_Of_Origin",40.7],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSJasper-Porter(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Jasper-Porter_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.1],PARAMETER["Scale_Factor",1.000027],PARAMETER["Latitude_Of_Origin",40.7],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSJay(m)",
    `PROJCS["NAD_1983_2011_InGCS_Jay_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.0],PARAMETER["Scale_Factor",1.000038],PARAMETER["Latitude_Of_Origin",40.3],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSJay(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Jay_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.0],PARAMETER["Scale_Factor",1.000038],PARAMETER["Latitude_Of_Origin",40.3],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSJefferson(m)",
    `PROJCS["NAD_1983_2011_InGCS_Jefferson_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.35],PARAMETER["Scale_Factor",1.000028],PARAMETER["Latitude_Of_Origin",38.55],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSJefferson(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Jefferson_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.35],PARAMETER["Scale_Factor",1.000028],PARAMETER["Latitude_Of_Origin",38.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSJennings(m)",
    `PROJCS["NAD_1983_2011_InGCS_Jennings_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.8],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",38.8],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSJennings(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Jennings_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.8],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",38.8],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSJohnson-Marion(m)",
    `PROJCS["NAD_1983_2011_InGCS_Johnson-Marion_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.15],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",39.3],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSJohnson-Marion(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Johnson-Marion_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.15],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",39.3],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSKnox(m)",
    `PROJCS["NAD_1983_2011_InGCS_Knox_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.45],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",38.4],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSKnox(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Knox_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.45],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",38.4],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSLaGrange-Noble(m)",
    `PROJCS["NAD_1983_2011_InGCS_LaGrange-Noble_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.45],PARAMETER["Scale_Factor",1.000037],PARAMETER["Latitude_Of_Origin",41.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSLaGrange-Noble(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_LaGrange-Noble_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.45],PARAMETER["Scale_Factor",1.000037],PARAMETER["Latitude_Of_Origin",41.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSLake-Newton(m)",
    `PROJCS["NAD_1983_2011_InGCS_Lake-Newton_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.4],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",40.7],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSLake-Newton(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Lake-Newton_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.4],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",40.7],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSLaPorte-Pulaski-Starke(m)",
    `PROJCS["NAD_1983_2011_InGCS_LaPorte-Pulaski-Starke_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.75],PARAMETER["Scale_Factor",1.000027],PARAMETER["Latitude_Of_Origin",40.9],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSLaPorte-Pulaski-Starke(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_LaPorte-Pulaski-Starke_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.75],PARAMETER["Scale_Factor",1.000027],PARAMETER["Latitude_Of_Origin",40.9],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSMonroe-Morgan(m)",
    `PROJCS["NAD_1983_2011_InGCS_Monroe-Morgan_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.5],PARAMETER["Scale_Factor",1.000028],PARAMETER["Latitude_Of_Origin",38.95],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSMonroe-Morgan(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Monroe-Morgan_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.5],PARAMETER["Scale_Factor",1.000028],PARAMETER["Latitude_Of_Origin",38.95],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSMontgomery-Putnam(m)",
    `PROJCS["NAD_1983_2011_InGCS_Montgomery-Putnam_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.95],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",39.45],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSMontgomery-Putnam(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Montgomery-Putnam_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.95],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",39.45],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSOwen(m)",
    `PROJCS["NAD_1983_2011_InGCS_Owen_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.9],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",39.15],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSOwen(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Owen_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.9],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",39.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSParke-Vermillion(m)",
    `PROJCS["NAD_1983_2011_InGCS_Parke-Vermillion_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.35],PARAMETER["Scale_Factor",1.000022],PARAMETER["Latitude_Of_Origin",39.6],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSParke-Vermillion(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Parke-Vermillion_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.35],PARAMETER["Scale_Factor",1.000022],PARAMETER["Latitude_Of_Origin",39.6],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSPerry(m)",
    `PROJCS["NAD_1983_2011_InGCS_Perry_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.7],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",37.8],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSPerry(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Perry_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.7],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",37.8],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSPike-Warrick(m)",
    `PROJCS["NAD_1983_2011_InGCS_Pike-Warrick_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.3],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",37.85],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSPike-Warrick(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Pike-Warrick_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.3],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",37.85],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSPosey(m)",
    `PROJCS["NAD_1983_2011_InGCS_Posey_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.95],PARAMETER["Scale_Factor",1.000013],PARAMETER["Latitude_Of_Origin",37.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSPosey(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Posey_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.95],PARAMETER["Scale_Factor",1.000013],PARAMETER["Latitude_Of_Origin",37.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSRandolph-Wayne(m)",
    `PROJCS["NAD_1983_2011_InGCS_Randolph-Wayne_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.05],PARAMETER["Scale_Factor",1.000044],PARAMETER["Latitude_Of_Origin",39.7],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSRandolph-Wayne(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Randolph-Wayne_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.05],PARAMETER["Scale_Factor",1.000044],PARAMETER["Latitude_Of_Origin",39.7],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSRipley(m)",
    `PROJCS["NAD_1983_2011_InGCS_Ripley_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.3],PARAMETER["Scale_Factor",1.000038],PARAMETER["Latitude_Of_Origin",38.9],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSRipley(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Ripley_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.3],PARAMETER["Scale_Factor",1.000038],PARAMETER["Latitude_Of_Origin",38.9],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSShelby(m)",
    `PROJCS["NAD_1983_2011_InGCS_Shelby_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.9],PARAMETER["Scale_Factor",1.00003],PARAMETER["Latitude_Of_Origin",39.3],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSShelby(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Shelby_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.9],PARAMETER["Scale_Factor",1.00003],PARAMETER["Latitude_Of_Origin",39.3],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSSpencer(m)",
    `PROJCS["NAD_1983_2011_InGCS_Spencer_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.05],PARAMETER["Scale_Factor",1.000014],PARAMETER["Latitude_Of_Origin",37.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSSpencer(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Spencer_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.05],PARAMETER["Scale_Factor",1.000014],PARAMETER["Latitude_Of_Origin",37.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSSteuben(m)",
    `PROJCS["NAD_1983_2011_InGCS_Steuben_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.0],PARAMETER["Scale_Factor",1.000041],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSSteuben(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Steuben_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.0],PARAMETER["Scale_Factor",1.000041],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSSullivan(m)",
    `PROJCS["NAD_1983_2011_InGCS_Sullivan_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",1.000017],PARAMETER["Latitude_Of_Origin",38.9],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSSullivan(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Sullivan_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",1.000017],PARAMETER["Latitude_Of_Origin",38.9],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSTippecanoe-White(m)",
    `PROJCS["NAD_1983_2011_InGCS_Tippecanoe-White_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-86.9],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",40.2],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSTippecanoe-White(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Tippecanoe-White_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-86.9],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",40.2],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSVanderburgh(m)",
    `PROJCS["NAD_1983_2011_InGCS_Vanderburgh_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",37.8],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSVanderburgh(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Vanderburgh_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",37.8],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSVigo(m)",
    `PROJCS["NAD_1983_2011_InGCS_Vigo_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-87.45],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",39.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSVigo(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Vigo_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-87.45],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",39.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011InGCSWells(m)",
    `PROJCS["NAD_1983_2011_InGCS_Wells_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",240000.0],PARAMETER["False_Northing",36000.0],PARAMETER["Central_Meridian",-85.25],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",40.55],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011InGCSWells(ftUS)",
    `PROJCS["NAD_1983_2011_InGCS_Wells_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",787400.0],PARAMETER["False_Northing",118110.0],PARAMETER["Central_Meridian",-85.25],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",40.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "ONGD14UTMZone39N",
    `PROJCS["ONGD14_UTM_Zone_39N",GEOGCS["ONGD14",DATUM["Oman_National_Geodetic_Datum_2014",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ONGD14UTMZone40N",
    `PROJCS["ONGD14_UTM_Zone_40N",GEOGCS["ONGD14",DATUM["Oman_National_Geodetic_Datum_2014",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ONGD14UTMZone41N",
    `PROJCS["ONGD14_UTM_Zone_41N",GEOGCS["ONGD14",DATUM["Oman_National_Geodetic_Datum_2014",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSAdamsandJuneauMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Adams_and_Juneau_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",147218.6942],PARAMETER["False_Northing",0.0037],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",1.0000365285],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSAshlandMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Ashland_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",172821.9461],PARAMETER["False_Northing",0.0017],PARAMETER["Central_Meridian",-90.62222222222222],PARAMETER["Scale_Factor",1.0000495683],PARAMETER["Latitude_Of_Origin",45.70611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSBarronMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Barron_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",93150.0],PARAMETER["False_Northing",0.0029],PARAMETER["Central_Meridian",-91.85],PARAMETER["Scale_Factor",1.0000486665],PARAMETER["Latitude_Of_Origin",45.13333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSBayfieldMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Bayfield_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",228600.4575],PARAMETER["False_Northing",148551.4837],PARAMETER["Central_Meridian",-91.15277777777779],PARAMETER["Standard_Parallel_1",46.66964837722222],PARAMETER["Scale_Factor",1.0000331195],PARAMETER["Latitude_Of_Origin",46.66964837722222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSBrownMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Brown_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31600.0],PARAMETER["False_Northing",4600.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSBuffaloMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Buffalo_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",175260.3502],PARAMETER["False_Northing",0.0048],PARAMETER["Central_Meridian",-91.79722222222222],PARAMETER["Scale_Factor",1.0000382778],PARAMETER["Latitude_Of_Origin",43.48138888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSBurnettMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Burnett_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",64008.1276],PARAMETER["False_Northing",59445.9043],PARAMETER["Central_Meridian",-92.45777777777778],PARAMETER["Standard_Parallel_1",45.89871486583333],PARAMETER["Scale_Factor",1.0000383841],PARAMETER["Latitude_Of_Origin",45.89871486583333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSCalumetFondduLacOutagamieWinnebagoMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Calumet_Fond_du_Lac_Outagamie_Winnebago_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",244754.8893],PARAMETER["False_Northing",0.0049],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSChippewaMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Chippewa_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",60045.72],PARAMETER["False_Northing",44091.4346],PARAMETER["Central_Meridian",-91.29444444444444],PARAMETER["Standard_Parallel_1",44.97785689861112],PARAMETER["Scale_Factor",1.0000391127],PARAMETER["Latitude_Of_Origin",44.97785689861112],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSClarkMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Clark_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",199949.1989],PARAMETER["False_Northing",0.0086],PARAMETER["Central_Meridian",-90.70833333333334],PARAMETER["Scale_Factor",1.0000463003],PARAMETER["Latitude_Of_Origin",43.6],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSColumbiaMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Columbia_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",169164.3381],PARAMETER["False_Northing",111569.6134],PARAMETER["Central_Meridian",-89.39444444444445],PARAMETER["Standard_Parallel_1",43.46254664583333],PARAMETER["Scale_Factor",1.00003498],PARAMETER["Latitude_Of_Origin",43.46254664583333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSCrawfordMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Crawford_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",113690.6274],PARAMETER["False_Northing",53703.1201],PARAMETER["Central_Meridian",-90.9388888888889],PARAMETER["Standard_Parallel_1",43.200055605],PARAMETER["Scale_Factor",1.0000349151],PARAMETER["Latitude_Of_Origin",43.200055605],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSDaneMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Dane_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",247193.2944],PARAMETER["False_Northing",146591.9896],PARAMETER["Central_Meridian",-89.42222222222223],PARAMETER["Standard_Parallel_1",43.0695160375],PARAMETER["Scale_Factor",1.0000384786],PARAMETER["Latitude_Of_Origin",43.0695160375],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSDodgeandJeffersonMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Dodge_and_Jefferson_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",263347.7263],PARAMETER["False_Northing",0.0076],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",1.0000346418],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSDoorMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Door_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",158801.1176],PARAMETER["False_Northing",0.0023],PARAMETER["Central_Meridian",-87.27222222222223],PARAMETER["Scale_Factor",1.0000187521],PARAMETER["Latitude_Of_Origin",44.4],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSDouglasMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Douglas_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",59131.3183],PARAMETER["False_Northing",0.0041],PARAMETER["Central_Meridian",-91.91666666666667],PARAMETER["Scale_Factor",1.0000385418],PARAMETER["Latitude_Of_Origin",45.88333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSDunnMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Dunn_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",51816.104],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-91.89444444444445],PARAMETER["Scale_Factor",1.0000410324],PARAMETER["Latitude_Of_Origin",44.40833333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSEauClaireMeters",
    `PROJCS["NAD_1983_2011_WISCRS_EauClaire_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120091.4402],PARAMETER["False_Northing",91687.9239],PARAMETER["Central_Meridian",-91.28888888888889],PARAMETER["Standard_Parallel_1",44.87228112638889],PARAMETER["Scale_Factor",1.000035079],PARAMETER["Latitude_Of_Origin",44.87228112638889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSFlorenceMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Florence_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",133502.6683],PARAMETER["False_Northing",0.0063],PARAMETER["Central_Meridian",-88.14166666666668],PARAMETER["Scale_Factor",1.0000552095],PARAMETER["Latitude_Of_Origin",45.43888888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSForestMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Forest_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",275844.5533],PARAMETER["False_Northing",0.0157],PARAMETER["Central_Meridian",-88.63333333333334],PARAMETER["Scale_Factor",1.0000673004],PARAMETER["Latitude_Of_Origin",44.00555555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSGrantMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Grant_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",242316.4841],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-90.8],PARAMETER["Scale_Factor",1.0000349452],PARAMETER["Latitude_Of_Origin",41.41111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSGreenandLafayetteMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Green_and_Lafayette_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",170078.7403],PARAMETER["False_Northing",45830.2947],PARAMETER["Central_Meridian",-89.83888888888889],PARAMETER["Standard_Parallel_1",42.63756227694444],PARAMETER["Scale_Factor",1.0000390487],PARAMETER["Latitude_Of_Origin",42.63756227694444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSGreenLakeandMarquetteMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Green_Lake_and_Marquette_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150876.3018],PARAMETER["False_Northing",79170.7795],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.80700011777778],PARAMETER["Scale_Factor",1.0000344057],PARAMETER["Latitude_Of_Origin",43.80700011777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSIowaMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Iowa_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",113081.0261],PARAMETER["False_Northing",0.0045],PARAMETER["Central_Meridian",-90.16111111111111],PARAMETER["Scale_Factor",1.0000394961],PARAMETER["Latitude_Of_Origin",42.53888888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSIronMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Iron_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",220980.4419],PARAMETER["False_Northing",0.0085],PARAMETER["Central_Meridian",-90.25555555555556],PARAMETER["Scale_Factor",1.0000677153],PARAMETER["Latitude_Of_Origin",45.43333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSJacksonMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Jackson_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",27000.0],PARAMETER["False_Northing",25000.0],PARAMETER["Central_Meridian",-90.84429651944444],PARAMETER["Scale_Factor",1.0000353],PARAMETER["Latitude_Of_Origin",44.25333512777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSKenoshaMilwaukeeOzaukeeRacineMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Kenosha_Milwaukee_Ozaukee_Racine_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185928.3728],PARAMETER["False_Northing",0.0009],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSKewauneeManitowocSheboyganMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Kewaunee_Manitowoc_Sheboygan_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",79857.7614],PARAMETER["False_Northing",0.0012],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0000233704],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSLaCrosseMeters",
    `PROJCS["NAD_1983_2011_WISCRS_La_Crosse_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",130454.6598],PARAMETER["False_Northing",0.0033],PARAMETER["Central_Meridian",-91.31666666666666],PARAMETER["Scale_Factor",1.0000319985],PARAMETER["Latitude_Of_Origin",43.45111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSLangladeMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Langlade_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",198425.197],PARAMETER["False_Northing",105279.7829],PARAMETER["Central_Meridian",-89.03333333333333],PARAMETER["Standard_Parallel_1",45.15423710527778],PARAMETER["Scale_Factor",1.0000627024],PARAMETER["Latitude_Of_Origin",45.15423710527778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSLincolnMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Lincoln_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",116129.0323],PARAMETER["False_Northing",0.0058],PARAMETER["Central_Meridian",-89.73333333333333],PARAMETER["Scale_Factor",1.0000599003],PARAMETER["Latitude_Of_Origin",44.84444444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSMarathonMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Marathon_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",74676.1493],PARAMETER["False_Northing",55049.2669],PARAMETER["Central_Meridian",-89.77],PARAMETER["Standard_Parallel_1",44.90090442361111],PARAMETER["Scale_Factor",1.000053289],PARAMETER["Latitude_Of_Origin",44.90090442361111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSMarinetteMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Marinette_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",238658.8794],PARAMETER["False_Northing",0.0032],PARAMETER["Central_Meridian",-87.71111111111111],PARAMETER["Scale_Factor",1.0000234982],PARAMETER["Latitude_Of_Origin",44.69166666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSMenomineeMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Menominee_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",105461.0121],PARAMETER["False_Northing",0.0029],PARAMETER["Central_Meridian",-88.41666666666667],PARAMETER["Scale_Factor",1.0000362499],PARAMETER["Latitude_Of_Origin",44.71666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSMonroeMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Monroe_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",204521.209],PARAMETER["False_Northing",121923.9861],PARAMETER["Central_Meridian",-90.64166666666668],PARAMETER["Standard_Parallel_1",44.00007392861111],PARAMETER["Scale_Factor",1.0000434122],PARAMETER["Latitude_Of_Origin",44.00007392861111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSOcontoMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Oconto_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",182880.3676],PARAMETER["False_Northing",0.0033],PARAMETER["Central_Meridian",-87.90833333333335],PARAMETER["Scale_Factor",1.0000236869],PARAMETER["Latitude_Of_Origin",44.39722222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSOneidaMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Oneida_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",70104.1401],PARAMETER["False_Northing",57588.0346],PARAMETER["Central_Meridian",-89.54444444444444],PARAMETER["Standard_Parallel_1",45.70422377027778],PARAMETER["Scale_Factor",1.0000686968],PARAMETER["Latitude_Of_Origin",45.70422377027778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSPepinandPierceMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Pepin_and_Pierce_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",167640.3354],PARAMETER["False_Northing",86033.0876],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.63614887194444],PARAMETER["Scale_Factor",1.0000362977],PARAMETER["Latitude_Of_Origin",44.63614887194444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSPolkMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Polk_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",141732.2823],PARAMETER["False_Northing",0.0059],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000433849],PARAMETER["Latitude_Of_Origin",44.66111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSPortageMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Portage_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",56388.1128],PARAMETER["False_Northing",50022.1874],PARAMETER["Central_Meridian",-89.5],PARAMETER["Standard_Parallel_1",44.41682397527777],PARAMETER["Scale_Factor",1.000039936],PARAMETER["Latitude_Of_Origin",44.41682397527777],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSPriceMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Price_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",227990.8546],PARAMETER["False_Northing",0.0109],PARAMETER["Central_Meridian",-90.48888888888889],PARAMETER["Scale_Factor",1.0000649554],PARAMETER["Latitude_Of_Origin",44.55555555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSRichlandMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Richland_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",202387.6048],PARAMETER["False_Northing",134255.4253],PARAMETER["Central_Meridian",-90.43055555555556],PARAMETER["Standard_Parallel_1",43.3223129275],PARAMETER["Scale_Factor",1.0000375653],PARAMETER["Latitude_Of_Origin",43.3223129275],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSRockMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Rock_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",146304.2926],PARAMETER["False_Northing",0.0068],PARAMETER["Central_Meridian",-89.07222222222222],PARAMETER["Scale_Factor",1.0000337311],PARAMETER["Latitude_Of_Origin",41.94444444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSRuskMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Rusk_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250546.1013],PARAMETER["False_Northing",0.0234],PARAMETER["Central_Meridian",-91.06666666666666],PARAMETER["Scale_Factor",1.0000495976],PARAMETER["Latitude_Of_Origin",43.91944444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSSaukMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Sauk_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185623.5716],PARAMETER["False_Northing",0.0051],PARAMETER["Central_Meridian",-89.9],PARAMETER["Scale_Factor",1.0000373868],PARAMETER["Latitude_Of_Origin",42.81944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSSawyerMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Sawyer_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",216713.2336],PARAMETER["False_Northing",120734.1631],PARAMETER["Central_Meridian",-91.11666666666666],PARAMETER["Standard_Parallel_1",45.90009913138888],PARAMETER["Scale_Factor",1.0000573461],PARAMETER["Latitude_Of_Origin",45.90009913138888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSShawanoMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Shawano_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262433.3253],PARAMETER["False_Northing",0.0096],PARAMETER["Central_Meridian",-88.60555555555555],PARAMETER["Scale_Factor",1.000032144],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSStCroixMeters",
    `PROJCS["NAD_1983_2011_WISCRS_St_Croix_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165506.7302],PARAMETER["False_Northing",0.0103],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000381803],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSTaylorMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Taylor_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",187147.5744],PARAMETER["False_Northing",107746.7522],PARAMETER["Central_Meridian",-90.48333333333333],PARAMETER["Standard_Parallel_1",45.17782208583333],PARAMETER["Scale_Factor",1.0000597566],PARAMETER["Latitude_Of_Origin",45.17782208583333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSTrempealeauMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Trempealeau_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",256946.9138],PARAMETER["False_Northing",0.0041],PARAMETER["Central_Meridian",-91.36666666666666],PARAMETER["Scale_Factor",1.0000361538],PARAMETER["Latitude_Of_Origin",43.16111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSVernonMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Vernon_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",222504.4451],PARAMETER["False_Northing",47532.0602],PARAMETER["Central_Meridian",-90.78333333333333],PARAMETER["Standard_Parallel_1",43.57503293972223],PARAMETER["Scale_Factor",1.0000408158],PARAMETER["Latitude_Of_Origin",43.57503293972223],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSVilasMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Vilas_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",134417.0689],PARAMETER["False_Northing",50337.1092],PARAMETER["Central_Meridian",-89.48888888888889],PARAMETER["Standard_Parallel_1",46.07784409055556],PARAMETER["Scale_Factor",1.0000730142],PARAMETER["Latitude_Of_Origin",46.07784409055556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSWalworthMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Walworth_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",232562.8651],PARAMETER["False_Northing",111088.2224],PARAMETER["Central_Meridian",-88.54166666666667],PARAMETER["Standard_Parallel_1",42.66946209694444],PARAMETER["Scale_Factor",1.0000367192],PARAMETER["Latitude_Of_Origin",42.66946209694444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSWashburnMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Washburn_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",234086.8682],PARAMETER["False_Northing",188358.6058],PARAMETER["Central_Meridian",-91.78333333333333],PARAMETER["Standard_Parallel_1",45.96121983333334],PARAMETER["Scale_Factor",1.0000475376],PARAMETER["Latitude_Of_Origin",45.96121983333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSWashingtonMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Washington_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",120091.4415],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-88.06388888888888],PARAMETER["Scale_Factor",1.00003738],PARAMETER["Latitude_Of_Origin",42.91805555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSWaukeshaMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Waukesha_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",208788.418],PARAMETER["False_Northing",0.0034],PARAMETER["Central_Meridian",-88.225],PARAMETER["Scale_Factor",1.0000346179],PARAMETER["Latitude_Of_Origin",42.56944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSWaupacaMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Waupaca_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185013.9709],PARAMETER["False_Northing",0.007],PARAMETER["Central_Meridian",-88.81666666666666],PARAMETER["Scale_Factor",1.0000333645],PARAMETER["Latitude_Of_Origin",43.42027777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSWausharaMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Waushara_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120091.4402],PARAMETER["False_Northing",45069.7587],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",44.11394404583334],PARAMETER["Scale_Factor",1.0000392096],PARAMETER["Latitude_Of_Origin",44.11394404583334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSWoodMeters",
    `PROJCS["NAD_1983_2011_WISCRS_Wood_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",208483.6173],PARAMETER["False_Northing",134589.754],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.36259546944444],PARAMETER["Scale_Factor",1.0000421209],PARAMETER["Latitude_Of_Origin",44.36259546944444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WISCRSAdamsandJuneauFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Adams_and_Juneau_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",482999.999],PARAMETER["False_Northing",0.012],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",1.0000365285],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSAshlandFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Ashland_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",567000.001],PARAMETER["False_Northing",0.006],PARAMETER["Central_Meridian",-90.62222222222222],PARAMETER["Scale_Factor",1.0000495683],PARAMETER["Latitude_Of_Origin",45.70611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSBarronFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Barron_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",305609.625],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-91.85],PARAMETER["Scale_Factor",1.0000486665],PARAMETER["Latitude_Of_Origin",45.13333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSBayfieldFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Bayfield_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",750000.001],PARAMETER["False_Northing",487372.659],PARAMETER["Central_Meridian",-91.15277777777779],PARAMETER["Standard_Parallel_1",46.66964837722222],PARAMETER["Scale_Factor",1.0000331195],PARAMETER["Latitude_Of_Origin",46.66964837722222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSBrownFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Brown_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",103674.333],PARAMETER["False_Northing",15091.833],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSBuffaloFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Buffalo_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",574999.999],PARAMETER["False_Northing",0.016],PARAMETER["Central_Meridian",-91.79722222222222],PARAMETER["Scale_Factor",1.0000382778],PARAMETER["Latitude_Of_Origin",43.48138888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSBurnettFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Burnett_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",209999.999],PARAMETER["False_Northing",195032.104],PARAMETER["Central_Meridian",-92.45777777777778],PARAMETER["Standard_Parallel_1",45.89871486583333],PARAMETER["Scale_Factor",1.0000383841],PARAMETER["Latitude_Of_Origin",45.89871486583333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSCalumetFondduLacOutagamieWinnebagoFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Calumet_Fond_du_Lac_Outagamie_Winnebago_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",802999.999],PARAMETER["False_Northing",0.016],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSChippewaFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Chippewa_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",197000.0],PARAMETER["False_Northing",144656.648],PARAMETER["Central_Meridian",-91.29444444444444],PARAMETER["Standard_Parallel_1",44.97785689861112],PARAMETER["Scale_Factor",1.0000391127],PARAMETER["Latitude_Of_Origin",44.97785689861112],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSClarkFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Clark_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",655999.997],PARAMETER["False_Northing",0.028],PARAMETER["Central_Meridian",-90.70833333333334],PARAMETER["Scale_Factor",1.0000463003],PARAMETER["Latitude_Of_Origin",43.6],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSColumbiaFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Columbia_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",554999.999],PARAMETER["False_Northing",366041.307],PARAMETER["Central_Meridian",-89.39444444444445],PARAMETER["Standard_Parallel_1",43.46254664583333],PARAMETER["Scale_Factor",1.00003498],PARAMETER["Latitude_Of_Origin",43.46254664583333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSCrawfordFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Crawford_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",373000.0],PARAMETER["False_Northing",176190.987],PARAMETER["Central_Meridian",-90.9388888888889],PARAMETER["Standard_Parallel_1",43.200055605],PARAMETER["Scale_Factor",1.0000349151],PARAMETER["Latitude_Of_Origin",43.200055605],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSDaneFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Dane_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",811000.0],PARAMETER["False_Northing",480943.886],PARAMETER["Central_Meridian",-89.42222222222223],PARAMETER["Standard_Parallel_1",43.0695160375],PARAMETER["Scale_Factor",1.0000384786],PARAMETER["Latitude_Of_Origin",43.0695160375],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSDodgeandJeffersonFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Dodge_and_Jefferson_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",863999.999],PARAMETER["False_Northing",0.025],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",1.0000346418],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSDoorFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Door_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",521000.0],PARAMETER["False_Northing",0.008],PARAMETER["Central_Meridian",-87.27222222222223],PARAMETER["Scale_Factor",1.0000187521],PARAMETER["Latitude_Of_Origin",44.4],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSDouglasFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Douglas_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",194000.0],PARAMETER["False_Northing",0.013],PARAMETER["Central_Meridian",-91.91666666666667],PARAMETER["Scale_Factor",1.0000385418],PARAMETER["Latitude_Of_Origin",45.88333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSDunnFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Dunn_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",170000.001],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-91.89444444444445],PARAMETER["Scale_Factor",1.0000410324],PARAMETER["Latitude_Of_Origin",44.40833333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSEauClaireFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Eau_Claire_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",394000.0],PARAMETER["False_Northing",300812.797],PARAMETER["Central_Meridian",-91.28888888888889],PARAMETER["Standard_Parallel_1",44.87228112638889],PARAMETER["Scale_Factor",1.000035079],PARAMETER["Latitude_Of_Origin",44.87228112638889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSFlorenceFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Florence_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",438000.004],PARAMETER["False_Northing",0.021],PARAMETER["Central_Meridian",-88.14166666666668],PARAMETER["Scale_Factor",1.0000552095],PARAMETER["Latitude_Of_Origin",45.43888888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSForestFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Forest_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",905000.005],PARAMETER["False_Northing",0.052],PARAMETER["Central_Meridian",-88.63333333333334],PARAMETER["Scale_Factor",1.0000673004],PARAMETER["Latitude_Of_Origin",44.00555555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSGrantFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Grant_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",794999.998],PARAMETER["False_Northing",0.033],PARAMETER["Central_Meridian",-90.8],PARAMETER["Scale_Factor",1.0000349452],PARAMETER["Latitude_Of_Origin",41.41111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSGreenandLafayetteFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Green_and_Lafayette_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",558000.0],PARAMETER["False_Northing",150361.559],PARAMETER["Central_Meridian",-89.83888888888889],PARAMETER["Standard_Parallel_1",42.63756227694444],PARAMETER["Scale_Factor",1.0000390487],PARAMETER["Latitude_Of_Origin",42.63756227694444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSGreenLakeandMarquetteFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Green_Lake_and_Marquette_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",495000.0],PARAMETER["False_Northing",259746.132],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.80700011777778],PARAMETER["Scale_Factor",1.0000344057],PARAMETER["Latitude_Of_Origin",43.80700011777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSIowaFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Iowa_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",371000.0],PARAMETER["False_Northing",0.015],PARAMETER["Central_Meridian",-90.16111111111111],PARAMETER["Scale_Factor",1.0000394961],PARAMETER["Latitude_Of_Origin",42.53888888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSIronFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Iron_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",725000.0],PARAMETER["False_Northing",0.028],PARAMETER["Central_Meridian",-90.25555555555556],PARAMETER["Scale_Factor",1.0000677153],PARAMETER["Latitude_Of_Origin",45.43333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSJacksonFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Jackson_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88582.5],PARAMETER["False_Northing",82020.833],PARAMETER["Central_Meridian",-90.84429651944444],PARAMETER["Scale_Factor",1.0000353],PARAMETER["Latitude_Of_Origin",44.25333512777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSKenoshaMilwaukeeOzaukeeRacineFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Kenosha_Milwaukee_Ozaukee_Racine_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",610000.003],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSKewauneeManitowocSheboyganFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Kewaunee_Manitowoc_Sheboygan_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262000.006],PARAMETER["False_Northing",0.004],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0000233704],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSLaCrosseFeet",
    `PROJCS["NAD_1983_2011_WISCRS_La_Crosse_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",427999.996],PARAMETER["False_Northing",0.011],PARAMETER["Central_Meridian",-91.31666666666666],PARAMETER["Scale_Factor",1.0000319985],PARAMETER["Latitude_Of_Origin",43.45111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSLangladeFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Langlade_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",651000.0],PARAMETER["False_Northing",345405.421],PARAMETER["Central_Meridian",-89.03333333333333],PARAMETER["Standard_Parallel_1",45.15423710527778],PARAMETER["Scale_Factor",1.0000627024],PARAMETER["Latitude_Of_Origin",45.15423710527778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSLincolnFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Lincoln_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",381000.0],PARAMETER["False_Northing",0.019],PARAMETER["Central_Meridian",-89.73333333333333],PARAMETER["Scale_Factor",1.0000599003],PARAMETER["Latitude_Of_Origin",44.84444444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSMarathonFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Marathon_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",245000.0],PARAMETER["False_Northing",180607.47],PARAMETER["Central_Meridian",-89.77],PARAMETER["Standard_Parallel_1",44.90090442361111],PARAMETER["Scale_Factor",1.000053289],PARAMETER["Latitude_Of_Origin",44.90090442361111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSMarinetteFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Marinette_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",783000.007],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-87.71111111111111],PARAMETER["Scale_Factor",1.0000234982],PARAMETER["Latitude_Of_Origin",44.69166666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSMenomineeFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Menominee_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",346000.004],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-88.41666666666667],PARAMETER["Scale_Factor",1.0000362499],PARAMETER["Latitude_Of_Origin",44.71666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSMonroeFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Monroe_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",671000.0],PARAMETER["False_Northing",400012.278],PARAMETER["Central_Meridian",-90.64166666666668],PARAMETER["Standard_Parallel_1",44.00007392861111],PARAMETER["Scale_Factor",1.0000434122],PARAMETER["Latitude_Of_Origin",44.00007392861111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSOcontoFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Oconto_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.006],PARAMETER["False_Northing",0.011],PARAMETER["Central_Meridian",-87.90833333333335],PARAMETER["Scale_Factor",1.0000236869],PARAMETER["Latitude_Of_Origin",44.39722222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSOneidaFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Oneida_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",230000.0],PARAMETER["False_Northing",188936.744],PARAMETER["Central_Meridian",-89.54444444444444],PARAMETER["Standard_Parallel_1",45.70422377027778],PARAMETER["Scale_Factor",1.0000686968],PARAMETER["Latitude_Of_Origin",45.70422377027778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSPepinandPierceFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Pepin_and_Pierce_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",550000.0],PARAMETER["False_Northing",282260.222],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.63614887194444],PARAMETER["Scale_Factor",1.0000362977],PARAMETER["Latitude_Of_Origin",44.63614887194444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSPolkFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Polk_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",464999.996],PARAMETER["False_Northing",0.019],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000433849],PARAMETER["Latitude_Of_Origin",44.66111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSPortageFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Portage_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",185000.0],PARAMETER["False_Northing",164114.46],PARAMETER["Central_Meridian",-89.5],PARAMETER["Standard_Parallel_1",44.41682397527777],PARAMETER["Scale_Factor",1.000039936],PARAMETER["Latitude_Of_Origin",44.41682397527777],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSPriceFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Price_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",747999.995],PARAMETER["False_Northing",0.036],PARAMETER["Central_Meridian",-90.48888888888889],PARAMETER["Scale_Factor",1.0000649554],PARAMETER["Latitude_Of_Origin",44.55555555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSRichlandFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Richland_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",664000.0],PARAMETER["False_Northing",440469.675],PARAMETER["Central_Meridian",-90.43055555555556],PARAMETER["Standard_Parallel_1",43.3223129275],PARAMETER["Scale_Factor",1.0000375653],PARAMETER["Latitude_Of_Origin",43.3223129275],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSRockFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Rock_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",480000.0],PARAMETER["False_Northing",0.022],PARAMETER["Central_Meridian",-89.07222222222222],PARAMETER["Scale_Factor",1.0000337311],PARAMETER["Latitude_Of_Origin",41.94444444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSRuskFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Rusk_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",822000.001],PARAMETER["False_Northing",0.077],PARAMETER["Central_Meridian",-91.06666666666666],PARAMETER["Scale_Factor",1.0000495976],PARAMETER["Latitude_Of_Origin",43.91944444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSSaukFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Sauk_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",609000.001],PARAMETER["False_Northing",0.017],PARAMETER["Central_Meridian",-89.9],PARAMETER["Scale_Factor",1.0000373868],PARAMETER["Latitude_Of_Origin",42.81944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSSawyerFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Sawyer_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",711000.001],PARAMETER["False_Northing",396108.667],PARAMETER["Central_Meridian",-91.11666666666666],PARAMETER["Standard_Parallel_1",45.90009913138888],PARAMETER["Scale_Factor",1.0000573461],PARAMETER["Latitude_Of_Origin",45.90009913138888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSShawanoFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Shawano_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",861000.001],PARAMETER["False_Northing",0.031],PARAMETER["Central_Meridian",-88.60555555555555],PARAMETER["Scale_Factor",1.000032144],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSStCroixFeet",
    `PROJCS["NAD_1983_2011_WISCRS_St_Croix_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",542999.997],PARAMETER["False_Northing",0.034],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000381803],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSTaylorFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Taylor_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",614000.0],PARAMETER["False_Northing",353499.136],PARAMETER["Central_Meridian",-90.48333333333333],PARAMETER["Standard_Parallel_1",45.17782208583333],PARAMETER["Scale_Factor",1.0000597566],PARAMETER["Latitude_Of_Origin",45.17782208583333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSTrempealeauFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Trempealeau_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",843000.0],PARAMETER["False_Northing",0.013],PARAMETER["Central_Meridian",-91.36666666666666],PARAMETER["Scale_Factor",1.0000361538],PARAMETER["Latitude_Of_Origin",43.16111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSVernonFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Vernon_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",730000.0],PARAMETER["False_Northing",155944.768],PARAMETER["Central_Meridian",-90.78333333333333],PARAMETER["Standard_Parallel_1",43.57503293972223],PARAMETER["Scale_Factor",1.0000408158],PARAMETER["Latitude_Of_Origin",43.57503293972223],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSVilasFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Vilas_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",441000.0],PARAMETER["False_Northing",165147.666],PARAMETER["Central_Meridian",-89.48888888888889],PARAMETER["Standard_Parallel_1",46.07784409055556],PARAMETER["Scale_Factor",1.0000730142],PARAMETER["Latitude_Of_Origin",46.07784409055556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSWalworthFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Walworth_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",763000.0],PARAMETER["False_Northing",364461.943],PARAMETER["Central_Meridian",-88.54166666666667],PARAMETER["Standard_Parallel_1",42.66946209694444],PARAMETER["Scale_Factor",1.0000367192],PARAMETER["Latitude_Of_Origin",42.66946209694444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSWashburnFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Washburn_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",768000.0],PARAMETER["False_Northing",617973.193],PARAMETER["Central_Meridian",-91.78333333333333],PARAMETER["Standard_Parallel_1",45.96121983333334],PARAMETER["Scale_Factor",1.0000475376],PARAMETER["Latitude_Of_Origin",45.96121983333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSWashingtonFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Washington_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",394000.004],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-88.06388888888888],PARAMETER["Scale_Factor",1.00003738],PARAMETER["Latitude_Of_Origin",42.91805555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSWaukeshaFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Waukesha_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",685000.001],PARAMETER["False_Northing",0.011],PARAMETER["Central_Meridian",-88.225],PARAMETER["Scale_Factor",1.0000346179],PARAMETER["Latitude_Of_Origin",42.56944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSWaupacaFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Waupaca_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",607000.003],PARAMETER["False_Northing",0.023],PARAMETER["Central_Meridian",-88.81666666666666],PARAMETER["Scale_Factor",1.0000333645],PARAMETER["Latitude_Of_Origin",43.42027777777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSWausharaFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Waushara_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",394000.0],PARAMETER["False_Northing",147866.367],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",44.11394404583334],PARAMETER["Scale_Factor",1.0000392096],PARAMETER["Latitude_Of_Origin",44.11394404583334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011WISCRSWoodFeet",
    `PROJCS["NAD_1983_2011_WISCRS_Wood_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",684000.001],PARAMETER["False_Northing",441566.551],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.36259546944444],PARAMETER["Scale_Factor",1.0000421209],PARAMETER["Latitude_Of_Origin",44.36259546944444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Kyrg-06TMZone1",
    `PROJCS["Kyrg-06_TM_Zone_1",GEOGCS["GCS_Kyrg-06",DATUM["D_Kyrgyz_Republic_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1300000.0],PARAMETER["False_Northing",14743.5],PARAMETER["Central_Meridian",68.51666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kyrg-06TMZone2",
    `PROJCS["Kyrg-06_TM_Zone_2",GEOGCS["GCS_Kyrg-06",DATUM["D_Kyrgyz_Republic_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2300000.0],PARAMETER["False_Northing",14743.5],PARAMETER["Central_Meridian",71.51666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kyrg-06TMZone3",
    `PROJCS["Kyrg-06_TM_Zone_3",GEOGCS["GCS_Kyrg-06",DATUM["D_Kyrgyz_Republic_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3300000.0],PARAMETER["False_Northing",14743.5],PARAMETER["Central_Meridian",74.51666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kyrg-06TMZone4",
    `PROJCS["Kyrg-06_TM_Zone_4",GEOGCS["GCS_Kyrg-06",DATUM["D_Kyrgyz_Republic_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4300000.0],PARAMETER["False_Northing",14743.5],PARAMETER["Central_Meridian",77.51666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kyrg-06TMZone5",
    `PROJCS["Kyrg-06_TM_Zone_5",GEOGCS["GCS_Kyrg-06",DATUM["D_Kyrgyz_Republic_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5300000.0],PARAMETER["False_Northing",14743.5],PARAMETER["Central_Meridian",80.51666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984IndiaNSFLCC",
    `PROJCS["WGS_1984_India_NSF_LCC",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",80.0],PARAMETER["Standard_Parallel_1",12.472955],PARAMETER["Standard_Parallel_2",35.17280444444444],PARAMETER["Latitude_Of_Origin",24.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984AndhraPradesh",
    `PROJCS["WGS_1984_Andhra_Pradesh",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",80.875],PARAMETER["Standard_Parallel_1",13.75],PARAMETER["Standard_Parallel_2",18.75],PARAMETER["Latitude_Of_Origin",16.25543298],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ArunachalPradesh",
    `PROJCS["WGS_1984_Arunachal_Pradesh",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",94.5],PARAMETER["Standard_Parallel_1",27.0],PARAMETER["Standard_Parallel_2",29.0],PARAMETER["Latitude_Of_Origin",28.00157897],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Assam",
    `PROJCS["WGS_1984_Assam",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",92.75],PARAMETER["Standard_Parallel_1",24.66666666666667],PARAMETER["Standard_Parallel_2",27.33333333333333],PARAMETER["Latitude_Of_Origin",26.00257703],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Bihar",
    `PROJCS["WGS_1984_Bihar",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",85.875],PARAMETER["Standard_Parallel_1",24.625],PARAMETER["Standard_Parallel_2",27.125],PARAMETER["Latitude_Of_Origin",25.87725247],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Delhi",
    `PROJCS["WGS_1984_Delhi",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",77.0],PARAMETER["Standard_Parallel_1",28.375],PARAMETER["Standard_Parallel_2",28.875],PARAMETER["Latitude_Of_Origin",28.62510126],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Gujarat",
    `PROJCS["WGS_1984_Gujarat",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",71.375],PARAMETER["Standard_Parallel_1",20.79166666666667],PARAMETER["Standard_Parallel_2",23.95833333333334],PARAMETER["Latitude_Of_Origin",22.37807121],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Haryana",
    `PROJCS["WGS_1984_Haryana",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",76.0],PARAMETER["Standard_Parallel_1",28.08333333333333],PARAMETER["Standard_Parallel_2",30.41666666666667],PARAMETER["Latitude_Of_Origin",29.25226266],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984HimachalPradesh",
    `PROJCS["WGS_1984_Himachal_Pradesh",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",77.375],PARAMETER["Standard_Parallel_1",30.75],PARAMETER["Standard_Parallel_2",32.75],PARAMETER["Latitude_Of_Origin",31.75183497],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984JammuandKashmir",
    `PROJCS["WGS_1984_Jammu_and_Kashmir",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",76.5],PARAMETER["Standard_Parallel_1",33.08333333333334],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.75570874],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Jharkhand",
    `PROJCS["WGS_1984_Jharkhand",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",85.625],PARAMETER["Standard_Parallel_1",22.54166666666667],PARAMETER["Standard_Parallel_2",24.70833333333333],PARAMETER["Latitude_Of_Origin",23.62652682],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984MadhyaPradesh",
    `PROJCS["WGS_1984_Madhya_Pradesh",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",78.375],PARAMETER["Standard_Parallel_1",22.0],PARAMETER["Standard_Parallel_2",26.0],PARAMETER["Latitude_Of_Origin",24.00529821],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Maharashtra",
    `PROJCS["WGS_1984_Maharashtra",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",76.75],PARAMETER["Standard_Parallel_1",16.625],PARAMETER["Standard_Parallel_2",21.125],PARAMETER["Latitude_Of_Origin",18.88015774],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Manipur",
    `PROJCS["WGS_1984_Manipur",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",94.0],PARAMETER["Standard_Parallel_1",24.08333333333333],PARAMETER["Standard_Parallel_2",25.41666666666667],PARAMETER["Latitude_Of_Origin",24.75060911],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Meghalaya",
    `PROJCS["WGS_1984_Meghalaya",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",91.375],PARAMETER["Standard_Parallel_1",25.20833333333334],PARAMETER["Standard_Parallel_2",26.04166666666667],PARAMETER["Latitude_Of_Origin",25.62524747],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Nagaland",
    `PROJCS["WGS_1984_Nagaland",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",94.375],PARAMETER["Standard_Parallel_1",25.375],PARAMETER["Standard_Parallel_2",26.875],PARAMETER["Latitude_Of_Origin",26.12581974],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984IndiaNortheast",
    `PROJCS["WGS_1984_India_Northeast",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",93.5],PARAMETER["Standard_Parallel_1",23.04166666666667],PARAMETER["Standard_Parallel_2",28.20833333333334],PARAMETER["Latitude_Of_Origin",25.63452135],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Orissa",
    `PROJCS["WGS_1984_Orissa",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",84.375],PARAMETER["Standard_Parallel_1",18.58333333333334],PARAMETER["Standard_Parallel_2",21.91666666666667],PARAMETER["Latitude_Of_Origin",20.25305174],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Punjab",
    `PROJCS["WGS_1984_Punjab",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",75.375],PARAMETER["Standard_Parallel_1",30.0],PARAMETER["Standard_Parallel_2",32.0],PARAMETER["Latitude_Of_Origin",31.00178226],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Rajasthan",
    `PROJCS["WGS_1984_Rajasthan",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",73.875],PARAMETER["Standard_Parallel_1",24.29166666666666],PARAMETER["Standard_Parallel_2",29.45833333333334],PARAMETER["Latitude_Of_Origin",26.88505546],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UttarPradesh",
    `PROJCS["WGS_1984_Uttar_Pradesh",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",80.875],PARAMETER["Standard_Parallel_1",24.875],PARAMETER["Standard_Parallel_2",29.375],PARAMETER["Latitude_Of_Origin",27.13270823],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Uttaranchal",
    `PROJCS["WGS_1984_Uttaranchal",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",79.375],PARAMETER["Standard_Parallel_1",29.0],PARAMETER["Standard_Parallel_2",31.0],PARAMETER["Latitude_Of_Origin",30.0017132],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984AndamanandNicobarIslands",
    `PROJCS["WGS_1984_Andaman_and_Nicobar_Islands",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",93.25],PARAMETER["Scale_Factor",0.9999428],PARAMETER["Latitude_Of_Origin",10.25],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984Chhattisgarh",
    `PROJCS["WGS_1984_Chhattisgarh",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",82.25],PARAMETER["Scale_Factor",0.9998332],PARAMETER["Latitude_Of_Origin",21.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Goa",
    `PROJCS["WGS_1984_Goa",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",74.0],PARAMETER["Scale_Factor",0.9999913],PARAMETER["Latitude_Of_Origin",15.375],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Karnataka",
    `PROJCS["WGS_1984_Karnataka",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",76.375],PARAMETER["Scale_Factor",0.9998012],PARAMETER["Latitude_Of_Origin",15.125],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Kerala",
    `PROJCS["WGS_1984_Kerala",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",76.0],PARAMETER["Scale_Factor",0.9999177],PARAMETER["Latitude_Of_Origin",10.5],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Lakshadweep",
    `PROJCS["WGS_1984_Lakshadweep",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",73.125],PARAMETER["Scale_Factor",0.9999536],PARAMETER["Latitude_Of_Origin",10.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Mizoram",
    `PROJCS["WGS_1984_Mizoram",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",92.75],PARAMETER["Scale_Factor",0.9999821],PARAMETER["Latitude_Of_Origin",23.125],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Sikkim",
    `PROJCS["WGS_1984_Sikkim",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",88.5],PARAMETER["Scale_Factor",0.9999926],PARAMETER["Latitude_Of_Origin",27.625],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984TamilNadu",
    `PROJCS["WGS_1984_Tamil_Nadu",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",78.375],PARAMETER["Scale_Factor",0.9997942],PARAMETER["Latitude_Of_Origin",10.875],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984Tripura",
    `PROJCS["WGS_1984_Tripura",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",91.75],PARAMETER["Scale_Factor",0.9999822],PARAMETER["Latitude_Of_Origin",23.75],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984WestBengal",
    `PROJCS["WGS_1984_West_Bengal",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",87.875],PARAMETER["Scale_Factor",0.9998584],PARAMETER["Latitude_Of_Origin",24.375],UNIT["Meter",1.0]]`
  ],
  [
    "RDN2008UTMzone32N",
    `PROJCS["RDN2008_UTM_zone_32N",GEOGCS["GCS_RDN2008",DATUM["D_Rete_Dinamica_Nazionale_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RDN2008UTMzone33N",
    `PROJCS["RDN2008_UTM_zone_33N",GEOGCS["GCS_RDN2008",DATUM["D_Rete_Dinamica_Nazionale_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RDN2008UTMzone34N",
    `PROJCS["RDN2008_UTM_zone_34N",GEOGCS["GCS_RDN2008",DATUM["D_Rete_Dinamica_Nazionale_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RDN2008Italyzone(E-N)",
    `PROJCS["RDN2008_Italy_zone_(E-N)",GEOGCS["GCS_RDN2008",DATUM["D_Rete_Dinamica_Nazionale_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9985],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RDN2008Zone12(E-N)",
    `PROJCS["RDN2008_Zone_12_(E-N)",GEOGCS["GCS_RDN2008",DATUM["D_Rete_Dinamica_Nazionale_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BGS2005UTMzone34N(N-E)",
    `PROJCS["BGS2005_UTM_zone_34N_(N-E)",GEOGCS["BGS2005",DATUM["Bulgaria_Geodetic_System_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BGS2005UTMzone35N(N-E)",
    `PROJCS["BGS2005_UTM_zone_35N_(N-E)",GEOGCS["BGS2005",DATUM["Bulgaria_Geodetic_System_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BGS2005CCS2005",
    `PROJCS["BGS2005_CCS2005",GEOGCS["BGS2005",DATUM["Bulgaria_Geodetic_System_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",4725824.3591],PARAMETER["Central_Meridian",25.5],PARAMETER["Standard_Parallel_1",42.0],PARAMETER["Standard_Parallel_2",43.33333333333334],PARAMETER["Latitude_Of_Origin",42.66787568333332],UNIT["Meter",1.0]]`
  ],
  [
    "BGS2005UTMzone34N",
    `PROJCS["BGS2005_UTM_zone_34N",GEOGCS["BGS2005",DATUM["Bulgaria_Geodetic_System_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BGS2005UTMzone35N",
    `PROJCS["BGS2005_UTM_zone_35N",GEOGCS["BGS2005",DATUM["Bulgaria_Geodetic_System_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BGS2005UTMzone36N",
    `PROJCS["BGS2005_UTM_zone_36N",GEOGCS["BGS2005",DATUM["Bulgaria_Geodetic_System_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63zoneX1",
    `PROJCS["Pulkovo_1942_CS63_zone_X1",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",23.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.08333333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63zoneX2",
    `PROJCS["Pulkovo_1942_CS63_zone_X2",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",26.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.08333333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63zoneX3",
    `PROJCS["Pulkovo_1942_CS63_zone_X3",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",29.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.08333333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63zoneX4",
    `PROJCS["Pulkovo_1942_CS63_zone_X4",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",32.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.08333333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63zoneX5",
    `PROJCS["Pulkovo_1942_CS63_zone_X5",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",35.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.08333333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63zoneX6",
    `PROJCS["Pulkovo_1942_CS63_zone_X6",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",38.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.08333333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942CS63zoneX7",
    `PROJCS["Pulkovo_1942_CS63_zone_X7",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",41.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.08333333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020GALCC",
    `PROJCS["GDA2020_GA_LCC",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",134.0],PARAMETER["Standard_Parallel_1",-18.0],PARAMETER["Standard_Parallel_2",-36.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone46",
    `PROJCS["GDA2020_MGA_Zone_46",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone47",
    `PROJCS["GDA2020_MGA_Zone_47",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone48",
    `PROJCS["GDA2020_MGA_Zone_48",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone49",
    `PROJCS["GDA2020_MGA_Zone_49",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone50",
    `PROJCS["GDA2020_MGA_Zone_50",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone51",
    `PROJCS["GDA2020_MGA_Zone_51",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone52",
    `PROJCS["GDA2020_MGA_Zone_52",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone53",
    `PROJCS["GDA2020_MGA_Zone_53",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone54",
    `PROJCS["GDA2020_MGA_Zone_54",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone55",
    `PROJCS["GDA2020_MGA_Zone_55",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone56",
    `PROJCS["GDA2020_MGA_Zone_56",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone57",
    `PROJCS["GDA2020_MGA_Zone_57",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone58",
    `PROJCS["GDA2020_MGA_Zone_58",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MGAZone59",
    `PROJCS["GDA2020_MGA_Zone_59",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AstroDOS714SHLG71",
    `PROJCS["Astro_DOS_71_4_SHLG71",GEOGCS["GCS_DOS_71_4",DATUM["D_DOS_71_4",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-5.716666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-15.96666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "AstroDOS714UTMzone30S",
    `PROJCS["Astro_DOS_71_4_UTM_zone_30S",GEOGCS["GCS_DOS_71_4",DATUM["D_DOS_71_4",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "StHelenaTritanSHLG(Tritan)",
    `PROJCS["St_Helena_Tritan_SHLG(Tritan)",GEOGCS["St_Helena_Tritan",DATUM["St_Helena_Tritan",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",299483.737],PARAMETER["False_Northing",2000527.879],PARAMETER["Central_Meridian",-5.716666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-15.96666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "StHelenaTritanUTMzone30S",
    `PROJCS["St_Helena_Tritan_UTM_zone_30S",GEOGCS["St_Helena_Tritan",DATUM["St_Helena_Tritan",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SHMG2015",
    `PROJCS["SHMG2015",GEOGCS["SHGD2015",DATUM["St_Helena_Geodetic_Datum_2015",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020Vicgrid",
    `PROJCS["GDA2020_Vicgrid",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",145.0],PARAMETER["Standard_Parallel_1",-36.0],PARAMETER["Standard_Parallel_2",-38.0],PARAMETER["Latitude_Of_Origin",-37.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD27MTMzone10",
    `PROJCS["NAD27_MTM_zone_10",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Malongo1987UTMzone33S",
    `PROJCS["Malongo_1987_UTM_zone_33S",GEOGCS["GCS_Malongo_1987",DATUM["D_Malongo_1987",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020ALB2020",
    `PROJCS["GDA2020_ALB2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",4100000.0],PARAMETER["Central_Meridian",117.8833333333333],PARAMETER["Scale_Factor",1.0000044],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020BIO2020",
    `PROJCS["GDA2020_BIO2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",60000.0],PARAMETER["False_Northing",2700000.0],PARAMETER["Central_Meridian",115.25],PARAMETER["Scale_Factor",1.0000022],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "GDA2020BRO2020",
    `PROJCS["GDA2020_BRO2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2300000.0],PARAMETER["Central_Meridian",122.3333333333333],PARAMETER["Scale_Factor",1.00000298],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020BCG2020",
    `PROJCS["GDA2020_BCG2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",115.4333333333333],PARAMETER["Scale_Factor",0.99999592],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020CARN2020",
    `PROJCS["GDA2020_CARN2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3050000.0],PARAMETER["Central_Meridian",113.6666666666667],PARAMETER["Scale_Factor",0.99999796],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020CIG2020",
    `PROJCS["GDA2020_CIG2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",1400000.0],PARAMETER["Central_Meridian",105.625],PARAMETER["Scale_Factor",1.00002514],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020CKIG2020",
    `PROJCS["GDA2020_CKIG2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",1600000.0],PARAMETER["Central_Meridian",96.875],PARAMETER["Scale_Factor",0.99999387],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020COL2020",
    `PROJCS["GDA2020_COL2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",4100000.0],PARAMETER["Central_Meridian",115.9333333333333],PARAMETER["Scale_Factor",1.000019],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020ESP2020",
    `PROJCS["GDA2020_ESP2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",4050000.0],PARAMETER["Central_Meridian",121.8833333333333],PARAMETER["Scale_Factor",1.0000055],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020EXM2020",
    `PROJCS["GDA2020_EXM2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2750000.0],PARAMETER["Central_Meridian",114.0666666666667],PARAMETER["Scale_Factor",1.00000236],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020GCG2020",
    `PROJCS["GDA2020_GCG2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3450000.0],PARAMETER["Central_Meridian",114.5833333333333],PARAMETER["Scale_Factor",1.00000628],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020GOLD2020",
    `PROJCS["GDA2020_GOLD2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",60000.0],PARAMETER["False_Northing",3800000.0],PARAMETER["Central_Meridian",121.5],PARAMETER["Scale_Factor",1.00004949],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020JCG2020",
    `PROJCS["GDA2020_JCG2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3650000.0],PARAMETER["Central_Meridian",114.9833333333333],PARAMETER["Scale_Factor",1.00000314],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020KALB2020",
    `PROJCS["GDA2020_KALB2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",55000.0],PARAMETER["False_Northing",3700000.0],PARAMETER["Central_Meridian",114.3152777777778],PARAMETER["Scale_Factor",1.000014],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020KAR2020",
    `PROJCS["GDA2020_KAR2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2550000.0],PARAMETER["Central_Meridian",116.9333333333333],PARAMETER["Scale_Factor",0.9999989],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020KUN2020",
    `PROJCS["GDA2020_KUN2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2100000.0],PARAMETER["Central_Meridian",128.75],PARAMETER["Scale_Factor",1.0000165],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020LCG2020",
    `PROJCS["GDA2020_LCG2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3750000.0],PARAMETER["Central_Meridian",115.3666666666667],PARAMETER["Scale_Factor",1.00000157],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020MRCG2020",
    `PROJCS["GDA2020_MRCG2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",4050000.0],PARAMETER["Central_Meridian",115.1666666666667],PARAMETER["Scale_Factor",1.0000055],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020PCG2020",
    `PROJCS["GDA2020_PCG2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3900000.0],PARAMETER["Central_Meridian",115.8166666666667],PARAMETER["Scale_Factor",0.99999906],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020PHG2020",
    `PROJCS["GDA2020_PHG2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",118.6],PARAMETER["Scale_Factor",1.00000135],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984TMZone20N(USFeet)",
    `PROJCS["WGS_1984_TM_Zone_20N_(US_Feet)",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "WGS1984TMZone21N(USFeet)",
    `PROJCS["WGS_1984_TM_Zone_21N_(US_Feet)",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "GDA2020NSWLambert",
    `PROJCS["GDA2020_NSW_Lambert",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",9300000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Standard_Parallel_1",-30.75],PARAMETER["Standard_Parallel_2",-35.75],PARAMETER["Latitude_Of_Origin",-33.25],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020SouthAustraliaLambert",
    `PROJCS["GDA2020_South_Australia_Lambert",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Standard_Parallel_1",-28.0],PARAMETER["Standard_Parallel_2",-36.0],PARAMETER["Latitude_Of_Origin",-32.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(2011)PCCSzone1(ft)",
    `PROJCS["NAD_1983_(2011)_PCCS_zone_1_(ft)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Center"],PARAMETER["False_Easting",160000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Scale_Factor",1.00011],PARAMETER["Azimuth",45.0],PARAMETER["Longitude_Of_Center",-111.4],PARAMETER["Latitude_Of_Center",32.25],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983(2011)PCCSzone2(ft)",
    `PROJCS["NAD_1983_(2011)_PCCS_zone_2_(ft)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1800000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",1.00009],PARAMETER["Latitude_Of_Origin",31.25],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983(2011)PCCSzone3(ft)",
    `PROJCS["NAD_1983_(2011)_PCCS_zone_3_(ft)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.1666666666667],PARAMETER["Scale_Factor",1.000055],PARAMETER["Latitude_Of_Origin",31.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983(2011)PCCSzone4(ft)",
    `PROJCS["NAD_1983_(2011)_PCCS_zone_4_(ft)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",30000.0],PARAMETER["False_Northing",-620000.0],PARAMETER["Central_Meridian",-110.75],PARAMETER["Standard_Parallel_1",30.5],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983(CSRS)v6MTMNovaScotiazone4",
    `PROJCS["NAD_1983_(CSRS)_v6_MTM_Nova_Scotia_zone_4",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-61.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6MTMNovaScotiazone5",
    `PROJCS["NAD_1983_(CSRS)_v6_MTM_Nova_Scotia_zone_5",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-64.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ISN2016Lambert2016",
    `PROJCS["ISN2016_Lambert_2016",GEOGCS["ISN2016",DATUM["Islands_Net_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2700000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",-19.0],PARAMETER["Standard_Parallel_1",64.25],PARAMETER["Standard_Parallel_2",65.75],PARAMETER["Latitude_Of_Origin",65.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSFlorenceCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Florence_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",133502.6683],PARAMETER["False_Northing",0.0063],PARAMETER["Central_Meridian",-88.14166666666668],PARAMETER["Scale_Factor",1.0000552095],PARAMETER["Latitude_Of_Origin",45.43888888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSFlorenceCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Florence_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",438000.004],PARAMETER["False_Northing",0.021],PARAMETER["Central_Meridian",-88.14166666666668],PARAMETER["Scale_Factor",1.0000552095],PARAMETER["Latitude_Of_Origin",45.43888888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSEauClaireCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_EauClaire_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120091.4402],PARAMETER["False_Northing",91687.9239],PARAMETER["Central_Meridian",-91.28888888888889],PARAMETER["Standard_Parallel_1",44.87228112638889],PARAMETER["Scale_Factor",1.000035079],PARAMETER["Latitude_Of_Origin",44.87228112638889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSEauClaireCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_EauClaire_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",394000.0],PARAMETER["False_Northing",300812.797],PARAMETER["Central_Meridian",-91.28888888888889],PARAMETER["Standard_Parallel_1",44.87228112638889],PARAMETER["Scale_Factor",1.000035079],PARAMETER["Latitude_Of_Origin",44.87228112638889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWoodCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Wood_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",208483.6173],PARAMETER["False_Northing",134589.754],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.36259546944444],PARAMETER["Scale_Factor",1.0000421209],PARAMETER["Latitude_Of_Origin",44.36259546944444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWoodCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Wood_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",684000.001],PARAMETER["False_Northing",441566.551],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.36259546944444],PARAMETER["Scale_Factor",1.0000421209],PARAMETER["Latitude_Of_Origin",44.36259546944444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWausharaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Waushara_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120091.4402],PARAMETER["False_Northing",45069.7587],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",44.11394404583334],PARAMETER["Scale_Factor",1.0000392096],PARAMETER["Latitude_Of_Origin",44.11394404583334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWausharaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Waushara_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",394000.0],PARAMETER["False_Northing",147866.367],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",44.11394404583334],PARAMETER["Scale_Factor",1.0000392096],PARAMETER["Latitude_Of_Origin",44.11394404583334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWaupacaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Waupaca_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185013.9709],PARAMETER["False_Northing",0.007],PARAMETER["Central_Meridian",-88.81666666666666],PARAMETER["Scale_Factor",1.0000333645],PARAMETER["Latitude_Of_Origin",43.42027777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWaupacaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Waupaca_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",607000.003],PARAMETER["False_Northing",0.023],PARAMETER["Central_Meridian",-88.81666666666666],PARAMETER["Scale_Factor",1.0000333645],PARAMETER["Latitude_Of_Origin",43.42027777777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWaukeshaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Waukesha_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",208788.418],PARAMETER["False_Northing",0.0034],PARAMETER["Central_Meridian",-88.225],PARAMETER["Scale_Factor",1.0000346179],PARAMETER["Latitude_Of_Origin",42.56944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWaukeshaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Waukesha_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",685000.001],PARAMETER["False_Northing",0.011],PARAMETER["Central_Meridian",-88.225],PARAMETER["Scale_Factor",1.0000346179],PARAMETER["Latitude_Of_Origin",42.56944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWashingtonCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Washington_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",120091.4415],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-88.06388888888888],PARAMETER["Scale_Factor",1.00003738],PARAMETER["Latitude_Of_Origin",42.91805555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWashingtonCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Washington_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",394000.004],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-88.06388888888888],PARAMETER["Scale_Factor",1.00003738],PARAMETER["Latitude_Of_Origin",42.91805555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWashburnCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Washburn_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",234086.8682],PARAMETER["False_Northing",188358.6058],PARAMETER["Central_Meridian",-91.78333333333333],PARAMETER["Standard_Parallel_1",45.96121983333334],PARAMETER["Scale_Factor",1.0000475376],PARAMETER["Latitude_Of_Origin",45.96121983333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWashburnCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Washburn_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",768000.0],PARAMETER["False_Northing",617973.193],PARAMETER["Central_Meridian",-91.78333333333333],PARAMETER["Standard_Parallel_1",45.96121983333334],PARAMETER["Scale_Factor",1.0000475376],PARAMETER["Latitude_Of_Origin",45.96121983333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWalworthCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Walworth_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",232562.8651],PARAMETER["False_Northing",111088.2224],PARAMETER["Central_Meridian",-88.54166666666667],PARAMETER["Standard_Parallel_1",42.66946209694444],PARAMETER["Scale_Factor",1.0000367192],PARAMETER["Latitude_Of_Origin",42.66946209694444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWalworthCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Walworth_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",763000.0],PARAMETER["False_Northing",364461.943],PARAMETER["Central_Meridian",-88.54166666666667],PARAMETER["Standard_Parallel_1",42.66946209694444],PARAMETER["Scale_Factor",1.0000367192],PARAMETER["Latitude_Of_Origin",42.66946209694444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSVilasCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Vilas_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",134417.0689],PARAMETER["False_Northing",50337.1092],PARAMETER["Central_Meridian",-89.48888888888889],PARAMETER["Standard_Parallel_1",46.07784409055556],PARAMETER["Scale_Factor",1.0000730142],PARAMETER["Latitude_Of_Origin",46.07784409055556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSVilasCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Vilas_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",441000.0],PARAMETER["False_Northing",165147.666],PARAMETER["Central_Meridian",-89.48888888888889],PARAMETER["Standard_Parallel_1",46.07784409055556],PARAMETER["Scale_Factor",1.0000730142],PARAMETER["Latitude_Of_Origin",46.07784409055556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSVernonCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Vernon_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",222504.4451],PARAMETER["False_Northing",47532.0602],PARAMETER["Central_Meridian",-90.78333333333333],PARAMETER["Standard_Parallel_1",43.57503293972223],PARAMETER["Scale_Factor",1.0000408158],PARAMETER["Latitude_Of_Origin",43.57503293972223],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSVernonCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Vernon_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",730000.0],PARAMETER["False_Northing",155944.768],PARAMETER["Central_Meridian",-90.78333333333333],PARAMETER["Standard_Parallel_1",43.57503293972223],PARAMETER["Scale_Factor",1.0000408158],PARAMETER["Latitude_Of_Origin",43.57503293972223],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSTrempealeauCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Trempealeau_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",256946.9138],PARAMETER["False_Northing",0.0041],PARAMETER["Central_Meridian",-91.36666666666666],PARAMETER["Scale_Factor",1.0000361538],PARAMETER["Latitude_Of_Origin",43.16111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSTrempealeauCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Trempealeau_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",843000.0],PARAMETER["False_Northing",0.013],PARAMETER["Central_Meridian",-91.36666666666666],PARAMETER["Scale_Factor",1.0000361538],PARAMETER["Latitude_Of_Origin",43.16111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSTaylorCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Taylor_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",187147.5744],PARAMETER["False_Northing",107746.7522],PARAMETER["Central_Meridian",-90.48333333333333],PARAMETER["Standard_Parallel_1",45.17782208583333],PARAMETER["Scale_Factor",1.0000597566],PARAMETER["Latitude_Of_Origin",45.17782208583333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSTaylorCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Taylor_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",614000.0],PARAMETER["False_Northing",353499.136],PARAMETER["Central_Meridian",-90.48333333333333],PARAMETER["Standard_Parallel_1",45.17782208583333],PARAMETER["Scale_Factor",1.0000597566],PARAMETER["Latitude_Of_Origin",45.17782208583333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSStCroixCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_St_Croix_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165506.7302],PARAMETER["False_Northing",0.0103],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000381803],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSStCroixCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_St_Croix_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",542999.997],PARAMETER["False_Northing",0.034],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000381803],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSShawanoCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Shawano_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262433.3253],PARAMETER["False_Northing",0.0096],PARAMETER["Central_Meridian",-88.60555555555555],PARAMETER["Scale_Factor",1.000032144],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSShawanoCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Shawano_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",861000.001],PARAMETER["False_Northing",0.031],PARAMETER["Central_Meridian",-88.60555555555555],PARAMETER["Scale_Factor",1.000032144],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSSawyerCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Sawyer_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",216713.2336],PARAMETER["False_Northing",120734.1631],PARAMETER["Central_Meridian",-91.11666666666666],PARAMETER["Standard_Parallel_1",45.90009913138888],PARAMETER["Scale_Factor",1.0000573461],PARAMETER["Latitude_Of_Origin",45.90009913138888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSSawyerCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Sawyer_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",711000.001],PARAMETER["False_Northing",396108.667],PARAMETER["Central_Meridian",-91.11666666666666],PARAMETER["Standard_Parallel_1",45.90009913138888],PARAMETER["Scale_Factor",1.0000573461],PARAMETER["Latitude_Of_Origin",45.90009913138888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSSaukCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Sauk_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185623.5716],PARAMETER["False_Northing",0.0051],PARAMETER["Central_Meridian",-89.9],PARAMETER["Scale_Factor",1.0000373868],PARAMETER["Latitude_Of_Origin",42.81944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSSaukCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Sauk_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",609000.001],PARAMETER["False_Northing",0.017],PARAMETER["Central_Meridian",-89.9],PARAMETER["Scale_Factor",1.0000373868],PARAMETER["Latitude_Of_Origin",42.81944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSRuskCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Rusk_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250546.1013],PARAMETER["False_Northing",0.0234],PARAMETER["Central_Meridian",-91.06666666666666],PARAMETER["Scale_Factor",1.0000495976],PARAMETER["Latitude_Of_Origin",43.91944444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSRuskCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Rusk_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",822000.001],PARAMETER["False_Northing",0.077],PARAMETER["Central_Meridian",-91.06666666666666],PARAMETER["Scale_Factor",1.0000495976],PARAMETER["Latitude_Of_Origin",43.91944444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSRockCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Rock_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",146304.2926],PARAMETER["False_Northing",0.0068],PARAMETER["Central_Meridian",-89.07222222222222],PARAMETER["Scale_Factor",1.0000337311],PARAMETER["Latitude_Of_Origin",41.94444444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSRockCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Rock_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",480000.0],PARAMETER["False_Northing",0.022],PARAMETER["Central_Meridian",-89.07222222222222],PARAMETER["Scale_Factor",1.0000337311],PARAMETER["Latitude_Of_Origin",41.94444444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSRichlandCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Richland_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",202387.6048],PARAMETER["False_Northing",134255.4253],PARAMETER["Central_Meridian",-90.43055555555556],PARAMETER["Standard_Parallel_1",43.3223129275],PARAMETER["Scale_Factor",1.0000375653],PARAMETER["Latitude_Of_Origin",43.3223129275],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSRichlandCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Richland_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",664000.0],PARAMETER["False_Northing",440469.675],PARAMETER["Central_Meridian",-90.43055555555556],PARAMETER["Standard_Parallel_1",43.3223129275],PARAMETER["Scale_Factor",1.0000375653],PARAMETER["Latitude_Of_Origin",43.3223129275],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSPriceCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Price_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",227990.8546],PARAMETER["False_Northing",0.0109],PARAMETER["Central_Meridian",-90.48888888888889],PARAMETER["Scale_Factor",1.0000649554],PARAMETER["Latitude_Of_Origin",44.55555555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSPriceCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Price_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",747999.995],PARAMETER["False_Northing",0.036],PARAMETER["Central_Meridian",-90.48888888888889],PARAMETER["Scale_Factor",1.0000649554],PARAMETER["Latitude_Of_Origin",44.55555555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSPortageCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Portage_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",56388.1128],PARAMETER["False_Northing",50022.1874],PARAMETER["Central_Meridian",-89.5],PARAMETER["Standard_Parallel_1",44.41682397527777],PARAMETER["Scale_Factor",1.000039936],PARAMETER["Latitude_Of_Origin",44.41682397527777],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSPortageCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Portage_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",185000.0],PARAMETER["False_Northing",164114.46],PARAMETER["Central_Meridian",-89.5],PARAMETER["Standard_Parallel_1",44.41682397527777],PARAMETER["Scale_Factor",1.000039936],PARAMETER["Latitude_Of_Origin",44.41682397527777],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSPolkCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Polk_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",141732.2823],PARAMETER["False_Northing",0.0059],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000433849],PARAMETER["Latitude_Of_Origin",44.66111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSPolkCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Polk_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",464999.996],PARAMETER["False_Northing",0.019],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000433849],PARAMETER["Latitude_Of_Origin",44.66111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSPepinandPierceMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Pepin_and_Pierce_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",167640.3354],PARAMETER["False_Northing",86033.0876],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.63614887194444],PARAMETER["Scale_Factor",1.0000362977],PARAMETER["Latitude_Of_Origin",44.63614887194444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSPepinandPierceFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Pepin_and_Pierce_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",550000.0],PARAMETER["False_Northing",282260.222],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.63614887194444],PARAMETER["Scale_Factor",1.0000362977],PARAMETER["Latitude_Of_Origin",44.63614887194444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSOneidaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Oneida_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",70104.1401],PARAMETER["False_Northing",57588.0346],PARAMETER["Central_Meridian",-89.54444444444444],PARAMETER["Standard_Parallel_1",45.70422377027778],PARAMETER["Scale_Factor",1.0000686968],PARAMETER["Latitude_Of_Origin",45.70422377027778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSOneidaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Oneida_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",230000.0],PARAMETER["False_Northing",188936.744],PARAMETER["Central_Meridian",-89.54444444444444],PARAMETER["Standard_Parallel_1",45.70422377027778],PARAMETER["Scale_Factor",1.0000686968],PARAMETER["Latitude_Of_Origin",45.70422377027778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSOcontoCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Oconto_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",182880.3676],PARAMETER["False_Northing",0.0033],PARAMETER["Central_Meridian",-87.90833333333335],PARAMETER["Scale_Factor",1.0000236869],PARAMETER["Latitude_Of_Origin",44.39722222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSOcontoCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Oconto_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.006],PARAMETER["False_Northing",0.011],PARAMETER["Central_Meridian",-87.90833333333335],PARAMETER["Scale_Factor",1.0000236869],PARAMETER["Latitude_Of_Origin",44.39722222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSMonroeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Monroe_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",204521.209],PARAMETER["False_Northing",121923.9861],PARAMETER["Central_Meridian",-90.64166666666668],PARAMETER["Standard_Parallel_1",44.00007392861111],PARAMETER["Scale_Factor",1.0000434122],PARAMETER["Latitude_Of_Origin",44.00007392861111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSMonroeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Monroe_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",671000.0],PARAMETER["False_Northing",400012.278],PARAMETER["Central_Meridian",-90.64166666666668],PARAMETER["Standard_Parallel_1",44.00007392861111],PARAMETER["Scale_Factor",1.0000434122],PARAMETER["Latitude_Of_Origin",44.00007392861111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSMenomineeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Menominee_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",105461.0121],PARAMETER["False_Northing",0.0029],PARAMETER["Central_Meridian",-88.41666666666667],PARAMETER["Scale_Factor",1.0000362499],PARAMETER["Latitude_Of_Origin",44.71666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSMenomineeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Menominee_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",346000.004],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-88.41666666666667],PARAMETER["Scale_Factor",1.0000362499],PARAMETER["Latitude_Of_Origin",44.71666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSMarinetteCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Marinette_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",238658.8794],PARAMETER["False_Northing",0.0032],PARAMETER["Central_Meridian",-87.71111111111111],PARAMETER["Scale_Factor",1.0000234982],PARAMETER["Latitude_Of_Origin",44.69166666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSMarinetteCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Marinette_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",783000.007],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-87.71111111111111],PARAMETER["Scale_Factor",1.0000234982],PARAMETER["Latitude_Of_Origin",44.69166666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSMarathonCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Marathon_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",74676.1493],PARAMETER["False_Northing",55049.2669],PARAMETER["Central_Meridian",-89.77],PARAMETER["Standard_Parallel_1",44.90090442361111],PARAMETER["Scale_Factor",1.000053289],PARAMETER["Latitude_Of_Origin",44.90090442361111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSMarathonCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Marathon_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",245000.0],PARAMETER["False_Northing",180607.47],PARAMETER["Central_Meridian",-89.77],PARAMETER["Standard_Parallel_1",44.90090442361111],PARAMETER["Scale_Factor",1.000053289],PARAMETER["Latitude_Of_Origin",44.90090442361111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSLincolnCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Lincoln_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",116129.0323],PARAMETER["False_Northing",0.0058],PARAMETER["Central_Meridian",-89.73333333333333],PARAMETER["Scale_Factor",1.0000599003],PARAMETER["Latitude_Of_Origin",44.84444444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSLincolnCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Lincoln_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",381000.0],PARAMETER["False_Northing",0.019],PARAMETER["Central_Meridian",-89.73333333333333],PARAMETER["Scale_Factor",1.0000599003],PARAMETER["Latitude_Of_Origin",44.84444444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSLangladeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Langlade_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",198425.197],PARAMETER["False_Northing",105279.7829],PARAMETER["Central_Meridian",-89.03333333333333],PARAMETER["Standard_Parallel_1",45.15423710527778],PARAMETER["Scale_Factor",1.0000627024],PARAMETER["Latitude_Of_Origin",45.15423710527778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSLangladeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Langlade_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",651000.0],PARAMETER["False_Northing",345405.421],PARAMETER["Central_Meridian",-89.03333333333333],PARAMETER["Standard_Parallel_1",45.15423710527778],PARAMETER["Scale_Factor",1.0000627024],PARAMETER["Latitude_Of_Origin",45.15423710527778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSLaCrosseCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_LaCrosse_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",130454.6598],PARAMETER["False_Northing",0.0033],PARAMETER["Central_Meridian",-91.31666666666666],PARAMETER["Scale_Factor",1.0000319985],PARAMETER["Latitude_Of_Origin",43.45111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSLaCrosseCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_LaCrosse_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",427999.996],PARAMETER["False_Northing",0.011],PARAMETER["Central_Meridian",-91.31666666666666],PARAMETER["Scale_Factor",1.0000319985],PARAMETER["Latitude_Of_Origin",43.45111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSKewauneeManitowocandSheboyganMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Kewaunee_Manitowoc_and_Sheboygan_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",79857.7614],PARAMETER["False_Northing",0.0012],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0000233704],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSKewauneeManitowocandSheboyganFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Kewaunee_Manitowoc_and_Sheboygan_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262000.006],PARAMETER["False_Northing",0.004],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0000233704],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSKenoshaMilwaukeeOzaukeeandRacineMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Kenosha_Milwaukee_Ozaukee_and_Racine_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185928.3728],PARAMETER["False_Northing",0.0009],PARAMETER["Central_Meridian",-87.89444444444449],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSKenoshaMilwaukeeOzaukeeandRacineFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Kenosha_Milwaukee_Ozaukee_and_Racine_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",610000.003],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-87.89444444444449],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSJacksonCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Jackson_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",27000.0],PARAMETER["False_Northing",25000.0],PARAMETER["Central_Meridian",-90.84429651944444],PARAMETER["Scale_Factor",1.0000353],PARAMETER["Latitude_Of_Origin",44.25333512777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSJacksonCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Jackson_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88582.5],PARAMETER["False_Northing",82020.833],PARAMETER["Central_Meridian",-90.84429651944444],PARAMETER["Scale_Factor",1.0000353],PARAMETER["Latitude_Of_Origin",44.25333512777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSIronCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Iron_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",220980.4419],PARAMETER["False_Northing",0.0085],PARAMETER["Central_Meridian",-90.25555555555556],PARAMETER["Scale_Factor",1.0000677153],PARAMETER["Latitude_Of_Origin",45.43333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSIronCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Iron_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",725000.0],PARAMETER["False_Northing",0.028],PARAMETER["Central_Meridian",-90.25555555555556],PARAMETER["Scale_Factor",1.0000677153],PARAMETER["Latitude_Of_Origin",45.43333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSIowaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Iowa_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",113081.0261],PARAMETER["False_Northing",0.0045],PARAMETER["Central_Meridian",-90.16111111111111],PARAMETER["Scale_Factor",1.0000394961],PARAMETER["Latitude_Of_Origin",42.53888888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSIowaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Iowa_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",371000.0],PARAMETER["False_Northing",0.015],PARAMETER["Central_Meridian",-90.16111111111111],PARAMETER["Scale_Factor",1.0000394961],PARAMETER["Latitude_Of_Origin",42.53888888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSGreenLakeandMarquetteMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Green_Lake_and_Marquette_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150876.3018],PARAMETER["False_Northing",79170.7795],PARAMETER["Central_Meridian",-89.24166666666665],PARAMETER["Standard_Parallel_1",43.80700011777778],PARAMETER["Scale_Factor",1.0000344057],PARAMETER["Latitude_Of_Origin",43.80700011777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSGreenLakeandMarquetteFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Green_Lake_and_Marquette_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",495000.0],PARAMETER["False_Northing",259746.132],PARAMETER["Central_Meridian",-89.24166666666665],PARAMETER["Standard_Parallel_1",43.80700011777778],PARAMETER["Scale_Factor",1.0000344057],PARAMETER["Latitude_Of_Origin",43.80700011777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSGreenandLafayetteMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Green_and_Lafayette_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",170078.7403],PARAMETER["False_Northing",45830.2947],PARAMETER["Central_Meridian",-89.83888888888886],PARAMETER["Standard_Parallel_1",42.63756227694443],PARAMETER["Scale_Factor",1.0000390487],PARAMETER["Latitude_Of_Origin",42.63756227694443],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSGreenandLafayetteFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Green_and_Lafayette_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",558000.0],PARAMETER["False_Northing",150361.559],PARAMETER["Central_Meridian",-89.83888888888886],PARAMETER["Standard_Parallel_1",42.63756227694443],PARAMETER["Scale_Factor",1.0000390487],PARAMETER["Latitude_Of_Origin",42.63756227694443],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSGrantCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Grant_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",242316.4841],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-90.8],PARAMETER["Scale_Factor",1.0000349452],PARAMETER["Latitude_Of_Origin",41.41111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSGrantCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Grant_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",794999.998],PARAMETER["False_Northing",0.033],PARAMETER["Central_Meridian",-90.8],PARAMETER["Scale_Factor",1.0000349452],PARAMETER["Latitude_Of_Origin",41.41111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSForestCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Forest_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",275844.5533],PARAMETER["False_Northing",0.0157],PARAMETER["Central_Meridian",-88.63333333333334],PARAMETER["Scale_Factor",1.0000673004],PARAMETER["Latitude_Of_Origin",44.00555555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSForestCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Forest_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",905000.005],PARAMETER["False_Northing",0.052],PARAMETER["Central_Meridian",-88.63333333333334],PARAMETER["Scale_Factor",1.0000673004],PARAMETER["Latitude_Of_Origin",44.00555555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSDunnCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Dunn_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",51816.104],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-91.89444444444445],PARAMETER["Scale_Factor",1.0000410324],PARAMETER["Latitude_Of_Origin",44.40833333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSDunnCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Dunn_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",170000.001],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-91.89444444444445],PARAMETER["Scale_Factor",1.0000410324],PARAMETER["Latitude_Of_Origin",44.40833333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSDouglasCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Douglas_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",59131.3183],PARAMETER["False_Northing",0.0041],PARAMETER["Central_Meridian",-91.91666666666667],PARAMETER["Scale_Factor",1.0000385418],PARAMETER["Latitude_Of_Origin",45.88333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSDouglasCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Douglas_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",194000.0],PARAMETER["False_Northing",0.013],PARAMETER["Central_Meridian",-91.91666666666667],PARAMETER["Scale_Factor",1.0000385418],PARAMETER["Latitude_Of_Origin",45.88333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSDoorCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Door_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",158801.1176],PARAMETER["False_Northing",0.0023],PARAMETER["Central_Meridian",-87.27222222222223],PARAMETER["Scale_Factor",1.0000187521],PARAMETER["Latitude_Of_Origin",44.4],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSDoorCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Door_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",521000.0],PARAMETER["False_Northing",0.008],PARAMETER["Central_Meridian",-87.27222222222223],PARAMETER["Scale_Factor",1.0000187521],PARAMETER["Latitude_Of_Origin",44.4],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSDodgeandJeffersonMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Dodge_and_Jefferson_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",263347.7263],PARAMETER["False_Northing",0.0076],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",1.0000346418],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSDodgeandJeffersonFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Dodge_and_Jefferson_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",863999.999],PARAMETER["False_Northing",0.025],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",1.0000346418],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSDaneCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Dane_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",247193.2944],PARAMETER["False_Northing",146591.9896],PARAMETER["Central_Meridian",-89.42222222222223],PARAMETER["Standard_Parallel_1",43.0695160375],PARAMETER["Scale_Factor",1.0000384786],PARAMETER["Latitude_Of_Origin",43.0695160375],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSDaneCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Dane_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",811000.0],PARAMETER["False_Northing",480943.886],PARAMETER["Central_Meridian",-89.42222222222223],PARAMETER["Standard_Parallel_1",43.0695160375],PARAMETER["Scale_Factor",1.0000384786],PARAMETER["Latitude_Of_Origin",43.0695160375],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSCrawfordCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Crawford_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",113690.6274],PARAMETER["False_Northing",53703.1201],PARAMETER["Central_Meridian",-90.9388888888889],PARAMETER["Standard_Parallel_1",43.200055605],PARAMETER["Scale_Factor",1.0000349151],PARAMETER["Latitude_Of_Origin",43.200055605],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSCrawfordCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Crawford_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",373000.0],PARAMETER["False_Northing",176190.987],PARAMETER["Central_Meridian",-90.9388888888889],PARAMETER["Standard_Parallel_1",43.200055605],PARAMETER["Scale_Factor",1.0000349151],PARAMETER["Latitude_Of_Origin",43.200055605],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSColumbiaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Columbia_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",169164.3381],PARAMETER["False_Northing",111569.6134],PARAMETER["Central_Meridian",-89.39444444444445],PARAMETER["Standard_Parallel_1",43.46254664583333],PARAMETER["Scale_Factor",1.00003498],PARAMETER["Latitude_Of_Origin",43.46254664583333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSColumbiaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Columbia_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",554999.999],PARAMETER["False_Northing",366041.307],PARAMETER["Central_Meridian",-89.39444444444445],PARAMETER["Standard_Parallel_1",43.46254664583333],PARAMETER["Scale_Factor",1.00003498],PARAMETER["Latitude_Of_Origin",43.46254664583333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSClarkCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Clark_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",199949.1989],PARAMETER["False_Northing",0.0086],PARAMETER["Central_Meridian",-90.70833333333334],PARAMETER["Scale_Factor",1.0000463003],PARAMETER["Latitude_Of_Origin",43.6],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSClarkCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Clark_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",655999.997],PARAMETER["False_Northing",0.028],PARAMETER["Central_Meridian",-90.70833333333334],PARAMETER["Scale_Factor",1.0000463003],PARAMETER["Latitude_Of_Origin",43.6],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSChippewaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Chippewa_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",60045.72],PARAMETER["False_Northing",44091.4346],PARAMETER["Central_Meridian",-91.29444444444444],PARAMETER["Standard_Parallel_1",44.97785689861112],PARAMETER["Scale_Factor",1.0000391127],PARAMETER["Latitude_Of_Origin",44.97785689861112],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSChippewaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Chippewa_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",197000.0],PARAMETER["False_Northing",144656.648],PARAMETER["Central_Meridian",-91.29444444444444],PARAMETER["Standard_Parallel_1",44.97785689861112],PARAMETER["Scale_Factor",1.0000391127],PARAMETER["Latitude_Of_Origin",44.97785689861112],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSCalumetFondduLacOutagamieandWinnebagoMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Calumet_Fond_du_Lac_Outagamie_and_Winnebago_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",244754.8893],PARAMETER["False_Northing",0.0049],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444443],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSCalumetFondduLacOutagamieandWinnebagoFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Calumet_Fond_du_Lac_Outagamie_and_Winnebago_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",802999.999],PARAMETER["False_Northing",0.016],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444443],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSBurnettCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Burnett_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",64008.1276],PARAMETER["False_Northing",59445.9043],PARAMETER["Central_Meridian",-92.45777777777778],PARAMETER["Standard_Parallel_1",45.89871486583333],PARAMETER["Scale_Factor",1.0000383841],PARAMETER["Latitude_Of_Origin",45.89871486583333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSBurnettCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Burnett_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",209999.999],PARAMETER["False_Northing",195032.104],PARAMETER["Central_Meridian",-92.45777777777778],PARAMETER["Standard_Parallel_1",45.89871486583333],PARAMETER["Scale_Factor",1.0000383841],PARAMETER["Latitude_Of_Origin",45.89871486583333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSBuffaloCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Buffalo_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",175260.3502],PARAMETER["False_Northing",0.0048],PARAMETER["Central_Meridian",-91.79722222222222],PARAMETER["Scale_Factor",1.0000382778],PARAMETER["Latitude_Of_Origin",43.48138888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSBuffaloCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Buffalo_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",574999.999],PARAMETER["False_Northing",0.016],PARAMETER["Central_Meridian",-91.79722222222222],PARAMETER["Scale_Factor",1.0000382778],PARAMETER["Latitude_Of_Origin",43.48138888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSBrownCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Brown_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31600.0],PARAMETER["False_Northing",4600.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSBrownCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Brown_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",103674.333],PARAMETER["False_Northing",15091.833],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSBayfieldCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Bayfield_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",228600.4575],PARAMETER["False_Northing",148551.4837],PARAMETER["Central_Meridian",-91.15277777777779],PARAMETER["Standard_Parallel_1",46.66964837722222],PARAMETER["Scale_Factor",1.0000331195],PARAMETER["Latitude_Of_Origin",46.66964837722222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSBayfieldCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Bayfield_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",750000.001],PARAMETER["False_Northing",487372.659],PARAMETER["Central_Meridian",-91.15277777777779],PARAMETER["Standard_Parallel_1",46.66964837722222],PARAMETER["Scale_Factor",1.0000331195],PARAMETER["Latitude_Of_Origin",46.66964837722222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSBarronCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Barron_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",93150.0],PARAMETER["False_Northing",0.0029],PARAMETER["Central_Meridian",-91.85],PARAMETER["Scale_Factor",1.0000486665],PARAMETER["Latitude_Of_Origin",45.13333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSBarronCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Barron_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",305609.625],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-91.85],PARAMETER["Scale_Factor",1.0000486665],PARAMETER["Latitude_Of_Origin",45.13333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSAshlandCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Ashland_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",172821.9461],PARAMETER["False_Northing",0.0017],PARAMETER["Central_Meridian",-90.62222222222222],PARAMETER["Scale_Factor",1.0000495683],PARAMETER["Latitude_Of_Origin",45.70611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSAshlandCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Ashland_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",567000.001],PARAMETER["False_Northing",0.006],PARAMETER["Central_Meridian",-90.62222222222222],PARAMETER["Scale_Factor",1.0000495683],PARAMETER["Latitude_Of_Origin",45.70611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSAdamsandJuneauMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Adams_and_Juneau_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",147218.6942],PARAMETER["False_Northing",0.0037],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",1.0000365285],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSAdamsandJuneauFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Adams_and_Juneau_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",482999.999],PARAMETER["False_Northing",0.012],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",1.0000365285],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "OCRSBurns-HarperNAD19832011TMMeters",
    `PROJCS["OCRS_Burns-Harper_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",90000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.6666666666667],PARAMETER["Scale_Factor",1.00014],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBurns-HarperNAD19832011TMFtIntl",
    `PROJCS["OCRS_Burns-Harper_NAD_1983_2011_TM_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",295275.5906],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.6666666666667],PARAMETER["Scale_Factor",1.00014],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSCanyonCity-BurnsNAD19832011TMMeters",
    `PROJCS["OCRS_Canyon_City-Burns_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Scale_Factor",1.00022],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSCanyonCity-BurnsNAD19832011TMFtIntl",
    `PROJCS["OCRS_Canyon_City-Burns_NAD_1983_2011_TM_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",65616.7979],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Scale_Factor",1.00022],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSCoastRangeNorthNAD19832011LCCMeters",
    `PROJCS["OCRS_Coast_Range_North_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",30000.0],PARAMETER["False_Northing",20000.0],PARAMETER["Central_Meridian",-123.4166666666667],PARAMETER["Standard_Parallel_1",45.58333333333334],PARAMETER["Scale_Factor",1.000045],PARAMETER["Latitude_Of_Origin",45.58333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSCoastRangeNorthNAD19832011LCCFtIntl",
    `PROJCS["OCRS_Coast_Range_North_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",98425.1969],PARAMETER["False_Northing",65616.7979],PARAMETER["Central_Meridian",-123.4166666666667],PARAMETER["Standard_Parallel_1",45.58333333333334],PARAMETER["Scale_Factor",1.000045],PARAMETER["Latitude_Of_Origin",45.58333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSDayville-PrairieCityNAD19832011TMMeters",
    `PROJCS["OCRS_Dayville-Prairie_City_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.6333333333333],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSDayville-PrairieCityNAD19832011TMFtIntl",
    `PROJCS["OCRS_Dayville-Prairie_City_NAD_1983_2011_TM_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",65616.7979],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.6333333333333],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSDenio-BurnsNAD19832011TMMeters",
    `PROJCS["OCRS_Denio-Burns_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.4166666666667],PARAMETER["Scale_Factor",1.00019],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSDenio-BurnsNAD19832011TMFtIntl",
    `PROJCS["OCRS_Denio-Burns_NAD_1983_2011_TM_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262467.1916],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.4166666666667],PARAMETER["Scale_Factor",1.00019],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSHalfwayNAD19832011LCCMeters",
    `PROJCS["OCRS_Halfway_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",70000.0],PARAMETER["Central_Meridian",-117.25],PARAMETER["Standard_Parallel_1",45.25],PARAMETER["Scale_Factor",1.000085],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSHalfwayNAD19832011LCCFtIntl",
    `PROJCS["OCRS_Halfway_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",131233.5958],PARAMETER["False_Northing",229658.7927],PARAMETER["Central_Meridian",-117.25],PARAMETER["Standard_Parallel_1",45.25],PARAMETER["Scale_Factor",1.000085],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSMedford-DiamondLakeNAD19832011LCCMeters",
    `PROJCS["OCRS_Medford-Diamond_Lake_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",60000.0],PARAMETER["False_Northing",-60000.0],PARAMETER["Central_Meridian",-122.25],PARAMETER["Standard_Parallel_1",42.0],PARAMETER["Scale_Factor",1.00004],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSMedford-DiamondLakeNAD19832011LCCFtIntl",
    `PROJCS["OCRS_Medford-Diamond_Lake_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",196850.3937],PARAMETER["False_Northing",-196850.3937],PARAMETER["Central_Meridian",-122.25],PARAMETER["Standard_Parallel_1",42.0],PARAMETER["Scale_Factor",1.00004],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSMitchellNAD19832011LCCMeters",
    `PROJCS["OCRS_Mitchell_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",30000.0],PARAMETER["False_Northing",290000.0],PARAMETER["Central_Meridian",-120.25],PARAMETER["Standard_Parallel_1",47.0],PARAMETER["Scale_Factor",0.99927],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSMitchellNAD19832011LCCFtIntl",
    `PROJCS["OCRS_Mitchell_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",98425.1969],PARAMETER["False_Northing",951443.5696],PARAMETER["Central_Meridian",-120.25],PARAMETER["Standard_Parallel_1",47.0],PARAMETER["Scale_Factor",0.99927],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSNorthCentralNAD19832011LCCMeters",
    `PROJCS["OCRS_North_Central_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",140000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",46.16666666666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",46.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSNorthCentralNAD19832011LCCFtIntl",
    `PROJCS["OCRS_North_Central_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",328083.9895],PARAMETER["False_Northing",459317.5853],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",46.16666666666666],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",46.16666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSOchocoSummitNAD19832011LCCMeters",
    `PROJCS["OCRS_Ochoco_Summit_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",-80000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.5],PARAMETER["Scale_Factor",1.00006],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSOchocoSummitNAD19832011LCCFtIntl",
    `PROJCS["OCRS_Ochoco_Summit_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",131233.5958],PARAMETER["False_Northing",-262467.1916],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.5],PARAMETER["Scale_Factor",1.00006],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSOwyheeNAD19832011TMMeters",
    `PROJCS["OCRS_Owyhee_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",70000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.5833333333333],PARAMETER["Scale_Factor",1.00018],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSOwyheeNAD19832011TMFtIntl",
    `PROJCS["OCRS_Owyhee_NAD_1983_2011_TM_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",229658.7927],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.5833333333333],PARAMETER["Scale_Factor",1.00018],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPilotRock-UkiahNAD19832011LCCMeters",
    `PROJCS["OCRS_Pilot_Rock-Ukiah_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",130000.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",46.16666666666666],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",46.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPilotRock-UkiahNAD19832011LCCFtIntl",
    `PROJCS["OCRS_Pilot_Rock-Ukiah_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",164041.9948],PARAMETER["False_Northing",426509.1864],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",46.16666666666666],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",46.16666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPrairieCity-BroganNAD19832011LCCMeters",
    `PROJCS["OCRS_Prairie_City-Brogan_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",60000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",44.0],PARAMETER["Scale_Factor",1.00017],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPrairieCity-BroganNAD19832011LCCFtIntl",
    `PROJCS["OCRS_Prairie_City-Brogan_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",196850.3937],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",44.0],PARAMETER["Scale_Factor",1.00017],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSRiley-LakeviewNAD19832011TMMeters",
    `PROJCS["OCRS_Riley-Lakeview_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",70000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.3333333333333],PARAMETER["Scale_Factor",1.000215],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSRiley-LakeviewNAD19832011TMFtIntl",
    `PROJCS["OCRS_Riley-Lakeview_NAD_1983_2011_TM_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",229658.7927],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.3333333333333],PARAMETER["Scale_Factor",1.000215],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSSiskiyouPassNAD19832011LCCMeters",
    `PROJCS["OCRS_Siskiyou_Pass_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",10000.0],PARAMETER["False_Northing",60000.0],PARAMETER["Central_Meridian",-122.5833333333333],PARAMETER["Standard_Parallel_1",42.5],PARAMETER["Scale_Factor",1.00015],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSSiskiyouPassNAD19832011LCCFtIntl",
    `PROJCS["OCRS_Siskiyou_Pass_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",32808.399],PARAMETER["False_Northing",196850.3937],PARAMETER["Central_Meridian",-122.5833333333333],PARAMETER["Standard_Parallel_1",42.5],PARAMETER["Scale_Factor",1.00015],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSUkiah-FoxNAD19832011LCCMeters",
    `PROJCS["OCRS_Ukiah-Fox_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",30000.0],PARAMETER["False_Northing",90000.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",45.25],PARAMETER["Scale_Factor",1.00014],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSUkiah-FoxNAD19832011LCCFtIntl",
    `PROJCS["OCRS_Ukiah-Fox_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",98425.1969],PARAMETER["False_Northing",295275.5906],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",45.25],PARAMETER["Scale_Factor",1.00014],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSWallowaNAD19832011TMMeters",
    `PROJCS["OCRS_Wallowa_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",60000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.5],PARAMETER["Scale_Factor",1.000195],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSWallowaNAD19832011TMFtIntl",
    `PROJCS["OCRS_Wallowa_NAD_1983_2011_TM_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",196850.3937],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.5],PARAMETER["Scale_Factor",1.000195],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSWarnerHighwayNAD19832011LCCMeters",
    `PROJCS["OCRS_Warner_Highway_NAD_1983_2011_LCC_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",60000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",42.5],PARAMETER["Scale_Factor",1.000245],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSWarnerHighwayNAD19832011LCCFtIntl",
    `PROJCS["OCRS_Warner_Highway_NAD_1983_2011_LCC_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",131233.5958],PARAMETER["False_Northing",196850.3937],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",42.5],PARAMETER["Scale_Factor",1.000245],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSWillamettePassNAD19832011TMMeters",
    `PROJCS["OCRS_Willamette_Pass_NAD_1983_2011_TM_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Scale_Factor",1.000223],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSWillamettePassNAD19832011TMFtIntl",
    `PROJCS["OCRS_Willamette_Pass_NAD_1983_2011_TM_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",65616.7979],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Scale_Factor",1.000223],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot",0.3048]]`
  ],
  [
    "S-JTSK[JTSK03]Krovak",
    `PROJCS["S-JTSK_[JTSK03]_Krovak",GEOGCS["S-JTSK_[JTSK03]",DATUM["S-JTSK_[JTSK03]",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Krovak"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Pseudo_Standard_Parallel_1",78.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",30.28813975277778],PARAMETER["Longitude_Of_Center",24.83333333333333],PARAMETER["Latitude_Of_Center",49.5],PARAMETER["X_Scale",1.0],PARAMETER["Y_Scale",1.0],PARAMETER["XY_Plane_Rotation",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "S-JTSK[JTSK03]KrovakEastNorth",
    `PROJCS["S-JTSK_[JTSK03]_Krovak_East_North",GEOGCS["S-JTSK_[JTSK03]",DATUM["S-JTSK_[JTSK03]",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Krovak"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Pseudo_Standard_Parallel_1",78.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",30.28813975277778],PARAMETER["Longitude_Of_Center",24.83333333333333],PARAMETER["Latitude_Of_Center",49.5],PARAMETER["X_Scale",-1.0],PARAMETER["Y_Scale",1.0],PARAMETER["XY_Plane_Rotation",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NCRSLasVegas(m)",
    `PROJCS["NAD_1983_NCRS_Las_Vegas_(m)",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-114.9666666666667],PARAMETER["Scale_Factor",1.0001],PARAMETER["Latitude_Of_Origin",36.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NCRSLasVegas(ftUS)",
    `PROJCS["NAD_1983_NCRS_Las_Vegas_(ftUS)",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333],PARAMETER["False_Northing",656166.6667],PARAMETER["Central_Meridian",-114.9666666666667],PARAMETER["Scale_Factor",1.0001],PARAMETER["Latitude_Of_Origin",36.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NCRSLasVegashigh(m)",
    `PROJCS["NAD_1983_NCRS_Las_Vegas_high_(m)",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-114.9666666666667],PARAMETER["Scale_Factor",1.000135],PARAMETER["Latitude_Of_Origin",36.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NCRSLasVegashigh(ftUS)",
    `PROJCS["NAD_1983_NCRS_Las_Vegas_high_(ftUS)",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",1312333.3333],PARAMETER["Central_Meridian",-114.9666666666667],PARAMETER["Scale_Factor",1.000135],PARAMETER["Latitude_Of_Origin",36.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)NCRSLasVegas(m)",
    `PROJCS["NAD_1983_(2011)_NCRS_Las_Vegas_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-114.9666666666667],PARAMETER["Scale_Factor",1.0001],PARAMETER["Latitude_Of_Origin",36.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(2011)NCRSLasVegas(ftUS)",
    `PROJCS["NAD_1983_(2011)_NCRS_Las_Vegas_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333],PARAMETER["False_Northing",656166.6667],PARAMETER["Central_Meridian",-114.9666666666667],PARAMETER["Scale_Factor",1.0001],PARAMETER["Latitude_Of_Origin",36.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)NCRSLasVegashigh(m)",
    `PROJCS["NAD_1983_(2011)_NCRS_Las_Vegas_high_(m)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-114.9666666666667],PARAMETER["Scale_Factor",1.000135],PARAMETER["Latitude_Of_Origin",36.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(2011)NCRSLasVegashigh(ftUS)",
    `PROJCS["NAD_1983_(2011)_NCRS_Las_Vegas_high_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",1312333.3333],PARAMETER["Central_Meridian",-114.9666666666667],PARAMETER["Scale_Factor",1.000135],PARAMETER["Latitude_Of_Origin",36.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "GDA1994WEIPA94",
    `PROJCS["GDA_1994_WEIPA94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.999929],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989GKCM9E",
    `PROJCS["ETRS_1989_GK_CM_9E",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Macao1920MacaoGrid",
    `PROJCS["Macao_1920_Macao_Grid",GEOGCS["Macao_1920",DATUM["Macao_1920",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20000.0],PARAMETER["False_Northing",20000.0],PARAMETER["Central_Meridian",113.5364694444444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.21239722222222],UNIT["Meter",1.0]]`
  ],
  [
    "Tananarive1925LabordeGrid",
    `PROJCS["Tananarive_1925_Laborde_Grid",GEOGCS["GCS_Tananarive_1925",DATUM["D_Tananarive_1925",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Laborde_Oblique_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Azimuth",18.9],PARAMETER["Longitude_Of_Center",46.43722916666667],PARAMETER["Latitude_Of_Center",-18.9],UNIT["Meter",1.0]]`
  ],
  [
    "RGTAAF07UTMZone53S",
    `PROJCS["RGTAAF07_UTM_Zone_53S",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGTAAF07UTMZone54S",
    `PROJCS["RGTAAF07_UTM_Zone_54S",GEOGCS["GCS_RGTAAF07",DATUM["D_Reseau_Geodesique_des_Terres_Australes_et_Antarctiques_Francaises_2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011KSRCSZone1",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_1",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-101.6],PARAMETER["Scale_Factor",1.000156],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone2",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_2",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.95],PARAMETER["Scale_Factor",1.000134],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone3",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_3",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.35],PARAMETER["Scale_Factor",1.000116],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone4",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_4",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.45],PARAMETER["Scale_Factor",1.000082],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone5",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_5",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.66666666666667],PARAMETER["Scale_Factor",1.000078],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone6",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_6",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.15],PARAMETER["Scale_Factor",1.000068],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone7",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_7",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-97.33333333333333],PARAMETER["Scale_Factor",1.000049],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone8",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_8",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",-96.5],PARAMETER["Standard_Parallel_1",39.16666666666666],PARAMETER["Scale_Factor",1.000044],PARAMETER["Latitude_Of_Origin",39.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone9",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_9",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",-96.5],PARAMETER["Standard_Parallel_1",38.5],PARAMETER["Scale_Factor",1.00005],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone10",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_10",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",-95.75],PARAMETER["Standard_Parallel_1",39.63333333333335],PARAMETER["Scale_Factor",1.00004],PARAMETER["Latitude_Of_Origin",39.63333333333335],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone11",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_11",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",-95.25],PARAMETER["Standard_Parallel_1",39.1],PARAMETER["Scale_Factor",1.000033],PARAMETER["Latitude_Of_Origin",39.1],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone12",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_12",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-101.4166666666667],PARAMETER["Scale_Factor",1.00014],PARAMETER["Latitude_Of_Origin",36.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone13",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_13",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.4],PARAMETER["Scale_Factor",1.000109],PARAMETER["Latitude_Of_Origin",36.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone14",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_14",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.66666666666667],PARAMETER["Scale_Factor",1.000097],PARAMETER["Latitude_Of_Origin",36.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone15",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_15",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.2],PARAMETER["Scale_Factor",1.000087],PARAMETER["Latitude_Of_Origin",36.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone16",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_16",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.55],PARAMETER["Scale_Factor",1.000069],PARAMETER["Latitude_Of_Origin",36.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone17",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_17",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-97.5],PARAMETER["Standard_Parallel_1",37.76666666666667],PARAMETER["Scale_Factor",1.000059],PARAMETER["Latitude_Of_Origin",37.76666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone18",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_18",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-97.5],PARAMETER["Standard_Parallel_1",37.18333333333333],PARAMETER["Scale_Factor",1.000055],PARAMETER["Latitude_Of_Origin",37.18333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone19",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_19",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-95.96666666666667],PARAMETER["Scale_Factor",1.000034],PARAMETER["Latitude_Of_Origin",36.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011KSRCSZone20",
    `PROJCS["NAD_1983_2011_KS_RCS_Zone_20",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-95.08333333333333],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",36.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "MGI1901Balkanszone5",
    `PROJCS["MGI_1901_Balkans_zone_5",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI1901Balkanszone6",
    `PROJCS["MGI_1901_Balkans_zone_6",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI1901Balkanszone8",
    `PROJCS["MGI_1901_Balkans_zone_8",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRBETRS89UTMzone34N",
    `PROJCS["SRB_ETRS89_UTM_zone_34N",GEOGCS["SRB_ETRS89",DATUM["Serbian_Spatial_Reference_System_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI1901SloveniaGrid",
    `PROJCS["MGI_1901_Slovenia_Grid",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Slovenia1996UTMZone33N",
    `PROJCS["Slovenia_1996_UTM_Zone_33N",GEOGCS["GCS_Slovenia_1996",DATUM["D_Slovenia_Geodetic_Datum_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MA11UTMZone54N",
    `PROJCS["NAD_1983_MA11_UTM_Zone_54N",GEOGCS["GCS_NAD_1983_MA11",DATUM["D_NAD_1983_MA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MA11UTMZone55N",
    `PROJCS["NAD_1983_MA11_UTM_Zone_55N",GEOGCS["GCS_NAD_1983_MA11",DATUM["D_NAD_1983_MA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983IdahoTM",
    `PROJCS["NAD_1983_Idaho_TM",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "MTRF-2000UTMzone36N",
    `PROJCS["MTRF-2000_UTM_zone_36N",GEOGCS["MTRF-2000",DATUM["MOMRA_Terrestrial_Reference_Frame_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MTRF-2000UTMzone37N",
    `PROJCS["MTRF-2000_UTM_zone_37N",GEOGCS["MTRF-2000",DATUM["MOMRA_Terrestrial_Reference_Frame_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MTRF-2000UTMzone38N",
    `PROJCS["MTRF-2000_UTM_zone_38N",GEOGCS["MTRF-2000",DATUM["MOMRA_Terrestrial_Reference_Frame_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MTRF-2000UTMzone39N",
    `PROJCS["MTRF-2000_UTM_zone_39N",GEOGCS["MTRF-2000",DATUM["MOMRA_Terrestrial_Reference_Frame_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MTRF-2000UTMzone40N",
    `PROJCS["MTRF-2000_UTM_zone_40N",GEOGCS["MTRF-2000",DATUM["MOMRA_Terrestrial_Reference_Frame_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EqualEarthGreenwich",
    `PROJCS["WGS_1984_Equal_Earth_Greenwich",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equal_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EqualEarthAmericas",
    `PROJCS["WGS_1984_Equal_Earth_Americas",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equal_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EqualEarthAsiaPacific",
    `PROJCS["WGS_1984_Equal_Earth_Asia_Pacific",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equal_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",150.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGWF96UTMZone1S",
    `PROJCS["RGWF96_UTM_Zone_1S",GEOGCS["RGWF96",DATUM["Reseau_Geodesique_de_Wallis_et_Futuna_1996",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CR-SIRGASCRTM05",
    `PROJCS["CR-SIRGAS_CRTM05",GEOGCS["CR-SIRGAS",DATUM["CR-SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CR-SIRGASUTMZone16N",
    `PROJCS["CR-SIRGAS_UTM_Zone_16N",GEOGCS["CR-SIRGAS",DATUM["CR-SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CR-SIRGASUTMZone17N",
    `PROJCS["CR-SIRGAS_UTM_Zone_17N",GEOGCS["CR-SIRGAS",DATUM["CR-SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-Chile2010UTMZone18S",
    `PROJCS["SIRGAS-Chile_2010_UTM_Zone_18S",GEOGCS["SIRGAS-Chile_2010",DATUM["SIRGAS-Chile_realization_2_epoch_2010",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-Chile2010UTMZone19S",
    `PROJCS["SIRGAS-Chile_2010_UTM_Zone_19S",GEOGCS["SIRGAS-Chile_2010",DATUM["SIRGAS-Chile_realization_2_epoch_2010",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ISN2016LAEAEurope",
    `PROJCS["ISN2016_LAEA_Europe",GEOGCS["ISN2016",DATUM["Islands_Net_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",4321000.0],PARAMETER["False_Northing",3210000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "ISN2016LCCEurope",
    `PROJCS["ISN2016_LCC_Europe",GEOGCS["ISN2016",DATUM["Islands_Net_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",2800000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",35.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "KOSOVAREF01BalkansZone7",
    `PROJCS["KOSOVAREF01_Balkans_Zone_7",GEOGCS["KOSOVAREF01",DATUM["Kosovo_Reference_System_2001",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-Chile2013UTMZone18S",
    `PROJCS["SIRGAS-Chile_2013_UTM_Zone_18S",GEOGCS["SIRGAS-Chile_2013",DATUM["SIRGAS-Chile_realization_3_epoch_2013",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-Chile2013UTMZone19S",
    `PROJCS["SIRGAS-Chile_2013_UTM_Zone_19S",GEOGCS["SIRGAS-Chile_2013",DATUM["SIRGAS-Chile_realization_3_epoch_2013",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-Chile2016UTMZone18S",
    `PROJCS["SIRGAS-Chile_2016_UTM_Zone_18S",GEOGCS["SIRGAS-Chile_2016",DATUM["SIRGAS-Chile_realization_4_epoch_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-Chile2016UTMZone19S",
    `PROJCS["SIRGAS-Chile_2016_UTM_Zone_19S",GEOGCS["SIRGAS-Chile_2016",DATUM["SIRGAS-Chile_realization_4_epoch_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RSAO13UTMZone32S",
    `PROJCS["RSAO13_UTM_Zone_32S",GEOGCS["RSAO13",DATUM["Reference_System_de_Angola_2013",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RSAO13UTMZone33S",
    `PROJCS["RSAO13_UTM_Zone_33S",GEOGCS["RSAO13",DATUM["Reference_System_de_Angola_2013",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RSAO13UTMZone34S",
    `PROJCS["RSAO13_UTM_Zone_34S",GEOGCS["RSAO13",DATUM["Reference_System_de_Angola_2013",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RSAO13TM12SE",
    `PROJCS["RSAO13_TM_12_SE",GEOGCS["RSAO13",DATUM["Reference_System_de_Angola_2013",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984NIWAAlbers",
    `PROJCS["WGS_1984_NIWA_Albers",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",175.0],PARAMETER["Standard_Parallel_1",-30.0],PARAMETER["Standard_Parallel_2",-50.0],PARAMETER["Latitude_Of_Origin",-40.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3103-00",
    `PROJCS["VN-2000_TM-3_103-00",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",103.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3104-00",
    `PROJCS["VN-2000_TM-3_104-00",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",104.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3104-30",
    `PROJCS["VN-2000_TM-3_104-30",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",104.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3104-45",
    `PROJCS["VN-2000_TM-3_104-45",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",104.75],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3105-30",
    `PROJCS["VN-2000_TM-3_105-30",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3105-45",
    `PROJCS["VN-2000_TM-3_105-45",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.75],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3106-00",
    `PROJCS["VN-2000_TM-3_106-00",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",106.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3106-15",
    `PROJCS["VN-2000_TM-3_106-15",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3106-30",
    `PROJCS["VN-2000_TM-3_106-30",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",106.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3107-00",
    `PROJCS["VN-2000_TM-3_107-00",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",107.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3107-15",
    `PROJCS["VN-2000_TM-3_107-15",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",107.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3107-30",
    `PROJCS["VN-2000_TM-3_107-30",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",107.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3108-15",
    `PROJCS["VN-2000_TM-3_108-15",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "VN-2000TM-3108-30",
    `PROJCS["VN-2000_TM-3_108-30",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94ZAFBSUAlbers25E",
    `PROJCS["Hartebeesthoek94_ZAF_BSU_Albers_25E",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1400000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Standard_Parallel_1",-22.0],PARAMETER["Standard_Parallel_2",-38.0],PARAMETER["Latitude_Of_Origin",-30.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94ZAFBSUAlbers44E",
    `PROJCS["Hartebeesthoek94_ZAF_BSU_Albers_44E",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1200000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",44.0],PARAMETER["Standard_Parallel_1",-34.0],PARAMETER["Standard_Parallel_2",-50.0],PARAMETER["Latitude_Of_Origin",-42.0],UNIT["Meter",1.0]]`
  ],
  [
    "TapiAikeArgentina1",
    `PROJCS["Tapi_Aike_Argentina_1",GEOGCS["Tapi_Aike",DATUM["Tapi_Aike",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "TapiAikeArgentina2",
    `PROJCS["Tapi_Aike_Argentina_2",GEOGCS["Tapi_Aike",DATUM["Tapi_Aike",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "MMNArgentina2",
    `PROJCS["MMN_Argentina_2",GEOGCS["MMN",DATUM["Ministerio_de_Marina_Norte",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "MMSArgentina2",
    `PROJCS["MMS_Argentina_2",GEOGCS["MMS",DATUM["Ministerio_de_Marina_Sur",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR2007UTMzone19S",
    `PROJCS["POSGAR_2007_UTM_zone_19S",GEOGCS["GCS_POSGAR_2007",DATUM["D_POSGAR_2007",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaWest",
    `PROJCS["MGI_Austria_West",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaCentral",
    `PROJCS["MGI_Austria_Central",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",13.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaEast",
    `PROJCS["MGI_Austria_East",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",16.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PampadelCastilloArgentina1",
    `PROJCS["Pampa_del_Castillo_Argentina_1",GEOGCS["GCS_Pampa_del_Castillo",DATUM["D_Pampa_del_Castillo",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "PampadelCastilloArgentina3",
    `PROJCS["Pampa_del_Castillo_Argentina_3",GEOGCS["GCS_Pampa_del_Castillo",DATUM["D_Pampa_del_Castillo",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "ONGD17UTMZone39N",
    `PROJCS["ONGD17_UTM_Zone_39N",GEOGCS["ONGD17",DATUM["Oman_National_Geodetic_Datum_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ONGD17UTMZone40N",
    `PROJCS["ONGD17_UTM_Zone_40N",GEOGCS["ONGD17",DATUM["Oman_National_Geodetic_Datum_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ONGD17UTMZone41N",
    `PROJCS["ONGD17_UTM_Zone_41N",GEOGCS["ONGD17",DATUM["Oman_National_Geodetic_Datum_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "HS2SurveyGrid",
    `PROJCS["HS2_Survey_Grid",GEOGCS["HS2-IRF",DATUM["HS2_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",198873.0046],PARAMETER["False_Northing",375064.3871],PARAMETER["Central_Meridian",-1.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",52.3],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984IBCSOPolarStereographic",
    `PROJCS["WGS_1984_IBCSO_Polar_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polar_Stereographic_Variant_B"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Longitude_Of_Origin",0.0],PARAMETER["Standard_Parallel_1",-65.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "KSA-GRF17UTMzone36N",
    `PROJCS["KSA-GRF17_UTM_zone_36N",GEOGCS["KSA-GRF17",DATUM["Kingdom_of_Saudi_Arabia_Geodetic_Reference_Frame_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KSA-GRF17UTMzone37N",
    `PROJCS["KSA-GRF17_UTM_zone_37N",GEOGCS["KSA-GRF17",DATUM["Kingdom_of_Saudi_Arabia_Geodetic_Reference_Frame_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KSA-GRF17UTMzone38N",
    `PROJCS["KSA-GRF17_UTM_zone_38N",GEOGCS["KSA-GRF17",DATUM["Kingdom_of_Saudi_Arabia_Geodetic_Reference_Frame_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KSA-GRF17UTMzone39N",
    `PROJCS["KSA-GRF17_UTM_zone_39N",GEOGCS["KSA-GRF17",DATUM["Kingdom_of_Saudi_Arabia_Geodetic_Reference_Frame_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KSA-GRF17UTMzone40N",
    `PROJCS["KSA-GRF17_UTM_zone_40N",GEOGCS["KSA-GRF17",DATUM["Kingdom_of_Saudi_Arabia_Geodetic_Reference_Frame_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TPEN11Grid",
    `PROJCS["TPEN11_Grid",GEOGCS["TPEN11-IRF",DATUM["TPEN11_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",203252.175],PARAMETER["False_Northing",407512.765],PARAMETER["Central_Meridian",-2.25],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",53.5],UNIT["Meter",1.0]]`
  ],
  [
    "MML07Grid",
    `PROJCS["MML07_Grid",GEOGCS["MML07-IRF",DATUM["MML07_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",49350.157],PARAMETER["False_Northing",108398.212],PARAMETER["Central_Meridian",-0.85],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",52.45],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNA-SIRGAS2018Origen-Nacional",
    `PROJCS["MAGNA-SIRGAS_2018_Origen-Nacional",GEOGCS["MAGNA-SIRGAS_2018",DATUM["Marco_Geocentrico_Nacional_de_Referencia_2018",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-73.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",4.0],UNIT["Meter",1.0]]`
  ],
  [
    "AbInvA962020Grid",
    `PROJCS["AbInvA96_2020_Grid",GEOGCS["AbInvA96_2020-IRF",DATUM["AbInvA96_2020_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",155828.702],PARAMETER["False_Northing",115225.707],PARAMETER["Central_Meridian",-3.2],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",57.4],UNIT["Meter",1.0]]`
  ],
  [
    "BGS2005UTMzone35N",
    `PROJCS["BGS2005_UTM_zone_35N",GEOGCS["BGS2005",DATUM["Bulgaria_Geodetic_System_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PN68UTMzone27N",
    `PROJCS["PN68_UTM_zone_27N",GEOGCS["PN68",DATUM["Pico_de_las_Nieves_1968",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PN68UTMzone28N",
    `PROJCS["PN68_UTM_zone_28N",GEOGCS["PN68",DATUM["Pico_de_las_Nieves_1968",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PN84UTMzone27N",
    `PROJCS["PN84_UTM_zone_27N",GEOGCS["GCS_Pico_de_Las_Nieves",DATUM["D_Pico_de_Las_Nieves",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PN84UTMzone28N",
    `PROJCS["PN84_UTM_zone_28N",GEOGCS["GCS_Pico_de_Las_Nieves",DATUM["D_Pico_de_Las_Nieves",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GBK19Grid",
    `PROJCS["GBK19_Grid",GEOGCS["GBK19-IRF",DATUM["GBK19_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",93720.394],PARAMETER["False_Northing",113870.493],PARAMETER["Central_Meridian",-4.35],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",55.75],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020AustralianAlbers",
    `PROJCS["GDA2020_Australian_Albers",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Standard_Parallel_1",-18.0],PARAMETER["Standard_Parallel_2",-36.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone46N",
    `PROJCS["SRGI2013_UTM_zone_46N",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone47N",
    `PROJCS["SRGI2013_UTM_zone_47N",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone48N",
    `PROJCS["SRGI2013_UTM_zone_48N",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone49N",
    `PROJCS["SRGI2013_UTM_zone_49N",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone50N",
    `PROJCS["SRGI2013_UTM_zone_50N",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone51N",
    `PROJCS["SRGI2013_UTM_zone_51N",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone52N",
    `PROJCS["SRGI2013_UTM_zone_52N",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone47S",
    `PROJCS["SRGI2013_UTM_zone_47S",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone48S",
    `PROJCS["SRGI2013_UTM_zone_48S",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone49S",
    `PROJCS["SRGI2013_UTM_zone_49S",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone50S",
    `PROJCS["SRGI2013_UTM_zone_50S",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone51S",
    `PROJCS["SRGI2013_UTM_zone_51S",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone52S",
    `PROJCS["SRGI2013_UTM_zone_52S",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone53S",
    `PROJCS["SRGI2013_UTM_zone_53S",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SRGI2013UTMzone54S",
    `PROJCS["SRGI2013_UTM_zone_54S",GEOGCS["SRGI2013",DATUM["Sistem_Referensi_Geospasial_Indonesia_2013",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR2007CABA2019",
    `PROJCS["POSGAR_2007_CABA_2019",GEOGCS["GCS_POSGAR_2007",DATUM["D_POSGAR_2007",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20000.0],PARAMETER["False_Northing",70000.0],PARAMETER["Central_Meridian",-58.46330833333335],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-34.62926666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983USFSR6Albers",
    `PROJCS["NAD_1983_USFS_R6_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",48.0],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Meter",1.0]]`
  ],
  [
    "Gulshan303BangladeshTransverseMercator",
    `PROJCS["Gulshan_303_Bangladesh_Transverse_Mercator",GEOGCS["GCS_Gulshan_303",DATUM["D_Gulshan_303",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-2000000.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS84TM90NE",
    `PROJCS["WGS_84_TM_90_NE",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "REDGEOMINUTMzone12S",
    `PROJCS["REDGEOMIN_UTM_zone_12S",GEOGCS["REDGEOMIN",DATUM["Red_Geodesica_Para_Mineria_en_Chile",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "REDGEOMINUTMzone18S",
    `PROJCS["REDGEOMIN_UTM_zone_18S",GEOGCS["REDGEOMIN",DATUM["Red_Geodesica_Para_Mineria_en_Chile",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "REDGEOMINUTMzone19S",
    `PROJCS["REDGEOMIN_UTM_zone_19S",GEOGCS["REDGEOMIN",DATUM["Red_Geodesica_Para_Mineria_en_Chile",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone23N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_23N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone24N",
    `PROJCS["NAD_1983_UTM_Zone_24N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone24N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_24N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "IGM95UTMZone34N",
    `PROJCS["IGM95_UTM_Zone_34N",GEOGCS["GCS_IGM_1995",DATUM["D_IGM_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "EOS21Grid",
    `PROJCS["EOS21_Grid",GEOGCS["EOS21-IRF",DATUM["EOS21_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",74996.927],PARAMETER["False_Northing",133508.35],PARAMETER["Central_Meridian",-2.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",56.35],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlabamaEast(ftUS)",
    `PROJCS["NAD_1983_2011_StatePlane_Alabama_East_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlabamaWest(ftUS)",
    `PROJCS["NAD_1983_2011_StatePlane_Alabama_West_(ftUS)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",0.999933333],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "ECML14NBGrid",
    `PROJCS["ECML14_NB_Grid",GEOGCS["ECML14_NB-IRF",DATUM["ECML14_NB_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",112242.8512],PARAMETER["False_Northing",402313.7432],PARAMETER["Central_Meridian",-1.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",55.05],UNIT["Meter",1.0]]`
  ],
  [
    "EWR2Grid",
    `PROJCS["EWR2_Grid",GEOGCS["EWR2-IRF",DATUM["EWR2_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",192519.9715],PARAMETER["False_Northing",146942.6806],PARAMETER["Central_Meridian",-0.9],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",51.95],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2Lambert-93",
    `PROJCS["RGF93_v2_Lambert-93",GEOGCS["RGF93_v2",DATUM["Reseau_Geodesique_Francais_1993_v2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",6600000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",44.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2bLambert-93",
    `PROJCS["RGF93_v2b_Lambert-93",GEOGCS["RGF93_v2b",DATUM["Reseau_Geodesique_Francais_1993_v2b",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",6600000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",44.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-32Kyivregion",
    `PROJCS["UCS-2000_LCS-32_Kyiv_region",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2CC42",
    `PROJCS["RGF93_v2_CC42",GEOGCS["RGF93_v2",DATUM["Reseau_Geodesique_Francais_1993_v2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",41.25],PARAMETER["Standard_Parallel_2",42.75],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2CC43",
    `PROJCS["RGF93_v2_CC43",GEOGCS["RGF93_v2",DATUM["Reseau_Geodesique_Francais_1993_v2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",2200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",42.25],PARAMETER["Standard_Parallel_2",43.75],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2CC44",
    `PROJCS["RGF93_v2_CC44",GEOGCS["RGF93_v2",DATUM["Reseau_Geodesique_Francais_1993_v2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",3200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",43.25],PARAMETER["Standard_Parallel_2",44.75],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2CC45",
    `PROJCS["RGF93_v2_CC45",GEOGCS["RGF93_v2",DATUM["Reseau_Geodesique_Francais_1993_v2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",4200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.75],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2CC46",
    `PROJCS["RGF93_v2_CC46",GEOGCS["RGF93_v2",DATUM["Reseau_Geodesique_Francais_1993_v2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",5200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",45.25],PARAMETER["Standard_Parallel_2",46.75],PARAMETER["Latitude_Of_Origin",46.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2CC47",
    `PROJCS["RGF93_v2_CC47",GEOGCS["RGF93_v2",DATUM["Reseau_Geodesique_Francais_1993_v2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",6200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",46.25],PARAMETER["Standard_Parallel_2",47.75],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2CC48",
    `PROJCS["RGF93_v2_CC48",GEOGCS["RGF93_v2",DATUM["Reseau_Geodesique_Francais_1993_v2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",7200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",47.25],PARAMETER["Standard_Parallel_2",48.75],PARAMETER["Latitude_Of_Origin",48.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2CC49",
    `PROJCS["RGF93_v2_CC49",GEOGCS["RGF93_v2",DATUM["Reseau_Geodesique_Francais_1993_v2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",8200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",48.25],PARAMETER["Standard_Parallel_2",49.75],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2CC50",
    `PROJCS["RGF93_v2_CC50",GEOGCS["RGF93_v2",DATUM["Reseau_Geodesique_Francais_1993_v2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",9200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",49.25],PARAMETER["Standard_Parallel_2",50.75],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-01Crimea",
    `PROJCS["UCS-2000_LCS-01_Crimea",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",34.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-05Vinnytsia",
    `PROJCS["UCS-2000_LCS-05_Vinnytsia",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",28.66666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-07Volyn",
    `PROJCS["UCS-2000_LCS-07_Volyn",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.83333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-12Dnipropetrovsk",
    `PROJCS["UCS-2000_LCS-12_Dnipropetrovsk",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",35.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-14Donetsk",
    `PROJCS["UCS-2000_LCS-14_Donetsk",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",37.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-18Zhytomyr",
    `PROJCS["UCS-2000_LCS-18_Zhytomyr",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",28.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-21Zakarpattia",
    `PROJCS["UCS-2000_LCS-21_Zakarpattia",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",23.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-23Zaporizhzhia",
    `PROJCS["UCS-2000_LCS-23_Zaporizhzhia",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-26Ivano-Frankivsk",
    `PROJCS["UCS-2000_LCS-26_Ivano-Frankivsk",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-35Kirovohrad",
    `PROJCS["UCS-2000_LCS-35_Kirovohrad",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",32.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-44Luhansk",
    `PROJCS["UCS-2000_LCS-44_Luhansk",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2bCC42",
    `PROJCS["RGF93_v2b_CC42",GEOGCS["RGF93_v2b",DATUM["Reseau_Geodesique_Francais_1993_v2b",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",41.25],PARAMETER["Standard_Parallel_2",42.75],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2bCC43",
    `PROJCS["RGF93_v2b_CC43",GEOGCS["RGF93_v2b",DATUM["Reseau_Geodesique_Francais_1993_v2b",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",2200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",42.25],PARAMETER["Standard_Parallel_2",43.75],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2bCC44",
    `PROJCS["RGF93_v2b_CC44",GEOGCS["RGF93_v2b",DATUM["Reseau_Geodesique_Francais_1993_v2b",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",3200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",43.25],PARAMETER["Standard_Parallel_2",44.75],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2bCC45",
    `PROJCS["RGF93_v2b_CC45",GEOGCS["RGF93_v2b",DATUM["Reseau_Geodesique_Francais_1993_v2b",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",4200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.75],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2bCC46",
    `PROJCS["RGF93_v2b_CC46",GEOGCS["RGF93_v2b",DATUM["Reseau_Geodesique_Francais_1993_v2b",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",5200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",45.25],PARAMETER["Standard_Parallel_2",46.75],PARAMETER["Latitude_Of_Origin",46.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2bCC47",
    `PROJCS["RGF93_v2b_CC47",GEOGCS["RGF93_v2b",DATUM["Reseau_Geodesique_Francais_1993_v2b",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",6200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",46.25],PARAMETER["Standard_Parallel_2",47.75],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2bCC48",
    `PROJCS["RGF93_v2b_CC48",GEOGCS["RGF93_v2b",DATUM["Reseau_Geodesique_Francais_1993_v2b",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",7200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",47.25],PARAMETER["Standard_Parallel_2",48.75],PARAMETER["Latitude_Of_Origin",48.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2bCC49",
    `PROJCS["RGF93_v2b_CC49",GEOGCS["RGF93_v2b",DATUM["Reseau_Geodesique_Francais_1993_v2b",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",8200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",48.25],PARAMETER["Standard_Parallel_2",49.75],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF93v2bCC50",
    `PROJCS["RGF93_v2b_CC50",GEOGCS["RGF93_v2b",DATUM["Reseau_Geodesique_Francais_1993_v2b",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",9200000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",49.25],PARAMETER["Standard_Parallel_2",50.75],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-46Lviv",
    `PROJCS["UCS-2000_LCS-46_Lviv",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-48Mykolaiv",
    `PROJCS["UCS-2000_LCS-48_Mykolaiv",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",31.83333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-51Odessa",
    `PROJCS["UCS-2000_LCS-51_Odessa",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-53Poltava",
    `PROJCS["UCS-2000_LCS-53_Poltava",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.83333333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-56Rivne",
    `PROJCS["UCS-2000_LCS-56_Rivne",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-59Sumy",
    `PROJCS["UCS-2000_LCS-59_Sumy",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",34.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-61Ternopil",
    `PROJCS["UCS-2000_LCS-61_Ternopil",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-63Kharkiv",
    `PROJCS["UCS-2000_LCS-63_Kharkiv",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-65Kherson",
    `PROJCS["UCS-2000_LCS-65_Kherson",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-68Khmelnytsky",
    `PROJCS["UCS-2000_LCS-68_Khmelnytsky",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-71Cherkasy",
    `PROJCS["UCS-2000_LCS-71_Cherkasy",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",31.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-73Chernivtsi",
    `PROJCS["UCS-2000_LCS-73_Chernivtsi",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",26.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-74Chernihiv",
    `PROJCS["UCS-2000_LCS-74_Chernihiv",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",32.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-80Kyivcity",
    `PROJCS["UCS-2000_LCS-80_Kyiv_city",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UCS-2000LCS-85Sevastopol",
    `PROJCS["UCS-2000_LCS-85_Sevastopol",GEOGCS["GCS_Ukraine_2000",DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MRH21Grid",
    `PROJCS["MRH21_Grid",GEOGCS["MRH21-IRF",DATUM["MRH21_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",227286.9881],PARAMETER["False_Northing",265751.2874],PARAMETER["Central_Meridian",-1.8],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",52.3],UNIT["Meter",1.0]]`
  ],
  [
    "PNG94PNGMG94Zone57",
    `PROJCS["PNG94_PNGMG94_Zone_57",GEOGCS["GCS_PNG94",DATUM["D_Papua_New_Guinea_Geodetic_Datum_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PNG94PNGMG94Zone58",
    `PROJCS["PNG94_PNGMG94_Zone_58",GEOGCS["GCS_PNG94",DATUM["D_Papua_New_Guinea_Geodetic_Datum_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MOLDOR11Grid",
    `PROJCS["MOLDOR11_Grid",GEOGCS["MOLDOR11-IRF",DATUM["MOLDOR11_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",226574.2032],PARAMETER["False_Northing",390894.838],PARAMETER["Central_Meridian",-1.9],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",53.35],UNIT["Meter",1.0]]`
  ],
  [
    "LUREFLuxembourgTM(3D)",
    `PROJCS["LUREF_Luxembourg_TM_(3D)",GEOGCS["LUREF_3D",DATUM["D_Luxembourg_Reference_Frame",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433],LINUNIT["Meter",1.0]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",6.166666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",49.83333333333334],UNIT["Meter",1.0],LINUNIT["Meter",1.0]]`
  ],
  [
    "EBBWV14Grid",
    `PROJCS["EBBWV14_Grid",GEOGCS["EBBWV14-IRF",DATUM["EBBWV14_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",106702.326],PARAMETER["False_Northing",119968.1395],PARAMETER["Central_Meridian",-3.1],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",51.75],UNIT["Meter",1.0]]`
  ],
  [
    "MacedoniaStateCoordinateSystemtruncated",
    `PROJCS["Macedonia_State_Coordinate_System_truncated",GEOGCS["GCS_MGI_1901",DATUM["D_MGI_1901",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ISN2004LAEAIceland",
    `PROJCS["ISN2004_LAEA_Iceland",GEOGCS["GCS_ISN_2004",DATUM["D_Islands_Network_2004",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",-19.0],PARAMETER["Latitude_Of_Origin",65.0],UNIT["Meter",1.0]]`
  ],
  [
    "HULLEE13Grid",
    `PROJCS["HULLEE13_Grid",GEOGCS["HULLEE13-IRF",DATUM["HULLEE13_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",140859.7394],PARAMETER["False_Northing",247512.2812],PARAMETER["Central_Meridian",-0.95],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",53.75],UNIT["Meter",1.0]]`
  ],
  [
    "SCM22Grid",
    `PROJCS["SCM22_Grid",GEOGCS["SCM22-IRF",DATUM["SCM22_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",108600.972],PARAMETER["False_Northing",239087.349],PARAMETER["Central_Meridian",-3.85],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",56.6],UNIT["Meter",1.0]]`
  ],
  [
    "FNL22Grid",
    `PROJCS["FNL22_Grid",GEOGCS["FNL22-IRF",DATUM["FNL22_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",139618.9493],PARAMETER["False_Northing",183110.794],PARAMETER["Central_Meridian",-3.8],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "S34Jreconstructioneast-orientated",
    `PROJCS["S34J_reconstruction_east-orientated",GEOGCS["S34J-IRF",DATUM["System_34_Jylland_Intermediate_Datum",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-210327.0],PARAMETER["False_Northing",-6034310.0],PARAMETER["Central_Meridian",10.37],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DoPw22Grid",
    `PROJCS["DoPw22_Grid",GEOGCS["DoPw22-IRF",DATUM["DoPw22_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",64859.6557],PARAMETER["False_Northing",122266.5277],PARAMETER["Central_Meridian",-4.15],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",52.7],UNIT["Meter",1.0]]`
  ],
  [
    "ShAb07Grid",
    `PROJCS["ShAb07_Grid",GEOGCS["ShAb07-IRF",DATUM["ShAb07_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",56023.5377],PARAMETER["False_Northing",24567.6764],PARAMETER["Central_Meridian",-3.35],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",52.6],UNIT["Meter",1.0]]`
  ],
  [
    "CNH22Grid",
    `PROJCS["CNH22_Grid",GEOGCS["CNH22-IRF",DATUM["CNH22_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",212548.8756],PARAMETER["False_Northing",495230.9254],PARAMETER["Central_Meridian",-3.5],PARAMETER["Standard_Parallel_1",53.1],PARAMETER["Standard_Parallel_2",53.4],PARAMETER["Latitude_Of_Origin",53.25],UNIT["Meter",1.0]]`
  ],
  [
    "CWS13Grid",
    `PROJCS["CWS13_Grid",GEOGCS["CWS13-IRF",DATUM["CWS13_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",199668.0926],PARAMETER["False_Northing",89354.3229],PARAMETER["Central_Meridian",-2.9],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",52.4],UNIT["Meter",1.0]]`
  ],
  [
    "DIBA15Grid",
    `PROJCS["DIBA15_Grid",GEOGCS["DIBA15-IRF",DATUM["DIBA15_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",511622.854],PARAMETER["False_Northing",324737.4633],PARAMETER["Central_Meridian",-1.3],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",51.85],UNIT["Meter",1.0]]`
  ],
  [
    "GWPBS22Grid",
    `PROJCS["GWPBS22_Grid",GEOGCS["GWPBS22-IRF",DATUM["GWPBS22_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",168854.016],PARAMETER["False_Northing",193447.117],PARAMETER["Central_Meridian",-2.65],PARAMETER["Standard_Parallel_1",51.4],PARAMETER["Standard_Parallel_2",51.9],PARAMETER["Latitude_Of_Origin",51.65],UNIT["Meter",1.0]]`
  ],
  [
    "GWWAB22Grid",
    `PROJCS["GWWAB22_Grid",GEOGCS["GWWAB22-IRF",DATUM["GWWAB22_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",168854.016],PARAMETER["False_Northing",193447.117],PARAMETER["Central_Meridian",-2.65],PARAMETER["Standard_Parallel_1",51.4],PARAMETER["Standard_Parallel_2",51.9],PARAMETER["Latitude_Of_Origin",51.65],UNIT["Meter",1.0]]`
  ],
  [
    "GWWWA22Grid",
    `PROJCS["GWWWA22_Grid",GEOGCS["GWWWA22-IRF",DATUM["GWWWA22_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",168854.016],PARAMETER["False_Northing",193447.117],PARAMETER["Central_Meridian",-2.65],PARAMETER["Standard_Parallel_1",51.4],PARAMETER["Standard_Parallel_2",51.9],PARAMETER["Latitude_Of_Origin",51.65],UNIT["Meter",1.0]]`
  ],
  [
    "MALS09Grid",
    `PROJCS["MALS09_Grid",GEOGCS["MALS09-IRF",DATUM["MALS09_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",175262.1809],PARAMETER["False_Northing",174688.2508],PARAMETER["Central_Meridian",-1.15],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",52.2],UNIT["Meter",1.0]]`
  ],
  [
    "OxWo08Grid",
    `PROJCS["OxWo08_Grid",GEOGCS["OxWo08-IRF",DATUM["OxWo08_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",134791.6965],PARAMETER["False_Northing",121872.5056],PARAMETER["Central_Meridian",-1.7],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",51.95],UNIT["Meter",1.0]]`
  ],
  [
    "SYC20Grid",
    `PROJCS["SYC20_Grid",GEOGCS["SYC20-IRF",DATUM["SYC20_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",110094.4312],PARAMETER["False_Northing",120623.8396],PARAMETER["Central_Meridian",-2.6],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",52.9],UNIT["Meter",1.0]]`
  ],
  [
    "S34Sreconstructioneast-orientated",
    `PROJCS["S34S_reconstruction_east-orientated",GEOGCS["S34S-IRF",DATUM["System_34_Sjaelland_Intermediate_Datum",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-210327.0],PARAMETER["False_Northing",-6034310.0],PARAMETER["Central_Meridian",10.37],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "S45Breconstructioneast-orientated",
    `PROJCS["S45B_reconstruction_east-orientated",GEOGCS["S45B-IRF",DATUM["System_45_Bornholm_Intermediate_Datum",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-50000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",14.88927],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",55.11171],UNIT["Meter",1.0]]`
  ],
  [
    "Ostenfeldreconstruction",
    `PROJCS["Ostenfeld_reconstruction",GEOGCS["Ostenfeld-IRF",DATUM["Ostenfeld_Intermediate_Datum",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.233],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",54.4685],UNIT["Meter",1.0]]`
  ],
  [
    "SMITB20Grid",
    `PROJCS["SMITB20_Grid",GEOGCS["SMITB20-IRF",DATUM["SMITB20_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",110693.666],PARAMETER["False_Northing",102089.2943],PARAMETER["Central_Meridian",-3.85],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",50.75],UNIT["Meter",1.0]]`
  ],
  [
    "RBEPP12Grid",
    `PROJCS["RBEPP12_Grid",GEOGCS["RBEPP12-IRF",DATUM["RBEPP12_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",372382.8292],PARAMETER["False_Northing",217764.7796],PARAMETER["Central_Meridian",-3.25],PARAMETER["Standard_Parallel_1",50.3],PARAMETER["Standard_Parallel_2",51.45],PARAMETER["Latitude_Of_Origin",50.85],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS89DREF9120163-degreeGauss-Krugerzone3",
    `PROJCS["ETRS89_DREF91_2016_3-degree_Gauss-Kruger_zone_3",GEOGCS["ETRS89_DREF91_2016",DATUM["ETRS89_DREF91_Realization_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS89DREF912016UTMzone31N(N-zE)",
    `PROJCS["ETRS89_DREF91_2016_UTM_zone_31N_(N-zE)",GEOGCS["ETRS89_DREF91_2016",DATUM["ETRS89_DREF91_Realization_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS89DREF912016UTMzone31N(zE-N)",
    `PROJCS["ETRS89_DREF91_2016_UTM_zone_31N_(zE-N)",GEOGCS["ETRS89_DREF91_2016",DATUM["ETRS89_DREF91_Realization_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS89DREF912016UTMzone32N(N-zE)",
    `PROJCS["ETRS89_DREF91_2016_UTM_zone_32N_(N-zE)",GEOGCS["ETRS89_DREF91_2016",DATUM["ETRS89_DREF91_Realization_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS89DREF912016UTMzone32N(zE-N)",
    `PROJCS["ETRS89_DREF91_2016_UTM_zone_32N_(zE-N)",GEOGCS["ETRS89_DREF91_2016",DATUM["ETRS89_DREF91_Realization_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS89DREF912016UTMzone33N(N-zE)",
    `PROJCS["ETRS89_DREF91_2016_UTM_zone_33N_(N-zE)",GEOGCS["ETRS89_DREF91_2016",DATUM["ETRS89_DREF91_Realization_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS89DREF912016UTMzone33N(zE-N)",
    `PROJCS["ETRS89_DREF91_2016_UTM_zone_33N_(zE-N)",GEOGCS["ETRS89_DREF91_2016",DATUM["ETRS89_DREF91_Realization_2016",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LKS-2020LatviaTM",
    `PROJCS["LKS-2020_Latvia_TM",GEOGCS["LKS-2020",DATUM["Latvian_coordinate_system_2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-6000000.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGNC15LambertNewCaledonia2015",
    `PROJCS["RGNC15_Lambert_New_Caledonia_2015",GEOGCS["RGNC15",DATUM["Reseau_Geodesique_de_Nouvelle_Caledonie_2015",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2400000.0],PARAMETER["False_Northing",2300000.0],PARAMETER["Central_Meridian",166.0],PARAMETER["Standard_Parallel_1",-20.66666666666667],PARAMETER["Standard_Parallel_2",-22.33333333333333],PARAMETER["Latitude_Of_Origin",-21.5],UNIT["Meter",1.0]]`
  ],
  [
    "RGNC15UTMzone57S",
    `PROJCS["RGNC15_UTM_zone_57S",GEOGCS["RGNC15",DATUM["Reseau_Geodesique_de_Nouvelle_Caledonie_2015",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGNC15UTMzone58S",
    `PROJCS["RGNC15_UTM_zone_58S",GEOGCS["RGNC15",DATUM["Reseau_Geodesique_de_Nouvelle_Caledonie_2015",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGNC15UTMzone59S",
    `PROJCS["RGNC15_UTM_zone_59S",GEOGCS["RGNC15",DATUM["Reseau_Geodesique_de_Nouvelle_Caledonie_2015",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BHETRS89TM",
    `PROJCS["BH_ETRS89_TM",GEOGCS["BH_ETRS89",DATUM["BH_ETRS89",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994ALB94",
    `PROJCS["GDA_1994_ALB94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",117.8833333333333],PARAMETER["Scale_Factor",1.0000044],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994BIO94",
    `PROJCS["GDA_1994_BIO94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",60000.0],PARAMETER["False_Northing",2600000.0],PARAMETER["Central_Meridian",115.25],PARAMETER["Scale_Factor",1.0000022],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994BRO94",
    `PROJCS["GDA_1994_BRO94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2200000.0],PARAMETER["Central_Meridian",122.3333333333333],PARAMETER["Scale_Factor",1.00000298],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994BCG94",
    `PROJCS["GDA_1994_BCG94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3900000.0],PARAMETER["Central_Meridian",115.4333333333333],PARAMETER["Scale_Factor",0.99999592],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994CARN94",
    `PROJCS["GDA_1994_CARN94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2950000.0],PARAMETER["Central_Meridian",113.6666666666667],PARAMETER["Scale_Factor",0.99999796],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994COL94",
    `PROJCS["GDA_1994_COL94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",115.9333333333333],PARAMETER["Scale_Factor",1.000019],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994ESP94",
    `PROJCS["GDA_1994_ESP94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3950000.0],PARAMETER["Central_Meridian",121.8833333333333],PARAMETER["Scale_Factor",1.0000055],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994EXM94",
    `PROJCS["GDA_1994_EXM94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2650000.0],PARAMETER["Central_Meridian",114.0666666666667],PARAMETER["Scale_Factor",1.00000236],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994GCG94",
    `PROJCS["GDA_1994_GCG94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3350000.0],PARAMETER["Central_Meridian",114.5833333333333],PARAMETER["Scale_Factor",1.00000628],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994GOLD94",
    `PROJCS["GDA_1994_GOLD94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",60000.0],PARAMETER["False_Northing",3700000.0],PARAMETER["Central_Meridian",121.5],PARAMETER["Scale_Factor",1.00004949],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994JCG94",
    `PROJCS["GDA_1994_JCG94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3550000.0],PARAMETER["Central_Meridian",114.9833333333333],PARAMETER["Scale_Factor",1.00000314],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994KALB94",
    `PROJCS["GDA_1994_KALB94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",55000.0],PARAMETER["False_Northing",3600000.0],PARAMETER["Central_Meridian",114.3152777777778],PARAMETER["Scale_Factor",1.000014],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994KAR94",
    `PROJCS["GDA_1994_KAR94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2450000.0],PARAMETER["Central_Meridian",116.9333333333333],PARAMETER["Scale_Factor",0.9999989],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994KUN94",
    `PROJCS["GDA_1994_KUN94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",128.75],PARAMETER["Scale_Factor",1.0000165],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994LCG94",
    `PROJCS["GDA_1994_LCG94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3650000.0],PARAMETER["Central_Meridian",115.3666666666667],PARAMETER["Scale_Factor",1.00000157],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MRCG94",
    `PROJCS["GDA_1994_MRCG94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3950000.0],PARAMETER["Central_Meridian",115.1666666666667],PARAMETER["Scale_Factor",1.0000055],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994PCG94",
    `PROJCS["GDA_1994_PCG94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3800000.0],PARAMETER["Central_Meridian",115.8166666666667],PARAMETER["Scale_Factor",0.99999906],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994PHG94",
    `PROJCS["GDA_1994_PHG94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",2400000.0],PARAMETER["Central_Meridian",118.6],PARAMETER["Scale_Factor",1.00000135],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "COV23Grid",
    `PROJCS["COV23_Grid",GEOGCS["COV23-IRF",DATUM["COV23_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",116887.9989],PARAMETER["False_Northing",102194.9369],PARAMETER["Central_Meridian",-1.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",52.4],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNA-SIRGAS2018ColombiaFarWestzone",
    `PROJCS["MAGNA-SIRGAS_2018_Colombia_Far_West_zone",GEOGCS["MAGNA-SIRGAS_2018",DATUM["Marco_Geocentrico_Nacional_de_Referencia_2018",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-80.07750776944444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.596203222222221],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNA-SIRGAS2018ColombiaWestzone",
    `PROJCS["MAGNA-SIRGAS_2018_Colombia_West_zone",GEOGCS["MAGNA-SIRGAS_2018",DATUM["Marco_Geocentrico_Nacional_de_Referencia_2018",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-77.07750776944444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.596203222222221],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNA-SIRGAS2018ColombiaBogotazone",
    `PROJCS["MAGNA-SIRGAS_2018_Colombia_Bogota_zone",GEOGCS["MAGNA-SIRGAS_2018",DATUM["Marco_Geocentrico_Nacional_de_Referencia_2018",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-74.07750776944444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.596203222222221],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNA-SIRGAS2018ColombiaEastCentralzone",
    `PROJCS["MAGNA-SIRGAS_2018_Colombia_East_Central_zone",GEOGCS["MAGNA-SIRGAS_2018",DATUM["Marco_Geocentrico_Nacional_de_Referencia_2018",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-71.07750776944444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.596203222222221],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNA-SIRGAS2018ColombiaEastzone",
    `PROJCS["MAGNA-SIRGAS_2018_Colombia_East_zone",GEOGCS["MAGNA-SIRGAS_2018",DATUM["Marco_Geocentrico_Nacional_de_Referencia_2018",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-68.07750776944444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.596203222222221],UNIT["Meter",1.0]]`
  ],
  [
    "MWC18Grid",
    `PROJCS["MWC18_Grid",GEOGCS["MWC18-IRF",DATUM["MWC18_Intermediate_Reference_Frame",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",171975.9382],PARAMETER["False_Northing",116744.6938],PARAMETER["Central_Meridian",-2.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",53.35],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone4",
    `PROJCS["Pulkovo_1995_GK_Zone_4",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone5",
    `PROJCS["Pulkovo_1995_GK_Zone_5",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone6",
    `PROJCS["Pulkovo_1995_GK_Zone_6",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone7",
    `PROJCS["Pulkovo_1995_GK_Zone_7",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone8",
    `PROJCS["Pulkovo_1995_GK_Zone_8",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone9",
    `PROJCS["Pulkovo_1995_GK_Zone_9",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone10",
    `PROJCS["Pulkovo_1995_GK_Zone_10",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone11",
    `PROJCS["Pulkovo_1995_GK_Zone_11",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone12",
    `PROJCS["Pulkovo_1995_GK_Zone_12",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone13",
    `PROJCS["Pulkovo_1995_GK_Zone_13",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone14",
    `PROJCS["Pulkovo_1995_GK_Zone_14",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone15",
    `PROJCS["Pulkovo_1995_GK_Zone_15",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone16",
    `PROJCS["Pulkovo_1995_GK_Zone_16",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone17",
    `PROJCS["Pulkovo_1995_GK_Zone_17",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone18",
    `PROJCS["Pulkovo_1995_GK_Zone_18",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone19",
    `PROJCS["Pulkovo_1995_GK_Zone_19",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone20",
    `PROJCS["Pulkovo_1995_GK_Zone_20",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone21",
    `PROJCS["Pulkovo_1995_GK_Zone_21",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone22",
    `PROJCS["Pulkovo_1995_GK_Zone_22",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone23",
    `PROJCS["Pulkovo_1995_GK_Zone_23",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone24",
    `PROJCS["Pulkovo_1995_GK_Zone_24",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone25",
    `PROJCS["Pulkovo_1995_GK_Zone_25",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone26",
    `PROJCS["Pulkovo_1995_GK_Zone_26",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone27",
    `PROJCS["Pulkovo_1995_GK_Zone_27",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone28",
    `PROJCS["Pulkovo_1995_GK_Zone_28",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone29",
    `PROJCS["Pulkovo_1995_GK_Zone_29",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone30",
    `PROJCS["Pulkovo_1995_GK_Zone_30",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone31",
    `PROJCS["Pulkovo_1995_GK_Zone_31",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone32",
    `PROJCS["Pulkovo_1995_GK_Zone_32",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-Chile2021UTMZone12S",
    `PROJCS["SIRGAS-Chile_2021_UTM_Zone_12S",GEOGCS["SIRGAS-Chile_2021",DATUM["SIRGAS-Chile_realization_5_epoch_2021",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA2020BCSG2020",
    `PROJCS["GDA2020_BCSG2020",GEOGCS["GDA2020",DATUM["GDA2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",-28.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SIRGAS-Chile2021UTMZone18S",
    `PROJCS["SIRGAS-Chile_2021_UTM_Zone_18S",GEOGCS["SIRGAS-Chile_2021",DATUM["SIRGAS-Chile_realization_5_epoch_2021",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS-Chile2021UTMZone19S",
    `PROJCS["SIRGAS-Chile_2021_UTM_Zone_19S",GEOGCS["SIRGAS-Chile_2021",DATUM["SIRGAS-Chile_realization_5_epoch_2021",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(2011)AmtrakNECCS21(ft)",
    `PROJCS["NAD_1983_(2011)_Amtrak_NECCS21_(ft)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Center"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Azimuth",58.0],PARAMETER["Longitude_Of_Center",-74.0],PARAMETER["Latitude_Of_Center",40.83333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "Pulkovo1995GKZone2N",
    `PROJCS["Pulkovo_1995_GK_Zone_2N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone3N",
    `PROJCS["Pulkovo_1995_GK_Zone_3N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone4N",
    `PROJCS["Pulkovo_1995_GK_Zone_4N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone5N",
    `PROJCS["Pulkovo_1995_GK_Zone_5N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone6N",
    `PROJCS["Pulkovo_1995_GK_Zone_6N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone7N",
    `PROJCS["Pulkovo_1995_GK_Zone_7N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone8N",
    `PROJCS["Pulkovo_1995_GK_Zone_8N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone9N",
    `PROJCS["Pulkovo_1995_GK_Zone_9N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone10N",
    `PROJCS["Pulkovo_1995_GK_Zone_10N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone11N",
    `PROJCS["Pulkovo_1995_GK_Zone_11N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone12N",
    `PROJCS["Pulkovo_1995_GK_Zone_12N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone13N",
    `PROJCS["Pulkovo_1995_GK_Zone_13N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone14N",
    `PROJCS["Pulkovo_1995_GK_Zone_14N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone15N",
    `PROJCS["Pulkovo_1995_GK_Zone_15N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone16N",
    `PROJCS["Pulkovo_1995_GK_Zone_16N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone17N",
    `PROJCS["Pulkovo_1995_GK_Zone_17N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone18N",
    `PROJCS["Pulkovo_1995_GK_Zone_18N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone19N",
    `PROJCS["Pulkovo_1995_GK_Zone_19N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone20N",
    `PROJCS["Pulkovo_1995_GK_Zone_20N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone21N",
    `PROJCS["Pulkovo_1995_GK_Zone_21N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone22N",
    `PROJCS["Pulkovo_1995_GK_Zone_22N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone23N",
    `PROJCS["Pulkovo_1995_GK_Zone_23N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone24N",
    `PROJCS["Pulkovo_1995_GK_Zone_24N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone25N",
    `PROJCS["Pulkovo_1995_GK_Zone_25N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone26N",
    `PROJCS["Pulkovo_1995_GK_Zone_26N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone27N",
    `PROJCS["Pulkovo_1995_GK_Zone_27N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone28N",
    `PROJCS["Pulkovo_1995_GK_Zone_28N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone29N",
    `PROJCS["Pulkovo_1995_GK_Zone_29N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone30N",
    `PROJCS["Pulkovo_1995_GK_Zone_30N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone31N",
    `PROJCS["Pulkovo_1995_GK_Zone_31N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1995GKZone32N",
    `PROJCS["Pulkovo_1995_GK_Zone_32N",GEOGCS["GCS_Pulkovo_1995",DATUM["D_Pulkovo_1995",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AdindanUTMZone35N",
    `PROJCS["Adindan_UTM_Zone_35N",GEOGCS["GCS_Adindan",DATUM["D_Adindan",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AdindanUTMZone36N",
    `PROJCS["Adindan_UTM_Zone_36N",GEOGCS["GCS_Adindan",DATUM["D_Adindan",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AdindanUTMZone37N",
    `PROJCS["Adindan_UTM_Zone_37N",GEOGCS["GCS_Adindan",DATUM["D_Adindan",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AdindanUTMZone38N",
    `PROJCS["Adindan_UTM_Zone_38N",GEOGCS["GCS_Adindan",DATUM["D_Adindan",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone48",
    `PROJCS["AGD_1966_AMG_Zone_48",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone49",
    `PROJCS["AGD_1966_AMG_Zone_49",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone50",
    `PROJCS["AGD_1966_AMG_Zone_50",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone51",
    `PROJCS["AGD_1966_AMG_Zone_51",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone52",
    `PROJCS["AGD_1966_AMG_Zone_52",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone53",
    `PROJCS["AGD_1966_AMG_Zone_53",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone54",
    `PROJCS["AGD_1966_AMG_Zone_54",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone55",
    `PROJCS["AGD_1966_AMG_Zone_55",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone56",
    `PROJCS["AGD_1966_AMG_Zone_56",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone57",
    `PROJCS["AGD_1966_AMG_Zone_57",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966AMGZone58",
    `PROJCS["AGD_1966_AMG_Zone_58",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone48",
    `PROJCS["AGD_1984_AMG_Zone_48",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone49",
    `PROJCS["AGD_1984_AMG_Zone_49",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone50",
    `PROJCS["AGD_1984_AMG_Zone_50",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone51",
    `PROJCS["AGD_1984_AMG_Zone_51",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone52",
    `PROJCS["AGD_1984_AMG_Zone_52",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone53",
    `PROJCS["AGD_1984_AMG_Zone_53",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone54",
    `PROJCS["AGD_1984_AMG_Zone_54",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone55",
    `PROJCS["AGD_1984_AMG_Zone_55",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone56",
    `PROJCS["AGD_1984_AMG_Zone_56",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone57",
    `PROJCS["AGD_1984_AMG_Zone_57",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1984AMGZone58",
    `PROJCS["AGD_1984_AMG_Zone_58",GEOGCS["GCS_Australian_1984",DATUM["D_Australian_1984",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AinelAbdUTMZone36N",
    `PROJCS["Ain_el_Abd_UTM_Zone_36N",GEOGCS["GCS_Ain_el_Abd_1970",DATUM["D_Ain_el_Abd_1970",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AinelAbdUTMZone37N",
    `PROJCS["Ain_el_Abd_UTM_Zone_37N",GEOGCS["GCS_Ain_el_Abd_1970",DATUM["D_Ain_el_Abd_1970",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AinelAbdUTMZone38N",
    `PROJCS["Ain_el_Abd_UTM_Zone_38N",GEOGCS["GCS_Ain_el_Abd_1970",DATUM["D_Ain_el_Abd_1970",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AinelAbdUTMZone39N",
    `PROJCS["Ain_el_Abd_UTM_Zone_39N",GEOGCS["GCS_Ain_el_Abd_1970",DATUM["D_Ain_el_Abd_1970",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AinelAbdUTMZone40N",
    `PROJCS["Ain_el_Abd_UTM_Zone_40N",GEOGCS["GCS_Ain_el_Abd_1970",DATUM["D_Ain_el_Abd_1970",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BahrainStateGrid",
    `PROJCS["Bahrain_State_Grid",GEOGCS["GCS_Ain_el_Abd_1970",DATUM["D_Ain_el_Abd_1970",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AfgooyeUTMZone38N",
    `PROJCS["Afgooye_UTM_Zone_38N",GEOGCS["GCS_Afgooye",DATUM["D_Afgooye",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AfgooyeUTMZone39N",
    `PROJCS["Afgooye_UTM_Zone_39N",GEOGCS["GCS_Afgooye",DATUM["D_Afgooye",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PortugueseNationalGrid",
    `PROJCS["Portuguese_National_Grid",GEOGCS["GCS_Lisbon_Lisbon",DATUM["D_Lisbon",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Lisbon",-9.131906111111112],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",1.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "LisbonLisbonPortugueseGrid",
    `PROJCS["Lisbon_Lisbon_Portuguese_Grid",GEOGCS["GCS_Lisbon_Lisbon",DATUM["D_Lisbon",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Lisbon",-9.131906111111112],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",1.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "AratuUTMZone22S",
    `PROJCS["Aratu_UTM_Zone_22S",GEOGCS["GCS_Aratu",DATUM["D_Aratu",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AratuUTMZone23S",
    `PROJCS["Aratu_UTM_Zone_23S",GEOGCS["GCS_Aratu",DATUM["D_Aratu",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AratuUTMZone24S",
    `PROJCS["Aratu_UTM_Zone_24S",GEOGCS["GCS_Aratu",DATUM["D_Aratu",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone4",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_4",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone5",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_5",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone6",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_6",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone7",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_7",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone8",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_8",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone9",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_9",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone10",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_10",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone11",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_11",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone12",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_12",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone13",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_13",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone14",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_14",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone15",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_15",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone16",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_16",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone17",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_17",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone18",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_18",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone19",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_19",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone20",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_20",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone21",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_21",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone22",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_22",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone23",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_23",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone24",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_24",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone25",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_25",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone26",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_26",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone27",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_27",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone28",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_28",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone29",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_29",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone30",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_30",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone31",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_31",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-Krugerzone32",
    `PROJCS["GSK-2011_Gauss-Kruger_zone_32",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Arc1950UTMZone34S",
    `PROJCS["Arc_1950_UTM_Zone_34S",GEOGCS["GCS_Arc_1950",DATUM["D_Arc_1950",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Arc1950UTMZone35S",
    `PROJCS["Arc_1950_UTM_Zone_35S",GEOGCS["GCS_Arc_1950",DATUM["D_Arc_1950",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Arc1950UTMZone36S",
    `PROJCS["Arc_1950_UTM_Zone_36S",GEOGCS["GCS_Arc_1950",DATUM["D_Arc_1950",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM21E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_21E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM27E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_27E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM33E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_33E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM39E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_39E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM45E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_45E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM51E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_51E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM57E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_57E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM63E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_63E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM69E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_69E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM75E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_75E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM81E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_81E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM87E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_87E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM93E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_93E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM99E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_99E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM105E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_105E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM111E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_111E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM117E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_117E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM123E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_123E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM129E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_129E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM135E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_135E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM141E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_141E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM147E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_147E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM153E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_153E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM159E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_159E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM165E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_165E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM171E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_171E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM177E",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_177E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM177W",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_177W",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011Gauss-KrugerCM171W",
    `PROJCS["GSK-2011_Gauss-Kruger_CM_171W",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Arc1960UTMZone35S",
    `PROJCS["Arc_1960_UTM_Zone_35S",GEOGCS["GCS_Arc_1960",DATUM["D_Arc_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Arc1960UTMZone36S",
    `PROJCS["Arc_1960_UTM_Zone_36S",GEOGCS["GCS_Arc_1960",DATUM["D_Arc_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Arc1960UTMZone37S",
    `PROJCS["Arc_1960_UTM_Zone_37S",GEOGCS["GCS_Arc_1960",DATUM["D_Arc_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Arc1960UTMZone35N",
    `PROJCS["Arc_1960_UTM_Zone_35N",GEOGCS["GCS_Arc_1960",DATUM["D_Arc_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Arc1960UTMZone36N",
    `PROJCS["Arc_1960_UTM_Zone_36N",GEOGCS["GCS_Arc_1960",DATUM["D_Arc_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Arc1960UTMZone37N",
    `PROJCS["Arc_1960_UTM_Zone_37N",GEOGCS["GCS_Arc_1960",DATUM["D_Arc_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BataviaUTMZone48S",
    `PROJCS["Batavia_UTM_Zone_48S",GEOGCS["GCS_Batavia",DATUM["D_Batavia",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BataviaUTMZone49S",
    `PROJCS["Batavia_UTM_Zone_49S",GEOGCS["GCS_Batavia",DATUM["D_Batavia",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BataviaUTMZone50S",
    `PROJCS["Batavia_UTM_Zone_50S",GEOGCS["GCS_Batavia",DATUM["D_Batavia",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone7",
    `PROJCS["GSK-2011_GSK_3GK_zone_7",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone8",
    `PROJCS["GSK-2011_GSK_3GK_zone_8",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",8250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone9",
    `PROJCS["GSK-2011_GSK_3GK_zone_9",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone10",
    `PROJCS["GSK-2011_GSK_3GK_zone_10",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",10250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone11",
    `PROJCS["GSK-2011_GSK_3GK_zone_11",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",11250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone12",
    `PROJCS["GSK-2011_GSK_3GK_zone_12",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",12250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone13",
    `PROJCS["GSK-2011_GSK_3GK_zone_13",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",13250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone14",
    `PROJCS["GSK-2011_GSK_3GK_zone_14",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",14250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone15",
    `PROJCS["GSK-2011_GSK_3GK_zone_15",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",15250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone16",
    `PROJCS["GSK-2011_GSK_3GK_zone_16",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",16250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",48.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone17",
    `PROJCS["GSK-2011_GSK_3GK_zone_17",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",17250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone18",
    `PROJCS["GSK-2011_GSK_3GK_zone_18",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",18250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",54.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone19",
    `PROJCS["GSK-2011_GSK_3GK_zone_19",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",19250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone20",
    `PROJCS["GSK-2011_GSK_3GK_zone_20",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone21",
    `PROJCS["GSK-2011_GSK_3GK_zone_21",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",21250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone22",
    `PROJCS["GSK-2011_GSK_3GK_zone_22",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",22250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone23",
    `PROJCS["GSK-2011_GSK_3GK_zone_23",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",23250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone24",
    `PROJCS["GSK-2011_GSK_3GK_zone_24",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",24250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone25",
    `PROJCS["GSK-2011_GSK_3GK_zone_25",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",25250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone26",
    `PROJCS["GSK-2011_GSK_3GK_zone_26",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",26250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone27",
    `PROJCS["GSK-2011_GSK_3GK_zone_27",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",27250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone28",
    `PROJCS["GSK-2011_GSK_3GK_zone_28",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",28250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone29",
    `PROJCS["GSK-2011_GSK_3GK_zone_29",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",29250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone30",
    `PROJCS["GSK-2011_GSK_3GK_zone_30",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",30250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone31",
    `PROJCS["GSK-2011_GSK_3GK_zone_31",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone32",
    `PROJCS["GSK-2011_GSK_3GK_zone_32",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone33",
    `PROJCS["GSK-2011_GSK_3GK_zone_33",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",33250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone34",
    `PROJCS["GSK-2011_GSK_3GK_zone_34",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",34250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone35",
    `PROJCS["GSK-2011_GSK_3GK_zone_35",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",35250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone36",
    `PROJCS["GSK-2011_GSK_3GK_zone_36",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",36250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone37",
    `PROJCS["GSK-2011_GSK_3GK_zone_37",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",37250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone38",
    `PROJCS["GSK-2011_GSK_3GK_zone_38",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",38250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone39",
    `PROJCS["GSK-2011_GSK_3GK_zone_39",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",39250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone40",
    `PROJCS["GSK-2011_GSK_3GK_zone_40",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone41",
    `PROJCS["GSK-2011_GSK_3GK_zone_41",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",41250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone42",
    `PROJCS["GSK-2011_GSK_3GK_zone_42",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",42250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone43",
    `PROJCS["GSK-2011_GSK_3GK_zone_43",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",43250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone44",
    `PROJCS["GSK-2011_GSK_3GK_zone_44",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",44250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone45",
    `PROJCS["GSK-2011_GSK_3GK_zone_45",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",45250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone46",
    `PROJCS["GSK-2011_GSK_3GK_zone_46",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",46250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",138.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone47",
    `PROJCS["GSK-2011_GSK_3GK_zone_47",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",47250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone48",
    `PROJCS["GSK-2011_GSK_3GK_zone_48",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",48250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",144.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone49",
    `PROJCS["GSK-2011_GSK_3GK_zone_49",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",49250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone50",
    `PROJCS["GSK-2011_GSK_3GK_zone_50",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",150.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone51",
    `PROJCS["GSK-2011_GSK_3GK_zone_51",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",51250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone52",
    `PROJCS["GSK-2011_GSK_3GK_zone_52",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",52250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",156.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone53",
    `PROJCS["GSK-2011_GSK_3GK_zone_53",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",53250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone54",
    `PROJCS["GSK-2011_GSK_3GK_zone_54",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",162.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone55",
    `PROJCS["GSK-2011_GSK_3GK_zone_55",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",55250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone56",
    `PROJCS["GSK-2011_GSK_3GK_zone_56",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",56250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone57",
    `PROJCS["GSK-2011_GSK_3GK_zone_57",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",57250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone58",
    `PROJCS["GSK-2011_GSK_3GK_zone_58",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",58250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone59",
    `PROJCS["GSK-2011_GSK_3GK_zone_59",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",59250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone60",
    `PROJCS["GSK-2011_GSK_3GK_zone_60",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",60250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone61",
    `PROJCS["GSK-2011_GSK_3GK_zone_61",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",61250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone62",
    `PROJCS["GSK-2011_GSK_3GK_zone_62",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",62250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone63",
    `PROJCS["GSK-2011_GSK_3GK_zone_63",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",63250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKzone64",
    `PROJCS["GSK-2011_GSK_3GK_zone_64",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",64250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Barbados1938BritishWestIndiesGrid",
    `PROJCS["Barbados_1938_British_West_Indies_Grid",GEOGCS["GCS_Barbados_1938",DATUM["D_Barbados_1938",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-62.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Barbados1938BarbadosGrid",
    `PROJCS["Barbados_1938_Barbados_Grid",GEOGCS["GCS_Barbados_1938",DATUM["D_Barbados_1938",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",30000.0],PARAMETER["False_Northing",75000.0],PARAMETER["Central_Meridian",-59.55972222222222],PARAMETER["Scale_Factor",0.9999986],PARAMETER["Latitude_Of_Origin",13.17638888888889],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM21E",
    `PROJCS["GSK-2011_GSK_3GK_CM_21E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM24E",
    `PROJCS["GSK-2011_GSK_3GK_CM_24E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM27E",
    `PROJCS["GSK-2011_GSK_3GK_CM_27E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM30E",
    `PROJCS["GSK-2011_GSK_3GK_CM_30E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM33E",
    `PROJCS["GSK-2011_GSK_3GK_CM_33E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM36E",
    `PROJCS["GSK-2011_GSK_3GK_CM_36E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM39E",
    `PROJCS["GSK-2011_GSK_3GK_CM_39E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM42E",
    `PROJCS["GSK-2011_GSK_3GK_CM_42E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM45E",
    `PROJCS["GSK-2011_GSK_3GK_CM_45E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM48E",
    `PROJCS["GSK-2011_GSK_3GK_CM_48E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",48.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM51E",
    `PROJCS["GSK-2011_GSK_3GK_CM_51E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM54E",
    `PROJCS["GSK-2011_GSK_3GK_CM_54E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",54.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM57E",
    `PROJCS["GSK-2011_GSK_3GK_CM_57E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM60E",
    `PROJCS["GSK-2011_GSK_3GK_CM_60E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM63E",
    `PROJCS["GSK-2011_GSK_3GK_CM_63E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM66E",
    `PROJCS["GSK-2011_GSK_3GK_CM_66E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM69E",
    `PROJCS["GSK-2011_GSK_3GK_CM_69E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM72E",
    `PROJCS["GSK-2011_GSK_3GK_CM_72E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM75E",
    `PROJCS["GSK-2011_GSK_3GK_CM_75E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM78E",
    `PROJCS["GSK-2011_GSK_3GK_CM_78E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",78.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM81E",
    `PROJCS["GSK-2011_GSK_3GK_CM_81E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM84E",
    `PROJCS["GSK-2011_GSK_3GK_CM_84E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM87E",
    `PROJCS["GSK-2011_GSK_3GK_CM_87E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM90E",
    `PROJCS["GSK-2011_GSK_3GK_CM_90E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM93E",
    `PROJCS["GSK-2011_GSK_3GK_CM_93E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM96E",
    `PROJCS["GSK-2011_GSK_3GK_CM_96E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",96.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM99E",
    `PROJCS["GSK-2011_GSK_3GK_CM_99E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM102E",
    `PROJCS["GSK-2011_GSK_3GK_CM_102E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",102.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM105E",
    `PROJCS["GSK-2011_GSK_3GK_CM_105E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM108E",
    `PROJCS["GSK-2011_GSK_3GK_CM_108E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",108.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM111E",
    `PROJCS["GSK-2011_GSK_3GK_CM_111E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM114E",
    `PROJCS["GSK-2011_GSK_3GK_CM_114E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",114.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM117E",
    `PROJCS["GSK-2011_GSK_3GK_CM_117E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM120E",
    `PROJCS["GSK-2011_GSK_3GK_CM_120E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",120.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM123E",
    `PROJCS["GSK-2011_GSK_3GK_CM_123E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM126E",
    `PROJCS["GSK-2011_GSK_3GK_CM_126E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",126.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM129E",
    `PROJCS["GSK-2011_GSK_3GK_CM_129E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM132E",
    `PROJCS["GSK-2011_GSK_3GK_CM_132E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM135E",
    `PROJCS["GSK-2011_GSK_3GK_CM_135E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM138E",
    `PROJCS["GSK-2011_GSK_3GK_CM_138E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",138.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM141E",
    `PROJCS["GSK-2011_GSK_3GK_CM_141E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM144E",
    `PROJCS["GSK-2011_GSK_3GK_CM_144E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",144.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM147E",
    `PROJCS["GSK-2011_GSK_3GK_CM_147E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM150E",
    `PROJCS["GSK-2011_GSK_3GK_CM_150E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",150.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM153E",
    `PROJCS["GSK-2011_GSK_3GK_CM_153E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM156E",
    `PROJCS["GSK-2011_GSK_3GK_CM_156E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",156.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM159E",
    `PROJCS["GSK-2011_GSK_3GK_CM_159E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM162E",
    `PROJCS["GSK-2011_GSK_3GK_CM_162E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",162.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM165E",
    `PROJCS["GSK-2011_GSK_3GK_CM_165E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM168E",
    `PROJCS["GSK-2011_GSK_3GK_CM_168E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM171E",
    `PROJCS["GSK-2011_GSK_3GK_CM_171E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM174E",
    `PROJCS["GSK-2011_GSK_3GK_CM_174E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM177E",
    `PROJCS["GSK-2011_GSK_3GK_CM_177E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM180E",
    `PROJCS["GSK-2011_GSK_3GK_CM_180E",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM177W",
    `PROJCS["GSK-2011_GSK_3GK_CM_177W",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM174W",
    `PROJCS["GSK-2011_GSK_3GK_CM_174W",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-174.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM171W",
    `PROJCS["GSK-2011_GSK_3GK_CM_171W",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GSK-2011GSK3GKCM168W",
    `PROJCS["GSK-2011_GSK_3GK_CM_168W",GEOGCS["GSK-2011",DATUM["Geodezicheskaya_Sistema_Koordinat_2011",SPHEROID["GSK-2011",6378136.5,298.2564151]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-168.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone13",
    `PROJCS["Beijing_1954_GK_Zone_13",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone14",
    `PROJCS["Beijing_1954_GK_Zone_14",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone15",
    `PROJCS["Beijing_1954_GK_Zone_15",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone16",
    `PROJCS["Beijing_1954_GK_Zone_16",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone17",
    `PROJCS["Beijing_1954_GK_Zone_17",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone18",
    `PROJCS["Beijing_1954_GK_Zone_18",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone19",
    `PROJCS["Beijing_1954_GK_Zone_19",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone20",
    `PROJCS["Beijing_1954_GK_Zone_20",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone21",
    `PROJCS["Beijing_1954_GK_Zone_21",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone22",
    `PROJCS["Beijing_1954_GK_Zone_22",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone23",
    `PROJCS["Beijing_1954_GK_Zone_23",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM75E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_75E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM81E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_81E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM87E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_87E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM93E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_93E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM99E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_99E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM105E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_105E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM111E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_111E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM117E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_117E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM123E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_123E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM129E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_129E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GaussKrugerCM135E",
    `PROJCS["Beijing_1954_Gauss_Kruger_CM_135E",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone13N",
    `PROJCS["Beijing_1954_GK_Zone_13N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone14N",
    `PROJCS["Beijing_1954_GK_Zone_14N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone15N",
    `PROJCS["Beijing_1954_GK_Zone_15N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone16N",
    `PROJCS["Beijing_1954_GK_Zone_16N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone17N",
    `PROJCS["Beijing_1954_GK_Zone_17N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone18N",
    `PROJCS["Beijing_1954_GK_Zone_18N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone19N",
    `PROJCS["Beijing_1954_GK_Zone_19N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone20N",
    `PROJCS["Beijing_1954_GK_Zone_20N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone21N",
    `PROJCS["Beijing_1954_GK_Zone_21N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone22N",
    `PROJCS["Beijing_1954_GK_Zone_22N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Beijing1954GKZone23N",
    `PROJCS["Beijing_1954_GK_Zone_23N",GEOGCS["GCS_Beijing_1954",DATUM["D_Beijing_1954",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BelgeLambert1950",
    `PROJCS["Belge_Lambert_1950",GEOGCS["GCS_Belge_1950_Brussels",DATUM["D_Belge_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Brussels",4.367975],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",5400000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",49.83333333333334],PARAMETER["Standard_Parallel_2",51.16666666666666],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "Bern1898BernLV03C",
    `PROJCS["Bern_1898_Bern_LV03C",GEOGCS["GCS_Bern_1898_Bern",DATUM["D_Bern_1898",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Bern",7.439583333333332],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Center"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",90.0],PARAMETER["Longitude_Of_Center",0.0],PARAMETER["Latitude_Of_Center",46.95240555555556],UNIT["Meter",1.0]]`
  ],
  [
    "CH1903LV03",
    `PROJCS["CH1903_LV03",GEOGCS["GCS_CH1903",DATUM["D_CH1903",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Center"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",90.0],PARAMETER["Longitude_Of_Center",7.439583333333333],PARAMETER["Latitude_Of_Center",46.95240555555556],UNIT["Meter",1.0]]`
  ],
  [
    "CH1903LV03C-G",
    `PROJCS["CH1903_LV03C-G",GEOGCS["GCS_CH1903",DATUM["D_CH1903",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Center"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",90.0],PARAMETER["Longitude_Of_Center",7.439583333333333],PARAMETER["Latitude_Of_Center",46.95240555555556],UNIT["Meter",1.0]]`
  ],
  [
    "BogotaUTMZone17N",
    `PROJCS["Bogota_UTM_Zone_17N",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BogotaUTMZone18N",
    `PROJCS["Bogota_UTM_Zone_18N",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ColombiaWestZone",
    `PROJCS["Colombia_West_Zone",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-77.08091666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.599047222222222],UNIT["Meter",1.0]]`
  ],
  [
    "ColombiaBogotaZone",
    `PROJCS["Colombia_Bogota_Zone",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-74.08091666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.599047222222222],UNIT["Meter",1.0]]`
  ],
  [
    "ColombiaEastCentralZone",
    `PROJCS["Colombia_East_Central_Zone",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-71.08091666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.599047222222222],UNIT["Meter",1.0]]`
  ],
  [
    "ColombiaEastZone",
    `PROJCS["Colombia_East_Zone",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-68.08091666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.599047222222222],UNIT["Meter",1.0]]`
  ],
  [
    "ColombiaWestZone",
    `PROJCS["Colombia_West_Zone",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-77.08091666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.599047222222222],UNIT["Meter",1.0]]`
  ],
  [
    "ColombiaBogotaZone",
    `PROJCS["Colombia_Bogota_Zone",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-74.08091666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.599047222222222],UNIT["Meter",1.0]]`
  ],
  [
    "ColombiaEastCentralZone",
    `PROJCS["Colombia_East_Central_Zone",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-71.08091666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.599047222222222],UNIT["Meter",1.0]]`
  ],
  [
    "ColombiaEastZone",
    `PROJCS["Colombia_East_Zone",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-68.08091666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.599047222222222],UNIT["Meter",1.0]]`
  ],
  [
    "CamacupaUTMZone32S",
    `PROJCS["Camacupa_UTM_Zone_32S",GEOGCS["GCS_Camacupa",DATUM["D_Camacupa",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CamacupaUTMZone33S",
    `PROJCS["Camacupa_UTM_Zone_33S",GEOGCS["GCS_Camacupa",DATUM["D_Camacupa",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CamacupaTM1130SE",
    `PROJCS["Camacupa_TM_11_30_SE",GEOGCS["GCS_Camacupa",DATUM["D_Camacupa",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",11.5],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CamacupaTM12SE",
    `PROJCS["Camacupa_TM_12_SE",GEOGCS["GCS_Camacupa",DATUM["D_Camacupa",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1998ArgentinaZone1",
    `PROJCS["POSGAR_1998_Argentina_Zone_1",GEOGCS["GCS_POSGAR_1998",DATUM["D_POSGAR_1998",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1998ArgentinaZone2",
    `PROJCS["POSGAR_1998_Argentina_Zone_2",GEOGCS["GCS_POSGAR_1998",DATUM["D_POSGAR_1998",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1998ArgentinaZone3",
    `PROJCS["POSGAR_1998_Argentina_Zone_3",GEOGCS["GCS_POSGAR_1998",DATUM["D_POSGAR_1998",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1998ArgentinaZone4",
    `PROJCS["POSGAR_1998_Argentina_Zone_4",GEOGCS["GCS_POSGAR_1998",DATUM["D_POSGAR_1998",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1998ArgentinaZone5",
    `PROJCS["POSGAR_1998_Argentina_Zone_5",GEOGCS["GCS_POSGAR_1998",DATUM["D_POSGAR_1998",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1998ArgentinaZone6",
    `PROJCS["POSGAR_1998_Argentina_Zone_6",GEOGCS["GCS_POSGAR_1998",DATUM["D_POSGAR_1998",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1998ArgentinaZone7",
    `PROJCS["POSGAR_1998_Argentina_Zone_7",GEOGCS["GCS_POSGAR_1998",DATUM["D_POSGAR_1998",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-54.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1994ArgentinaZone1",
    `PROJCS["POSGAR_1994_Argentina_Zone_1",GEOGCS["GCS_POSGAR_1994",DATUM["D_POSGAR_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1994ArgentinaZone2",
    `PROJCS["POSGAR_1994_Argentina_Zone_2",GEOGCS["GCS_POSGAR_1994",DATUM["D_POSGAR_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1994ArgentinaZone3",
    `PROJCS["POSGAR_1994_Argentina_Zone_3",GEOGCS["GCS_POSGAR_1994",DATUM["D_POSGAR_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1994ArgentinaZone4",
    `PROJCS["POSGAR_1994_Argentina_Zone_4",GEOGCS["GCS_POSGAR_1994",DATUM["D_POSGAR_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1994ArgentinaZone5",
    `PROJCS["POSGAR_1994_Argentina_Zone_5",GEOGCS["GCS_POSGAR_1994",DATUM["D_POSGAR_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1994ArgentinaZone6",
    `PROJCS["POSGAR_1994_Argentina_Zone_6",GEOGCS["GCS_POSGAR_1994",DATUM["D_POSGAR_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "POSGAR1994ArgentinaZone7",
    `PROJCS["POSGAR_1994_Argentina_Zone_7",GEOGCS["GCS_POSGAR_1994",DATUM["D_POSGAR_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-54.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "ArgentinaZone1",
    `PROJCS["Argentina_Zone_1",GEOGCS["GCS_Campo_Inchauspe",DATUM["D_Campo_Inchauspe",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "ArgentinaZone2",
    `PROJCS["Argentina_Zone_2",GEOGCS["GCS_Campo_Inchauspe",DATUM["D_Campo_Inchauspe",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "ArgentinaZone3",
    `PROJCS["Argentina_Zone_3",GEOGCS["GCS_Campo_Inchauspe",DATUM["D_Campo_Inchauspe",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-66.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "ArgentinaZone4",
    `PROJCS["Argentina_Zone_4",GEOGCS["GCS_Campo_Inchauspe",DATUM["D_Campo_Inchauspe",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "ArgentinaZone5",
    `PROJCS["Argentina_Zone_5",GEOGCS["GCS_Campo_Inchauspe",DATUM["D_Campo_Inchauspe",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "ArgentinaZone6",
    `PROJCS["Argentina_Zone_6",GEOGCS["GCS_Campo_Inchauspe",DATUM["D_Campo_Inchauspe",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "ArgentinaZone7",
    `PROJCS["Argentina_Zone_7",GEOGCS["GCS_Campo_Inchauspe",DATUM["D_Campo_Inchauspe",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-54.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone7N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_7N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone8N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_8N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone9N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_9N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone10N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_10N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone11N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_11N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone12N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_12N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone13N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_13N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone14N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_14N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone15N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_15N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone16N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_16N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone17N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_17N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone18N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_18N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone19N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_19N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone20N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_20N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone21N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_21N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2UTMZone22N",
    `PROJCS["NAD83(CSRS)v2_UTM_Zone_22N",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGSH2020UTMzone29N",
    `PROJCS["RGSH2020_UTM_zone_29N",GEOGCS["RGSH2020",DATUM["Sonatrach_Reference_Frame_2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGSH2020UTMzone30N",
    `PROJCS["RGSH2020_UTM_zone_30N",GEOGCS["RGSH2020",DATUM["Sonatrach_Reference_Frame_2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGSH2020UTMzone31N",
    `PROJCS["RGSH2020_UTM_zone_31N",GEOGCS["RGSH2020",DATUM["Sonatrach_Reference_Frame_2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGSH2020UTMzone32N",
    `PROJCS["RGSH2020_UTM_zone_32N",GEOGCS["RGSH2020",DATUM["Sonatrach_Reference_Frame_2020",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeUTMZone34S",
    `PROJCS["Cape_UTM_Zone_34S",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeUTMZone35S",
    `PROJCS["Cape_UTM_Zone_35S",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeUTMZone36S",
    `PROJCS["Cape_UTM_Zone_36S",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2PEIStereographic",
    `PROJCS["NAD83(CSRS)v2_PEI_Stereographic",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",47.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2NBStereographic",
    `PROJCS["NAD83(CSRS)v2_NB_Stereographic",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",7500000.0],PARAMETER["Central_Meridian",-66.5],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2SCoPQzone3",
    `PROJCS["NAD83(CSRS)v2_SCoPQ_zone_3",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-58.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2SCoPQzone4",
    `PROJCS["NAD83(CSRS)v2_SCoPQ_zone_4",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-61.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2SCoPQzone5",
    `PROJCS["NAD83(CSRS)v2_SCoPQ_zone_5",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-64.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2SCoPQzone6",
    `PROJCS["NAD83(CSRS)v2_SCoPQ_zone_6",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2SCoPQzone7",
    `PROJCS["NAD83(CSRS)v2_SCoPQ_zone_7",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2SCoPQzone8",
    `PROJCS["NAD83(CSRS)v2_SCoPQ_zone_8",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-73.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2SCoPQzone9",
    `PROJCS["NAD83(CSRS)v2_SCoPQ_zone_9",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2SCoPQzone10",
    `PROJCS["NAD83(CSRS)v2_SCoPQ_zone_10",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2Alberta3TMrefmerid111W",
    `PROJCS["NAD83(CSRS)v2_Alberta_3TM_ref_merid_111_W",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2Alberta3TMrefmerid114W",
    `PROJCS["NAD83(CSRS)v2_Alberta_3TM_ref_merid_114_W",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2Alberta3TMrefmerid117W",
    `PROJCS["NAD83(CSRS)v2_Alberta_3TM_ref_merid_117_W",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v2Alberta3TMrefmerid120W",
    `PROJCS["NAD83(CSRS)v2_Alberta_3TM_ref_merid_120_W",GEOGCS["NAD83(CSRS)v2",DATUM["North_American_Datum_of_1983_(CSRS)_version_2",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone7N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_7N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone8N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_8N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone9N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_9N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone10N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_10N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone11N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_11N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone12N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_12N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone13N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_13N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone14N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_14N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone15N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_15N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone16N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_16N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone17N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_17N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone18N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_18N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone19N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_19N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone20N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_20N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone21N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_21N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3UTMZone22N",
    `PROJCS["NAD83(CSRS)v3_UTM_Zone_22N",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CarthageUTMZone32N",
    `PROJCS["Carthage_UTM_Zone_32N",GEOGCS["GCS_Carthage",DATUM["D_Carthage",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMNS1997Zone5",
    `PROJCS["NAD83(CSRS)v3_MTM_NS_1997_Zone_5",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-64.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMNS1997Zone4",
    `PROJCS["NAD83(CSRS)v3_MTM_NS_1997_Zone_4",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-61.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMZone8",
    `PROJCS["NAD83(CSRS)v3_MTM_Zone_8",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-73.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMZone9",
    `PROJCS["NAD83(CSRS)v3_MTM_Zone_9",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMZone10",
    `PROJCS["NAD83(CSRS)v3_MTM_Zone_10",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMZone11",
    `PROJCS["NAD83(CSRS)v3_MTM_Zone_11",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMZone12",
    `PROJCS["NAD83(CSRS)v3_MTM_Zone_12",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMZone13",
    `PROJCS["NAD83(CSRS)v3_MTM_Zone_13",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMZone14",
    `PROJCS["NAD83(CSRS)v3_MTM_Zone_14",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMZone15",
    `PROJCS["NAD83(CSRS)v3_MTM_Zone_15",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMZone16",
    `PROJCS["NAD83(CSRS)v3_MTM_Zone_16",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v3MTMZone17",
    `PROJCS["NAD83(CSRS)v3_MTM_Zone_17",GEOGCS["NAD83(CSRS)v3",DATUM["North_American_Datum_of_1983_(CSRS)_version_3",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NordTunisie",
    `PROJCS["Nord_Tunisie",GEOGCS["GCS_Carthage",DATUM["D_Carthage",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",9.9],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",0.999625544],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "SudTunisie",
    `PROJCS["Sud_Tunisie",GEOGCS["GCS_Carthage",DATUM["D_Carthage",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",9.9],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Scale_Factor",0.999625769],PARAMETER["Latitude_Of_Origin",33.3],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone7N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_7N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone8N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_8N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone9N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_9N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone10N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_10N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone11N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_11N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone12N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_12N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone13N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_13N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone14N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_14N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone15N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_15N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone16N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_16N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone17N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_17N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone18N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_18N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone19N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_19N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone20N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_20N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone21N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_21N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4UTMZone22N",
    `PROJCS["NAD83(CSRS)v4_UTM_Zone_22N",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4Alberta3TMrefmerid111W",
    `PROJCS["NAD83(CSRS)v4_Alberta_3TM_ref_merid_111_W",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4Alberta3TMrefmerid114W",
    `PROJCS["NAD83(CSRS)v4_Alberta_3TM_ref_merid_114_W",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4Alberta3TMrefmerid117W",
    `PROJCS["NAD83(CSRS)v4_Alberta_3TM_ref_merid_117_W",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v4Alberta3TMrefmerid120W",
    `PROJCS["NAD83(CSRS)v4_Alberta_3TM_ref_merid_120_W",GEOGCS["NAD83(CSRS)v4",DATUM["North_American_Datum_of_1983_(CSRS)_version_4",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CorregoAlegreUTMZone21S",
    `PROJCS["Corrego_Alegre_UTM_Zone_21S",GEOGCS["GCS_Corrego_Alegre",DATUM["D_Corrego_Alegre",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CorregoAlegreUTMZone22S",
    `PROJCS["Corrego_Alegre_UTM_Zone_22S",GEOGCS["GCS_Corrego_Alegre",DATUM["D_Corrego_Alegre",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CorregoAlegreUTMZone23S",
    `PROJCS["Corrego_Alegre_UTM_Zone_23S",GEOGCS["GCS_Corrego_Alegre",DATUM["D_Corrego_Alegre",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CorregoAlegreUTMZone24S",
    `PROJCS["Corrego_Alegre_UTM_Zone_24S",GEOGCS["GCS_Corrego_Alegre",DATUM["D_Corrego_Alegre",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CorregoAlegreUTMZone25S",
    `PROJCS["Corrego_Alegre_UTM_Zone_25S",GEOGCS["GCS_Corrego_Alegre",DATUM["D_Corrego_Alegre",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6UTMZone7N",
    `PROJCS["NAD83(CSRS)v6_UTM_Zone_7N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6UTMZone8N",
    `PROJCS["NAD83(CSRS)v6_UTM_Zone_8N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6UTMZone9N",
    `PROJCS["NAD83(CSRS)v6_UTM_Zone_9N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6UTMZone10N",
    `PROJCS["NAD83(CSRS)v6_UTM_Zone_10N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6UTMZone11N",
    `PROJCS["NAD83(CSRS)v6_UTM_Zone_11N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6UTMZone12N",
    `PROJCS["NAD83(CSRS)v6_UTM_Zone_12N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6UTMZone13N",
    `PROJCS["NAD83(CSRS)v6_UTM_Zone_13N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6UTMZone14N",
    `PROJCS["NAD83(CSRS)v6_UTM_Zone_14N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone15N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_15N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone16N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_16N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone17N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_17N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone18N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_18N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone19N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_19N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone20N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_20N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone21N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_21N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6UTMZone22N",
    `PROJCS["NAD83(CSRS)v6_UTM_Zone_22N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6PEIStereographic",
    `PROJCS["NAD83(CSRS)v6_PEI_Stereographic",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",47.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone1",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_1",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-53.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone2",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_2",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-56.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone3",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_3",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-58.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone4",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_4",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-61.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone5",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_5",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-64.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone6",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_6",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone8",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_8",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-73.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone9",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_9",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone10",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_10",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone11",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_11",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone12",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_12",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone13",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_13",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone14",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_14",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone15",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_15",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone16",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_16",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v6MTMZone17",
    `PROJCS["NAD83(CSRS)v6_MTM_Zone_17",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DeirezZorLevantZone",
    `PROJCS["Deir_ez_Zor_Levant_Zone",GEOGCS["GCS_Deir_ez_Zor",DATUM["D_Deir_ez_Zor",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.15],PARAMETER["Scale_Factor",0.9995341],PARAMETER["Latitude_Of_Origin",34.2],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone7N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_7N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone8N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_8N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone9N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_9N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone10N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_10N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone11N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_11N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone12N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_12N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone13N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_13N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone14N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_14N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone15N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_15N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone16N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_16N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone17N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_17N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone18N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_18N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone19N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_19N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone20N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_20N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone21N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_21N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7UTMZone22N",
    `PROJCS["NAD83(CSRS)v7_UTM_Zone_22N",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7PEIStereographic",
    `PROJCS["NAD83(CSRS)v7_PEI_Stereographic",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.999912],PARAMETER["Latitude_Of_Origin",47.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7Alberta3TMrefmerid111W",
    `PROJCS["NAD83(CSRS)v7_Alberta_3TM_ref_merid_111_W",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7Alberta3TMrefmerid114W",
    `PROJCS["NAD83(CSRS)v7_Alberta_3TM_ref_merid_114_W",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7Alberta3TMrefmerid117W",
    `PROJCS["NAD83(CSRS)v7_Alberta_3TM_ref_merid_117_W",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v7Alberta3TMrefmerid120W",
    `PROJCS["NAD83(CSRS)v7_Alberta_3TM_ref_merid_120_W",GEOGCS["NAD83(CSRS)v7",DATUM["North_American_Datum_of_1983_(CSRS)_version_7",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DeirezZorSyriaLambert",
    `PROJCS["Deir_ez_Zor_Syria_Lambert",GEOGCS["GCS_Deir_ez_Zor",DATUM["D_Deir_ez_Zor",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",37.35],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Scale_Factor",0.9996256],PARAMETER["Latitude_Of_Origin",34.65],UNIT["Meter",1.0]]`
  ],
  [
    "DeirezZorLevantStereographic",
    `PROJCS["Deir_ez_Zor_Levant_Stereographic",GEOGCS["GCS_Deir_ez_Zor",DATUM["D_Deir_ez_Zor",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.15],PARAMETER["Scale_Factor",0.9995341],PARAMETER["Latitude_Of_Origin",34.2],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone7N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_7N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone8N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_8N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone9N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_9N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone10N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_10N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone11N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_11N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone12N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_12N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone13N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_13N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone14N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_14N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone15N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_15N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone16N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_16N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone17N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_17N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone18N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_18N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone19N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_19N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone20N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_20N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone21N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_21N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD83(CSRS)v8UTMZone22N",
    `PROJCS["NAD83(CSRS)v8_UTM_Zone_22N",GEOGCS["NAD83(CSRS)v8",DATUM["North_American_Datum_of_1983_(CSRS)_version_8",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DoualaUTMZone32N",
    `PROJCS["Douala_UTM_Zone_32N",GEOGCS["GCS_Douala",DATUM["D_Douala",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "EgyptBlueBelt",
    `PROJCS["Egypt_Blue_Belt",GEOGCS["GCS_Egypt_1907",DATUM["D_Egypt_1907",SPHEROID["Helmert_1906",6378200.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",1100000.0],PARAMETER["Central_Meridian",35.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "EgyptRedBelt",
    `PROJCS["Egypt_Red_Belt",GEOGCS["GCS_Egypt_1907",DATUM["D_Egypt_1907",SPHEROID["Helmert_1906",6378200.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",615000.0],PARAMETER["False_Northing",810000.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "EgyptPurpleBelt",
    `PROJCS["Egypt_Purple_Belt",GEOGCS["GCS_Egypt_1907",DATUM["D_Egypt_1907",SPHEROID["Helmert_1906",6378200.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "EgyptExtendedPurpleBelt",
    `PROJCS["Egypt_Extended_Purple_Belt",GEOGCS["GCS_Egypt_1907",DATUM["D_Egypt_1907",SPHEROID["Helmert_1906",6378200.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone28N",
    `PROJCS["ED_1950_UTM_Zone_28N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone29N",
    `PROJCS["ED_1950_UTM_Zone_29N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone30N",
    `PROJCS["ED_1950_UTM_Zone_30N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone31N",
    `PROJCS["ED_1950_UTM_Zone_31N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone32N",
    `PROJCS["ED_1950_UTM_Zone_32N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone33N",
    `PROJCS["ED_1950_UTM_Zone_33N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone34N",
    `PROJCS["ED_1950_UTM_Zone_34N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone35N",
    `PROJCS["ED_1950_UTM_Zone_35N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone36N",
    `PROJCS["ED_1950_UTM_Zone_36N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone37N",
    `PROJCS["ED_1950_UTM_Zone_37N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950UTMZone38N",
    `PROJCS["ED_1950_UTM_Zone_38N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950TM0N",
    `PROJCS["ED_1950_TM_0_N",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950TM5NE",
    `PROJCS["ED_1950_TM_5_NE",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",5.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "FahudUTMZone39N",
    `PROJCS["Fahud_UTM_Zone_39N",GEOGCS["GCS_Fahud",DATUM["D_Fahud",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "FahudUTMZone40N",
    `PROJCS["Fahud_UTM_Zone_40N",GEOGCS["GCS_Fahud",DATUM["D_Fahud",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(2011)ICSFreeport(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Freeport_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",1804000.0],PARAMETER["False_Northing",755000.0],PARAMETER["Central_Meridian",-89.95],PARAMETER["Scale_Factor",1.000029],PARAMETER["Latitude_Of_Origin",42.2],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSRockford(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Rockford_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2822000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-89.25],PARAMETER["Scale_Factor",1.000029],PARAMETER["Latitude_Of_Origin",41.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSAurora(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Aurora_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3773000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.00003],PARAMETER["Latitude_Of_Origin",41.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSChicago(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Chicago_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4757000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-87.8],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",41.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSMoline(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Moline_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",5741000.0],PARAMETER["False_Northing",755000.0],PARAMETER["Central_Meridian",-90.6],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",41.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSSterling(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Sterling_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",6726000.0],PARAMETER["False_Northing",755000.0],PARAMETER["Central_Meridian",-90.05],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",41.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSOttawa(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Ottawa_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",7743000.0],PARAMETER["False_Northing",755000.0],PARAMETER["Central_Meridian",-89.05],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",41.3],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSJoliet(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Joliet_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",8694000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.000022],PARAMETER["Latitude_Of_Origin",40.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSMonmouth(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Monmouth_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9678000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-90.85],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",40.2],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSGalesburg(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Galesburg_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",230000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-90.1],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",37.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSPeoria(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Peoria_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1378000.0],PARAMETER["False_Northing",622000.0],PARAMETER["Central_Meridian",-89.65],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",37.4],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSEureka(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Eureka_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2756000.0],PARAMETER["False_Northing",427000.0],PARAMETER["Central_Meridian",-89.3],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",37.45],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSBloomington(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Bloomington_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",3773000.0],PARAMETER["False_Northing",1739000.0],PARAMETER["Central_Meridian",-88.85],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSPontiac(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Pontiac_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",4757000.0],PARAMETER["False_Northing",1739000.0],PARAMETER["Central_Meridian",-88.55],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",40.9],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSWatseka(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Watseka_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",5741000.0],PARAMETER["False_Northing",1739000.0],PARAMETER["Central_Meridian",-87.95],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",40.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSQuincy(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Quincy_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6726000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-91.25],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",36.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSMacomb(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Macomb_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7710000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-90.6],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",36.8],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSLincoln(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Lincoln_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",8760000.0],PARAMETER["False_Northing",1739000.0],PARAMETER["Central_Meridian",-89.8],PARAMETER["Scale_Factor",1.000018],PARAMETER["Latitude_Of_Origin",40.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSDecatur(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Decatur_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9678000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-88.8],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",36.3],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSChampaign(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Champaign_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",328000.0],PARAMETER["False_Northing",2822000.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",40.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSJacksonville(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Jacksonville_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",1247000.0],PARAMETER["False_Northing",2822000.0],PARAMETER["Central_Meridian",-90.6],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",39.65],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSSpringfield(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Springfield_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",2329000.0],PARAMETER["False_Northing",2887000.0],PARAMETER["Central_Meridian",-89.65],PARAMETER["Scale_Factor",1.000022],PARAMETER["Latitude_Of_Origin",39.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSCharleston(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Charleston_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",3773000.0],PARAMETER["False_Northing",2756000.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",39.65],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSJerseyville(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Jerseyville_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4757000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",1.000019],PARAMETER["Latitude_Of_Origin",32.85],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSCarlinville(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Carlinville_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",5741000.0],PARAMETER["False_Northing",2756000.0],PARAMETER["Central_Meridian",-90.15],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",39.3],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSTaylorville(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Taylorville_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6726000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-89.4],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",33.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSEffingham(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Effingham_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",7710000.0],PARAMETER["False_Northing",2756000.0],PARAMETER["Central_Meridian",-89.0],PARAMETER["Scale_Factor",1.000019],PARAMETER["Latitude_Of_Origin",38.95],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSRobinson(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Robinson_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",8694000.0],PARAMETER["False_Northing",2756000.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.000017],PARAMETER["Latitude_Of_Origin",39.1],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSBelleville(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Belleville_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9678000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",1.000016],PARAMETER["Latitude_Of_Origin",32.35],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSMountVernon(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Mount_Vernon_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",328000.0],PARAMETER["False_Northing",3773000.0],PARAMETER["Central_Meridian",-89.15],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",38.45],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSOlney(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Olney_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",1247000.0],PARAMETER["False_Northing",3773000.0],PARAMETER["Central_Meridian",-88.15],PARAMETER["Scale_Factor",1.000013],PARAMETER["Latitude_Of_Origin",38.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSCarbondale(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Carbondale_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",2395000.0],PARAMETER["False_Northing",3773000.0],PARAMETER["Central_Meridian",-88.95],PARAMETER["Scale_Factor",1.000012],PARAMETER["Latitude_Of_Origin",37.9],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSMetropolis(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Metropolis_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",3642000.0],PARAMETER["False_Northing",3839000.0],PARAMETER["Central_Meridian",-88.9],PARAMETER["Scale_Factor",1.00001],PARAMETER["Latitude_Of_Origin",37.2],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "GarouaUTMZone33N",
    `PROJCS["Garoua_UTM_Zone_33N",GEOGCS["GCS_Garoua",DATUM["D_Garoua",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hungarian1972EgysegesOrszagosVetuleti",
    `PROJCS["Hungarian_1972_Egyseges_Orszagos_Vetuleti",GEOGCS["GCS_Hungarian_1972",DATUM["D_Hungarian_1972",SPHEROID["GRS_1967",6378160.0,298.247167427]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Center"],PARAMETER["False_Easting",650000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Scale_Factor",0.99993],PARAMETER["Azimuth",90.0],PARAMETER["Longitude_Of_Center",19.04857177777778],PARAMETER["Latitude_Of_Center",47.14439372222],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone46.2",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_46.2",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",94.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone47.1",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_47.1",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",97.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone47.2",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_47.2",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",100.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone48.1",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_48.1",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",103.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone48.2",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_48.2",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",106.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone49.1",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_49.1",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",109.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone49.2",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_49.2",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",112.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone50.1",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_50.1",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",115.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone50.2",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_50.2",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",118.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone51.1",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_51.1",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",121.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone51.2",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_51.2",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",124.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone52.1",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_52.1",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",127.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone52.2",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_52.2",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",130.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone53.1",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_53.1",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",133.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone53.2",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_53.2",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",136.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995IndonesiaTM-3Zone54.1",
    `PROJCS["DGN_1995_Indonesia_TM-3_Zone_54.1",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1500000.0],PARAMETER["Central_Meridian",139.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone46N",
    `PROJCS["Indonesian_1974_UTM_Zone_46N",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone47N",
    `PROJCS["Indonesian_1974_UTM_Zone_47N",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone48N",
    `PROJCS["Indonesian_1974_UTM_Zone_48N",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone49N",
    `PROJCS["Indonesian_1974_UTM_Zone_49N",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone50N",
    `PROJCS["Indonesian_1974_UTM_Zone_50N",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone51N",
    `PROJCS["Indonesian_1974_UTM_Zone_51N",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone52N",
    `PROJCS["Indonesian_1974_UTM_Zone_52N",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone53N",
    `PROJCS["Indonesian_1974_UTM_Zone_53N",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone46N",
    `PROJCS["DGN_1995_UTM_Zone_46N",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone47N",
    `PROJCS["DGN_1995_UTM_Zone_47N",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone48N",
    `PROJCS["DGN_1995_UTM_Zone_48N",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone49N",
    `PROJCS["DGN_1995_UTM_Zone_49N",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone50N",
    `PROJCS["DGN_1995_UTM_Zone_50N",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone51N",
    `PROJCS["DGN_1995_UTM_Zone_51N",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone52N",
    `PROJCS["DGN_1995_UTM_Zone_52N",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone47S",
    `PROJCS["DGN_1995_UTM_Zone_47S",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone48S",
    `PROJCS["DGN_1995_UTM_Zone_48S",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone49S",
    `PROJCS["DGN_1995_UTM_Zone_49S",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone50S",
    `PROJCS["DGN_1995_UTM_Zone_50S",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone51S",
    `PROJCS["DGN_1995_UTM_Zone_51S",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone52S",
    `PROJCS["DGN_1995_UTM_Zone_52S",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone53S",
    `PROJCS["DGN_1995_UTM_Zone_53S",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DGN1995UTMZone54S",
    `PROJCS["DGN_1995_UTM_Zone_54S",GEOGCS["GCS_DGN_1995",DATUM["D_Datum_Geodesi_Nasional_1995",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone46S",
    `PROJCS["Indonesian_1974_UTM_Zone_46S",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone47S",
    `PROJCS["Indonesian_1974_UTM_Zone_47S",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone48S",
    `PROJCS["Indonesian_1974_UTM_Zone_48S",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone49S",
    `PROJCS["Indonesian_1974_UTM_Zone_49S",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone50S",
    `PROJCS["Indonesian_1974_UTM_Zone_50S",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone51S",
    `PROJCS["Indonesian_1974_UTM_Zone_51S",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone52S",
    `PROJCS["Indonesian_1974_UTM_Zone_52S",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone53S",
    `PROJCS["Indonesian_1974_UTM_Zone_53S",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indonesian1974UTMZone54S",
    `PROJCS["Indonesian_1974_UTM_Zone_54S",GEOGCS["GCS_Indonesian_1974",DATUM["D_Indonesian_1974",SPHEROID["Indonesian",6378160.0,298.247]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indian1954UTMZone46N",
    `PROJCS["Indian_1954_UTM_Zone_46N",GEOGCS["GCS_Indian_1954",DATUM["D_Indian_1954",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indian1954UTMZone47N",
    `PROJCS["Indian_1954_UTM_Zone_47N",GEOGCS["GCS_Indian_1954",DATUM["D_Indian_1954",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indian1954UTMZone48N",
    `PROJCS["Indian_1954_UTM_Zone_48N",GEOGCS["GCS_Indian_1954",DATUM["D_Indian_1954",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indian1975UTMZone47N",
    `PROJCS["Indian_1975_UTM_Zone_47N",GEOGCS["GCS_Indian_1975",DATUM["D_Indian_1975",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Indian1975UTMZone48N",
    `PROJCS["Indian_1975_UTM_Zone_48N",GEOGCS["GCS_Indian_1975",DATUM["D_Indian_1975",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Jamaica1875OldGrid",
    `PROJCS["Jamaica_1875_Old_Grid",GEOGCS["GCS_Jamaica_1875",DATUM["D_Jamaica_1875",SPHEROID["Clarke_1880",6378249.144808011,293.4663076556253]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",550000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Foot_Clarke",0.3047972654]]`
  ],
  [
    "JamaicaGrid",
    `PROJCS["Jamaica_Grid",GEOGCS["GCS_Jamaica_1969",DATUM["D_Jamaica_1969",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",150000.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1937UTMZone45N",
    `PROJCS["Kalianpur_1937_UTM_Zone_45N",GEOGCS["GCS_Kalianpur_1937",DATUM["D_Kalianpur_1937",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1937UTMZone46N",
    `PROJCS["Kalianpur_1937_UTM_Zone_46N",GEOGCS["GCS_Kalianpur_1937",DATUM["D_Kalianpur_1937",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1962UTMZone41N",
    `PROJCS["Kalianpur_1962_UTM_Zone_41N",GEOGCS["GCS_Kalianpur_1962",DATUM["D_Kalianpur_1962",SPHEROID["Everest_Definition_1962",6377301.243,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1962UTMZone42N",
    `PROJCS["Kalianpur_1962_UTM_Zone_42N",GEOGCS["GCS_Kalianpur_1962",DATUM["D_Kalianpur_1962",SPHEROID["Everest_Definition_1962",6377301.243,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1962UTMZone43N",
    `PROJCS["Kalianpur_1962_UTM_Zone_43N",GEOGCS["GCS_Kalianpur_1962",DATUM["D_Kalianpur_1962",SPHEROID["Everest_Definition_1962",6377301.243,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1975UTMZone42N",
    `PROJCS["Kalianpur_1975_UTM_Zone_42N",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1975UTMZone43N",
    `PROJCS["Kalianpur_1975_UTM_Zone_43N",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1975UTMZone44N",
    `PROJCS["Kalianpur_1975_UTM_Zone_44N",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1975UTMZone45N",
    `PROJCS["Kalianpur_1975_UTM_Zone_45N",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1975UTMZone46N",
    `PROJCS["Kalianpur_1975_UTM_Zone_46N",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1975UTMZone47N",
    `PROJCS["Kalianpur_1975_UTM_Zone_47N",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1880IndiaZone0",
    `PROJCS["Kalianpur_1880_India_Zone_0",GEOGCS["GCS_Kalianpur_1880",DATUM["D_Kalianpur_1880",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2355500.0],PARAMETER["False_Northing",2590000.0],PARAMETER["Central_Meridian",68.0],PARAMETER["Standard_Parallel_1",39.5],PARAMETER["Scale_Factor",0.99846154],PARAMETER["Latitude_Of_Origin",39.5],UNIT["Yard_Indian",0.9143985307444408]]`
  ],
  [
    "Kalianpur1880IndiaZoneI",
    `PROJCS["Kalianpur_1880_India_Zone_I",GEOGCS["GCS_Kalianpur_1880",DATUM["D_Kalianpur_1880",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",68.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Yard_Indian",0.9143985307444408]]`
  ],
  [
    "Kalianpur1880IndiaZoneIIa",
    `PROJCS["Kalianpur_1880_India_Zone_IIa",GEOGCS["GCS_Kalianpur_1880",DATUM["D_Kalianpur_1880",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",74.0],PARAMETER["Standard_Parallel_1",26.0],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Yard_Indian",0.9143985307444408]]`
  ],
  [
    "Kalianpur1880IndiaZoneIII",
    `PROJCS["Kalianpur_1880_India_Zone_III",GEOGCS["GCS_Kalianpur_1880",DATUM["D_Kalianpur_1880",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",80.0],PARAMETER["Standard_Parallel_1",19.0],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",19.0],UNIT["Yard_Indian",0.9143985307444408]]`
  ],
  [
    "Kalianpur1880IndiaZoneIV",
    `PROJCS["Kalianpur_1880_India_Zone_IV",GEOGCS["GCS_Kalianpur_1880",DATUM["D_Kalianpur_1880",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",80.0],PARAMETER["Standard_Parallel_1",12.0],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",12.0],UNIT["Yard_Indian",0.9143985307444408]]`
  ],
  [
    "Kalianpur1937IndiaZoneIIb",
    `PROJCS["Kalianpur_1937_India_Zone_IIb",GEOGCS["GCS_Kalianpur_1937",DATUM["D_Kalianpur_1937",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2743185.69],PARAMETER["False_Northing",914395.23],PARAMETER["Central_Meridian",90.0],PARAMETER["Standard_Parallel_1",26.0],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1962IndiaZoneI",
    `PROJCS["Kalianpur_1962_India_Zone_I",GEOGCS["GCS_Kalianpur_1962",DATUM["D_Kalianpur_1962",SPHEROID["Everest_Definition_1962",6377301.243,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2743196.4],PARAMETER["False_Northing",914398.8],PARAMETER["Central_Meridian",68.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1962IndiaZoneIIa",
    `PROJCS["Kalianpur_1962_India_Zone_IIa",GEOGCS["GCS_Kalianpur_1962",DATUM["D_Kalianpur_1962",SPHEROID["Everest_Definition_1962",6377301.243,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2743196.4],PARAMETER["False_Northing",914398.8],PARAMETER["Central_Meridian",74.0],PARAMETER["Standard_Parallel_1",26.0],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1975IndiaZoneI",
    `PROJCS["Kalianpur_1975_India_Zone_I",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2743195.5],PARAMETER["False_Northing",914398.5],PARAMETER["Central_Meridian",68.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1975IndiaZoneIIa",
    `PROJCS["Kalianpur_1975_India_Zone_IIa",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2743195.5],PARAMETER["False_Northing",914398.5],PARAMETER["Central_Meridian",74.0],PARAMETER["Standard_Parallel_1",26.0],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1975IndiaZoneIIb",
    `PROJCS["Kalianpur_1975_India_Zone_IIb",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2743195.5],PARAMETER["False_Northing",914398.5],PARAMETER["Central_Meridian",90.0],PARAMETER["Standard_Parallel_1",26.0],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1975IndiaZoneIII",
    `PROJCS["Kalianpur_1975_India_Zone_III",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2743195.5],PARAMETER["False_Northing",914398.5],PARAMETER["Central_Meridian",80.0],PARAMETER["Standard_Parallel_1",19.0],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",19.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kalianpur1880IndiaZoneIIb",
    `PROJCS["Kalianpur_1880_India_Zone_IIb",GEOGCS["GCS_Kalianpur_1880",DATUM["D_Kalianpur_1880",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Standard_Parallel_1",26.0],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Yard_Indian",0.9143985307444408]]`
  ],
  [
    "Kalianpur1975IndiaZoneIV",
    `PROJCS["Kalianpur_1975_India_Zone_IV",GEOGCS["GCS_Kalianpur_1975",DATUM["D_Kalianpur_1975",SPHEROID["Everest_Definition_1975",6377299.151,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2743195.5],PARAMETER["False_Northing",914398.5],PARAMETER["Central_Meridian",80.0],PARAMETER["Standard_Parallel_1",12.0],PARAMETER["Scale_Factor",0.99878641],PARAMETER["Latitude_Of_Origin",12.0],UNIT["Meter",1.0]]`
  ],
  [
    "KertauSingaporeGrid",
    `PROJCS["Kertau_Singapore_Grid",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",30000.0],PARAMETER["False_Northing",30000.0],PARAMETER["Central_Meridian",103.8530022222222],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",1.287646666666667],UNIT["Meter",1.0]]`
  ],
  [
    "KertauUTMZone47N",
    `PROJCS["Kertau_UTM_Zone_47N",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KertauUTMZone48N",
    `PROJCS["Kertau_UTM_Zone_48N",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KertauRSOMalayaChains",
    `PROJCS["Kertau_RSO_Malaya_Chains",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",-36.97420943711801],PARAMETER["Longitude_Of_Center",102.25],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",-36.86989764584402],UNIT["Chain_Benoit_1895_B",20.11678249437587]]`
  ],
  [
    "KOCLambert",
    `PROJCS["KOC_Lambert",GEOGCS["GCS_Kuwait_Oil_Company",DATUM["D_Kuwait_Oil_Company",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1166200.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Scale_Factor",0.998786407767],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "LaCanoaUTMZone18N",
    `PROJCS["La_Canoa_UTM_Zone_18N",GEOGCS["GCS_La_Canoa",DATUM["D_La_Canoa",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LaCanoaUTMZone19N",
    `PROJCS["La_Canoa_UTM_Zone_19N",GEOGCS["GCS_La_Canoa",DATUM["D_La_Canoa",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LaCanoaUTMZone20N",
    `PROJCS["La_Canoa_UTM_Zone_20N",GEOGCS["GCS_La_Canoa",DATUM["D_La_Canoa",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LaCanoaUTMZone21N",
    `PROJCS["La_Canoa_UTM_Zone_21N",GEOGCS["GCS_La_Canoa",DATUM["D_La_Canoa",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone17N",
    `PROJCS["PSAD_1956_UTM_Zone_17N",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone18N",
    `PROJCS["PSAD_1956_UTM_Zone_18N",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone19N",
    `PROJCS["PSAD_1956_UTM_Zone_19N",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone20N",
    `PROJCS["PSAD_1956_UTM_Zone_20N",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone21N",
    `PROJCS["PSAD_1956_UTM_Zone_21N",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone17S",
    `PROJCS["PSAD_1956_UTM_Zone_17S",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone18S",
    `PROJCS["PSAD_1956_UTM_Zone_18S",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone19S",
    `PROJCS["PSAD_1956_UTM_Zone_19S",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone20S",
    `PROJCS["PSAD_1956_UTM_Zone_20S",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone21S",
    `PROJCS["PSAD_1956_UTM_Zone_21S",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PSAD1956UTMZone22S",
    `PROJCS["PSAD_1956_UTM_Zone_22S",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PeruWestZone",
    `PROJCS["Peru_West_Zone",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",222000.0],PARAMETER["False_Northing",1426834.743],PARAMETER["Central_Meridian",-80.5],PARAMETER["Scale_Factor",0.99983008],PARAMETER["Latitude_Of_Origin",-6.0],UNIT["Meter",1.0]]`
  ],
  [
    "PeruCentralZone",
    `PROJCS["Peru_Central_Zone",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",720000.0],PARAMETER["False_Northing",1039979.159],PARAMETER["Central_Meridian",-76.0],PARAMETER["Scale_Factor",0.99932994],PARAMETER["Latitude_Of_Origin",-9.5],UNIT["Meter",1.0]]`
  ],
  [
    "PeruEastZone",
    `PROJCS["Peru_East_Zone",GEOGCS["GCS_Provisional_S_American_1956",DATUM["D_Provisional_S_American_1956",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1324000.0],PARAMETER["False_Northing",1040084.558],PARAMETER["Central_Meridian",-70.5],PARAMETER["Scale_Factor",0.99952992],PARAMETER["Latitude_Of_Origin",-9.5],UNIT["Meter",1.0]]`
  ],
  [
    "GhanaMetreGrid",
    `PROJCS["Ghana_Metre_Grid",GEOGCS["GCS_Leigon",DATUM["D_Leigon",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",274319.51],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-1.0],PARAMETER["Scale_Factor",0.99975],PARAMETER["Latitude_Of_Origin",4.666666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "LomeUTMZone31N",
    `PROJCS["Lome_UTM_Zone_31N",GEOGCS["GCS_Lome",DATUM["D_Lome",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PhilippinesZoneI",
    `PROJCS["Philippines_Zone_I",GEOGCS["GCS_Luzon_1911",DATUM["D_Luzon_1911",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PhilippinesZoneII",
    `PROJCS["Philippines_Zone_II",GEOGCS["GCS_Luzon_1911",DATUM["D_Luzon_1911",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",119.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PhilippinesZoneIII",
    `PROJCS["Philippines_Zone_III",GEOGCS["GCS_Luzon_1911",DATUM["D_Luzon_1911",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",121.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PhilippinesZoneIV",
    `PROJCS["Philippines_Zone_IV",GEOGCS["GCS_Luzon_1911",DATUM["D_Luzon_1911",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PhilippinesZoneV",
    `PROJCS["Philippines_Zone_V",GEOGCS["GCS_Luzon_1911",DATUM["D_Luzon_1911",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",125.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone28N",
    `PROJCS["ETRS_1989_UTM_Zone_28N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone29N",
    `PROJCS["ETRS_1989_UTM_Zone_29N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone30N",
    `PROJCS["ETRS_1989_UTM_Zone_30N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone31N",
    `PROJCS["ETRS_1989_UTM_Zone_31N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone32N",
    `PROJCS["ETRS_1989_UTM_Zone_32N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone33N",
    `PROJCS["ETRS_1989_UTM_Zone_33N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone34N",
    `PROJCS["ETRS_1989_UTM_Zone_34N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone35N",
    `PROJCS["ETRS_1989_UTM_Zone_35N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone36N",
    `PROJCS["ETRS_1989_UTM_Zone_36N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone37N",
    `PROJCS["ETRS_1989_UTM_Zone_37N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone38N",
    `PROJCS["ETRS_1989_UTM_Zone_38N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989TMBaltic1993",
    `PROJCS["ETRS_1989_TM_Baltic_1993",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Malongo1987UTMZone32S",
    `PROJCS["Malongo_1987_UTM_Zone_32S",GEOGCS["GCS_Malongo_1987",DATUM["D_Malongo_1987",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NordMaroc",
    `PROJCS["Nord_Maroc",GEOGCS["GCS_Merchich",DATUM["D_Merchich",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",-5.4],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Scale_Factor",0.999625769],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Meter",1.0]]`
  ],
  [
    "SudMaroc",
    `PROJCS["Sud_Maroc",GEOGCS["GCS_Merchich",DATUM["D_Merchich",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",-5.4],PARAMETER["Standard_Parallel_1",29.7],PARAMETER["Scale_Factor",0.999615596],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "Sahara",
    `PROJCS["Sahara",GEOGCS["GCS_Merchich",DATUM["D_Merchich",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1200000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-5.4],PARAMETER["Standard_Parallel_1",26.1],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "MerchichSaharaNord",
    `PROJCS["Merchich_Sahara_Nord",GEOGCS["GCS_Merchich",DATUM["D_Merchich",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1200000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-5.4],PARAMETER["Standard_Parallel_1",26.1],PARAMETER["Scale_Factor",0.999616304],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "MerchichSaharaSud",
    `PROJCS["Merchich_Sahara_Sud",GEOGCS["GCS_Merchich",DATUM["D_Merchich",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-5.4],PARAMETER["Standard_Parallel_1",22.5],PARAMETER["Scale_Factor",0.999616437],PARAMETER["Latitude_Of_Origin",25.0],UNIT["Meter",1.0]]`
  ],
  [
    "MassawaUTMZone37N",
    `PROJCS["Massawa_UTM_Zone_37N",GEOGCS["GCS_Massawa",DATUM["D_Massawa",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MinnaUTMZone31N",
    `PROJCS["Minna_UTM_Zone_31N",GEOGCS["GCS_Minna",DATUM["D_Minna",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MinnaUTMZone32N",
    `PROJCS["Minna_UTM_Zone_32N",GEOGCS["GCS_Minna",DATUM["D_Minna",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NigeriaWestBelt",
    `PROJCS["Nigeria_West_Belt",GEOGCS["GCS_Minna",DATUM["D_Minna",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",230738.26],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",4.5],PARAMETER["Scale_Factor",0.99975],PARAMETER["Latitude_Of_Origin",4.0],UNIT["Meter",1.0]]`
  ],
  [
    "NigeriaMidBelt",
    `PROJCS["Nigeria_Mid_Belt",GEOGCS["GCS_Minna",DATUM["D_Minna",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",670553.98],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",8.5],PARAMETER["Scale_Factor",0.99975],PARAMETER["Latitude_Of_Origin",4.0],UNIT["Meter",1.0]]`
  ],
  [
    "NigeriaEastBelt",
    `PROJCS["Nigeria_East_Belt",GEOGCS["GCS_Minna",DATUM["D_Minna",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1110369.7],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.5],PARAMETER["Scale_Factor",0.99975],PARAMETER["Latitude_Of_Origin",4.0],UNIT["Meter",1.0]]`
  ],
  [
    "MhastUTMZone32S",
    `PROJCS["Mhast_UTM_Zone_32S",GEOGCS["GCS_Mhast",DATUM["D_Mhast",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MonteMarioRomeItaly1",
    `PROJCS["Monte_Mario_Rome_Italy_1",GEOGCS["GCS_Monte_Mario_Rome",DATUM["D_Monte_Mario",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Rome",12.45233333333333],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.45233333],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MonteMarioRomeItaly2",
    `PROJCS["Monte_Mario_Rome_Italy_2",GEOGCS["GCS_Monte_Mario_Rome",DATUM["D_Monte_Mario",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Rome",12.45233333333333],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2520000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",2.54766667],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MporalokoUTMZone32N",
    `PROJCS["Mporaloko_UTM_Zone_32N",GEOGCS["GCS_Mporaloko",DATUM["D_Mporaloko",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MporalokoUTMZone32S",
    `PROJCS["Mporaloko_UTM_Zone_32S",GEOGCS["GCS_Mporaloko",DATUM["D_Mporaloko",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone1N",
    `PROJCS["NAD_1927_UTM_Zone_1N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone2N",
    `PROJCS["NAD_1927_UTM_Zone_2N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone3N",
    `PROJCS["NAD_1927_UTM_Zone_3N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone4N",
    `PROJCS["NAD_1927_UTM_Zone_4N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone5N",
    `PROJCS["NAD_1927_UTM_Zone_5N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone6N",
    `PROJCS["NAD_1927_UTM_Zone_6N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone7N",
    `PROJCS["NAD_1927_UTM_Zone_7N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone8N",
    `PROJCS["NAD_1927_UTM_Zone_8N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone9N",
    `PROJCS["NAD_1927_UTM_Zone_9N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone10N",
    `PROJCS["NAD_1927_UTM_Zone_10N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone11N",
    `PROJCS["NAD_1927_UTM_Zone_11N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone12N",
    `PROJCS["NAD_1927_UTM_Zone_12N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone13N",
    `PROJCS["NAD_1927_UTM_Zone_13N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone14N",
    `PROJCS["NAD_1927_UTM_Zone_14N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone15N",
    `PROJCS["NAD_1927_UTM_Zone_15N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone16N",
    `PROJCS["NAD_1927_UTM_Zone_16N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone17N",
    `PROJCS["NAD_1927_UTM_Zone_17N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone18N",
    `PROJCS["NAD_1927_UTM_Zone_18N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone19N",
    `PROJCS["NAD_1927_UTM_Zone_19N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone20N",
    `PROJCS["NAD_1927_UTM_Zone_20N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone21N",
    `PROJCS["NAD_1927_UTM_Zone_21N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone22N",
    `PROJCS["NAD_1927_UTM_Zone_22N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927StatePlaneAlabamaEastFIPS0101",
    `PROJCS["NAD_1927_StatePlane_Alabama_East_FIPS_0101",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlabamaWestFIPS0102",
    `PROJCS["NAD_1927_StatePlane_Alabama_West_FIPS_0102",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlaska1FIPS5001",
    `PROJCS["NAD_1927_StatePlane_Alaska_1_FIPS_5001",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",16404166.666667],PARAMETER["False_Northing",-16404166.666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",-36.86989764583333],PARAMETER["Longitude_Of_Center",-133.6666666666667],PARAMETER["Latitude_Of_Center",57.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlaska2FIPS5002",
    `PROJCS["NAD_1927_StatePlane_Alaska_2_FIPS_5002",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlaska3FIPS5003",
    `PROJCS["NAD_1927_StatePlane_Alaska_3_FIPS_5003",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-146.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlaska4FIPS5004",
    `PROJCS["NAD_1927_StatePlane_Alaska_4_FIPS_5004",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlaska5FIPS5005",
    `PROJCS["NAD_1927_StatePlane_Alaska_5_FIPS_5005",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlaska6FIPS5006",
    `PROJCS["NAD_1927_StatePlane_Alaska_6_FIPS_5006",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlaska7FIPS5007",
    `PROJCS["NAD_1927_StatePlane_Alaska_7_FIPS_5007",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-162.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlaska8FIPS5008",
    `PROJCS["NAD_1927_StatePlane_Alaska_8_FIPS_5008",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-166.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlaska9FIPS5009",
    `PROJCS["NAD_1927_StatePlane_Alaska_9_FIPS_5009",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-170.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneAlaska10FIPS5010",
    `PROJCS["NAD_1927_StatePlane_Alaska_10_FIPS_5010",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-176.0],PARAMETER["Standard_Parallel_1",51.83333333333334],PARAMETER["Standard_Parallel_2",53.83333333333334],PARAMETER["Latitude_Of_Origin",51.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneCaliforniaIFIPS0401",
    `PROJCS["NAD_1927_StatePlane_California_I_FIPS_0401",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneCaliforniaIIFIPS0402",
    `PROJCS["NAD_1927_StatePlane_California_II_FIPS_0402",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneCaliforniaIIIFIPS0403",
    `PROJCS["NAD_1927_StatePlane_California_III_FIPS_0403",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneCaliforniaIVFIPS0404",
    `PROJCS["NAD_1927_StatePlane_California_IV_FIPS_0404",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneCaliforniaVFIPS0405",
    `PROJCS["NAD_1927_StatePlane_California_V_FIPS_0405",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneCaliforniaVIFIPS0406",
    `PROJCS["NAD_1927_StatePlane_California_VI_FIPS_0406",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneCaliforniaVIIFIPS0407",
    `PROJCS["NAD_1927_StatePlane_California_VII_FIPS_0407",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4186692.58],PARAMETER["False_Northing",4160926.74],PARAMETER["Central_Meridian",-118.3333333333333],PARAMETER["Standard_Parallel_1",33.86666666666667],PARAMETER["Standard_Parallel_2",34.41666666666666],PARAMETER["Latitude_Of_Origin",34.13333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneArizonaEastFIPS0201",
    `PROJCS["NAD_1927_StatePlane_Arizona_East_FIPS_0201",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneArizonaCentralFIPS0202",
    `PROJCS["NAD_1927_StatePlane_Arizona_Central_FIPS_0202",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneArizonaWestFIPS0203",
    `PROJCS["NAD_1927_StatePlane_Arizona_West_FIPS_0203",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneArkansasNorthFIPS0301",
    `PROJCS["NAD_1927_StatePlane_Arkansas_North_FIPS_0301",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneArkansasSouthFIPS0302",
    `PROJCS["NAD_1927_StatePlane_Arkansas_South_FIPS_0302",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneColoradoNorthFIPS0501",
    `PROJCS["NAD_1927_StatePlane_Colorado_North_FIPS_0501",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneColoradoCentralFIPS0502",
    `PROJCS["NAD_1927_StatePlane_Colorado_Central_FIPS_0502",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneColoradoSouthFIPS0503",
    `PROJCS["NAD_1927_StatePlane_Colorado_South_FIPS_0503",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneConnecticutFIPS0600",
    `PROJCS["NAD_1927_StatePlane_Connecticut_FIPS_0600",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneDelawareFIPS0700",
    `PROJCS["NAD_1927_StatePlane_Delaware_FIPS_0700",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneFloridaEastFIPS0901",
    `PROJCS["NAD_1927_StatePlane_Florida_East_FIPS_0901",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneFloridaWestFIPS0902",
    `PROJCS["NAD_1927_StatePlane_Florida_West_FIPS_0902",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneFloridaNorthFIPS0903",
    `PROJCS["NAD_1927_StatePlane_Florida_North_FIPS_0903",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneHawaii1FIPS5101",
    `PROJCS["NAD_1927_StatePlane_Hawaii_1_FIPS_5101",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-155.5],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",18.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneHawaii2FIPS5102",
    `PROJCS["NAD_1927_StatePlane_Hawaii_2_FIPS_5102",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-156.6666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",20.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneHawaii3FIPS5103",
    `PROJCS["NAD_1927_StatePlane_Hawaii_3_FIPS_5103",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneHawaii4FIPS5104",
    `PROJCS["NAD_1927_StatePlane_Hawaii_4_FIPS_5104",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.5],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneHawaii5FIPS5105",
    `PROJCS["NAD_1927_StatePlane_Hawaii_5_FIPS_5105",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.1666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",21.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneGeorgiaEastFIPS1001",
    `PROJCS["NAD_1927_StatePlane_Georgia_East_FIPS_1001",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneGeorgiaWestFIPS1002",
    `PROJCS["NAD_1927_StatePlane_Georgia_West_FIPS_1002",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneIdahoEastFIPS1101",
    `PROJCS["NAD_1927_StatePlane_Idaho_East_FIPS_1101",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneIdahoCentralFIPS1102",
    `PROJCS["NAD_1927_StatePlane_Idaho_Central_FIPS_1102",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneIdahoWestFIPS1103",
    `PROJCS["NAD_1927_StatePlane_Idaho_West_FIPS_1103",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneIllinoisEastFIPS1201",
    `PROJCS["NAD_1927_StatePlane_Illinois_East_FIPS_1201",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneIllinoisWestFIPS1202",
    `PROJCS["NAD_1927_StatePlane_Illinois_West_FIPS_1202",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneIndianaEastFIPS1301",
    `PROJCS["NAD_1927_StatePlane_Indiana_East_FIPS_1301",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneIndianaWestFIPS1302",
    `PROJCS["NAD_1927_StatePlane_Indiana_West_FIPS_1302",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneIowaNorthFIPS1401",
    `PROJCS["NAD_1927_StatePlane_Iowa_North_FIPS_1401",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneIowaSouthFIPS1402",
    `PROJCS["NAD_1927_StatePlane_Iowa_South_FIPS_1402",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneKansasNorthFIPS1501",
    `PROJCS["NAD_1927_StatePlane_Kansas_North_FIPS_1501",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneKansasSouthFIPS1502",
    `PROJCS["NAD_1927_StatePlane_Kansas_South_FIPS_1502",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneKentuckyNorthFIPS1601",
    `PROJCS["NAD_1927_StatePlane_Kentucky_North_FIPS_1601",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneKentuckySouthFIPS1602",
    `PROJCS["NAD_1927_StatePlane_Kentucky_South_FIPS_1602",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneLouisianaNorthFIPS1701",
    `PROJCS["NAD_1927_StatePlane_Louisiana_North_FIPS_1701",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneLouisianaSouthFIPS1702",
    `PROJCS["NAD_1927_StatePlane_Louisiana_South_FIPS_1702",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMaineEastFIPS1801",
    `PROJCS["NAD_1927_StatePlane_Maine_East_FIPS_1801",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMaineWestFIPS1802",
    `PROJCS["NAD_1927_StatePlane_Maine_West_FIPS_1802",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMarylandFIPS1900",
    `PROJCS["NAD_1927_StatePlane_Maryland_FIPS_1900",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMassachusettsMainlandFIPS2001",
    `PROJCS["NAD_1927_StatePlane_Massachusetts_Mainland_FIPS_2001",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMassachusettsIslandFIPS2002",
    `PROJCS["NAD_1927_StatePlane_Massachusetts_Island_FIPS_2002",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMichiganNorthFIPS2111",
    `PROJCS["NAD_1927_StatePlane_Michigan_North_FIPS_2111",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMichiganCentralFIPS2112",
    `PROJCS["NAD_1927_StatePlane_Michigan_Central_FIPS_2112",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.33333333333333],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMichiganSouthFIPS2113",
    `PROJCS["NAD_1927_StatePlane_Michigan_South_FIPS_2113",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.33333333333333],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMinnesotaNorthFIPS2201",
    `PROJCS["NAD_1927_StatePlane_Minnesota_North_FIPS_2201",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMinnesotaCentralFIPS2202",
    `PROJCS["NAD_1927_StatePlane_Minnesota_Central_FIPS_2202",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMinnesotaSouthFIPS2203",
    `PROJCS["NAD_1927_StatePlane_Minnesota_South_FIPS_2203",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMississippiEastFIPS2301",
    `PROJCS["NAD_1927_StatePlane_Mississippi_East_FIPS_2301",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMississippiWestFIPS2302",
    `PROJCS["NAD_1927_StatePlane_Mississippi_West_FIPS_2302",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMissouriEastFIPS2401",
    `PROJCS["NAD_1927_StatePlane_Missouri_East_FIPS_2401",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMissouriCentralFIPS2402",
    `PROJCS["NAD_1927_StatePlane_Missouri_Central_FIPS_2402",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMissouriWestFIPS2403",
    `PROJCS["NAD_1927_StatePlane_Missouri_West_FIPS_2403",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.5],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneCaliforniaVIIFIPS0407",
    `PROJCS["NAD_1927_StatePlane_California_VII_FIPS_0407",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4186692.58],PARAMETER["False_Northing",4160926.74],PARAMETER["Central_Meridian",-118.3333333333333],PARAMETER["Standard_Parallel_1",33.86666666666667],PARAMETER["Standard_Parallel_2",34.41666666666666],PARAMETER["Latitude_Of_Origin",34.13333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NADMichiganStatePlaneMichiganEastOldFIPS2101",
    `PROJCS["NAD_Michigan_StatePlane_Michigan_East_Old_FIPS_2101",GEOGCS["GCS_North_American_Michigan",DATUM["D_North_American_Michigan",SPHEROID["Clarke_1866_Michigan",6378450.047,294.978684677]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-83.66666666666667],PARAMETER["Scale_Factor",0.9999428571],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NADMichiganStatePlaneMichiganCentralOldFIPS2102",
    `PROJCS["NAD_Michigan_StatePlane_Michigan_Central_Old_FIPS_2102",GEOGCS["GCS_North_American_Michigan",DATUM["D_North_American_Michigan",SPHEROID["Clarke_1866_Michigan",6378450.047,294.978684677]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Scale_Factor",0.9999090909],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NADMichiganStatePlaneMichiganWestOldFIPS2103",
    `PROJCS["NAD_Michigan_StatePlane_Michigan_West_Old_FIPS_2103",GEOGCS["GCS_North_American_Michigan",DATUM["D_North_American_Michigan",SPHEROID["Clarke_1866_Michigan",6378450.047,294.978684677]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.75],PARAMETER["Scale_Factor",0.9999090909],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NADMichiganStatePlaneMichiganNorthFIPS2111",
    `PROJCS["NAD_Michigan_StatePlane_Michigan_North_FIPS_2111",GEOGCS["GCS_North_American_Michigan",DATUM["D_North_American_Michigan",SPHEROID["Clarke_1866_Michigan",6378450.047,294.978684677]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NADMichiganStatePlaneMichiganCentralFIPS2112",
    `PROJCS["NAD_Michigan_StatePlane_Michigan_Central_FIPS_2112",GEOGCS["GCS_North_American_Michigan",DATUM["D_North_American_Michigan",SPHEROID["Clarke_1866_Michigan",6378450.047,294.978684677]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.33333333333333],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NADMichiganStatePlaneMichiganSouthFIPS2113",
    `PROJCS["NAD_Michigan_StatePlane_Michigan_South_FIPS_2113",GEOGCS["GCS_North_American_Michigan",DATUM["D_North_American_Michigan",SPHEROID["Clarke_1866_Michigan",6378450.047,294.978684677]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.33333333333333],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMaineEastFIPS1801Feet",
    `PROJCS["NAD_1983_StatePlane_Maine_East_FIPS_1801_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMaineWestFIPS1802Feet",
    `PROJCS["NAD_1983_StatePlane_Maine_West_FIPS_1802_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMinnesotaNorthFIPS2201Feet",
    `PROJCS["NAD_1983_StatePlane_Minnesota_North_FIPS_2201_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMinnesotaCentralFIPS2202Feet",
    `PROJCS["NAD_1983_StatePlane_Minnesota_Central_FIPS_2202_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMinnesotaSouthFIPS2203Feet",
    `PROJCS["NAD_1983_StatePlane_Minnesota_South_FIPS_2203_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNebraskaFIPS2600Feet",
    `PROJCS["NAD_1983_StatePlane_Nebraska_FIPS_2600_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWestVirginiaNorthFIPS4701Feet",
    `PROJCS["NAD_1983_StatePlane_West_Virginia_North_FIPS_4701_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWestVirginiaSouthFIPS4702Feet",
    `PROJCS["NAD_1983_StatePlane_West_Virginia_South_FIPS_4702_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMaineEastFIPS1801Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Maine_East_FIPS_1801_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMaineWestFIPS1802Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Maine_West_FIPS_1802_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaNorthFIPS2201Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_North_FIPS_2201_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaCentralFIPS2202Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_Central_FIPS_2202_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaSouthFIPS2203Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_South_FIPS_2203_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneNebraskaFIPS2600Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Nebraska_FIPS_2600_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWestVirginiaNorthFIPS4701Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_West_Virginia_North_FIPS_4701_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneWestVirginiaSouthFIPS4702Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_West_Virginia_South_FIPS_4702_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMaineEastFIPS1801FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Maine_East_FIPS_1801_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMaineWestFIPS1802FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Maine_West_FIPS_1802_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMinnesotaNorthFIPS2201FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Minnesota_North_FIPS_2201_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMinnesotaCentralFIPS2202FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Minnesota_Central_FIPS_2202_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneMinnesotaSouthFIPS2203FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Minnesota_South_FIPS_2203_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneNebraskaFIPS2600FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Nebraska_FIPS_2600_Ft_US",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWestVirginiaNorthFIPS4701FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_West_Virginia_North_FIPS_4701_FtUS",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlaneWestVirginiaSouthFIPS4702FtUS",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_West_Virginia_South_FIPS_4702_FtUS",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CSRSMTM11",
    `PROJCS["NAD_1983_CSRS_MTM_11",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM12",
    `PROJCS["NAD_1983_CSRS_MTM_12",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM13",
    `PROJCS["NAD_1983_CSRS_MTM_13",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM14",
    `PROJCS["NAD_1983_CSRS_MTM_14",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM15",
    `PROJCS["NAD_1983_CSRS_MTM_15",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM16",
    `PROJCS["NAD_1983_CSRS_MTM_16",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM17",
    `PROJCS["NAD_1983_CSRS_MTM_17",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM1",
    `PROJCS["NAD_1983_CSRS_MTM_1",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-53.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSMTM2",
    `PROJCS["NAD_1983_CSRS_MTM_2",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-56.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone1N",
    `PROJCS["NAD_1983_UTM_Zone_1N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone2N",
    `PROJCS["NAD_1983_UTM_Zone_2N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone3N",
    `PROJCS["NAD_1983_UTM_Zone_3N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone4N",
    `PROJCS["NAD_1983_UTM_Zone_4N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone5N",
    `PROJCS["NAD_1983_UTM_Zone_5N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone6N",
    `PROJCS["NAD_1983_UTM_Zone_6N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone7N",
    `PROJCS["NAD_1983_UTM_Zone_7N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone8N",
    `PROJCS["NAD_1983_UTM_Zone_8N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone9N",
    `PROJCS["NAD_1983_UTM_Zone_9N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone10N",
    `PROJCS["NAD_1983_UTM_Zone_10N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone11N",
    `PROJCS["NAD_1983_UTM_Zone_11N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone12N",
    `PROJCS["NAD_1983_UTM_Zone_12N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone13N",
    `PROJCS["NAD_1983_UTM_Zone_13N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone14N",
    `PROJCS["NAD_1983_UTM_Zone_14N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone15N",
    `PROJCS["NAD_1983_UTM_Zone_15N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone16N",
    `PROJCS["NAD_1983_UTM_Zone_16N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone17N",
    `PROJCS["NAD_1983_UTM_Zone_17N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone18N",
    `PROJCS["NAD_1983_UTM_Zone_18N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone19N",
    `PROJCS["NAD_1983_UTM_Zone_19N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone20N",
    `PROJCS["NAD_1983_UTM_Zone_20N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone21N",
    `PROJCS["NAD_1983_UTM_Zone_21N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone22N",
    `PROJCS["NAD_1983_UTM_Zone_22N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone23N",
    `PROJCS["NAD_1983_UTM_Zone_23N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlabamaEastFIPS0101",
    `PROJCS["NAD_1983_StatePlane_Alabama_East_FIPS_0101",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlabamaWestFIPS0102",
    `PROJCS["NAD_1983_StatePlane_Alabama_West_FIPS_0102",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlaska1FIPS5001",
    `PROJCS["NAD_1983_StatePlane_Alaska_1_FIPS_5001",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",-36.86989764583333],PARAMETER["Longitude_Of_Center",-133.6666666666667],PARAMETER["Latitude_Of_Center",57.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlaska2FIPS5002",
    `PROJCS["NAD_1983_StatePlane_Alaska_2_FIPS_5002",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlaska3FIPS5003",
    `PROJCS["NAD_1983_StatePlane_Alaska_3_FIPS_5003",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-146.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlaska4FIPS5004",
    `PROJCS["NAD_1983_StatePlane_Alaska_4_FIPS_5004",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlaska5FIPS5005",
    `PROJCS["NAD_1983_StatePlane_Alaska_5_FIPS_5005",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlaska6FIPS5006",
    `PROJCS["NAD_1983_StatePlane_Alaska_6_FIPS_5006",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlaska7FIPS5007",
    `PROJCS["NAD_1983_StatePlane_Alaska_7_FIPS_5007",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-162.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlaska8FIPS5008",
    `PROJCS["NAD_1983_StatePlane_Alaska_8_FIPS_5008",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-166.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlaska9FIPS5009",
    `PROJCS["NAD_1983_StatePlane_Alaska_9_FIPS_5009",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-170.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlaska10FIPS5010",
    `PROJCS["NAD_1983_StatePlane_Alaska_10_FIPS_5010",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-176.0],PARAMETER["Standard_Parallel_1",51.83333333333334],PARAMETER["Standard_Parallel_2",53.83333333333334],PARAMETER["Latitude_Of_Origin",51.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIFIPS0401",
    `PROJCS["NAD_1983_StatePlane_California_I_FIPS_0401",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIIFIPS0402",
    `PROJCS["NAD_1983_StatePlane_California_II_FIPS_0402",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIIIFIPS0403",
    `PROJCS["NAD_1983_StatePlane_California_III_FIPS_0403",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIVFIPS0404",
    `PROJCS["NAD_1983_StatePlane_California_IV_FIPS_0404",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaVFIPS0405",
    `PROJCS["NAD_1983_StatePlane_California_V_FIPS_0405",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaVIFIPS0406",
    `PROJCS["NAD_1983_StatePlane_California_VI_FIPS_0406",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneArizonaEastFIPS0201",
    `PROJCS["NAD_1983_StatePlane_Arizona_East_FIPS_0201",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneArizonaCentralFIPS0202",
    `PROJCS["NAD_1983_StatePlane_Arizona_Central_FIPS_0202",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneArizonaWestFIPS0203",
    `PROJCS["NAD_1983_StatePlane_Arizona_West_FIPS_0203",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneArkansasNorthFIPS0301",
    `PROJCS["NAD_1983_StatePlane_Arkansas_North_FIPS_0301",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneArkansasSouthFIPS0302",
    `PROJCS["NAD_1983_StatePlane_Arkansas_South_FIPS_0302",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneColoradoNorthFIPS0501",
    `PROJCS["NAD_1983_StatePlane_Colorado_North_FIPS_0501",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneColoradoCentralFIPS0502",
    `PROJCS["NAD_1983_StatePlane_Colorado_Central_FIPS_0502",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneColoradoSouthFIPS0503",
    `PROJCS["NAD_1983_StatePlane_Colorado_South_FIPS_0503",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneConnecticutFIPS0600",
    `PROJCS["NAD_1983_StatePlane_Connecticut_FIPS_0600",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",304800.6096],PARAMETER["False_Northing",152400.3048],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneDelawareFIPS0700",
    `PROJCS["NAD_1983_StatePlane_Delaware_FIPS_0700",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneFloridaEastFIPS0901",
    `PROJCS["NAD_1983_StatePlane_Florida_East_FIPS_0901",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneFloridaWestFIPS0902",
    `PROJCS["NAD_1983_StatePlane_Florida_West_FIPS_0902",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneFloridaNorthFIPS0903",
    `PROJCS["NAD_1983_StatePlane_Florida_North_FIPS_0903",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneHawaii1FIPS5101",
    `PROJCS["NAD_1983_StatePlane_Hawaii_1_FIPS_5101",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-155.5],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",18.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneHawaii2FIPS5102",
    `PROJCS["NAD_1983_StatePlane_Hawaii_2_FIPS_5102",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-156.6666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",20.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneHawaii3FIPS5103",
    `PROJCS["NAD_1983_StatePlane_Hawaii_3_FIPS_5103",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneHawaii4FIPS5104",
    `PROJCS["NAD_1983_StatePlane_Hawaii_4_FIPS_5104",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.5],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneHawaii5FIPS5105",
    `PROJCS["NAD_1983_StatePlane_Hawaii_5_FIPS_5105",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.1666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",21.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneGeorgiaEastFIPS1001",
    `PROJCS["NAD_1983_StatePlane_Georgia_East_FIPS_1001",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneGeorgiaWestFIPS1002",
    `PROJCS["NAD_1983_StatePlane_Georgia_West_FIPS_1002",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneIdahoEastFIPS1101",
    `PROJCS["NAD_1983_StatePlane_Idaho_East_FIPS_1101",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneIdahoCentralFIPS1102",
    `PROJCS["NAD_1983_StatePlane_Idaho_Central_FIPS_1102",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneIdahoWestFIPS1103",
    `PROJCS["NAD_1983_StatePlane_Idaho_West_FIPS_1103",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneIllinoisEastFIPS1201",
    `PROJCS["NAD_1983_StatePlane_Illinois_East_FIPS_1201",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneIllinoisWestFIPS1202",
    `PROJCS["NAD_1983_StatePlane_Illinois_West_FIPS_1202",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneIndianaEastFIPS1301",
    `PROJCS["NAD_1983_StatePlane_Indiana_East_FIPS_1301",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneIndianaWestFIPS1302",
    `PROJCS["NAD_1983_StatePlane_Indiana_West_FIPS_1302",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneIowaNorthFIPS1401",
    `PROJCS["NAD_1983_StatePlane_Iowa_North_FIPS_1401",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneIowaSouthFIPS1402",
    `PROJCS["NAD_1983_StatePlane_Iowa_South_FIPS_1402",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneKansasNorthFIPS1501",
    `PROJCS["NAD_1983_StatePlane_Kansas_North_FIPS_1501",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneKansasSouthFIPS1502",
    `PROJCS["NAD_1983_StatePlane_Kansas_South_FIPS_1502",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneKentuckyNorthFIPS1601",
    `PROJCS["NAD_1983_StatePlane_Kentucky_North_FIPS_1601",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneKentuckySouthFIPS1602",
    `PROJCS["NAD_1983_StatePlane_Kentucky_South_FIPS_1602",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneLouisianaNorthFIPS1701",
    `PROJCS["NAD_1983_StatePlane_Louisiana_North_FIPS_1701",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneLouisianaSouthFIPS1702",
    `PROJCS["NAD_1983_StatePlane_Louisiana_South_FIPS_1702",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMaineEastFIPS1801",
    `PROJCS["NAD_1983_StatePlane_Maine_East_FIPS_1801",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMaineWestFIPS1802",
    `PROJCS["NAD_1983_StatePlane_Maine_West_FIPS_1802",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMarylandFIPS1900",
    `PROJCS["NAD_1983_StatePlane_Maryland_FIPS_1900",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMassachusettsMainlandFIPS2001",
    `PROJCS["NAD_1983_StatePlane_Massachusetts_Mainland_FIPS_2001",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",750000.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMassachusettsIslandFIPS2002",
    `PROJCS["NAD_1983_StatePlane_Massachusetts_Island_FIPS_2002",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMichiganNorthFIPS2111",
    `PROJCS["NAD_1983_StatePlane_Michigan_North_FIPS_2111",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMichiganCentralFIPS2112",
    `PROJCS["NAD_1983_StatePlane_Michigan_Central_FIPS_2112",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMichiganSouthFIPS2113",
    `PROJCS["NAD_1983_StatePlane_Michigan_South_FIPS_2113",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMinnesotaNorthFIPS2201",
    `PROJCS["NAD_1983_StatePlane_Minnesota_North_FIPS_2201",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMinnesotaCentralFIPS2202",
    `PROJCS["NAD_1983_StatePlane_Minnesota_Central_FIPS_2202",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMinnesotaSouthFIPS2203",
    `PROJCS["NAD_1983_StatePlane_Minnesota_South_FIPS_2203",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMississippiEastFIPS2301",
    `PROJCS["NAD_1983_StatePlane_Mississippi_East_FIPS_2301",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMississippiWestFIPS2302",
    `PROJCS["NAD_1983_StatePlane_Mississippi_West_FIPS_2302",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMissouriEastFIPS2401",
    `PROJCS["NAD_1983_StatePlane_Missouri_East_FIPS_2401",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMissouriCentralFIPS2402",
    `PROJCS["NAD_1983_StatePlane_Missouri_Central_FIPS_2402",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneMissouriWestFIPS2403",
    `PROJCS["NAD_1983_StatePlane_Missouri_West_FIPS_2403",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",850000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.5],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "Nahrwan1967UTMZone37N",
    `PROJCS["Nahrwan_1967_UTM_Zone_37N",GEOGCS["GCS_Nahrwan_1967",DATUM["D_Nahrwan_1967",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Nahrwan1967UTMZone38N",
    `PROJCS["Nahrwan_1967_UTM_Zone_38N",GEOGCS["GCS_Nahrwan_1967",DATUM["D_Nahrwan_1967",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Nahrwan1967UTMZone39N",
    `PROJCS["Nahrwan_1967_UTM_Zone_39N",GEOGCS["GCS_Nahrwan_1967",DATUM["D_Nahrwan_1967",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Nahrwan1967UTMZone40N",
    `PROJCS["Nahrwan_1967_UTM_Zone_40N",GEOGCS["GCS_Nahrwan_1967",DATUM["D_Nahrwan_1967",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Naparima1972UTMZone20N",
    `PROJCS["Naparima_1972_UTM_Zone_20N",GEOGCS["GCS_Naparima_1972",DATUM["D_Naparima_1972",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GD1949NewZealandMapGrid",
    `PROJCS["GD_1949_New_Zealand_Map_Grid",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["New_Zealand_Map_Grid"],PARAMETER["False_Easting",2510000.0],PARAMETER["False_Northing",6023150.0],PARAMETER["Longitude_Of_Origin",173.0],PARAMETER["Latitude_Of_Origin",-41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949MountEdenCircuit",
    `PROJCS["NZGD_1949_Mount_Eden_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",174.7643393611111],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",-36.87986527777778],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949BayofPlentyCircuit",
    `PROJCS["NZGD_1949_Bay_of_Plenty_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",176.46619725],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-37.76124980555556],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949PovertyBayCircuit",
    `PROJCS["NZGD_1949_Poverty_Bay_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",177.8856362777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-38.62470277777778],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949HawkesBayCircuit",
    `PROJCS["NZGD_1949_Hawkes_Bay_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",176.6736805277778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-39.65092930555556],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949TaranakiCircuit",
    `PROJCS["NZGD_1949_Taranaki_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",174.22801175],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-39.13575830555556],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949TuhirangiCircuit",
    `PROJCS["NZGD_1949_Tuhirangi_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",175.6400368055556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-39.51247038888889],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949WanganuiCircuit",
    `PROJCS["NZGD_1949_Wanganui_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",175.4880996111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-40.24194713888889],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949WairarapaCircuit",
    `PROJCS["NZGD_1949_Wairarapa_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",175.6473496666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-40.92553263888889],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949WellingtonCircuit",
    `PROJCS["NZGD_1949_Wellington_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",174.7766231111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-41.30131963888888],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949CollingwoodCircuit",
    `PROJCS["NZGD_1949_Collingwood_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",172.6720465],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-40.71475905555556],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949NelsonCircuit",
    `PROJCS["NZGD_1949_Nelson_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",173.2993168055555],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-41.27454472222222],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949KarameaCircuit",
    `PROJCS["NZGD_1949_Karamea_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",172.1090281944444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-41.28991152777778],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949BullerCircuit",
    `PROJCS["NZGD_1949_Buller_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",171.5812600555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-41.81080286111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949GreyCircuit",
    `PROJCS["NZGD_1949_Grey_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",171.5497713055556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-42.33369427777778],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949AmuriCircuit",
    `PROJCS["NZGD_1949_Amuri_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",173.0101333888889],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-42.68911658333333],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949MarlboroughCircuit",
    `PROJCS["NZGD_1949_Marlborough_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",173.8020741111111],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-41.54448666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949HokitikaCircuit",
    `PROJCS["NZGD_1949_Hokitika_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",170.9799935],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-42.88632236111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949OkaritoCircuit",
    `PROJCS["NZGD_1949_Okarito_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",170.2609258333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-43.11012813888889],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949JacksonsBayCircuit",
    `PROJCS["NZGD_1949_Jacksons_Bay_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",168.606267],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-43.97780288888889],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949MountPleasantCircuit",
    `PROJCS["NZGD_1949_Mount_Pleasant_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",172.7271935833333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-43.59063758333333],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949GawlerCircuit",
    `PROJCS["NZGD_1949_Gawler_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",171.3607484722222],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-43.74871155555556],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949TimaruCircuit",
    `PROJCS["NZGD_1949_Timaru_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",171.0572508333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-44.40222036111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949LindisPeakCircuit",
    `PROJCS["NZGD_1949_Lindis_Peak_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",169.4677550833333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-44.73526797222222],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949MountNicholasCircuit",
    `PROJCS["NZGD_1949_Mount_Nicholas_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",168.3986411944444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-45.13290258333333],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949MountYorkCircuit",
    `PROJCS["NZGD_1949_Mount_York_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",167.7388617777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-45.56372616666666],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949ObservationPointCircuit",
    `PROJCS["NZGD_1949_Observation_Point_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",170.6285951666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-45.81619661111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949NorthTaieriCircuit",
    `PROJCS["NZGD_1949_North_Taieri_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",700000.0],PARAMETER["Central_Meridian",170.2825891111111],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",-45.86151336111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949BluffCircuit",
    `PROJCS["NZGD_1949_Bluff_Circuit",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300002.66],PARAMETER["False_Northing",699999.58],PARAMETER["Central_Meridian",168.342872],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-46.60000961111111],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949UTMZone58S",
    `PROJCS["NZGD_1949_UTM_Zone_58S",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949UTMZone59S",
    `PROJCS["NZGD_1949_UTM_Zone_59S",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD1949UTMZone60S",
    `PROJCS["NZGD_1949_UTM_Zone_60S",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewZealandNorthIsland",
    `PROJCS["New_Zealand_North_Island",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",175.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-39.0],UNIT["Yard_Sears",0.9143984146160287]]`
  ],
  [
    "NewZealandSouthIsland",
    `PROJCS["New_Zealand_South_Island",GEOGCS["GCS_New_Zealand_1949",DATUM["D_New_Zealand_1949",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",171.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-44.0],UNIT["Yard_Sears",0.9143984146160287]]`
  ],
  [
    "NGO1948OsloNorwayZone1",
    `PROJCS["NGO_1948_Oslo_Norway_Zone_1",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-4.666666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloNorwayZone2",
    `PROJCS["NGO_1948_Oslo_Norway_Zone_2",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-2.333333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloNorwayZone3",
    `PROJCS["NGO_1948_Oslo_Norway_Zone_3",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloNorwayZone4",
    `PROJCS["NGO_1948_Oslo_Norway_Zone_4",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",2.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloNorwayZone5",
    `PROJCS["NGO_1948_Oslo_Norway_Zone_5",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",6.166666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloNorwayZone6",
    `PROJCS["NGO_1948_Oslo_Norway_Zone_6",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.16666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloNorwayZone7",
    `PROJCS["NGO_1948_Oslo_Norway_Zone_7",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",14.16666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloNorwayZone8",
    `PROJCS["NGO_1948_Oslo_Norway_Zone_8",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "Datum73UTMZone29N",
    `PROJCS["Datum_73_UTM_Zone_29N",GEOGCS["GCS_Datum_73",DATUM["D_Datum_73",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Datum73ModifiedPortugueseGrid",
    `PROJCS["Datum_73_Modified_Portuguese_Grid",GEOGCS["GCS_Datum_73",DATUM["D_Datum_73",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",180.598],PARAMETER["False_Northing",-86.99],PARAMETER["Central_Meridian",-8.131906111111112],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "Datum73ModifiedPortugueseGrid",
    `PROJCS["Datum_73_Modified_Portuguese_Grid",GEOGCS["GCS_Datum_73",DATUM["D_Datum_73",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",180.598],PARAMETER["False_Northing",-86.99],PARAMETER["Central_Meridian",-8.131906111111112],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NorddeGuerre",
    `PROJCS["Nord_de_Guerre",GEOGCS["GCS_ATF_Paris",DATUM["D_ATF",SPHEROID["Plessis_1817",6376523.0,308.64]],PRIMEM["Paris_RGS",2.337208333333333],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",6.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Scale_Factor",0.99950908],PARAMETER["Latitude_Of_Origin",55.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisLambertNordFrance",
    `PROJCS["NTF_Paris_Lambert_Nord_France",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Scale_Factor",0.999877341],PARAMETER["Latitude_Of_Origin",55.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisLambertCentreFrance",
    `PROJCS["NTF_Paris_Lambert_Centre_France",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",52.0],PARAMETER["Scale_Factor",0.99987742],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisLambertSudFrance",
    `PROJCS["NTF_Paris_Lambert_Sud_France",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",49.0],PARAMETER["Scale_Factor",0.999877499],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisLambertCorse",
    `PROJCS["NTF_Paris_Lambert_Corse",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",234.358],PARAMETER["False_Northing",185861.369],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",46.85],PARAMETER["Scale_Factor",0.99994471],PARAMETER["Latitude_Of_Origin",46.85],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisLambertZoneI",
    `PROJCS["NTF_Paris_Lambert_Zone_I",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Scale_Factor",0.999877341],PARAMETER["Latitude_Of_Origin",55.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisLambertZoneII",
    `PROJCS["NTF_Paris_Lambert_Zone_II",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",52.0],PARAMETER["Scale_Factor",0.99987742],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisLambertZoneIII",
    `PROJCS["NTF_Paris_Lambert_Zone_III",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",3200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",49.0],PARAMETER["Scale_Factor",0.999877499],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisLambertZoneIV",
    `PROJCS["NTF_Paris_Lambert_Zone_IV",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",234.358],PARAMETER["False_Northing",4185861.369],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",46.85],PARAMETER["Scale_Factor",0.99994471],PARAMETER["Latitude_Of_Origin",46.85],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisFranceI",
    `PROJCS["NTF_Paris_France_I",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Scale_Factor",0.999877341],PARAMETER["Latitude_Of_Origin",55.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisFranceII",
    `PROJCS["NTF_Paris_France_II",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",52.0],PARAMETER["Scale_Factor",0.99987742],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisFranceIII",
    `PROJCS["NTF_Paris_France_III",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",3200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",49.0],PARAMETER["Scale_Factor",0.999877499],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisFranceIV",
    `PROJCS["NTF_Paris_France_IV",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",234.358],PARAMETER["False_Northing",4185861.369],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",46.85],PARAMETER["Scale_Factor",0.99994471],PARAMETER["Latitude_Of_Origin",46.85],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisNordFrance",
    `PROJCS["NTF_Paris_Nord_France",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Scale_Factor",0.999877341],PARAMETER["Latitude_Of_Origin",55.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisCentreFrance",
    `PROJCS["NTF_Paris_Centre_France",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",52.0],PARAMETER["Scale_Factor",0.99987742],PARAMETER["Latitude_Of_Origin",52.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisSudFrance",
    `PROJCS["NTF_Paris_Sud_France",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",49.0],PARAMETER["Scale_Factor",0.999877499],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "NTFParisCorse",
    `PROJCS["NTF_Paris_Corse",GEOGCS["GCS_NTF_Paris",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",234.358],PARAMETER["False_Northing",185861.369],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",46.85],PARAMETER["Scale_Factor",0.99994471],PARAMETER["Latitude_Of_Origin",46.85],UNIT["Meter",1.0]]`
  ],
  [
    "BritishNationalGrid",
    `PROJCS["British_National_Grid",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",-100000.0],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996012717],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "Palestine1923PalestineGrid",
    `PROJCS["Palestine_1923_Palestine_Grid",GEOGCS["GCS_Palestine_1923",DATUM["D_Palestine_1923",SPHEROID["Clarke_1880_Benoit",6378300.789,293.4663155389802]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",170251.555],PARAMETER["False_Northing",126867.909],PARAMETER["Central_Meridian",35.21208055555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",31.73409694444445],UNIT["Meter",1.0]]`
  ],
  [
    "Palestine1923PalestineBelt",
    `PROJCS["Palestine_1923_Palestine_Belt",GEOGCS["GCS_Palestine_1923",DATUM["D_Palestine_1923",SPHEROID["Clarke_1880_Benoit",6378300.789,293.4663155389802]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",170251.555],PARAMETER["False_Northing",1126867.909],PARAMETER["Central_Meridian",35.21208055555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",31.73409694444445],UNIT["Meter",1.0]]`
  ],
  [
    "Palestine1923IsraelCSGrid",
    `PROJCS["Palestine_1923_Israel_CS_Grid",GEOGCS["GCS_Palestine_1923",DATUM["D_Palestine_1923",SPHEROID["Clarke_1880_Benoit",6378300.789,293.4663155389802]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",170251.555],PARAMETER["False_Northing",1126867.909],PARAMETER["Central_Meridian",35.21208055555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",31.73409694444445],UNIT["Meter",1.0]]`
  ],
  [
    "PointeNoireUTMZone32S",
    `PROJCS["Pointe_Noire_UTM_Zone_32S",GEOGCS["GCS_Pointe_Noire",DATUM["D_Pointe_Noire",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone48",
    `PROJCS["GDA_1994_MGA_Zone_48",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone49",
    `PROJCS["GDA_1994_MGA_Zone_49",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone50",
    `PROJCS["GDA_1994_MGA_Zone_50",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone51",
    `PROJCS["GDA_1994_MGA_Zone_51",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone52",
    `PROJCS["GDA_1994_MGA_Zone_52",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone53",
    `PROJCS["GDA_1994_MGA_Zone_53",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone54",
    `PROJCS["GDA_1994_MGA_Zone_54",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone55",
    `PROJCS["GDA_1994_MGA_Zone_55",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone56",
    `PROJCS["GDA_1994_MGA_Zone_56",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone57",
    `PROJCS["GDA_1994_MGA_Zone_57",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994MGAZone58",
    `PROJCS["GDA_1994_MGA_Zone_58",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone2",
    `PROJCS["Pulkovo_1942_GK_Zone_2",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone3",
    `PROJCS["Pulkovo_1942_GK_Zone_3",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone4",
    `PROJCS["Pulkovo_1942_GK_Zone_4",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone5",
    `PROJCS["Pulkovo_1942_GK_Zone_5",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone6",
    `PROJCS["Pulkovo_1942_GK_Zone_6",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone7",
    `PROJCS["Pulkovo_1942_GK_Zone_7",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone8",
    `PROJCS["Pulkovo_1942_GK_Zone_8",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone9",
    `PROJCS["Pulkovo_1942_GK_Zone_9",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone10",
    `PROJCS["Pulkovo_1942_GK_Zone_10",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone11",
    `PROJCS["Pulkovo_1942_GK_Zone_11",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone12",
    `PROJCS["Pulkovo_1942_GK_Zone_12",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone13",
    `PROJCS["Pulkovo_1942_GK_Zone_13",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone14",
    `PROJCS["Pulkovo_1942_GK_Zone_14",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone15",
    `PROJCS["Pulkovo_1942_GK_Zone_15",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone16",
    `PROJCS["Pulkovo_1942_GK_Zone_16",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",16500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone17",
    `PROJCS["Pulkovo_1942_GK_Zone_17",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",17500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone18",
    `PROJCS["Pulkovo_1942_GK_Zone_18",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",18500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone19",
    `PROJCS["Pulkovo_1942_GK_Zone_19",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",19500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone20",
    `PROJCS["Pulkovo_1942_GK_Zone_20",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",20500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone21",
    `PROJCS["Pulkovo_1942_GK_Zone_21",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",21500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone22",
    `PROJCS["Pulkovo_1942_GK_Zone_22",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",22500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone23",
    `PROJCS["Pulkovo_1942_GK_Zone_23",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",23500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone24",
    `PROJCS["Pulkovo_1942_GK_Zone_24",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",24500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone25",
    `PROJCS["Pulkovo_1942_GK_Zone_25",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",25500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone26",
    `PROJCS["Pulkovo_1942_GK_Zone_26",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",26500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone27",
    `PROJCS["Pulkovo_1942_GK_Zone_27",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",27500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone28",
    `PROJCS["Pulkovo_1942_GK_Zone_28",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",28500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone29",
    `PROJCS["Pulkovo_1942_GK_Zone_29",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",29500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone30",
    `PROJCS["Pulkovo_1942_GK_Zone_30",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",30500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone31",
    `PROJCS["Pulkovo_1942_GK_Zone_31",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",31500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone32",
    `PROJCS["Pulkovo_1942_GK_Zone_32",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone2N",
    `PROJCS["Pulkovo_1942_GK_Zone_2N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone3N",
    `PROJCS["Pulkovo_1942_GK_Zone_3N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone4N",
    `PROJCS["Pulkovo_1942_GK_Zone_4N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone5N",
    `PROJCS["Pulkovo_1942_GK_Zone_5N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone6N",
    `PROJCS["Pulkovo_1942_GK_Zone_6N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone7N",
    `PROJCS["Pulkovo_1942_GK_Zone_7N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone8N",
    `PROJCS["Pulkovo_1942_GK_Zone_8N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone9N",
    `PROJCS["Pulkovo_1942_GK_Zone_9N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone10N",
    `PROJCS["Pulkovo_1942_GK_Zone_10N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone11N",
    `PROJCS["Pulkovo_1942_GK_Zone_11N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone12N",
    `PROJCS["Pulkovo_1942_GK_Zone_12N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone13N",
    `PROJCS["Pulkovo_1942_GK_Zone_13N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone14N",
    `PROJCS["Pulkovo_1942_GK_Zone_14N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone15N",
    `PROJCS["Pulkovo_1942_GK_Zone_15N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone16N",
    `PROJCS["Pulkovo_1942_GK_Zone_16N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone17N",
    `PROJCS["Pulkovo_1942_GK_Zone_17N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone18N",
    `PROJCS["Pulkovo_1942_GK_Zone_18N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone19N",
    `PROJCS["Pulkovo_1942_GK_Zone_19N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone20N",
    `PROJCS["Pulkovo_1942_GK_Zone_20N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone21N",
    `PROJCS["Pulkovo_1942_GK_Zone_21N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone22N",
    `PROJCS["Pulkovo_1942_GK_Zone_22N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone23N",
    `PROJCS["Pulkovo_1942_GK_Zone_23N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone24N",
    `PROJCS["Pulkovo_1942_GK_Zone_24N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone25N",
    `PROJCS["Pulkovo_1942_GK_Zone_25N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone26N",
    `PROJCS["Pulkovo_1942_GK_Zone_26N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone27N",
    `PROJCS["Pulkovo_1942_GK_Zone_27N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone28N",
    `PROJCS["Pulkovo_1942_GK_Zone_28N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone29N",
    `PROJCS["Pulkovo_1942_GK_Zone_29N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone30N",
    `PROJCS["Pulkovo_1942_GK_Zone_30N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone31N",
    `PROJCS["Pulkovo_1942_GK_Zone_31N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942GKZone32N",
    `PROJCS["Pulkovo_1942_GK_Zone_32N",GEOGCS["GCS_Pulkovo_1942",DATUM["D_Pulkovo_1942",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "QatarNationalGrid",
    `PROJCS["Qatar_National_Grid",GEOGCS["GCS_Qatar_1974",DATUM["D_Qatar",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",51.21666666666667],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",24.45],UNIT["Meter",1.0]]`
  ],
  [
    "RDOld",
    `PROJCS["RD_Old",GEOGCS["GCS_Amersfoort",DATUM["D_Amersfoort",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",5.38763888888889],PARAMETER["Scale_Factor",0.9999079],PARAMETER["Latitude_Of_Origin",52.15616055555555],UNIT["Meter",1.0]]`
  ],
  [
    "RDNew",
    `PROJCS["RD_New",GEOGCS["GCS_Amersfoort",DATUM["D_Amersfoort",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",155000.0],PARAMETER["False_Northing",463000.0],PARAMETER["Central_Meridian",5.38763888888889],PARAMETER["Scale_Factor",0.9999079],PARAMETER["Latitude_Of_Origin",52.15616055555555],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969BrazilPolyconic",
    `PROJCS["SAD_1969_Brazil_Polyconic",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polyconic"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-54.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969BrazilPolyconic",
    `PROJCS["SAD_1969_Brazil_Polyconic",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polyconic"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-54.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone18N",
    `PROJCS["SAD_1969_UTM_Zone_18N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone19N",
    `PROJCS["SAD_1969_UTM_Zone_19N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone20N",
    `PROJCS["SAD_1969_UTM_Zone_20N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone21N",
    `PROJCS["SAD_1969_UTM_Zone_21N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone22N",
    `PROJCS["SAD_1969_UTM_Zone_22N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone18N",
    `PROJCS["SAD_1969_UTM_Zone_18N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone19N",
    `PROJCS["SAD_1969_UTM_Zone_19N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone20N",
    `PROJCS["SAD_1969_UTM_Zone_20N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone21N",
    `PROJCS["SAD_1969_UTM_Zone_21N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone22N",
    `PROJCS["SAD_1969_UTM_Zone_22N",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone17S",
    `PROJCS["SAD_1969_UTM_Zone_17S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone18S",
    `PROJCS["SAD_1969_UTM_Zone_18S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone19S",
    `PROJCS["SAD_1969_UTM_Zone_19S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone20S",
    `PROJCS["SAD_1969_UTM_Zone_20S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone21S",
    `PROJCS["SAD_1969_UTM_Zone_21S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone22S",
    `PROJCS["SAD_1969_UTM_Zone_22S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone23S",
    `PROJCS["SAD_1969_UTM_Zone_23S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone24S",
    `PROJCS["SAD_1969_UTM_Zone_24S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone25S",
    `PROJCS["SAD_1969_UTM_Zone_25S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone17S",
    `PROJCS["SAD_1969_UTM_Zone_17S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone18S",
    `PROJCS["SAD_1969_UTM_Zone_18S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone19S",
    `PROJCS["SAD_1969_UTM_Zone_19S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone20S",
    `PROJCS["SAD_1969_UTM_Zone_20S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone21S",
    `PROJCS["SAD_1969_UTM_Zone_21S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone22S",
    `PROJCS["SAD_1969_UTM_Zone_22S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone23S",
    `PROJCS["SAD_1969_UTM_Zone_23S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone24S",
    `PROJCS["SAD_1969_UTM_Zone_24S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SAD1969UTMZone25S",
    `PROJCS["SAD_1969_UTM_Zone_25S",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SapperHill1943UTMZone20S",
    `PROJCS["Sapper_Hill_1943_UTM_Zone_20S",GEOGCS["GCS_Sapper_Hill_1943",DATUM["D_Sapper_Hill_1943",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SapperHill1943UTMZone21S",
    `PROJCS["Sapper_Hill_1943_UTM_Zone_21S",GEOGCS["GCS_Sapper_Hill_1943",DATUM["D_Sapper_Hill_1943",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SchwarzeckUTMZone33S",
    `PROJCS["Schwarzeck_UTM_Zone_33S",GEOGCS["GCS_Schwarzeck",DATUM["D_Schwarzeck",SPHEROID["Bessel_Namibia",6377483.865280418,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SudanUTMZone35N",
    `PROJCS["Sudan_UTM_Zone_35N",GEOGCS["GCS_Sudan",DATUM["D_Sudan",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SudanUTMZone36N",
    `PROJCS["Sudan_UTM_Zone_36N",GEOGCS["GCS_Sudan",DATUM["D_Sudan",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Tananarive1925ParisLabordeGrid",
    `PROJCS["Tananarive_1925_Paris_Laborde_Grid",GEOGCS["GCS_Tananarive_1925_Paris",DATUM["D_Tananarive_1925",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Paris",2.337229166666667],UNIT["Grad",0.01570796326794897]],PROJECTION["Laborde_Oblique_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Azimuth",21.0],PARAMETER["Longitude_Of_Center",49.0],PARAMETER["Latitude_Of_Center",-21.0],UNIT["Meter",1.0]]`
  ],
  [
    "Tananarive1925UTMZone38S",
    `PROJCS["Tananarive_1925_UTM_Zone_38S",GEOGCS["GCS_Tananarive_1925",DATUM["D_Tananarive_1925",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Tananarive1925UTMZone39S",
    `PROJCS["Tananarive_1925_UTM_Zone_39S",GEOGCS["GCS_Tananarive_1925",DATUM["D_Tananarive_1925",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Timbalai1948UTMZone49N",
    `PROJCS["Timbalai_1948_UTM_Zone_49N",GEOGCS["GCS_Timbalai_1948",DATUM["D_Timbalai_1948",SPHEROID["Everest_Definition_1967",6377298.556,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Timbalai1948UTMZone50N",
    `PROJCS["Timbalai_1948_UTM_Zone_50N",GEOGCS["GCS_Timbalai_1948",DATUM["D_Timbalai_1948",SPHEROID["Everest_Definition_1967",6377298.556,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Timbalai1948RSOBorneoChains",
    `PROJCS["Timbalai_1948_RSO_Borneo_Chains",GEOGCS["GCS_Timbalai_1948",DATUM["D_Timbalai_1948",SPHEROID["Everest_Definition_1967",6377298.556,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",53.31582047908623],PARAMETER["Longitude_Of_Center",115.0],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",53.13010235415598],UNIT["Chain_Sears",20.11676512155263]]`
  ],
  [
    "Timbalai1948RSOBorneoFeet",
    `PROJCS["Timbalai_1948_RSO_Borneo_Feet",GEOGCS["GCS_Timbalai_1948",DATUM["D_Timbalai_1948",SPHEROID["Everest_Definition_1967",6377298.556,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",53.31582047908623],PARAMETER["Longitude_Of_Center",115.0],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",53.13010235415598],UNIT["Foot_Sears",0.3047994715386762]]`
  ],
  [
    "Timbalai1948RSOBorneoMeters",
    `PROJCS["Timbalai_1948_RSO_Borneo_Meters",GEOGCS["GCS_Timbalai_1948",DATUM["D_Timbalai_1948",SPHEROID["Everest_Definition_1967",6377298.556,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",53.31582047908623],PARAMETER["Longitude_Of_Center",115.0],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",53.13010235415598],UNIT["Meter",1.0]]`
  ],
  [
    "Timbalai1948RSOSarawakLSD(m)",
    `PROJCS["Timbalai_1948_RSO_Sarawak_LSD_(m)",GEOGCS["GCS_Timbalai_1948",DATUM["D_Timbalai_1948",SPHEROID["Everest_Definition_1967",6377298.556,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",53.3158204722222],PARAMETER["Longitude_Of_Center",115.0],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",53.13010236111109],UNIT["Meter",1.0]]`
  ],
  [
    "TM65IrishGrid",
    `PROJCS["TM65_Irish_Grid",GEOGCS["GCS_TM65",DATUM["D_TM65",SPHEROID["Airy_Modified",6377340.189,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-8.0],PARAMETER["Scale_Factor",1.000035],PARAMETER["Latitude_Of_Origin",53.5],UNIT["Meter",1.0]]`
  ],
  [
    "OSNI1952IrishNationalGrid",
    `PROJCS["OSNI_1952_Irish_National_Grid",GEOGCS["GCS_OSNI_1952",DATUM["D_OSNI_1952",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-8.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",53.5],UNIT["Meter",1.0]]`
  ],
  [
    "TM65IrishGrid",
    `PROJCS["TM65_Irish_Grid",GEOGCS["GCS_TM65",DATUM["D_TM65",SPHEROID["Airy_Modified",6377340.189,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-8.0],PARAMETER["Scale_Factor",1.000035],PARAMETER["Latitude_Of_Origin",53.5],UNIT["Meter",1.0]]`
  ],
  [
    "TM75IrishGrid",
    `PROJCS["TM75_Irish_Grid",GEOGCS["GCS_TM75",DATUM["D_TM75",SPHEROID["Airy_Modified",6377340.189,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-8.0],PARAMETER["Scale_Factor",1.000035],PARAMETER["Latitude_Of_Origin",53.5],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone1",
    `PROJCS["Japan_Zone_1",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone2",
    `PROJCS["Japan_Zone_2",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone3",
    `PROJCS["Japan_Zone_3",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone4",
    `PROJCS["Japan_Zone_4",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",133.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone5",
    `PROJCS["Japan_Zone_5",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",134.3333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone6",
    `PROJCS["Japan_Zone_6",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",136.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone7",
    `PROJCS["Japan_Zone_7",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",137.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone8",
    `PROJCS["Japan_Zone_8",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",138.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone9",
    `PROJCS["Japan_Zone_9",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",139.8333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone10",
    `PROJCS["Japan_Zone_10",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",140.8333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone11",
    `PROJCS["Japan_Zone_11",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",140.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone12",
    `PROJCS["Japan_Zone_12",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",142.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone13",
    `PROJCS["Japan_Zone_13",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",144.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone14",
    `PROJCS["Japan_Zone_14",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone15",
    `PROJCS["Japan_Zone_15",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",127.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone16",
    `PROJCS["Japan_Zone_16",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",124.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone17",
    `PROJCS["Japan_Zone_17",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone18",
    `PROJCS["Japan_Zone_18",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",136.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",20.0],UNIT["Meter",1.0]]`
  ],
  [
    "JapanZone19",
    `PROJCS["Japan_Zone_19",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "Trinidad1903TrinidadGrid",
    `PROJCS["Trinidad_1903_Trinidad_Grid",GEOGCS["GCS_Trinidad_1903",DATUM["D_Trinidad_1903",SPHEROID["Clarke_1858",6378293.645208759,294.260676369]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",430000.0],PARAMETER["False_Northing",325000.0],PARAMETER["Central_Meridian",-61.33333333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",10.44166666666667],UNIT["Link_Clarke",0.201166195164]]`
  ],
  [
    "TC1948UTMZone39N",
    `PROJCS["TC_1948_UTM_Zone_39N",GEOGCS["GCS_Trucial_Coast_1948",DATUM["D_Trucial_Coast_1948",SPHEROID["Helmert_1906",6378200.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TC1948UTMZone40N",
    `PROJCS["TC_1948_UTM_Zone_40N",GEOGCS["GCS_Trucial_Coast_1948",DATUM["D_Trucial_Coast_1948",SPHEROID["Helmert_1906",6378200.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NordAlgerieAncienne",
    `PROJCS["Nord_Algerie_Ancienne",GEOGCS["GCS_Voirol_1875",DATUM["D_Voirol_1875",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",2.7],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",0.999625544],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "SudAlgerieAncienne",
    `PROJCS["Sud_Algerie_Ancienne",GEOGCS["GCS_Voirol_1875",DATUM["D_Voirol_1875",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",2.7],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Scale_Factor",0.999625769],PARAMETER["Latitude_Of_Origin",33.3],UNIT["Meter",1.0]]`
  ],
  [
    "Voirol1879NordAlgerieAncienne",
    `PROJCS["Voirol_1879_Nord_Algerie_Ancienne",GEOGCS["GCS_Voirol_1879",DATUM["D_Voirol_1879",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",2.7],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",0.999625544],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "Voirol1879SudAlgerieAncienne",
    `PROJCS["Voirol_1879_Sud_Algerie_Ancienne",GEOGCS["GCS_Voirol_1879",DATUM["D_Voirol_1879",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",2.7],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Scale_Factor",0.999625769],PARAMETER["Latitude_Of_Origin",33.3],UNIT["Meter",1.0]]`
  ],
  [
    "NordAlgerie",
    `PROJCS["Nord_Algerie",GEOGCS["GCS_Voirol_Unifie_1960",DATUM["D_Voirol_Unifie_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500135.0],PARAMETER["False_Northing",300090.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Scale_Factor",0.999625544],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "SudAlgerie",
    `PROJCS["Sud_Algerie",GEOGCS["GCS_Voirol_Unifie_1960",DATUM["D_Voirol_Unifie_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Grad",0.01570796326794897]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500135.0],PARAMETER["False_Northing",300090.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",37.0],PARAMETER["Scale_Factor",0.999625769],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Meter",1.0]]`
  ],
  [
    "NordSahara1959UTMZone29N",
    `PROJCS["Nord_Sahara_1959_UTM_Zone_29N",GEOGCS["GCS_Nord_Sahara_1959",DATUM["D_Nord_Sahara_1959",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NordSahara1959UTMZone30N",
    `PROJCS["Nord_Sahara_1959_UTM_Zone_30N",GEOGCS["GCS_Nord_Sahara_1959",DATUM["D_Nord_Sahara_1959",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NordSahara1959UTMZone31N",
    `PROJCS["Nord_Sahara_1959_UTM_Zone_31N",GEOGCS["GCS_Nord_Sahara_1959",DATUM["D_Nord_Sahara_1959",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NordSahara1959UTMZone32N",
    `PROJCS["Nord_Sahara_1959_UTM_Zone_32N",GEOGCS["GCS_Nord_Sahara_1959",DATUM["D_Nord_Sahara_1959",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NordSahara1959VoirolUnifieNord",
    `PROJCS["Nord_Sahara_1959_Voirol_Unifie_Nord",GEOGCS["GCS_Nord_Sahara_1959",DATUM["D_Nord_Sahara_1959",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500135.0],PARAMETER["False_Northing",300090.0],PARAMETER["Central_Meridian",2.7],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",0.999625544],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "NordSahara1959VoirolUnifieSud",
    `PROJCS["Nord_Sahara_1959_Voirol_Unifie_Sud",GEOGCS["GCS_Nord_Sahara_1959",DATUM["D_Nord_Sahara_1959",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500135.0],PARAMETER["False_Northing",300090.0],PARAMETER["Central_Meridian",2.7],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Scale_Factor",0.999625769],PARAMETER["Latitude_Of_Origin",33.3],UNIT["Meter",1.0]]`
  ],
  [
    "SwedishNationalGrid",
    `PROJCS["Swedish_National_Grid",GEOGCS["GCS_RT38_Stockholm",DATUM["D_Stockholm_1938",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Stockholm",18.05827777777778],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-2.25],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Yoff1972UTMZone28N",
    `PROJCS["Yoff_1972_UTM_Zone_28N",GEOGCS["GCS_Yoff",DATUM["D_Yoff",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Zanderij1972UTMZone21N",
    `PROJCS["Zanderij_1972_UTM_Zone_21N",GEOGCS["GCS_Zanderij",DATUM["D_Zanderij",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ZanderijTM54NW",
    `PROJCS["Zanderij_TM_54_NW",GEOGCS["GCS_Zanderij",DATUM["D_Zanderij",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-54.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ZanderijSurinameOldTM",
    `PROJCS["Zanderij_Suriname_Old_TM",GEOGCS["GCS_Zanderij",DATUM["D_Zanderij",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-55.68333333333333],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ZanderijSurinameTM",
    `PROJCS["Zanderij_Suriname_TM",GEOGCS["GCS_Zanderij",DATUM["D_Zanderij",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-55.68333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIFerroAustriaGKWest",
    `PROJCS["MGI_Ferro_Austria_GK_West",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",28.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIFerroAustriaGKCentral",
    `PROJCS["MGI_Ferro_Austria_GK_Central",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIFerroAustriaGKEast",
    `PROJCS["MGI_Ferro_Austria_GK_East",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",34.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaGKWest",
    `PROJCS["MGI_Austria_GK_West",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",10.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaGKCentral",
    `PROJCS["MGI_Austria_GK_Central",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",13.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaGKEast",
    `PROJCS["MGI_Austria_GK_East",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",16.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaGKM28",
    `PROJCS["MGI_Austria_GK_M28",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",10.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaGKM31",
    `PROJCS["MGI_Austria_GK_M31",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",450000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",13.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaGKM34",
    `PROJCS["MGI_Austria_GK_M34",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",750000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",16.33333333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI3DegreeGaussZone5",
    `PROJCS["MGI_3_Degree_Gauss_Zone_5",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI3DegreeGaussZone6",
    `PROJCS["MGI_3_Degree_Gauss_Zone_6",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI3DegreeGaussZone7",
    `PROJCS["MGI_3_Degree_Gauss_Zone_7",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGI3DegreeGaussZone8",
    `PROJCS["MGI_3_Degree_Gauss_Zone_8",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIBalkans5",
    `PROJCS["MGI_Balkans_5",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIBalkans6",
    `PROJCS["MGI_Balkans_6",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIBalkans7",
    `PROJCS["MGI_Balkans_7",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIBalkans8",
    `PROJCS["MGI_Balkans_8",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIBalkans8",
    `PROJCS["MGI_Balkans_8",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AustriaWestZone",
    `PROJCS["Austria_West_Zone",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",28.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AustriaCentralZone",
    `PROJCS["Austria_Central_Zone",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AustriaEastZone",
    `PROJCS["Austria_East_Zone",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",34.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIM28",
    `PROJCS["MGI_M28",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIM31",
    `PROJCS["MGI_M31",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",450000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",13.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIM34",
    `PROJCS["MGI_M34",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",750000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",16.33333333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaLambert",
    `PROJCS["MGI_Austria_Lambert",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",13.33333333333333],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",47.5],UNIT["Meter",1.0]]`
  ],
  [
    "MGIFerroM28",
    `PROJCS["MGI_Ferro_M28",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",28.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIFerroM31",
    `PROJCS["MGI_Ferro_M31",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",450000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIFerroM34",
    `PROJCS["MGI_Ferro_M34",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",750000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",34.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AustriaWestZone",
    `PROJCS["Austria_West_Zone",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",28.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AustriaCentralZone",
    `PROJCS["Austria_Central_Zone",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AustriaEastZone",
    `PROJCS["Austria_East_Zone",GEOGCS["GCS_MGI_Ferro",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",34.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIM28",
    `PROJCS["MGI_M28",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIM31",
    `PROJCS["MGI_M31",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",450000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",13.33333333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIM34",
    `PROJCS["MGI_M34",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",750000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",16.33333333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MGIAustriaLambert",
    `PROJCS["MGI_Austria_Lambert",GEOGCS["GCS_MGI",DATUM["D_MGI",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",13.33333333333333],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",47.5],UNIT["Meter",1.0]]`
  ],
  [
    "BelgeLambert1972",
    `PROJCS["Belge_Lambert_1972",GEOGCS["GCS_Belge_1972",DATUM["D_Belge_1972",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150000.013],PARAMETER["False_Northing",5400088.438],PARAMETER["Central_Meridian",4.367486666666666],PARAMETER["Standard_Parallel_1",49.8333339],PARAMETER["Standard_Parallel_2",51.16666723333333],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGaussZone1",
    `PROJCS["DHDN_3_Degree_Gauss_Zone_1",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGaussZone2",
    `PROJCS["DHDN_3_Degree_Gauss_Zone_2",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",6.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGaussZone3",
    `PROJCS["DHDN_3_Degree_Gauss_Zone_3",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGaussZone4",
    `PROJCS["DHDN_3_Degree_Gauss_Zone_4",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGaussZone5",
    `PROJCS["DHDN_3_Degree_Gauss_Zone_5",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGaussZone2",
    `PROJCS["DHDN_3_Degree_Gauss_Zone_2",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",6.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGaussZone3",
    `PROJCS["DHDN_3_Degree_Gauss_Zone_3",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGaussZone4",
    `PROJCS["DHDN_3_Degree_Gauss_Zone_4",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "DHDN3DegreeGaussZone5",
    `PROJCS["DHDN_3_Degree_Gauss_Zone_5",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GermanyZone1",
    `PROJCS["Germany_Zone_1",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GermanyZone2",
    `PROJCS["Germany_Zone_2",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",6.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GermanyZone3",
    `PROJCS["Germany_Zone_3",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GermanyZone4",
    `PROJCS["Germany_Zone_4",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GermanyZone5",
    `PROJCS["Germany_Zone_5",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Conakry1905UTMZone28N",
    `PROJCS["Conakry_1905_UTM_Zone_28N",GEOGCS["GCS_Conakry_1905",DATUM["D_Conakry_1905",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Conakry1905UTMZone29N",
    `PROJCS["Conakry_1905_UTM_Zone_29N",GEOGCS["GCS_Conakry_1905",DATUM["D_Conakry_1905",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Stereo33",
    `PROJCS["Stereo_33",GEOGCS["GCS_Dealul_Piscului_1933",DATUM["D_Dealul_Piscului_1933",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",25.39246588888889],PARAMETER["Scale_Factor",0.9996667],PARAMETER["Latitude_Of_Origin",45.9],UNIT["Meter",1.0]]`
  ],
  [
    "Stereo70",
    `PROJCS["Stereo_70",GEOGCS["GCS_Dealul_Piscului_1970",DATUM["D_Dealul_Piscului_1970",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Double_Stereographic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Scale_Factor",0.99975],PARAMETER["Latitude_Of_Origin",46.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGNUTMZone38N",
    `PROJCS["NGN_UTM_Zone_38N",GEOGCS["GCS_NGN",DATUM["D_NGN",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGNUTMZone39N",
    `PROJCS["NGN_UTM_Zone_39N",GEOGCS["GCS_NGN",DATUM["D_NGN",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KUDAMSKTM",
    `PROJCS["KUDAMS_KTM",GEOGCS["GCS_KUDAMS",DATUM["D_Kuwait_Utility",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",48.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone17N",
    `PROJCS["SIRGAS_UTM_Zone_17N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone18N",
    `PROJCS["SIRGAS_UTM_Zone_18N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone19N",
    `PROJCS["SIRGAS_UTM_Zone_19N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone20N",
    `PROJCS["SIRGAS_UTM_Zone_20N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone21N",
    `PROJCS["SIRGAS_UTM_Zone_21N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone22N",
    `PROJCS["SIRGAS_UTM_Zone_22N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone11N",
    `PROJCS["SIRGAS_2000_UTM_Zone_11N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone12N",
    `PROJCS["SIRGAS_2000_UTM_Zone_12N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone13N",
    `PROJCS["SIRGAS_2000_UTM_Zone_13N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone14N",
    `PROJCS["SIRGAS_2000_UTM_Zone_14N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone15N",
    `PROJCS["SIRGAS_2000_UTM_Zone_15N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone16N",
    `PROJCS["SIRGAS_2000_UTM_Zone_16N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone17N",
    `PROJCS["SIRGAS_2000_UTM_Zone_17N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone18N",
    `PROJCS["SIRGAS_2000_UTM_Zone_18N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone19N",
    `PROJCS["SIRGAS_2000_UTM_Zone_19N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone20N",
    `PROJCS["SIRGAS_2000_UTM_Zone_20N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone21N",
    `PROJCS["SIRGAS_2000_UTM_Zone_21N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone22N",
    `PROJCS["SIRGAS_2000_UTM_Zone_22N",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone17S",
    `PROJCS["SIRGAS_2000_UTM_Zone_17S",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone18S",
    `PROJCS["SIRGAS_2000_UTM_Zone_18S",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone19S",
    `PROJCS["SIRGAS_2000_UTM_Zone_19S",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone20S",
    `PROJCS["SIRGAS_2000_UTM_Zone_20S",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone21S",
    `PROJCS["SIRGAS_2000_UTM_Zone_21S",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone22S",
    `PROJCS["SIRGAS_2000_UTM_Zone_22S",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone23S",
    `PROJCS["SIRGAS_2000_UTM_Zone_23S",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone24S",
    `PROJCS["SIRGAS_2000_UTM_Zone_24S",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGAS2000UTMZone25S",
    `PROJCS["SIRGAS_2000_UTM_Zone_25S",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone17N",
    `PROJCS["SIRGAS_UTM_Zone_17N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone18N",
    `PROJCS["SIRGAS_UTM_Zone_18N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone19N",
    `PROJCS["SIRGAS_UTM_Zone_19N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone20N",
    `PROJCS["SIRGAS_UTM_Zone_20N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone21N",
    `PROJCS["SIRGAS_UTM_Zone_21N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone22N",
    `PROJCS["SIRGAS_UTM_Zone_22N",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone17S",
    `PROJCS["SIRGAS_UTM_Zone_17S",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone18S",
    `PROJCS["SIRGAS_UTM_Zone_18S",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone19S",
    `PROJCS["SIRGAS_UTM_Zone_19S",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone20S",
    `PROJCS["SIRGAS_UTM_Zone_20S",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone21S",
    `PROJCS["SIRGAS_UTM_Zone_21S",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone22S",
    `PROJCS["SIRGAS_UTM_Zone_22S",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone23S",
    `PROJCS["SIRGAS_UTM_Zone_23S",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone24S",
    `PROJCS["SIRGAS_UTM_Zone_24S",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SIRGASUTMZone25S",
    `PROJCS["SIRGAS_UTM_Zone_25S",GEOGCS["GCS_SIRGAS",DATUM["D_SIRGAS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927StatePlaneMontanaNorthFIPS2501",
    `PROJCS["NAD_1927_StatePlane_Montana_North_FIPS_2501",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",47.85],PARAMETER["Standard_Parallel_2",48.71666666666667],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMontanaCentralFIPS2502",
    `PROJCS["NAD_1927_StatePlane_Montana_Central_FIPS_2502",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",46.45],PARAMETER["Standard_Parallel_2",47.88333333333333],PARAMETER["Latitude_Of_Origin",45.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneMontanaSouthFIPS2503",
    `PROJCS["NAD_1927_StatePlane_Montana_South_FIPS_2503",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",44.86666666666667],PARAMETER["Standard_Parallel_2",46.4],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNebraskaNorthFIPS2601",
    `PROJCS["NAD_1927_StatePlane_Nebraska_North_FIPS_2601",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",41.85],PARAMETER["Standard_Parallel_2",42.81666666666667],PARAMETER["Latitude_Of_Origin",41.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNebraskaSouthFIPS2602",
    `PROJCS["NAD_1927_StatePlane_Nebraska_South_FIPS_2602",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.5],PARAMETER["Standard_Parallel_1",40.28333333333333],PARAMETER["Standard_Parallel_2",41.71666666666667],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNevadaEastFIPS2701",
    `PROJCS["NAD_1927_StatePlane_Nevada_East_FIPS_2701",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNevadaCentralFIPS2702",
    `PROJCS["NAD_1927_StatePlane_Nevada_Central_FIPS_2702",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNevadaWestFIPS2703",
    `PROJCS["NAD_1927_StatePlane_Nevada_West_FIPS_2703",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNewHampshireFIPS2800",
    `PROJCS["NAD_1927_StatePlane_New_Hampshire_FIPS_2800",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNewJerseyFIPS2900",
    `PROJCS["NAD_1927_StatePlane_New_Jersey_FIPS_2900",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.66666666666667],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNewMexicoEastFIPS3001",
    `PROJCS["NAD_1927_StatePlane_New_Mexico_East_FIPS_3001",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNewMexicoCentralFIPS3002",
    `PROJCS["NAD_1927_StatePlane_New_Mexico_Central_FIPS_3002",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNewMexicoWestFIPS3003",
    `PROJCS["NAD_1927_StatePlane_New_Mexico_West_FIPS_3003",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNewYorkEastFIPS3101",
    `PROJCS["NAD_1927_StatePlane_New_York_East_FIPS_3101",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.33333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNewYorkCentralFIPS3102",
    `PROJCS["NAD_1927_StatePlane_New_York_Central_FIPS_3102",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNewYorkWestFIPS3103",
    `PROJCS["NAD_1927_StatePlane_New_York_West_FIPS_3103",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNewYorkLongIslandFIPS3104",
    `PROJCS["NAD_1927_StatePlane_New_York_Long_Island_FIPS_3104",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNorthCarolinaFIPS3200",
    `PROJCS["NAD_1927_StatePlane_North_Carolina_FIPS_3200",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNorthDakotaNorthFIPS3301",
    `PROJCS["NAD_1927_StatePlane_North_Dakota_North_FIPS_3301",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneNorthDakotaSouthFIPS3302",
    `PROJCS["NAD_1927_StatePlane_North_Dakota_South_FIPS_3302",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneOhioNorthFIPS3401",
    `PROJCS["NAD_1927_StatePlane_Ohio_North_FIPS_3401",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneOhioSouthFIPS3402",
    `PROJCS["NAD_1927_StatePlane_Ohio_South_FIPS_3402",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneOklahomaNorthFIPS3501",
    `PROJCS["NAD_1927_StatePlane_Oklahoma_North_FIPS_3501",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneOklahomaSouthFIPS3502",
    `PROJCS["NAD_1927_StatePlane_Oklahoma_South_FIPS_3502",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneOregonNorthFIPS3601",
    `PROJCS["NAD_1927_StatePlane_Oregon_North_FIPS_3601",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneOregonSouthFIPS3602",
    `PROJCS["NAD_1927_StatePlane_Oregon_South_FIPS_3602",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlanePennsylvaniaNorthFIPS3701",
    `PROJCS["NAD_1927_StatePlane_Pennsylvania_North_FIPS_3701",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlanePennsylvaniaSouthFIPS3702",
    `PROJCS["NAD_1927_StatePlane_Pennsylvania_South_FIPS_3702",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneRhodeIslandFIPS3800",
    `PROJCS["NAD_1927_StatePlane_Rhode_Island_FIPS_3800",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneSouthCarolinaNorthFIPS3901",
    `PROJCS["NAD_1927_StatePlane_South_Carolina_North_FIPS_3901",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",33.76666666666667],PARAMETER["Standard_Parallel_2",34.96666666666667],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneSouthCarolinaSouthFIPS3902",
    `PROJCS["NAD_1927_StatePlane_South_Carolina_South_FIPS_3902",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.33333333333334],PARAMETER["Standard_Parallel_2",33.66666666666666],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneSouthDakotaNorthFIPS4001",
    `PROJCS["NAD_1927_StatePlane_South_Dakota_North_FIPS_4001",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneSouthDakotaSouthFIPS4002",
    `PROJCS["NAD_1927_StatePlane_South_Dakota_South_FIPS_4002",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneTennesseeFIPS4100",
    `PROJCS["NAD_1927_StatePlane_Tennessee_FIPS_4100",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneTexasNorthFIPS4201",
    `PROJCS["NAD_1927_StatePlane_Texas_North_FIPS_4201",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneTexasNorthCentralFIPS4202",
    `PROJCS["NAD_1927_StatePlane_Texas_North_Central_FIPS_4202",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-97.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneTexasCentralFIPS4203",
    `PROJCS["NAD_1927_StatePlane_Texas_Central_FIPS_4203",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneTexasSouthCentralFIPS4204",
    `PROJCS["NAD_1927_StatePlane_Texas_South_Central_FIPS_4204",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneTexasSouthFIPS4205",
    `PROJCS["NAD_1927_StatePlane_Texas_South_FIPS_4205",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneUtahNorthFIPS4301",
    `PROJCS["NAD_1927_StatePlane_Utah_North_FIPS_4301",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneUtahCentralFIPS4302",
    `PROJCS["NAD_1927_StatePlane_Utah_Central_FIPS_4302",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneUtahSouthFIPS4303",
    `PROJCS["NAD_1927_StatePlane_Utah_South_FIPS_4303",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneVermontFIPS4400",
    `PROJCS["NAD_1927_StatePlane_Vermont_FIPS_4400",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneVirginiaNorthFIPS4501",
    `PROJCS["NAD_1927_StatePlane_Virginia_North_FIPS_4501",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneVirginiaSouthFIPS4502",
    `PROJCS["NAD_1927_StatePlane_Virginia_South_FIPS_4502",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWashingtonNorthFIPS4601",
    `PROJCS["NAD_1927_StatePlane_Washington_North_FIPS_4601",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWashingtonSouthFIPS4602",
    `PROJCS["NAD_1927_StatePlane_Washington_South_FIPS_4602",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWestVirginiaNorthFIPS4701",
    `PROJCS["NAD_1927_StatePlane_West_Virginia_North_FIPS_4701",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWestVirginiaSouthFIPS4702",
    `PROJCS["NAD_1927_StatePlane_West_Virginia_South_FIPS_4702",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWisconsinNorthFIPS4801",
    `PROJCS["NAD_1927_StatePlane_Wisconsin_North_FIPS_4801",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWisconsinCentralFIPS4802",
    `PROJCS["NAD_1927_StatePlane_Wisconsin_Central_FIPS_4802",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWisconsinSouthFIPS4803",
    `PROJCS["NAD_1927_StatePlane_Wisconsin_South_FIPS_4803",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWyomingEastFIPS4901",
    `PROJCS["NAD_1927_StatePlane_Wyoming_East_FIPS_4901",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",40.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWyomingEastCentralFIPS4902",
    `PROJCS["NAD_1927_StatePlane_Wyoming_East_Central_FIPS_4902",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",40.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWyomingWestCentralFIPS4903",
    `PROJCS["NAD_1927_StatePlane_Wyoming_West_Central_FIPS_4903",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",40.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneWyomingWestFIPS4904",
    `PROJCS["NAD_1927_StatePlane_Wyoming_West_FIPS_4904",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",40.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlanePuertoRicoFIPS5201",
    `PROJCS["NAD_1927_StatePlane_Puerto_Rico_FIPS_5201",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneVirginIslandsStCroixFIPS5202",
    `PROJCS["NAD_1927_StatePlane_Virgin_Islands_St_Croix_FIPS_5202",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927GuatemalaNorte",
    `PROJCS["NAD_1927_Guatemala_Norte",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",292209.579],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Standard_Parallel_1",16.81666666666667],PARAMETER["Scale_Factor",0.99992226],PARAMETER["Latitude_Of_Origin",16.81666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927GuatemalaSur",
    `PROJCS["NAD_1927_Guatemala_Sur",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",325992.681],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Standard_Parallel_1",14.9],PARAMETER["Scale_Factor",0.99989906],PARAMETER["Latitude_Of_Origin",14.9],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927BLMZone14N",
    `PROJCS["NAD_1927_BLM_Zone_14N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone15N",
    `PROJCS["NAD_1927_BLM_Zone_15N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone16N",
    `PROJCS["NAD_1927_BLM_Zone_16N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone17N",
    `PROJCS["NAD_1927_BLM_Zone_17N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone14N",
    `PROJCS["NAD_1927_BLM_Zone_14N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone15N",
    `PROJCS["NAD_1927_BLM_Zone_15N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone16N",
    `PROJCS["NAD_1927_BLM_Zone_16N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927BLMZone17N",
    `PROJCS["NAD_1927_BLM_Zone_17N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927MTM1",
    `PROJCS["NAD_1927_MTM_1",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-53.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927MTM2",
    `PROJCS["NAD_1927_MTM_2",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-56.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927MTM3",
    `PROJCS["NAD_1927_MTM_3",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-58.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927MTM4",
    `PROJCS["NAD_1927_MTM_4",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-61.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927MTM5",
    `PROJCS["NAD_1927_MTM_5",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-64.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927MTM6",
    `PROJCS["NAD_1927_MTM_6",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927QuebecLambert",
    `PROJCS["NAD_1927_Quebec_Lambert",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927StatePlaneLouisianaOffshoreFIPS1703",
    `PROJCS["NAD_1927_StatePlane_Louisiana_Offshore_FIPS_1703",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMontanaFIPS2500",
    `PROJCS["NAD_1983_StatePlane_Montana_FIPS_2500",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNebraskaFIPS2600",
    `PROJCS["NAD_1983_StatePlane_Nebraska_FIPS_2600",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNevadaEastFIPS2701",
    `PROJCS["NAD_1983_StatePlane_Nevada_East_FIPS_2701",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",8000000.0],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNevadaCentralFIPS2702",
    `PROJCS["NAD_1983_StatePlane_Nevada_Central_FIPS_2702",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNevadaWestFIPS2703",
    `PROJCS["NAD_1983_StatePlane_Nevada_West_FIPS_2703",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNewHampshireFIPS2800",
    `PROJCS["NAD_1983_StatePlane_New_Hampshire_FIPS_2800",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNewJerseyFIPS2900",
    `PROJCS["NAD_1983_StatePlane_New_Jersey_FIPS_2900",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNewMexicoEastFIPS3001",
    `PROJCS["NAD_1983_StatePlane_New_Mexico_East_FIPS_3001",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNewMexicoCentralFIPS3002",
    `PROJCS["NAD_1983_StatePlane_New_Mexico_Central_FIPS_3002",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNewMexicoWestFIPS3003",
    `PROJCS["NAD_1983_StatePlane_New_Mexico_West_FIPS_3003",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",830000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNewYorkEastFIPS3101",
    `PROJCS["NAD_1983_StatePlane_New_York_East_FIPS_3101",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNewYorkCentralFIPS3102",
    `PROJCS["NAD_1983_StatePlane_New_York_Central_FIPS_3102",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNewYorkWestFIPS3103",
    `PROJCS["NAD_1983_StatePlane_New_York_West_FIPS_3103",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",350000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNewYorkLongIslandFIPS3104",
    `PROJCS["NAD_1983_StatePlane_New_York_Long_Island_FIPS_3104",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNorthCarolinaFIPS3200",
    `PROJCS["NAD_1983_StatePlane_North_Carolina_FIPS_3200",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609601.22],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNorthDakotaNorthFIPS3301",
    `PROJCS["NAD_1983_StatePlane_North_Dakota_North_FIPS_3301",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNorthDakotaSouthFIPS3302",
    `PROJCS["NAD_1983_StatePlane_North_Dakota_South_FIPS_3302",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneOhioNorthFIPS3401",
    `PROJCS["NAD_1983_StatePlane_Ohio_North_FIPS_3401",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneOhioSouthFIPS3402",
    `PROJCS["NAD_1983_StatePlane_Ohio_South_FIPS_3402",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneOklahomaNorthFIPS3501",
    `PROJCS["NAD_1983_StatePlane_Oklahoma_North_FIPS_3501",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneOklahomaSouthFIPS3502",
    `PROJCS["NAD_1983_StatePlane_Oklahoma_South_FIPS_3502",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneOregonNorthFIPS3601",
    `PROJCS["NAD_1983_StatePlane_Oregon_North_FIPS_3601",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneOregonSouthFIPS3602",
    `PROJCS["NAD_1983_StatePlane_Oregon_South_FIPS_3602",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlanePennsylvaniaNorthFIPS3701",
    `PROJCS["NAD_1983_StatePlane_Pennsylvania_North_FIPS_3701",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlanePennsylvaniaSouthFIPS3702",
    `PROJCS["NAD_1983_StatePlane_Pennsylvania_South_FIPS_3702",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneRhodeIslandFIPS3800",
    `PROJCS["NAD_1983_StatePlane_Rhode_Island_FIPS_3800",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneSouthCarolinaFIPS3900",
    `PROJCS["NAD_1983_StatePlane_South_Carolina_FIPS_3900",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609600.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneSouthDakotaNorthFIPS4001",
    `PROJCS["NAD_1983_StatePlane_South_Dakota_North_FIPS_4001",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneSouthDakotaSouthFIPS4002",
    `PROJCS["NAD_1983_StatePlane_South_Dakota_South_FIPS_4002",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneTennesseeFIPS4100",
    `PROJCS["NAD_1983_StatePlane_Tennessee_FIPS_4100",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneTexasNorthFIPS4201",
    `PROJCS["NAD_1983_StatePlane_Texas_North_FIPS_4201",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneTexasNorthCentralFIPS4202",
    `PROJCS["NAD_1983_StatePlane_Texas_North_Central_FIPS_4202",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneTexasCentralFIPS4203",
    `PROJCS["NAD_1983_StatePlane_Texas_Central_FIPS_4203",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneTexasSouthCentralFIPS4204",
    `PROJCS["NAD_1983_StatePlane_Texas_South_Central_FIPS_4204",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneTexasSouthFIPS4205",
    `PROJCS["NAD_1983_StatePlane_Texas_South_FIPS_4205",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneUtahNorthFIPS4301",
    `PROJCS["NAD_1983_StatePlane_Utah_North_FIPS_4301",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneUtahCentralFIPS4302",
    `PROJCS["NAD_1983_StatePlane_Utah_Central_FIPS_4302",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneUtahSouthFIPS4303",
    `PROJCS["NAD_1983_StatePlane_Utah_South_FIPS_4303",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneVermontFIPS4400",
    `PROJCS["NAD_1983_StatePlane_Vermont_FIPS_4400",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneVirginiaNorthFIPS4501",
    `PROJCS["NAD_1983_StatePlane_Virginia_North_FIPS_4501",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneVirginiaSouthFIPS4502",
    `PROJCS["NAD_1983_StatePlane_Virginia_South_FIPS_4502",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWashingtonNorthFIPS4601",
    `PROJCS["NAD_1983_StatePlane_Washington_North_FIPS_4601",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWashingtonSouthFIPS4602",
    `PROJCS["NAD_1983_StatePlane_Washington_South_FIPS_4602",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWestVirginiaNorthFIPS4701",
    `PROJCS["NAD_1983_StatePlane_West_Virginia_North_FIPS_4701",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWestVirginiaSouthFIPS4702",
    `PROJCS["NAD_1983_StatePlane_West_Virginia_South_FIPS_4702",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWisconsinNorthFIPS4801",
    `PROJCS["NAD_1983_StatePlane_Wisconsin_North_FIPS_4801",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWisconsinCentralFIPS4802",
    `PROJCS["NAD_1983_StatePlane_Wisconsin_Central_FIPS_4802",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWisconsinSouthFIPS4803",
    `PROJCS["NAD_1983_StatePlane_Wisconsin_South_FIPS_4803",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWyomingEastFIPS4901",
    `PROJCS["NAD_1983_StatePlane_Wyoming_East_FIPS_4901",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWyomingEastCentralFIPS4902",
    `PROJCS["NAD_1983_StatePlane_Wyoming_East_Central_FIPS_4902",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWyomingWestCentralFIPS4903",
    `PROJCS["NAD_1983_StatePlane_Wyoming_West_Central_FIPS_4903",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneWyomingWestFIPS4904",
    `PROJCS["NAD_1983_StatePlane_Wyoming_West_FIPS_4904",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983WyLAM",
    `PROJCS["NAD_1983_WyLAM",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-107.5],PARAMETER["Standard_Parallel_1",41.0],PARAMETER["Standard_Parallel_2",45.0],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlanePuertoRicoVirginIslandsFIPS5200",
    `PROJCS["NAD_1983_StatePlane_Puerto_Rico_Virgin_Islands_FIPS_5200",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983BLMZone14NftUS",
    `PROJCS["NAD_1983_BLM_Zone_14N_ftUS",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone15NftUS",
    `PROJCS["NAD_1983_BLM_Zone_15N_ftUS",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone16NftUS",
    `PROJCS["NAD_1983_BLM_Zone_16N_ftUS",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983BLMZone17NftUS",
    `PROJCS["NAD_1983_BLM_Zone_17N_ftUS",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983MTM2SCoPQ",
    `PROJCS["NAD_1983_MTM_2_SCoPQ",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-55.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM1",
    `PROJCS["NAD_1983_MTM_1",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-53.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM2",
    `PROJCS["NAD_1983_MTM_2",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-56.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM3",
    `PROJCS["NAD_1983_MTM_3",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-58.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM4",
    `PROJCS["NAD_1983_MTM_4",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-61.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM5",
    `PROJCS["NAD_1983_MTM_5",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-64.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM6",
    `PROJCS["NAD_1983_MTM_6",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM7",
    `PROJCS["NAD_1983_MTM_7",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM8",
    `PROJCS["NAD_1983_MTM_8",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-73.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM9",
    `PROJCS["NAD_1983_MTM_9",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM10",
    `PROJCS["NAD_1983_MTM_10",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM11",
    `PROJCS["NAD_1983_MTM_11",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM12",
    `PROJCS["NAD_1983_MTM_12",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM13",
    `PROJCS["NAD_1983_MTM_13",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM14",
    `PROJCS["NAD_1983_MTM_14",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM15",
    `PROJCS["NAD_1983_MTM_15",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM16",
    `PROJCS["NAD_1983_MTM_16",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MTM17",
    `PROJCS["NAD_1983_MTM_17",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983QuebecLambert",
    `PROJCS["NAD_1983_Quebec_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Standard_Parallel_1",46.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneLouisianaOffshoreFIPS1703",
    `PROJCS["NAD_1983_StatePlane_Louisiana_Offshore_FIPS_1703",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.5],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone1N",
    `PROJCS["WGS_1972_UTM_Zone_1N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone2N",
    `PROJCS["WGS_1972_UTM_Zone_2N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone3N",
    `PROJCS["WGS_1972_UTM_Zone_3N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone4N",
    `PROJCS["WGS_1972_UTM_Zone_4N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone5N",
    `PROJCS["WGS_1972_UTM_Zone_5N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone6N",
    `PROJCS["WGS_1972_UTM_Zone_6N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone7N",
    `PROJCS["WGS_1972_UTM_Zone_7N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone8N",
    `PROJCS["WGS_1972_UTM_Zone_8N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone9N",
    `PROJCS["WGS_1972_UTM_Zone_9N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone10N",
    `PROJCS["WGS_1972_UTM_Zone_10N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone11N",
    `PROJCS["WGS_1972_UTM_Zone_11N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone12N",
    `PROJCS["WGS_1972_UTM_Zone_12N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone13N",
    `PROJCS["WGS_1972_UTM_Zone_13N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone14N",
    `PROJCS["WGS_1972_UTM_Zone_14N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone15N",
    `PROJCS["WGS_1972_UTM_Zone_15N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone16N",
    `PROJCS["WGS_1972_UTM_Zone_16N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone17N",
    `PROJCS["WGS_1972_UTM_Zone_17N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone18N",
    `PROJCS["WGS_1972_UTM_Zone_18N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone19N",
    `PROJCS["WGS_1972_UTM_Zone_19N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone20N",
    `PROJCS["WGS_1972_UTM_Zone_20N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone21N",
    `PROJCS["WGS_1972_UTM_Zone_21N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone22N",
    `PROJCS["WGS_1972_UTM_Zone_22N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone23N",
    `PROJCS["WGS_1972_UTM_Zone_23N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone24N",
    `PROJCS["WGS_1972_UTM_Zone_24N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone25N",
    `PROJCS["WGS_1972_UTM_Zone_25N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone26N",
    `PROJCS["WGS_1972_UTM_Zone_26N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone27N",
    `PROJCS["WGS_1972_UTM_Zone_27N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone28N",
    `PROJCS["WGS_1972_UTM_Zone_28N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone29N",
    `PROJCS["WGS_1972_UTM_Zone_29N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone30N",
    `PROJCS["WGS_1972_UTM_Zone_30N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone31N",
    `PROJCS["WGS_1972_UTM_Zone_31N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone32N",
    `PROJCS["WGS_1972_UTM_Zone_32N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone33N",
    `PROJCS["WGS_1972_UTM_Zone_33N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone34N",
    `PROJCS["WGS_1972_UTM_Zone_34N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone35N",
    `PROJCS["WGS_1972_UTM_Zone_35N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone36N",
    `PROJCS["WGS_1972_UTM_Zone_36N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone37N",
    `PROJCS["WGS_1972_UTM_Zone_37N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone38N",
    `PROJCS["WGS_1972_UTM_Zone_38N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone39N",
    `PROJCS["WGS_1972_UTM_Zone_39N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone40N",
    `PROJCS["WGS_1972_UTM_Zone_40N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone41N",
    `PROJCS["WGS_1972_UTM_Zone_41N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone42N",
    `PROJCS["WGS_1972_UTM_Zone_42N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone43N",
    `PROJCS["WGS_1972_UTM_Zone_43N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone44N",
    `PROJCS["WGS_1972_UTM_Zone_44N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone45N",
    `PROJCS["WGS_1972_UTM_Zone_45N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone46N",
    `PROJCS["WGS_1972_UTM_Zone_46N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone47N",
    `PROJCS["WGS_1972_UTM_Zone_47N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone48N",
    `PROJCS["WGS_1972_UTM_Zone_48N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone49N",
    `PROJCS["WGS_1972_UTM_Zone_49N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone50N",
    `PROJCS["WGS_1972_UTM_Zone_50N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone51N",
    `PROJCS["WGS_1972_UTM_Zone_51N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone52N",
    `PROJCS["WGS_1972_UTM_Zone_52N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone53N",
    `PROJCS["WGS_1972_UTM_Zone_53N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone54N",
    `PROJCS["WGS_1972_UTM_Zone_54N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone55N",
    `PROJCS["WGS_1972_UTM_Zone_55N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone56N",
    `PROJCS["WGS_1972_UTM_Zone_56N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone57N",
    `PROJCS["WGS_1972_UTM_Zone_57N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone58N",
    `PROJCS["WGS_1972_UTM_Zone_58N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone59N",
    `PROJCS["WGS_1972_UTM_Zone_59N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone60N",
    `PROJCS["WGS_1972_UTM_Zone_60N",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone1S",
    `PROJCS["WGS_1972_UTM_Zone_1S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone2S",
    `PROJCS["WGS_1972_UTM_Zone_2S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone3S",
    `PROJCS["WGS_1972_UTM_Zone_3S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone4S",
    `PROJCS["WGS_1972_UTM_Zone_4S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone5S",
    `PROJCS["WGS_1972_UTM_Zone_5S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone6S",
    `PROJCS["WGS_1972_UTM_Zone_6S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone7S",
    `PROJCS["WGS_1972_UTM_Zone_7S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone8S",
    `PROJCS["WGS_1972_UTM_Zone_8S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone9S",
    `PROJCS["WGS_1972_UTM_Zone_9S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone10S",
    `PROJCS["WGS_1972_UTM_Zone_10S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone11S",
    `PROJCS["WGS_1972_UTM_Zone_11S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone12S",
    `PROJCS["WGS_1972_UTM_Zone_12S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone13S",
    `PROJCS["WGS_1972_UTM_Zone_13S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone14S",
    `PROJCS["WGS_1972_UTM_Zone_14S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone15S",
    `PROJCS["WGS_1972_UTM_Zone_15S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone16S",
    `PROJCS["WGS_1972_UTM_Zone_16S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone17S",
    `PROJCS["WGS_1972_UTM_Zone_17S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone18S",
    `PROJCS["WGS_1972_UTM_Zone_18S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone19S",
    `PROJCS["WGS_1972_UTM_Zone_19S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone20S",
    `PROJCS["WGS_1972_UTM_Zone_20S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone21S",
    `PROJCS["WGS_1972_UTM_Zone_21S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone22S",
    `PROJCS["WGS_1972_UTM_Zone_22S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone23S",
    `PROJCS["WGS_1972_UTM_Zone_23S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone24S",
    `PROJCS["WGS_1972_UTM_Zone_24S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone25S",
    `PROJCS["WGS_1972_UTM_Zone_25S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone26S",
    `PROJCS["WGS_1972_UTM_Zone_26S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone27S",
    `PROJCS["WGS_1972_UTM_Zone_27S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone28S",
    `PROJCS["WGS_1972_UTM_Zone_28S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone29S",
    `PROJCS["WGS_1972_UTM_Zone_29S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone30S",
    `PROJCS["WGS_1972_UTM_Zone_30S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone31S",
    `PROJCS["WGS_1972_UTM_Zone_31S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone32S",
    `PROJCS["WGS_1972_UTM_Zone_32S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone33S",
    `PROJCS["WGS_1972_UTM_Zone_33S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone34S",
    `PROJCS["WGS_1972_UTM_Zone_34S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone35S",
    `PROJCS["WGS_1972_UTM_Zone_35S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone36S",
    `PROJCS["WGS_1972_UTM_Zone_36S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone37S",
    `PROJCS["WGS_1972_UTM_Zone_37S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone38S",
    `PROJCS["WGS_1972_UTM_Zone_38S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone39S",
    `PROJCS["WGS_1972_UTM_Zone_39S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone40S",
    `PROJCS["WGS_1972_UTM_Zone_40S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone41S",
    `PROJCS["WGS_1972_UTM_Zone_41S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone42S",
    `PROJCS["WGS_1972_UTM_Zone_42S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone43S",
    `PROJCS["WGS_1972_UTM_Zone_43S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone44S",
    `PROJCS["WGS_1972_UTM_Zone_44S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone45S",
    `PROJCS["WGS_1972_UTM_Zone_45S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone46S",
    `PROJCS["WGS_1972_UTM_Zone_46S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone47S",
    `PROJCS["WGS_1972_UTM_Zone_47S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone48S",
    `PROJCS["WGS_1972_UTM_Zone_48S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone49S",
    `PROJCS["WGS_1972_UTM_Zone_49S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone50S",
    `PROJCS["WGS_1972_UTM_Zone_50S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone51S",
    `PROJCS["WGS_1972_UTM_Zone_51S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone52S",
    `PROJCS["WGS_1972_UTM_Zone_52S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone53S",
    `PROJCS["WGS_1972_UTM_Zone_53S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone54S",
    `PROJCS["WGS_1972_UTM_Zone_54S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone55S",
    `PROJCS["WGS_1972_UTM_Zone_55S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone56S",
    `PROJCS["WGS_1972_UTM_Zone_56S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone57S",
    `PROJCS["WGS_1972_UTM_Zone_57S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone58S",
    `PROJCS["WGS_1972_UTM_Zone_58S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone59S",
    `PROJCS["WGS_1972_UTM_Zone_59S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972UTMZone60S",
    `PROJCS["WGS_1972_UTM_Zone_60S",GEOGCS["GCS_WGS_1972",DATUM["D_WGS_1972",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone1N",
    `PROJCS["WGS_1972_BE_UTM_Zone_1N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone2N",
    `PROJCS["WGS_1972_BE_UTM_Zone_2N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone3N",
    `PROJCS["WGS_1972_BE_UTM_Zone_3N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone4N",
    `PROJCS["WGS_1972_BE_UTM_Zone_4N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone5N",
    `PROJCS["WGS_1972_BE_UTM_Zone_5N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone6N",
    `PROJCS["WGS_1972_BE_UTM_Zone_6N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone7N",
    `PROJCS["WGS_1972_BE_UTM_Zone_7N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone8N",
    `PROJCS["WGS_1972_BE_UTM_Zone_8N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone9N",
    `PROJCS["WGS_1972_BE_UTM_Zone_9N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone10N",
    `PROJCS["WGS_1972_BE_UTM_Zone_10N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone11N",
    `PROJCS["WGS_1972_BE_UTM_Zone_11N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone12N",
    `PROJCS["WGS_1972_BE_UTM_Zone_12N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone13N",
    `PROJCS["WGS_1972_BE_UTM_Zone_13N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone14N",
    `PROJCS["WGS_1972_BE_UTM_Zone_14N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone15N",
    `PROJCS["WGS_1972_BE_UTM_Zone_15N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone16N",
    `PROJCS["WGS_1972_BE_UTM_Zone_16N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone17N",
    `PROJCS["WGS_1972_BE_UTM_Zone_17N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone18N",
    `PROJCS["WGS_1972_BE_UTM_Zone_18N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone19N",
    `PROJCS["WGS_1972_BE_UTM_Zone_19N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone20N",
    `PROJCS["WGS_1972_BE_UTM_Zone_20N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone21N",
    `PROJCS["WGS_1972_BE_UTM_Zone_21N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone22N",
    `PROJCS["WGS_1972_BE_UTM_Zone_22N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone23N",
    `PROJCS["WGS_1972_BE_UTM_Zone_23N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone24N",
    `PROJCS["WGS_1972_BE_UTM_Zone_24N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone25N",
    `PROJCS["WGS_1972_BE_UTM_Zone_25N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone26N",
    `PROJCS["WGS_1972_BE_UTM_Zone_26N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone27N",
    `PROJCS["WGS_1972_BE_UTM_Zone_27N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone28N",
    `PROJCS["WGS_1972_BE_UTM_Zone_28N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone29N",
    `PROJCS["WGS_1972_BE_UTM_Zone_29N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone30N",
    `PROJCS["WGS_1972_BE_UTM_Zone_30N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone31N",
    `PROJCS["WGS_1972_BE_UTM_Zone_31N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone32N",
    `PROJCS["WGS_1972_BE_UTM_Zone_32N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone33N",
    `PROJCS["WGS_1972_BE_UTM_Zone_33N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone34N",
    `PROJCS["WGS_1972_BE_UTM_Zone_34N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone35N",
    `PROJCS["WGS_1972_BE_UTM_Zone_35N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone36N",
    `PROJCS["WGS_1972_BE_UTM_Zone_36N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone37N",
    `PROJCS["WGS_1972_BE_UTM_Zone_37N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone38N",
    `PROJCS["WGS_1972_BE_UTM_Zone_38N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone39N",
    `PROJCS["WGS_1972_BE_UTM_Zone_39N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone40N",
    `PROJCS["WGS_1972_BE_UTM_Zone_40N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone41N",
    `PROJCS["WGS_1972_BE_UTM_Zone_41N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone42N",
    `PROJCS["WGS_1972_BE_UTM_Zone_42N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone43N",
    `PROJCS["WGS_1972_BE_UTM_Zone_43N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone44N",
    `PROJCS["WGS_1972_BE_UTM_Zone_44N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone45N",
    `PROJCS["WGS_1972_BE_UTM_Zone_45N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone46N",
    `PROJCS["WGS_1972_BE_UTM_Zone_46N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone47N",
    `PROJCS["WGS_1972_BE_UTM_Zone_47N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone48N",
    `PROJCS["WGS_1972_BE_UTM_Zone_48N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone49N",
    `PROJCS["WGS_1972_BE_UTM_Zone_49N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone50N",
    `PROJCS["WGS_1972_BE_UTM_Zone_50N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone51N",
    `PROJCS["WGS_1972_BE_UTM_Zone_51N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone52N",
    `PROJCS["WGS_1972_BE_UTM_Zone_52N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone53N",
    `PROJCS["WGS_1972_BE_UTM_Zone_53N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone54N",
    `PROJCS["WGS_1972_BE_UTM_Zone_54N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone55N",
    `PROJCS["WGS_1972_BE_UTM_Zone_55N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone56N",
    `PROJCS["WGS_1972_BE_UTM_Zone_56N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone57N",
    `PROJCS["WGS_1972_BE_UTM_Zone_57N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone58N",
    `PROJCS["WGS_1972_BE_UTM_Zone_58N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone59N",
    `PROJCS["WGS_1972_BE_UTM_Zone_59N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone60N",
    `PROJCS["WGS_1972_BE_UTM_Zone_60N",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone1S",
    `PROJCS["WGS_1972_BE_UTM_Zone_1S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone2S",
    `PROJCS["WGS_1972_BE_UTM_Zone_2S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone3S",
    `PROJCS["WGS_1972_BE_UTM_Zone_3S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone4S",
    `PROJCS["WGS_1972_BE_UTM_Zone_4S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone5S",
    `PROJCS["WGS_1972_BE_UTM_Zone_5S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone6S",
    `PROJCS["WGS_1972_BE_UTM_Zone_6S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone7S",
    `PROJCS["WGS_1972_BE_UTM_Zone_7S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone8S",
    `PROJCS["WGS_1972_BE_UTM_Zone_8S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone9S",
    `PROJCS["WGS_1972_BE_UTM_Zone_9S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone10S",
    `PROJCS["WGS_1972_BE_UTM_Zone_10S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone11S",
    `PROJCS["WGS_1972_BE_UTM_Zone_11S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone12S",
    `PROJCS["WGS_1972_BE_UTM_Zone_12S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone13S",
    `PROJCS["WGS_1972_BE_UTM_Zone_13S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone14S",
    `PROJCS["WGS_1972_BE_UTM_Zone_14S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone15S",
    `PROJCS["WGS_1972_BE_UTM_Zone_15S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone16S",
    `PROJCS["WGS_1972_BE_UTM_Zone_16S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone17S",
    `PROJCS["WGS_1972_BE_UTM_Zone_17S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone18S",
    `PROJCS["WGS_1972_BE_UTM_Zone_18S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone19S",
    `PROJCS["WGS_1972_BE_UTM_Zone_19S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone20S",
    `PROJCS["WGS_1972_BE_UTM_Zone_20S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone21S",
    `PROJCS["WGS_1972_BE_UTM_Zone_21S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone22S",
    `PROJCS["WGS_1972_BE_UTM_Zone_22S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone23S",
    `PROJCS["WGS_1972_BE_UTM_Zone_23S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone24S",
    `PROJCS["WGS_1972_BE_UTM_Zone_24S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone25S",
    `PROJCS["WGS_1972_BE_UTM_Zone_25S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone26S",
    `PROJCS["WGS_1972_BE_UTM_Zone_26S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone27S",
    `PROJCS["WGS_1972_BE_UTM_Zone_27S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone28S",
    `PROJCS["WGS_1972_BE_UTM_Zone_28S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone29S",
    `PROJCS["WGS_1972_BE_UTM_Zone_29S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone30S",
    `PROJCS["WGS_1972_BE_UTM_Zone_30S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone31S",
    `PROJCS["WGS_1972_BE_UTM_Zone_31S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone32S",
    `PROJCS["WGS_1972_BE_UTM_Zone_32S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone33S",
    `PROJCS["WGS_1972_BE_UTM_Zone_33S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone34S",
    `PROJCS["WGS_1972_BE_UTM_Zone_34S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone35S",
    `PROJCS["WGS_1972_BE_UTM_Zone_35S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone36S",
    `PROJCS["WGS_1972_BE_UTM_Zone_36S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone37S",
    `PROJCS["WGS_1972_BE_UTM_Zone_37S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone38S",
    `PROJCS["WGS_1972_BE_UTM_Zone_38S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone39S",
    `PROJCS["WGS_1972_BE_UTM_Zone_39S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone40S",
    `PROJCS["WGS_1972_BE_UTM_Zone_40S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone41S",
    `PROJCS["WGS_1972_BE_UTM_Zone_41S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone42S",
    `PROJCS["WGS_1972_BE_UTM_Zone_42S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone43S",
    `PROJCS["WGS_1972_BE_UTM_Zone_43S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone44S",
    `PROJCS["WGS_1972_BE_UTM_Zone_44S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone45S",
    `PROJCS["WGS_1972_BE_UTM_Zone_45S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone46S",
    `PROJCS["WGS_1972_BE_UTM_Zone_46S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone47S",
    `PROJCS["WGS_1972_BE_UTM_Zone_47S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone48S",
    `PROJCS["WGS_1972_BE_UTM_Zone_48S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone49S",
    `PROJCS["WGS_1972_BE_UTM_Zone_49S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone50S",
    `PROJCS["WGS_1972_BE_UTM_Zone_50S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone51S",
    `PROJCS["WGS_1972_BE_UTM_Zone_51S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone52S",
    `PROJCS["WGS_1972_BE_UTM_Zone_52S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone53S",
    `PROJCS["WGS_1972_BE_UTM_Zone_53S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone54S",
    `PROJCS["WGS_1972_BE_UTM_Zone_54S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone55S",
    `PROJCS["WGS_1972_BE_UTM_Zone_55S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone56S",
    `PROJCS["WGS_1972_BE_UTM_Zone_56S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone57S",
    `PROJCS["WGS_1972_BE_UTM_Zone_57S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone58S",
    `PROJCS["WGS_1972_BE_UTM_Zone_58S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone59S",
    `PROJCS["WGS_1972_BE_UTM_Zone_59S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1972BEUTMZone60S",
    `PROJCS["WGS_1972_BE_UTM_Zone_60S",GEOGCS["GCS_WGS_1972_BE",DATUM["D_WGS_1972_BE",SPHEROID["WGS_1972",6378135.0,298.26]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone1N",
    `PROJCS["WGS_1984_UTM_Zone_1N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone2N",
    `PROJCS["WGS_1984_UTM_Zone_2N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone3N",
    `PROJCS["WGS_1984_UTM_Zone_3N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone4N",
    `PROJCS["WGS_1984_UTM_Zone_4N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone5N",
    `PROJCS["WGS_1984_UTM_Zone_5N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone6N",
    `PROJCS["WGS_1984_UTM_Zone_6N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone7N",
    `PROJCS["WGS_1984_UTM_Zone_7N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone8N",
    `PROJCS["WGS_1984_UTM_Zone_8N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone9N",
    `PROJCS["WGS_1984_UTM_Zone_9N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone10N",
    `PROJCS["WGS_1984_UTM_Zone_10N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone11N",
    `PROJCS["WGS_1984_UTM_Zone_11N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone12N",
    `PROJCS["WGS_1984_UTM_Zone_12N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone13N",
    `PROJCS["WGS_1984_UTM_Zone_13N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone14N",
    `PROJCS["WGS_1984_UTM_Zone_14N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone15N",
    `PROJCS["WGS_1984_UTM_Zone_15N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone16N",
    `PROJCS["WGS_1984_UTM_Zone_16N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone17N",
    `PROJCS["WGS_1984_UTM_Zone_17N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone18N",
    `PROJCS["WGS_1984_UTM_Zone_18N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone19N",
    `PROJCS["WGS_1984_UTM_Zone_19N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone20N",
    `PROJCS["WGS_1984_UTM_Zone_20N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone21N",
    `PROJCS["WGS_1984_UTM_Zone_21N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone22N",
    `PROJCS["WGS_1984_UTM_Zone_22N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone23N",
    `PROJCS["WGS_1984_UTM_Zone_23N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone24N",
    `PROJCS["WGS_1984_UTM_Zone_24N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone25N",
    `PROJCS["WGS_1984_UTM_Zone_25N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone26N",
    `PROJCS["WGS_1984_UTM_Zone_26N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone27N",
    `PROJCS["WGS_1984_UTM_Zone_27N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone28N",
    `PROJCS["WGS_1984_UTM_Zone_28N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone29N",
    `PROJCS["WGS_1984_UTM_Zone_29N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone30N",
    `PROJCS["WGS_1984_UTM_Zone_30N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone31N",
    `PROJCS["WGS_1984_UTM_Zone_31N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone32N",
    `PROJCS["WGS_1984_UTM_Zone_32N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone33N",
    `PROJCS["WGS_1984_UTM_Zone_33N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone34N",
    `PROJCS["WGS_1984_UTM_Zone_34N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone35N",
    `PROJCS["WGS_1984_UTM_Zone_35N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone36N",
    `PROJCS["WGS_1984_UTM_Zone_36N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone37N",
    `PROJCS["WGS_1984_UTM_Zone_37N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone38N",
    `PROJCS["WGS_1984_UTM_Zone_38N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone39N",
    `PROJCS["WGS_1984_UTM_Zone_39N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone40N",
    `PROJCS["WGS_1984_UTM_Zone_40N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone41N",
    `PROJCS["WGS_1984_UTM_Zone_41N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone42N",
    `PROJCS["WGS_1984_UTM_Zone_42N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone43N",
    `PROJCS["WGS_1984_UTM_Zone_43N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone44N",
    `PROJCS["WGS_1984_UTM_Zone_44N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone45N",
    `PROJCS["WGS_1984_UTM_Zone_45N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone46N",
    `PROJCS["WGS_1984_UTM_Zone_46N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone47N",
    `PROJCS["WGS_1984_UTM_Zone_47N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone48N",
    `PROJCS["WGS_1984_UTM_Zone_48N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone49N",
    `PROJCS["WGS_1984_UTM_Zone_49N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone50N",
    `PROJCS["WGS_1984_UTM_Zone_50N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone51N",
    `PROJCS["WGS_1984_UTM_Zone_51N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone52N",
    `PROJCS["WGS_1984_UTM_Zone_52N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone53N",
    `PROJCS["WGS_1984_UTM_Zone_53N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone54N",
    `PROJCS["WGS_1984_UTM_Zone_54N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone55N",
    `PROJCS["WGS_1984_UTM_Zone_55N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone56N",
    `PROJCS["WGS_1984_UTM_Zone_56N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone57N",
    `PROJCS["WGS_1984_UTM_Zone_57N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone58N",
    `PROJCS["WGS_1984_UTM_Zone_58N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone59N",
    `PROJCS["WGS_1984_UTM_Zone_59N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone60N",
    `PROJCS["WGS_1984_UTM_Zone_60N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UPSNorth",
    `PROJCS["UPS_North",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",0.994],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984PlateCarree",
    `PROJCS["WGS_1984_Plate_Carree",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Plate_Carree"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984BLMZone14NftUS",
    `PROJCS["WGS_1984_BLM_Zone_14N_ftUS",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "WGS1984BLMZone15NftUS",
    `PROJCS["WGS_1984_BLM_Zone_15N_ftUS",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "WGS1984BLMZone16NftUS",
    `PROJCS["WGS_1984_BLM_Zone_16N_ftUS",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "WGS1984BLMZone17NftUS",
    `PROJCS["WGS_1984_BLM_Zone_17N_ftUS",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "WGS1984UTMZone1S",
    `PROJCS["WGS_1984_UTM_Zone_1S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone2S",
    `PROJCS["WGS_1984_UTM_Zone_2S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone3S",
    `PROJCS["WGS_1984_UTM_Zone_3S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone4S",
    `PROJCS["WGS_1984_UTM_Zone_4S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone5S",
    `PROJCS["WGS_1984_UTM_Zone_5S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone6S",
    `PROJCS["WGS_1984_UTM_Zone_6S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone7S",
    `PROJCS["WGS_1984_UTM_Zone_7S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone8S",
    `PROJCS["WGS_1984_UTM_Zone_8S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone9S",
    `PROJCS["WGS_1984_UTM_Zone_9S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone10S",
    `PROJCS["WGS_1984_UTM_Zone_10S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone11S",
    `PROJCS["WGS_1984_UTM_Zone_11S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone12S",
    `PROJCS["WGS_1984_UTM_Zone_12S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone13S",
    `PROJCS["WGS_1984_UTM_Zone_13S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone14S",
    `PROJCS["WGS_1984_UTM_Zone_14S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone15S",
    `PROJCS["WGS_1984_UTM_Zone_15S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone16S",
    `PROJCS["WGS_1984_UTM_Zone_16S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone17S",
    `PROJCS["WGS_1984_UTM_Zone_17S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone18S",
    `PROJCS["WGS_1984_UTM_Zone_18S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone19S",
    `PROJCS["WGS_1984_UTM_Zone_19S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone20S",
    `PROJCS["WGS_1984_UTM_Zone_20S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone21S",
    `PROJCS["WGS_1984_UTM_Zone_21S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone22S",
    `PROJCS["WGS_1984_UTM_Zone_22S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone23S",
    `PROJCS["WGS_1984_UTM_Zone_23S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone24S",
    `PROJCS["WGS_1984_UTM_Zone_24S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone25S",
    `PROJCS["WGS_1984_UTM_Zone_25S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone26S",
    `PROJCS["WGS_1984_UTM_Zone_26S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone27S",
    `PROJCS["WGS_1984_UTM_Zone_27S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone28S",
    `PROJCS["WGS_1984_UTM_Zone_28S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone29S",
    `PROJCS["WGS_1984_UTM_Zone_29S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone30S",
    `PROJCS["WGS_1984_UTM_Zone_30S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone31S",
    `PROJCS["WGS_1984_UTM_Zone_31S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone32S",
    `PROJCS["WGS_1984_UTM_Zone_32S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone33S",
    `PROJCS["WGS_1984_UTM_Zone_33S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone34S",
    `PROJCS["WGS_1984_UTM_Zone_34S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone35S",
    `PROJCS["WGS_1984_UTM_Zone_35S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone36S",
    `PROJCS["WGS_1984_UTM_Zone_36S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone37S",
    `PROJCS["WGS_1984_UTM_Zone_37S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone38S",
    `PROJCS["WGS_1984_UTM_Zone_38S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone39S",
    `PROJCS["WGS_1984_UTM_Zone_39S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone40S",
    `PROJCS["WGS_1984_UTM_Zone_40S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone41S",
    `PROJCS["WGS_1984_UTM_Zone_41S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone42S",
    `PROJCS["WGS_1984_UTM_Zone_42S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone43S",
    `PROJCS["WGS_1984_UTM_Zone_43S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone44S",
    `PROJCS["WGS_1984_UTM_Zone_44S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone45S",
    `PROJCS["WGS_1984_UTM_Zone_45S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone46S",
    `PROJCS["WGS_1984_UTM_Zone_46S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone47S",
    `PROJCS["WGS_1984_UTM_Zone_47S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone48S",
    `PROJCS["WGS_1984_UTM_Zone_48S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone49S",
    `PROJCS["WGS_1984_UTM_Zone_49S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone50S",
    `PROJCS["WGS_1984_UTM_Zone_50S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone51S",
    `PROJCS["WGS_1984_UTM_Zone_51S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone52S",
    `PROJCS["WGS_1984_UTM_Zone_52S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone53S",
    `PROJCS["WGS_1984_UTM_Zone_53S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone54S",
    `PROJCS["WGS_1984_UTM_Zone_54S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone55S",
    `PROJCS["WGS_1984_UTM_Zone_55S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone56S",
    `PROJCS["WGS_1984_UTM_Zone_56S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone57S",
    `PROJCS["WGS_1984_UTM_Zone_57S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone58S",
    `PROJCS["WGS_1984_UTM_Zone_58S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone59S",
    `PROJCS["WGS_1984_UTM_Zone_59S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMZone60S",
    `PROJCS["WGS_1984_UTM_Zone_60S",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "UPSSouth",
    `PROJCS["UPS_South",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",0.994],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984TM36SE",
    `PROJCS["WGS_1984_TM_36_SE",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SpherePlateCarree",
    `PROJCS["Sphere_Plate_Carree",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Plate_Carree"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereEquidistantCylindrical",
    `PROJCS["Sphere_Equidistant_Cylindrical",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",60.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereMillerCylindrical",
    `PROJCS["Sphere_Miller_Cylindrical",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Miller_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SphereMercator",
    `PROJCS["Sphere_Mercator",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereMollweide",
    `PROJCS["Sphere_Mollweide",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mollweide"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereEckertV",
    `PROJCS["Sphere_Eckert_V",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Eckert_V"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereEckertIII",
    `PROJCS["Sphere_Eckert_III",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Eckert_III"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereEckertI",
    `PROJCS["Sphere_Eckert_I",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Eckert_I"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereBehrmann",
    `PROJCS["Sphere_Behrmann",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Behrmann"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereWinkelII",
    `PROJCS["Sphere_Winkel_II",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Winkel_II"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",50.45977625218981],UNIT["Meter",1.0]]`
  ],
  [
    "SpherePolyconic",
    `PROJCS["Sphere_Polyconic",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polyconic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereQuarticAuthalic",
    `PROJCS["Sphere_Quartic_Authalic",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Quartic_Authalic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SphereLoximuthal",
    `PROJCS["Sphere_Loximuthal",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Loximuthal"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Central_Parallel",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereBonne",
    `PROJCS["Sphere_Bonne",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Bonne"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",60.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereHotine",
    `PROJCS["Sphere_Hotine",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Two_Point_Natural_Origin"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Latitude_Of_1st_Point",0.0],PARAMETER["Latitude_Of_2nd_Point",60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Longitude_Of_1st_Point",0.0],PARAMETER["Longitude_Of_2nd_Point",60.0],PARAMETER["Latitude_Of_Center",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereStereographic",
    `PROJCS["Sphere_Stereographic",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereEquidistantConic",
    `PROJCS["Sphere_Equidistant_Conic",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",60.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereCassini",
    `PROJCS["Sphere_Cassini",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereVanderGrintenI",
    `PROJCS["Sphere_Van_der_Grinten_I",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Van_der_Grinten_I"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SphereTwoPointEquidistant",
    `PROJCS["Sphere_Two_Point_Equidistant",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Two_Point_Equidistant"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Latitude_Of_1st_Point",0.0],PARAMETER["Latitude_Of_2nd_Point",60.0],PARAMETER["Longitude_Of_1st_Point",0.0],PARAMETER["Longitude_Of_2nd_Point",60.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereAzimuthalEquidistant",
    `PROJCS["Sphere_Azimuthal_Equidistant",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereCylindricalEqualArea",
    `PROJCS["Sphere_Cylindrical_Equal_Area",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cylindrical_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereEqualEarthGreenwich",
    `PROJCS["Sphere_Equal_Earth_Greenwich",GEOGCS["GCS_Sphere_GRS_1980_Mean_Radius",DATUM["D_Sphere_GRS_1980_Mean_Radius",SPHEROID["Sphere_GRS_1980_Mean_Radius",6371008.7714,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equal_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]](lon)",east,ORDER[1]],AXIS["Latitude`
  ],
  [
    "SphereEqualEarthAmericas",
    `PROJCS["Sphere_Equal_Earth_Americas",GEOGCS["GCS_Sphere_GRS_1980_Mean_Radius",DATUM["D_Sphere_GRS_1980_Mean_Radius",SPHEROID["Sphere_GRS_1980_Mean_Radius",6371008.7714,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equal_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],UNIT["Meter",1.0]](lon)",east,ORDER[1]],AXIS["Latitude`
  ],
  [
    "SphereEqualEarthAsiaPacific",
    `PROJCS["Sphere_Equal_Earth_Asia_Pacific",GEOGCS["GCS_Sphere_GRS_1980_Mean_Radius",DATUM["D_Sphere_GRS_1980_Mean_Radius",SPHEROID["Sphere_GRS_1980_Mean_Radius",6371008.7714,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equal_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",150.0],UNIT["Meter",1.0]](lon)",east,ORDER[1]],AXIS["Latitude`
  ],
  [
    "SphereWinkelTripelNGS",
    `PROJCS["Sphere_Winkel_Tripel_NGS",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Winkel_Tripel"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",50.467],UNIT["Meter",1.0]]`
  ],
  [
    "SphereAitoff",
    `PROJCS["Sphere_Aitoff",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Aitoff"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereFlatPolarQuartic",
    `PROJCS["Sphere_Flat_Polar_Quartic",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Flat_Polar_Quartic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SphereTimes",
    `PROJCS["Sphere_Times",GEOGCS["GCS_Sphere",DATUM["D_Sphere",SPHEROID["Sphere",6371000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Times"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereVerticalPerspective",
    `PROJCS["Sphere_Vertical_Perspective",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Vertical_Near_Side_Perspective"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Longitude_Of_Center",0.0],PARAMETER["Latitude_Of_Center",0.0],PARAMETER["Height",35800000.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereWagnerIV",
    `PROJCS["Sphere_Wagner_IV",GEOGCS["GCS_Sphere_GRS_1980_Authalic",DATUM["D_Sphere_GRS_1980_Authalic",SPHEROID["Sphere_GRS_1980_Authalic",6371007.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Wagner_IV"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereWagnerV",
    `PROJCS["Sphere_Wagner_V",GEOGCS["GCS_Sphere_GRS_1980_Mean_Radius",DATUM["D_Sphere_GRS_1980_Mean_Radius",SPHEROID["Sphere_GRS_1980_Mean_Radius",6371008.7714,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Wagner_V"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereWagnerVII",
    `PROJCS["Sphere_Wagner_VII",GEOGCS["GCS_Sphere_GRS_1980_Authalic",DATUM["D_Sphere_GRS_1980_Authalic",SPHEROID["Sphere_GRS_1980_Authalic",6371007.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Wagner_VII"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereNaturalEarth",
    `PROJCS["Sphere_Natural_Earth",GEOGCS["GCS_Sphere_GRS_1980_Mean_Radius",DATUM["D_Sphere_GRS_1980_Mean_Radius",SPHEROID["Sphere_GRS_1980_Mean_Radius",6371008.7714,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Natural_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereNaturalEarthII",
    `PROJCS["Sphere_Natural_Earth_II",GEOGCS["GCS_Sphere_GRS_1980_Mean_Radius",DATUM["D_Sphere_GRS_1980_Mean_Radius",SPHEROID["Sphere_GRS_1980_Mean_Radius",6371008.7714,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Natural_Earth_II"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SpherePatterson",
    `PROJCS["Sphere_Patterson",GEOGCS["GCS_Sphere_GRS_1980_Mean_Radius",DATUM["D_Sphere_GRS_1980_Mean_Radius",SPHEROID["Sphere_GRS_1980_Mean_Radius",6371008.7714,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Patterson"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SphereCompactMiller",
    `PROJCS["Sphere_Compact_Miller",GEOGCS["GCS_Sphere_GRS_1980_Mean_Radius",DATUM["D_Sphere_GRS_1980_Mean_Radius",SPHEROID["Sphere_GRS_1980_Mean_Radius",6371008.7714,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Compact_Miller"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldPlateCarree",
    `PROJCS["World_Plate_Carree",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Plate_Carree"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldEquidistantCylindrical",
    `PROJCS["World_Equidistant_Cylindrical",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",60.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldMillerCylindrical",
    `PROJCS["World_Miller_Cylindrical",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Miller_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldMercator",
    `PROJCS["World_Mercator",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldSinusoidal",
    `PROJCS["World_Sinusoidal",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Sinusoidal"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldMollweide",
    `PROJCS["World_Mollweide",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mollweide"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldEckertVI",
    `PROJCS["World_Eckert_VI",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Eckert_VI"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldEckertV",
    `PROJCS["World_Eckert_V",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Eckert_V"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldEckertIV",
    `PROJCS["World_Eckert_IV",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Eckert_IV"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldEckertIII",
    `PROJCS["World_Eckert_III",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Eckert_III"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldEckertII",
    `PROJCS["World_Eckert_II",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Eckert_II"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldEckertI",
    `PROJCS["World_Eckert_I",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Eckert_I"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldGallStereographic",
    `PROJCS["World_Gall_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gall_Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldBehrmann",
    `PROJCS["World_Behrmann",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Behrmann"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldWinkelI",
    `PROJCS["World_Winkel_I",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Winkel_I"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",50.45977625218981],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldWinkelII",
    `PROJCS["World_Winkel_II",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Winkel_II"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",50.45977625218981],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldPolyconic",
    `PROJCS["World_Polyconic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polyconic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldQuarticAuthalic",
    `PROJCS["World_Quartic_Authalic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Quartic_Authalic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldLoximuthal",
    `PROJCS["World_Loximuthal",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Loximuthal"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Central_Parallel",40.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldBonne",
    `PROJCS["World_Bonne",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Bonne"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",60.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldHotine",
    `PROJCS["World_Hotine",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Two_Point_Natural_Origin"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Latitude_Of_1st_Point",0.0],PARAMETER["Latitude_Of_2nd_Point",60.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Longitude_Of_1st_Point",0.0],PARAMETER["Longitude_Of_2nd_Point",60.0],PARAMETER["Latitude_Of_Center",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldStereographic",
    `PROJCS["World_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldEquidistantConic",
    `PROJCS["World_Equidistant_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",60.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldCassini",
    `PROJCS["World_Cassini",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldVanderGrintenI",
    `PROJCS["World_Van_der_Grinten_I",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Van_der_Grinten_I"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldRobinson",
    `PROJCS["World_Robinson",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Robinson"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldTwoPointEquidistant",
    `PROJCS["World_Two_Point_Equidistant",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Two_Point_Equidistant"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Latitude_Of_1st_Point",0.0],PARAMETER["Latitude_Of_2nd_Point",60.0],PARAMETER["Longitude_Of_1st_Point",0.0],PARAMETER["Longitude_Of_2nd_Point",60.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldAzimuthalEquidistant",
    `PROJCS["World_Azimuthal_Equidistant",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldCylindricalEqualArea",
    `PROJCS["World_Cylindrical_Equal_Area",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cylindrical_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984EqualEarthGreenwich",
    `PROJCS["WGS_1984_Equal_Earth_Greenwich",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equal_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EqualEarthAmericas",
    `PROJCS["WGS_1984_Equal_Earth_Americas",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equal_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984EqualEarthAsiaPacific",
    `PROJCS["WGS_1984_Equal_Earth_Asia_Pacific",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equal_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",150.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldWinkelTripelNGS",
    `PROJCS["World_Winkel_Tripel_NGS",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Winkel_Tripel"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",50.467],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldAitoff",
    `PROJCS["World_Aitoff",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Aitoff"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldHammerAitoff",
    `PROJCS["World_Hammer_Aitoff",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hammer_Aitoff"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldFlatPolarQuartic",
    `PROJCS["World_Flat_Polar_Quartic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Flat_Polar_Quartic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldCrasterParabolic",
    `PROJCS["World_Craster_Parabolic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Craster_Parabolic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldTimes",
    `PROJCS["World_Times",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Times"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldVerticalPerspective",
    `PROJCS["World_Vertical_Perspective",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Vertical_Near_Side_Perspective"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Longitude_Of_Center",0.0],PARAMETER["Latitude_Of_Center",0.0],PARAMETER["Height",35800000.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldFuller",
    `PROJCS["World_Fuller",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Fuller"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Option",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WorldCube",
    `PROJCS["World_Cube",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cube"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Option",1.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldGoodeHomolosineLand",
    `PROJCS["World_Goode_Homolosine_Land",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Goode_Homolosine"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Option",1.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldGoodeHomolosineOcean",
    `PROJCS["World_Goode_Homolosine_Ocean",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Goode_Homolosine"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.0],PARAMETER["Option",2.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldWagnerIV",
    `PROJCS["World_Wagner_IV",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Wagner_IV"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldWagnerV",
    `PROJCS["World_Wagner_V",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Wagner_V"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldWagnerVII",
    `PROJCS["World_Wagner_VII",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Wagner_VII"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldNaturalEarth",
    `PROJCS["World_Natural_Earth",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Natural_Earth"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldNaturalEarthII",
    `PROJCS["World_Natural_Earth_II",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Natural_Earth_II"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldPatterson",
    `PROJCS["World_Patterson",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Patterson"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WorldCompactMiller",
    `PROJCS["World_Compact_Miller",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Compact_Miller"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984PeircequincuncialNorthPolesquare",
    `PROJCS["WGS_1984_Peirce_quincuncial_North_Pole_square",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Peirce_Quincuncial"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",90.0],PARAMETER["Option",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984PeircequincuncialNorthPolediamond",
    `PROJCS["WGS_1984_Peirce_quincuncial_North_Pole_diamond",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Peirce_Quincuncial"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",90.0],PARAMETER["Option",1.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984AdamsSquareII",
    `PROJCS["WGS_1984_Adams_Square_II",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Adams_Square_II"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",0.0],PARAMETER["Longitude_Of_Center",0.0],PARAMETER["Latitude_Of_Center",0.0],PARAMETER["XY_Plane_Rotation",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984SpilhausOceanMapinSquare",
    `PROJCS["WGS_1984_Spilhaus_Ocean_Map_in_Square",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Adams_Square_II"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",40.17823482],PARAMETER["Longitude_Of_Center",66.94970198],PARAMETER["Latitude_Of_Center",-49.56371678],PARAMETER["XY_Plane_Rotation",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ToblerCylindricalI",
    `PROJCS["WGS_1984_Tobler_Cylindrical_I",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Tobler_Cylindrical_I"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ToblerCylindricalII",
    `PROJCS["WGS_1984_Tobler_Cylindrical_II",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Tobler_Cylindrical_II"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "NAD1927StatePlaneGuamFIPS5400",
    `PROJCS["NAD_1927_StatePlane_Guam_FIPS_5400",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polyconic"],PARAMETER["False_Easting",164041.6666666667],PARAMETER["False_Northing",164041.6666666667],PARAMETER["Central_Meridian",144.7487507055556],PARAMETER["Latitude_Of_Origin",13.47246635277778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "AmericanSamoa1962StatePlaneAmericanSamoaFIPS5300",
    `PROJCS["American_Samoa_1962_StatePlane_American_Samoa_FIPS_5300",GEOGCS["GCS_American_Samoa_1962",DATUM["D_American_Samoa_1962",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",312234.65],PARAMETER["Central_Meridian",-170.0],PARAMETER["Standard_Parallel_1",-14.26666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-14.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneGuamFIPS5400",
    `PROJCS["NAD_1983_StatePlane_Guam_FIPS_5400",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polyconic"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",144.7487507055556],PARAMETER["Latitude_Of_Origin",13.47246635277778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneKentuckyFIPS1600",
    `PROJCS["NAD_1983_StatePlane_Kentucky_FIPS_1600",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "CanadaAlbersEqualAreaConic",
    `PROJCS["Canada_Albers_Equal_Area_Conic",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",50.0],PARAMETER["Standard_Parallel_2",70.0],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "CanadaLambertConformalConic",
    `PROJCS["Canada_Lambert_Conformal_Conic",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",50.0],PARAMETER["Standard_Parallel_2",70.0],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "USAContiguousAlbersEqualAreaConic",
    `PROJCS["USA_Contiguous_Albers_Equal_Area_Conic",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",29.5],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "USAContiguousLambertConformalConic",
    `PROJCS["USA_Contiguous_Lambert_Conformal_Conic",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",33.0],PARAMETER["Standard_Parallel_2",45.0],PARAMETER["Latitude_Of_Origin",39.0],UNIT["Meter",1.0]]`
  ],
  [
    "USAContiguousEquidistantConic",
    `PROJCS["USA_Contiguous_Equidistant_Conic",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",33.0],PARAMETER["Standard_Parallel_2",45.0],PARAMETER["Latitude_Of_Origin",39.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983AlaskaAlbers",
    `PROJCS["NAD_1983_Alaska_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Meter",1.0]]`
  ],
  [
    "HawaiiAlbersEqualAreaConic",
    `PROJCS["Hawaii_Albers_Equal_Area_Conic",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-157.0],PARAMETER["Standard_Parallel_1",8.0],PARAMETER["Standard_Parallel_2",18.0],PARAMETER["Latitude_Of_Origin",13.0],UNIT["Meter",1.0]]`
  ],
  [
    "NorthAmericaAlbersEqualAreaConic",
    `PROJCS["North_America_Albers_Equal_Area_Conic",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",20.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NorthAmericaLambertConformalConic",
    `PROJCS["North_America_Lambert_Conformal_Conic",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",20.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NorthAmericaEquidistantConic",
    `PROJCS["North_America_Equidistant_Conic",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",20.0],PARAMETER["Standard_Parallel_2",60.0],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "AfricaSinusoidal",
    `PROJCS["Africa_Sinusoidal",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Sinusoidal"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "AsiaLambertConformalConic",
    `PROJCS["Asia_Lambert_Conformal_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Standard_Parallel_1",30.0],PARAMETER["Standard_Parallel_2",62.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "EuropeAlbersEqualAreaConic",
    `PROJCS["Europe_Albers_Equal_Area_Conic",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",62.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "EuropeLambertConformalConic",
    `PROJCS["Europe_Lambert_Conformal_Conic",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",62.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "SouthAmericaLambertConformalConic",
    `PROJCS["South_America_Lambert_Conformal_Conic",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-60.0],PARAMETER["Standard_Parallel_1",-5.0],PARAMETER["Standard_Parallel_2",-42.0],PARAMETER["Latitude_Of_Origin",-32.0],UNIT["Meter",1.0]]`
  ],
  [
    "NorthPoleAzimuthalEquidistant",
    `PROJCS["North_Pole_Azimuthal_Equidistant",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "NorthPoleLambertAzimuthalEqualArea",
    `PROJCS["North_Pole_Lambert_Azimuthal_Equal_Area",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "NorthPoleStereographic",
    `PROJCS["North_Pole_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SouthPoleAzimuthalEquidistant",
    `PROJCS["South_Pole_Azimuthal_Equidistant",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SouthPoleLambertAzimuthalEqualArea",
    `PROJCS["South_Pole_Lambert_Azimuthal_Equal_Area",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SouthPoleStereographic",
    `PROJCS["South_Pole_Stereographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "AfricaAlbersEqualAreaConic",
    `PROJCS["Africa_Albers_Equal_Area_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Standard_Parallel_1",20.0],PARAMETER["Standard_Parallel_2",-23.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AfricaEquidistantConic",
    `PROJCS["Africa_Equidistant_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Standard_Parallel_1",20.0],PARAMETER["Standard_Parallel_2",-23.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AfricaLambertConformalConic",
    `PROJCS["Africa_Lambert_Conformal_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Standard_Parallel_1",20.0],PARAMETER["Standard_Parallel_2",-23.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AsiaNorthAlbersEqualAreaConic",
    `PROJCS["Asia_North_Albers_Equal_Area_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",95.0],PARAMETER["Standard_Parallel_1",15.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "AsiaNorthEquidistantConic",
    `PROJCS["Asia_North_Equidistant_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",95.0],PARAMETER["Standard_Parallel_1",15.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "AsiaNorthLambertConformalConic",
    `PROJCS["Asia_North_Lambert_Conformal_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",95.0],PARAMETER["Standard_Parallel_1",15.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "AsiaSouthAlbersEqualAreaConic",
    `PROJCS["Asia_South_Albers_Equal_Area_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",125.0],PARAMETER["Standard_Parallel_1",7.0],PARAMETER["Standard_Parallel_2",-32.0],PARAMETER["Latitude_Of_Origin",-15.0],UNIT["Meter",1.0]]`
  ],
  [
    "AsiaSouthEquidistantConic",
    `PROJCS["Asia_South_Equidistant_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",125.0],PARAMETER["Standard_Parallel_1",7.0],PARAMETER["Standard_Parallel_2",-32.0],PARAMETER["Latitude_Of_Origin",-15.0],UNIT["Meter",1.0]]`
  ],
  [
    "AsiaSouthLambertConformalConic",
    `PROJCS["Asia_South_Lambert_Conformal_Conic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",125.0],PARAMETER["Standard_Parallel_1",7.0],PARAMETER["Standard_Parallel_2",-32.0],PARAMETER["Latitude_Of_Origin",-15.0],UNIT["Meter",1.0]]`
  ],
  [
    "EuropeEquidistantConic",
    `PROJCS["Europe_Equidistant_Conic",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",62.0],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "SouthAmericaEquidistantConic",
    `PROJCS["South_America_Equidistant_Conic",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-60.0],PARAMETER["Standard_Parallel_1",-5.0],PARAMETER["Standard_Parallel_2",-42.0],PARAMETER["Latitude_Of_Origin",-32.0],UNIT["Meter",1.0]]`
  ],
  [
    "SouthAmericaAlbersEqualAreaConic",
    `PROJCS["South_America_Albers_Equal_Area_Conic",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-60.0],PARAMETER["Standard_Parallel_1",-5.0],PARAMETER["Standard_Parallel_2",-42.0],PARAMETER["Latitude_Of_Origin",-32.0],UNIT["Meter",1.0]]`
  ],
  [
    "NorthPoleGnomonic",
    `PROJCS["North_Pole_Gnomonic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gnomonic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Longitude_Of_Center",0.0],PARAMETER["Latitude_Of_Center",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "NorthPoleOrthographic",
    `PROJCS["North_Pole_Orthographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Orthographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Longitude_Of_Center",0.0],PARAMETER["Latitude_Of_Center",90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SouthPoleGnomonic",
    `PROJCS["South_Pole_Gnomonic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gnomonic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Longitude_Of_Center",0.0],PARAMETER["Latitude_Of_Center",-90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "SouthPoleOrthographic",
    `PROJCS["South_Pole_Orthographic",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Orthographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Longitude_Of_Center",0.0],PARAMETER["Latitude_Of_Center",-90.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "TheWorldFromSpace",
    `PROJCS["The_World_From_Space",GEOGCS["GCS_Sphere_ARC_INFO",DATUM["D_Sphere_ARC_INFO",SPHEROID["Sphere_ARC_INFO",6370997.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Orthographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Longitude_Of_Center",-72.5333333334],PARAMETER["Latitude_Of_Center",42.5333333333],UNIT["Meter",1.0]]`
  ],
  [
    "USAContiguousAlbersEqualAreaConicUSGSversion",
    `PROJCS["USA_Contiguous_Albers_Equal_Area_Conic_USGS_version",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",29.5],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",23.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985KoreaUnifiedCoordinateSystem",
    `PROJCS["Korean_1985_Korea_Unified_Coordinate_System",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",127.5],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "COBNAD832007",
    `PROJCS["COB_NAD83_2007",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.896666667],PARAMETER["False_Northing",0.24],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983USFSR9Albers",
    `PROJCS["NAD_1983_USFS_R9_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.25],PARAMETER["Standard_Parallel_1",38.25],PARAMETER["Standard_Parallel_2",47.25],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone20N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_20N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007UTMZone20N",
    `PROJCS["NAD_1983_NSRS2007_UTM_Zone_20N",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone20N",
    `PROJCS["NAD_1983_2011_UTM_Zone_20N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone59N",
    `PROJCS["NAD_1983_2011_UTM_Zone_59N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone60N",
    `PROJCS["NAD_1983_2011_UTM_Zone_60N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone1N",
    `PROJCS["NAD_1983_2011_UTM_Zone_1N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone2N",
    `PROJCS["NAD_1983_2011_UTM_Zone_2N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone3N",
    `PROJCS["NAD_1983_2011_UTM_Zone_3N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone4N",
    `PROJCS["NAD_1983_2011_UTM_Zone_4N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone5N",
    `PROJCS["NAD_1983_2011_UTM_Zone_5N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone6N",
    `PROJCS["NAD_1983_2011_UTM_Zone_6N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone7N",
    `PROJCS["NAD_1983_2011_UTM_Zone_7N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone8N",
    `PROJCS["NAD_1983_2011_UTM_Zone_8N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone9N",
    `PROJCS["NAD_1983_2011_UTM_Zone_9N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone10N",
    `PROJCS["NAD_1983_2011_UTM_Zone_10N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone11N",
    `PROJCS["NAD_1983_2011_UTM_Zone_11N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone12N",
    `PROJCS["NAD_1983_2011_UTM_Zone_12N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "D48SloveniaTM",
    `PROJCS["D48_Slovenia_TM",GEOGCS["GCS_D48",DATUM["D_D48",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "EverestModified1969RSOMalayaMeters",
    `PROJCS["Everest_Modified_1969_RSO_Malaya_Meters",GEOGCS["GCS_Everest_Modified_1969",DATUM["D_Everest_Modified_1969",SPHEROID["Everest_Modified_1969",6377295.664,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",804670.24],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",-36.97420943711801],PARAMETER["Longitude_Of_Center",102.25],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",-36.86989764584402],UNIT["Meter",1.0]]`
  ],
  [
    "KertauRSOMalayaMeters",
    `PROJCS["Kertau_RSO_Malaya_Meters",GEOGCS["GCS_Kertau",DATUM["D_Kertau",SPHEROID["Everest_1830_Modified",6377304.063,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",804671.299775],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",-36.97420943711801],PARAMETER["Longitude_Of_Center",102.25],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",-36.86989764584402],UNIT["Meter",1.0]]`
  ],
  [
    "KandawalaCeylonBeltMeters",
    `PROJCS["Kandawala_Ceylon_Belt_Meters",GEOGCS["GCS_Kandawala",DATUM["D_Kandawala",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",160933.56048],PARAMETER["False_Northing",160933.56048],PARAMETER["Central_Meridian",80.77171111111112],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",7.000480277777778],UNIT["Meter",1.0]]`
  ],
  [
    "KandawalaCeylonBeltIndianYards1937",
    `PROJCS["Kandawala_Ceylon_Belt_Indian_Yards_1937",GEOGCS["GCS_Kandawala",DATUM["D_Kandawala",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",176000.0],PARAMETER["False_Northing",176000.0],PARAMETER["Central_Meridian",80.77171111111112],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",7.000480277777778],UNIT["Yard_Indian_1937",0.91439523]]`
  ],
  [
    "S-JTSKKrovak",
    `PROJCS["S-JTSK_Krovak",GEOGCS["GCS_S_JTSK",DATUM["D_S_JTSK",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Krovak"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Pseudo_Standard_Parallel_1",78.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",30.28813975277778],PARAMETER["Longitude_Of_Center",24.83333333333333],PARAMETER["Latitude_Of_Center",49.5],PARAMETER["X_Scale",1.0],PARAMETER["Y_Scale",1.0],PARAMETER["XY_Plane_Rotation",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "S-JTSKFerroKrovakEastNorth",
    `PROJCS["S-JTSK_Ferro_Krovak_East_North",GEOGCS["GCS_S_JTSK_Ferro",DATUM["D_S_JTSK",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Ferro",-17.66666666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Krovak"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Pseudo_Standard_Parallel_1",78.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",30.28813975277778],PARAMETER["Longitude_Of_Center",42.5],PARAMETER["Latitude_Of_Center",49.5],PARAMETER["X_Scale",-1.0],PARAMETER["Y_Scale",1.0],PARAMETER["XY_Plane_Rotation",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "S-JTSKKrovakEastNorth",
    `PROJCS["S-JTSK_Krovak_East_North",GEOGCS["GCS_S_JTSK",DATUM["D_S_JTSK",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Krovak"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Pseudo_Standard_Parallel_1",78.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",30.28813975277778],PARAMETER["Longitude_Of_Center",24.83333333333333],PARAMETER["Latitude_Of_Center",49.5],PARAMETER["X_Scale",-1.0],PARAMETER["Y_Scale",1.0],PARAMETER["XY_Plane_Rotation",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "EMEP50KilometerGrid",
    `PROJCS["EMEP_50_Kilometer_Grid",GEOGCS["GCS_Sphere_EMEP",DATUM["D_Sphere_EMEP",SPHEROID["Sphere_EMEP",6370000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_North_Pole"],PARAMETER["False_Easting",8.0],PARAMETER["False_Northing",110.0],PARAMETER["Central_Meridian",-32.0],PARAMETER["Standard_Parallel_1",60.0],UNIT["50_Kilometers",50000.0]]`
  ],
  [
    "EMEP150KilometerGrid",
    `PROJCS["EMEP_150_Kilometer_Grid",GEOGCS["GCS_Sphere_EMEP",DATUM["D_Sphere_EMEP",SPHEROID["Sphere_EMEP",6370000.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic_North_Pole"],PARAMETER["False_Easting",3.0],PARAMETER["False_Northing",37.0],PARAMETER["Central_Meridian",-32.0],PARAMETER["Standard_Parallel_1",60.0],UNIT["150_Kilometers",150000.0]]`
  ],
  [
    "GuernseyGrid",
    `PROJCS["Guernsey_Grid",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",47000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",-2.416666666666667],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",49.5],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ACTGridAGCZone",
    `PROJCS["AGD_1966_ACT_Grid_AGC_Zone",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",4510193.4939],PARAMETER["Central_Meridian",149.0092948333333],PARAMETER["Scale_Factor",1.000086],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ISG542",
    `PROJCS["AGD_1966_ISG_54_2",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.99994],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ISG543",
    `PROJCS["AGD_1966_ISG_54_3",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",143.0],PARAMETER["Scale_Factor",0.99994],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ISG551",
    `PROJCS["AGD_1966_ISG_55_1",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",145.0],PARAMETER["Scale_Factor",0.99994],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ISG552",
    `PROJCS["AGD_1966_ISG_55_2",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.99994],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ISG553",
    `PROJCS["AGD_1966_ISG_55_3",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",149.0],PARAMETER["Scale_Factor",0.99994],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ISG561",
    `PROJCS["AGD_1966_ISG_56_1",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",151.0],PARAMETER["Scale_Factor",0.99994],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ISG562",
    `PROJCS["AGD_1966_ISG_56_2",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.99994],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ISG563",
    `PROJCS["AGD_1966_ISG_56_3",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",155.0],PARAMETER["Scale_Factor",0.99994],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002UnifiedCoordinateSystem",
    `PROJCS["KGD2002_Unified_Coordinate_System",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",127.5],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002WestBelt2010",
    `PROJCS["KGD2002_West_Belt_2010",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",125.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002CentralBelt2010",
    `PROJCS["KGD2002_Central_Belt_2010",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",127.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002EastBelt2010",
    `PROJCS["KGD2002_East_Belt_2010",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "KGD2002EastSeaBelt2010",
    `PROJCS["KGD2002_East_Sea_Belt_2010",GEOGCS["KGD2002",DATUM["D_Korea_Geodetic_Datum_2002",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985ModifiedKoreaWestBelt",
    `PROJCS["Korean_1985_Modified_Korea_West_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",125.0028902777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985ModifiedKoreaCentralBelt",
    `PROJCS["Korean_1985_Modified_Korea_Central_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",127.0028902777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985ModifiedKoreaCentralBeltJeju",
    `PROJCS["Korean_1985_Modified_Korea_Central_Belt_Jeju",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",550000.0],PARAMETER["Central_Meridian",127.0028902777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985ModifiedKoreaEastBelt",
    `PROJCS["Korean_1985_Modified_Korea_East_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",129.0028902777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Korean1985ModifiedKoreaEastSeaBelt",
    `PROJCS["Korean_1985_Modified_Korea_East_Sea_Belt",GEOGCS["GCS_Korean_Datum_1985",DATUM["D_Korean_Datum_1985",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",131.0028902777778],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "Bermuda2000NationalGrid",
    `PROJCS["Bermuda_2000_National_Grid",GEOGCS["GCS_Bermuda_2000",DATUM["D_Bermuda_2000",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",550000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-64.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",32.0],UNIT["Meter",1.0]]`
  ],
  [
    "MonteMarioItaly1",
    `PROJCS["Monte_Mario_Italy_1",GEOGCS["GCS_Monte_Mario",DATUM["D_Monte_Mario",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MonteMarioItaly2",
    `PROJCS["Monte_Mario_Italy_2",GEOGCS["GCS_Monte_Mario",DATUM["D_Monte_Mario",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2520000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Roma1940GaussBoagaEst",
    `PROJCS["Roma_1940_Gauss_Boaga_Est",GEOGCS["GCS_Roma_1940",DATUM["D_Roma_1940",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2520000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Roma1940GaussBoagaOvest",
    `PROJCS["Roma_1940_Gauss_Boaga_Ovest",GEOGCS["GCS_Roma_1940",DATUM["D_Roma_1940",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JAD2001JamaicaGrid",
    `PROJCS["JAD_2001_Jamaica_Grid",GEOGCS["GCS_JAD_2001",DATUM["D_Jamaica_2001",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",750000.0],PARAMETER["False_Northing",650000.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",18.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "BabSouthPalauAzimuthalEquidistant",
    `PROJCS["Bab_South_Palau_Azimuthal_Equidistant",GEOGCS["GCS_Bab_South",DATUM["D_Bab_South",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",150000.0],PARAMETER["Central_Meridian",134.4504448611111],PARAMETER["Latitude_Of_Origin",7.35122211111111],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone26N",
    `PROJCS["ETRS_1989_UTM_Zone_26N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone27N",
    `PROJCS["ETRS_1989_UTM_Zone_27N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone39N",
    `PROJCS["ETRS_1989_UTM_Zone_39N",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984WebMercatorAuxiliarySphere",
    `PROJCS["WGS_1984_Web_Mercator_Auxiliary_Sphere",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator_Auxiliary_Sphere"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],PARAMETER["Auxiliary_Sphere_Type",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "NGO1948NorwayZone1",
    `PROJCS["NGO_1948_Norway_Zone_1",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",6.05625],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948NorwayZone2",
    `PROJCS["NGO_1948_Norway_Zone_2",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",8.389583333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948NorwayZone3",
    `PROJCS["NGO_1948_Norway_Zone_3",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",10.72291666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948NorwayZone4",
    `PROJCS["NGO_1948_Norway_Zone_4",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",13.22291666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948NorwayZone5",
    `PROJCS["NGO_1948_Norway_Zone_5",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",16.88958333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948NorwayZone6",
    `PROJCS["NGO_1948_Norway_Zone_6",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",20.88958333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948NorwayZone7",
    `PROJCS["NGO_1948_Norway_Zone_7",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.88958333333333],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948NorwayZone8",
    `PROJCS["NGO_1948_Norway_Zone_8",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",29.05625],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989SloveniaTM",
    `PROJCS["ETRS_1989_Slovenia_TM",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGF1993Lambert93",
    `PROJCS["RGF_1993_Lambert_93",GEOGCS["GCS_RGF_1993",DATUM["D_RGF_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",6600000.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Standard_Parallel_1",44.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "ChathamIslands1979MapGrid",
    `PROJCS["Chatham_Islands_1979_Map_Grid",GEOGCS["GCS_Chatham_Islands_1979",DATUM["D_Chatham_Islands_1979",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",350000.0],PARAMETER["False_Northing",650000.0],PARAMETER["Central_Meridian",-176.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-44.0],UNIT["Meter",1.0]]`
  ],
  [
    "NZGD2000ChathamIslandCircuit",
    `PROJCS["NZGD_2000_Chatham_Island_Circuit",GEOGCS["GCS_NZGD_2000",DATUM["D_NZGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Central_Meridian",-176.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-44.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984WebMercator",
    `PROJCS["WGS_1984_Web_Mercator",GEOGCS["GCS_WGS_1984_Major_Auxiliary_Sphere",DATUM["D_WGS_1984_Major_Auxiliary_Sphere",SPHEROID["WGS_1984_Major_Auxiliary_Sphere",6378137.0,0.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "OldHawaiianUTMZone4N",
    `PROJCS["Old_Hawaiian_UTM_Zone_4N",GEOGCS["GCS_Old_Hawaiian",DATUM["D_Old_Hawaiian",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "OldHawaiianUTMZone5N",
    `PROJCS["Old_Hawaiian_UTM_Zone_5N",GEOGCS["GCS_Old_Hawaiian",DATUM["D_Old_Hawaiian",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AmericanSamoa1962UTMZone2S",
    `PROJCS["American_Samoa_1962_UTM_Zone_2S",GEOGCS["GCS_American_Samoa_1962",DATUM["D_American_Samoa_1962",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927AlaskaAlbersMeters",
    `PROJCS["NAD_1927_Alaska_Albers_Meters",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927GeorgiaStatewideAlbers",
    `PROJCS["NAD_1927_Georgia_Statewide_Albers",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-83.5],PARAMETER["Standard_Parallel_1",29.5],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",23.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927TexasStatewideMappingSystem",
    `PROJCS["NAD_1927_Texas_Statewide_Mapping_System",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.41666666666667],PARAMETER["Standard_Parallel_2",34.91666666666666],PARAMETER["Latitude_Of_Origin",31.16666666666667],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1927MichiganGeoRefFeetUS",
    `PROJCS["NAD_1927_Michigan_GeoRef_Feet_US",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",8355401.583],PARAMETER["False_Northing",-14284780.538],PARAMETER["Scale_Factor",0.9996],PARAMETER["Azimuth",337.25556],PARAMETER["Longitude_Of_Center",-86.0],PARAMETER["Latitude_Of_Center",45.30916666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983MichiganGeoRefFeetUS",
    `PROJCS["NAD_1983_Michigan_GeoRef_Feet_US",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",8355401.583],PARAMETER["False_Northing",-14284780.538],PARAMETER["Scale_Factor",0.9996],PARAMETER["Azimuth",337.25556],PARAMETER["Longitude_Of_Center",-86.0],PARAMETER["Latitude_Of_Center",45.30916666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927MichiganGeoRefMeters",
    `PROJCS["NAD_1927_Michigan_GeoRef_Meters",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",2546731.496],PARAMETER["False_Northing",-4354009.816],PARAMETER["Scale_Factor",0.9996],PARAMETER["Azimuth",337.25556],PARAMETER["Longitude_Of_Center",-86.0],PARAMETER["Latitude_Of_Center",45.30916666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MichiganGeoRefMeters",
    `PROJCS["NAD_1983_Michigan_GeoRef_Meters",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",2546731.496],PARAMETER["False_Northing",-4354009.816],PARAMETER["Scale_Factor",0.9996],PARAMETER["Azimuth",337.25556],PARAMETER["Longitude_Of_Center",-86.0],PARAMETER["Latitude_Of_Center",45.30916666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone1N",
    `PROJCS["NAD_1927_UTM_Zone_1N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone2N",
    `PROJCS["NAD_1927_UTM_Zone_2N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone59N",
    `PROJCS["NAD_1927_UTM_Zone_59N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1927UTMZone60N",
    `PROJCS["NAD_1927_UTM_Zone_60N",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone1N",
    `PROJCS["NAD_1983_UTM_Zone_1N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone2N",
    `PROJCS["NAD_1983_UTM_Zone_2N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone59N",
    `PROJCS["NAD_1983_UTM_Zone_59N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone60N",
    `PROJCS["NAD_1983_UTM_Zone_60N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948UTMZone32N",
    `PROJCS["NGO_1948_UTM_Zone_32N",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948UTMZone33N",
    `PROJCS["NGO_1948_UTM_Zone_33N",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948UTMZone34N",
    `PROJCS["NGO_1948_UTM_Zone_34N",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948UTMZone35N",
    `PROJCS["NGO_1948_UTM_Zone_35N",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948BaerumKommune",
    `PROJCS["NGO_1948_Baerum_Kommune",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",19999.32],PARAMETER["False_Northing",-202977.79],PARAMETER["Central_Meridian",10.72291666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948Bergenhalvoen",
    `PROJCS["NGO_1948_Bergenhalvoen",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",-200000.0],PARAMETER["Central_Meridian",6.05625],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloKommune",
    `PROJCS["NGO_1948_Oslo_Kommune",GEOGCS["GCS_NGO_1948",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-212979.18],PARAMETER["Central_Meridian",10.72291666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "EUREFFINTM35FIN",
    `PROJCS["EUREF_FIN_TM35FIN",GEOGCS["GCS_EUREF_FIN",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "HongKong1980Grid",
    `PROJCS["Hong_Kong_1980_Grid",GEOGCS["GCS_Hong_Kong_1980",DATUM["D_Hong_Kong_1980",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",836694.05],PARAMETER["False_Northing",819069.8],PARAMETER["Central_Meridian",114.1785555555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.31213333333334],UNIT["Meter",1.0]]`
  ],
  [
    "HongKong1980UTMZone49N",
    `PROJCS["Hong_Kong_1980_UTM_Zone_49N",GEOGCS["GCS_Hong_Kong_1980",DATUM["D_Hong_Kong_1980",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "HongKong1980UTMZone50N",
    `PROJCS["Hong_Kong_1980_UTM_Zone_50N",GEOGCS["GCS_Hong_Kong_1980",DATUM["D_Hong_Kong_1980",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "QND1995UTM39N",
    `PROJCS["QND_1995_UTM_39N",GEOGCS["GCS_QND_1995",DATUM["D_QND_1995",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MerchichUTMZone28N",
    `PROJCS["Merchich_UTM_Zone_28N",GEOGCS["GCS_Merchich",DATUM["D_Merchich",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone51N",
    `PROJCS["JGD_2000_UTM_Zone_51N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone52N",
    `PROJCS["JGD_2000_UTM_Zone_52N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone53N",
    `PROJCS["JGD_2000_UTM_Zone_53N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone54N",
    `PROJCS["JGD_2000_UTM_Zone_54N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone55N",
    `PROJCS["JGD_2000_UTM_Zone_55N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2000UTMZone56N",
    `PROJCS["JGD_2000_UTM_Zone_56N",GEOGCS["GCS_JGD_2000",DATUM["D_JGD_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TokyoUTMZone51N",
    `PROJCS["Tokyo_UTM_Zone_51N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TokyoUTMZone52N",
    `PROJCS["Tokyo_UTM_Zone_52N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TokyoUTMZone53N",
    `PROJCS["Tokyo_UTM_Zone_53N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TokyoUTMZone54N",
    `PROJCS["Tokyo_UTM_Zone_54N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TokyoUTMZone55N",
    `PROJCS["Tokyo_UTM_Zone_55N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TokyoUTMZone56N",
    `PROJCS["Tokyo_UTM_Zone_56N",GEOGCS["GCS_Tokyo",DATUM["D_Tokyo",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989KosovoGrid",
    `PROJCS["ETRS_1989_Kosovo_Grid",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JordanJTM",
    `PROJCS["Jordan_JTM",GEOGCS["GCS_Jordan",DATUM["D_Jordan",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-3000000.0],PARAMETER["Central_Meridian",37.0],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ObservatorioMeteorologico1965MacauGrid",
    `PROJCS["Observatorio_Meteorologico_1965_Macau_Grid",GEOGCS["GCS_Observatorio_Meteorologico_1965",DATUM["D_Observatorio_Meteorologico_1965",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20000.0],PARAMETER["False_Northing",20000.0],PARAMETER["Central_Meridian",113.5364694444444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.21239722222222],UNIT["Meter",1.0]]`
  ],
  [
    "Datum73HayfordGaussIGeoE",
    `PROJCS["Datum_73_Hayford_Gauss_IGeoE",GEOGCS["GCS_Datum_73",DATUM["D_Datum_73",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200180.598],PARAMETER["False_Northing",299913.01],PARAMETER["Central_Meridian",-8.131906111111112],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "Datum73HayfordGaussIPCC",
    `PROJCS["Datum_73_Hayford_Gauss_IPCC",GEOGCS["GCS_Datum_73",DATUM["D_Datum_73",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",180.598],PARAMETER["False_Northing",-86.99],PARAMETER["Central_Meridian",-8.131906111111112],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "GraciosaBaseSW1948UTMZone26N",
    `PROJCS["Graciosa_Base_SW_1948_UTM_Zone_26N",GEOGCS["GCS_Graciosa_Base_SW_1948",DATUM["D_Graciosa_Base_SW_1948",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "LisboaBesselBonne",
    `PROJCS["Lisboa_Bessel_Bonne",GEOGCS["GCS_Datum_Lisboa_Bessel",DATUM["D_Datum_Lisboa_Bessel",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Bonne"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-8.131906111111112],PARAMETER["Standard_Parallel_1",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "LisboaHayfordGaussIGeoE",
    `PROJCS["Lisboa_Hayford_Gauss_IGeoE",GEOGCS["GCS_Datum_Lisboa_Hayford",DATUM["D_Datum_Lisboa_Hayford",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",-8.131906111111112],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "LisboaHayfordGaussIPCC",
    `PROJCS["Lisboa_Hayford_Gauss_IPCC",GEOGCS["GCS_Datum_Lisboa_Hayford",DATUM["D_Datum_Lisboa_Hayford",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-8.131906111111112],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "ObservatorioMeteorologico1939UTMZone25N",
    `PROJCS["Observatorio_Meteorologico_1939_UTM_Zone_25N",GEOGCS["GCS_Observatorio_Meteorologico_1939",DATUM["D_Observatorio_Meteorologico_1939",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PortoSanto1936UTMZone28N",
    `PROJCS["Porto_Santo_1936_UTM_Zone_28N",GEOGCS["GCS_Porto_Santo_1936",DATUM["D_Porto_Santo_1936",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SaoBrazUTMZone26N",
    `PROJCS["Sao_Braz_UTM_Zone_26N",GEOGCS["GCS_Sao_Braz",DATUM["D_Sao_Braz",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "SelvagemGrande1938UTMZone28N",
    `PROJCS["Selvagem_Grande_1938_UTM_Zone_28N",GEOGCS["GCS_Selvagem_Grande_1938",DATUM["D_Selvagem_Grande_1938",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966VICGRID",
    `PROJCS["AGD_1966_VICGRID",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",4500000.0],PARAMETER["Central_Meridian",145.0],PARAMETER["Standard_Parallel_1",-36.0],PARAMETER["Standard_Parallel_2",-38.0],PARAMETER["Latitude_Of_Origin",-37.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994VICGRID94",
    `PROJCS["GDA_1994_VICGRID94",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",2500000.0],PARAMETER["Central_Meridian",145.0],PARAMETER["Standard_Parallel_1",-36.0],PARAMETER["Standard_Parallel_2",-38.0],PARAMETER["Latitude_Of_Origin",-37.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994SouthAustraliaLambert",
    `PROJCS["GDA_1994_South_Australia_Lambert",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Standard_Parallel_1",-28.0],PARAMETER["Standard_Parallel_2",-36.0],PARAMETER["Latitude_Of_Origin",-32.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UWPP1992",
    `PROJCS["ETRS_1989_UWPP_1992",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-5300000.0],PARAMETER["Central_Meridian",19.0],PARAMETER["Scale_Factor",0.9993],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UWPP2000PAS5",
    `PROJCS["ETRS_1989_UWPP_2000_PAS_5",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UWPP2000PAS6",
    `PROJCS["ETRS_1989_UWPP_2000_PAS_6",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UWPP2000PAS7",
    `PROJCS["ETRS_1989_UWPP_2000_PAS_7",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UWPP2000PAS8",
    `PROJCS["ETRS_1989_UWPP_2000_PAS_8",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD192710TMAEPForest",
    `PROJCS["NAD_1927_10TM_AEP_Forest",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD192710TMAEPResource",
    `PROJCS["NAD_1927_10TM_AEP_Resource",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19273TM111",
    `PROJCS["NAD_1927_3TM_111",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19273TM114",
    `PROJCS["NAD_1927_3TM_114",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19273TM117",
    `PROJCS["NAD_1927_3TM_117",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19273TM120",
    `PROJCS["NAD_1927_3TM_120",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD198310TMAEPForest",
    `PROJCS["NAD_1983_10TM_AEP_Forest",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD198310TMAEPResource",
    `PROJCS["NAD_1983_10TM_AEP_Resource",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19833TM111",
    `PROJCS["NAD_1983_3TM_111",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19833TM114",
    `PROJCS["NAD_1983_3TM_114",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19833TM117",
    `PROJCS["NAD_1983_3TM_117",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19833TM120",
    `PROJCS["NAD_1983_3TM_120",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983BCEnvironmentAlbers",
    `PROJCS["NAD_1983_BC_Environment_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-126.0],PARAMETER["Standard_Parallel_1",50.0],PARAMETER["Standard_Parallel_2",58.5],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "NordMaroc",
    `PROJCS["Nord_Maroc",GEOGCS["GCS_Merchich",DATUM["D_Merchich",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",-5.4],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Scale_Factor",0.999625769],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Meter",1.0]]`
  ],
  [
    "SudMaroc",
    `PROJCS["Sud_Maroc",GEOGCS["GCS_Merchich",DATUM["D_Merchich",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",-5.4],PARAMETER["Standard_Parallel_1",29.7],PARAMETER["Scale_Factor",0.999615596],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "PEPCSMERCHICHDEGREESAHARA",
    `PROJCS["PE_PCS_MERCHICH_DEGREE_SAHARA",GEOGCS["GCS_Merchich",DATUM["D_Merchich",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1200000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-5.4],PARAMETER["Standard_Parallel_1",26.1],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",26.1],UNIT["Meter",1.0]]`
  ],
  [
    "UWPP1992",
    `PROJCS["UWPP_1992",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-5300000.0],PARAMETER["Central_Meridian",19.0],PARAMETER["Scale_Factor",0.9993],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "UWPP2000PAS5",
    `PROJCS["UWPP_2000_PAS_5",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "UWPP2000PAS6",
    `PROJCS["UWPP_2000_PAS_6",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "UWPP2000PAS7",
    `PROJCS["UWPP_2000_PAS_7",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "UWPP2000PAS8",
    `PROJCS["UWPP_2000_PAS_8",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.999923],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "BelgeLambert2008",
    `PROJCS["Belge_Lambert_2008",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",649328.0],PARAMETER["False_Northing",665262.0],PARAMETER["Central_Meridian",4.359215833333333],PARAMETER["Standard_Parallel_1",49.83333333333334],PARAMETER["Standard_Parallel_2",51.16666666666666],PARAMETER["Latitude_Of_Origin",50.797815],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone2S",
    `PROJCS["NAD_1983_HARN_UTM_Zone_2S",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNGuamMapGrid",
    `PROJCS["NAD_1983_HARN_Guam_Map_Grid",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",144.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",13.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone4N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_4N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone5N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_5N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AinelAbd1970AramcoLambert2",
    `PROJCS["Ain_el_Abd_1970_Aramco_Lambert_2",GEOGCS["GCS_Ain_el_Abd_1970",DATUM["D_Ain_el_Abd_1970",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Standard_Parallel_1",21.0],PARAMETER["Standard_Parallel_2",27.0],PARAMETER["Latitude_Of_Origin",24.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone11N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_11N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone12N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_12N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone13N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_13N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNMaine2000EastZone",
    `PROJCS["NAD_1983_HARN_Maine_2000_East_Zone",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.875],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNMaine2000CentralZone",
    `PROJCS["NAD_1983_HARN_Maine_2000_Central_Zone",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.125],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNMaine2000WestZone",
    `PROJCS["NAD_1983_HARN_Maine_2000_West_Zone",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.375],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNUTMZone18N",
    `PROJCS["NAD_1983_HARN_UTM_Zone_18N",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983WyLAM",
    `PROJCS["NAD_1983_WyLAM",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-107.5],PARAMETER["Standard_Parallel_1",41.0],PARAMETER["Standard_Parallel_2",45.0],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983UTMZone58N",
    `PROJCS["NAD_1983_UTM_Zone_58N",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984CapeVerdeGrid",
    `PROJCS["WGS_1984_Cape_Verde_Grid",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",161587.83],PARAMETER["False_Northing",128511.202],PARAMETER["Central_Meridian",-24.0],PARAMETER["Standard_Parallel_1",15.0],PARAMETER["Standard_Parallel_2",16.66666666666667],PARAMETER["Latitude_Of_Origin",15.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984CanadaAtlasLCC",
    `PROJCS["WGS_1984_Canada_Atlas_LCC",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-8000000.0],PARAMETER["Central_Meridian",-95.0],PARAMETER["Standard_Parallel_1",49.0],PARAMETER["Standard_Parallel_2",77.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDA1994PerthCoastalGrid1994",
    `PROJCS["GDA_1994_Perth_Coastal_Grid_1994",GEOGCS["GCS_GDA_1994",DATUM["D_GDA_1994",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",3800000.0],PARAMETER["Central_Meridian",115.8166666666667],PARAMETER["Scale_Factor",0.99999906],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007WisconsinTMUSFt",
    `PROJCS["NAD_1983_NSRS2007_Wisconsin_TM_US_Ft",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1706033.333333333],PARAMETER["False_Northing",-14698133.33333333],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983USFSR6Albers",
    `PROJCS["NAD_1983_USFS_R6_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",48.0],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983WisconsinTMUSFt",
    `PROJCS["NAD_1983_Wisconsin_TM_US_Ft",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1706033.333333333],PARAMETER["False_Northing",-14698133.33333333],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWisconsinTMUSFt",
    `PROJCS["NAD_1983_HARN_Wisconsin_TM_US_Ft",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1706033.333333333],PARAMETER["False_Northing",-14698133.33333333],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Ocotepeque1935CostaRicaLambertNorte",
    `PROJCS["Ocotepeque_1935_Costa_Rica_Lambert_Norte",GEOGCS["GCS_Ocotepeque_1935",DATUM["D_Ocotepeque_1935",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",271820.522],PARAMETER["Central_Meridian",-84.33333333333333],PARAMETER["Standard_Parallel_1",9.933333333333334],PARAMETER["Standard_Parallel_2",11.0],PARAMETER["Latitude_Of_Origin",10.46666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "Ocotepeque1935CostaRicaLambertSur",
    `PROJCS["Ocotepeque_1935_Costa_Rica_Lambert_Sur",GEOGCS["GCS_Ocotepeque_1935",DATUM["D_Ocotepeque_1935",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",327987.436],PARAMETER["Central_Meridian",-83.66666666666667],PARAMETER["Standard_Parallel_1",8.466666666666667],PARAMETER["Standard_Parallel_2",9.533333333333333],PARAMETER["Latitude_Of_Origin",9.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984CostaRicaTM90",
    `PROJCS["WGS_1984_Costa_Rica_TM_90",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MONREF1997UTMZone46N",
    `PROJCS["MONREF_1997_UTM_Zone_46N",GEOGCS["GCS_MONREF_1997",DATUM["D_ITRF_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MONREF1997UTMZone47N",
    `PROJCS["MONREF_1997_UTM_Zone_47N",GEOGCS["GCS_MONREF_1997",DATUM["D_ITRF_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MONREF1997UTMZone48N",
    `PROJCS["MONREF_1997_UTM_Zone_48N",GEOGCS["GCS_MONREF_1997",DATUM["D_ITRF_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MONREF1997UTMZone49N",
    `PROJCS["MONREF_1997_UTM_Zone_49N",GEOGCS["GCS_MONREF_1997",DATUM["D_ITRF_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MONREF1997UTMZone50N",
    `PROJCS["MONREF_1997_UTM_Zone_50N",GEOGCS["GCS_MONREF_1997",DATUM["D_ITRF_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneAlabamaEastFIPS0101",
    `PROJCS["NAD_1983_HARN_StatePlane_Alabama_East_FIPS_0101",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneAlabamaWestFIPS0102",
    `PROJCS["NAD_1983_HARN_StatePlane_Alabama_West_FIPS_0102",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "ColombiaWestWestZone",
    `PROJCS["Colombia_West_West_Zone",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-80.08091666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",4.599047222222222],UNIT["Meter",1.0]]`
  ],
  [
    "BogotaCiudadBogota",
    `PROJCS["Bogota_Ciudad_Bogota",GEOGCS["GCS_Bogota",DATUM["D_Bogota",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",92334.879],PARAMETER["False_Northing",109320.965],PARAMETER["Central_Meridian",-74.15],PARAMETER["Scale_Factor",1.000399787532524],PARAMETER["Latitude_Of_Origin",4.683333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNACiudadBogota",
    `PROJCS["MAGNA_Ciudad_Bogota",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",92334.879],PARAMETER["False_Northing",109320.965],PARAMETER["Central_Meridian",-74.14659166666668],PARAMETER["Scale_Factor",1.000399803265436],PARAMETER["Latitude_Of_Origin",4.680486111111112],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone14N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_14N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone15N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_15N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CSRSUTMZone16N",
    `PROJCS["NAD_1983_CSRS_UTM_Zone_16N",GEOGCS["GCS_North_American_1983_CSRS",DATUM["D_North_American_1983_CSRS",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PohnpeiAzEq1971",
    `PROJCS["Pohnpei_Az_Eq_1971",GEOGCS["GCS_Pohnpei",DATUM["D_Pohnpei",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",80122.82],PARAMETER["False_Northing",80747.24],PARAMETER["Central_Meridian",158.2092992222222],PARAMETER["Latitude_Of_Origin",6.965075694444445],UNIT["Meter",1.0]]`
  ],
  [
    "SaipanAzEq1969",
    `PROJCS["Saipan_Az_Eq_1969",GEOGCS["GCS_Guam_1963",DATUM["D_Guam_1963",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",145.7112869444444],PARAMETER["Latitude_Of_Origin",15.16755722222222],UNIT["Meter",1.0]]`
  ],
  [
    "GuamGeodeticTriangulationNetwork1963",
    `PROJCS["Guam_Geodetic_Triangulation_Network_1963",GEOGCS["GCS_Guam_1963",DATUM["D_Guam_1963",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",144.7487507055556],PARAMETER["Latitude_Of_Origin",13.47246635277778],UNIT["Meter",1.0]]`
  ],
  [
    "GuamGeodeticNetwork1993",
    `PROJCS["Guam_Geodetic_Network_1993",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",144.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",13.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIFIPS0401",
    `PROJCS["NAD_1983_HARN_StatePlane_California_I_FIPS_0401",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIIFIPS0402",
    `PROJCS["NAD_1983_HARN_StatePlane_California_II_FIPS_0402",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIIIFIPS0403",
    `PROJCS["NAD_1983_HARN_StatePlane_California_III_FIPS_0403",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaIVFIPS0404",
    `PROJCS["NAD_1983_HARN_StatePlane_California_IV_FIPS_0404",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaVFIPS0405",
    `PROJCS["NAD_1983_HARN_StatePlane_California_V_FIPS_0405",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneCaliforniaVIFIPS0406",
    `PROJCS["NAD_1983_HARN_StatePlane_California_VI_FIPS_0406",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96AlaskaAlbers",
    `PROJCS["NAD_1983_CORS96_Alaska_Albers",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArizonaEastFIPS0201",
    `PROJCS["NAD_1983_HARN_StatePlane_Arizona_East_FIPS_0201",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArizonaCentralFIPS0202",
    `PROJCS["NAD_1983_HARN_StatePlane_Arizona_Central_FIPS_0202",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArizonaWestFIPS0203",
    `PROJCS["NAD_1983_HARN_StatePlane_Arizona_West_FIPS_0203",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArkansasNorthFIPS0301",
    `PROJCS["NAD_1983_HARN_StatePlane_Arkansas_North_FIPS_0301",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneArkansasSouthFIPS0302",
    `PROJCS["NAD_1983_HARN_StatePlane_Arkansas_South_FIPS_0302",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneColoradoNorthFIPS0501",
    `PROJCS["NAD_1983_HARN_StatePlane_Colorado_North_FIPS_0501",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneColoradoCentralFIPS0502",
    `PROJCS["NAD_1983_HARN_StatePlane_Colorado_Central_FIPS_0502",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneColoradoSouthFIPS0503",
    `PROJCS["NAD_1983_HARN_StatePlane_Colorado_South_FIPS_0503",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneConnecticutFIPS0600",
    `PROJCS["NAD_1983_HARN_StatePlane_Connecticut_FIPS_0600",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",304800.6096],PARAMETER["False_Northing",152400.3048],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneDelawareFIPS0700",
    `PROJCS["NAD_1983_HARN_StatePlane_Delaware_FIPS_0700",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneFloridaEastFIPS0901",
    `PROJCS["NAD_1983_HARN_StatePlane_Florida_East_FIPS_0901",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneFloridaWestFIPS0902",
    `PROJCS["NAD_1983_HARN_StatePlane_Florida_West_FIPS_0902",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneFloridaNorthFIPS0903",
    `PROJCS["NAD_1983_HARN_StatePlane_Florida_North_FIPS_0903",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii1FIPS5101",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_1_FIPS_5101",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-155.5],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",18.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii2FIPS5102",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_2_FIPS_5102",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-156.6666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",20.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii3FIPS5103",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_3_FIPS_5103",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii4FIPS5104",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_4_FIPS_5104",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.5],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii5FIPS5105",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_5_FIPS_5105",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.1666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",21.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneGeorgiaEastFIPS1001",
    `PROJCS["NAD_1983_HARN_StatePlane_Georgia_East_FIPS_1001",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneGeorgiaWestFIPS1002",
    `PROJCS["NAD_1983_HARN_StatePlane_Georgia_West_FIPS_1002",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIdahoEastFIPS1101",
    `PROJCS["NAD_1983_HARN_StatePlane_Idaho_East_FIPS_1101",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIdahoCentralFIPS1102",
    `PROJCS["NAD_1983_HARN_StatePlane_Idaho_Central_FIPS_1102",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIdahoWestFIPS1103",
    `PROJCS["NAD_1983_HARN_StatePlane_Idaho_West_FIPS_1103",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIllinoisEastFIPS1201",
    `PROJCS["NAD_1983_HARN_StatePlane_Illinois_East_FIPS_1201",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIllinoisWestFIPS1202",
    `PROJCS["NAD_1983_HARN_StatePlane_Illinois_West_FIPS_1202",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIndianaEastFIPS1301",
    `PROJCS["NAD_1983_HARN_StatePlane_Indiana_East_FIPS_1301",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIndianaWestFIPS1302",
    `PROJCS["NAD_1983_HARN_StatePlane_Indiana_West_FIPS_1302",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIowaNorthFIPS1401",
    `PROJCS["NAD_1983_HARN_StatePlane_Iowa_North_FIPS_1401",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneIowaSouthFIPS1402",
    `PROJCS["NAD_1983_HARN_StatePlane_Iowa_South_FIPS_1402",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneKansasNorthFIPS1501",
    `PROJCS["NAD_1983_HARN_StatePlane_Kansas_North_FIPS_1501",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneKansasSouthFIPS1502",
    `PROJCS["NAD_1983_HARN_StatePlane_Kansas_South_FIPS_1502",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneKentuckyNorthFIPS1601",
    `PROJCS["NAD_1983_HARN_StatePlane_Kentucky_North_FIPS_1601",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneKentuckySouthFIPS1602",
    `PROJCS["NAD_1983_HARN_StatePlane_Kentucky_South_FIPS_1602",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneLouisianaNorthFIPS1701",
    `PROJCS["NAD_1983_HARN_StatePlane_Louisiana_North_FIPS_1701",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneLouisianaSouthFIPS1702",
    `PROJCS["NAD_1983_HARN_StatePlane_Louisiana_South_FIPS_1702",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMaineEastFIPS1801",
    `PROJCS["NAD_1983_HARN_StatePlane_Maine_East_FIPS_1801",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMaineWestFIPS1802",
    `PROJCS["NAD_1983_HARN_StatePlane_Maine_West_FIPS_1802",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMarylandFIPS1900",
    `PROJCS["NAD_1983_HARN_StatePlane_Maryland_FIPS_1900",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMassachusettsMainlandFIPS2001",
    `PROJCS["NAD_1983_HARN_StatePlane_Massachusetts_Mainland_FIPS_2001",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",750000.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMassachusettsIslandFIPS2002",
    `PROJCS["NAD_1983_HARN_StatePlane_Massachusetts_Island_FIPS_2002",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMichiganNorthFIPS2111",
    `PROJCS["NAD_1983_HARN_StatePlane_Michigan_North_FIPS_2111",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMichiganCentralFIPS2112",
    `PROJCS["NAD_1983_HARN_StatePlane_Michigan_Central_FIPS_2112",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMichiganSouthFIPS2113",
    `PROJCS["NAD_1983_HARN_StatePlane_Michigan_South_FIPS_2113",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaNorthFIPS2201",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_North_FIPS_2201",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaCentralFIPS2202",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_Central_FIPS_2202",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaSouthFIPS2203",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_South_FIPS_2203",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMississippiEastFIPS2301",
    `PROJCS["NAD_1983_HARN_StatePlane_Mississippi_East_FIPS_2301",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMississippiWestFIPS2302",
    `PROJCS["NAD_1983_HARN_StatePlane_Mississippi_West_FIPS_2302",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMissouriEastFIPS2401",
    `PROJCS["NAD_1983_HARN_StatePlane_Missouri_East_FIPS_2401",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMissouriCentralFIPS2402",
    `PROJCS["NAD_1983_HARN_StatePlane_Missouri_Central_FIPS_2402",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMissouriWestFIPS2403",
    `PROJCS["NAD_1983_HARN_StatePlane_Missouri_West_FIPS_2403",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",850000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.5],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "BerghausStarAAG",
    `PROJCS["Berghaus_Star_AAG",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Berghaus_Star"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-52.0],PARAMETER["Latitude_Of_Origin",90.0],PARAMETER["XY_Plane_Rotation",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneMontanaFIPS2500",
    `PROJCS["NAD_1983_HARN_StatePlane_Montana_FIPS_2500",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNebraskaFIPS2600",
    `PROJCS["NAD_1983_HARN_StatePlane_Nebraska_FIPS_2600",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "CRTM05",
    `PROJCS["CRTM05",GEOGCS["GCS_CR05",DATUM["D_Costa_Rica_2005",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NepalNagarkotTM",
    `PROJCS["Nepal_Nagarkot_TM",GEOGCS["GCS_Nepal_Nagarkot",DATUM["D_Nepal_Nagarkot",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",84.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNevadaEastFIPS2701",
    `PROJCS["NAD_1983_HARN_StatePlane_Nevada_East_FIPS_2701",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",8000000.0],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNevadaCentralFIPS2702",
    `PROJCS["NAD_1983_HARN_StatePlane_Nevada_Central_FIPS_2702",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNevadaWestFIPS2703",
    `PROJCS["NAD_1983_HARN_StatePlane_Nevada_West_FIPS_2703",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewHampshireFIPS2800",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Hampshire_FIPS_2800",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewJerseyFIPS2900",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Jersey_FIPS_2900",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewMexicoEastFIPS3001",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Mexico_East_FIPS_3001",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewMexicoCentralFIPS3002",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Mexico_Central_FIPS_3002",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewMexicoWestFIPS3003",
    `PROJCS["NAD_1983_HARN_StatePlane_New_Mexico_West_FIPS_3003",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",830000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkEastFIPS3101",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_East_FIPS_3101",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkCentralFIPS3102",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_Central_FIPS_3102",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkWestFIPS3103",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_West_FIPS_3103",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",350000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNewYorkLongIslandFIPS3104",
    `PROJCS["NAD_1983_HARN_StatePlane_New_York_Long_Island_FIPS_3104",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "CGRS1993LTM",
    `PROJCS["CGRS_1993_LTM",GEOGCS["GCS_CGRS_1993",DATUM["D_Cyprus_Geodetic_Reference_System_1993",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",-3500000.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNorthDakotaNorthFIPS3301",
    `PROJCS["NAD_1983_HARN_StatePlane_North_Dakota_North_FIPS_3301",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneNorthDakotaSouthFIPS3302",
    `PROJCS["NAD_1983_HARN_StatePlane_North_Dakota_South_FIPS_3302",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOhioNorthFIPS3401",
    `PROJCS["NAD_1983_HARN_StatePlane_Ohio_North_FIPS_3401",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOhioSouthFIPS3402",
    `PROJCS["NAD_1983_HARN_StatePlane_Ohio_South_FIPS_3402",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOklahomaNorthFIPS3501",
    `PROJCS["NAD_1983_HARN_StatePlane_Oklahoma_North_FIPS_3501",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOklahomaSouthFIPS3502",
    `PROJCS["NAD_1983_HARN_StatePlane_Oklahoma_South_FIPS_3502",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOregonNorthFIPS3601",
    `PROJCS["NAD_1983_HARN_StatePlane_Oregon_North_FIPS_3601",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneOregonSouthFIPS3602",
    `PROJCS["NAD_1983_HARN_StatePlane_Oregon_South_FIPS_3602",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone32N7stellen",
    `PROJCS["ETRS_1989_UTM_Zone_32N_7stellen",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone32N8stellen",
    `PROJCS["ETRS_1989_UTM_Zone_32N_8stellen",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneRhodeIslandFIPS3800",
    `PROJCS["NAD_1983_HARN_StatePlane_Rhode_Island_FIPS_3800",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "PTRA08UTMZone25N",
    `PROJCS["PTRA08_UTM_Zone_25N",GEOGCS["GCS_PTRA08",DATUM["D_PTRA08",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PTRA08UTMZone26N",
    `PROJCS["PTRA08_UTM_Zone_26N",GEOGCS["GCS_PTRA08",DATUM["D_PTRA08",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PTRA08UTMZone28N",
    `PROJCS["PTRA08_UTM_Zone_28N",GEOGCS["GCS_PTRA08",DATUM["D_PTRA08",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneSouthDakotaNorthFIPS4001",
    `PROJCS["NAD_1983_HARN_StatePlane_South_Dakota_North_FIPS_4001",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneSouthDakotaSouthFIPS4002",
    `PROJCS["NAD_1983_HARN_StatePlane_South_Dakota_South_FIPS_4002",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTennesseeFIPS4100",
    `PROJCS["NAD_1983_HARN_StatePlane_Tennessee_FIPS_4100",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasNorthFIPS4201",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_North_FIPS_4201",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasNorthCentralFIPS4202",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_North_Central_FIPS_4202",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasCentralFIPS4203",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_Central_FIPS_4203",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasSouthCentralFIPS4204",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_South_Central_FIPS_4204",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneTexasSouthFIPS4205",
    `PROJCS["NAD_1983_HARN_StatePlane_Texas_South_FIPS_4205",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahNorthFIPS4301",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_North_FIPS_4301",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahCentralFIPS4302",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_Central_FIPS_4302",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneUtahSouthFIPS4303",
    `PROJCS["NAD_1983_HARN_StatePlane_Utah_South_FIPS_4303",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneVermontFIPS4400",
    `PROJCS["NAD_1983_HARN_StatePlane_Vermont_FIPS_4400",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneVirginiaNorthFIPS4501",
    `PROJCS["NAD_1983_HARN_StatePlane_Virginia_North_FIPS_4501",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneVirginiaSouthFIPS4502",
    `PROJCS["NAD_1983_HARN_StatePlane_Virginia_South_FIPS_4502",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWashingtonNorthFIPS4601",
    `PROJCS["NAD_1983_HARN_StatePlane_Washington_North_FIPS_4601",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWashingtonSouthFIPS4602",
    `PROJCS["NAD_1983_HARN_StatePlane_Washington_South_FIPS_4602",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWestVirginiaNorthFIPS4701",
    `PROJCS["NAD_1983_HARN_StatePlane_West_Virginia_North_FIPS_4701",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWestVirginiaSouthFIPS4702",
    `PROJCS["NAD_1983_HARN_StatePlane_West_Virginia_South_FIPS_4702",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWisconsinNorthFIPS4801",
    `PROJCS["NAD_1983_HARN_StatePlane_Wisconsin_North_FIPS_4801",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWisconsinCentralFIPS4802",
    `PROJCS["NAD_1983_HARN_StatePlane_Wisconsin_Central_FIPS_4802",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWisconsinSouthFIPS4803",
    `PROJCS["NAD_1983_HARN_StatePlane_Wisconsin_South_FIPS_4803",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingEastFIPS4901",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_East_FIPS_4901",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingEastCentralFIPS4902",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_East_Central_FIPS_4902",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingWestCentralFIPS4903",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_West_Central_FIPS_4903",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneWyomingWestFIPS4904",
    `PROJCS["NAD_1983_HARN_StatePlane_Wyoming_West_FIPS_4904",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone33N7stellen",
    `PROJCS["ETRS_1989_UTM_Zone_33N_7stellen",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZone33N8stellen",
    `PROJCS["ETRS_1989_UTM_Zone_33N_8stellen",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",33500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlanePuertoRicoVirginIslandsFIPS5200",
    `PROJCS["NAD_1983_HARN_StatePlane_Puerto_Rico_Virgin_Islands_FIPS_5200",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989UTMZoneN32",
    `PROJCS["ETRS_1989_UTM_Zone_N32",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNStatePlaneKentuckyFIPS1600",
    `PROJCS["NAD_1983_HARN_StatePlane_Kentucky_FIPS_1600",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone59N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_59N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone60N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_60N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlaska1FIPS5001",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alaska_1_FIPS_5001",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",-36.86989764583333],PARAMETER["Longitude_Of_Center",-133.6666666666667],PARAMETER["Latitude_Of_Center",57.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlaska2FIPS5002",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alaska_2_FIPS_5002",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlaska3FIPS5003",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alaska_3_FIPS_5003",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-146.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlaska4FIPS5004",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alaska_4_FIPS_5004",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlaska5FIPS5005",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alaska_5_FIPS_5005",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlaska6FIPS5006",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alaska_6_FIPS_5006",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlaska7FIPS5007",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alaska_7_FIPS_5007",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-162.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlaska8FIPS5008",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alaska_8_FIPS_5008",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-166.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlaska9FIPS5009",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alaska_9_FIPS_5009",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-170.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlaska10FIPS5010",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alaska_10_FIPS_5010",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-176.0],PARAMETER["Standard_Parallel_1",51.83333333333334],PARAMETER["Standard_Parallel_2",53.83333333333334],PARAMETER["Latitude_Of_Origin",51.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneOregonNorthFIPS3601",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oregon_North_FIPS_3601",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneOregonSouthFIPS3602",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oregon_South_FIPS_3602",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneOregonNorthFIPS3601FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oregon_North_FIPS_3601_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8202099.737532808],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneOregonSouthFIPS3602FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oregon_South_FIPS_3602_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921259.842519685],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96OregonStatewideLambert",
    `PROJCS["NAD_1983_CORS96_Oregon_Statewide_Lambert",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96OregonStatewideLambertFtIntl",
    `PROJCS["NAD_1983_CORS96_Oregon_Statewide_Lambert_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312335.958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011UTMZone13N",
    `PROJCS["NAD_1983_2011_UTM_Zone_13N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone14N",
    `PROJCS["NAD_1983_2011_UTM_Zone_14N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone15N",
    `PROJCS["NAD_1983_2011_UTM_Zone_15N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone16N",
    `PROJCS["NAD_1983_2011_UTM_Zone_16N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone17N",
    `PROJCS["NAD_1983_2011_UTM_Zone_17N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone18N",
    `PROJCS["NAD_1983_2011_UTM_Zone_18N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011UTMZone19N",
    `PROJCS["NAD_1983_2011_UTM_Zone_19N",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983FargoGroundCoordinateSystem",
    `PROJCS["NAD_1983_Fargo_Ground_Coordinate_System",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",2869344.93],PARAMETER["False_Northing",-16657672.6488],PARAMETER["Scale_Factor",1.000038773618],PARAMETER["Azimuth",2.63389226],PARAMETER["Longitude_Of_Center",-96.88886388888889],PARAMETER["Latitude_Of_Center",46.99163611111111],PARAMETER["XY_Plane_Rotation",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNFargoGroundCoordinateSystem",
    `PROJCS["NAD_1983_HARN_Fargo_Ground_Coordinate_System",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",2869344.93],PARAMETER["False_Northing",-16657672.6488],PARAMETER["Scale_Factor",1.000038773618],PARAMETER["Azimuth",2.63389226],PARAMETER["Longitude_Of_Center",-96.88886388888889],PARAMETER["Latitude_Of_Center",46.99163611111111],PARAMETER["XY_Plane_Rotation",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011FargoGroundCoordinateSystem",
    `PROJCS["NAD_1983_2011_Fargo_Ground_Coordinate_System",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",2869344.93],PARAMETER["False_Northing",-16657672.6488],PARAMETER["Scale_Factor",1.000038773618],PARAMETER["Azimuth",2.63389226],PARAMETER["Longitude_Of_Center",-96.88886388888889],PARAMETER["Latitude_Of_Center",46.99163611111111],PARAMETER["XY_Plane_Rotation",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlaska4FIPS5004Feet",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_4_FIPS_5004_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlaska5FIPS5005Feet",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_5_FIPS_5005_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlaska6FIPS5006Feet",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_6_FIPS_5006_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlaska7FIPS5007Feet",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_7_FIPS_5007_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-162.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlaska8FIPS5008Feet",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_8_FIPS_5008_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-166.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlaska9FIPS5009Feet",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_9_FIPS_5009_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-170.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlaska10FIPS5010Feet",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_10_FIPS_5010_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-176.0],PARAMETER["Standard_Parallel_1",51.83333333333334],PARAMETER["Standard_Parallel_2",53.83333333333334],PARAMETER["Latitude_Of_Origin",51.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983AlbersBLMMTNDSD",
    `PROJCS["NAD_1983_Albers_BLM_MT_ND_SD",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.0],PARAMETER["Standard_Parallel_1",43.5],PARAMETER["Standard_Parallel_2",48.0],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "LondonSurveyGrid",
    `PROJCS["London_Survey_Grid",GEOGCS["GCS_Xrail84",DATUM["D_Xrail84",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",78250.0],PARAMETER["False_Northing",-2800.0],PARAMETER["Central_Meridian",-0.158333333333333],PARAMETER["Scale_Factor",0.9999999],PARAMETER["Latitude_Of_Origin",51.1666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone1N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_1N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-177.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone2N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_2N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone3N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_3N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-165.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone4N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_4N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone5N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_5N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone6N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_6N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone7N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_7N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone8N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_8N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone9N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_9N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone10N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_10N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone11N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_11N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone12N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_12N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone13N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_13N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone14N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_14N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone15N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_15N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone16N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_16N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone17N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_17N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone18N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_18N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96UTMZone19N",
    `PROJCS["NAD_1983_CORS96_UTM_Zone_19N",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ISN2004Lambert2004",
    `PROJCS["ISN_2004_Lambert_2004",GEOGCS["GCS_ISN_2004",DATUM["D_Islands_Network_2004",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1700000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",-19.0],PARAMETER["Standard_Parallel_1",64.25],PARAMETER["Standard_Parallel_2",65.75],PARAMETER["Latitude_Of_Origin",65.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ARCSystemZone01",
    `PROJCS["WGS_1984_ARC_System_Zone_01",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",22.94791772],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone02",
    `PROJCS["WGS_1984_ARC_System_Zone_02",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",41.12682127],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone03",
    `PROJCS["WGS_1984_ARC_System_Zone_03",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",52.28859923],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone04",
    `PROJCS["WGS_1984_ARC_System_Zone_04",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",60.32378942],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone05",
    `PROJCS["WGS_1984_ARC_System_Zone_05",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",66.09421768],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone06",
    `PROJCS["WGS_1984_ARC_System_Zone_06",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",70.10896259],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone07",
    `PROJCS["WGS_1984_ARC_System_Zone_07",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",74.13230145],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone08",
    `PROJCS["WGS_1984_ARC_System_Zone_08",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",78.1728375],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone09",
    `PROJCS["WGS_1984_ARC_System_Zone_09",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone10",
    `PROJCS["WGS_1984_ARC_System_Zone_10",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-22.94791772],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone11",
    `PROJCS["WGS_1984_ARC_System_Zone_11",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-41.12682127],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone12",
    `PROJCS["WGS_1984_ARC_System_Zone_12",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-52.28859923],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone13",
    `PROJCS["WGS_1984_ARC_System_Zone_13",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-60.32378942],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone14",
    `PROJCS["WGS_1984_ARC_System_Zone_14",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-66.09421768],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone15",
    `PROJCS["WGS_1984_ARC_System_Zone_15",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-70.10896259],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone16",
    `PROJCS["WGS_1984_ARC_System_Zone_16",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-74.13230145],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone17",
    `PROJCS["WGS_1984_ARC_System_Zone_17",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",-78.1728375],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ARCSystemZone18",
    `PROJCS["WGS_1984_ARC_System_Zone_18",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Azimuthal_Equidistant"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]tude(lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984AustralianCentreforRemoteSensingLambert",
    `PROJCS["WGS_1984_Australian_Centre_for_Remote_Sensing_Lambert",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Standard_Parallel_1",-18.0],PARAMETER["Standard_Parallel_2",-36.0],PARAMETER["Latitude_Of_Origin",-27.0],UNIT["Meter",1.0]]`
  ],
  [
    "LKS1992LatviaTM0",
    `PROJCS["LKS_1992_Latvia_TM_0",GEOGCS["GCS_LKS_1992",DATUM["D_Latvia_1992",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TWD1967TMTaiwan",
    `PROJCS["TWD_1967_TM_Taiwan",GEOGCS["GCS_TWD_1967",DATUM["D_TWD_1967",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",121.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TWD1967TMPenghu",
    `PROJCS["TWD_1967_TM_Penghu",GEOGCS["GCS_TWD_1967",DATUM["D_TWD_1967",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",119.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TWD1997TMTaiwan",
    `PROJCS["TWD_1997_TM_Taiwan",GEOGCS["GCS_TWD_1997",DATUM["D_TWD_1997",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",121.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "TWD1997TMPenghu",
    `PROJCS["TWD_1997_TM_Penghu",GEOGCS["GCS_TWD_1997",DATUM["D_TWD_1997",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",119.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska1FIPS5001Feet",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_1_FIPS_5001_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",16404166.66666666],PARAMETER["False_Northing",-16404166.66666666],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",-36.86989764583333],PARAMETER["Longitude_Of_Center",-133.6666666666667],PARAMETER["Latitude_Of_Center",57.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlaska2FIPS5002Feet",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_2_FIPS_5002_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlaska3FIPS5003Feet",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_3_FIPS_5003_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-146.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Macao2008MacaoGrid",
    `PROJCS["Macao_2008_Macao_Grid",GEOGCS["GCS_MACAO_2008",DATUM["D_MACAO_2008",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",20000.0],PARAMETER["False_Northing",20000.0],PARAMETER["Central_Meridian",113.5364694444444],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.21239722222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MA11UTMZone55N",
    `PROJCS["NAD_1983_MA11_UTM_Zone_55N",GEOGCS["GCS_NAD_1983_MA11",DATUM["D_NAD_1983_MA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloBaerumKommune",
    `PROJCS["NGO_1948_Oslo_Baerum_Kommune",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",19999.32],PARAMETER["False_Northing",-202977.79],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloBergenhalvoen",
    `PROJCS["NGO_1948_Oslo_Bergenhalvoen",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",-200000.0],PARAMETER["Central_Meridian",-4.666666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "NGO1948OsloOsloKommune",
    `PROJCS["NGO_1948_Oslo_Oslo_Kommune",GEOGCS["GCS_NGO_1948_Oslo",DATUM["D_NGO_1948",SPHEROID["Bessel_Modified",6377492.018,299.1528128]],PRIMEM["Oslo",10.72291666666667],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-212979.18],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",58.0],UNIT["Meter",1.0]]`
  ],
  [
    "Luzon1911UTMZone50N",
    `PROJCS["Luzon_1911_UTM_Zone_50N",GEOGCS["GCS_Luzon_1911",DATUM["D_Luzon_1911",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Luzon1911UTMZone51N",
    `PROJCS["Luzon_1911_UTM_Zone_51N",GEOGCS["GCS_Luzon_1911",DATUM["D_Luzon_1911",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Luzon1911UTMZone52N",
    `PROJCS["Luzon_1911_UTM_Zone_52N",GEOGCS["GCS_Luzon_1911",DATUM["D_Luzon_1911",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PRS1992UTMZone50N",
    `PROJCS["PRS_1992_UTM_Zone_50N",GEOGCS["GCS_PRS_1992",DATUM["D_Philippine_Reference_System_1992",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PRS1992UTMZone51N",
    `PROJCS["PRS_1992_UTM_Zone_51N",GEOGCS["GCS_PRS_1992",DATUM["D_Philippine_Reference_System_1992",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PRS1992UTMZone52N",
    `PROJCS["PRS_1992_UTM_Zone_52N",GEOGCS["GCS_PRS_1992",DATUM["D_Philippine_Reference_System_1992",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983Idaho-AdaCounty",
    `PROJCS["NAD_1983_Idaho-Ada_County",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2625138.996430666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",1.00011328],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "HJAIAAirportGrid2Mar10",
    `PROJCS["HJAIA_AirportGrid_2Mar10",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Local"],PARAMETER["False_Easting",11233.741],PARAMETER["False_Northing",3076.34],PARAMETER["Scale_Factor",1.000047],PARAMETER["Azimuth",-0.01935],PARAMETER["Longitude_Of_Center",-84.4306922136],PARAMETER["Latitude_Of_Center",33.6340844042],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii1FIPS5101Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_1_FIPS_5101_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-155.5],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",18.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii2FIPS5102Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_2_FIPS_5102_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-156.6666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",20.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii3FIPS5103Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_3_FIPS_5103_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii4FIPS5104Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_4_FIPS_5104_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.5],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneHawaii5FIPS5105Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Hawaii_5_FIPS_5105_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.1666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",21.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaNorthFIPS2201Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_North_FIPS_2201_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaCentralFIPS2202Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_Central_FIPS_2202_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNStatePlaneMinnesotaSouthFIPS2203Feet",
    `PROJCS["NAD_1983_HARN_StatePlane_Minnesota_South_FIPS_2203_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNMississippiTM",
    `PROJCS["NAD_1983_HARN_Mississippi_TM",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",-89.75],PARAMETER["Scale_Factor",0.9998335],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "CapeLo15",
    `PROJCS["Cape_Lo15",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeLo17",
    `PROJCS["Cape_Lo17",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",17.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeLo19",
    `PROJCS["Cape_Lo19",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",19.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeLo21",
    `PROJCS["Cape_Lo21",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeLo23",
    `PROJCS["Cape_Lo23",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",23.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeLo25",
    `PROJCS["Cape_Lo25",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeLo27",
    `PROJCS["Cape_Lo27",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeLo29",
    `PROJCS["Cape_Lo29",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",29.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeLo31",
    `PROJCS["Cape_Lo31",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CapeLo33",
    `PROJCS["Cape_Lo33",GEOGCS["GCS_Cape",DATUM["D_Cape",SPHEROID["Clarke_1880_Arc",6378249.145,293.466307656]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo15",
    `PROJCS["Hartebeesthoek94_Lo15",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo17",
    `PROJCS["Hartebeesthoek94_Lo17",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",17.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo19",
    `PROJCS["Hartebeesthoek94_Lo19",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",19.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo21",
    `PROJCS["Hartebeesthoek94_Lo21",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo23",
    `PROJCS["Hartebeesthoek94_Lo23",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",23.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo25",
    `PROJCS["Hartebeesthoek94_Lo25",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo27",
    `PROJCS["Hartebeesthoek94_Lo27",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo29",
    `PROJCS["Hartebeesthoek94_Lo29",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",29.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo31",
    `PROJCS["Hartebeesthoek94_Lo31",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo33",
    `PROJCS["Hartebeesthoek94_Lo33",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",-1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GDBD2009GEORSO",
    `PROJCS["GDBD2009_GEORSO",GEOGCS["GCS_GDBD2009",DATUM["D_GDBD2009",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Rectified_Skew_Orthomorphic_Natural_Origin"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Scale_Factor",0.99984],PARAMETER["Azimuth",53.31580995],PARAMETER["Longitude_Of_Center",115.0],PARAMETER["Latitude_Of_Center",4.0],PARAMETER["XY_Plane_Rotation",53.13010235415598],UNIT["Meter",1.0]]`
  ],
  [
    "NordAlgerieAncienneDegree",
    `PROJCS["Nord_Algerie_Ancienne_Degree",GEOGCS["GCS_Voirol_1875",DATUM["D_Voirol_1875",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",2.7],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",0.999625544],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "SudAlgerieAncienneDegree",
    `PROJCS["Sud_Algerie_Ancienne_Degree",GEOGCS["GCS_Voirol_1875",DATUM["D_Voirol_1875",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",300000.0],PARAMETER["Central_Meridian",2.7],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Scale_Factor",0.999625769],PARAMETER["Latitude_Of_Origin",33.3],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11UTMZone4N",
    `PROJCS["NAD_1983_PA11_UTM_Zone_4N",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11UTMZone5N",
    `PROJCS["NAD_1983_PA11_UTM_Zone_5N",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MA11GuamMapGrid",
    `PROJCS["NAD_1983_MA11_Guam_Map_Grid",GEOGCS["GCS_NAD_1983_MA11",DATUM["D_NAD_1983_MA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",144.75],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",13.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11UTMZone2S",
    `PROJCS["NAD_1983_PA11_UTM_Zone_2S",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GOES-16EastABIFixedGridITRF2000incorrectGCS",
    `PROJCS["GOES-16_East_ABI_Fixed_Grid_ITRF2000_incorrect_GCS",GEOGCS["GCS_ITRF_2000",DATUM["D_ITRF_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Geostationary_Satellite"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Longitude_Of_Center",-75.0],PARAMETER["Height",35786023.0],PARAMETER["Option",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GOES-16EastABIFixedGridITRF2008",
    `PROJCS["GOES-16_East_ABI_Fixed_Grid_ITRF2008",GEOGCS["GCS_ITRF_2008",DATUM["D_ITRF_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Geostationary_Satellite"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Longitude_Of_Center",-75.0],PARAMETER["Height",35786023.0],PARAMETER["Option",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BelgeLambert1972badFEFN",
    `PROJCS["Belge_Lambert_1972_bad_FE_FN",GEOGCS["GCS_Belge_1972",DATUM["D_Belge_1972",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150000.01256],PARAMETER["False_Northing",5400088.4378],PARAMETER["Central_Meridian",4.367486666666666],PARAMETER["Standard_Parallel_1",49.8333339],PARAMETER["Standard_Parallel_2",51.16666723333333],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBakerNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Baker_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",131233.5958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.8333333333333],PARAMETER["Scale_Factor",1.00016],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSBend-BurnsNAD1983CORS96LCCFeetIntl",
    `PROJCS["OCRS_Bend-Burns_NAD_1983_CORS96_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",393700.7874015748],PARAMETER["False_Northing",196850.3937007874],PARAMETER["Central_Meridian",-119.75],PARAMETER["Standard_Parallel_1",43.66666666666666],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSBend-KlamathFallsNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Bend-Klamath_Falls_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.75],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSBend-Redmond-PrinevilleNAD1983CORS96LCCFeetIntl",
    `PROJCS["OCRS_Bend-Redmond-Prineville_NAD_1983_CORS96_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",426509.186351706],PARAMETER["Central_Meridian",-121.25],PARAMETER["Standard_Parallel_1",44.66666666666666],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",44.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSCanyonville-GrantsPassNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Canyonville-Grants_Pass_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",131233.5958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.00007],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSColumbiaRiverEastNAD1983CORS96LCCFeetIntl",
    `PROJCS["OCRS_Columbia_River_East_NAD_1983_CORS96_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",492125.9842519685],PARAMETER["False_Northing",98425.1968503937],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.66666666666666],PARAMETER["Scale_Factor",1.000008],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSColumbiaRiverWestNAD1983CORS96OMFeetIntl",
    `PROJCS["OCRS_Columbia_River_West_NAD_1983_CORS96_OM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",22965879.26509186],PARAMETER["False_Northing",-9842519.685039369],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",-65.0],PARAMETER["Longitude_Of_Center",-123.0],PARAMETER["Latitude_Of_Center",45.91666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSCottageGrove-CanyonvilleNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Cottage_Grove-Canyonville_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSDufur-MadrasNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Dufur-Madras_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.0],PARAMETER["Scale_Factor",1.00011],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSEugeneNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Eugene_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.1666666666667],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",43.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSGrantsPass-AshlandNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Grants_Pass-Ashland_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000043],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSGresham-WarmSpringsNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Gresham-Warm_Springs_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",32808.39895013123],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.3333333333333],PARAMETER["Scale_Factor",1.00005],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSLaGrandeNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_La_Grande_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",131233.5958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Scale_Factor",1.00013],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSOntarioNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Ontario_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262467.1916010499],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",1.0001],PARAMETER["Latitude_Of_Origin",43.25],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSOregonCoastNAD1983CORS96OMFeetIntl",
    `PROJCS["OCRS_Oregon_Coast_NAD_1983_CORS96_OM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",-984251.968503937],PARAMETER["False_Northing",-15091863.51706037],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",5.0],PARAMETER["Longitude_Of_Center",-124.05],PARAMETER["Latitude_Of_Center",44.75],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPendleton-LaGrandeNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Pendleton-La_Grande_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",98425.1968503937],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.3333333333333],PARAMETER["Scale_Factor",1.000175],PARAMETER["Latitude_Of_Origin",45.08333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPendletonNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Pendleton_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",196850.3937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.1666666666667],PARAMETER["Scale_Factor",1.000045],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSPortlandNAD1983CORS96LCCFeetIntl",
    `PROJCS["OCRS_Portland_NAD_1983_CORS96_LCC_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",328083.9895013123],PARAMETER["False_Northing",164041.9947506562],PARAMETER["Central_Meridian",-122.75],PARAMETER["Standard_Parallel_1",45.5],PARAMETER["Scale_Factor",1.000002],PARAMETER["Latitude_Of_Origin",45.5],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSSalemNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Salem_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.9947506562],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0833333333333],PARAMETER["Scale_Factor",1.00001],PARAMETER["Latitude_Of_Origin",44.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "OCRSSantiamPassNAD1983CORS96TMFeetIntl",
    `PROJCS["OCRS_Santiam_Pass_NAD_1983_CORS96_TM_Feet_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.5],PARAMETER["Scale_Factor",1.000155],PARAMETER["Latitude_Of_Origin",44.08333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii1FIPS5101",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_1_FIPS_5101",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-155.5],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",18.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii2FIPS5102",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_2_FIPS_5102",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-156.6666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",20.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii3FIPS5103",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_3_FIPS_5103",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii4FIPS5104",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_4_FIPS_5104",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.5],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii5FIPS5105",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_5_FIPS_5105",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.1666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",21.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii1FIPS5101Feet",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_1_FIPS_5101_Feet",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-155.5],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",18.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii2FIPS5102Feet",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_2_FIPS_5102_Feet",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-156.6666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",20.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii3FIPS5103Feet",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_3_FIPS_5103_Feet",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii4FIPS5104Feet",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_4_FIPS_5104_Feet",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.5],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983PA11StatePlaneHawaii5FIPS5105Feet",
    `PROJCS["NAD_1983_PA11_StatePlane_Hawaii_5_FIPS_5105_Feet",GEOGCS["GCS_NAD_1983_PA11",DATUM["D_NAD_1983_PA11",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.1666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",21.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "OCRSBakerNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Baker_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.8333333333333],PARAMETER["Scale_Factor",1.00016],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBend-BurnsNAD1983CORS96LCCMeters",
    `PROJCS["OCRS_Bend-Burns_NAD_1983_CORS96_LCC_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120000.0],PARAMETER["False_Northing",60000.0],PARAMETER["Central_Meridian",-119.75],PARAMETER["Standard_Parallel_1",43.66666666666666],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBend-KlamathFallsNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Bend-Klamath_Falls_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.75],PARAMETER["Scale_Factor",1.0002],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSBend-Redmond-PrinevilleNAD1983CORS96LCCMeters",
    `PROJCS["OCRS_Bend-Redmond-Prineville_NAD_1983_CORS96_LCC_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",130000.0],PARAMETER["Central_Meridian",-121.25],PARAMETER["Standard_Parallel_1",44.66666666666666],PARAMETER["Scale_Factor",1.00012],PARAMETER["Latitude_Of_Origin",44.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSCanyonville-GrantsPassNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Canyonville-Grants_Pass_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.00007],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSColumbiaRiverEastNAD1983CORS96LCCMeters",
    `PROJCS["OCRS_Columbia_River_East_NAD_1983_CORS96_LCC_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",30000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.66666666666666],PARAMETER["Scale_Factor",1.000008],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSColumbiaRiverWestNAD1983CORS96OMMeters",
    `PROJCS["OCRS_Columbia_River_West_NAD_1983_CORS96_OM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",7000000.0],PARAMETER["False_Northing",-3000000.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",-65.0],PARAMETER["Longitude_Of_Center",-123.0],PARAMETER["Latitude_Of_Center",45.91666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSCottageGrove-CanyonvilleNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Cottage_Grove-Canyonville_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSDufur-MadrasNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Dufur-Madras_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-121.0],PARAMETER["Scale_Factor",1.00011],PARAMETER["Latitude_Of_Origin",44.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSEugeneNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Eugene_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.1666666666667],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",43.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSGrantsPass-AshlandNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Grants_Pass-Ashland_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.3333333333333],PARAMETER["Scale_Factor",1.000043],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSGresham-WarmSpringsNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Gresham-Warm_Springs_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",10000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.3333333333333],PARAMETER["Scale_Factor",1.00005],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSLaGrandeNAD1983CORS96TMMeters",
    `PROJCS["OCRS_La_Grande_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",40000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Scale_Factor",1.00013],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSOntarioNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Ontario_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",80000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",1.0001],PARAMETER["Latitude_Of_Origin",43.25],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSOregonCoastNAD1983CORS96OMMeters",
    `PROJCS["OCRS_Oregon_Coast_NAD_1983_CORS96_OM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",-300000.0],PARAMETER["False_Northing",-4600000.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Azimuth",5.0],PARAMETER["Longitude_Of_Center",-124.05],PARAMETER["Latitude_Of_Center",44.75],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPendleton-LaGrandeNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Pendleton-La_Grande_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",30000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-118.3333333333333],PARAMETER["Scale_Factor",1.000175],PARAMETER["Latitude_Of_Origin",45.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPendletonNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Pendleton_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",60000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-119.1666666666667],PARAMETER["Scale_Factor",1.000045],PARAMETER["Latitude_Of_Origin",45.25],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSPortlandNAD1983CORS96LCCMeters",
    `PROJCS["OCRS_Portland_NAD_1983_CORS96_LCC_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",50000.0],PARAMETER["Central_Meridian",-122.75],PARAMETER["Standard_Parallel_1",45.5],PARAMETER["Scale_Factor",1.000002],PARAMETER["Latitude_Of_Origin",45.5],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSSalemNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Salem_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",50000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-123.0833333333333],PARAMETER["Scale_Factor",1.00001],PARAMETER["Latitude_Of_Origin",44.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "OCRSSantiamPassNAD1983CORS96TMMeters",
    `PROJCS["OCRS_Santiam_Pass_NAD_1983_CORS96_TM_Meters",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-122.5],PARAMETER["Scale_Factor",1.000155],PARAMETER["Latitude_Of_Origin",44.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey9",
    `PROJCS["ED_1950_Turkey_9",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey10",
    `PROJCS["ED_1950_Turkey_10",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",10500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",30.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey11",
    `PROJCS["ED_1950_Turkey_11",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",11500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey12",
    `PROJCS["ED_1950_Turkey_12",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",12500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",36.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey13",
    `PROJCS["ED_1950_Turkey_13",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",13500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey14",
    `PROJCS["ED_1950_Turkey_14",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",14500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",42.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ED1950Turkey15",
    `PROJCS["ED_1950_Turkey_15",GEOGCS["GCS_European_1950",DATUM["D_European_1950",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",15500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kyrg-06TMZone1",
    `PROJCS["Kyrg-06_TM_Zone_1",GEOGCS["GCS_Kyrg-06",DATUM["D_Kyrgyz_Republic_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1300000.0],PARAMETER["False_Northing",14743.5],PARAMETER["Central_Meridian",68.51666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kyrg-06TMZone2",
    `PROJCS["Kyrg-06_TM_Zone_2",GEOGCS["GCS_Kyrg-06",DATUM["D_Kyrgyz_Republic_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2300000.0],PARAMETER["False_Northing",14743.5],PARAMETER["Central_Meridian",71.51666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kyrg-06TMZone3",
    `PROJCS["Kyrg-06_TM_Zone_3",GEOGCS["GCS_Kyrg-06",DATUM["D_Kyrgyz_Republic_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3300000.0],PARAMETER["False_Northing",14743.5],PARAMETER["Central_Meridian",74.51666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kyrg-06TMZone4",
    `PROJCS["Kyrg-06_TM_Zone_4",GEOGCS["GCS_Kyrg-06",DATUM["D_Kyrgyz_Republic_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4300000.0],PARAMETER["False_Northing",14743.5],PARAMETER["Central_Meridian",77.51666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Kyrg-06TMZone5",
    `PROJCS["Kyrg-06_TM_Zone_5",GEOGCS["GCS_Kyrg-06",DATUM["D_Kyrgyz_Republic_2006",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5300000.0],PARAMETER["False_Northing",14743.5],PARAMETER["Central_Meridian",80.51666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo19(E-N)",
    `PROJCS["Hartebeesthoek94_Lo19_(E-N)",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",19.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo21(E-N)",
    `PROJCS["Hartebeesthoek94_Lo21_(E-N)",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo23(E-N)",
    `PROJCS["Hartebeesthoek94_Lo23_(E-N)",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",23.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo25(E-N)",
    `PROJCS["Hartebeesthoek94_Lo25_(E-N)",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",25.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo27(E-N)",
    `PROJCS["Hartebeesthoek94_Lo27_(E-N)",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo29(E-N)",
    `PROJCS["Hartebeesthoek94_Lo29_(E-N)",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",29.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo31(E-N)",
    `PROJCS["Hartebeesthoek94_Lo31_(E-N)",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",31.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NewBeijing3DegreeGaussKrugerCM132E",
    `PROJCS["New_Beijing_3_Degree_Gauss_Kruger_CM_132E",GEOGCS["GCS_New_Beijing",DATUM["D_New_Beijing",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ComplexUTMZone20N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_20N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ComplexUTMZone21N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_21N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ComplexUTMZone22N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_22N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ComplexUTMZone23N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_23N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ComplexUTMZone24N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_24N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ComplexUTMZone25N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_25N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ComplexUTMZone26N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_26N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ComplexUTMZone27N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_27N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ComplexUTMZone28N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_28N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984ComplexUTMZone29N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_29N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "WGS1984ComplexUTMZone30N",
    `PROJCS["WGS_1984_Complex_UTM_Zone_30N",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator_Complex"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "NTFFranceIdegrees",
    `PROJCS["NTF_France_I_degrees",GEOGCS["GCS_NTF",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Central_Meridian",2.337229166666667],PARAMETER["Standard_Parallel_1",49.5],PARAMETER["Scale_Factor",0.999877341],PARAMETER["Latitude_Of_Origin",49.5],UNIT["Meter",1.0]]`
  ],
  [
    "NTFFranceIIdegrees",
    `PROJCS["NTF_France_II_degrees",GEOGCS["GCS_NTF",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2200000.0],PARAMETER["Central_Meridian",2.337229166666667],PARAMETER["Standard_Parallel_1",46.8],PARAMETER["Scale_Factor",0.99987742],PARAMETER["Latitude_Of_Origin",46.8],UNIT["Meter",1.0]]`
  ],
  [
    "NTFFranceIIIdegrees",
    `PROJCS["NTF_France_III_degrees",GEOGCS["GCS_NTF",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",3200000.0],PARAMETER["Central_Meridian",2.337229166666667],PARAMETER["Standard_Parallel_1",44.1],PARAMETER["Scale_Factor",0.999877499],PARAMETER["Latitude_Of_Origin",44.1],UNIT["Meter",1.0]]`
  ],
  [
    "NTFFranceIVdegrees",
    `PROJCS["NTF_France_IV_degrees",GEOGCS["GCS_NTF",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",234.358],PARAMETER["False_Northing",4185861.369],PARAMETER["Central_Meridian",2.337229166666667],PARAMETER["Standard_Parallel_1",42.165],PARAMETER["Scale_Factor",0.99994471],PARAMETER["Latitude_Of_Origin",42.165],UNIT["Meter",1.0]]`
  ],
  [
    "NTFLambertZoneI",
    `PROJCS["NTF_Lambert_Zone_I",GEOGCS["GCS_NTF",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Central_Meridian",2.337229166666667],PARAMETER["Standard_Parallel_1",49.5],PARAMETER["Scale_Factor",0.999877341],PARAMETER["Latitude_Of_Origin",49.5],UNIT["Meter",1.0]]`
  ],
  [
    "NTFLambertZoneII",
    `PROJCS["NTF_Lambert_Zone_II",GEOGCS["GCS_NTF",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2200000.0],PARAMETER["Central_Meridian",2.337229166666667],PARAMETER["Standard_Parallel_1",46.8],PARAMETER["Scale_Factor",0.99987742],PARAMETER["Latitude_Of_Origin",46.8],UNIT["Meter",1.0]]`
  ],
  [
    "NTFLambertZoneIII",
    `PROJCS["NTF_Lambert_Zone_III",GEOGCS["GCS_NTF",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",3200000.0],PARAMETER["Central_Meridian",2.337229166666667],PARAMETER["Standard_Parallel_1",44.1],PARAMETER["Scale_Factor",0.999877499],PARAMETER["Latitude_Of_Origin",44.1],UNIT["Meter",1.0]]`
  ],
  [
    "NTFLambertZoneIV",
    `PROJCS["NTF_Lambert_Zone_IV",GEOGCS["GCS_NTF",DATUM["D_NTF",SPHEROID["Clarke_1880_IGN",6378249.2,293.4660212936265]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",234.358],PARAMETER["False_Northing",4185861.369],PARAMETER["Central_Meridian",2.337229166666667],PARAMETER["Standard_Parallel_1",42.165],PARAMETER["Scale_Factor",0.99994471],PARAMETER["Latitude_Of_Origin",42.165],UNIT["Meter",1.0]]`
  ],
  [
    "PanhandleEnergyAlbers",
    `PROJCS["Panhandle_Energy_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",31.0],PARAMETER["Standard_Parallel_2",41.0],PARAMETER["Latitude_Of_Origin",25.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Tananarive1925LabordeGrid",
    `PROJCS["Tananarive_1925_Laborde_Grid",GEOGCS["GCS_Tananarive_1925",DATUM["D_Tananarive_1925",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Laborde_Oblique_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",800000.0],PARAMETER["Scale_Factor",0.9995],PARAMETER["Azimuth",18.9],PARAMETER["Longitude_Of_Center",46.43722916666667],PARAMETER["Latitude_Of_Center",-18.9],UNIT["Meter",1.0]]`
  ],
  [
    "NordAlgerieDegree",
    `PROJCS["Nord_Algerie_Degree",GEOGCS["GCS_Voirol_Unifie_1960_Degree",DATUM["D_Voirol_Unifie_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500135.0],PARAMETER["False_Northing",300090.0],PARAMETER["Central_Meridian",2.7],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",0.999625544],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "SudAlgerieDegree",
    `PROJCS["Sud_Algerie_Degree",GEOGCS["GCS_Voirol_Unifie_1960_Degree",DATUM["D_Voirol_Unifie_1960",SPHEROID["Clarke_1880_RGS",6378249.145,293.465]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500135.0],PARAMETER["False_Northing",300090.0],PARAMETER["Central_Meridian",2.7],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Scale_Factor",0.999625769],PARAMETER["Latitude_Of_Origin",33.3],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone51N",
    `PROJCS["JGD_2011_UTM_Zone_51N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",123.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone52N",
    `PROJCS["JGD_2011_UTM_Zone_52N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone53N",
    `PROJCS["JGD_2011_UTM_Zone_53N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",135.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone54N",
    `PROJCS["JGD_2011_UTM_Zone_54N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",141.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone55N",
    `PROJCS["JGD_2011_UTM_Zone_55N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",147.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011UTMZone56N",
    `PROJCS["JGD_2011_UTM_Zone_56N",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984CaliforniaTealeAlbersFtUS",
    `PROJCS["WGS_1984_California_Teale_Albers_FtUS",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",34.0],PARAMETER["Standard_Parallel_2",40.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CaliforniaTealeAlbersFtUS",
    `PROJCS["NAD_1983_California_Teale_Albers_FtUS",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",34.0],PARAMETER["Standard_Parallel_2",40.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983TexasCentricMappingSystemAlbers",
    `PROJCS["NAD_1983_Texas_Centric_Mapping_System_Albers",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983TexasCentricMappingSystemLambert",
    `PROJCS["NAD_1983_Texas_Centric_Mapping_System_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983TexasStatewideMappingSystem",
    `PROJCS["NAD_1983_Texas_Statewide_Mapping_System",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.41666666666667],PARAMETER["Standard_Parallel_2",34.91666666666666],PARAMETER["Latitude_Of_Origin",31.16666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983GeorgiaStatewideLambert",
    `PROJCS["NAD_1983_Georgia_Statewide_Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-83.5],PARAMETER["Standard_Parallel_1",31.41666666666667],PARAMETER["Standard_Parallel_2",34.28333333333333],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983IdahoTM",
    `PROJCS["NAD_1983_Idaho_TM",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",1200000.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983Maine2000EastZone",
    `PROJCS["NAD_1983_Maine_2000_East_Zone",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.875],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983Maine2000CentralZone",
    `PROJCS["NAD_1983_Maine_2000_Central_Zone",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.125],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983Maine2000WestZone",
    `PROJCS["NAD_1983_Maine_2000_West_Zone",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.375],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983MississippiTM",
    `PROJCS["NAD_1983_Mississippi_TM",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",-89.75],PARAMETER["Scale_Factor",0.9998335],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone1",
    `PROJCS["JGD_2011_Japan_Zone_1",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",129.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone2",
    `PROJCS["JGD_2011_Japan_Zone_2",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone3",
    `PROJCS["JGD_2011_Japan_Zone_3",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",132.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone4",
    `PROJCS["JGD_2011_Japan_Zone_4",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",133.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",33.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone5",
    `PROJCS["JGD_2011_Japan_Zone_5",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",134.3333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone6",
    `PROJCS["JGD_2011_Japan_Zone_6",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",136.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone7",
    `PROJCS["JGD_2011_Japan_Zone_7",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",137.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone8",
    `PROJCS["JGD_2011_Japan_Zone_8",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",138.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone9",
    `PROJCS["JGD_2011_Japan_Zone_9",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",139.8333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone10",
    `PROJCS["JGD_2011_Japan_Zone_10",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",140.8333333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone11",
    `PROJCS["JGD_2011_Japan_Zone_11",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",140.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone12",
    `PROJCS["JGD_2011_Japan_Zone_12",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",142.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone13",
    `PROJCS["JGD_2011_Japan_Zone_13",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",144.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone14",
    `PROJCS["JGD_2011_Japan_Zone_14",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone15",
    `PROJCS["JGD_2011_Japan_Zone_15",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",127.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone16",
    `PROJCS["JGD_2011_Japan_Zone_16",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",124.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone17",
    `PROJCS["JGD_2011_Japan_Zone_17",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",131.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone18",
    `PROJCS["JGD_2011_Japan_Zone_18",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",136.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",20.0],UNIT["Meter",1.0]]`
  ],
  [
    "JGD2011JapanZone19",
    `PROJCS["JGD_2011_Japan_Zone_19",GEOGCS["GCS_JGD_2011",DATUM["D_JGD_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneAlabamaEastFIPS0101Feet",
    `PROJCS["NAD_1983_StatePlane_Alabama_East_FIPS_0101_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlabamaWestFIPS0102Feet",
    `PROJCS["NAD_1983_StatePlane_Alabama_West_FIPS_0102_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlaska1FIPS5001Feet",
    `PROJCS["NAD_1983_StatePlane_Alaska_1_FIPS_5001_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",16404166.66666666],PARAMETER["False_Northing",-16404166.66666666],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",-36.86989764583333],PARAMETER["Longitude_Of_Center",-133.6666666666667],PARAMETER["Latitude_Of_Center",57.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlaska2FIPS5002Feet",
    `PROJCS["NAD_1983_StatePlane_Alaska_2_FIPS_5002_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlaska3FIPS5003Feet",
    `PROJCS["NAD_1983_StatePlane_Alaska_3_FIPS_5003_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-146.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlaska4FIPS5004Feet",
    `PROJCS["NAD_1983_StatePlane_Alaska_4_FIPS_5004_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlaska5FIPS5005Feet",
    `PROJCS["NAD_1983_StatePlane_Alaska_5_FIPS_5005_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlaska6FIPS5006Feet",
    `PROJCS["NAD_1983_StatePlane_Alaska_6_FIPS_5006_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlaska7FIPS5007Feet",
    `PROJCS["NAD_1983_StatePlane_Alaska_7_FIPS_5007_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-162.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlaska8FIPS5008Feet",
    `PROJCS["NAD_1983_StatePlane_Alaska_8_FIPS_5008_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-166.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlaska9FIPS5009Feet",
    `PROJCS["NAD_1983_StatePlane_Alaska_9_FIPS_5009_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-170.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneAlaska10FIPS5010Feet",
    `PROJCS["NAD_1983_StatePlane_Alaska_10_FIPS_5010_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-176.0],PARAMETER["Standard_Parallel_1",51.83333333333334],PARAMETER["Standard_Parallel_2",53.83333333333334],PARAMETER["Latitude_Of_Origin",51.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIFIPS0401Feet",
    `PROJCS["NAD_1983_StatePlane_California_I_FIPS_0401_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIIFIPS0402Feet",
    `PROJCS["NAD_1983_StatePlane_California_II_FIPS_0402_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIIIFIPS0403Feet",
    `PROJCS["NAD_1983_StatePlane_California_III_FIPS_0403_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaIVFIPS0404Feet",
    `PROJCS["NAD_1983_StatePlane_California_IV_FIPS_0404_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaVFIPS0405Feet",
    `PROJCS["NAD_1983_StatePlane_California_V_FIPS_0405_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneCaliforniaVIFIPS0406Feet",
    `PROJCS["NAD_1983_StatePlane_California_VI_FIPS_0406_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007StatePlanePuertoRicoVirginIslsFIPS5200",
    `PROJCS["NAD_1983_NSRS2007_StatePlane_Puerto_Rico_Virgin_Isls_FIPS_5200",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneArizonaEastFIPS0201Feet",
    `PROJCS["NAD_1983_StatePlane_Arizona_East_FIPS_0201_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",699998.6],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneArizonaCentralFIPS0202Feet",
    `PROJCS["NAD_1983_StatePlane_Arizona_Central_FIPS_0202_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",699998.6],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneArizonaWestFIPS0203Feet",
    `PROJCS["NAD_1983_StatePlane_Arizona_West_FIPS_0203_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",699998.6],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneArkansasNorthFIPS0301Feet",
    `PROJCS["NAD_1983_StatePlane_Arkansas_North_FIPS_0301_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneArkansasSouthFIPS0302Feet",
    `PROJCS["NAD_1983_StatePlane_Arkansas_South_FIPS_0302_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneColoradoNorthFIPS0501Feet",
    `PROJCS["NAD_1983_StatePlane_Colorado_North_FIPS_0501_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneColoradoCentralFIPS0502Feet",
    `PROJCS["NAD_1983_StatePlane_Colorado_Central_FIPS_0502_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneColoradoSouthFIPS0503Feet",
    `PROJCS["NAD_1983_StatePlane_Colorado_South_FIPS_0503_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneConnecticutFIPS0600Feet",
    `PROJCS["NAD_1983_StatePlane_Connecticut_FIPS_0600_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",999999.999996],PARAMETER["False_Northing",499999.999998],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneDelawareFIPS0700Feet",
    `PROJCS["NAD_1983_StatePlane_Delaware_FIPS_0700_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneFloridaEastFIPS0901Feet",
    `PROJCS["NAD_1983_StatePlane_Florida_East_FIPS_0901_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneFloridaWestFIPS0902Feet",
    `PROJCS["NAD_1983_StatePlane_Florida_West_FIPS_0902_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneFloridaNorthFIPS0903Feet",
    `PROJCS["NAD_1983_StatePlane_Florida_North_FIPS_0903_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneHawaii1FIPS5101Feet",
    `PROJCS["NAD_1983_StatePlane_Hawaii_1_FIPS_5101_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-155.5],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",18.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneHawaii2FIPS5102Feet",
    `PROJCS["NAD_1983_StatePlane_Hawaii_2_FIPS_5102_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-156.6666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",20.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneHawaii3FIPS5103Feet",
    `PROJCS["NAD_1983_StatePlane_Hawaii_3_FIPS_5103_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.16666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneHawaii4FIPS5104Feet",
    `PROJCS["NAD_1983_StatePlane_Hawaii_4_FIPS_5104_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.5],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",21.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneHawaii5FIPS5105Feet",
    `PROJCS["NAD_1983_StatePlane_Hawaii_5_FIPS_5105_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-160.1666666666667],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",21.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneGeorgiaEastFIPS1001Feet",
    `PROJCS["NAD_1983_StatePlane_Georgia_East_FIPS_1001_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneGeorgiaWestFIPS1002Feet",
    `PROJCS["NAD_1983_StatePlane_Georgia_West_FIPS_1002_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIdahoEastFIPS1101Feet",
    `PROJCS["NAD_1983_StatePlane_Idaho_East_FIPS_1101_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIdahoCentralFIPS1102Feet",
    `PROJCS["NAD_1983_StatePlane_Idaho_Central_FIPS_1102_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIdahoWestFIPS1103Feet",
    `PROJCS["NAD_1983_StatePlane_Idaho_West_FIPS_1103_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIllinoisEastFIPS1201Feet",
    `PROJCS["NAD_1983_StatePlane_Illinois_East_FIPS_1201_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIllinoisWestFIPS1202Feet",
    `PROJCS["NAD_1983_StatePlane_Illinois_West_FIPS_1202_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIndianaEastFIPS1301Feet",
    `PROJCS["NAD_1983_StatePlane_Indiana_East_FIPS_1301_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIndianaWestFIPS1302Feet",
    `PROJCS["NAD_1983_StatePlane_Indiana_West_FIPS_1302_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIowaNorthFIPS1401Feet",
    `PROJCS["NAD_1983_StatePlane_Iowa_North_FIPS_1401_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneIowaSouthFIPS1402Feet",
    `PROJCS["NAD_1983_StatePlane_Iowa_South_FIPS_1402_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneKansasNorthFIPS1501Feet",
    `PROJCS["NAD_1983_StatePlane_Kansas_North_FIPS_1501_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneKansasSouthFIPS1502Feet",
    `PROJCS["NAD_1983_StatePlane_Kansas_South_FIPS_1502_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneKentuckyNorthFIPS1601Feet",
    `PROJCS["NAD_1983_StatePlane_Kentucky_North_FIPS_1601_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneKentuckySouthFIPS1602Feet",
    `PROJCS["NAD_1983_StatePlane_Kentucky_South_FIPS_1602_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneLouisianaNorthFIPS1701Feet",
    `PROJCS["NAD_1983_StatePlane_Louisiana_North_FIPS_1701_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneLouisianaSouthFIPS1702Feet",
    `PROJCS["NAD_1983_StatePlane_Louisiana_South_FIPS_1702_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMaineEastFIPS1801Feet",
    `PROJCS["NAD_1983_StatePlane_Maine_East_FIPS_1801_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMaineWestFIPS1802Feet",
    `PROJCS["NAD_1983_StatePlane_Maine_West_FIPS_1802_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMarylandFIPS1900Feet",
    `PROJCS["NAD_1983_StatePlane_Maryland_FIPS_1900_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMassachusettsMainlandFIPS2001Feet",
    `PROJCS["NAD_1983_StatePlane_Massachusetts_Mainland_FIPS_2001_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",2460625.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMassachusettsIslandFIPS2002Feet",
    `PROJCS["NAD_1983_StatePlane_Massachusetts_Island_FIPS_2002_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMichiganNorthFIPS2111Feet",
    `PROJCS["NAD_1983_StatePlane_Michigan_North_FIPS_2111_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",26246666.66666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMichiganCentralFIPS2112Feet",
    `PROJCS["NAD_1983_StatePlane_Michigan_Central_FIPS_2112_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",19685000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMichiganSouthFIPS2113Feet",
    `PROJCS["NAD_1983_StatePlane_Michigan_South_FIPS_2113_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",13123333.33333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMinnesotaNorthFIPS2201Feet",
    `PROJCS["NAD_1983_StatePlane_Minnesota_North_FIPS_2201_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMinnesotaCentralFIPS2202Feet",
    `PROJCS["NAD_1983_StatePlane_Minnesota_Central_FIPS_2202_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMinnesotaSouthFIPS2203Feet",
    `PROJCS["NAD_1983_StatePlane_Minnesota_South_FIPS_2203_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMississippiEastFIPS2301Feet",
    `PROJCS["NAD_1983_StatePlane_Mississippi_East_FIPS_2301_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMississippiWestFIPS2302Feet",
    `PROJCS["NAD_1983_StatePlane_Mississippi_West_FIPS_2302_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMissouriEastFIPS2401Feet",
    `PROJCS["NAD_1983_StatePlane_Missouri_East_FIPS_2401_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",820208.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMissouriCentralFIPS2402Feet",
    `PROJCS["NAD_1983_StatePlane_Missouri_Central_FIPS_2402_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMissouriWestFIPS2403Feet",
    `PROJCS["NAD_1983_StatePlane_Missouri_West_FIPS_2403_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2788708.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.5],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1927StatePlaneCaliforniaVVentura",
    `PROJCS["NAD_1927_StatePlane_California_V_Ventura",GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",408000.0],PARAMETER["False_Northing",-266000.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneMontanaFIPS2500Feet",
    `PROJCS["NAD_1983_StatePlane_Montana_FIPS_2500_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983PACP00UTMZone4N",
    `PROJCS["NAD_1983_PACP00_UTM_Zone_4N",GEOGCS["GCS_NAD_1983_PACP00",DATUM["D_NAD_1983_PACP00",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-159.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PACP00UTMZone5N",
    `PROJCS["NAD_1983_PACP00_UTM_Zone_5N",GEOGCS["GCS_NAD_1983_PACP00",DATUM["D_NAD_1983_PACP00",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-153.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983PACP00UTMZone2S",
    `PROJCS["NAD_1983_PACP00_UTM_Zone_2S",GEOGCS["GCS_NAD_1983_PACP00",DATUM["D_NAD_1983_PACP00",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-171.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNebraskaFIPS2600Feet",
    `PROJCS["NAD_1983_StatePlane_Nebraska_FIPS_2600_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NebraskaLancasterCountyFtUS",
    `PROJCS["NAD_1983_Nebraska_Lancaster_County_FtUS",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",164041.6666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.68805555555556],PARAMETER["Scale_Factor",1.000054615],PARAMETER["Latitude_Of_Origin",40.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Palestine1923PalestineGridTM",
    `PROJCS["Palestine_1923_Palestine_Grid_TM",GEOGCS["GCS_Palestine_1923",DATUM["D_Palestine_1923",SPHEROID["Clarke_1880_Benoit",6378300.789,293.4663155389802]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",170251.555],PARAMETER["False_Northing",126867.909],PARAMETER["Central_Meridian",35.21208055555556],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",31.73409694444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneNevadaEastFIPS2701Feet",
    `PROJCS["NAD_1983_StatePlane_Nevada_East_FIPS_2701_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",26246666.66666666],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNevadaCentralFIPS2702Feet",
    `PROJCS["NAD_1983_StatePlane_Nevada_Central_FIPS_2702_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",19685000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNevadaWestFIPS2703Feet",
    `PROJCS["NAD_1983_StatePlane_Nevada_West_FIPS_2703_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewHampshireFIPS2800Feet",
    `PROJCS["NAD_1983_StatePlane_New_Hampshire_FIPS_2800_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewJerseyFIPS2900Feet",
    `PROJCS["NAD_1983_StatePlane_New_Jersey_FIPS_2900_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewMexicoEastFIPS3001Feet",
    `PROJCS["NAD_1983_StatePlane_New_Mexico_East_FIPS_3001_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",541337.5],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewMexicoCentralFIPS3002Feet",
    `PROJCS["NAD_1983_StatePlane_New_Mexico_Central_FIPS_3002_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewMexicoWestFIPS3003Feet",
    `PROJCS["NAD_1983_StatePlane_New_Mexico_West_FIPS_3003_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2723091.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewYorkEastFIPS3101Feet",
    `PROJCS["NAD_1983_StatePlane_New_York_East_FIPS_3101_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewYorkCentralFIPS3102Feet",
    `PROJCS["NAD_1983_StatePlane_New_York_Central_FIPS_3102_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",820208.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewYorkWestFIPS3103Feet",
    `PROJCS["NAD_1983_StatePlane_New_York_West_FIPS_3103_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1148291.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNewYorkLongIslandFIPS3104Feet",
    `PROJCS["NAD_1983_StatePlane_New_York_Long_Island_FIPS_3104_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNorthCarolinaFIPS3200Feet",
    `PROJCS["NAD_1983_StatePlane_North_Carolina_FIPS_3200_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.002616666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNorthDakotaNorthFIPS3301Feet",
    `PROJCS["NAD_1983_StatePlane_North_Dakota_North_FIPS_3301_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneNorthDakotaSouthFIPS3302Feet",
    `PROJCS["NAD_1983_StatePlane_North_Dakota_South_FIPS_3302_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneOhioNorthFIPS3401Feet",
    `PROJCS["NAD_1983_StatePlane_Ohio_North_FIPS_3401_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneOhioSouthFIPS3402Feet",
    `PROJCS["NAD_1983_StatePlane_Ohio_South_FIPS_3402_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneOklahomaNorthFIPS3501Feet",
    `PROJCS["NAD_1983_StatePlane_Oklahoma_North_FIPS_3501_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneOklahomaSouthFIPS3502Feet",
    `PROJCS["NAD_1983_StatePlane_Oklahoma_South_FIPS_3502_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneOregonNorthFIPS3601Feet",
    `PROJCS["NAD_1983_StatePlane_Oregon_North_FIPS_3601_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8202083.333333332],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneOregonSouthFIPS3602Feet",
    `PROJCS["NAD_1983_StatePlane_Oregon_South_FIPS_3602_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlanePennsylvaniaNorthFIPS3701Feet",
    `PROJCS["NAD_1983_StatePlane_Pennsylvania_North_FIPS_3701_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlanePennsylvaniaSouthFIPS3702Feet",
    `PROJCS["NAD_1983_StatePlane_Pennsylvania_South_FIPS_3702_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneRhodeIslandFIPS3800Feet",
    `PROJCS["NAD_1983_StatePlane_Rhode_Island_FIPS_3800_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "PANAMAITRF08UTM17N",
    `PROJCS["PANAMA_ITRF08_UTM_17N",GEOGCS["GCS_PANAMA08_2011",DATUM["PANAMA08_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PANAMAITRF08UTM18N",
    `PROJCS["PANAMA_ITRF08_UTM_18N",GEOGCS["GCS_PANAMA08_2011",DATUM["PANAMA08_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneSouthCarolinaFIPS3900Feet",
    `PROJCS["NAD_1983_StatePlane_South_Carolina_FIPS_3900_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1999996.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneSouthDakotaNorthFIPS4001Feet",
    `PROJCS["NAD_1983_StatePlane_South_Dakota_North_FIPS_4001_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneSouthDakotaSouthFIPS4002Feet",
    `PROJCS["NAD_1983_StatePlane_South_Dakota_South_FIPS_4002_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTennesseeFIPS4100Feet",
    `PROJCS["NAD_1983_StatePlane_Tennessee_FIPS_4100_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTexasNorthFIPS4201Feet",
    `PROJCS["NAD_1983_StatePlane_Texas_North_FIPS_4201_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTexasNorthCentralFIPS4202Feet",
    `PROJCS["NAD_1983_StatePlane_Texas_North_Central_FIPS_4202_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTexasCentralFIPS4203Feet",
    `PROJCS["NAD_1983_StatePlane_Texas_Central_FIPS_4203_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTexasSouthCentralFIPS4204Feet",
    `PROJCS["NAD_1983_StatePlane_Texas_South_Central_FIPS_4204_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneTexasSouthFIPS4205Feet",
    `PROJCS["NAD_1983_StatePlane_Texas_South_FIPS_4205_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",16404166.66666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneUtahNorthFIPS4301Feet",
    `PROJCS["NAD_1983_StatePlane_Utah_North_FIPS_4301_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneUtahCentralFIPS4302Feet",
    `PROJCS["NAD_1983_StatePlane_Utah_Central_FIPS_4302_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneUtahSouthFIPS4303Feet",
    `PROJCS["NAD_1983_StatePlane_Utah_South_FIPS_4303_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneVermontFIPS4400Feet",
    `PROJCS["NAD_1983_StatePlane_Vermont_FIPS_4400_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneVirginiaNorthFIPS4501Feet",
    `PROJCS["NAD_1983_StatePlane_Virginia_North_FIPS_4501_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneVirginiaSouthFIPS4502Feet",
    `PROJCS["NAD_1983_StatePlane_Virginia_South_FIPS_4502_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWashingtonNorthFIPS4601Feet",
    `PROJCS["NAD_1983_StatePlane_Washington_North_FIPS_4601_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWashingtonSouthFIPS4602Feet",
    `PROJCS["NAD_1983_StatePlane_Washington_South_FIPS_4602_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWestVirginiaNorthFIPS4701Feet",
    `PROJCS["NAD_1983_StatePlane_West_Virginia_North_FIPS_4701_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWestVirginiaSouthFIPS4702Feet",
    `PROJCS["NAD_1983_StatePlane_West_Virginia_South_FIPS_4702_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWisconsinNorthFIPS4801Feet",
    `PROJCS["NAD_1983_StatePlane_Wisconsin_North_FIPS_4801_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWisconsinCentralFIPS4802Feet",
    `PROJCS["NAD_1983_StatePlane_Wisconsin_Central_FIPS_4802_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWisconsinSouthFIPS4803Feet",
    `PROJCS["NAD_1983_StatePlane_Wisconsin_South_FIPS_4803_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWyomingEastFIPS4901Feet",
    `PROJCS["NAD_1983_StatePlane_Wyoming_East_FIPS_4901_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWyomingEastCentralFIPS4902Feet",
    `PROJCS["NAD_1983_StatePlane_Wyoming_East_Central_FIPS_4902_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWyomingWestCentralFIPS4903Feet",
    `PROJCS["NAD_1983_StatePlane_Wyoming_West_Central_FIPS_4903_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983StatePlaneWyomingWestFIPS4904Feet",
    `PROJCS["NAD_1983_StatePlane_Wyoming_West_FIPS_4904_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "WGS1984CaliforniaTealeAlbersFtIntl",
    `PROJCS["WGS_1984_California_Teale_Albers_Ft_Intl",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",34.0],PARAMETER["Standard_Parallel_2",40.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CaliforniaTealeAlbersFtIntl",
    `PROJCS["NAD_1983_California_Teale_Albers_Ft_Intl",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",34.0],PARAMETER["Standard_Parallel_2",40.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983StatePlanePuertoRicoVirginIslandsFIPS5200Feet",
    `PROJCS["NAD_1983_StatePlane_Puerto_Rico_Virgin_Islands_FIPS_5200_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",656166.6666666665],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Katanga1955KatangaLambert",
    `PROJCS["Katanga_1955_Katanga_Lambert",GEOGCS["GCS_Katanga_1955",DATUM["D_Katanga_1955",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",26.0],PARAMETER["Standard_Parallel_1",-6.5],PARAMETER["Standard_Parallel_2",-11.5],PARAMETER["Latitude_Of_Origin",-9.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneKentuckyFIPS1600Feet",
    `PROJCS["NAD_1983_StatePlane_Kentucky_FIPS_1600_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone7",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_7",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Pulkovo1942Adj19833DegreeGKZone8",
    `PROJCS["Pulkovo_1942_Adj_1983_3_Degree_GK_Zone_8",GEOGCS["GCS_Pulkovo_1942_Adj_1983",DATUM["D_Pulkovo_1942_Adj_1983",SPHEROID["Krasovsky_1940",6378245.0,298.3]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",8500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983StatePlaneGuamFIPS5400Feet",
    `PROJCS["NAD_1983_StatePlane_Guam_FIPS_5400_Feet",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Polyconic"],PARAMETER["False_Easting",164041.6666666666],PARAMETER["False_Northing",164041.6666666666],PARAMETER["Central_Meridian",144.7487507055556],PARAMETER["Latitude_Of_Origin",13.47246635277778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "MAGNALeticiaAmazonas1994",
    `PROJCS["MAGNA_Leticia_Amazonas_1994",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",25978.217],PARAMETER["False_Northing",27501.365],PARAMETER["Longitude_Of_Center",-69.94281105833333],PARAMETER["Latitude_Of_Center",-4.197684047222222],PARAMETER["Height",89.7],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAMedellinAntioquia2010",
    `PROJCS["MAGNA_Medellin_Antioquia_2010",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",835378.647],PARAMETER["False_Northing",1180816.875],PARAMETER["Longitude_Of_Center",-75.56488694444444],PARAMETER["Latitude_Of_Center",6.229208888888889],PARAMETER["Height",1510.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAArauca2007",
    `PROJCS["MAGNA_Arauca_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1035263.443],PARAMETER["False_Northing",1275526.621],PARAMETER["Longitude_Of_Center",-70.75830965555555],PARAMETER["Latitude_Of_Center",7.087606391666666],PARAMETER["Height",100.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNABarranquillaAtlantico1997",
    `PROJCS["MAGNA_Barranquilla_Atlantico_1997",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",917264.406],PARAMETER["False_Northing",1699839.935],PARAMETER["Longitude_Of_Center",-74.83433133333332],PARAMETER["Latitude_Of_Center",10.92318308333333],PARAMETER["Height",100.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNABogotaDC2005",
    `PROJCS["MAGNA_Bogota_DC_2005",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",92334.879],PARAMETER["False_Northing",109320.965],PARAMETER["Longitude_Of_Center",-74.14659166666668],PARAMETER["Latitude_Of_Center",4.680486111111112],PARAMETER["Height",2550.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNACartagenaBolivar2005",
    `PROJCS["MAGNA_Cartagena_Bolivar_2005",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",842981.41],PARAMETER["False_Northing",1641887.09],PARAMETER["Longitude_Of_Center",-75.51120694444444],PARAMETER["Latitude_Of_Center",10.3970475],PARAMETER["Height",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNATunjaBoyaca1997",
    `PROJCS["MAGNA_Tunja_Boyaca_1997",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1080514.91],PARAMETER["False_Northing",1103772.028],PARAMETER["Longitude_Of_Center",-73.3519389],PARAMETER["Latitude_Of_Center",5.534194738888889],PARAMETER["Height",2800.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAManizalesCaldas2011",
    `PROJCS["MAGNA_Manizales_Caldas_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1173727.04],PARAMETER["False_Northing",1052391.13],PARAMETER["Longitude_Of_Center",-75.51109472222223],PARAMETER["Latitude_Of_Center",5.068153888888888],PARAMETER["Height",2100.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAFlorenciaCaqueta2007",
    `PROJCS["MAGNA_Florencia_Caqueta_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1162300.348],PARAMETER["False_Northing",671068.716],PARAMETER["Longitude_Of_Center",-75.61911760277778],PARAMETER["Latitude_Of_Center",1.621012294444445],PARAMETER["Height",300.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAYopalCasanare2006",
    `PROJCS["MAGNA_Yopal_Casanare_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",851184.177],PARAMETER["False_Northing",1083954.137],PARAMETER["Longitude_Of_Center",-72.42004027777779],PARAMETER["Latitude_Of_Center",5.353927222222222],PARAMETER["Height",300.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAPopayanCauca2006",
    `PROJCS["MAGNA_Popayan_Cauca_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1052430.525],PARAMETER["False_Northing",763366.548],PARAMETER["Longitude_Of_Center",-76.6060916361111],PARAMETER["Latitude_Of_Center",2.456159883333334],PARAMETER["Height",1740.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAValleduparCesar2011",
    `PROJCS["MAGNA_Valledupar_Cesar_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1090979.66],PARAMETER["False_Northing",1647208.93],PARAMETER["Longitude_Of_Center",-73.2465713888889],PARAMETER["Latitude_Of_Center",10.44726111111111],PARAMETER["Height",200.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAQuibdoChoco2011",
    `PROJCS["MAGNA_Quibdo_Choco_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1047273.617],PARAMETER["False_Northing",1121443.09],PARAMETER["Longitude_Of_Center",-76.65075385833335],PARAMETER["Latitude_Of_Center",5.694247661111111],PARAMETER["Height",44.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAMonteriaCordoba2006",
    `PROJCS["MAGNA_Monteria_Cordoba_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1131814.934],PARAMETER["False_Northing",1462131.119],PARAMETER["Longitude_Of_Center",-75.87955333055555],PARAMETER["Latitude_Of_Center",8.773085755555556],PARAMETER["Height",15.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAIniridaGuainia2008",
    `PROJCS["MAGNA_Inirida_Guainia_2008",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1019177.687],PARAMETER["False_Northing",491791.326],PARAMETER["Longitude_Of_Center",-67.90523208888889],PARAMETER["Latitude_Of_Center",3.845438183333334],PARAMETER["Height",96.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNASanJosedelGuaviare2011",
    `PROJCS["MAGNA_San_Jose_del_Guaviare_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1159876.62],PARAMETER["False_Northing",775380.342],PARAMETER["Longitude_Of_Center",-72.640033325],PARAMETER["Latitude_Of_Center",2.564078941666666],PARAMETER["Height",185.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNANeivaHuila2006",
    `PROJCS["MAGNA_Neiva_Huila_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",864476.923],PARAMETER["False_Northing",817199.827],PARAMETER["Longitude_Of_Center",-75.29643672222223],PARAMETER["Latitude_Of_Center",2.942415055555556],PARAMETER["Height",430.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNARiohachaLaGuajira2006",
    `PROJCS["MAGNA_Riohacha_La_Guajira_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1128154.73],PARAMETER["False_Northing",1767887.914],PARAMETER["Longitude_Of_Center",-72.90276886944444],PARAMETER["Latitude_Of_Center",11.53691332777778],PARAMETER["Height",6.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNASantaMartaMagdalena2007",
    `PROJCS["MAGNA_Santa_Marta_Magdalena_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",983892.409],PARAMETER["False_Northing",1732533.518],PARAMETER["Longitude_Of_Center",-74.22500527777778],PARAMETER["Latitude_Of_Center",11.21964305555556],PARAMETER["Height",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAVillavicencioMeta2011",
    `PROJCS["MAGNA_Villavicencio_Meta_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1050678.757],PARAMETER["False_Northing",950952.124],PARAMETER["Longitude_Of_Center",-73.62448598611111],PARAMETER["Latitude_Of_Center",4.1553751],PARAMETER["Height",427.19],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAPastoNarino2008",
    `PROJCS["MAGNA_Pasto_Narino_2008",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",980469.695],PARAMETER["False_Northing",624555.332],PARAMETER["Longitude_Of_Center",-77.25312563333334],PARAMETER["Latitude_Of_Center",1.200989513888889],PARAMETER["Height",2530.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNACucutaNortedeSantander2011",
    `PROJCS["MAGNA_Cucuta_Norte_de_Santander_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",842805.406],PARAMETER["False_Northing",1364404.57],PARAMETER["Longitude_Of_Center",-72.50287095],PARAMETER["Latitude_Of_Center",7.888936736111111],PARAMETER["Height",308.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAMocoaPutumayo2011",
    `PROJCS["MAGNA_Mocoa_Putumayo_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1047467.388],PARAMETER["False_Northing",617828.474],PARAMETER["Longitude_Of_Center",-76.65102121944444],PARAMETER["Latitude_Of_Center",1.140023358333333],PARAMETER["Height",655.2],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAArmeniaQuindio2006",
    `PROJCS["MAGNA_Armenia_Quindio_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1155824.666],PARAMETER["False_Northing",993087.465],PARAMETER["Longitude_Of_Center",-75.67348916666667],PARAMETER["Latitude_Of_Center",4.532325],PARAMETER["Height",1470.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAPereiraRisaralda2007",
    `PROJCS["MAGNA_Pereira_Risaralda_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1153492.012],PARAMETER["False_Northing",1024195.255],PARAMETER["Longitude_Of_Center",-75.69395138888889],PARAMETER["Latitude_Of_Center",4.813593611111111],PARAMETER["Height",1500.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNASanAndres2007",
    `PROJCS["MAGNA_San_Andres_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",820439.298],PARAMETER["False_Northing",1877357.828],PARAMETER["Longitude_Of_Center",-81.72937595],PARAMETER["Latitude_Of_Center",12.523794325],PARAMETER["Height",6.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNABucaramangaSantander2008",
    `PROJCS["MAGNA_Bucaramanga_Santander_2008",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1097241.305],PARAMETER["False_Northing",1274642.278],PARAMETER["Longitude_Of_Center",-73.19734322222223],PARAMETER["Latitude_Of_Center",7.078887141666667],PARAMETER["Height",931.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNASucre2006",
    `PROJCS["MAGNA_Sucre_2006",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",929043.607],PARAMETER["False_Northing",1466125.658],PARAMETER["Longitude_Of_Center",-74.722466825],PARAMETER["Latitude_Of_Center",8.810550366666668],PARAMETER["Height",20.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAIbagueTolima2007",
    `PROJCS["MAGNA_Ibague_Tolima_2007",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",877634.33],PARAMETER["False_Northing",980541.348],PARAMETER["Longitude_Of_Center",-75.17992593333334],PARAMETER["Latitude_Of_Center",4.419412827777778],PARAMETER["Height",1100.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNACaliValledelCauca2009",
    `PROJCS["MAGNA_Cali_Valle_del_Cauca_2009",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1061900.18],PARAMETER["False_Northing",872364.63],PARAMETER["Longitude_Of_Center",-76.5205625],PARAMETER["Latitude_Of_Center",3.441883333333334],PARAMETER["Height",1000.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAMituVaupes2011",
    `PROJCS["MAGNA_Mitu_Vaupes_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1093717.398],PARAMETER["False_Northing",629997.236],PARAMETER["Longitude_Of_Center",-70.23546165555555],PARAMETER["Latitude_Of_Center",1.249969366666667],PARAMETER["Height",170.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNAPuertoCarrenoVichada2011",
    `PROJCS["MAGNA_Puerto_Carreno_Vichada_2011",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["IGAC_Plano_Cartesiano"],PARAMETER["False_Easting",1063834.703],PARAMETER["False_Northing",1175257.481],PARAMETER["Longitude_Of_Center",-67.50075024722223],PARAMETER["Latitude_Of_Center",6.18072141388889],PARAMETER["Height",51.58],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA1H1",
    `PROJCS["OSGB36_Highways_England_A1H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",261910.5587],PARAMETER["False_Northing",70975.76209],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.99926],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA2H1",
    `PROJCS["OSGB36_Highways_England_A2H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",252927.2844],PARAMETER["False_Northing",70979.59363],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999314],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA3H1",
    `PROJCS["OSGB36_Highways_England_A3H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",243942.3084],PARAMETER["False_Northing",70983.21269],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999365],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA3H2",
    `PROJCS["OSGB36_Highways_England_A3H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",243948.4072],PARAMETER["False_Northing",70984.98734],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.99939],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA4H1",
    `PROJCS["OSGB36_Highways_England_A4H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",234956.1813],PARAMETER["False_Northing",70986.76115],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999415],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA5H1",
    `PROJCS["OSGB36_Highways_England_A5H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",225969.1556],PARAMETER["False_Northing",70990.30995],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999465],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA5H2",
    `PROJCS["OSGB36_Highways_England_A5H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",225974.8051],PARAMETER["False_Northing",70992.08478],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.99949],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA6H1",
    `PROJCS["OSGB36_Highways_England_A6H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",215981.5338],PARAMETER["False_Northing",70993.93011],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999516],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA6H2",
    `PROJCS["OSGB36_Highways_England_A6H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",215986.9336],PARAMETER["False_Northing",70995.70502],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999541],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA7H1",
    `PROJCS["OSGB36_Highways_England_A7H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",205992.2754],PARAMETER["False_Northing",70997.33764],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999564],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA7H2",
    `PROJCS["OSGB36_Highways_England_A7H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",205997.4254],PARAMETER["False_Northing",70999.11264],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999589],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA8H1",
    `PROJCS["OSGB36_Highways_England_A8H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",196002.254],PARAMETER["False_Northing",71000.81651],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999613],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA8H2",
    `PROJCS["OSGB36_Highways_England_A8H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",196007.1543],PARAMETER["False_Northing",71002.5916],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999638],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA9H1",
    `PROJCS["OSGB36_Highways_England_A9H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185011.1931],PARAMETER["False_Northing",71004.29572],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999662],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA9H2",
    `PROJCS["OSGB36_Highways_England_A9H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185015.8185],PARAMETER["False_Northing",71006.07089],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999687],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA10H1",
    `PROJCS["OSGB36_Highways_England_A10H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",173019.2914],PARAMETER["False_Northing",71007.91729],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999713],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA10H2",
    `PROJCS["OSGB36_Highways_England_A10H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",173023.6171],PARAMETER["False_Northing",71009.69256],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999738],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA11H1",
    `PROJCS["OSGB36_Highways_England_A11H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",159026.3186],PARAMETER["False_Northing",71011.75231],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999767],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA11H2",
    `PROJCS["OSGB36_Highways_England_A11H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",159030.2944],PARAMETER["False_Northing",71013.52767],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999792],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA11H3",
    `PROJCS["OSGB36_Highways_England_A11H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",159034.2704],PARAMETER["False_Northing",71015.30312],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999817],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA12H1",
    `PROJCS["OSGB36_Highways_England_A12H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",144031.0383],PARAMETER["False_Northing",71015.30362],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999817],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA12H2",
    `PROJCS["OSGB36_Highways_England_A12H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",144034.6392],PARAMETER["False_Northing",71017.07907],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999842],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA12H3",
    `PROJCS["OSGB36_Highways_England_A12H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",144038.2403],PARAMETER["False_Northing",71018.8546],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999867],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA13H1",
    `PROJCS["OSGB36_Highways_England_A13H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",128033.7365],PARAMETER["False_Northing",71018.71321],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999865],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA13H2",
    `PROJCS["OSGB36_Highways_England_A13H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",128036.9375],PARAMETER["False_Northing",71020.48874],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.99989],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA14H1",
    `PROJCS["OSGB36_Highways_England_A14H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111034.6979],PARAMETER["False_Northing",71022.19417],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999914],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA14H2",
    `PROJCS["OSGB36_Highways_England_A14H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111037.4739],PARAMETER["False_Northing",71023.96979],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999939],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA15H1",
    `PROJCS["OSGB36_Highways_England_A15H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88032.17537],PARAMETER["False_Northing",71025.95967],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999967],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA15H2",
    `PROJCS["OSGB36_Highways_England_A15H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88034.37626],PARAMETER["False_Northing",71027.73539],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999992],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA16H1",
    `PROJCS["OSGB36_Highways_England_A16H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54022.17583],PARAMETER["False_Northing",71029.15712],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000012],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA16H2",
    `PROJCS["OSGB36_Highways_England_A16H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54023.52644],PARAMETER["False_Northing",71030.93291],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000037],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA17H1",
    `PROJCS["OSGB36_Highways_England_A17H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.11135],PARAMETER["False_Northing",71026.9544],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999981],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA17H2",
    `PROJCS["OSGB36_Highways_England_A17H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.7116],PARAMETER["False_Northing",71028.73014],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000006],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA18H1",
    `PROJCS["OSGB36_Highways_England_A18H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58018.94296],PARAMETER["False_Northing",71023.18879],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999928],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA18H2",
    `PROJCS["OSGB36_Highways_England_A18H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58020.39349],PARAMETER["False_Northing",71024.96444],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999953],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA19H1",
    `PROJCS["OSGB36_Highways_England_A19H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-88023.98625],PARAMETER["False_Northing",71019.35254],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999874],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA19H2",
    `PROJCS["OSGB36_Highways_England_A19H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-88026.18693],PARAMETER["False_Northing",71021.12809],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999899],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA20H1",
    `PROJCS["OSGB36_Highways_England_A20H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-105023.5775],PARAMETER["False_Northing",71015.94289],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999826],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA20H2",
    `PROJCS["OSGB36_Highways_England_A20H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-105026.2032],PARAMETER["False_Northing",71017.71836],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999851],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA21H1",
    `PROJCS["OSGB36_Highways_England_A21H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-122020.6823],PARAMETER["False_Northing",71012.0364],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999771],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA21H2",
    `PROJCS["OSGB36_Highways_England_A21H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-122023.7329],PARAMETER["False_Northing",71013.81177],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999796],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA22H1",
    `PROJCS["OSGB36_Highways_England_A22H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-139014.8049],PARAMETER["False_Northing",71007.56222],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999708],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA22H2",
    `PROJCS["OSGB36_Highways_England_A22H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-139018.2804],PARAMETER["False_Northing",71009.33748],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999733],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA23H1",
    `PROJCS["OSGB36_Highways_England_A23H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-156008.5024],PARAMETER["False_Northing",71003.86967],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999656],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA23H2",
    `PROJCS["OSGB36_Highways_England_A23H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-156012.4027],PARAMETER["False_Northing",71005.64484],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999681],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA24H1",
    `PROJCS["OSGB36_Highways_England_A24H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-165001.8975],PARAMETER["False_Northing",71000.81651],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999613],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA24H2",
    `PROJCS["OSGB36_Highways_England_A24H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-165006.0227],PARAMETER["False_Northing",71002.5916],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999638],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA25H1",
    `PROJCS["OSGB36_Highways_England_A25H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-175993.5763],PARAMETER["False_Northing",70997.40864],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999565],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA25H2",
    `PROJCS["OSGB36_Highways_England_A25H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-175997.9763],PARAMETER["False_Northing",70999.18364],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.99959],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA26H1",
    `PROJCS["OSGB36_Highways_England_A26H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-185984.2846],PARAMETER["False_Northing",70994.00109],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999517],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA26H2",
    `PROJCS["OSGB36_Highways_England_A26H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-185988.9343],PARAMETER["False_Northing",70995.77601],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999542],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA27H1",
    `PROJCS["OSGB36_Highways_England_A27H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-195973.6419],PARAMETER["False_Northing",70990.45191],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999467],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA27H2",
    `PROJCS["OSGB36_Highways_England_A27H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-195978.5414],PARAMETER["False_Northing",70992.22674],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999492],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA28H1",
    `PROJCS["OSGB36_Highways_England_A28H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-205961.7946],PARAMETER["False_Northing",70986.83212],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999416],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA28H2",
    `PROJCS["OSGB36_Highways_England_A28H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-205966.9438],PARAMETER["False_Northing",70988.60686],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999441],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA29H1",
    `PROJCS["OSGB36_Highways_England_A29H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-214949.3801],PARAMETER["False_Northing",70983.28366],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999366],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandA30H1",
    `PROJCS["OSGB36_Highways_England_A30H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-223935.6193],PARAMETER["False_Northing",70979.59363],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999314],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB15H1",
    `PROJCS["OSGB36_Highways_England_B15H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88032.17537],PARAMETER["False_Northing",111040.5848],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999967],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB15H2",
    `PROJCS["OSGB36_Highways_England_B15H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88034.37626],PARAMETER["False_Northing",111043.361],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999992],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB15H3",
    `PROJCS["OSGB36_Highways_England_B15H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88036.57726],PARAMETER["False_Northing",111046.1372],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000017],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB16H1",
    `PROJCS["OSGB36_Highways_England_B16H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54022.17583],PARAMETER["False_Northing",111045.5837],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000012],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB16H2",
    `PROJCS["OSGB36_Highways_England_B16H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54023.52644],PARAMETER["False_Northing",111048.3599],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000037],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB16H3",
    `PROJCS["OSGB36_Highways_England_B16H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54024.87711],PARAMETER["False_Northing",111051.1363],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000062],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB16H4",
    `PROJCS["OSGB36_Highways_England_B16H4",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54026.22785],PARAMETER["False_Northing",111053.9128],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000087],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB17H1",
    `PROJCS["OSGB36_Highways_England_B17H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.11135],PARAMETER["False_Northing",111042.14],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999981],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB17H2",
    `PROJCS["OSGB36_Highways_England_B17H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.7116],PARAMETER["False_Northing",111044.9161],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000006],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB18H1",
    `PROJCS["OSGB36_Highways_England_B18H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58018.94296],PARAMETER["False_Northing",111036.2529],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999928],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB18H2",
    `PROJCS["OSGB36_Highways_England_B18H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58020.39349],PARAMETER["False_Northing",111039.0289],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999953],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB19H1",
    `PROJCS["OSGB36_Highways_England_B19H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-88023.98625],PARAMETER["False_Northing",111030.2554],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999874],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB20H1",
    `PROJCS["OSGB36_Highways_England_B20H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-105023.5775],PARAMETER["False_Northing",111024.9248],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999826],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB21H1",
    `PROJCS["OSGB36_Highways_England_B21H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-122020.6823],PARAMETER["False_Northing",111018.8175],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999771],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB22H1",
    `PROJCS["OSGB36_Highways_England_B22H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-139014.9439],PARAMETER["False_Northing",111011.9337],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999709],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB23H1",
    `PROJCS["OSGB36_Highways_England_B23H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-156008.5024],PARAMETER["False_Northing",111006.0498],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999656],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB24H1",
    `PROJCS["OSGB36_Highways_England_B24H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-165002.0625],PARAMETER["False_Northing",111001.3875],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999614],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB25H1",
    `PROJCS["OSGB36_Highways_England_B25H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-175993.5763],PARAMETER["False_Northing",110995.9487],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999565],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB26H1",
    `PROJCS["OSGB36_Highways_England_B26H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-185984.2846],PARAMETER["False_Northing",110990.6214],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999517],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB27H1",
    `PROJCS["OSGB36_Highways_England_B27H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-195973.6419],PARAMETER["False_Northing",110985.0727],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999467],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB28H1",
    `PROJCS["OSGB36_Highways_England_B28H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-205961.7946],PARAMETER["False_Northing",110979.4136],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999416],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB29H1",
    `PROJCS["OSGB36_Highways_England_B29H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-214949.595],PARAMETER["False_Northing",110973.9769],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999367],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB30H1",
    `PROJCS["OSGB36_Highways_England_B30H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-223935.8432],PARAMETER["False_Northing",110968.208],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999315],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB31H1",
    `PROJCS["OSGB36_Highways_England_B31H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-232920.6915],PARAMETER["False_Northing",110962.2179],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999261],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandB32H1",
    `PROJCS["OSGB36_Highways_England_B32H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-241904.3281],PARAMETER["False_Northing",110956.1174],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999206],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC13H1",
    `PROJCS["OSGB36_Highways_England_C13H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",128033.8646],PARAMETER["False_Northing",126033.3354],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999866],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC14H1",
    `PROJCS["OSGB36_Highways_England_C14H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111034.6979],PARAMETER["False_Northing",126039.3868],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999914],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC14H2",
    `PROJCS["OSGB36_Highways_England_C14H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111037.4739],PARAMETER["False_Northing",126042.5379],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999939],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC14H3",
    `PROJCS["OSGB36_Highways_England_C14H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111040.25],PARAMETER["False_Northing",126045.6892],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999964],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC14H4",
    `PROJCS["OSGB36_Highways_England_C14H4",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111043.0263],PARAMETER["False_Northing",126048.8406],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999989],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC15H1",
    `PROJCS["OSGB36_Highways_England_C15H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88032.17537],PARAMETER["False_Northing",126046.0693],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999967],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC15H2",
    `PROJCS["OSGB36_Highways_England_C15H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88034.37626],PARAMETER["False_Northing",126049.2206],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999992],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC15H3",
    `PROJCS["OSGB36_Highways_England_C15H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88036.57726],PARAMETER["False_Northing",126052.372],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000017],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC15H4",
    `PROJCS["OSGB36_Highways_England_C15H4",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88038.77836],PARAMETER["False_Northing",126055.5236],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000042],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC15H5",
    `PROJCS["OSGB36_Highways_England_C15H5",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88040.97958],PARAMETER["False_Northing",126058.6753],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000067],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC16H1",
    `PROJCS["OSGB36_Highways_England_C16H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54022.17583],PARAMETER["False_Northing",126051.7436],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000012],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC16H2",
    `PROJCS["OSGB36_Highways_England_C16H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54023.52644],PARAMETER["False_Northing",126054.895],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000037],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC16H3",
    `PROJCS["OSGB36_Highways_England_C16H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54024.87711],PARAMETER["False_Northing",126058.0466],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000062],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC16H4",
    `PROJCS["OSGB36_Highways_England_C16H4",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54026.22785],PARAMETER["False_Northing",126061.1983],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000087],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC17H1",
    `PROJCS["OSGB36_Highways_England_C17H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.11135],PARAMETER["False_Northing",126047.8346],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999981],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC17H2",
    `PROJCS["OSGB36_Highways_England_C17H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.7116],PARAMETER["False_Northing",126050.9859],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000006],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC18H1",
    `PROJCS["OSGB36_Highways_England_C18H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58018.94296],PARAMETER["False_Northing",126041.1519],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999928],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC18H2",
    `PROJCS["OSGB36_Highways_England_C18H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58020.39349],PARAMETER["False_Northing",126044.3031],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999953],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC19H1",
    `PROJCS["OSGB36_Highways_England_C19H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-88023.98625],PARAMETER["False_Northing",126034.3439],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999874],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36HighwaysEnglandC19H2",
    `PROJCS["OSGB36_Highways_England_C19H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-88026.18693],PARAMETER["False_Northing",126037.4949],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999899],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(2011)ICSAurora(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Aurora_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3773000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.00003],PARAMETER["Latitude_Of_Origin",41.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSFreeport(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Freeport_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",1804000.0],PARAMETER["False_Northing",755000.0],PARAMETER["Central_Meridian",-89.95],PARAMETER["Scale_Factor",1.000029],PARAMETER["Latitude_Of_Origin",42.2],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSChicago(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Chicago_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4757000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-87.8],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",41.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSRockford(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Rockford_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2822000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-89.25],PARAMETER["Scale_Factor",1.000029],PARAMETER["Latitude_Of_Origin",41.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSMoline(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Moline_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",5741000.0],PARAMETER["False_Northing",755000.0],PARAMETER["Central_Meridian",-90.6],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",41.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSOttawa(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Ottawa_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",7743000.0],PARAMETER["False_Northing",755000.0],PARAMETER["Central_Meridian",-89.05],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",41.3],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSSterling(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Sterling_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",6726000.0],PARAMETER["False_Northing",755000.0],PARAMETER["Central_Meridian",-90.05],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",41.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSJoliet(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Joliet_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",8694000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.000022],PARAMETER["Latitude_Of_Origin",40.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSEureka(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Eureka_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2756000.0],PARAMETER["False_Northing",427000.0],PARAMETER["Central_Meridian",-89.3],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",37.45],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSPontiac(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Pontiac_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",4757000.0],PARAMETER["False_Northing",1739000.0],PARAMETER["Central_Meridian",-88.55],PARAMETER["Scale_Factor",1.000025],PARAMETER["Latitude_Of_Origin",40.9],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSMonmouth(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Monmouth_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9678000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-90.85],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",40.2],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSWatseka(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Watseka_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",5741000.0],PARAMETER["False_Northing",1739000.0],PARAMETER["Central_Meridian",-87.95],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",40.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSPeoria(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Peoria_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1378000.0],PARAMETER["False_Northing",622000.0],PARAMETER["Central_Meridian",-89.65],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",37.4],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSBloomington(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Bloomington_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",3773000.0],PARAMETER["False_Northing",1739000.0],PARAMETER["Central_Meridian",-88.85],PARAMETER["Scale_Factor",1.000031],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSGalesburg(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Galesburg_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",230000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-90.1],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",37.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSChampaign(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Champaign_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",328000.0],PARAMETER["False_Northing",2822000.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.000026],PARAMETER["Latitude_Of_Origin",40.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSDecatur(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Decatur_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9678000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-88.8],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",36.3],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSQuincy(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Quincy_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6726000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-91.25],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",36.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSLincoln(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Lincoln_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",8760000.0],PARAMETER["False_Northing",1739000.0],PARAMETER["Central_Meridian",-89.8],PARAMETER["Scale_Factor",1.000018],PARAMETER["Latitude_Of_Origin",40.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSMacomb(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Macomb_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",7710000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-90.6],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",36.8],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSSpringfield(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Springfield_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",2329000.0],PARAMETER["False_Northing",2887000.0],PARAMETER["Central_Meridian",-89.65],PARAMETER["Scale_Factor",1.000022],PARAMETER["Latitude_Of_Origin",39.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSJacksonville(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Jacksonville_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",1247000.0],PARAMETER["False_Northing",2822000.0],PARAMETER["Central_Meridian",-90.6],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",39.65],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSTaylorville(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Taylorville_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",6726000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-89.4],PARAMETER["Scale_Factor",1.000023],PARAMETER["Latitude_Of_Origin",33.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSCharleston(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Charleston_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",3773000.0],PARAMETER["False_Northing",2756000.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.000024],PARAMETER["Latitude_Of_Origin",39.65],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSCarlinville(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Carlinville_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",5741000.0],PARAMETER["False_Northing",2756000.0],PARAMETER["Central_Meridian",-90.15],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",39.3],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSRobinson(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Robinson_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",8694000.0],PARAMETER["False_Northing",2756000.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.000017],PARAMETER["Latitude_Of_Origin",39.1],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSJerseyville(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Jerseyville_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4757000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",1.000019],PARAMETER["Latitude_Of_Origin",32.85],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSEffingham(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Effingham_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",7710000.0],PARAMETER["False_Northing",2756000.0],PARAMETER["Central_Meridian",-89.0],PARAMETER["Scale_Factor",1.000019],PARAMETER["Latitude_Of_Origin",38.95],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSBelleville(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Belleville_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",9678000.0],PARAMETER["False_Northing",492000.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",1.000016],PARAMETER["Latitude_Of_Origin",32.35],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSOlney(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Olney_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",1247000.0],PARAMETER["False_Northing",3773000.0],PARAMETER["Central_Meridian",-88.15],PARAMETER["Scale_Factor",1.000013],PARAMETER["Latitude_Of_Origin",38.55],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSMountVernon(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Mount_Vernon_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",328000.0],PARAMETER["False_Northing",3773000.0],PARAMETER["Central_Meridian",-89.15],PARAMETER["Scale_Factor",1.000015],PARAMETER["Latitude_Of_Origin",38.45],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSCarbondale(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Carbondale_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",2395000.0],PARAMETER["False_Northing",3773000.0],PARAMETER["Central_Meridian",-88.95],PARAMETER["Scale_Factor",1.000012],PARAMETER["Latitude_Of_Origin",37.9],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)ICSMetropolis(USFeet)",
    `PROJCS["NAD_1983_(2011)_ICS_Metropolis_(US_Feet)",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic_1SP"],PARAMETER["False_Easting",3642000.0],PARAMETER["False_Northing",3839000.0],PARAMETER["Central_Meridian",-88.9],PARAMETER["Scale_Factor",1.00001],PARAMETER["Latitude_Of_Origin",37.2],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "CassiniBangladeshZone01Dinajpur",
    `PROJCS["Cassini_Bangladesh_Zone_01_Dinajpur",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",88.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",25.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone02Rangpur",
    `PROJCS["Cassini_Bangladesh_Zone_02_Rangpur",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",89.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",25.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone03Rajshahi",
    `PROJCS["Cassini_Bangladesh_Zone_03_Rajshahi",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",88.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",24.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone04Bogura",
    `PROJCS["Cassini_Bangladesh_Zone_04_Bogura",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",89.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",24.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone05Pabna",
    `PROJCS["Cassini_Bangladesh_Zone_05_Pabna",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",89.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",24.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone06Mymensingh",
    `PROJCS["Cassini_Bangladesh_Zone_06_Mymensingh",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",24.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone07Dhaka",
    `PROJCS["Cassini_Bangladesh_Zone_07_Dhaka",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",24.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone08Faridpur",
    `PROJCS["Cassini_Bangladesh_Zone_08_Faridpur",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",23.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone09Sylhet",
    `PROJCS["Cassini_Bangladesh_Zone_09_Sylhet",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",92.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",24.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone10Kushtia",
    `PROJCS["Cassini_Bangladesh_Zone_10_Kushtia",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",89.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",23.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone11Joshore",
    `PROJCS["Cassini_Bangladesh_Zone_11_Joshore",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",89.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",23.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone12Khulna",
    `PROJCS["Cassini_Bangladesh_Zone_12_Khulna",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",89.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone13Barishal",
    `PROJCS["Cassini_Bangladesh_Zone_13_Barishal",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.5],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone14Cumilla",
    `PROJCS["Cassini_Bangladesh_Zone_14_Cumilla",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",91.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",23.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone15Noakhali",
    `PROJCS["Cassini_Bangladesh_Zone_15_Noakhali",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",91.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",23.5],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone16Chottogram",
    `PROJCS["Cassini_Bangladesh_Zone_16_Chottogram",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",92.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.0],UNIT["Chain",20.1168]]`
  ],
  [
    "CassiniBangladeshZone17CHT",
    `PROJCS["Cassini_Bangladesh_Zone_17_CHT",GEOGCS["GCS_Everest_1830",DATUM["D_Everest_1830",SPHEROID["Everest_1830",6377299.36559538,300.8017255]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Cassini"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",92.25],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",22.75],UNIT["Chain",20.1168]]`
  ],
  [
    "LCCBangladesh",
    `PROJCS["LCC_Bangladesh",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2743183.6991],PARAMETER["False_Northing",914395.233],PARAMETER["Central_Meridian",90.0],PARAMETER["Standard_Parallel_1",23.15],PARAMETER["Standard_Parallel_2",28.8],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "LCCGulshan",
    `PROJCS["LCC_Gulshan",GEOGCS["GCS_Gulshan_303",DATUM["D_Gulshan_303",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2743183.6991],PARAMETER["False_Northing",914395.233],PARAMETER["Central_Meridian",90.0],PARAMETER["Standard_Parallel_1",23.15],PARAMETER["Standard_Parallel_2",28.8],PARAMETER["Latitude_Of_Origin",26.0],UNIT["Meter",1.0]]`
  ],
  [
    "UTMBangladesh(BTM)",
    `PROJCS["UTM_Bangladesh_(BTM)",GEOGCS["GCS_Gulshan_303",DATUM["D_Gulshan_303",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",-2000000.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "BUTM2010",
    `PROJCS["BUTM2010",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]](lat)",north,ORDER[1]],AXIS["Longitude`
  ],
  [
    "UTMGulshan",
    `PROJCS["UTM_Gulshan",GEOGCS["GCS_Gulshan_303",DATUM["D_Gulshan_303",SPHEROID["Everest_Adjustment_1937",6377276.345,300.8017]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "AGD1966ISG572",
    `PROJCS["AGD_1966_ISG_57_2",GEOGCS["GCS_Australian_1966",DATUM["D_Australian_1966",SPHEROID["Australian",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",159.0],PARAMETER["Scale_Factor",0.99994],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011CaliforniaTealeAlbers",
    `PROJCS["NAD_1983_2011_California_Teale_Albers",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",-4000000.0],PARAMETER["Central_Meridian",-120.0],PARAMETER["Standard_Parallel_1",34.0],PARAMETER["Standard_Parallel_2",40.5],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011MississippiTM",
    `PROJCS["NAD_1983_2011_Mississippi_TM",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1300000.0],PARAMETER["Central_Meridian",-89.75],PARAMETER["Scale_Factor",0.9998335],PARAMETER["Latitude_Of_Origin",32.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011ContiguousUSAAlbers",
    `PROJCS["NAD_1983_2011_Contiguous_USA_Albers",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-96.0],PARAMETER["Standard_Parallel_1",29.5],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",23.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011AlaskaAlbers",
    `PROJCS["NAD_1983_2011_Alaska_Albers",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Standard_Parallel_1",55.0],PARAMETER["Standard_Parallel_2",65.0],PARAMETER["Latitude_Of_Origin",50.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011FloridaGDLAlbers",
    `PROJCS["NAD_1983_2011_Florida_GDL_Albers",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.0],PARAMETER["Standard_Parallel_1",24.0],PARAMETER["Standard_Parallel_2",31.5],PARAMETER["Latitude_Of_Origin",24.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011MichiganGeoRefMeters",
    `PROJCS["NAD_1983_2011_Michigan_GeoRef_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",2546731.496],PARAMETER["False_Northing",-4354009.816],PARAMETER["Scale_Factor",0.9996],PARAMETER["Azimuth",337.25556],PARAMETER["Longitude_Of_Center",-86.0],PARAMETER["Latitude_Of_Center",45.30916666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011OregonStatewideLambert",
    `PROJCS["NAD_1983_2011_Oregon_Statewide_Lambert",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011OregonStatewideLambertFtIntl",
    `PROJCS["NAD_1983_2011_Oregon_Statewide_Lambert_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312335.958005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",43.0],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011TexasCentricMappingSystemAlbers",
    `PROJCS["NAD_1983_2011_Texas_Centric_Mapping_System_Albers",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Albers"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011TexasCentricMappingSystemLambert",
    `PROJCS["NAD_1983_2011_Texas_Centric_Mapping_System_Lambert",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",27.5],PARAMETER["Standard_Parallel_2",35.0],PARAMETER["Latitude_Of_Origin",18.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WisconsinTM",
    `PROJCS["NAD_1983_2011_Wisconsin_TM",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",520000.0],PARAMETER["False_Northing",-4480000.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011WisconsinTMUSFt",
    `PROJCS["NAD_1983_2011_Wisconsin_TM_US_Ft",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1706033.333333333],PARAMETER["False_Northing",-14698133.33333333],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneAlabamaEastFIPS0101",
    `PROJCS["NAD_1983_2011_StatePlane_Alabama_East_FIPS_0101",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlabamaWestFIPS0102",
    `PROJCS["NAD_1983_2011_StatePlane_Alabama_West_FIPS_0102",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska1FIPS5001",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_1_FIPS_5001",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Hotine_Oblique_Mercator_Azimuth_Natural_Origin"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",-36.86989764583333],PARAMETER["Longitude_Of_Center",-133.6666666666667],PARAMETER["Latitude_Of_Center",57.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska2FIPS5002",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_2_FIPS_5002",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-142.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska3FIPS5003",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_3_FIPS_5003",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-146.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska4FIPS5004",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_4_FIPS_5004",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-150.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska5FIPS5005",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_5_FIPS_5005",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-154.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska6FIPS5006",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_6_FIPS_5006",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-158.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska7FIPS5007",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_7_FIPS_5007",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-162.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska8FIPS5008",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_8_FIPS_5008",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-166.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska9FIPS5009",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_9_FIPS_5009",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-170.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",54.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneAlaska10FIPS5010",
    `PROJCS["NAD_1983_2011_StatePlane_Alaska_10_FIPS_5010",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-176.0],PARAMETER["Standard_Parallel_1",51.83333333333334],PARAMETER["Standard_Parallel_2",53.83333333333334],PARAMETER["Latitude_Of_Origin",51.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArizonaEastFIPS0201",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_East_FIPS_0201",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArizonaCentralFIPS0202",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_Central_FIPS_0202",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArizonaWestFIPS0203",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_West_FIPS_0203",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArizonaEastFIPS0201FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_East_FIPS_0201_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneArizonaCentralFIPS0202FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_Central_FIPS_0202_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneArizonaWestFIPS0203FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Arizona_West_FIPS_0203_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneArkansasNorthFIPS0301",
    `PROJCS["NAD_1983_2011_StatePlane_Arkansas_North_FIPS_0301",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArkansasSouthFIPS0302",
    `PROJCS["NAD_1983_2011_StatePlane_Arkansas_South_FIPS_0302",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneArkansasNorthFIPS0301FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Arkansas_North_FIPS_0301_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneArkansasSouthFIPS0302FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Arkansas_South_FIPS_0302_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIFIPS0401",
    `PROJCS["NAD_1983_2011_StatePlane_California_I_FIPS_0401",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIIFIPS0402",
    `PROJCS["NAD_1983_2011_StatePlane_California_II_FIPS_0402",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIIIFIPS0403",
    `PROJCS["NAD_1983_2011_StatePlane_California_III_FIPS_0403",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIVFIPS0404",
    `PROJCS["NAD_1983_2011_StatePlane_California_IV_FIPS_0404",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaVFIPS0405",
    `PROJCS["NAD_1983_2011_StatePlane_California_V_FIPS_0405",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaVIFIPS0406",
    `PROJCS["NAD_1983_2011_StatePlane_California_VI_FIPS_0406",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIFIPS0401FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_I_FIPS_0401_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIIFIPS0402FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_II_FIPS_0402_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIIIFIPS0403FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_III_FIPS_0403_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaIVFIPS0404FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_IV_FIPS_0404_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaVFIPS0405FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_V_FIPS_0405_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneCaliforniaVIFIPS0406FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_California_VI_FIPS_0406_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneColoradoNorthFIPS0501",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_North_FIPS_0501",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneColoradoCentralFIPS0502",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_Central_FIPS_0502",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneColoradoSouthFIPS0503",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_South_FIPS_0503",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneColoradoNorthFIPS0501FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_North_FIPS_0501_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneColoradoCentralFIPS0502FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_Central_FIPS_0502_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneColoradoSouthFIPS0503FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Colorado_South_FIPS_0503_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneConnecticutFIPS0600",
    `PROJCS["NAD_1983_2011_StatePlane_Connecticut_FIPS_0600",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",304800.6096],PARAMETER["False_Northing",152400.3048],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneConnecticutFIPS0600FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Connecticut_FIPS_0600_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",999999.999996],PARAMETER["False_Northing",499999.999998],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneDelawareFIPS0700",
    `PROJCS["NAD_1983_2011_StatePlane_Delaware_FIPS_0700",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneDelawareFIPS0700FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Delaware_FIPS_0700_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneFloridaEastFIPS0901",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_East_FIPS_0901",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneFloridaWestFIPS0902",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_West_FIPS_0902",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneFloridaNorthFIPS0903",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_North_FIPS_0903",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneFloridaEastFIPS0901FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_East_FIPS_0901_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneFloridaWestFIPS0902FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_West_FIPS_0902_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneFloridaNorthFIPS0903FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Florida_North_FIPS_0903_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneGeorgiaEastFIPS1001",
    `PROJCS["NAD_1983_2011_StatePlane_Georgia_East_FIPS_1001",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneGeorgiaWestFIPS1002",
    `PROJCS["NAD_1983_2011_StatePlane_Georgia_West_FIPS_1002",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneGeorgiaEastFIPS1001FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Georgia_East_FIPS_1001_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneGeorgiaWestFIPS1002FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Georgia_West_FIPS_1002_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIdahoEastFIPS1101",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_East_FIPS_1101",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIdahoCentralFIPS1102",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_Central_FIPS_1102",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIdahoWestFIPS1103",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_West_FIPS_1103",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIdahoEastFIPS1101FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_East_FIPS_1101_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIdahoCentralFIPS1102FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_Central_FIPS_1102_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIdahoWestFIPS1103FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Idaho_West_FIPS_1103_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIllinoisEastFIPS1201",
    `PROJCS["NAD_1983_2011_StatePlane_Illinois_East_FIPS_1201",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIllinoisWestFIPS1202",
    `PROJCS["NAD_1983_2011_StatePlane_Illinois_West_FIPS_1202",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIllinoisEastFIPS1201FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Illinois_East_FIPS_1201_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIllinoisWestFIPS1202FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Illinois_West_FIPS_1202_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIndianaEastFIPS1301",
    `PROJCS["NAD_1983_2011_StatePlane_Indiana_East_FIPS_1301",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIndianaWestFIPS1302",
    `PROJCS["NAD_1983_2011_StatePlane_Indiana_West_FIPS_1302",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIndianaEastFIPS1301FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Indiana_East_FIPS_1301_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIndianaWestFIPS1302FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Indiana_West_FIPS_1302_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIowaNorthFIPS1401",
    `PROJCS["NAD_1983_2011_StatePlane_Iowa_North_FIPS_1401",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIowaSouthFIPS1402",
    `PROJCS["NAD_1983_2011_StatePlane_Iowa_South_FIPS_1402",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneIowaNorthFIPS1401FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Iowa_North_FIPS_1401_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneIowaSouthFIPS1402FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Iowa_South_FIPS_1402_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneKansasNorthFIPS1501",
    `PROJCS["NAD_1983_2011_StatePlane_Kansas_North_FIPS_1501",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneKansasSouthFIPS1502",
    `PROJCS["NAD_1983_2011_StatePlane_Kansas_South_FIPS_1502",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneKansasNorthFIPS1501FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Kansas_North_FIPS_1501_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneKansasSouthFIPS1502FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Kansas_South_FIPS_1502_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneKentuckyNorthFIPS1601",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_North_FIPS_1601",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneKentuckyNorthFIPS1601FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_North_FIPS_1601_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneKentuckyFIPS1600",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_FIPS_1600",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneKentuckyFIPS1600FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_FIPS_1600_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneKentuckySouthFIPS1602",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_South_FIPS_1602",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneKentuckySouthFIPS1602FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Kentucky_South_FIPS_1602_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneLouisianaNorthFIPS1701",
    `PROJCS["NAD_1983_2011_StatePlane_Louisiana_North_FIPS_1701",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneLouisianaSouthFIPS1702",
    `PROJCS["NAD_1983_2011_StatePlane_Louisiana_South_FIPS_1702",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneLouisianaNorthFIPS1701FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Louisiana_North_FIPS_1701_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneLouisianaSouthFIPS1702FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Louisiana_South_FIPS_1702_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMaineEastFIPS1801",
    `PROJCS["NAD_1983_2011_StatePlane_Maine_East_FIPS_1801",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMaineWestFIPS1802",
    `PROJCS["NAD_1983_2011_StatePlane_Maine_West_FIPS_1802",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMaineEastFIPS1801FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Maine_East_FIPS_1801_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMaineWestFIPS1802FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Maine_West_FIPS_1802_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011Maine2000EastZone",
    `PROJCS["NAD_1983_2011_Maine_2000_East_Zone",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.875],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011Maine2000CentralZone",
    `PROJCS["NAD_1983_2011_Maine_2000_Central_Zone",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.125],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011Maine2000WestZone",
    `PROJCS["NAD_1983_2011_Maine_2000_West_Zone",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.375],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMarylandFIPS1900",
    `PROJCS["NAD_1983_2011_StatePlane_Maryland_FIPS_1900",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMarylandFIPS1900FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Maryland_FIPS_1900_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMassachusettsMainlandFIPS2001",
    `PROJCS["NAD_1983_2011_StatePlane_Massachusetts_Mainland_FIPS_2001",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",750000.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMassachusettsIslandFIPS2002",
    `PROJCS["NAD_1983_2011_StatePlane_Massachusetts_Island_FIPS_2002",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMassachusettsMnldFIPS2001FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Massachusetts_Mnld_FIPS_2001_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",2460625.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMassachusettsIslFIPS2002FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Massachusetts_Isl_FIPS_2002_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMichiganNorthFIPS2111",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_North_FIPS_2111",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMichiganCentralFIPS2112",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_Central_FIPS_2112",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMichiganSouthFIPS2113",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_South_FIPS_2113",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMichiganNorthFIPS2111FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_North_FIPS_2111_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",26246719.16010498],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneMichiganCentralFIPS2112FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_Central_FIPS_2112_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",19685039.37007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneMichiganSouthFIPS2113FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Michigan_South_FIPS_2113_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",13123359.58005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaNorthFIPS2201",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_North_FIPS_2201",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaCentralFIPS2202",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_Central_FIPS_2202",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaSouthFIPS2203",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_South_FIPS_2203",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaNorthFIPS2201FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_North_FIPS_2201_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaCentralFIPS2202FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_Central_FIPS_2202_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMinnesotaSouthFIPS2203FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Minnesota_South_FIPS_2203_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMississippiEastFIPS2301",
    `PROJCS["NAD_1983_2011_StatePlane_Mississippi_East_FIPS_2301",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMississippiWestFIPS2302",
    `PROJCS["NAD_1983_2011_StatePlane_Mississippi_West_FIPS_2302",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMississippiEastFIPS2301FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Mississippi_East_FIPS_2301_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMississippiWestFIPS2302FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Mississippi_West_FIPS_2302_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneMissouriEastFIPS2401",
    `PROJCS["NAD_1983_2011_StatePlane_Missouri_East_FIPS_2401",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMissouriCentralFIPS2402",
    `PROJCS["NAD_1983_2011_StatePlane_Missouri_Central_FIPS_2402",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMissouriWestFIPS2403",
    `PROJCS["NAD_1983_2011_StatePlane_Missouri_West_FIPS_2403",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",850000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.5],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMontanaFIPS2500",
    `PROJCS["NAD_1983_2011_StatePlane_Montana_FIPS_2500",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneMontanaFIPS2500FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Montana_FIPS_2500_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneNebraskaFIPS2600",
    `PROJCS["NAD_1983_2011_StatePlane_Nebraska_FIPS_2600",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNebraskaFIPS2600FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Nebraska_FIPS_2600_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNevadaEastFIPS2701",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_East_FIPS_2701",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",8000000.0],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNevadaCentralFIPS2702",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_Central_FIPS_2702",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNevadaWestFIPS2703",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_West_FIPS_2703",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNevadaEastFIPS2701FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_East_FIPS_2701_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",26246666.66666666],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNevadaCentralFIPS2702FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_Central_FIPS_2702_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",19685000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNevadaWestFIPS2703FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Nevada_West_FIPS_2703_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewHampshireFIPS2800",
    `PROJCS["NAD_1983_2011_StatePlane_New_Hampshire_FIPS_2800",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewHampshireFIPS2800FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_Hampshire_FIPS_2800_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewJerseyFIPS2900",
    `PROJCS["NAD_1983_2011_StatePlane_New_Jersey_FIPS_2900",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewJerseyFIPS2900FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_Jersey_FIPS_2900_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoEastFIPS3001",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_East_FIPS_3001",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoCentralFIPS3002",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_Central_FIPS_3002",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoWestFIPS3003",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_West_FIPS_3003",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",830000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoEastFIPS3001FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_East_FIPS_3001_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",541337.5],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoCentralFIPS3002FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_Central_FIPS_3002_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewMexicoWestFIPS3003FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_Mexico_West_FIPS_3003_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2723091.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkEastFIPS3101",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_East_FIPS_3101",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkCentralFIPS3102",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_Central_FIPS_3102",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkWestFIPS3103",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_West_FIPS_3103",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",350000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkLongIslandFIPS3104",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_Long_Island_FIPS_3104",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkEastFIPS3101FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_East_FIPS_3101_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkCentralFIPS3102FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_Central_FIPS_3102_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",820208.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkWestFIPS3103FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_West_FIPS_3103_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1148291.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNewYorkLongIslFIPS3104FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_New_York_Long_Isl_FIPS_3104_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNorthCarolinaFIPS3200",
    `PROJCS["NAD_1983_2011_StatePlane_North_Carolina_FIPS_3200",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609601.2192024384],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNorthCarolinaFIPS3200FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_North_Carolina_FIPS_3200_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneNorthDakotaNorthFIPS3301",
    `PROJCS["NAD_1983_2011_StatePlane_North_Dakota_North_FIPS_3301",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNorthDakotaSouthFIPS3302",
    `PROJCS["NAD_1983_2011_StatePlane_North_Dakota_South_FIPS_3302",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneNorthDakotaNorthFIPS3301FtI",
    `PROJCS["NAD_1983_2011_StatePlane_North_Dakota_North_FIPS_3301_FtI",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneNorthDakotaSouthFIPS3302FtI",
    `PROJCS["NAD_1983_2011_StatePlane_North_Dakota_South_FIPS_3302_FtI",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneOhioNorthFIPS3401",
    `PROJCS["NAD_1983_2011_StatePlane_Ohio_North_FIPS_3401",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOhioSouthFIPS3402",
    `PROJCS["NAD_1983_2011_StatePlane_Ohio_South_FIPS_3402",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOhioNorthFIPS3401FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Ohio_North_FIPS_3401_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneOhioSouthFIPS3402FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Ohio_South_FIPS_3402_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneOklahomaNorthFIPS3501",
    `PROJCS["NAD_1983_2011_StatePlane_Oklahoma_North_FIPS_3501",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOklahomaSouthFIPS3502",
    `PROJCS["NAD_1983_2011_StatePlane_Oklahoma_South_FIPS_3502",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOklahomaNorthFIPS3501FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Oklahoma_North_FIPS_3501_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneOklahomaSouthFIPS3502FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Oklahoma_South_FIPS_3502_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneOregonNorthFIPS3601",
    `PROJCS["NAD_1983_2011_StatePlane_Oregon_North_FIPS_3601",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOregonSouthFIPS3602",
    `PROJCS["NAD_1983_2011_StatePlane_Oregon_South_FIPS_3602",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneOregonNorthFIPS3601FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Oregon_North_FIPS_3601_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8202099.737532808],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",44.33333333333334],PARAMETER["Standard_Parallel_2",46.0],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneOregonSouthFIPS3602FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Oregon_South_FIPS_3602_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921259.842519685],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",42.33333333333334],PARAMETER["Standard_Parallel_2",44.0],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlanePennsylvaniaNorthFIPS3701",
    `PROJCS["NAD_1983_2011_StatePlane_Pennsylvania_North_FIPS_3701",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlanePennsylvaniaNorthFIPS3701FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Pennsylvania_North_FIPS_3701_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlanePennsylvaniaSouthFIPS3702",
    `PROJCS["NAD_1983_2011_StatePlane_Pennsylvania_South_FIPS_3702",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlanePennsylvaniaSouthFIPS3702FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Pennsylvania_South_FIPS_3702_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneRhodeIslandFIPS3800",
    `PROJCS["NAD_1983_2011_StatePlane_Rhode_Island_FIPS_3800",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneRhodeIslandFIPS3800FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Rhode_Island_FIPS_3800_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneSouthCarolinaFIPS3900",
    `PROJCS["NAD_1983_2011_StatePlane_South_Carolina_FIPS_3900",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609600.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneSouthCarolinaFIPS3900FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_South_Carolina_FIPS_3900_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneSouthDakotaNorthFIPS4001",
    `PROJCS["NAD_1983_2011_StatePlane_South_Dakota_North_FIPS_4001",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneSouthDakotaSouthFIPS4002",
    `PROJCS["NAD_1983_2011_StatePlane_South_Dakota_South_FIPS_4002",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneSouthDakotaNorthFIPS4001FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_South_Dakota_North_FIPS_4001_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneSouthDakotaSouthFIPS4002FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_South_Dakota_South_FIPS_4002_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTennesseeFIPS4100",
    `PROJCS["NAD_1983_2011_StatePlane_Tennessee_FIPS_4100",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTennesseeFIPS4100FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Tennessee_FIPS_4100_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTexasNorthFIPS4201",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_North_FIPS_4201",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasNorthCentralFIPS4202",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_North_Central_FIPS_4202",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasCentralFIPS4203",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_Central_FIPS_4203",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasSouthCentralFIPS4204",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_South_Central_FIPS_4204",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasSouthFIPS4205",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_South_FIPS_4205",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneTexasNorthFIPS4201FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_North_FIPS_4201_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTexasNorthCentralFIPS4202FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_North_Central_FIPS_4202_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTexasCentralFIPS4203FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_Central_FIPS_4203_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTexasSouthCentralFIPS4204FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_South_Central_FIPS_4204_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneTexasSouthFIPS4205FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Texas_South_FIPS_4205_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",16404166.66666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneUtahNorthFIPS4301",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_North_FIPS_4301",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneUtahCentralFIPS4302",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_Central_FIPS_4302",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneUtahSouthFIPS4303",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_South_FIPS_4303",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneUtahNorthFIPS4301FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_North_FIPS_4301_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",3280839.895013123],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneUtahCentralFIPS4302FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_Central_FIPS_4302_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",6561679.790026246],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneUtahSouthFIPS4303FtIntl",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_South_FIPS_4303_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",9842519.685039369],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD19832011StatePlaneUtahNorthFIPS4301FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_North_FIPS_4301_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneUtahCentralFIPS4302FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_Central_FIPS_4302_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneUtahSouthFIPS4303FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Utah_South_FIPS_4303_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneVermontFIPS4400",
    `PROJCS["NAD_1983_2011_StatePlane_Vermont_FIPS_4400",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneVermontFIPS4400FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Vermont_FIPS_4400_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneVirginiaNorthFIPS4501",
    `PROJCS["NAD_1983_2011_StatePlane_Virginia_North_FIPS_4501",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneVirginiaSouthFIPS4502",
    `PROJCS["NAD_1983_2011_StatePlane_Virginia_South_FIPS_4502",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneVirginiaNorthFIPS4501FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Virginia_North_FIPS_4501_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneVirginiaSouthFIPS4502FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Virginia_South_FIPS_4502_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWashingtonNorthFIPS4601",
    `PROJCS["NAD_1983_2011_StatePlane_Washington_North_FIPS_4601",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWashingtonSouthFIPS4602",
    `PROJCS["NAD_1983_2011_StatePlane_Washington_South_FIPS_4602",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWashingtonNorthFIPS4601FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Washington_North_FIPS_4601_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWashingtonSouthFIPS4602FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Washington_South_FIPS_4602_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWestVirginiaNorthFIPS4701",
    `PROJCS["NAD_1983_2011_StatePlane_West_Virginia_North_FIPS_4701",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWestVirginiaSouthFIPS4702",
    `PROJCS["NAD_1983_2011_StatePlane_West_Virginia_South_FIPS_4702",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWestVirginiaNorthFIPS4701FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_West_Virginia_North_FIPS_4701_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWestVirginiaSouthFIPS4702FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_West_Virginia_South_FIPS_4702_FtUS",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinNorthFIPS4801",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_North_FIPS_4801",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinCentralFIPS4802",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_Central_FIPS_4802",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinSouthFIPS4803",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_South_FIPS_4803",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinNorthFIPS4801FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_North_FIPS_4801_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinCentralFIPS4802FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_Central_FIPS_4802_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWisconsinSouthFIPS4803FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wisconsin_South_FIPS_4803_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWyomingEastFIPS4901",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_East_FIPS_4901",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWyomingEastCentralFIPS4902",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_East_Central_FIPS_4902",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWyomingWestCentralFIPS4903",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_West_Central_FIPS_4903",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWyomingWestFIPS4904",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_West_FIPS_4904",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD19832011StatePlaneWyomingEastFIPS4901FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_East_FIPS_4901_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWyomingECentralFIPS4902FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_E_Central_FIPS_4902_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWyomingWCentralFIPS4903FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_W_Central_FIPS_4903_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlaneWyomingWestFIPS4904FtUS",
    `PROJCS["NAD_1983_2011_StatePlane_Wyoming_West_FIPS_4904_Ft_US",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011StatePlanePuertoRicoVirginIslsFIPS5200",
    `PROJCS["NAD_1983_2011_StatePlane_Puerto_Rico_Virgin_Isls_FIPS_5200",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",200000.0],PARAMETER["Central_Meridian",-66.43333333333334],PARAMETER["Standard_Parallel_1",18.03333333333333],PARAMETER["Standard_Parallel_2",18.43333333333333],PARAMETER["Latitude_Of_Origin",17.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone12",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_12",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone14",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_14",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",14.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone16",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_16",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",16.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone18",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_18",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",18.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone20",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_20",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",20.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone22",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_22",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",22.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone24",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_24",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone26",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_26",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",26.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005CongoTMZone28",
    `PROJCS["RGRDC_2005_Congo_TM_Zone_28",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",28.0],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005UTMZone33S",
    `PROJCS["RGRDC_2005_UTM_Zone_33S",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005UTMZone34S",
    `PROJCS["RGRDC_2005_UTM_Zone_34S",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "RGRDC2005UTMZone35S",
    `PROJCS["RGRDC_2005_UTM_Zone_35S",GEOGCS["GCS_RGRDC_2005",DATUM["D_Reseau_Geodesique_de_la_RDC_2005",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ChuaUTMZone23S",
    `PROJCS["Chua_UTM_Zone_23S",GEOGCS["GCS_Chua",DATUM["D_Chua",SPHEROID["International_1924",6378388.0,297.0]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",10000000.0],PARAMETER["Central_Meridian",-45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "REGCAN95UTMZone27N",
    `PROJCS["REGCAN95_UTM_Zone_27N",GEOGCS["GCS_REGCAN95",DATUM["D_Red_Geodesica_de_Canarias_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "REGCAN95UTMZone28N",
    `PROJCS["REGCAN95_UTM_Zone_28N",GEOGCS["GCS_REGCAN95",DATUM["D_Red_Geodesica_de_Canarias_1995",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989DKTM1",
    `PROJCS["ETRS_1989_DKTM1",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989DKTM2",
    `PROJCS["ETRS_1989_DKTM2",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",10.0],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989DKTM3",
    `PROJCS["ETRS_1989_DKTM3",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",11.75],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRS1989DKTM4",
    `PROJCS["ETRS_1989_DKTM4",GEOGCS["GCS_ETRS_1989",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",-5000000.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlabamaEastFIPS0101",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alabama_East_FIPS_0101",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-85.83333333333333],PARAMETER["Scale_Factor",0.99996],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneAlabamaWestFIPS0102",
    `PROJCS["NAD_1983_CORS96_StatePlane_Alabama_West_FIPS_0102",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneArizonaEastFIPS0201",
    `PROJCS["NAD_1983_CORS96_StatePlane_Arizona_East_FIPS_0201",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneArizonaCentralFIPS0202",
    `PROJCS["NAD_1983_CORS96_StatePlane_Arizona_Central_FIPS_0202",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneArizonaWestFIPS0203",
    `PROJCS["NAD_1983_CORS96_StatePlane_Arizona_West_FIPS_0203",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",213360.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneArizonaEastFIPS0201FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Arizona_East_FIPS_0201_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-110.1666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneArizonaCentralFIPS0202FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Arizona_Central_FIPS_0202_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.9166666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneArizonaWestFIPS0203FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Arizona_West_FIPS_0203_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-113.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneArkansasNorthFIPS0301",
    `PROJCS["NAD_1983_CORS96_StatePlane_Arkansas_North_FIPS_0301",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneArkansasSouthFIPS0302",
    `PROJCS["NAD_1983_CORS96_StatePlane_Arkansas_South_FIPS_0302",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneArkansasNorthFIPS0301FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Arkansas_North_FIPS_0301_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",34.93333333333333],PARAMETER["Standard_Parallel_2",36.23333333333333],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneArkansasSouthFIPS0302FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Arkansas_South_FIPS_0302_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-92.0],PARAMETER["Standard_Parallel_1",33.3],PARAMETER["Standard_Parallel_2",34.76666666666667],PARAMETER["Latitude_Of_Origin",32.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaIFIPS0401",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_I_FIPS_0401",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaIIFIPS0402",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_II_FIPS_0402",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaIIIFIPS0403",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_III_FIPS_0403",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaIVFIPS0404",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_IV_FIPS_0404",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaVFIPS0405",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_V_FIPS_0405",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaVIFIPS0406",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_VI_FIPS_0406",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaIFIPS0401FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_I_FIPS_0401_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaIIFIPS0402FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_II_FIPS_0402_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaIIIFIPS0403FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_III_FIPS_0403_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaIVFIPS0404FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_IV_FIPS_0404_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaVFIPS0405FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_V_FIPS_0405_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneCaliforniaVIFIPS0406FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_California_VI_FIPS_0406_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneColoradoNorthFIPS0501",
    `PROJCS["NAD_1983_CORS96_StatePlane_Colorado_North_FIPS_0501",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneColoradoCentralFIPS0502",
    `PROJCS["NAD_1983_CORS96_StatePlane_Colorado_Central_FIPS_0502",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneColoradoSouthFIPS0503",
    `PROJCS["NAD_1983_CORS96_StatePlane_Colorado_South_FIPS_0503",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",914401.8289],PARAMETER["False_Northing",304800.6096],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneColoradoNorthFIPS0501FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Colorado_North_FIPS_0501_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",39.71666666666667],PARAMETER["Standard_Parallel_2",40.78333333333333],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneColoradoCentralFIPS0502FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Colorado_Central_FIPS_0502_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",38.45],PARAMETER["Standard_Parallel_2",39.75],PARAMETER["Latitude_Of_Origin",37.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneColoradoSouthFIPS0503FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Colorado_South_FIPS_0503_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3000000.000316083],PARAMETER["False_Northing",999999.999996],PARAMETER["Central_Meridian",-105.5],PARAMETER["Standard_Parallel_1",37.23333333333333],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneConnecticutFIPS0600",
    `PROJCS["NAD_1983_CORS96_StatePlane_Connecticut_FIPS_0600",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",304800.6096],PARAMETER["False_Northing",152400.3048],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneConnecticutFIPS0600FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Connecticut_FIPS_0600_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",999999.999996],PARAMETER["False_Northing",499999.999998],PARAMETER["Central_Meridian",-72.75],PARAMETER["Standard_Parallel_1",41.2],PARAMETER["Standard_Parallel_2",41.86666666666667],PARAMETER["Latitude_Of_Origin",40.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneDelawareFIPS0700",
    `PROJCS["NAD_1983_CORS96_StatePlane_Delaware_FIPS_0700",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneDelawareFIPS0700FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Delaware_FIPS_0700_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.41666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneFloridaEastFIPS0901",
    `PROJCS["NAD_1983_CORS96_StatePlane_Florida_East_FIPS_0901",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneFloridaWestFIPS0902",
    `PROJCS["NAD_1983_CORS96_StatePlane_Florida_West_FIPS_0902",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneFloridaNorthFIPS0903",
    `PROJCS["NAD_1983_CORS96_StatePlane_Florida_North_FIPS_0903",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneFloridaEastFIPS0901FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Florida_East_FIPS_0901_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneFloridaWestFIPS0902FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Florida_West_FIPS_0902_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.0],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",24.33333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneFloridaNorthFIPS0903FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Florida_North_FIPS_0903_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.5],PARAMETER["Standard_Parallel_1",29.58333333333333],PARAMETER["Standard_Parallel_2",30.75],PARAMETER["Latitude_Of_Origin",29.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneGeorgiaEastFIPS1001",
    `PROJCS["NAD_1983_CORS96_StatePlane_Georgia_East_FIPS_1001",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneGeorgiaWestFIPS1002",
    `PROJCS["NAD_1983_CORS96_StatePlane_Georgia_West_FIPS_1002",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneGeorgiaEastFIPS1001FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Georgia_East_FIPS_1001_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneGeorgiaWestFIPS1002FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Georgia_West_FIPS_1002_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.16666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",30.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneIdahoEastFIPS1101",
    `PROJCS["NAD_1983_CORS96_StatePlane_Idaho_East_FIPS_1101",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneIdahoCentralFIPS1102",
    `PROJCS["NAD_1983_CORS96_StatePlane_Idaho_Central_FIPS_1102",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneIdahoWestFIPS1103",
    `PROJCS["NAD_1983_CORS96_StatePlane_Idaho_West_FIPS_1103",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneIdahoEastFIPS1101FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Idaho_East_FIPS_1101_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-112.1666666666667],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneIdahoCentralFIPS1102FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Idaho_Central_FIPS_1102_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-114.0],PARAMETER["Scale_Factor",0.9999473684210526],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneIdahoWestFIPS1103FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Idaho_West_FIPS_1103_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.75],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",41.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneIllinoisEastFIPS1201",
    `PROJCS["NAD_1983_CORS96_StatePlane_Illinois_East_FIPS_1201",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneIllinoisWestFIPS1202",
    `PROJCS["NAD_1983_CORS96_StatePlane_Illinois_West_FIPS_1202",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneIllinoisEastFIPS1201FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Illinois_East_FIPS_1201_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.33333333333333],PARAMETER["Scale_Factor",0.999975],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneIllinoisWestFIPS1202FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Illinois_West_FIPS_1202_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16666666666667],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneIndianaEastFIPS1301",
    `PROJCS["NAD_1983_CORS96_StatePlane_Indiana_East_FIPS_1301",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneIndianaWestFIPS1302",
    `PROJCS["NAD_1983_CORS96_StatePlane_Indiana_West_FIPS_1302",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",250000.0],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneIndianaEastFIPS1301FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Indiana_East_FIPS_1301_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-85.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneIndianaWestFIPS1302FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Indiana_West_FIPS_1302_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",820208.3333333333],PARAMETER["Central_Meridian",-87.08333333333333],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneIowaNorthFIPS1401",
    `PROJCS["NAD_1983_CORS96_StatePlane_Iowa_North_FIPS_1401",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneIowaSouthFIPS1402",
    `PROJCS["NAD_1983_CORS96_StatePlane_Iowa_South_FIPS_1402",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneIowaNorthFIPS1401FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Iowa_North_FIPS_1401_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",42.06666666666667],PARAMETER["Standard_Parallel_2",43.26666666666667],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneIowaSouthFIPS1402FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Iowa_South_FIPS_1402_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.61666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneKansasNorthFIPS1501",
    `PROJCS["NAD_1983_CORS96_StatePlane_Kansas_North_FIPS_1501",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneKansasSouthFIPS1502",
    `PROJCS["NAD_1983_CORS96_StatePlane_Kansas_South_FIPS_1502",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",400000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneKansasNorthFIPS1501FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Kansas_North_FIPS_1501_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",38.71666666666667],PARAMETER["Standard_Parallel_2",39.78333333333333],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneKansasSouthFIPS1502FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Kansas_South_FIPS_1502_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",1312333.333333333],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",37.26666666666667],PARAMETER["Standard_Parallel_2",38.56666666666667],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneKentuckyNorthFIPS1601",
    `PROJCS["NAD_1983_CORS96_StatePlane_Kentucky_North_FIPS_1601",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneKentuckyNorthFIPS1601FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Kentucky_North_FIPS_1601_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.25],PARAMETER["Standard_Parallel_1",37.96666666666667],PARAMETER["Standard_Parallel_2",38.96666666666667],PARAMETER["Latitude_Of_Origin",37.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneKentuckyFIPS1600",
    `PROJCS["NAD_1983_CORS96_StatePlane_Kentucky_FIPS_1600",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneKentuckyFIPS1600FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Kentucky_FIPS_1600_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4921250.0],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",37.08333333333334],PARAMETER["Standard_Parallel_2",38.66666666666666],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneKentuckySouthFIPS1602",
    `PROJCS["NAD_1983_CORS96_StatePlane_Kentucky_South_FIPS_1602",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneKentuckySouthFIPS1602FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Kentucky_South_FIPS_1602_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-85.75],PARAMETER["Standard_Parallel_1",36.73333333333333],PARAMETER["Standard_Parallel_2",37.93333333333333],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneLouisianaNorthFIPS1701",
    `PROJCS["NAD_1983_CORS96_StatePlane_Louisiana_North_FIPS_1701",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneLouisianaSouthFIPS1702",
    `PROJCS["NAD_1983_CORS96_StatePlane_Louisiana_South_FIPS_1702",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneLouisianaNorthFIPS1701FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Louisiana_North_FIPS_1701_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Standard_Parallel_1",31.16666666666667],PARAMETER["Standard_Parallel_2",32.66666666666666],PARAMETER["Latitude_Of_Origin",30.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneLouisianaSouthFIPS1702FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Louisiana_South_FIPS_1702_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3280833.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.33333333333333],PARAMETER["Standard_Parallel_1",29.3],PARAMETER["Standard_Parallel_2",30.7],PARAMETER["Latitude_Of_Origin",28.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMaineEastFIPS1801",
    `PROJCS["NAD_1983_CORS96_StatePlane_Maine_East_FIPS_1801",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMaineWestFIPS1802",
    `PROJCS["NAD_1983_CORS96_StatePlane_Maine_West_FIPS_1802",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",900000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMaineEastFIPS1801FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Maine_East_FIPS_1801_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-68.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",43.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMaineWestFIPS1802FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Maine_West_FIPS_1802_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2952750.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.16666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSAdamsCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Adams_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",147218.6942],PARAMETER["False_Northing",0.0037],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",1.0000365285],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSAshlandCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Ashland_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",172821.9461],PARAMETER["False_Northing",0.0017],PARAMETER["Central_Meridian",-90.62222222222222],PARAMETER["Scale_Factor",1.0000495683],PARAMETER["Latitude_Of_Origin",45.70611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSBarronCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Barron_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",93150.0],PARAMETER["False_Northing",0.0029],PARAMETER["Central_Meridian",-91.85],PARAMETER["Scale_Factor",1.0000486665],PARAMETER["Latitude_Of_Origin",45.13333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSBayfieldCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Bayfield_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",228600.4575],PARAMETER["False_Northing",148551.4837],PARAMETER["Central_Meridian",-91.15277777777779],PARAMETER["Standard_Parallel_1",46.66964837722222],PARAMETER["Scale_Factor",1.0000331195],PARAMETER["Latitude_Of_Origin",46.66964837722222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSBrownCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Brown_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31600.0],PARAMETER["False_Northing",4600.0],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSBuffaloCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Buffalo_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",175260.3502],PARAMETER["False_Northing",0.0048],PARAMETER["Central_Meridian",-91.79722222222222],PARAMETER["Scale_Factor",1.0000382778],PARAMETER["Latitude_Of_Origin",43.48138888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSBurnettCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Burnett_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",64008.1276],PARAMETER["False_Northing",59445.9043],PARAMETER["Central_Meridian",-92.45777777777778],PARAMETER["Standard_Parallel_1",45.89871486583333],PARAMETER["Scale_Factor",1.0000383841],PARAMETER["Latitude_Of_Origin",45.89871486583333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSCalumetCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Calumet_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",244754.8893],PARAMETER["False_Northing",0.0049],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSChippewaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Chippewa_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",60045.72],PARAMETER["False_Northing",44091.4346],PARAMETER["Central_Meridian",-91.29444444444444],PARAMETER["Standard_Parallel_1",44.97785689861112],PARAMETER["Scale_Factor",1.0000391127],PARAMETER["Latitude_Of_Origin",44.97785689861112],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSClarkCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Clark_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",199949.1989],PARAMETER["False_Northing",0.0086],PARAMETER["Central_Meridian",-90.70833333333334],PARAMETER["Scale_Factor",1.0000463003],PARAMETER["Latitude_Of_Origin",43.6],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSColumbiaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Columbia_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",169164.3381],PARAMETER["False_Northing",111569.6134],PARAMETER["Central_Meridian",-89.39444444444445],PARAMETER["Standard_Parallel_1",43.46254664583333],PARAMETER["Scale_Factor",1.00003498],PARAMETER["Latitude_Of_Origin",43.46254664583333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSCrawfordCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Crawford_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",113690.6274],PARAMETER["False_Northing",53703.1201],PARAMETER["Central_Meridian",-90.9388888888889],PARAMETER["Standard_Parallel_1",43.200055605],PARAMETER["Scale_Factor",1.0000349151],PARAMETER["Latitude_Of_Origin",43.200055605],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSDaneCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Dane_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",247193.2944],PARAMETER["False_Northing",146591.9896],PARAMETER["Central_Meridian",-89.42222222222223],PARAMETER["Standard_Parallel_1",43.0695160375],PARAMETER["Scale_Factor",1.0000384786],PARAMETER["Latitude_Of_Origin",43.0695160375],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSDodgeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Dodge_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",263347.7263],PARAMETER["False_Northing",0.0076],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",1.0000346418],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSDoorCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Door_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",158801.1176],PARAMETER["False_Northing",0.0023],PARAMETER["Central_Meridian",-87.27222222222223],PARAMETER["Scale_Factor",1.0000187521],PARAMETER["Latitude_Of_Origin",44.4],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSDouglasCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Douglas_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",59131.3183],PARAMETER["False_Northing",0.0041],PARAMETER["Central_Meridian",-91.91666666666667],PARAMETER["Scale_Factor",1.0000385418],PARAMETER["Latitude_Of_Origin",45.88333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSDunnCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Dunn_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",51816.104],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-91.89444444444445],PARAMETER["Scale_Factor",1.0000410324],PARAMETER["Latitude_Of_Origin",44.40833333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSEauClaireCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_EauClaire_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120091.4402],PARAMETER["False_Northing",91687.9239],PARAMETER["Central_Meridian",-91.28888888888889],PARAMETER["Standard_Parallel_1",44.87228112638889],PARAMETER["Scale_Factor",1.000035079],PARAMETER["Latitude_Of_Origin",44.87228112638889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSFlorenceCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Florence_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",133502.6683],PARAMETER["False_Northing",0.0063],PARAMETER["Central_Meridian",-88.14166666666668],PARAMETER["Scale_Factor",1.0000552095],PARAMETER["Latitude_Of_Origin",45.43888888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSFondduLacCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Fond_du_Lac_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",244754.8893],PARAMETER["False_Northing",0.0049],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSForestCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Forest_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",275844.5533],PARAMETER["False_Northing",0.0157],PARAMETER["Central_Meridian",-88.63333333333334],PARAMETER["Scale_Factor",1.0000673004],PARAMETER["Latitude_Of_Origin",44.00555555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSGrantCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Grant_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",242316.4841],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-90.8],PARAMETER["Scale_Factor",1.0000349452],PARAMETER["Latitude_Of_Origin",41.41111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSGreenCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Green_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",170078.7403],PARAMETER["False_Northing",45830.2947],PARAMETER["Central_Meridian",-89.83888888888889],PARAMETER["Standard_Parallel_1",42.63756227694444],PARAMETER["Scale_Factor",1.0000390487],PARAMETER["Latitude_Of_Origin",42.63756227694444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSGreenLakeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_GreenLake_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150876.3018],PARAMETER["False_Northing",79170.7795],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.80700011777778],PARAMETER["Scale_Factor",1.0000344057],PARAMETER["Latitude_Of_Origin",43.80700011777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSIowaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Iowa_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",113081.0261],PARAMETER["False_Northing",0.0045],PARAMETER["Central_Meridian",-90.16111111111111],PARAMETER["Scale_Factor",1.0000394961],PARAMETER["Latitude_Of_Origin",42.53888888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSIronCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Iron_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",220980.4419],PARAMETER["False_Northing",0.0085],PARAMETER["Central_Meridian",-90.25555555555556],PARAMETER["Scale_Factor",1.0000677153],PARAMETER["Latitude_Of_Origin",45.43333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSJacksonCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Jackson_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",27000.0],PARAMETER["False_Northing",25000.0],PARAMETER["Central_Meridian",-90.84429651944444],PARAMETER["Scale_Factor",1.0000353],PARAMETER["Latitude_Of_Origin",44.25333512777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSJeffersonCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Jefferson_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",263347.7263],PARAMETER["False_Northing",0.0076],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",1.0000346418],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSJuneauCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Juneau_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",147218.6942],PARAMETER["False_Northing",0.0037],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",1.0000365285],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSKenoshaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Kenosha_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185928.3728],PARAMETER["False_Northing",0.0009],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSKewauneeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Kewaunee_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",79857.7614],PARAMETER["False_Northing",0.0012],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0000233704],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSLaCrosseCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_LaCrosse_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",130454.6598],PARAMETER["False_Northing",0.0033],PARAMETER["Central_Meridian",-91.31666666666666],PARAMETER["Scale_Factor",1.0000319985],PARAMETER["Latitude_Of_Origin",43.45111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSLafayetteCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Lafayette_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",170078.7403],PARAMETER["False_Northing",45830.2947],PARAMETER["Central_Meridian",-89.83888888888889],PARAMETER["Standard_Parallel_1",42.63756227694444],PARAMETER["Scale_Factor",1.0000390487],PARAMETER["Latitude_Of_Origin",42.63756227694444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSLangladeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Langlade_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",198425.197],PARAMETER["False_Northing",105279.7829],PARAMETER["Central_Meridian",-89.03333333333333],PARAMETER["Standard_Parallel_1",45.15423710527778],PARAMETER["Scale_Factor",1.0000627024],PARAMETER["Latitude_Of_Origin",45.15423710527778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSLincolnCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Lincoln_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",116129.0323],PARAMETER["False_Northing",0.0058],PARAMETER["Central_Meridian",-89.73333333333333],PARAMETER["Scale_Factor",1.0000599003],PARAMETER["Latitude_Of_Origin",44.84444444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSManitowocCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Manitowoc_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",79857.7614],PARAMETER["False_Northing",0.0012],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0000233704],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSMarathonCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Marathon_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",74676.1493],PARAMETER["False_Northing",55049.2669],PARAMETER["Central_Meridian",-89.77],PARAMETER["Standard_Parallel_1",44.90090442361111],PARAMETER["Scale_Factor",1.000053289],PARAMETER["Latitude_Of_Origin",44.90090442361111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSMarinetteCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Marinette_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",238658.8794],PARAMETER["False_Northing",0.0032],PARAMETER["Central_Meridian",-87.71111111111111],PARAMETER["Scale_Factor",1.0000234982],PARAMETER["Latitude_Of_Origin",44.69166666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSMarquetteCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Marquette_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150876.3018],PARAMETER["False_Northing",79170.7795],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.80700011777778],PARAMETER["Scale_Factor",1.0000344057],PARAMETER["Latitude_Of_Origin",43.80700011777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSMenomineeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Menominee_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",105461.0121],PARAMETER["False_Northing",0.0029],PARAMETER["Central_Meridian",-88.41666666666667],PARAMETER["Scale_Factor",1.0000362499],PARAMETER["Latitude_Of_Origin",44.71666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSMilwaukeeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Milwaukee_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185928.3728],PARAMETER["False_Northing",0.0009],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSMonroeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Monroe_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",204521.209],PARAMETER["False_Northing",121923.9861],PARAMETER["Central_Meridian",-90.64166666666668],PARAMETER["Standard_Parallel_1",44.00007392861111],PARAMETER["Scale_Factor",1.0000434122],PARAMETER["Latitude_Of_Origin",44.00007392861111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSOcontoCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Oconto_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",182880.3676],PARAMETER["False_Northing",0.0033],PARAMETER["Central_Meridian",-87.90833333333335],PARAMETER["Scale_Factor",1.0000236869],PARAMETER["Latitude_Of_Origin",44.39722222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSOneidaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Oneida_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",70104.1401],PARAMETER["False_Northing",57588.0346],PARAMETER["Central_Meridian",-89.54444444444444],PARAMETER["Standard_Parallel_1",45.70422377027778],PARAMETER["Scale_Factor",1.0000686968],PARAMETER["Latitude_Of_Origin",45.70422377027778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSOutagamieCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Outagamie_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",244754.8893],PARAMETER["False_Northing",0.0049],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSOzaukeeCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Ozaukee_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185928.3728],PARAMETER["False_Northing",0.0009],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSPepinCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Pepin_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",167640.3354],PARAMETER["False_Northing",86033.0876],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.63614887194444],PARAMETER["Scale_Factor",1.0000362977],PARAMETER["Latitude_Of_Origin",44.63614887194444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSPierceCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Pierce_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",167640.3354],PARAMETER["False_Northing",86033.0876],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.63614887194444],PARAMETER["Scale_Factor",1.0000362977],PARAMETER["Latitude_Of_Origin",44.63614887194444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSPolkCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Polk_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",141732.2823],PARAMETER["False_Northing",0.0059],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000433849],PARAMETER["Latitude_Of_Origin",44.66111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSPortageCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Portage_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",56388.1128],PARAMETER["False_Northing",50022.1874],PARAMETER["Central_Meridian",-89.5],PARAMETER["Standard_Parallel_1",44.41682397527777],PARAMETER["Scale_Factor",1.000039936],PARAMETER["Latitude_Of_Origin",44.41682397527777],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSPriceCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Price_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",227990.8546],PARAMETER["False_Northing",0.0109],PARAMETER["Central_Meridian",-90.48888888888889],PARAMETER["Scale_Factor",1.0000649554],PARAMETER["Latitude_Of_Origin",44.55555555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSRacineCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Racine_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185928.3728],PARAMETER["False_Northing",0.0009],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSRichlandCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Richland_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",202387.6048],PARAMETER["False_Northing",134255.4253],PARAMETER["Central_Meridian",-90.43055555555556],PARAMETER["Standard_Parallel_1",43.3223129275],PARAMETER["Scale_Factor",1.0000375653],PARAMETER["Latitude_Of_Origin",43.3223129275],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSRockCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Rock_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",146304.2926],PARAMETER["False_Northing",0.0068],PARAMETER["Central_Meridian",-89.07222222222222],PARAMETER["Scale_Factor",1.0000337311],PARAMETER["Latitude_Of_Origin",41.94444444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSRuskCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Rusk_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250546.1013],PARAMETER["False_Northing",0.0234],PARAMETER["Central_Meridian",-91.06666666666666],PARAMETER["Scale_Factor",1.0000495976],PARAMETER["Latitude_Of_Origin",43.91944444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSSaukCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Sauk_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185623.5716],PARAMETER["False_Northing",0.0051],PARAMETER["Central_Meridian",-89.9],PARAMETER["Scale_Factor",1.0000373868],PARAMETER["Latitude_Of_Origin",42.81944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSSawyerCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Sawyer_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",216713.2336],PARAMETER["False_Northing",120734.1631],PARAMETER["Central_Meridian",-91.11666666666666],PARAMETER["Standard_Parallel_1",45.90009913138888],PARAMETER["Scale_Factor",1.0000573461],PARAMETER["Latitude_Of_Origin",45.90009913138888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSShawanoCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Shawano_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262433.3253],PARAMETER["False_Northing",0.0096],PARAMETER["Central_Meridian",-88.60555555555555],PARAMETER["Scale_Factor",1.000032144],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSSheboyganCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Sheboygan_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",79857.7614],PARAMETER["False_Northing",0.0012],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0000233704],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSStCroixCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_St_Croix_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165506.7302],PARAMETER["False_Northing",0.0103],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000381803],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSTaylorCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Taylor_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",187147.5744],PARAMETER["False_Northing",107746.7522],PARAMETER["Central_Meridian",-90.48333333333333],PARAMETER["Standard_Parallel_1",45.17782208583333],PARAMETER["Scale_Factor",1.0000597566],PARAMETER["Latitude_Of_Origin",45.17782208583333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSTrempealeauCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Trempealeau_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",256946.9138],PARAMETER["False_Northing",0.0041],PARAMETER["Central_Meridian",-91.36666666666666],PARAMETER["Scale_Factor",1.0000361538],PARAMETER["Latitude_Of_Origin",43.16111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSVernonCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Vernon_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",222504.4451],PARAMETER["False_Northing",47532.0602],PARAMETER["Central_Meridian",-90.78333333333333],PARAMETER["Standard_Parallel_1",43.57503293972223],PARAMETER["Scale_Factor",1.0000408158],PARAMETER["Latitude_Of_Origin",43.57503293972223],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSVilasCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Vilas_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",134417.0689],PARAMETER["False_Northing",50337.1092],PARAMETER["Central_Meridian",-89.48888888888889],PARAMETER["Standard_Parallel_1",46.07784409055556],PARAMETER["Scale_Factor",1.0000730142],PARAMETER["Latitude_Of_Origin",46.07784409055556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWalworthCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Walworth_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",232562.8651],PARAMETER["False_Northing",111088.2224],PARAMETER["Central_Meridian",-88.54166666666667],PARAMETER["Standard_Parallel_1",42.66946209694444],PARAMETER["Scale_Factor",1.0000367192],PARAMETER["Latitude_Of_Origin",42.66946209694444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWashburnCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Washburn_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",234086.8682],PARAMETER["False_Northing",188358.6058],PARAMETER["Central_Meridian",-91.78333333333333],PARAMETER["Standard_Parallel_1",45.96121983333334],PARAMETER["Scale_Factor",1.0000475376],PARAMETER["Latitude_Of_Origin",45.96121983333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWashingtonCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Washington_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",120091.4415],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-88.06388888888888],PARAMETER["Scale_Factor",1.00003738],PARAMETER["Latitude_Of_Origin",42.91805555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWaukeshaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Waukesha_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",208788.418],PARAMETER["False_Northing",0.0034],PARAMETER["Central_Meridian",-88.225],PARAMETER["Scale_Factor",1.0000346179],PARAMETER["Latitude_Of_Origin",42.56944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWaupacaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Waupaca_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185013.9709],PARAMETER["False_Northing",0.007],PARAMETER["Central_Meridian",-88.81666666666666],PARAMETER["Scale_Factor",1.0000333645],PARAMETER["Latitude_Of_Origin",43.42027777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWausharaCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Waushara_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120091.4402],PARAMETER["False_Northing",45069.7587],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",44.11394404583334],PARAMETER["Scale_Factor",1.0000392096],PARAMETER["Latitude_Of_Origin",44.11394404583334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWinnebagoCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Winnebago_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",244754.8893],PARAMETER["False_Northing",0.0049],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSWoodCountyMeters",
    `PROJCS["NAD_1983_HARN_WISCRS_Wood_County_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",208483.6173],PARAMETER["False_Northing",134589.754],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.36259546944444],PARAMETER["Scale_Factor",1.0000421209],PARAMETER["Latitude_Of_Origin",44.36259546944444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96Maine2000EastZone",
    `PROJCS["NAD_1983_CORS96_Maine_2000_East_Zone",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-67.875],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96Maine2000CentralZone",
    `PROJCS["NAD_1983_CORS96_Maine_2000_Central_Zone",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.125],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96Maine2000WestZone",
    `PROJCS["NAD_1983_CORS96_Maine_2000_West_Zone",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.375],PARAMETER["Scale_Factor",0.99998],PARAMETER["Latitude_Of_Origin",42.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMarylandFIPS1900",
    `PROJCS["NAD_1983_CORS96_StatePlane_Maryland_FIPS_1900",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMarylandFIPS1900FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Maryland_FIPS_1900_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.0],PARAMETER["Standard_Parallel_1",38.3],PARAMETER["Standard_Parallel_2",39.45],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMassachusettsMainlandFIPS2001",
    `PROJCS["NAD_1983_CORS96_StatePlane_Massachusetts_Mainland_FIPS_2001",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",750000.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMassachusettsIslandFIPS2002",
    `PROJCS["NAD_1983_CORS96_StatePlane_Massachusetts_Island_FIPS_2002",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMassachusettsMnldFIPS2001FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Massachusetts_Mnld_FIPS_2001_FtUS",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",2460625.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Standard_Parallel_1",41.71666666666667],PARAMETER["Standard_Parallel_2",42.68333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMassachusettsIslFIPS2002FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Massachusetts_Isl_FIPS_2002_FtUS",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-70.5],PARAMETER["Standard_Parallel_1",41.28333333333333],PARAMETER["Standard_Parallel_2",41.48333333333333],PARAMETER["Latitude_Of_Origin",41.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMichiganNorthFIPS2111",
    `PROJCS["NAD_1983_CORS96_StatePlane_Michigan_North_FIPS_2111",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",8000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMichiganCentralFIPS2112",
    `PROJCS["NAD_1983_CORS96_StatePlane_Michigan_Central_FIPS_2112",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMichiganSouthFIPS2113",
    `PROJCS["NAD_1983_CORS96_StatePlane_Michigan_South_FIPS_2113",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",4000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMichiganNorthFIPS2111FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Michigan_North_FIPS_2111_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",26246719.16010498],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Standard_Parallel_1",45.48333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",44.78333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneMichiganCentralFIPS2112FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Michigan_Central_FIPS_2112_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",19685039.37007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",43.31666666666667],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneMichiganSouthFIPS2113FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Michigan_South_FIPS_2113_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",13123359.58005249],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-84.36666666666666],PARAMETER["Standard_Parallel_1",42.1],PARAMETER["Standard_Parallel_2",43.66666666666666],PARAMETER["Latitude_Of_Origin",41.5],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneMinnesotaNorthFIPS2201",
    `PROJCS["NAD_1983_CORS96_StatePlane_Minnesota_North_FIPS_2201",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMinnesotaCentralFIPS2202",
    `PROJCS["NAD_1983_CORS96_StatePlane_Minnesota_Central_FIPS_2202",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMinnesotaSouthFIPS2203",
    `PROJCS["NAD_1983_CORS96_StatePlane_Minnesota_South_FIPS_2203",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMinnesotaNorthFIPS2201FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Minnesota_North_FIPS_2201_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-93.1],PARAMETER["Standard_Parallel_1",47.03333333333333],PARAMETER["Standard_Parallel_2",48.63333333333333],PARAMETER["Latitude_Of_Origin",46.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMinnesotaCentralFIPS2202FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Minnesota_Central_FIPS_2202_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.25],PARAMETER["Standard_Parallel_1",45.61666666666667],PARAMETER["Standard_Parallel_2",47.05],PARAMETER["Latitude_Of_Origin",45.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMinnesotaSouthFIPS2203FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Minnesota_South_FIPS_2203_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-94.0],PARAMETER["Standard_Parallel_1",43.78333333333333],PARAMETER["Standard_Parallel_2",45.21666666666667],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMississippiEastFIPS2301",
    `PROJCS["NAD_1983_CORS96_StatePlane_Mississippi_East_FIPS_2301",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMississippiWestFIPS2302",
    `PROJCS["NAD_1983_CORS96_StatePlane_Mississippi_West_FIPS_2302",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMississippiEastFIPS2301FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Mississippi_East_FIPS_2301_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.83333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMississippiWestFIPS2302FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Mississippi_West_FIPS_2302_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.33333333333333],PARAMETER["Scale_Factor",0.99995],PARAMETER["Latitude_Of_Origin",29.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMissouriEastFIPS2401",
    `PROJCS["NAD_1983_CORS96_StatePlane_Missouri_East_FIPS_2401",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMissouriCentralFIPS2402",
    `PROJCS["NAD_1983_CORS96_StatePlane_Missouri_Central_FIPS_2402",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.5],PARAMETER["Scale_Factor",0.9999333333333333],PARAMETER["Latitude_Of_Origin",35.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMissouriWestFIPS2403",
    `PROJCS["NAD_1983_CORS96_StatePlane_Missouri_West_FIPS_2403",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",850000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-94.5],PARAMETER["Scale_Factor",0.9999411764705882],PARAMETER["Latitude_Of_Origin",36.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNWISCRSAdamsCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Adams_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",482999.999],PARAMETER["False_Northing",0.012],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",1.0000365285],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSAshlandCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Ashland_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",567000.001],PARAMETER["False_Northing",0.006],PARAMETER["Central_Meridian",-90.62222222222222],PARAMETER["Scale_Factor",1.0000495683],PARAMETER["Latitude_Of_Origin",45.70611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSBarronCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Barron_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",305609.625],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-91.85],PARAMETER["Scale_Factor",1.0000486665],PARAMETER["Latitude_Of_Origin",45.13333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSBayfieldCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Bayfield_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",750000.001],PARAMETER["False_Northing",487372.659],PARAMETER["Central_Meridian",-91.15277777777779],PARAMETER["Standard_Parallel_1",46.66964837722222],PARAMETER["Scale_Factor",1.0000331195],PARAMETER["Latitude_Of_Origin",46.66964837722222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSBrownCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Brown_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",103674.333],PARAMETER["False_Northing",15091.833],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSBuffaloCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Buffalo_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",574999.999],PARAMETER["False_Northing",0.016],PARAMETER["Central_Meridian",-91.79722222222222],PARAMETER["Scale_Factor",1.0000382778],PARAMETER["Latitude_Of_Origin",43.48138888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSBurnettCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Burnett_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",209999.999],PARAMETER["False_Northing",195032.104],PARAMETER["Central_Meridian",-92.45777777777778],PARAMETER["Standard_Parallel_1",45.89871486583333],PARAMETER["Scale_Factor",1.0000383841],PARAMETER["Latitude_Of_Origin",45.89871486583333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSCalumetCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Calumet_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",802999.999],PARAMETER["False_Northing",0.016],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSChippewaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Chippewa_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",197000.0],PARAMETER["False_Northing",144656.648],PARAMETER["Central_Meridian",-91.29444444444444],PARAMETER["Standard_Parallel_1",44.97785689861112],PARAMETER["Scale_Factor",1.0000391127],PARAMETER["Latitude_Of_Origin",44.97785689861112],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSClarkCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Clark_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",655999.997],PARAMETER["False_Northing",0.028],PARAMETER["Central_Meridian",-90.70833333333334],PARAMETER["Scale_Factor",1.0000463003],PARAMETER["Latitude_Of_Origin",43.6],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSColumbiaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Columbia_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",554999.999],PARAMETER["False_Northing",366041.307],PARAMETER["Central_Meridian",-89.39444444444445],PARAMETER["Standard_Parallel_1",43.46254664583333],PARAMETER["Scale_Factor",1.00003498],PARAMETER["Latitude_Of_Origin",43.46254664583333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSCrawfordCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Crawford_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",373000.0],PARAMETER["False_Northing",176190.987],PARAMETER["Central_Meridian",-90.9388888888889],PARAMETER["Standard_Parallel_1",43.200055605],PARAMETER["Scale_Factor",1.0000349151],PARAMETER["Latitude_Of_Origin",43.200055605],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSDaneCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Dane_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",811000.0],PARAMETER["False_Northing",480943.886],PARAMETER["Central_Meridian",-89.42222222222223],PARAMETER["Standard_Parallel_1",43.0695160375],PARAMETER["Scale_Factor",1.0000384786],PARAMETER["Latitude_Of_Origin",43.0695160375],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSDodgeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Dodge_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",863999.999],PARAMETER["False_Northing",0.025],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",1.0000346418],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSDoorCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Door_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",521000.0],PARAMETER["False_Northing",0.008],PARAMETER["Central_Meridian",-87.27222222222223],PARAMETER["Scale_Factor",1.0000187521],PARAMETER["Latitude_Of_Origin",44.4],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSDouglasCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Douglas_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",194000.0],PARAMETER["False_Northing",0.013],PARAMETER["Central_Meridian",-91.91666666666667],PARAMETER["Scale_Factor",1.0000385418],PARAMETER["Latitude_Of_Origin",45.88333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSDunnCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Dunn_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",170000.001],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-91.89444444444445],PARAMETER["Scale_Factor",1.0000410324],PARAMETER["Latitude_Of_Origin",44.40833333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSEauClaireCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_EauClaire_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",394000.0],PARAMETER["False_Northing",300812.797],PARAMETER["Central_Meridian",-91.28888888888889],PARAMETER["Standard_Parallel_1",44.87228112638889],PARAMETER["Scale_Factor",1.000035079],PARAMETER["Latitude_Of_Origin",44.87228112638889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSFlorenceCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Florence_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",438000.004],PARAMETER["False_Northing",0.021],PARAMETER["Central_Meridian",-88.14166666666668],PARAMETER["Scale_Factor",1.0000552095],PARAMETER["Latitude_Of_Origin",45.43888888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSFondduLacCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Fond_du_Lac_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",802999.999],PARAMETER["False_Northing",0.016],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSForestCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Forest_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",905000.005],PARAMETER["False_Northing",0.052],PARAMETER["Central_Meridian",-88.63333333333334],PARAMETER["Scale_Factor",1.0000673004],PARAMETER["Latitude_Of_Origin",44.00555555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSGrantCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Grant_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",794999.998],PARAMETER["False_Northing",0.033],PARAMETER["Central_Meridian",-90.8],PARAMETER["Scale_Factor",1.0000349452],PARAMETER["Latitude_Of_Origin",41.41111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSGreenCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Green_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",558000.0],PARAMETER["False_Northing",150361.559],PARAMETER["Central_Meridian",-89.83888888888889],PARAMETER["Standard_Parallel_1",42.63756227694444],PARAMETER["Scale_Factor",1.0000390487],PARAMETER["Latitude_Of_Origin",42.63756227694444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSGreenLakeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_GreenLake_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",495000.0],PARAMETER["False_Northing",259746.132],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.80700011777778],PARAMETER["Scale_Factor",1.0000344057],PARAMETER["Latitude_Of_Origin",43.80700011777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSIowaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Iowa_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",371000.0],PARAMETER["False_Northing",0.015],PARAMETER["Central_Meridian",-90.16111111111111],PARAMETER["Scale_Factor",1.0000394961],PARAMETER["Latitude_Of_Origin",42.53888888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSIronCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Iron_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",725000.0],PARAMETER["False_Northing",0.028],PARAMETER["Central_Meridian",-90.25555555555556],PARAMETER["Scale_Factor",1.0000677153],PARAMETER["Latitude_Of_Origin",45.43333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSJacksonCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Jackson_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88582.5],PARAMETER["False_Northing",82020.833],PARAMETER["Central_Meridian",-90.84429651944444],PARAMETER["Scale_Factor",1.0000353],PARAMETER["Latitude_Of_Origin",44.25333512777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSJeffersonCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Jefferson_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",863999.999],PARAMETER["False_Northing",0.025],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",1.0000346418],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSJuneauCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Juneau_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",482999.999],PARAMETER["False_Northing",0.012],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",1.0000365285],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSKenoshaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Kenosha_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",610000.003],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSKewauneeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Kewaunee_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262000.006],PARAMETER["False_Northing",0.004],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0000233704],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSLaCrosseCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_LaCrosse_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",427999.996],PARAMETER["False_Northing",0.011],PARAMETER["Central_Meridian",-91.31666666666666],PARAMETER["Scale_Factor",1.0000319985],PARAMETER["Latitude_Of_Origin",43.45111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSLafayetteCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Lafayette_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",558000.0],PARAMETER["False_Northing",150361.559],PARAMETER["Central_Meridian",-89.83888888888889],PARAMETER["Standard_Parallel_1",42.63756227694444],PARAMETER["Scale_Factor",1.0000390487],PARAMETER["Latitude_Of_Origin",42.63756227694444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSLangladeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Langlade_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",651000.0],PARAMETER["False_Northing",345405.421],PARAMETER["Central_Meridian",-89.03333333333333],PARAMETER["Standard_Parallel_1",45.15423710527778],PARAMETER["Scale_Factor",1.0000627024],PARAMETER["Latitude_Of_Origin",45.15423710527778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSLincolnCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Lincoln_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",381000.0],PARAMETER["False_Northing",0.019],PARAMETER["Central_Meridian",-89.73333333333333],PARAMETER["Scale_Factor",1.0000599003],PARAMETER["Latitude_Of_Origin",44.84444444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSManitowocCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Manitowoc_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262000.006],PARAMETER["False_Northing",0.004],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0000233704],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSMarathonCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Marathon_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",245000.0],PARAMETER["False_Northing",180607.47],PARAMETER["Central_Meridian",-89.77],PARAMETER["Standard_Parallel_1",44.90090442361111],PARAMETER["Scale_Factor",1.000053289],PARAMETER["Latitude_Of_Origin",44.90090442361111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSMarinetteCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Marinette_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",783000.007],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-87.71111111111111],PARAMETER["Scale_Factor",1.0000234982],PARAMETER["Latitude_Of_Origin",44.69166666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSMarquetteCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Marquette_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",495000.0],PARAMETER["False_Northing",259746.132],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.80700011777778],PARAMETER["Scale_Factor",1.0000344057],PARAMETER["Latitude_Of_Origin",43.80700011777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSMenomineeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Menominee_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",346000.004],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-88.41666666666667],PARAMETER["Scale_Factor",1.0000362499],PARAMETER["Latitude_Of_Origin",44.71666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSMilwaukeeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Milwaukee_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",610000.003],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSMonroeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Monroe_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",671000.0],PARAMETER["False_Northing",400012.278],PARAMETER["Central_Meridian",-90.64166666666668],PARAMETER["Standard_Parallel_1",44.00007392861111],PARAMETER["Scale_Factor",1.0000434122],PARAMETER["Latitude_Of_Origin",44.00007392861111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSOcontoCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Oconto_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.006],PARAMETER["False_Northing",0.011],PARAMETER["Central_Meridian",-87.90833333333335],PARAMETER["Scale_Factor",1.0000236869],PARAMETER["Latitude_Of_Origin",44.39722222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSOneidaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Oneida_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",230000.0],PARAMETER["False_Northing",188936.744],PARAMETER["Central_Meridian",-89.54444444444444],PARAMETER["Standard_Parallel_1",45.70422377027778],PARAMETER["Scale_Factor",1.0000686968],PARAMETER["Latitude_Of_Origin",45.70422377027778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSOutagamieCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Outagamie_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",802999.999],PARAMETER["False_Northing",0.016],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSOzaukeeCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Ozaukee_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",610000.003],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSPepinCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Pepin_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",550000.0],PARAMETER["False_Northing",282260.222],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.63614887194444],PARAMETER["Scale_Factor",1.0000362977],PARAMETER["Latitude_Of_Origin",44.63614887194444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSPierceCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Pierce_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",550000.0],PARAMETER["False_Northing",282260.222],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.63614887194444],PARAMETER["Scale_Factor",1.0000362977],PARAMETER["Latitude_Of_Origin",44.63614887194444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSPolkCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Polk_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",464999.996],PARAMETER["False_Northing",0.019],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000433849],PARAMETER["Latitude_Of_Origin",44.66111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSPortageCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Portage_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",185000.0],PARAMETER["False_Northing",164114.46],PARAMETER["Central_Meridian",-89.5],PARAMETER["Standard_Parallel_1",44.41682397527777],PARAMETER["Scale_Factor",1.000039936],PARAMETER["Latitude_Of_Origin",44.41682397527777],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSPriceCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Price_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",747999.995],PARAMETER["False_Northing",0.036],PARAMETER["Central_Meridian",-90.48888888888889],PARAMETER["Scale_Factor",1.0000649554],PARAMETER["Latitude_Of_Origin",44.55555555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSRacineCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Racine_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",610000.003],PARAMETER["False_Northing",0.003],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",1.0000260649],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSRichlandCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Richland_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",664000.0],PARAMETER["False_Northing",440469.675],PARAMETER["Central_Meridian",-90.43055555555556],PARAMETER["Standard_Parallel_1",43.3223129275],PARAMETER["Scale_Factor",1.0000375653],PARAMETER["Latitude_Of_Origin",43.3223129275],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSRockCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Rock_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",480000.0],PARAMETER["False_Northing",0.022],PARAMETER["Central_Meridian",-89.07222222222222],PARAMETER["Scale_Factor",1.0000337311],PARAMETER["Latitude_Of_Origin",41.94444444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSRuskCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Rusk_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",822000.001],PARAMETER["False_Northing",0.077],PARAMETER["Central_Meridian",-91.06666666666666],PARAMETER["Scale_Factor",1.0000495976],PARAMETER["Latitude_Of_Origin",43.91944444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSSaukCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Sauk_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",609000.001],PARAMETER["False_Northing",0.017],PARAMETER["Central_Meridian",-89.9],PARAMETER["Scale_Factor",1.0000373868],PARAMETER["Latitude_Of_Origin",42.81944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSSawyerCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Sawyer_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",711000.001],PARAMETER["False_Northing",396108.667],PARAMETER["Central_Meridian",-91.11666666666666],PARAMETER["Standard_Parallel_1",45.90009913138888],PARAMETER["Scale_Factor",1.0000573461],PARAMETER["Latitude_Of_Origin",45.90009913138888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSShawanoCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Shawano_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",861000.001],PARAMETER["False_Northing",0.031],PARAMETER["Central_Meridian",-88.60555555555555],PARAMETER["Scale_Factor",1.000032144],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSSheboyganCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Sheboygan_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262000.006],PARAMETER["False_Northing",0.004],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0000233704],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSStCroixCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_St_Croix_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",542999.997],PARAMETER["False_Northing",0.034],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0000381803],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSTaylorCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Taylor_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",614000.0],PARAMETER["False_Northing",353499.136],PARAMETER["Central_Meridian",-90.48333333333333],PARAMETER["Standard_Parallel_1",45.17782208583333],PARAMETER["Scale_Factor",1.0000597566],PARAMETER["Latitude_Of_Origin",45.17782208583333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSTrempealeauCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Trempealeau_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",843000.0],PARAMETER["False_Northing",0.013],PARAMETER["Central_Meridian",-91.36666666666666],PARAMETER["Scale_Factor",1.0000361538],PARAMETER["Latitude_Of_Origin",43.16111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSVernonCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Vernon_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",730000.0],PARAMETER["False_Northing",155944.768],PARAMETER["Central_Meridian",-90.78333333333333],PARAMETER["Standard_Parallel_1",43.57503293972223],PARAMETER["Scale_Factor",1.0000408158],PARAMETER["Latitude_Of_Origin",43.57503293972223],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSVilasCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Vilas_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",441000.0],PARAMETER["False_Northing",165147.666],PARAMETER["Central_Meridian",-89.48888888888889],PARAMETER["Standard_Parallel_1",46.07784409055556],PARAMETER["Scale_Factor",1.0000730142],PARAMETER["Latitude_Of_Origin",46.07784409055556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWalworthCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Walworth_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",763000.0],PARAMETER["False_Northing",364461.943],PARAMETER["Central_Meridian",-88.54166666666667],PARAMETER["Standard_Parallel_1",42.66946209694444],PARAMETER["Scale_Factor",1.0000367192],PARAMETER["Latitude_Of_Origin",42.66946209694444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWashburnCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Washburn_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",768000.0],PARAMETER["False_Northing",617973.193],PARAMETER["Central_Meridian",-91.78333333333333],PARAMETER["Standard_Parallel_1",45.96121983333334],PARAMETER["Scale_Factor",1.0000475376],PARAMETER["Latitude_Of_Origin",45.96121983333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWashingtonCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Washington_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",394000.004],PARAMETER["False_Northing",0.01],PARAMETER["Central_Meridian",-88.06388888888888],PARAMETER["Scale_Factor",1.00003738],PARAMETER["Latitude_Of_Origin",42.91805555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWaukeshaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Waukesha_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",685000.001],PARAMETER["False_Northing",0.011],PARAMETER["Central_Meridian",-88.225],PARAMETER["Scale_Factor",1.0000346179],PARAMETER["Latitude_Of_Origin",42.56944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWaupacaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Waupaca_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",607000.003],PARAMETER["False_Northing",0.023],PARAMETER["Central_Meridian",-88.81666666666666],PARAMETER["Scale_Factor",1.0000333645],PARAMETER["Latitude_Of_Origin",43.42027777777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWausharaCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Waushara_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",394000.0],PARAMETER["False_Northing",147866.367],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",44.11394404583334],PARAMETER["Scale_Factor",1.0000392096],PARAMETER["Latitude_Of_Origin",44.11394404583334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWinnebagoCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Winnebago_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",802999.999],PARAMETER["False_Northing",0.016],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",1.0000286569],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNWISCRSWoodCountyFeet",
    `PROJCS["NAD_1983_HARN_WISCRS_Wood_County_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",684000.001],PARAMETER["False_Northing",441566.551],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.36259546944444],PARAMETER["Scale_Factor",1.0000421209],PARAMETER["Latitude_Of_Origin",44.36259546944444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneMontanaFIPS2500",
    `PROJCS["NAD_1983_CORS96_StatePlane_Montana_FIPS_2500",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneMontanaFIPS2500FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Montana_FIPS_2500_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",49.0],PARAMETER["Latitude_Of_Origin",44.25],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneNebraskaFIPS2600",
    `PROJCS["NAD_1983_CORS96_StatePlane_Nebraska_FIPS_2600",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNebraskaFIPS2600FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Nebraska_FIPS_2600_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",43.0],PARAMETER["Latitude_Of_Origin",39.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNevadaEastFIPS2701",
    `PROJCS["NAD_1983_CORS96_StatePlane_Nevada_East_FIPS_2701",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",8000000.0],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNevadaCentralFIPS2702",
    `PROJCS["NAD_1983_CORS96_StatePlane_Nevada_Central_FIPS_2702",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",6000000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNevadaWestFIPS2703",
    `PROJCS["NAD_1983_CORS96_StatePlane_Nevada_West_FIPS_2703",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNevadaEastFIPS2701FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Nevada_East_FIPS_2701_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",26246666.66666666],PARAMETER["Central_Meridian",-115.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNevadaCentralFIPS2702FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Nevada_Central_FIPS_2702_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",19685000.0],PARAMETER["Central_Meridian",-116.6666666666667],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNevadaWestFIPS2703FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Nevada_West_FIPS_2703_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-118.5833333333333],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",34.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewHampshireFIPS2800",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_Hampshire_FIPS_2800",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewHampshireFIPS2800FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_Hampshire_FIPS_2800_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.66666666666667],PARAMETER["Scale_Factor",0.9999666666666667],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewJerseyFIPS2900",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_Jersey_FIPS_2900",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewJerseyFIPS2900FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_Jersey_FIPS_2900_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewMexicoEastFIPS3001",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_Mexico_East_FIPS_3001",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewMexicoCentralFIPS3002",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_Mexico_Central_FIPS_3002",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewMexicoWestFIPS3003",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_Mexico_West_FIPS_3003",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",830000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewMexicoEastFIPS3001FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_Mexico_East_FIPS_3001_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",541337.5],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-104.3333333333333],PARAMETER["Scale_Factor",0.9999090909090909],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewMexicoCentralFIPS3002FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_Mexico_Central_FIPS_3002_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-106.25],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewMexicoWestFIPS3003FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_Mexico_West_FIPS_3003_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2723091.666666666],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-107.8333333333333],PARAMETER["Scale_Factor",0.9999166666666667],PARAMETER["Latitude_Of_Origin",31.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewYorkEastFIPS3101",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_York_East_FIPS_3101",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",150000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewYorkCentralFIPS3102",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_York_Central_FIPS_3102",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewYorkWestFIPS3103",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_York_West_FIPS_3103",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",350000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewYorkLongIslandFIPS3104",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_York_Long_Island_FIPS_3104",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewYorkEastFIPS3101FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_York_East_FIPS_3101_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",492125.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Latitude_Of_Origin",38.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewYorkCentralFIPS3102FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_York_Central_FIPS_3102_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",820208.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-76.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewYorkWestFIPS3103FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_York_West_FIPS_3103_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1148291.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-78.58333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNewYorkLongIslFIPS3104FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_New_York_Long_Isl_FIPS_3104_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-74.0],PARAMETER["Standard_Parallel_1",40.66666666666666],PARAMETER["Standard_Parallel_2",41.03333333333333],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNorthCarolinaFIPS3200",
    `PROJCS["NAD_1983_CORS96_StatePlane_North_Carolina_FIPS_3200",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609601.2192024384],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNorthCarolinaFIPS3200FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_North_Carolina_FIPS_3200_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.0],PARAMETER["Standard_Parallel_1",34.33333333333334],PARAMETER["Standard_Parallel_2",36.16666666666666],PARAMETER["Latitude_Of_Origin",33.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneNorthDakotaNorthFIPS3301",
    `PROJCS["NAD_1983_CORS96_StatePlane_North_Dakota_North_FIPS_3301",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNorthDakotaSouthFIPS3302",
    `PROJCS["NAD_1983_CORS96_StatePlane_North_Dakota_South_FIPS_3302",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneNorthDakotaNorthFIPS3301FtI",
    `PROJCS["NAD_1983_CORS96_StatePlane_North_Dakota_North_FIPS_3301_FtI",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",47.43333333333333],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneNorthDakotaSouthFIPS3302FtI",
    `PROJCS["NAD_1983_CORS96_StatePlane_North_Dakota_South_FIPS_3302_FtI",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968503.937007874],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.5],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",47.48333333333333],PARAMETER["Latitude_Of_Origin",45.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneOhioNorthFIPS3401",
    `PROJCS["NAD_1983_CORS96_StatePlane_Ohio_North_FIPS_3401",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneOhioSouthFIPS3402",
    `PROJCS["NAD_1983_CORS96_StatePlane_Ohio_South_FIPS_3402",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneOhioNorthFIPS3401FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Ohio_North_FIPS_3401_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",40.43333333333333],PARAMETER["Standard_Parallel_2",41.7],PARAMETER["Latitude_Of_Origin",39.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneOhioSouthFIPS3402FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Ohio_South_FIPS_3402_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-82.5],PARAMETER["Standard_Parallel_1",38.73333333333333],PARAMETER["Standard_Parallel_2",40.03333333333333],PARAMETER["Latitude_Of_Origin",38.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneOklahomaNorthFIPS3501",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oklahoma_North_FIPS_3501",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneOklahomaSouthFIPS3502",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oklahoma_South_FIPS_3502",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneOklahomaNorthFIPS3501FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oklahoma_North_FIPS_3501_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",35.56666666666667],PARAMETER["Standard_Parallel_2",36.76666666666667],PARAMETER["Latitude_Of_Origin",35.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneOklahomaSouthFIPS3502FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Oklahoma_South_FIPS_3502_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-98.0],PARAMETER["Standard_Parallel_1",33.93333333333333],PARAMETER["Standard_Parallel_2",35.23333333333333],PARAMETER["Latitude_Of_Origin",33.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlanePennsylvaniaNorthFIPS3701",
    `PROJCS["NAD_1983_CORS96_StatePlane_Pennsylvania_North_FIPS_3701",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlanePennsylvaniaNorthFIPS3701FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Pennsylvania_North_FIPS_3701_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",40.88333333333333],PARAMETER["Standard_Parallel_2",41.95],PARAMETER["Latitude_Of_Origin",40.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlanePennsylvaniaSouthFIPS3702",
    `PROJCS["NAD_1983_CORS96_StatePlane_Pennsylvania_South_FIPS_3702",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlanePennsylvaniaSouthFIPS3702FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Pennsylvania_South_FIPS_3702_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-77.75],PARAMETER["Standard_Parallel_1",39.93333333333333],PARAMETER["Standard_Parallel_2",40.96666666666667],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneRhodeIslandFIPS3800",
    `PROJCS["NAD_1983_CORS96_StatePlane_Rhode_Island_FIPS_3800",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",100000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneRhodeIslandFIPS3800FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Rhode_Island_FIPS_3800_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",328083.3333333333],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-71.5],PARAMETER["Scale_Factor",0.99999375],PARAMETER["Latitude_Of_Origin",41.08333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneSouthCarolinaFIPS3900",
    `PROJCS["NAD_1983_CORS96_StatePlane_South_Carolina_FIPS_3900",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",609600.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneSouthCarolinaFIPS3900FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_South_Carolina_FIPS_3900_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2000000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",32.5],PARAMETER["Standard_Parallel_2",34.83333333333334],PARAMETER["Latitude_Of_Origin",31.83333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneSouthDakotaNorthFIPS4001",
    `PROJCS["NAD_1983_CORS96_StatePlane_South_Dakota_North_FIPS_4001",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneSouthDakotaSouthFIPS4002",
    `PROJCS["NAD_1983_CORS96_StatePlane_South_Dakota_South_FIPS_4002",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneSouthDakotaNorthFIPS4001FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_South_Dakota_North_FIPS_4001_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.0],PARAMETER["Standard_Parallel_1",44.41666666666666],PARAMETER["Standard_Parallel_2",45.68333333333333],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneSouthDakotaSouthFIPS4002FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_South_Dakota_South_FIPS_4002_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",42.83333333333334],PARAMETER["Standard_Parallel_2",44.4],PARAMETER["Latitude_Of_Origin",42.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneTennesseeFIPS4100",
    `PROJCS["NAD_1983_CORS96_StatePlane_Tennessee_FIPS_4100",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneTennesseeFIPS4100FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Tennessee_FIPS_4100_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-86.0],PARAMETER["Standard_Parallel_1",35.25],PARAMETER["Standard_Parallel_2",36.41666666666666],PARAMETER["Latitude_Of_Origin",34.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "ETRF1989UTMZone28N",
    `PROJCS["ETRF_1989_UTM_Zone_28N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF1989UTMZone29N",
    `PROJCS["ETRF_1989_UTM_Zone_29N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF1989UTMZone30N",
    `PROJCS["ETRF_1989_UTM_Zone_30N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF1989UTMZone31N",
    `PROJCS["ETRF_1989_UTM_Zone_31N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF1989UTMZone32N",
    `PROJCS["ETRF_1989_UTM_Zone_32N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF1989UTMZone33N",
    `PROJCS["ETRF_1989_UTM_Zone_33N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF1989UTMZone34N",
    `PROJCS["ETRF_1989_UTM_Zone_34N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",21.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF1989UTMZone35N",
    `PROJCS["ETRF_1989_UTM_Zone_35N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",27.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF1989UTMZone36N",
    `PROJCS["ETRF_1989_UTM_Zone_36N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF1989UTMZone37N",
    `PROJCS["ETRF_1989_UTM_Zone_37N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",39.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ETRF1989UTMZone38N",
    `PROJCS["ETRF_1989_UTM_Zone_38N",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",45.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneTexasNorthFIPS4201",
    `PROJCS["NAD_1983_CORS96_StatePlane_Texas_North_FIPS_4201",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneTexasNorthCentralFIPS4202",
    `PROJCS["NAD_1983_CORS96_StatePlane_Texas_North_Central_FIPS_4202",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneTexasCentralFIPS4203",
    `PROJCS["NAD_1983_CORS96_StatePlane_Texas_Central_FIPS_4203",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",700000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneTexasSouthCentralFIPS4204",
    `PROJCS["NAD_1983_CORS96_StatePlane_Texas_South_Central_FIPS_4204",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",4000000.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneTexasSouthFIPS4205",
    `PROJCS["NAD_1983_CORS96_StatePlane_Texas_South_FIPS_4205",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",5000000.0],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneTexasNorthFIPS4201FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Texas_North_FIPS_4201_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-101.5],PARAMETER["Standard_Parallel_1",34.65],PARAMETER["Standard_Parallel_2",36.18333333333333],PARAMETER["Latitude_Of_Origin",34.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneTexasNorthCentralFIPS4202FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Texas_North_Central_FIPS_4202_FtUS",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",32.13333333333333],PARAMETER["Standard_Parallel_2",33.96666666666667],PARAMETER["Latitude_Of_Origin",31.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneTexasCentralFIPS4203FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Texas_Central_FIPS_4203_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",2296583.333333333],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-100.3333333333333],PARAMETER["Standard_Parallel_1",30.11666666666667],PARAMETER["Standard_Parallel_2",31.88333333333333],PARAMETER["Latitude_Of_Origin",29.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneTexasSouthCentralFIPS4204FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Texas_South_Central_FIPS_4204_FtUS",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",13123333.33333333],PARAMETER["Central_Meridian",-99.0],PARAMETER["Standard_Parallel_1",28.38333333333333],PARAMETER["Standard_Parallel_2",30.28333333333333],PARAMETER["Latitude_Of_Origin",27.83333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneTexasSouthFIPS4205FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Texas_South_FIPS_4205_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",16404166.66666666],PARAMETER["Central_Meridian",-98.5],PARAMETER["Standard_Parallel_1",26.16666666666667],PARAMETER["Standard_Parallel_2",27.83333333333333],PARAMETER["Latitude_Of_Origin",25.66666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneUtahNorthFIPS4301",
    `PROJCS["NAD_1983_CORS96_StatePlane_Utah_North_FIPS_4301",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneUtahCentralFIPS4302",
    `PROJCS["NAD_1983_CORS96_StatePlane_Utah_Central_FIPS_4302",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneUtahSouthFIPS4303",
    `PROJCS["NAD_1983_CORS96_StatePlane_Utah_South_FIPS_4303",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",3000000.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneUtahNorthFIPS4301FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Utah_North_FIPS_4301_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",3280839.895013123],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneUtahCentralFIPS4302FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Utah_Central_FIPS_4302_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",6561679.790026246],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneUtahSouthFIPS4303FtIntl",
    `PROJCS["NAD_1983_CORS96_StatePlane_Utah_South_FIPS_4303_Ft_Intl",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640419.947506561],PARAMETER["False_Northing",9842519.685039369],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983CORS96StatePlaneUtahNorthFIPS4301FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Utah_North_FIPS_4301_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",40.71666666666667],PARAMETER["Standard_Parallel_2",41.78333333333333],PARAMETER["Latitude_Of_Origin",40.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneUtahCentralFIPS4302FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Utah_Central_FIPS_4302_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",39.01666666666667],PARAMETER["Standard_Parallel_2",40.65],PARAMETER["Latitude_Of_Origin",38.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneUtahSouthFIPS4303FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Utah_South_FIPS_4303_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",9842500.0],PARAMETER["Central_Meridian",-111.5],PARAMETER["Standard_Parallel_1",37.21666666666667],PARAMETER["Standard_Parallel_2",38.35],PARAMETER["Latitude_Of_Origin",36.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneVermontFIPS4400",
    `PROJCS["NAD_1983_CORS96_StatePlane_Vermont_FIPS_4400",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-72.5],PARAMETER["Scale_Factor",0.9999642857142857],PARAMETER["Latitude_Of_Origin",42.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneVirginiaNorthFIPS4501",
    `PROJCS["NAD_1983_CORS96_StatePlane_Virginia_North_FIPS_4501",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneVirginiaSouthFIPS4502",
    `PROJCS["NAD_1983_CORS96_StatePlane_Virginia_South_FIPS_4502",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneVirginiaNorthFIPS4501FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Virginia_North_FIPS_4501_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",6561666.666666666],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",38.03333333333333],PARAMETER["Standard_Parallel_2",39.2],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneVirginiaSouthFIPS4502FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Virginia_South_FIPS_4502_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",11482916.66666666],PARAMETER["False_Northing",3280833.333333333],PARAMETER["Central_Meridian",-78.5],PARAMETER["Standard_Parallel_1",36.76666666666667],PARAMETER["Standard_Parallel_2",37.96666666666667],PARAMETER["Latitude_Of_Origin",36.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneWashingtonNorthFIPS4601",
    `PROJCS["NAD_1983_CORS96_StatePlane_Washington_North_FIPS_4601",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWashingtonSouthFIPS4602",
    `PROJCS["NAD_1983_CORS96_StatePlane_Washington_South_FIPS_4602",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWashingtonNorthFIPS4601FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Washington_North_FIPS_4601_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.8333333333333],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",48.73333333333333],PARAMETER["Latitude_Of_Origin",47.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneWashingtonSouthFIPS4602FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Washington_South_FIPS_4602_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1640416.666666667],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",45.83333333333334],PARAMETER["Standard_Parallel_2",47.33333333333334],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneWestVirginiaNorthFIPS4701",
    `PROJCS["NAD_1983_CORS96_StatePlane_West_Virginia_North_FIPS_4701",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWestVirginiaSouthFIPS4702",
    `PROJCS["NAD_1983_CORS96_StatePlane_West_Virginia_South_FIPS_4702",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWestVirginiaNorthFIPS4701FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_West_Virginia_North_FIPS_4701_FtUS",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-79.5],PARAMETER["Standard_Parallel_1",39.0],PARAMETER["Standard_Parallel_2",40.25],PARAMETER["Latitude_Of_Origin",38.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneWestVirginiaSouthFIPS4702FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_West_Virginia_South_FIPS_4702_FtUS",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Standard_Parallel_1",37.48333333333333],PARAMETER["Standard_Parallel_2",38.88333333333333],PARAMETER["Latitude_Of_Origin",37.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneWisconsinNorthFIPS4801",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wisconsin_North_FIPS_4801",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWisconsinCentralFIPS4802",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wisconsin_Central_FIPS_4802",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWisconsinSouthFIPS4803",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wisconsin_South_FIPS_4803",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWisconsinNorthFIPS4801FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wisconsin_North_FIPS_4801_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",46.76666666666667],PARAMETER["Latitude_Of_Origin",45.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneWisconsinCentralFIPS4802FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wisconsin_Central_FIPS_4802_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",45.5],PARAMETER["Latitude_Of_Origin",43.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneWisconsinSouthFIPS4803FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wisconsin_South_FIPS_4803_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",42.73333333333333],PARAMETER["Standard_Parallel_2",44.06666666666667],PARAMETER["Latitude_Of_Origin",42.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneWyomingEastFIPS4901",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wyoming_East_FIPS_4901",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",200000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWyomingEastCentralFIPS4902",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wyoming_East_Central_FIPS_4902",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWyomingWestCentralFIPS4903",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wyoming_West_Central_FIPS_4903",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWyomingWestFIPS4904",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wyoming_West_FIPS_4904",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",800000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWyomingEastFIPS4901FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wyoming_East_FIPS_4901_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656166.6666666665],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.1666666666667],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneWyomingECentralFIPS4902FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wyoming_E_Central_FIPS_4902_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1312333.333333333],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-107.3333333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983CORS96StatePlaneWyomingWCentralFIPS4903FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wyoming_W_Central_FIPS_4903_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1968500.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-108.75],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "ETRF1989TMBaltic1993",
    `PROJCS["ETRF_1989_TM_Baltic_1993",GEOGCS["GCS_ETRF_1989",DATUM["D_ETRF_1989",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",24.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983CORS96StatePlaneWyomingWestFIPS4904FtUS",
    `PROJCS["NAD_1983_CORS96_StatePlane_Wyoming_West_FIPS_4904_Ft_US",GEOGCS["GCS_NAD_1983_CORS96",DATUM["D_NAD_1983_CORS96",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2624666.666666666],PARAMETER["False_Northing",328083.3333333333],PARAMETER["Central_Meridian",-110.0833333333333],PARAMETER["Scale_Factor",0.9999375],PARAMETER["Latitude_Of_Origin",40.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNNavajoNationCoordinateSystemMeters",
    `PROJCS["NAD_1983_HARN_Navajo_Nation_Coordinate_System_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",1.00023],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNNavajoNationCoordinateSystemUSFeet",
    `PROJCS["NAD_1983_HARN_Navajo_Nation_Coordinate_System_US_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",1968500.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",1.00023],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNNavajoNationCoordinateSystemIntlFeet",
    `PROJCS["NAD_1983_HARN_Navajo_Nation_Coordinate_System_Intl_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984251.968503937],PARAMETER["False_Northing",1968503.937007874],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",1.00023],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983NSRS2007NavajoNationCoordinateSystemMeters",
    `PROJCS["NAD_1983_NSRS2007_Navajo_Nation_Coordinate_System_Meters",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",1.00023],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983NSRS2007NavajoNationCoordinateSystemUSFeet",
    `PROJCS["NAD_1983_NSRS2007_Navajo_Nation_Coordinate_System_US_Feet",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",1968500.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",1.00023],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983NSRS2007NavajoNationCoordinateSystemIntlFeet",
    `PROJCS["NAD_1983_NSRS2007_Navajo_Nation_Coordinate_System_Intl_Feet",GEOGCS["GCS_NAD_1983_NSRS2007",DATUM["D_NAD_1983_NSRS2007",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984251.968503937],PARAMETER["False_Northing",1968503.937007874],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",1.00023],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983(2011)NavajoNationCoordinateSystemMeters",
    `PROJCS["NAD_1983_(2011)_Navajo_Nation_Coordinate_System_Meters",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",300000.0],PARAMETER["False_Northing",600000.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",1.00023],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(2011)NavajoNationCoordinateSystemUSFeet",
    `PROJCS["NAD_1983_(2011)_Navajo_Nation_Coordinate_System_US_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984250.0],PARAMETER["False_Northing",1968500.0],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",1.00023],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(2011)NavajoNationCoordinateSystemIntlFeet",
    `PROJCS["NAD_1983_(2011)_Navajo_Nation_Coordinate_System_Intl_Feet",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",984251.968503937],PARAMETER["False_Northing",1968503.937007874],PARAMETER["Central_Meridian",-109.5],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Scale_Factor",1.00023],PARAMETER["Latitude_Of_Origin",36.0],UNIT["Foot",0.3048]]`
  ],
  [
    "ONGD17UTMZone39N",
    `PROJCS["ONGD17_UTM_Zone_39N",GEOGCS["ONGD17",DATUM["Oman_National_Geodetic_Datum_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",51.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ONGD17UTMZone40N",
    `PROJCS["ONGD17_UTM_Zone_40N",GEOGCS["ONGD17",DATUM["Oman_National_Geodetic_Datum_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "ONGD17UTMZone41N",
    `PROJCS["ONGD17_UTM_Zone_41N",GEOGCS["ONGD17",DATUM["Oman_National_Geodetic_Datum_2017",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GTM",
    `PROJCS["GTM",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.5],PARAMETER["Scale_Factor",0.9998],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MAGNA-SIRGASCMT12",
    `PROJCS["MAGNA-SIRGAS_CMT12",GEOGCS["GCS_MAGNA",DATUM["D_MAGNA",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5000000.0],PARAMETER["False_Northing",2000000.0],PARAMETER["Central_Meridian",-73.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",4.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNAitkinMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Aitkin_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",152409.319685395],PARAMETER["False_Northing",30481.86393707899],PARAMETER["Central_Meridian",-93.4325],PARAMETER["Scale_Factor",1.000059152669],PARAMETER["Latitude_Of_Origin",46.15416666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNClayMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Clay_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",152407.2112565913],PARAMETER["False_Northing",30481.44225131827],PARAMETER["Central_Meridian",-96.7],PARAMETER["Scale_Factor",1.000045317862],PARAMETER["Latitude_Of_Origin",46.63],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNClearwaterMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Clearwater_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",152411.3546854458],PARAMETER["False_Northing",30482.27093708916],PARAMETER["Central_Meridian",-95.37583333333333],PARAMETER["Scale_Factor",1.000072505661],PARAMETER["Latitude_Of_Origin",47.15166666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNHubbardMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Hubbard_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",152411.2096003556],PARAMETER["False_Northing",30482.24192007113],PARAMETER["Central_Meridian",-94.92055555555557],PARAMETER["Scale_Factor",1.000071553661],PARAMETER["Latitude_Of_Origin",46.80361111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNLakeMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lake_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",152411.8635439675],PARAMETER["False_Northing",30482.3727087935],PARAMETER["Central_Meridian",-91.40916666666668],PARAMETER["Scale_Factor",1.000075844621],PARAMETER["Latitude_Of_Origin",47.06666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNMilleLacsMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Mille_Lacs_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",152408.5566885446],PARAMETER["False_Northing",30481.71133770892],PARAMETER["Central_Meridian",-93.62055555555555],PARAMETER["Scale_Factor",1.000054146138],PARAMETER["Latitude_Of_Origin",45.55888888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNWashingtonMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Washington_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",152406.3759409195],PARAMETER["False_Northing",30481.2751881839],PARAMETER["Central_Meridian",-92.83333333333333],PARAMETER["Scale_Factor",1.000039836799],PARAMETER["Latitude_Of_Origin",44.74583333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNWilkinMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Wilkin_Meters",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",152407.7573379731],PARAMETER["False_Northing",30481.55146759462],PARAMETER["Central_Meridian",-96.52444444444444],PARAMETER["Scale_Factor",1.000048901066],PARAMETER["Latitude_Of_Origin",46.02166666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNAnokaMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Anoka_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Anoka",DATUM["D_NAD_1983_HARN_Adj_MN_Anoka",SPHEROID["S_GRS_1980_Adj_MN_Anoka",6378418.941,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.26666666666667],PARAMETER["Standard_Parallel_1",45.06666666666667],PARAMETER["Standard_Parallel_2",45.36666666666667],PARAMETER["Latitude_Of_Origin",45.03527777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNBeckerMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Becker_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Becker",DATUM["D_NAD_1983_HARN_Adj_MN_Becker",SPHEROID["S_GRS_1980_Adj_MN_Becker",6378586.581,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.68333333333334],PARAMETER["Standard_Parallel_1",46.78333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",46.71777777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNBeltramiNorthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Beltrami_North_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Beltrami_North",DATUM["D_NAD_1983_HARN_Adj_MN_Beltrami_North",SPHEROID["S_GRS_1980_Adj_MN_Beltrami_North",6378505.809,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.01666666666667],PARAMETER["Standard_Parallel_1",48.11666666666667],PARAMETER["Standard_Parallel_2",48.46666666666667],PARAMETER["Latitude_Of_Origin",48.02],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNBeltramiSouthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Beltrami_South_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Beltrami_South",DATUM["D_NAD_1983_HARN_Adj_MN_Beltrami_South",SPHEROID["S_GRS_1980_Adj_MN_Beltrami_South",6378544.823,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.85],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",47.91666666666666],PARAMETER["Latitude_Of_Origin",47.4125],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNBentonMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Benton_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Benton",DATUM["D_NAD_1983_HARN_Adj_MN_Benton",SPHEROID["S_GRS_1980_Adj_MN_Benton",6378490.569,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.05],PARAMETER["Standard_Parallel_1",45.58333333333334],PARAMETER["Standard_Parallel_2",45.78333333333333],PARAMETER["Latitude_Of_Origin",45.55916666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNBigStoneMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Big_Stone_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Big_Stone",DATUM["D_NAD_1983_HARN_Adj_MN_Big_Stone",SPHEROID["S_GRS_1980_Adj_MN_Big_Stone",6378470.757,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.05],PARAMETER["Standard_Parallel_1",45.21666666666667],PARAMETER["Standard_Parallel_2",45.53333333333333],PARAMETER["Latitude_Of_Origin",45.15222222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNBlueEarthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Blue_Earth_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Blue_Earth",DATUM["D_NAD_1983_HARN_Adj_MN_Blue_Earth",SPHEROID["S_GRS_1980_Adj_MN_Blue_Earth",6378403.701,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.26666666666667],PARAMETER["Standard_Parallel_1",43.93333333333333],PARAMETER["Standard_Parallel_2",44.36666666666667],PARAMETER["Latitude_Of_Origin",43.84805555555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNBrownMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Brown_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Brown",DATUM["D_NAD_1983_HARN_Adj_MN_Brown",SPHEROID["S_GRS_1980_Adj_MN_Brown",6378434.181,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.73333333333333],PARAMETER["Standard_Parallel_1",44.16666666666666],PARAMETER["Standard_Parallel_2",44.46666666666667],PARAMETER["Latitude_Of_Origin",44.10805555555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNCarltonMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Carlton_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Carlton",DATUM["D_NAD_1983_HARN_Adj_MN_Carlton",SPHEROID["S_GRS_1980_Adj_MN_Carlton",6378454.907,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.68333333333334],PARAMETER["Standard_Parallel_1",46.46666666666667],PARAMETER["Standard_Parallel_2",46.73333333333333],PARAMETER["Latitude_Of_Origin",46.41722222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNCarverMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Carver_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Carver",DATUM["D_NAD_1983_HARN_Adj_MN_Carver",SPHEROID["S_GRS_1980_Adj_MN_Carver",6378400.653,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.76666666666667],PARAMETER["Standard_Parallel_1",44.68333333333333],PARAMETER["Standard_Parallel_2",44.9],PARAMETER["Latitude_Of_Origin",44.63972222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNCassNorthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Cass_North_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Cass_North",DATUM["D_NAD_1983_HARN_Adj_MN_Cass_North",SPHEROID["S_GRS_1980_Adj_MN_Cass_North",6378567.378,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.21666666666667],PARAMETER["Standard_Parallel_1",46.91666666666666],PARAMETER["Standard_Parallel_2",47.31666666666667],PARAMETER["Latitude_Of_Origin",46.80361111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNCassSouthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Cass_South_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Cass_South",DATUM["D_NAD_1983_HARN_Adj_MN_Cass_South",SPHEROID["S_GRS_1980_Adj_MN_Cass_South",6378546.957,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.46666666666667],PARAMETER["Standard_Parallel_1",46.26666666666667],PARAMETER["Standard_Parallel_2",46.73333333333333],PARAMETER["Latitude_Of_Origin",46.15638888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNChippewaMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Chippewa_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Chippewa",DATUM["D_NAD_1983_HARN_Adj_MN_Chippewa",SPHEROID["S_GRS_1980_Adj_MN_Chippewa",6378476.853,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.85],PARAMETER["Standard_Parallel_1",44.83333333333334],PARAMETER["Standard_Parallel_2",45.2],PARAMETER["Latitude_Of_Origin",44.75277777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNChisagoMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Chisago_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Chisago",DATUM["D_NAD_1983_HARN_Adj_MN_Chisago",SPHEROID["S_GRS_1980_Adj_MN_Chisago",6378411.321,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.08333333333333],PARAMETER["Standard_Parallel_1",45.33333333333334],PARAMETER["Standard_Parallel_2",45.66666666666666],PARAMETER["Latitude_Of_Origin",45.29638888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNCookNorthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Cook_North_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Cook_North",DATUM["D_NAD_1983_HARN_Adj_MN_Cook_North",SPHEROID["S_GRS_1980_Adj_MN_Cook_North",6378647.541,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-90.25],PARAMETER["Standard_Parallel_1",47.93333333333333],PARAMETER["Standard_Parallel_2",48.16666666666666],PARAMETER["Latitude_Of_Origin",47.88333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNCookSouthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Cook_South_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Cook_South",DATUM["D_NAD_1983_HARN_Adj_MN_Cook_South",SPHEROID["S_GRS_1980_Adj_MN_Cook_South",6378647.541,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-90.25],PARAMETER["Standard_Parallel_1",47.55],PARAMETER["Standard_Parallel_2",47.81666666666667],PARAMETER["Latitude_Of_Origin",47.43888888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNCottonwoodMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Cottonwood_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Cottonwood",DATUM["D_NAD_1983_HARN_Adj_MN_Cottonwood",SPHEROID["S_GRS_1980_Adj_MN_Cottonwood",6378514.953,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.91666666666667],PARAMETER["Standard_Parallel_1",43.9],PARAMETER["Standard_Parallel_2",44.16666666666666],PARAMETER["Latitude_Of_Origin",43.84805555555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNCrowWingMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Crow_Wing_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Crow_Wing",DATUM["D_NAD_1983_HARN_Adj_MN_Crow_Wing",SPHEROID["S_GRS_1980_Adj_MN_Crow_Wing",6378546.957,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.46666666666667],PARAMETER["Standard_Parallel_1",46.26666666666667],PARAMETER["Standard_Parallel_2",46.73333333333333],PARAMETER["Latitude_Of_Origin",46.15638888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNDakotaMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Dakota_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Dakota",DATUM["D_NAD_1983_HARN_Adj_MN_Dakota",SPHEROID["S_GRS_1980_Adj_MN_Dakota",6378421.989,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.31666666666666],PARAMETER["Standard_Parallel_1",44.51666666666667],PARAMETER["Standard_Parallel_2",44.91666666666666],PARAMETER["Latitude_Of_Origin",44.47194444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNDodgeMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Dodge_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Dodge",DATUM["D_NAD_1983_HARN_Adj_MN_Dodge",SPHEROID["S_GRS_1980_Adj_MN_Dodge",6378481.425,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.91666666666667],PARAMETER["Standard_Parallel_1",43.88333333333333],PARAMETER["Standard_Parallel_2",44.13333333333333],PARAMETER["Latitude_Of_Origin",43.83388888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNDouglasMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Douglas_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Douglas",DATUM["D_NAD_1983_HARN_Adj_MN_Douglas",SPHEROID["S_GRS_1980_Adj_MN_Douglas",6378518.001,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.05],PARAMETER["Standard_Parallel_1",45.8],PARAMETER["Standard_Parallel_2",46.05],PARAMETER["Latitude_Of_Origin",45.75888888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNFaribaultMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Faribault_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Faribault",DATUM["D_NAD_1983_HARN_Adj_MN_Faribault",SPHEROID["S_GRS_1980_Adj_MN_Faribault",6378521.049,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNFillmoreMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Fillmore_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Fillmore",DATUM["D_NAD_1983_HARN_Adj_MN_Fillmore",SPHEROID["S_GRS_1980_Adj_MN_Fillmore",6378464.661,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.08333333333333],PARAMETER["Standard_Parallel_1",43.55],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNFreebornMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Freeborn_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Freeborn",DATUM["D_NAD_1983_HARN_Adj_MN_Freeborn",SPHEROID["S_GRS_1980_Adj_MN_Freeborn",6378521.049,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNGoodhueMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Goodhue_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Goodhue",DATUM["D_NAD_1983_HARN_Adj_MN_Goodhue",SPHEROID["S_GRS_1980_Adj_MN_Goodhue",6378434.181,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.13333333333334],PARAMETER["Standard_Parallel_1",44.3],PARAMETER["Standard_Parallel_2",44.66666666666666],PARAMETER["Latitude_Of_Origin",44.19472222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNGrantMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Grant_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Grant",DATUM["D_NAD_1983_HARN_Adj_MN_Grant",SPHEROID["S_GRS_1980_Adj_MN_Grant",6378518.001,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.05],PARAMETER["Standard_Parallel_1",45.8],PARAMETER["Standard_Parallel_2",46.05],PARAMETER["Latitude_Of_Origin",45.75888888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNHennepinMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Hennepin_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Hennepin",DATUM["D_NAD_1983_HARN_Adj_MN_Hennepin",SPHEROID["S_GRS_1980_Adj_MN_Hennepin",6378418.941,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.38333333333334],PARAMETER["Standard_Parallel_1",44.88333333333333],PARAMETER["Standard_Parallel_2",45.13333333333333],PARAMETER["Latitude_Of_Origin",44.79111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNHoustonMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Houston_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Houston",DATUM["D_NAD_1983_HARN_Adj_MN_Houston",SPHEROID["S_GRS_1980_Adj_MN_Houston",6378436.619,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-91.46666666666667],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNIsantiMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Isanti_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Isanti",DATUM["D_NAD_1983_HARN_Adj_MN_Isanti",SPHEROID["S_GRS_1980_Adj_MN_Isanti",6378411.321,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.08333333333333],PARAMETER["Standard_Parallel_1",45.33333333333334],PARAMETER["Standard_Parallel_2",45.66666666666666],PARAMETER["Latitude_Of_Origin",45.29638888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNItascaNorthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Itasca_North_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Itasca_North",DATUM["D_NAD_1983_HARN_Adj_MN_Itasca_North",SPHEROID["S_GRS_1980_Adj_MN_Itasca_North",6378574.389,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.73333333333333],PARAMETER["Standard_Parallel_1",47.56666666666667],PARAMETER["Standard_Parallel_2",47.81666666666667],PARAMETER["Latitude_Of_Origin",47.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNItascaSouthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Itasca_South_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Itasca_South",DATUM["D_NAD_1983_HARN_Adj_MN_Itasca_South",SPHEROID["S_GRS_1980_Adj_MN_Itasca_South",6378574.389,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.73333333333333],PARAMETER["Standard_Parallel_1",47.08333333333334],PARAMETER["Standard_Parallel_2",47.41666666666666],PARAMETER["Latitude_Of_Origin",47.02638888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNJacksonMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Jackson_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Jackson",DATUM["D_NAD_1983_HARN_Adj_MN_Jackson",SPHEROID["S_GRS_1980_Adj_MN_Jackson",6378521.049,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNKanabecMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Kanabec_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Kanabec",DATUM["D_NAD_1983_HARN_Adj_MN_Kanabec",SPHEROID["S_GRS_1980_Adj_MN_Kanabec",6378472.281,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.9],PARAMETER["Standard_Parallel_1",45.81666666666667],PARAMETER["Standard_Parallel_2",46.33333333333334],PARAMETER["Latitude_Of_Origin",45.73],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNKandiyohiMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Kandiyohi_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Kandiyohi",DATUM["D_NAD_1983_HARN_Adj_MN_Kandiyohi",SPHEROID["S_GRS_1980_Adj_MN_Kandiyohi",6378498.189,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.75],PARAMETER["Standard_Parallel_1",44.96666666666667],PARAMETER["Standard_Parallel_2",45.33333333333334],PARAMETER["Latitude_Of_Origin",44.89138888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNKittsonMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Kittson_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Kittson",DATUM["D_NAD_1983_HARN_Adj_MN_Kittson",SPHEROID["S_GRS_1980_Adj_MN_Kittson",6378449.421,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.15],PARAMETER["Standard_Parallel_1",48.6],PARAMETER["Standard_Parallel_2",48.93333333333333],PARAMETER["Latitude_Of_Origin",48.54388888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNKoochichingMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Koochiching_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Koochiching",DATUM["D_NAD_1983_HARN_Adj_MN_Koochiching",SPHEROID["S_GRS_1980_Adj_MN_Koochiching",6378525.621,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.75],PARAMETER["Standard_Parallel_1",48.0],PARAMETER["Standard_Parallel_2",48.61666666666667],PARAMETER["Latitude_Of_Origin",47.84583333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNLacQuiParleMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lac_Qui_Parle_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Lac_Qui_Parle",DATUM["D_NAD_1983_HARN_Adj_MN_Lac_Qui_Parle",SPHEROID["S_GRS_1980_Adj_MN_Lac_Qui_Parle",6378476.853,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.85],PARAMETER["Standard_Parallel_1",44.83333333333334],PARAMETER["Standard_Parallel_2",45.2],PARAMETER["Latitude_Of_Origin",44.75277777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNLakeoftheWoodsNorthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_North_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_North",DATUM["D_NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_North",SPHEROID["S_GRS_1980_Adj_MN_Lake_of_the_Woods_North",6378466.185,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.98333333333333],PARAMETER["Standard_Parallel_1",49.18333333333333],PARAMETER["Standard_Parallel_2",49.33333333333334],PARAMETER["Latitude_Of_Origin",49.15],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNLakeoftheWoodsSouthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_South_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_South",DATUM["D_NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_South",SPHEROID["S_GRS_1980_Adj_MN_Lake_of_the_Woods_South",6378496.665,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.88333333333334],PARAMETER["Standard_Parallel_1",48.45],PARAMETER["Standard_Parallel_2",48.88333333333333],PARAMETER["Latitude_Of_Origin",48.36611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNLeSueurMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Le_Sueur_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Le_Sueur",DATUM["D_NAD_1983_HARN_Adj_MN_Le_Sueur",SPHEROID["S_GRS_1980_Adj_MN_Le_Sueur",6378434.181,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.13333333333334],PARAMETER["Standard_Parallel_1",44.3],PARAMETER["Standard_Parallel_2",44.66666666666666],PARAMETER["Latitude_Of_Origin",44.19472222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNLincolnMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lincoln_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Lincoln",DATUM["D_NAD_1983_HARN_Adj_MN_Lincoln",SPHEROID["S_GRS_1980_Adj_MN_Lincoln",6378643.579,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.26666666666667],PARAMETER["Standard_Parallel_1",44.28333333333333],PARAMETER["Standard_Parallel_2",44.61666666666667],PARAMETER["Latitude_Of_Origin",44.19666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNLyonMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lyon_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Lyon",DATUM["D_NAD_1983_HARN_Adj_MN_Lyon",SPHEROID["S_GRS_1980_Adj_MN_Lyon",6378559.758,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.85],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",44.58333333333334],PARAMETER["Latitude_Of_Origin",44.19555555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNMcLeodMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_McLeod_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_McLeod",DATUM["D_NAD_1983_HARN_Adj_MN_McLeod",SPHEROID["S_GRS_1980_Adj_MN_McLeod",6378414.369,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.63333333333334],PARAMETER["Standard_Parallel_1",44.53333333333333],PARAMETER["Standard_Parallel_2",44.91666666666666],PARAMETER["Latitude_Of_Origin",44.45611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNMahnomenMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Mahnomen_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Mahnomen",DATUM["D_NAD_1983_HARN_Adj_MN_Mahnomen",SPHEROID["S_GRS_1980_Adj_MN_Mahnomen",6378586.581,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.81666666666666],PARAMETER["Standard_Parallel_1",47.2],PARAMETER["Standard_Parallel_2",47.45],PARAMETER["Latitude_Of_Origin",47.15166666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNMarshallMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Marshall_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Marshall",DATUM["D_NAD_1983_HARN_Adj_MN_Marshall",SPHEROID["S_GRS_1980_Adj_MN_Marshall",6378441.801,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.38333333333334],PARAMETER["Standard_Parallel_1",48.23333333333333],PARAMETER["Standard_Parallel_2",48.48333333333333],PARAMETER["Latitude_Of_Origin",48.17305555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNMartinMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Martin_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Martin",DATUM["D_NAD_1983_HARN_Adj_MN_Martin",SPHEROID["S_GRS_1980_Adj_MN_Martin",6378521.049,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNMeekerMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Meeker_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Meeker",DATUM["D_NAD_1983_HARN_Adj_MN_Meeker",SPHEROID["S_GRS_1980_Adj_MN_Meeker",6378498.189,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.75],PARAMETER["Standard_Parallel_1",44.96666666666667],PARAMETER["Standard_Parallel_2",45.33333333333334],PARAMETER["Latitude_Of_Origin",44.89138888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNMorrisonMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Morrison_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Morrison",DATUM["D_NAD_1983_HARN_Adj_MN_Morrison",SPHEROID["S_GRS_1980_Adj_MN_Morrison",6378502.761,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.2],PARAMETER["Standard_Parallel_1",45.85],PARAMETER["Standard_Parallel_2",46.26666666666667],PARAMETER["Latitude_Of_Origin",45.77388888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNMowerMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Mower_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Mower",DATUM["D_NAD_1983_HARN_Adj_MN_Mower",SPHEROID["S_GRS_1980_Adj_MN_Mower",6378521.049,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNMurrayMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Murray_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Murray",DATUM["D_NAD_1983_HARN_Adj_MN_Murray",SPHEROID["S_GRS_1980_Adj_MN_Murray",6378617.061,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.76666666666667],PARAMETER["Standard_Parallel_1",43.91666666666666],PARAMETER["Standard_Parallel_2",44.16666666666666],PARAMETER["Latitude_Of_Origin",43.84805555555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNNicolletMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Nicollet_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Nicollet",DATUM["D_NAD_1983_HARN_Adj_MN_Nicollet",SPHEROID["S_GRS_1980_Adj_MN_Nicollet",6378403.701,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.26666666666667],PARAMETER["Standard_Parallel_1",43.93333333333333],PARAMETER["Standard_Parallel_2",44.36666666666667],PARAMETER["Latitude_Of_Origin",43.84805555555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNNoblesMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Nobles_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Nobles",DATUM["D_NAD_1983_HARN_Adj_MN_Nobles",SPHEROID["S_GRS_1980_Adj_MN_Nobles",6378624.681,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNNormanMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Norman_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Norman",DATUM["D_NAD_1983_HARN_Adj_MN_Norman",SPHEROID["S_GRS_1980_Adj_MN_Norman",6378468.623,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.45],PARAMETER["Standard_Parallel_1",47.2],PARAMETER["Standard_Parallel_2",47.45],PARAMETER["Latitude_Of_Origin",47.15055555555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNOlmstedMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Olmsted_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Olmsted",DATUM["D_NAD_1983_HARN_Adj_MN_Olmsted",SPHEROID["S_GRS_1980_Adj_MN_Olmsted",6378481.425,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.91666666666667],PARAMETER["Standard_Parallel_1",43.88333333333333],PARAMETER["Standard_Parallel_2",44.13333333333333],PARAMETER["Latitude_Of_Origin",43.83388888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNOttertailMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Ottertail_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Ottertail",DATUM["D_NAD_1983_HARN_Adj_MN_Ottertail",SPHEROID["S_GRS_1980_Adj_MN_Ottertail",6378525.621,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.71666666666667],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",46.65],PARAMETER["Latitude_Of_Origin",46.10638888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNPenningtonMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Pennington_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Pennington",DATUM["D_NAD_1983_HARN_Adj_MN_Pennington",SPHEROID["S_GRS_1980_Adj_MN_Pennington",6378445.763,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.36666666666666],PARAMETER["Standard_Parallel_1",47.6],PARAMETER["Standard_Parallel_2",48.08333333333334],PARAMETER["Latitude_Of_Origin",47.49888888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNPineMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Pine_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Pine",DATUM["D_NAD_1983_HARN_Adj_MN_Pine",SPHEROID["S_GRS_1980_Adj_MN_Pine",6378472.281,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.9],PARAMETER["Standard_Parallel_1",45.81666666666667],PARAMETER["Standard_Parallel_2",46.33333333333334],PARAMETER["Latitude_Of_Origin",45.73],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNPipestoneMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Pipestone_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Pipestone",DATUM["D_NAD_1983_HARN_Adj_MN_Pipestone",SPHEROID["S_GRS_1980_Adj_MN_Pipestone",6378670.401,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.25],PARAMETER["Standard_Parallel_1",43.88333333333333],PARAMETER["Standard_Parallel_2",44.15],PARAMETER["Latitude_Of_Origin",43.84916666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNPolkMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Polk_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Polk",DATUM["D_NAD_1983_HARN_Adj_MN_Polk",SPHEROID["S_GRS_1980_Adj_MN_Polk",6378445.763,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.36666666666666],PARAMETER["Standard_Parallel_1",47.6],PARAMETER["Standard_Parallel_2",48.08333333333334],PARAMETER["Latitude_Of_Origin",47.49888888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNPopeMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Pope_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Pope",DATUM["D_NAD_1983_HARN_Adj_MN_Pope",SPHEROID["S_GRS_1980_Adj_MN_Pope",6378502.761,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.15],PARAMETER["Standard_Parallel_1",45.35],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",45.28277777777777],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNRamseyMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Ramsey_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Ramsey",DATUM["D_NAD_1983_HARN_Adj_MN_Ramsey",SPHEROID["S_GRS_1980_Adj_MN_Ramsey",6378418.941,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.38333333333334],PARAMETER["Standard_Parallel_1",44.88333333333333],PARAMETER["Standard_Parallel_2",45.13333333333333],PARAMETER["Latitude_Of_Origin",44.79111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNRedLakeMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Red_Lake_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Red_Lake",DATUM["D_NAD_1983_HARN_Adj_MN_Red_Lake",SPHEROID["S_GRS_1980_Adj_MN_Red_Lake",6378445.763,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.36666666666666],PARAMETER["Standard_Parallel_1",47.6],PARAMETER["Standard_Parallel_2",48.08333333333334],PARAMETER["Latitude_Of_Origin",47.49888888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNRedwoodMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Redwood_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Redwood",DATUM["D_NAD_1983_HARN_Adj_MN_Redwood",SPHEROID["S_GRS_1980_Adj_MN_Redwood",6378438.753,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.23333333333333],PARAMETER["Standard_Parallel_1",44.26666666666667],PARAMETER["Standard_Parallel_2",44.56666666666667],PARAMETER["Latitude_Of_Origin",44.19472222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNRenvilleMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Renville_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Renville",DATUM["D_NAD_1983_HARN_Adj_MN_Renville",SPHEROID["S_GRS_1980_Adj_MN_Renville",6378414.369,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.63333333333334],PARAMETER["Standard_Parallel_1",44.53333333333333],PARAMETER["Standard_Parallel_2",44.91666666666666],PARAMETER["Latitude_Of_Origin",44.45611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNRiceMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Rice_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Rice",DATUM["D_NAD_1983_HARN_Adj_MN_Rice",SPHEROID["S_GRS_1980_Adj_MN_Rice",6378434.181,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.13333333333334],PARAMETER["Standard_Parallel_1",44.3],PARAMETER["Standard_Parallel_2",44.66666666666666],PARAMETER["Latitude_Of_Origin",44.19472222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNRockMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Rock_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Rock",DATUM["D_NAD_1983_HARN_Adj_MN_Rock",SPHEROID["S_GRS_1980_Adj_MN_Rock",6378624.681,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNRoseauMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Roseau_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Roseau",DATUM["D_NAD_1983_HARN_Adj_MN_Roseau",SPHEROID["S_GRS_1980_Adj_MN_Roseau",6378449.421,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.15],PARAMETER["Standard_Parallel_1",48.6],PARAMETER["Standard_Parallel_2",48.93333333333333],PARAMETER["Latitude_Of_Origin",48.54388888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNStLouisNorthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_St_Louis_North_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_St_Louis_North",DATUM["D_NAD_1983_HARN_Adj_MN_St_Louis_North",SPHEROID["S_GRS_1980_Adj_MN_St_Louis_North",6378543.909,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.45],PARAMETER["Standard_Parallel_1",47.98333333333333],PARAMETER["Standard_Parallel_2",48.53333333333333],PARAMETER["Latitude_Of_Origin",47.83333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNStLouisCentralMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_St_Louis_Central_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_St_Louis_Central",DATUM["D_NAD_1983_HARN_Adj_MN_St_Louis_Central",SPHEROID["S_GRS_1980_Adj_MN_St_Louis_Central",6378605.783,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.45],PARAMETER["Standard_Parallel_1",47.33333333333334],PARAMETER["Standard_Parallel_2",47.75],PARAMETER["Latitude_Of_Origin",47.25],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNStLouisSouthMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_St_Louis_South_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_St_Louis_South",DATUM["D_NAD_1983_HARN_Adj_MN_St_Louis_South",SPHEROID["S_GRS_1980_Adj_MN_St_Louis_South",6378540.861,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.45],PARAMETER["Standard_Parallel_1",46.78333333333333],PARAMETER["Standard_Parallel_2",47.13333333333333],PARAMETER["Latitude_Of_Origin",46.65],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNScottMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Scott_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Scott",DATUM["D_NAD_1983_HARN_Adj_MN_Scott",SPHEROID["S_GRS_1980_Adj_MN_Scott",6378421.989,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.31666666666666],PARAMETER["Standard_Parallel_1",44.51666666666667],PARAMETER["Standard_Parallel_2",44.91666666666666],PARAMETER["Latitude_Of_Origin",44.47194444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNSherburneMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Sherburne_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Sherburne",DATUM["D_NAD_1983_HARN_Adj_MN_Sherburne",SPHEROID["S_GRS_1980_Adj_MN_Sherburne",6378443.325,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.88333333333334],PARAMETER["Standard_Parallel_1",45.03333333333333],PARAMETER["Standard_Parallel_2",45.46666666666667],PARAMETER["Latitude_Of_Origin",44.9775],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNSibleyMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Sibley_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Sibley",DATUM["D_NAD_1983_HARN_Adj_MN_Sibley",SPHEROID["S_GRS_1980_Adj_MN_Sibley",6378414.369,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.63333333333334],PARAMETER["Standard_Parallel_1",44.53333333333333],PARAMETER["Standard_Parallel_2",44.91666666666666],PARAMETER["Latitude_Of_Origin",44.45611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNStearnsMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Stearns_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Stearns",DATUM["D_NAD_1983_HARN_Adj_MN_Stearns",SPHEROID["S_GRS_1980_Adj_MN_Stearns",6378502.761,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.15],PARAMETER["Standard_Parallel_1",45.35],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",45.28277777777777],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNSteeleMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Steele_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Steele",DATUM["D_NAD_1983_HARN_Adj_MN_Steele",SPHEROID["S_GRS_1980_Adj_MN_Steele",6378481.425,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.91666666666667],PARAMETER["Standard_Parallel_1",43.88333333333333],PARAMETER["Standard_Parallel_2",44.13333333333333],PARAMETER["Latitude_Of_Origin",43.83388888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNStevensMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Stevens_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Stevens",DATUM["D_NAD_1983_HARN_Adj_MN_Stevens",SPHEROID["S_GRS_1980_Adj_MN_Stevens",6378502.761,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.15],PARAMETER["Standard_Parallel_1",45.35],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",45.28277777777777],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNSwiftMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Swift_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Swift",DATUM["D_NAD_1983_HARN_Adj_MN_Swift",SPHEROID["S_GRS_1980_Adj_MN_Swift",6378470.757,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.05],PARAMETER["Standard_Parallel_1",45.21666666666667],PARAMETER["Standard_Parallel_2",45.53333333333333],PARAMETER["Latitude_Of_Origin",45.15222222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNToddMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Todd_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Todd",DATUM["D_NAD_1983_HARN_Adj_MN_Todd",SPHEROID["S_GRS_1980_Adj_MN_Todd",6378548.481,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.9],PARAMETER["Standard_Parallel_1",45.86666666666667],PARAMETER["Standard_Parallel_2",46.28333333333333],PARAMETER["Latitude_Of_Origin",45.77333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNTraverseMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Traverse_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Traverse",DATUM["D_NAD_1983_HARN_Adj_MN_Traverse",SPHEROID["S_GRS_1980_Adj_MN_Traverse",6378463.746,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-96.55],PARAMETER["Standard_Parallel_1",45.63333333333333],PARAMETER["Standard_Parallel_2",45.96666666666667],PARAMETER["Latitude_Of_Origin",45.58555555555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNWabashaMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Wabasha_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Wabasha",DATUM["D_NAD_1983_HARN_Adj_MN_Wabasha",SPHEROID["S_GRS_1980_Adj_MN_Wabasha",6378426.561,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.26666666666667],PARAMETER["Standard_Parallel_1",44.15],PARAMETER["Standard_Parallel_2",44.41666666666666],PARAMETER["Latitude_Of_Origin",44.10694444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNWadenaMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Wadena_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Wadena",DATUM["D_NAD_1983_HARN_Adj_MN_Wadena",SPHEROID["S_GRS_1980_Adj_MN_Wadena",6378546.957,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.46666666666667],PARAMETER["Standard_Parallel_1",46.26666666666667],PARAMETER["Standard_Parallel_2",46.73333333333333],PARAMETER["Latitude_Of_Origin",46.15638888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNWasecaMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Waseca_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Waseca",DATUM["D_NAD_1983_HARN_Adj_MN_Waseca",SPHEROID["S_GRS_1980_Adj_MN_Waseca",6378481.425,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-92.91666666666667],PARAMETER["Standard_Parallel_1",43.88333333333333],PARAMETER["Standard_Parallel_2",44.13333333333333],PARAMETER["Latitude_Of_Origin",43.83388888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNWatonwanMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Watonwan_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Watonwan",DATUM["D_NAD_1983_HARN_Adj_MN_Watonwan",SPHEROID["S_GRS_1980_Adj_MN_Watonwan",6378514.953,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-94.91666666666667],PARAMETER["Standard_Parallel_1",43.9],PARAMETER["Standard_Parallel_2",44.16666666666666],PARAMETER["Latitude_Of_Origin",43.84805555555556],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNWinonaMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Winona_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Winona",DATUM["D_NAD_1983_HARN_Adj_MN_Winona",SPHEROID["S_GRS_1980_Adj_MN_Winona",6378453.688,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-91.61666666666666],PARAMETER["Standard_Parallel_1",43.9],PARAMETER["Standard_Parallel_2",44.13333333333333],PARAMETER["Latitude_Of_Origin",43.84722222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNWrightMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Wright_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Wright",DATUM["D_NAD_1983_HARN_Adj_MN_Wright",SPHEROID["S_GRS_1980_Adj_MN_Wright",6378443.325,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-93.88333333333334],PARAMETER["Standard_Parallel_1",45.03333333333333],PARAMETER["Standard_Parallel_2",45.46666666666667],PARAMETER["Latitude_Of_Origin",44.9775],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNYellowMedicineMeters",
    `PROJCS["NAD_1983_HARN_Adj_MN_Yellow_Medicine_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Yellow_Medicine",DATUM["D_NAD_1983_HARN_Adj_MN_Yellow_Medicine",SPHEROID["S_GRS_1980_Adj_MN_Yellow_Medicine",6378530.193,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",152400.3048006096],PARAMETER["False_Northing",30480.06096012193],PARAMETER["Central_Meridian",-95.9],PARAMETER["Standard_Parallel_1",44.66666666666666],PARAMETER["Standard_Parallel_2",44.95],PARAMETER["Latitude_Of_Origin",44.54166666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNStLouisCS96Meters",
    `PROJCS["NAD_1983_HARN_Adj_MN_St_Louis_CS96_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_St_Louis",DATUM["D_NAD_1983_HARN_Adj_MN_St_Louis",SPHEROID["S_GRS_1980_Adj_MN_St_Louis",6378523.0,298.2752724]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1450000.0],PARAMETER["False_Northing",1000000.0],PARAMETER["Central_Meridian",-92.45],PARAMETER["Scale_Factor",0.99998529],PARAMETER["Latitude_Of_Origin",46.61666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNStLouisCS96Feet",
    `PROJCS["NAD_1983_HARN_Adj_MN_St_Louis_CS96_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_St_Louis",DATUM["D_NAD_1983_HARN_Adj_MN_St_Louis",SPHEROID["S_GRS_1980_Adj_MN_St_Louis",6378523.0,298.2752724]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4757208.333333],PARAMETER["False_Northing",3280833.333333],PARAMETER["Central_Meridian",-92.45],PARAMETER["Scale_Factor",0.99998529],PARAMETER["Latitude_Of_Origin",46.61666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone19N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_19N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-69.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone20N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_20N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-63.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone21N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_21N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-57.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "PalestineGrid1923ModifiedTM",
    `PROJCS["Palestine_Grid_1923_Modified_TM",GEOGCS["GCS_Israel",DATUM["D_Israel",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",169529.584],PARAMETER["False_Northing",126907.39],PARAMETER["Central_Meridian",35.20451694444445],PARAMETER["Scale_Factor",1.0000067],PARAMETER["Latitude_Of_Origin",31.73439361111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjMNAitkinFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Aitkin_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500029.5763345],PARAMETER["False_Northing",100005.9152669],PARAMETER["Central_Meridian",-93.4325],PARAMETER["Scale_Factor",1.000059152669],PARAMETER["Latitude_Of_Origin",46.15416666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNClayFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Clay_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500022.658931],PARAMETER["False_Northing",100004.5317862],PARAMETER["Central_Meridian",-96.7],PARAMETER["Scale_Factor",1.000045317862],PARAMETER["Latitude_Of_Origin",46.63],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNClearwaterFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Clearwater_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500036.2528305],PARAMETER["False_Northing",100007.2505661],PARAMETER["Central_Meridian",-95.37583333333333],PARAMETER["Scale_Factor",1.000072505661],PARAMETER["Latitude_Of_Origin",47.15166666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNHubbardFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Hubbard_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500035.7768305],PARAMETER["False_Northing",100007.1553661],PARAMETER["Central_Meridian",-94.92055555555557],PARAMETER["Scale_Factor",1.000071553661],PARAMETER["Latitude_Of_Origin",46.80361111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNLakeFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lake_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500037.9223105],PARAMETER["False_Northing",100007.5844621],PARAMETER["Central_Meridian",-91.40916666666668],PARAMETER["Scale_Factor",1.000075844621],PARAMETER["Latitude_Of_Origin",47.06666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNMilleLacsFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Mille_Lacs_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500027.073069],PARAMETER["False_Northing",100005.4146138],PARAMETER["Central_Meridian",-93.62055555555555],PARAMETER["Scale_Factor",1.000054146138],PARAMETER["Latitude_Of_Origin",45.55888888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNWashingtonFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Washington_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500019.9183995],PARAMETER["False_Northing",100003.9836799],PARAMETER["Central_Meridian",-92.83333333333333],PARAMETER["Scale_Factor",1.000039836799],PARAMETER["Latitude_Of_Origin",44.74583333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNWilkinFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Wilkin_Feet",GEOGCS["GCS_North_American_1983_HARN",DATUM["D_North_American_1983_HARN",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500024.450533],PARAMETER["False_Northing",100004.8901066],PARAMETER["Central_Meridian",-96.52444444444444],PARAMETER["Scale_Factor",1.000048901066],PARAMETER["Latitude_Of_Origin",46.02166666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNAnokaFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Anoka_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Anoka",DATUM["D_NAD_1983_HARN_Adj_MN_Anoka",SPHEROID["S_GRS_1980_Adj_MN_Anoka",6378418.941,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.26666666666667],PARAMETER["Standard_Parallel_1",45.06666666666667],PARAMETER["Standard_Parallel_2",45.36666666666667],PARAMETER["Latitude_Of_Origin",45.03527777777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNBeckerFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Becker_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Becker",DATUM["D_NAD_1983_HARN_Adj_MN_Becker",SPHEROID["S_GRS_1980_Adj_MN_Becker",6378586.581,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.68333333333334],PARAMETER["Standard_Parallel_1",46.78333333333333],PARAMETER["Standard_Parallel_2",47.08333333333334],PARAMETER["Latitude_Of_Origin",46.71777777777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNBeltramiNorthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Beltrami_North_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Beltrami_North",DATUM["D_NAD_1983_HARN_Adj_MN_Beltrami_North",SPHEROID["S_GRS_1980_Adj_MN_Beltrami_North",6378505.809,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.01666666666667],PARAMETER["Standard_Parallel_1",48.11666666666667],PARAMETER["Standard_Parallel_2",48.46666666666667],PARAMETER["Latitude_Of_Origin",48.02],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNBeltramiSouthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Beltrami_South_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Beltrami_South",DATUM["D_NAD_1983_HARN_Adj_MN_Beltrami_South",SPHEROID["S_GRS_1980_Adj_MN_Beltrami_South",6378544.823,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.85],PARAMETER["Standard_Parallel_1",47.5],PARAMETER["Standard_Parallel_2",47.91666666666666],PARAMETER["Latitude_Of_Origin",47.4125],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNBentonFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Benton_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Benton",DATUM["D_NAD_1983_HARN_Adj_MN_Benton",SPHEROID["S_GRS_1980_Adj_MN_Benton",6378490.569,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.05],PARAMETER["Standard_Parallel_1",45.58333333333334],PARAMETER["Standard_Parallel_2",45.78333333333333],PARAMETER["Latitude_Of_Origin",45.55916666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNBigStoneFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Big_Stone_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Big_Stone",DATUM["D_NAD_1983_HARN_Adj_MN_Big_Stone",SPHEROID["S_GRS_1980_Adj_MN_Big_Stone",6378470.757,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.05],PARAMETER["Standard_Parallel_1",45.21666666666667],PARAMETER["Standard_Parallel_2",45.53333333333333],PARAMETER["Latitude_Of_Origin",45.15222222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNBlueEarthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Blue_Earth_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Blue_Earth",DATUM["D_NAD_1983_HARN_Adj_MN_Blue_Earth",SPHEROID["S_GRS_1980_Adj_MN_Blue_Earth",6378403.701,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.26666666666667],PARAMETER["Standard_Parallel_1",43.93333333333333],PARAMETER["Standard_Parallel_2",44.36666666666667],PARAMETER["Latitude_Of_Origin",43.84805555555556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNBrownFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Brown_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Brown",DATUM["D_NAD_1983_HARN_Adj_MN_Brown",SPHEROID["S_GRS_1980_Adj_MN_Brown",6378434.181,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.73333333333333],PARAMETER["Standard_Parallel_1",44.16666666666666],PARAMETER["Standard_Parallel_2",44.46666666666667],PARAMETER["Latitude_Of_Origin",44.10805555555556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNCarltonFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Carlton_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Carlton",DATUM["D_NAD_1983_HARN_Adj_MN_Carlton",SPHEROID["S_GRS_1980_Adj_MN_Carlton",6378454.907,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.68333333333334],PARAMETER["Standard_Parallel_1",46.46666666666667],PARAMETER["Standard_Parallel_2",46.73333333333333],PARAMETER["Latitude_Of_Origin",46.41722222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNCarverFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Carver_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Carver",DATUM["D_NAD_1983_HARN_Adj_MN_Carver",SPHEROID["S_GRS_1980_Adj_MN_Carver",6378400.653,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.76666666666667],PARAMETER["Standard_Parallel_1",44.68333333333333],PARAMETER["Standard_Parallel_2",44.9],PARAMETER["Latitude_Of_Origin",44.63972222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNCassNorthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Cass_North_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Cass_North",DATUM["D_NAD_1983_HARN_Adj_MN_Cass_North",SPHEROID["S_GRS_1980_Adj_MN_Cass_North",6378567.378,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.21666666666667],PARAMETER["Standard_Parallel_1",46.91666666666666],PARAMETER["Standard_Parallel_2",47.31666666666667],PARAMETER["Latitude_Of_Origin",46.80361111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNCassSouthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Cass_South_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Cass_South",DATUM["D_NAD_1983_HARN_Adj_MN_Cass_South",SPHEROID["S_GRS_1980_Adj_MN_Cass_South",6378546.957,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.46666666666667],PARAMETER["Standard_Parallel_1",46.26666666666667],PARAMETER["Standard_Parallel_2",46.73333333333333],PARAMETER["Latitude_Of_Origin",46.15638888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNChippewaFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Chippewa_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Chippewa",DATUM["D_NAD_1983_HARN_Adj_MN_Chippewa",SPHEROID["S_GRS_1980_Adj_MN_Chippewa",6378476.853,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.85],PARAMETER["Standard_Parallel_1",44.83333333333334],PARAMETER["Standard_Parallel_2",45.2],PARAMETER["Latitude_Of_Origin",44.75277777777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNChisagoFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Chisago_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Chisago",DATUM["D_NAD_1983_HARN_Adj_MN_Chisago",SPHEROID["S_GRS_1980_Adj_MN_Chisago",6378411.321,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.08333333333333],PARAMETER["Standard_Parallel_1",45.33333333333334],PARAMETER["Standard_Parallel_2",45.66666666666666],PARAMETER["Latitude_Of_Origin",45.29638888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNCookNorthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Cook_North_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Cook_North",DATUM["D_NAD_1983_HARN_Adj_MN_Cook_North",SPHEROID["S_GRS_1980_Adj_MN_Cook_North",6378647.541,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-90.25],PARAMETER["Standard_Parallel_1",47.93333333333333],PARAMETER["Standard_Parallel_2",48.16666666666666],PARAMETER["Latitude_Of_Origin",47.88333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNCookSouthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Cook_South_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Cook_South",DATUM["D_NAD_1983_HARN_Adj_MN_Cook_South",SPHEROID["S_GRS_1980_Adj_MN_Cook_South",6378647.541,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-90.25],PARAMETER["Standard_Parallel_1",47.55],PARAMETER["Standard_Parallel_2",47.81666666666667],PARAMETER["Latitude_Of_Origin",47.43888888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNCottonwoodFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Cottonwood_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Cottonwood",DATUM["D_NAD_1983_HARN_Adj_MN_Cottonwood",SPHEROID["S_GRS_1980_Adj_MN_Cottonwood",6378514.953,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.91666666666667],PARAMETER["Standard_Parallel_1",43.9],PARAMETER["Standard_Parallel_2",44.16666666666666],PARAMETER["Latitude_Of_Origin",43.84805555555556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNCrowWingFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Crow_Wing_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Crow_Wing",DATUM["D_NAD_1983_HARN_Adj_MN_Crow_Wing",SPHEROID["S_GRS_1980_Adj_MN_Crow_Wing",6378546.957,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.46666666666667],PARAMETER["Standard_Parallel_1",46.26666666666667],PARAMETER["Standard_Parallel_2",46.73333333333333],PARAMETER["Latitude_Of_Origin",46.15638888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNDakotaFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Dakota_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Dakota",DATUM["D_NAD_1983_HARN_Adj_MN_Dakota",SPHEROID["S_GRS_1980_Adj_MN_Dakota",6378421.989,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.31666666666666],PARAMETER["Standard_Parallel_1",44.51666666666667],PARAMETER["Standard_Parallel_2",44.91666666666666],PARAMETER["Latitude_Of_Origin",44.47194444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNDodgeFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Dodge_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Dodge",DATUM["D_NAD_1983_HARN_Adj_MN_Dodge",SPHEROID["S_GRS_1980_Adj_MN_Dodge",6378481.425,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.91666666666667],PARAMETER["Standard_Parallel_1",43.88333333333333],PARAMETER["Standard_Parallel_2",44.13333333333333],PARAMETER["Latitude_Of_Origin",43.83388888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNDouglasFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Douglas_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Douglas",DATUM["D_NAD_1983_HARN_Adj_MN_Douglas",SPHEROID["S_GRS_1980_Adj_MN_Douglas",6378518.001,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.05],PARAMETER["Standard_Parallel_1",45.8],PARAMETER["Standard_Parallel_2",46.05],PARAMETER["Latitude_Of_Origin",45.75888888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNFaribaultFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Faribault_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Faribault",DATUM["D_NAD_1983_HARN_Adj_MN_Faribault",SPHEROID["S_GRS_1980_Adj_MN_Faribault",6378521.049,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNFillmoreFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Fillmore_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Fillmore",DATUM["D_NAD_1983_HARN_Adj_MN_Fillmore",SPHEROID["S_GRS_1980_Adj_MN_Fillmore",6378464.661,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.08333333333333],PARAMETER["Standard_Parallel_1",43.55],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNFreebornFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Freeborn_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Freeborn",DATUM["D_NAD_1983_HARN_Adj_MN_Freeborn",SPHEROID["S_GRS_1980_Adj_MN_Freeborn",6378521.049,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNGoodhueFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Goodhue_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Goodhue",DATUM["D_NAD_1983_HARN_Adj_MN_Goodhue",SPHEROID["S_GRS_1980_Adj_MN_Goodhue",6378434.181,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.13333333333334],PARAMETER["Standard_Parallel_1",44.3],PARAMETER["Standard_Parallel_2",44.66666666666666],PARAMETER["Latitude_Of_Origin",44.19472222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNGrantFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Grant_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Grant",DATUM["D_NAD_1983_HARN_Adj_MN_Grant",SPHEROID["S_GRS_1980_Adj_MN_Grant",6378518.001,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.05],PARAMETER["Standard_Parallel_1",45.8],PARAMETER["Standard_Parallel_2",46.05],PARAMETER["Latitude_Of_Origin",45.75888888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNHennepinFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Hennepin_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Hennepin",DATUM["D_NAD_1983_HARN_Adj_MN_Hennepin",SPHEROID["S_GRS_1980_Adj_MN_Hennepin",6378418.941,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.38333333333334],PARAMETER["Standard_Parallel_1",44.88333333333333],PARAMETER["Standard_Parallel_2",45.13333333333333],PARAMETER["Latitude_Of_Origin",44.79111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNHoustonFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Houston_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Houston",DATUM["D_NAD_1983_HARN_Adj_MN_Houston",SPHEROID["S_GRS_1980_Adj_MN_Houston",6378436.619,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-91.46666666666667],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNIsantiFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Isanti_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Isanti",DATUM["D_NAD_1983_HARN_Adj_MN_Isanti",SPHEROID["S_GRS_1980_Adj_MN_Isanti",6378411.321,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.08333333333333],PARAMETER["Standard_Parallel_1",45.33333333333334],PARAMETER["Standard_Parallel_2",45.66666666666666],PARAMETER["Latitude_Of_Origin",45.29638888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNItascaNorthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Itasca_North_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Itasca_North",DATUM["D_NAD_1983_HARN_Adj_MN_Itasca_North",SPHEROID["S_GRS_1980_Adj_MN_Itasca_North",6378574.389,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.73333333333333],PARAMETER["Standard_Parallel_1",47.56666666666667],PARAMETER["Standard_Parallel_2",47.81666666666667],PARAMETER["Latitude_Of_Origin",47.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNItascaSouthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Itasca_South_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Itasca_South",DATUM["D_NAD_1983_HARN_Adj_MN_Itasca_South",SPHEROID["S_GRS_1980_Adj_MN_Itasca_South",6378574.389,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.73333333333333],PARAMETER["Standard_Parallel_1",47.08333333333334],PARAMETER["Standard_Parallel_2",47.41666666666666],PARAMETER["Latitude_Of_Origin",47.02638888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNJacksonFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Jackson_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Jackson",DATUM["D_NAD_1983_HARN_Adj_MN_Jackson",SPHEROID["S_GRS_1980_Adj_MN_Jackson",6378521.049,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNKanabecFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Kanabec_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Kanabec",DATUM["D_NAD_1983_HARN_Adj_MN_Kanabec",SPHEROID["S_GRS_1980_Adj_MN_Kanabec",6378472.281,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.9],PARAMETER["Standard_Parallel_1",45.81666666666667],PARAMETER["Standard_Parallel_2",46.33333333333334],PARAMETER["Latitude_Of_Origin",45.73],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNKandiyohiFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Kandiyohi_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Kandiyohi",DATUM["D_NAD_1983_HARN_Adj_MN_Kandiyohi",SPHEROID["S_GRS_1980_Adj_MN_Kandiyohi",6378498.189,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.75],PARAMETER["Standard_Parallel_1",44.96666666666667],PARAMETER["Standard_Parallel_2",45.33333333333334],PARAMETER["Latitude_Of_Origin",44.89138888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNKittsonFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Kittson_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Kittson",DATUM["D_NAD_1983_HARN_Adj_MN_Kittson",SPHEROID["S_GRS_1980_Adj_MN_Kittson",6378449.421,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.15],PARAMETER["Standard_Parallel_1",48.6],PARAMETER["Standard_Parallel_2",48.93333333333333],PARAMETER["Latitude_Of_Origin",48.54388888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNKoochichingFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Koochiching_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Koochiching",DATUM["D_NAD_1983_HARN_Adj_MN_Koochiching",SPHEROID["S_GRS_1980_Adj_MN_Koochiching",6378525.621,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.75],PARAMETER["Standard_Parallel_1",48.0],PARAMETER["Standard_Parallel_2",48.61666666666667],PARAMETER["Latitude_Of_Origin",47.84583333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNLacQuiParleFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lac_Qui_Parle_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Lac_Qui_Parle",DATUM["D_NAD_1983_HARN_Adj_MN_Lac_Qui_Parle",SPHEROID["S_GRS_1980_Adj_MN_Lac_Qui_Parle",6378476.853,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.85],PARAMETER["Standard_Parallel_1",44.83333333333334],PARAMETER["Standard_Parallel_2",45.2],PARAMETER["Latitude_Of_Origin",44.75277777777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNLakeoftheWoodsNorthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_North_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_North",DATUM["D_NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_North",SPHEROID["S_GRS_1980_Adj_MN_Lake_of_the_Woods_North",6378466.185,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.98333333333333],PARAMETER["Standard_Parallel_1",49.18333333333333],PARAMETER["Standard_Parallel_2",49.33333333333334],PARAMETER["Latitude_Of_Origin",49.15],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNLakeoftheWoodsSouthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_South_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_South",DATUM["D_NAD_1983_HARN_Adj_MN_Lake_of_the_Woods_South",SPHEROID["S_GRS_1980_Adj_MN_Lake_of_the_Woods_South",6378496.665,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.88333333333334],PARAMETER["Standard_Parallel_1",48.45],PARAMETER["Standard_Parallel_2",48.88333333333333],PARAMETER["Latitude_Of_Origin",48.36611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNLeSueurFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Le_Sueur_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Le_Sueur",DATUM["D_NAD_1983_HARN_Adj_MN_Le_Sueur",SPHEROID["S_GRS_1980_Adj_MN_Le_Sueur",6378434.181,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.13333333333334],PARAMETER["Standard_Parallel_1",44.3],PARAMETER["Standard_Parallel_2",44.66666666666666],PARAMETER["Latitude_Of_Origin",44.19472222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNLincolnFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lincoln_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Lincoln",DATUM["D_NAD_1983_HARN_Adj_MN_Lincoln",SPHEROID["S_GRS_1980_Adj_MN_Lincoln",6378643.579,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.26666666666667],PARAMETER["Standard_Parallel_1",44.28333333333333],PARAMETER["Standard_Parallel_2",44.61666666666667],PARAMETER["Latitude_Of_Origin",44.19666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNLyonFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Lyon_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Lyon",DATUM["D_NAD_1983_HARN_Adj_MN_Lyon",SPHEROID["S_GRS_1980_Adj_MN_Lyon",6378559.758,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.85],PARAMETER["Standard_Parallel_1",44.25],PARAMETER["Standard_Parallel_2",44.58333333333334],PARAMETER["Latitude_Of_Origin",44.19555555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNMcLeodFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_McLeod_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_McLeod",DATUM["D_NAD_1983_HARN_Adj_MN_McLeod",SPHEROID["S_GRS_1980_Adj_MN_McLeod",6378414.369,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.63333333333334],PARAMETER["Standard_Parallel_1",44.53333333333333],PARAMETER["Standard_Parallel_2",44.91666666666666],PARAMETER["Latitude_Of_Origin",44.45611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNMahnomenFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Mahnomen_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Mahnomen",DATUM["D_NAD_1983_HARN_Adj_MN_Mahnomen",SPHEROID["S_GRS_1980_Adj_MN_Mahnomen",6378586.581,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.81666666666666],PARAMETER["Standard_Parallel_1",47.2],PARAMETER["Standard_Parallel_2",47.45],PARAMETER["Latitude_Of_Origin",47.15166666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNMarshallFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Marshall_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Marshall",DATUM["D_NAD_1983_HARN_Adj_MN_Marshall",SPHEROID["S_GRS_1980_Adj_MN_Marshall",6378441.801,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.38333333333334],PARAMETER["Standard_Parallel_1",48.23333333333333],PARAMETER["Standard_Parallel_2",48.48333333333333],PARAMETER["Latitude_Of_Origin",48.17305555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNMartinFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Martin_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Martin",DATUM["D_NAD_1983_HARN_Adj_MN_Martin",SPHEROID["S_GRS_1980_Adj_MN_Martin",6378521.049,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNMeekerFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Meeker_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Meeker",DATUM["D_NAD_1983_HARN_Adj_MN_Meeker",SPHEROID["S_GRS_1980_Adj_MN_Meeker",6378498.189,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.75],PARAMETER["Standard_Parallel_1",44.96666666666667],PARAMETER["Standard_Parallel_2",45.33333333333334],PARAMETER["Latitude_Of_Origin",44.89138888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNMorrisonFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Morrison_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Morrison",DATUM["D_NAD_1983_HARN_Adj_MN_Morrison",SPHEROID["S_GRS_1980_Adj_MN_Morrison",6378502.761,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.2],PARAMETER["Standard_Parallel_1",45.85],PARAMETER["Standard_Parallel_2",46.26666666666667],PARAMETER["Latitude_Of_Origin",45.77388888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNMowerFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Mower_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Mower",DATUM["D_NAD_1983_HARN_Adj_MN_Mower",SPHEROID["S_GRS_1980_Adj_MN_Mower",6378521.049,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNMurrayFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Murray_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Murray",DATUM["D_NAD_1983_HARN_Adj_MN_Murray",SPHEROID["S_GRS_1980_Adj_MN_Murray",6378617.061,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.76666666666667],PARAMETER["Standard_Parallel_1",43.91666666666666],PARAMETER["Standard_Parallel_2",44.16666666666666],PARAMETER["Latitude_Of_Origin",43.84805555555556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNNicolletFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Nicollet_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Nicollet",DATUM["D_NAD_1983_HARN_Adj_MN_Nicollet",SPHEROID["S_GRS_1980_Adj_MN_Nicollet",6378403.701,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.26666666666667],PARAMETER["Standard_Parallel_1",43.93333333333333],PARAMETER["Standard_Parallel_2",44.36666666666667],PARAMETER["Latitude_Of_Origin",43.84805555555556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNNoblesFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Nobles_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Nobles",DATUM["D_NAD_1983_HARN_Adj_MN_Nobles",SPHEROID["S_GRS_1980_Adj_MN_Nobles",6378624.681,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNNormanFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Norman_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Norman",DATUM["D_NAD_1983_HARN_Adj_MN_Norman",SPHEROID["S_GRS_1980_Adj_MN_Norman",6378468.623,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.45],PARAMETER["Standard_Parallel_1",47.2],PARAMETER["Standard_Parallel_2",47.45],PARAMETER["Latitude_Of_Origin",47.15055555555556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNOlmstedFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Olmsted_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Olmsted",DATUM["D_NAD_1983_HARN_Adj_MN_Olmsted",SPHEROID["S_GRS_1980_Adj_MN_Olmsted",6378481.425,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.91666666666667],PARAMETER["Standard_Parallel_1",43.88333333333333],PARAMETER["Standard_Parallel_2",44.13333333333333],PARAMETER["Latitude_Of_Origin",43.83388888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNOttertailFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Ottertail_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Ottertail",DATUM["D_NAD_1983_HARN_Adj_MN_Ottertail",SPHEROID["S_GRS_1980_Adj_MN_Ottertail",6378525.621,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.71666666666667],PARAMETER["Standard_Parallel_1",46.18333333333333],PARAMETER["Standard_Parallel_2",46.65],PARAMETER["Latitude_Of_Origin",46.10638888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNPenningtonFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Pennington_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Pennington",DATUM["D_NAD_1983_HARN_Adj_MN_Pennington",SPHEROID["S_GRS_1980_Adj_MN_Pennington",6378445.763,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.36666666666666],PARAMETER["Standard_Parallel_1",47.6],PARAMETER["Standard_Parallel_2",48.08333333333334],PARAMETER["Latitude_Of_Origin",47.49888888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNPineFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Pine_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Pine",DATUM["D_NAD_1983_HARN_Adj_MN_Pine",SPHEROID["S_GRS_1980_Adj_MN_Pine",6378472.281,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.9],PARAMETER["Standard_Parallel_1",45.81666666666667],PARAMETER["Standard_Parallel_2",46.33333333333334],PARAMETER["Latitude_Of_Origin",45.73],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNPipestoneFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Pipestone_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Pipestone",DATUM["D_NAD_1983_HARN_Adj_MN_Pipestone",SPHEROID["S_GRS_1980_Adj_MN_Pipestone",6378670.401,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.25],PARAMETER["Standard_Parallel_1",43.88333333333333],PARAMETER["Standard_Parallel_2",44.15],PARAMETER["Latitude_Of_Origin",43.84916666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNPolkFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Polk_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Polk",DATUM["D_NAD_1983_HARN_Adj_MN_Polk",SPHEROID["S_GRS_1980_Adj_MN_Polk",6378445.763,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.36666666666666],PARAMETER["Standard_Parallel_1",47.6],PARAMETER["Standard_Parallel_2",48.08333333333334],PARAMETER["Latitude_Of_Origin",47.49888888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNPopeFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Pope_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Pope",DATUM["D_NAD_1983_HARN_Adj_MN_Pope",SPHEROID["S_GRS_1980_Adj_MN_Pope",6378502.761,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.15],PARAMETER["Standard_Parallel_1",45.35],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",45.28277777777777],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNRamseyFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Ramsey_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Ramsey",DATUM["D_NAD_1983_HARN_Adj_MN_Ramsey",SPHEROID["S_GRS_1980_Adj_MN_Ramsey",6378418.941,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.38333333333334],PARAMETER["Standard_Parallel_1",44.88333333333333],PARAMETER["Standard_Parallel_2",45.13333333333333],PARAMETER["Latitude_Of_Origin",44.79111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNRedLakeFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Red_Lake_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Red_Lake",DATUM["D_NAD_1983_HARN_Adj_MN_Red_Lake",SPHEROID["S_GRS_1980_Adj_MN_Red_Lake",6378445.763,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.36666666666666],PARAMETER["Standard_Parallel_1",47.6],PARAMETER["Standard_Parallel_2",48.08333333333334],PARAMETER["Latitude_Of_Origin",47.49888888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNRedwoodFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Redwood_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Redwood",DATUM["D_NAD_1983_HARN_Adj_MN_Redwood",SPHEROID["S_GRS_1980_Adj_MN_Redwood",6378438.753,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.23333333333333],PARAMETER["Standard_Parallel_1",44.26666666666667],PARAMETER["Standard_Parallel_2",44.56666666666667],PARAMETER["Latitude_Of_Origin",44.19472222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNRenvilleFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Renville_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Renville",DATUM["D_NAD_1983_HARN_Adj_MN_Renville",SPHEROID["S_GRS_1980_Adj_MN_Renville",6378414.369,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.63333333333334],PARAMETER["Standard_Parallel_1",44.53333333333333],PARAMETER["Standard_Parallel_2",44.91666666666666],PARAMETER["Latitude_Of_Origin",44.45611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNRiceFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Rice_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Rice",DATUM["D_NAD_1983_HARN_Adj_MN_Rice",SPHEROID["S_GRS_1980_Adj_MN_Rice",6378434.181,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.13333333333334],PARAMETER["Standard_Parallel_1",44.3],PARAMETER["Standard_Parallel_2",44.66666666666666],PARAMETER["Latitude_Of_Origin",44.19472222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNRockFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Rock_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Rock",DATUM["D_NAD_1983_HARN_Adj_MN_Rock",SPHEROID["S_GRS_1980_Adj_MN_Rock",6378624.681,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.95],PARAMETER["Standard_Parallel_1",43.56666666666667],PARAMETER["Standard_Parallel_2",43.8],PARAMETER["Latitude_Of_Origin",43.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNRoseauFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Roseau_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Roseau",DATUM["D_NAD_1983_HARN_Adj_MN_Roseau",SPHEROID["S_GRS_1980_Adj_MN_Roseau",6378449.421,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.15],PARAMETER["Standard_Parallel_1",48.6],PARAMETER["Standard_Parallel_2",48.93333333333333],PARAMETER["Latitude_Of_Origin",48.54388888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNStLouisNorthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_St_Louis_North_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_St_Louis_North",DATUM["D_NAD_1983_HARN_Adj_MN_St_Louis_North",SPHEROID["S_GRS_1980_Adj_MN_St_Louis_North",6378543.909,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.45],PARAMETER["Standard_Parallel_1",47.98333333333333],PARAMETER["Standard_Parallel_2",48.53333333333333],PARAMETER["Latitude_Of_Origin",47.83333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNStLouisCentralFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_St_Louis_Central_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_St_Louis_Central",DATUM["D_NAD_1983_HARN_Adj_MN_St_Louis_Central",SPHEROID["S_GRS_1980_Adj_MN_St_Louis_Central",6378605.783,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.45],PARAMETER["Standard_Parallel_1",47.33333333333334],PARAMETER["Standard_Parallel_2",47.75],PARAMETER["Latitude_Of_Origin",47.25],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNStLouisSouthFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_St_Louis_South_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_St_Louis_South",DATUM["D_NAD_1983_HARN_Adj_MN_St_Louis_South",SPHEROID["S_GRS_1980_Adj_MN_St_Louis_South",6378540.861,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.45],PARAMETER["Standard_Parallel_1",46.78333333333333],PARAMETER["Standard_Parallel_2",47.13333333333333],PARAMETER["Latitude_Of_Origin",46.65],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNScottFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Scott_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Scott",DATUM["D_NAD_1983_HARN_Adj_MN_Scott",SPHEROID["S_GRS_1980_Adj_MN_Scott",6378421.989,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.31666666666666],PARAMETER["Standard_Parallel_1",44.51666666666667],PARAMETER["Standard_Parallel_2",44.91666666666666],PARAMETER["Latitude_Of_Origin",44.47194444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNSherburneFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Sherburne_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Sherburne",DATUM["D_NAD_1983_HARN_Adj_MN_Sherburne",SPHEROID["S_GRS_1980_Adj_MN_Sherburne",6378443.325,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.88333333333334],PARAMETER["Standard_Parallel_1",45.03333333333333],PARAMETER["Standard_Parallel_2",45.46666666666667],PARAMETER["Latitude_Of_Origin",44.9775],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNSibleyFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Sibley_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Sibley",DATUM["D_NAD_1983_HARN_Adj_MN_Sibley",SPHEROID["S_GRS_1980_Adj_MN_Sibley",6378414.369,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.63333333333334],PARAMETER["Standard_Parallel_1",44.53333333333333],PARAMETER["Standard_Parallel_2",44.91666666666666],PARAMETER["Latitude_Of_Origin",44.45611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNStearnsFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Stearns_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Stearns",DATUM["D_NAD_1983_HARN_Adj_MN_Stearns",SPHEROID["S_GRS_1980_Adj_MN_Stearns",6378502.761,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.15],PARAMETER["Standard_Parallel_1",45.35],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",45.28277777777777],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNSteeleFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Steele_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Steele",DATUM["D_NAD_1983_HARN_Adj_MN_Steele",SPHEROID["S_GRS_1980_Adj_MN_Steele",6378481.425,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.91666666666667],PARAMETER["Standard_Parallel_1",43.88333333333333],PARAMETER["Standard_Parallel_2",44.13333333333333],PARAMETER["Latitude_Of_Origin",43.83388888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNStevensFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Stevens_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Stevens",DATUM["D_NAD_1983_HARN_Adj_MN_Stevens",SPHEROID["S_GRS_1980_Adj_MN_Stevens",6378502.761,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.15],PARAMETER["Standard_Parallel_1",45.35],PARAMETER["Standard_Parallel_2",45.7],PARAMETER["Latitude_Of_Origin",45.28277777777777],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNSwiftFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Swift_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Swift",DATUM["D_NAD_1983_HARN_Adj_MN_Swift",SPHEROID["S_GRS_1980_Adj_MN_Swift",6378470.757,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.05],PARAMETER["Standard_Parallel_1",45.21666666666667],PARAMETER["Standard_Parallel_2",45.53333333333333],PARAMETER["Latitude_Of_Origin",45.15222222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNToddFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Todd_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Todd",DATUM["D_NAD_1983_HARN_Adj_MN_Todd",SPHEROID["S_GRS_1980_Adj_MN_Todd",6378548.481,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.9],PARAMETER["Standard_Parallel_1",45.86666666666667],PARAMETER["Standard_Parallel_2",46.28333333333333],PARAMETER["Latitude_Of_Origin",45.77333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNTraverseFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Traverse_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Traverse",DATUM["D_NAD_1983_HARN_Adj_MN_Traverse",SPHEROID["S_GRS_1980_Adj_MN_Traverse",6378463.746,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-96.55],PARAMETER["Standard_Parallel_1",45.63333333333333],PARAMETER["Standard_Parallel_2",45.96666666666667],PARAMETER["Latitude_Of_Origin",45.58555555555556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNWabashaFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Wabasha_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Wabasha",DATUM["D_NAD_1983_HARN_Adj_MN_Wabasha",SPHEROID["S_GRS_1980_Adj_MN_Wabasha",6378426.561,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.26666666666667],PARAMETER["Standard_Parallel_1",44.15],PARAMETER["Standard_Parallel_2",44.41666666666666],PARAMETER["Latitude_Of_Origin",44.10694444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNWadenaFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Wadena_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Wadena",DATUM["D_NAD_1983_HARN_Adj_MN_Wadena",SPHEROID["S_GRS_1980_Adj_MN_Wadena",6378546.957,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.46666666666667],PARAMETER["Standard_Parallel_1",46.26666666666667],PARAMETER["Standard_Parallel_2",46.73333333333333],PARAMETER["Latitude_Of_Origin",46.15638888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNWasecaFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Waseca_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Waseca",DATUM["D_NAD_1983_HARN_Adj_MN_Waseca",SPHEROID["S_GRS_1980_Adj_MN_Waseca",6378481.425,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-92.91666666666667],PARAMETER["Standard_Parallel_1",43.88333333333333],PARAMETER["Standard_Parallel_2",44.13333333333333],PARAMETER["Latitude_Of_Origin",43.83388888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNWatonwanFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Watonwan_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Watonwan",DATUM["D_NAD_1983_HARN_Adj_MN_Watonwan",SPHEROID["S_GRS_1980_Adj_MN_Watonwan",6378514.953,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-94.91666666666667],PARAMETER["Standard_Parallel_1",43.9],PARAMETER["Standard_Parallel_2",44.16666666666666],PARAMETER["Latitude_Of_Origin",43.84805555555556],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNWinonaFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Winona_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Winona",DATUM["D_NAD_1983_HARN_Adj_MN_Winona",SPHEROID["S_GRS_1980_Adj_MN_Winona",6378453.688,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-91.61666666666666],PARAMETER["Standard_Parallel_1",43.9],PARAMETER["Standard_Parallel_2",44.13333333333333],PARAMETER["Latitude_Of_Origin",43.84722222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNWrightFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Wright_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Wright",DATUM["D_NAD_1983_HARN_Adj_MN_Wright",SPHEROID["S_GRS_1980_Adj_MN_Wright",6378443.325,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-93.88333333333334],PARAMETER["Standard_Parallel_1",45.03333333333333],PARAMETER["Standard_Parallel_2",45.46666666666667],PARAMETER["Latitude_Of_Origin",44.9775],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjMNYellowMedicineFeet",
    `PROJCS["NAD_1983_HARN_Adj_MN_Yellow_Medicine_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_MN_Yellow_Medicine",DATUM["D_NAD_1983_HARN_Adj_MN_Yellow_Medicine",SPHEROID["S_GRS_1980_Adj_MN_Yellow_Medicine",6378530.193,298.2572221008827]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",100000.0],PARAMETER["Central_Meridian",-95.9],PARAMETER["Standard_Parallel_1",44.66666666666666],PARAMETER["Standard_Parallel_2",44.95],PARAMETER["Latitude_Of_Origin",44.54166666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "MexicanDatum1993UTMZone11N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_11N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-117.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone12N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_12N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-111.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone13N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_13N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-105.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone14N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_14N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-99.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone15N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_15N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "MexicanDatum1993UTMZone16N",
    `PROJCS["Mexican_Datum_1993_UTM_Zone_16N",GEOGCS["GCS_Mexican_Datum_of_1993",DATUM["D_Mexican_Datum_of_1993",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIAdamsMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Adams_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Adams",DATUM["D_NAD_1983_HARN_Adj_WI_AD_JN",SPHEROID["GRS_1980_Adj_WI_AD_JN",6378376.271,298.268410995005]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",147218.6944373889],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.999999],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIAshlandMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Ashland_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Ashland",DATUM["D_NAD_1983_HARN_Adj_WI_AL",SPHEROID["GRS_1980_Adj_WI_AL",6378471.92,298.272883775229]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",172821.9456438913],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.62222222222222],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",45.70611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIBarronMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Barron_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Barron",DATUM["D_NAD_1983_HARN_Adj_WI_BA",SPHEROID["GRS_1980_Adj_WI_BA",6378472.931,298.272931052052]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",93150.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.85],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",45.13333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIBrownMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Brown_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Brown",DATUM["D_NAD_1983_HARN_Adj_WI_BR",SPHEROID["GRS_1980_Adj_WI_BR",6378137.0,298.257222100225]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",31599.99998984],PARAMETER["False_Northing",4599.98983997968],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIBuffaloMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Buffalo_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Buffalo",DATUM["D_NAD_1983_HARN_Adj_WI_BU",SPHEROID["GRS_1980_Adj_WI_BU",6378380.991,298.268631713702]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",175260.3505207011],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.79722222222222],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",43.48138888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWICalumetMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Calumet_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Calumet",DATUM["D_NAD_1983_HARN_Adj_WI_CL_FL_OG_WN",SPHEROID["GRS_1980_Adj_WI_CL_FL_OG_WN",6378345.09,298.266952895494]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",244754.8895097791],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIClarkMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Clark_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Clark",DATUM["D_NAD_1983_HARN_Adj_WI_CK",SPHEROID["GRS_1980_Adj_WI_CK",6378470.401,298.272812743089]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",199949.1998984],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.70833333333334],PARAMETER["Scale_Factor",0.999994],PARAMETER["Latitude_Of_Origin",43.6],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIDodgeMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Dodge_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Dodge",DATUM["D_NAD_1983_HARN_Adj_WI_DD_JF",SPHEROID["GRS_1980_Adj_WI_DD_JF",6378376.811,298.268436246721]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",263347.7266954534],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIDoorMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Door_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Door",DATUM["D_NAD_1983_HARN_Adj_WI_DR",SPHEROID["GRS_1980_Adj_WI_DR",6378313.92,298.26549531037]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",158801.1176022352],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.27222222222223],PARAMETER["Scale_Factor",0.999991],PARAMETER["Latitude_Of_Origin",44.4],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIDouglasMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Douglas_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Douglas",DATUM["D_NAD_1983_HARN_Adj_WI_DG",SPHEROID["GRS_1980_Adj_WI_DG",6378414.93,298.270218784012]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",59131.31826263653],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.91666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",45.88333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIDunnMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Dunn_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Dunn",DATUM["D_NAD_1983_HARN_Adj_WI_DU",SPHEROID["GRS_1980_Adj_WI_DU",6378413.021,298.270129514522]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",51816.10363220727],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.89444444444445],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",44.40833333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIFlorenceMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Florence_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Florence",DATUM["D_NAD_1983_HARN_Adj_WI_FN",SPHEROID["GRS_1980_Adj_WI_FN",6378530.851,298.275639532334]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",133502.667005334],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.14166666666668],PARAMETER["Scale_Factor",0.999993],PARAMETER["Latitude_Of_Origin",45.43888888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIFondduLacMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Fond_du_Lac_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_FondduLac",DATUM["D_NAD_1983_HARN_Adj_WI_CL_FL_OG_WN",SPHEROID["GRS_1980_Adj_WI_CL_FL_OG_WN",6378345.09,298.266952895494]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",244754.8895097791],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIForestMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Forest_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Forest",DATUM["D_NAD_1983_HARN_Adj_WI_FR",SPHEROID["GRS_1980_Adj_WI_FR",6378591.521,298.278476609315]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",275844.5516891034],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.63333333333334],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",44.00555555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIGrantMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Grant_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Grant",DATUM["D_NAD_1983_HARN_Adj_WI_GT",SPHEROID["GRS_1980_Adj_WI_GT",6378378.881,298.268533044963]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",242316.4846329693],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.8],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",41.41111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIIowaMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Iowa_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Iowa",DATUM["D_NAD_1983_HARN_Adj_WI_IA",SPHEROID["GRS_1980_Adj_WI_IA",6378408.041,298.269896637591]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",113081.0261620523],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16111111111111],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",42.53888888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIIronMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Iron_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Iron",DATUM["D_NAD_1983_HARN_Adj_WI_IR",SPHEROID["GRS_1980_Adj_WI_IR",6378655.071,298.281448362111]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",220980.4419608839],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.25555555555556],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",45.43333333333333],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIJeffersonMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Jefferson_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Jefferson",DATUM["D_NAD_1983_HARN_Adj_WI_DD_JF",SPHEROID["GRS_1980_Adj_WI_DD_JF",6378376.811,298.268436246721]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",263347.7266954534],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIJuneauMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Juneau_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Juneau",DATUM["D_NAD_1983_HARN_Adj_WI_AD_JN",SPHEROID["GRS_1980_Adj_WI_AD_JN",6378376.271,298.268410995005]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",147218.6944373889],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.999999],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIKenoshaMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Kenosha_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Kenosha",DATUM["D_NAD_1983_HARN_Adj_WI_KN_MW_OZ_RA",SPHEROID["GRS_1980_Adj_WI_KN_MW_OZ_RA",6378315.7,298.265578547505]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185928.3718567437],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIKewauneeMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Kewaunee_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Kewaunee",DATUM["D_NAD_1983_HARN_Adj_WI_KW_MT_SG",SPHEROID["GRS_1980_Adj_WI_KW_MT_SG",6378285.86,298.264183156421]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",79857.75971551944],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWILaCrosseMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_LaCrosse_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_LaCrosse",DATUM["D_NAD_1983_HARN_Adj_WI_LC",SPHEROID["GRS_1980_Adj_WI_LC",6378379.301,298.268552685186]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",130454.6609093218],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.31666666666666],PARAMETER["Scale_Factor",0.999994],PARAMETER["Latitude_Of_Origin",43.45111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWILincolnMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Lincoln_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Lincoln",DATUM["D_NAD_1983_HARN_Adj_WI_LN",SPHEROID["GRS_1980_Adj_WI_LN",6378531.821,298.275684891897]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",116129.0322580645],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.73333333333333],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",44.84444444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIManitowocMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Manitowoc_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Manitowoc",DATUM["D_NAD_1983_HARN_Adj_WI_KW_MT_SG",SPHEROID["GRS_1980_Adj_WI_KW_MT_SG",6378285.86,298.264183156421]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",79857.75971551944],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIMarinetteMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Marinette_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Marinette",DATUM["D_NAD_1983_HARN_Adj_WI_MN",SPHEROID["GRS_1980_Adj_WI_MN",6378376.041,298.268400239645]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",238658.8773177547],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.71111111111111],PARAMETER["Scale_Factor",0.999986],PARAMETER["Latitude_Of_Origin",44.69166666666666],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIMenomineeMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Menominee_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Menominee",DATUM["D_NAD_1983_HARN_Adj_WI_ME",SPHEROID["GRS_1980_Adj_WI_ME",6378406.601,298.269829299684]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",105461.0109220219],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.41666666666667],PARAMETER["Scale_Factor",0.999994],PARAMETER["Latitude_Of_Origin",44.71666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIMilwaukeeMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Milwaukee_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Milwaukee",DATUM["D_NAD_1983_HARN_Adj_WI_KN_MW_OZ_RA",SPHEROID["GRS_1980_Adj_WI_KN_MW_OZ_RA",6378315.7,298.265578547505]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185928.3718567437],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIOcontoMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Oconto_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Oconto",DATUM["D_NAD_1983_HARN_Adj_WI_OC",SPHEROID["GRS_1980_Adj_WI_OC",6378345.42,298.266968327098]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",182880.3657607315],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.90833333333335],PARAMETER["Scale_Factor",0.999991],PARAMETER["Latitude_Of_Origin",44.39722222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIOutagamieMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Outagamie_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Outagamie",DATUM["D_NAD_1983_HARN_Adj_WI_CL_FL_OG_WN",SPHEROID["GRS_1980_Adj_WI_CL_FL_OG_WN",6378345.09,298.266952895494]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",244754.8895097791],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIOzaukeeMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Ozaukee_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Ozaukee",DATUM["D_NAD_1983_HARN_Adj_WI_KN_MW_OZ_RA",SPHEROID["GRS_1980_Adj_WI_KN_MW_OZ_RA",6378315.7,298.265578547505]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185928.3718567437],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIPolkMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Polk_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Polk",DATUM["D_NAD_1983_HARN_Adj_WI_PK",SPHEROID["GRS_1980_Adj_WI_PK",6378413.671,298.270159910105]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",141732.283464567],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",44.66111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIPriceMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Price_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Price",DATUM["D_NAD_1983_HARN_Adj_WI_PR",SPHEROID["GRS_1980_Adj_WI_PR",6378563.891,298.277184563214]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",227990.855981712],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.48888888888889],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",44.55555555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIRacineMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Racine_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Racine",DATUM["D_NAD_1983_HARN_Adj_WI_KN_MW_OZ_RA",SPHEROID["GRS_1980_Adj_WI_KN_MW_OZ_RA",6378315.7,298.265578547505]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185928.3718567437],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIRockMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Rock_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Rock",DATUM["D_NAD_1983_HARN_Adj_WI_RK",SPHEROID["GRS_1980_Adj_WI_RK",6378377.671,298.268476462415]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",146304.2926085852],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.07222222222222],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",41.94444444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIRuskMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Rusk_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Rusk",DATUM["D_NAD_1983_HARN_Adj_WI_RS",SPHEROID["GRS_1980_Adj_WI_RS",6378472.751,298.272922634813]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",250546.1010922022],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.06666666666666],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",43.91944444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIStCroixMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_St_Croix_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_StCroix",DATUM["D_NAD_1983_HARN_Adj_WI_SC",SPHEROID["GRS_1980_Adj_WI_SC",6378412.511,298.270105665679]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",165506.731013462],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWISaukMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Sauk_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Sauk",DATUM["D_NAD_1983_HARN_Adj_WI_SK",SPHEROID["GRS_1980_Adj_WI_SK",6378407.281,298.26986109814]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185623.5712471425],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.9],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",42.81944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIShawanoMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Shawano_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Shawano",DATUM["D_NAD_1983_HARN_Adj_WI_SH",SPHEROID["GRS_1980_Adj_WI_SH",6378406.051,298.269803580344]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262433.3248666498],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.60555555555555],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWISheboyganMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Sheboygan_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Sheboygan",DATUM["D_NAD_1983_HARN_Adj_WI_KW_MT_SG",SPHEROID["GRS_1980_Adj_WI_KW_MT_SG",6378285.86,298.264183156421]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",79857.75971551944],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWITrempealeauMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Trempealeau_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Trempealeau",DATUM["D_NAD_1983_HARN_Adj_WI_TR",SPHEROID["GRS_1980_Adj_WI_TR",6378380.091,298.26858962751]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",256946.9138938278],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.36666666666666],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",43.16111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIWashingtonMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Washington_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Washington",DATUM["D_NAD_1983_HARN_Adj_WI_WA",SPHEROID["GRS_1980_Adj_WI_WA",6378407.141,298.269854551399]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",120091.4401828804],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.06388888888888],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",42.91805555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIWaukeshaMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Waukesha_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Waukesha",DATUM["D_NAD_1983_HARN_Adj_WI_WK",SPHEROID["GRS_1980_Adj_WI_WK",6378376.871,298.268439052467]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",208788.4175768352],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.225],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",42.56944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIWaupacaMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Waupaca_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Waupaca",DATUM["D_NAD_1983_HARN_Adj_WI_WP",SPHEROID["GRS_1980_Adj_WI_WP",6378375.251,298.268363297321]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185013.9700279401],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.81666666666666],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",43.42027777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIWinnebagoMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Winnebago_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Winnebago",DATUM["D_NAD_1983_HARN_Adj_WI_CL_FL_OG_WN",SPHEROID["GRS_1980_Adj_WI_CL_FL_OG_WN",6378345.09,298.266952895494]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",244754.8895097791],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIBayfieldMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Bayfield_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Bayfield",DATUM["D_NAD_1983_HARN_Adj_WI_BF",SPHEROID["GRS_1980_Adj_WI_BF",6378411.351,298.270051421254]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",228600.4572009144],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.15277777777779],PARAMETER["Standard_Parallel_1",46.41388888888888],PARAMETER["Standard_Parallel_2",46.925],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIBurnettMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Burnett_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Burnett",DATUM["D_NAD_1983_HARN_Adj_WI_BN",SPHEROID["GRS_1980_Adj_WI_BN",6378414.96,298.270220186885]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",64008.12801625604],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.45777777777778],PARAMETER["Standard_Parallel_1",45.71388888888889],PARAMETER["Standard_Parallel_2",46.08333333333334],PARAMETER["Latitude_Of_Origin",45.36388888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIChippewaMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Chippewa_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Chippewa",DATUM["D_NAD_1983_HARN_Adj_WI_CP",SPHEROID["GRS_1980_Adj_WI_CP",6378412.542,298.270107115315]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",60045.72009144019],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.29444444444444],PARAMETER["Standard_Parallel_1",44.81388888888888],PARAMETER["Standard_Parallel_2",45.14166666666667],PARAMETER["Latitude_Of_Origin",44.58111111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIColumbiaMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Columbia_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Columbia",DATUM["D_NAD_1983_HARN_Adj_WI_CO",SPHEROID["GRS_1980_Adj_WI_CO",6378376.331,298.268413800752]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",169164.3383286767],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.39444444444445],PARAMETER["Standard_Parallel_1",43.33333333333334],PARAMETER["Standard_Parallel_2",43.59166666666667],PARAMETER["Latitude_Of_Origin",42.45833333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWICrawfordMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Crawford_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Crawford",DATUM["D_NAD_1983_HARN_Adj_WI_CR",SPHEROID["GRS_1980_Adj_WI_CR",6378379.031,298.268540059328]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",113690.6273812548],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.9388888888889],PARAMETER["Standard_Parallel_1",43.05833333333333],PARAMETER["Standard_Parallel_2",43.34166666666667],PARAMETER["Latitude_Of_Origin",42.71666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIDaneMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Dane_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Dane",DATUM["D_NAD_1983_HARN_Adj_WI_DN",SPHEROID["GRS_1980_Adj_WI_DN",6378407.621,298.269876997368]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",247193.2943865888],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.42222222222223],PARAMETER["Standard_Parallel_1",42.90833333333333],PARAMETER["Standard_Parallel_2",43.23055555555555],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIEauClaireMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_EauClaire_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_EauClaire",DATUM["D_NAD_1983_HARN_Adj_WI_EC",SPHEROID["GRS_1980_Adj_WI_EC",6378380.381,298.268603188617]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120091.4401828804],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.28888888888889],PARAMETER["Standard_Parallel_1",44.73055555555555],PARAMETER["Standard_Parallel_2",45.01388888888889],PARAMETER["Latitude_Of_Origin",44.04722222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIGreenMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Green_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Green",DATUM["D_NAD_1983_HARN_Adj_WI_GR_LF",SPHEROID["GRS_1980_Adj_WI_GR_LF",6378408.481,298.269917213063]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",170078.7401574803],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.83888888888889],PARAMETER["Standard_Parallel_1",42.48611111111111],PARAMETER["Standard_Parallel_2",42.78888888888888],PARAMETER["Latitude_Of_Origin",42.225],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIGreenLakeMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_GreenLake_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_GreenLake",DATUM["D_NAD_1983_HARN_Adj_WI_GL_MQ",SPHEROID["GRS_1980_Adj_WI_GL_MQ",6378375.601,298.268379664173]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150876.3017526035],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.66666666666666],PARAMETER["Standard_Parallel_2",43.94722222222222],PARAMETER["Latitude_Of_Origin",43.09444444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIJacksonMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Jackson_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Jackson",DATUM["D_NAD_1983_HARN_Adj_WI_JA",SPHEROID["GRS_1980_Adj_WI_JA",6378409.151,298.269948543895]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",125882.6517653035],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.73888888888889],PARAMETER["Standard_Parallel_1",44.16388888888888],PARAMETER["Standard_Parallel_2",44.41944444444444],PARAMETER["Latitude_Of_Origin",43.79444444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWILafayetteMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Lafayette_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Lafayette",DATUM["D_NAD_1983_HARN_Adj_WI_GR_LF",SPHEROID["GRS_1980_Adj_WI_GR_LF",6378408.481,298.269917213063]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",170078.7401574803],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.83888888888889],PARAMETER["Standard_Parallel_1",42.48611111111111],PARAMETER["Standard_Parallel_2",42.78888888888888],PARAMETER["Latitude_Of_Origin",42.225],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWILangladeMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Langlade_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Langlade",DATUM["D_NAD_1983_HARN_Adj_WI_LG",SPHEROID["GRS_1980_Adj_WI_LG",6378560.121,298.277008268831]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",198425.1968503937],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.03333333333333],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",45.30833333333333],PARAMETER["Latitude_Of_Origin",44.20694444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIMarathonMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Marathon_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Marathon",DATUM["D_NAD_1983_HARN_Adj_WI_MA",SPHEROID["GRS_1980_Adj_WI_MA",6378500.6,298.274224921888]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",74676.14935229871],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.77],PARAMETER["Standard_Parallel_1",44.74527777777778],PARAMETER["Standard_Parallel_2",45.05638888888888],PARAMETER["Latitude_Of_Origin",44.40555555555555],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIMarquetteMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Marquette_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Marquette",DATUM["D_NAD_1983_HARN_Adj_WI_GL_MQ",SPHEROID["GRS_1980_Adj_WI_GL_MQ",6378375.601,298.268379664173]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",150876.3017526035],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.66666666666666],PARAMETER["Standard_Parallel_2",43.94722222222222],PARAMETER["Latitude_Of_Origin",43.09444444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIMonroeMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Monroe_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Monroe",DATUM["D_NAD_1983_HARN_Adj_WI_MR",SPHEROID["GRS_1980_Adj_WI_MR",6378438.991,298.27134393498]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",204521.2090424181],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.64166666666668],PARAMETER["Standard_Parallel_1",43.83888888888889],PARAMETER["Standard_Parallel_2",44.16111111111111],PARAMETER["Latitude_Of_Origin",42.90277777777778],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIOneidaMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Oneida_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Oneida",DATUM["D_NAD_1983_HARN_Adj_WI_ON",SPHEROID["GRS_1980_Adj_WI_ON",6378593.86,298.278585986653]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",70104.14020828043],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.54444444444444],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",45.84166666666667],PARAMETER["Latitude_Of_Origin",45.18611111111111],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIPepinMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Pepin_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Pepin",DATUM["D_NAD_1983_HARN_Adj_WI_PP_PC",SPHEROID["GRS_1980_Adj_WI_PP_PC",6378381.271,298.268644807185]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",167640.3352806706],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.52222222222222],PARAMETER["Standard_Parallel_2",44.75],PARAMETER["Latitude_Of_Origin",43.86194444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIPierceMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Pierce_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Pierce",DATUM["D_NAD_1983_HARN_Adj_WI_PP_PC",SPHEROID["GRS_1980_Adj_WI_PP_PC",6378381.271,298.268644807185]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",167640.3352806706],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.52222222222222],PARAMETER["Standard_Parallel_2",44.75],PARAMETER["Latitude_Of_Origin",43.86194444444445],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIPortageMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Portage_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Portage",DATUM["D_NAD_1983_HARN_Adj_WI_PT",SPHEROID["GRS_1980_Adj_WI_PT",6378344.377,298.266919538913]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",56388.11277622556],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.5],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",44.65],PARAMETER["Latitude_Of_Origin",43.96666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIRichlandMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Richland_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Richland",DATUM["D_NAD_1983_HARN_Adj_WI_RC",SPHEROID["GRS_1980_Adj_WI_RC",6378408.091,298.269898975713]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",202387.6047752096],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.43055555555556],PARAMETER["Standard_Parallel_1",43.14166666666667],PARAMETER["Standard_Parallel_2",43.50277777777778],PARAMETER["Latitude_Of_Origin",42.11388888888889],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWISawyerMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Sawyer_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Sawyer",DATUM["D_NAD_1983_HARN_Adj_WI_SW",SPHEROID["GRS_1980_Adj_WI_SW",6378534.451,298.275807877103]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",216713.2334264669],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.11666666666666],PARAMETER["Standard_Parallel_1",45.71944444444445],PARAMETER["Standard_Parallel_2",46.08055555555556],PARAMETER["Latitude_Of_Origin",44.81388888888888],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWITaylorMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Taylor_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Taylor",DATUM["D_NAD_1983_HARN_Adj_WI_TA",SPHEROID["GRS_1980_Adj_WI_TA",6378532.921,298.275736330576]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",187147.5742951486],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.48333333333333],PARAMETER["Standard_Parallel_1",45.05555555555555],PARAMETER["Standard_Parallel_2",45.3],PARAMETER["Latitude_Of_Origin",44.20833333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIVernonMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Vernon_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Vernon",DATUM["D_NAD_1983_HARN_Adj_WI_VR",SPHEROID["GRS_1980_Adj_WI_VR",6378408.941,298.269938723784]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",222504.44500889],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.78333333333333],PARAMETER["Standard_Parallel_1",43.46666666666667],PARAMETER["Standard_Parallel_2",43.68333333333333],PARAMETER["Latitude_Of_Origin",43.14722222222222],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIVilasMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Vilas_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Vilas",DATUM["D_NAD_1983_HARN_Adj_WI_VI",SPHEROID["GRS_1980_Adj_WI_VI",6378624.171,298.280003402845]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",134417.0688341377],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.48888888888889],PARAMETER["Standard_Parallel_1",45.93055555555555],PARAMETER["Standard_Parallel_2",46.225],PARAMETER["Latitude_Of_Origin",45.625],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIWalworthMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Walworth_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Walworth",DATUM["D_NAD_1983_HARN_Adj_WI_WW",SPHEROID["GRS_1980_Adj_WI_WW",6378377.411,298.268464304182]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",232562.8651257303],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.54166666666667],PARAMETER["Standard_Parallel_1",42.58888888888889],PARAMETER["Standard_Parallel_2",42.75],PARAMETER["Latitude_Of_Origin",41.66944444444444],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIWashburnMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Washburn_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Washburn",DATUM["D_NAD_1983_HARN_Adj_WI_WB",SPHEROID["GRS_1980_Adj_WI_WB",6378474.591,298.273008677695]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",234086.8681737364],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.78333333333333],PARAMETER["Standard_Parallel_1",45.77222222222222],PARAMETER["Standard_Parallel_2",46.15],PARAMETER["Latitude_Of_Origin",44.26666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIWausharaMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Waushara_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Waushara",DATUM["D_NAD_1983_HARN_Adj_WI_WS",SPHEROID["GRS_1980_Adj_WI_WS",6378405.971,298.269799839349]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",120091.4401828804],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.975],PARAMETER["Standard_Parallel_2",44.25277777777778],PARAMETER["Latitude_Of_Origin",43.70833333333334],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983HARNAdjWIWoodMeters",
    `PROJCS["NAD_1983_HARN_Adj_WI_Wood_Meters",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Wood",DATUM["D_NAD_1983_HARN_Adj_WI_WD",SPHEROID["GRS_1980_Adj_WI_WD",6378437.651,298.271281273316]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",208483.616967234],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.18055555555555],PARAMETER["Standard_Parallel_2",44.54444444444444],PARAMETER["Latitude_Of_Origin",43.15138888888889],UNIT["Meter",1.0]]`
  ],
  [
    "GDM2008LGM2012",
    `PROJCS["GDM2008_LGM2012",GEOGCS["GDM2008",DATUM["Geocentric_Datum_of_Mauritius_2008",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",250000.0],PARAMETER["False_Northing",350000.0],PARAMETER["Central_Meridian",57.56455662222222],PARAMETER["Standard_Parallel_1",-20.06111111111111],PARAMETER["Standard_Parallel_2",-20.44444444444445],PARAMETER["Latitude_Of_Origin",-20.2755163],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone15N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_15N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone16N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_16N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone17N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_17N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-81.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "NAD1983(CSRS)v6UTMZone18N",
    `PROJCS["NAD_1983_(CSRS)_v6_UTM_Zone_18N",GEOGCS["NAD83(CSRS)v6",DATUM["North_American_Datum_of_1983_(CSRS)_version_6",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-75.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Moon2000NorthPoleStereographic",
    `PROJCS["Moon_2000_North_Pole_Stereographic",GEOGCS["GCS_Moon_2000",DATUM["D_Moon_2000",SPHEROID["Moon_2000_IAU_IAG",1737400.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "Moon2000SouthPoleStereographic",
    `PROJCS["Moon_2000_South_Pole_Stereographic",GEOGCS["GCS_Moon_2000",DATUM["D_Moon_2000",SPHEROID["Moon_2000_IAU_IAG",1737400.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "Moon2000FarSideLambertAzimuthalEqualArea",
    `PROJCS["Moon_2000_Far_Side_Lambert_Azimuthal_Equal_Area",GEOGCS["GCS_Moon_2000",DATUM["D_Moon_2000",SPHEROID["Moon_2000_IAU_IAG",1737400.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",180.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Moon2000NearSideLambertAzimuthalEqualArea",
    `PROJCS["Moon_2000_Near_Side_Lambert_Azimuthal_Equal_Area",GEOGCS["GCS_Moon_2000",DATUM["D_Moon_2000",SPHEROID["Moon_2000_IAU_IAG",1737400.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Azimuthal_Equal_Area"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Moon2000EquidistantCylindrical",
    `PROJCS["Moon_2000_Equidistant_Cylindrical",GEOGCS["GCS_Moon_2000",DATUM["D_Moon_2000",SPHEROID["Moon_2000_IAU_IAG",1737400.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical_Ellipsoidal"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Moon2000Sinusoidal",
    `PROJCS["Moon_2000_Sinusoidal",GEOGCS["GCS_Moon_2000",DATUM["D_Moon_2000",SPHEROID["Moon_2000_IAU_IAG",1737400.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Sinusoidal"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Mars2000NorthPoleStereographicsphere",
    `PROJCS["Mars_2000_North_Pole_Stereographic_sphere",GEOGCS["Mars_2000_(Sphere)",DATUM["Mars_2000_(Sphere)",SPHEROID["Mars_2000_(Sphere)",3396190.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",90.0],UNIT["Meter",1.0]]`
  ],
  [
    "Mars2000SouthPoleStereographicsphere",
    `PROJCS["Mars_2000_South_Pole_Stereographic_sphere",GEOGCS["Mars_2000_(Sphere)",DATUM["Mars_2000_(Sphere)",SPHEROID["Mars_2000_(Sphere)",3396190.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Stereographic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",-90.0],UNIT["Meter",1.0]]`
  ],
  [
    "Mars2000EquidistantCylindricalsphere",
    `PROJCS["Mars_2000_Equidistant_Cylindrical_sphere",GEOGCS["Mars_2000_(Sphere)",DATUM["Mars_2000_(Sphere)",SPHEROID["Mars_2000_(Sphere)",3396190.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Equidistant_Cylindrical_Ellipsoidal"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Mars2000Sinusoidalsphere",
    `PROJCS["Mars_2000_Sinusoidal_sphere",GEOGCS["Mars_2000_(Sphere)",DATUM["Mars_2000_(Sphere)",SPHEROID["Mars_2000_(Sphere)",3396190.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Sinusoidal"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],UNIT["Meter",1.0]](lon)",east,ORDER[1]],AXIS["Latitude`
  ],
  [
    "Mars2000WinkelTripelsphere",
    `PROJCS["Mars_2000_Winkel_Tripel_sphere",GEOGCS["Mars_2000_(Sphere)",DATUM["Mars_2000_(Sphere)",SPHEROID["Mars_2000_(Sphere)",3396190.0,0.0]],PRIMEM["Reference_Meridian",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Winkel_Tripel"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",50.46666666666667],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo15(E-N)",
    `PROJCS["Hartebeesthoek94_Lo15_(E-N)",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo17(E-N)",
    `PROJCS["Hartebeesthoek94_Lo17_(E-N)",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",17.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "Hartebeesthoek94Lo33(E-N)",
    `PROJCS["Hartebeesthoek94_Lo33_(E-N)",GEOGCS["GCS_Hartebeesthoek_1994",DATUM["D_Hartebeesthoek_1994",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",33.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "CaliforniaSRSEpoch2017.50(NAD83)StatePlaneCAIUSFeet",
    `PROJCS["California_SRS_Epoch_2017.50_(NAD83)_StatePlane_CA_I_US_Feet",GEOGCS["California_SRS_Epoch_2017.50_(NAD83)",DATUM["California_SRS_Epoch_2017.50_(NAD83)",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",40.0],PARAMETER["Standard_Parallel_2",41.66666666666666],PARAMETER["Latitude_Of_Origin",39.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "CaliforniaSRSEpoch2017.50(NAD83)StatePlaneCAIIUSFeet",
    `PROJCS["California_SRS_Epoch_2017.50_(NAD83)_StatePlane_CA_II_US_Feet",GEOGCS["California_SRS_Epoch_2017.50_(NAD83)",DATUM["California_SRS_Epoch_2017.50_(NAD83)",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-122.0],PARAMETER["Standard_Parallel_1",38.33333333333334],PARAMETER["Standard_Parallel_2",39.83333333333334],PARAMETER["Latitude_Of_Origin",37.66666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "CaliforniaSRSEpoch2017.50(NAD83)StatePlaneCAIIIUSFeet",
    `PROJCS["California_SRS_Epoch_2017.50_(NAD83)_StatePlane_CA_III_US_Feet",GEOGCS["California_SRS_Epoch_2017.50_(NAD83)",DATUM["California_SRS_Epoch_2017.50_(NAD83)",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-120.5],PARAMETER["Standard_Parallel_1",37.06666666666667],PARAMETER["Standard_Parallel_2",38.43333333333333],PARAMETER["Latitude_Of_Origin",36.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "CaliforniaSRSEpoch2017.50(NAD83)StatePlaneCAIVUSFeet",
    `PROJCS["California_SRS_Epoch_2017.50_(NAD83)_StatePlane_CA_IV_US_Feet",GEOGCS["California_SRS_Epoch_2017.50_(NAD83)",DATUM["California_SRS_Epoch_2017.50_(NAD83)",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-119.0],PARAMETER["Standard_Parallel_1",36.0],PARAMETER["Standard_Parallel_2",37.25],PARAMETER["Latitude_Of_Origin",35.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "CaliforniaSRSEpoch2017.50(NAD83)StatePlaneCAVUSFeet",
    `PROJCS["California_SRS_Epoch_2017.50_(NAD83)_StatePlane_CA_V_US_Feet",GEOGCS["California_SRS_Epoch_2017.50_(NAD83)",DATUM["California_SRS_Epoch_2017.50_(NAD83)",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-118.0],PARAMETER["Standard_Parallel_1",34.03333333333333],PARAMETER["Standard_Parallel_2",35.46666666666667],PARAMETER["Latitude_Of_Origin",33.5],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "CaliforniaSRSEpoch2017.50(NAD83)StatePlaneCAVIUSFeet",
    `PROJCS["California_SRS_Epoch_2017.50_(NAD83)_StatePlane_CA_VI_US_Feet",GEOGCS["California_SRS_Epoch_2017.50_(NAD83)",DATUM["California_SRS_Epoch_2017.50_(NAD83)",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",6561666.666666666],PARAMETER["False_Northing",1640416.666666667],PARAMETER["Central_Meridian",-116.25],PARAMETER["Standard_Parallel_1",32.78333333333333],PARAMETER["Standard_Parallel_2",33.88333333333333],PARAMETER["Latitude_Of_Origin",32.16666666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD19832011NebraskaLDPDouglas-SarpyCountyFtIntl",
    `PROJCS["NAD_1983_2011_Nebraska_LDP_Douglas-Sarpy_County_Ft_Intl",GEOGCS["GCS_NAD_1983_2011",DATUM["D_NAD_1983_2011",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",131233.5958005249],PARAMETER["False_Northing",82020.99737532808],PARAMETER["Central_Meridian",-96.05],PARAMETER["Scale_Factor",1.0000482],PARAMETER["Latitude_Of_Origin",41.18333333333333],UNIT["Foot",0.3048]]`
  ],
  [
    "NAD1983HARNAdjWIAdamsFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Adams_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Adams",DATUM["D_NAD_1983_HARN_Adj_WI_AD_JN",SPHEROID["GRS_1980_Adj_WI_AD_JN",6378376.271,298.268410995005]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",483000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.999999],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIAshlandFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Ashland_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Ashland",DATUM["D_NAD_1983_HARN_Adj_WI_AL",SPHEROID["GRS_1980_Adj_WI_AL",6378471.92,298.272883775229]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",567000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.62222222222222],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",45.70611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIBarronFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Barron_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Barron",DATUM["D_NAD_1983_HARN_Adj_WI_BA",SPHEROID["GRS_1980_Adj_WI_BA",6378472.931,298.272931052052]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",305609.625],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.85],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",45.13333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIBrownFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Brown_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Brown",DATUM["D_NAD_1983_HARN_Adj_WI_BR",SPHEROID["GRS_1980_Adj_WI_BR",6378137.0,298.257222100225]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",103674.3333],PARAMETER["False_Northing",15091.8],PARAMETER["Central_Meridian",-88.0],PARAMETER["Scale_Factor",1.00002],PARAMETER["Latitude_Of_Origin",43.0],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIBuffaloFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Buffalo_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Buffalo",DATUM["D_NAD_1983_HARN_Adj_WI_BU",SPHEROID["GRS_1980_Adj_WI_BU",6378380.991,298.268631713702]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",575000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.79722222222222],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",43.48138888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWICalumetFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Calumet_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Calumet",DATUM["D_NAD_1983_HARN_Adj_WI_CL_FL_OG_WN",SPHEROID["GRS_1980_Adj_WI_CL_FL_OG_WN",6378345.09,298.266952895494]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",803000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIClarkFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Clark_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Clark",DATUM["D_NAD_1983_HARN_Adj_WI_CK",SPHEROID["GRS_1980_Adj_WI_CK",6378470.401,298.272812743089]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",656000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.70833333333334],PARAMETER["Scale_Factor",0.999994],PARAMETER["Latitude_Of_Origin",43.6],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIDodgeFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Dodge_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Dodge",DATUM["D_NAD_1983_HARN_Adj_WI_DD_JF",SPHEROID["GRS_1980_Adj_WI_DD_JF",6378376.811,298.268436246721]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",864000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIDoorFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Door_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Door",DATUM["D_NAD_1983_HARN_Adj_WI_DR",SPHEROID["GRS_1980_Adj_WI_DR",6378313.92,298.26549531037]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",521000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.27222222222223],PARAMETER["Scale_Factor",0.999991],PARAMETER["Latitude_Of_Origin",44.4],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIDouglasFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Douglas_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Douglas",DATUM["D_NAD_1983_HARN_Adj_WI_DG",SPHEROID["GRS_1980_Adj_WI_DG",6378414.93,298.270218784012]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",194000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.91666666666667],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",45.88333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIDunnFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Dunn_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Dunn",DATUM["D_NAD_1983_HARN_Adj_WI_DU",SPHEROID["GRS_1980_Adj_WI_DU",6378413.021,298.270129514522]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",170000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.89444444444445],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",44.40833333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIFlorenceFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Florence_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Florence",DATUM["D_NAD_1983_HARN_Adj_WI_FN",SPHEROID["GRS_1980_Adj_WI_FN",6378530.851,298.275639532334]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",438000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.14166666666668],PARAMETER["Scale_Factor",0.999993],PARAMETER["Latitude_Of_Origin",45.43888888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIFondduLacFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Fond_du_Lac_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_FondduLac",DATUM["D_NAD_1983_HARN_Adj_WI_CL_FL_OG_WN",SPHEROID["GRS_1980_Adj_WI_CL_FL_OG_WN",6378345.09,298.266952895494]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",803000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIForestFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Forest_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Forest",DATUM["D_NAD_1983_HARN_Adj_WI_FR",SPHEROID["GRS_1980_Adj_WI_FR",6378591.521,298.278476609315]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",905000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.63333333333334],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",44.00555555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIGrantFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Grant_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Grant",DATUM["D_NAD_1983_HARN_Adj_WI_GT",SPHEROID["GRS_1980_Adj_WI_GT",6378378.881,298.268533044963]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",795000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.8],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",41.41111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIIowaFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Iowa_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Iowa",DATUM["D_NAD_1983_HARN_Adj_WI_IA",SPHEROID["GRS_1980_Adj_WI_IA",6378408.041,298.269896637591]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",371000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.16111111111111],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",42.53888888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIIronFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Iron_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Iron",DATUM["D_NAD_1983_HARN_Adj_WI_IR",SPHEROID["GRS_1980_Adj_WI_IR",6378655.071,298.281448362111]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",725000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.25555555555556],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",45.43333333333333],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIJeffersonFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Jefferson_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Jefferson",DATUM["D_NAD_1983_HARN_Adj_WI_DD_JF",SPHEROID["GRS_1980_Adj_WI_DD_JF",6378376.811,298.268436246721]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",864000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.775],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",41.47222222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIJuneauFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Juneau_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Juneau",DATUM["D_NAD_1983_HARN_Adj_WI_AD_JN",SPHEROID["GRS_1980_Adj_WI_AD_JN",6378376.271,298.268410995005]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",483000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Scale_Factor",0.999999],PARAMETER["Latitude_Of_Origin",43.36666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIKenoshaFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Kenosha_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Kenosha",DATUM["D_NAD_1983_HARN_Adj_WI_KN_MW_OZ_RA",SPHEROID["GRS_1980_Adj_WI_KN_MW_OZ_RA",6378315.7,298.265578547505]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",610000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIKewauneeFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Kewaunee_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Kewaunee",DATUM["D_NAD_1983_HARN_Adj_WI_KW_MT_SG",SPHEROID["GRS_1980_Adj_WI_KW_MT_SG",6378285.86,298.264183156421]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWILaCrosseFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_LaCrosse_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_LaCrosse",DATUM["D_NAD_1983_HARN_Adj_WI_LC",SPHEROID["GRS_1980_Adj_WI_LC",6378379.301,298.268552685186]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",428000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.31666666666666],PARAMETER["Scale_Factor",0.999994],PARAMETER["Latitude_Of_Origin",43.45111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWILincolnFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Lincoln_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Lincoln",DATUM["D_NAD_1983_HARN_Adj_WI_LN",SPHEROID["GRS_1980_Adj_WI_LN",6378531.821,298.275684891897]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",381000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.73333333333333],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",44.84444444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIManitowocFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Manitowoc_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Manitowoc",DATUM["D_NAD_1983_HARN_Adj_WI_KW_MT_SG",SPHEROID["GRS_1980_Adj_WI_KW_MT_SG",6378285.86,298.264183156421]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIMarinetteFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Marinette_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Marinette",DATUM["D_NAD_1983_HARN_Adj_WI_MN",SPHEROID["GRS_1980_Adj_WI_MN",6378376.041,298.268400239645]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",783000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.71111111111111],PARAMETER["Scale_Factor",0.999986],PARAMETER["Latitude_Of_Origin",44.69166666666666],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIMenomineeFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Menominee_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Menominee",DATUM["D_NAD_1983_HARN_Adj_WI_ME",SPHEROID["GRS_1980_Adj_WI_ME",6378406.601,298.269829299684]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",346000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.41666666666667],PARAMETER["Scale_Factor",0.999994],PARAMETER["Latitude_Of_Origin",44.71666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIMilwaukeeFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Milwaukee_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Milwaukee",DATUM["D_NAD_1983_HARN_Adj_WI_KN_MW_OZ_RA",SPHEROID["GRS_1980_Adj_WI_KN_MW_OZ_RA",6378315.7,298.265578547505]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",610000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIOcontoFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Oconto_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Oconto",DATUM["D_NAD_1983_HARN_Adj_WI_OC",SPHEROID["GRS_1980_Adj_WI_OC",6378345.42,298.266968327098]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",600000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.90833333333335],PARAMETER["Scale_Factor",0.999991],PARAMETER["Latitude_Of_Origin",44.39722222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIOutagamieFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Outagamie_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Outagamie",DATUM["D_NAD_1983_HARN_Adj_WI_CL_FL_OG_WN",SPHEROID["GRS_1980_Adj_WI_CL_FL_OG_WN",6378345.09,298.266952895494]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",803000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIOzaukeeFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Ozaukee_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Ozaukee",DATUM["D_NAD_1983_HARN_Adj_WI_KN_MW_OZ_RA",SPHEROID["GRS_1980_Adj_WI_KN_MW_OZ_RA",6378315.7,298.265578547505]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",610000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIPolkFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Polk_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Polk",DATUM["D_NAD_1983_HARN_Adj_WI_PK",SPHEROID["GRS_1980_Adj_WI_PK",6378413.671,298.270159910105]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",465000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",44.66111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIPriceFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Price_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Price",DATUM["D_NAD_1983_HARN_Adj_WI_PR",SPHEROID["GRS_1980_Adj_WI_PR",6378563.891,298.277184563214]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",748000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.48888888888889],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",44.55555555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIRacineFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Racine_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Racine",DATUM["D_NAD_1983_HARN_Adj_WI_KN_MW_OZ_RA",SPHEROID["GRS_1980_Adj_WI_KN_MW_OZ_RA",6378315.7,298.265578547505]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",610000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.89444444444445],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",42.21666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIRockFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Rock_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Rock",DATUM["D_NAD_1983_HARN_Adj_WI_RK",SPHEROID["GRS_1980_Adj_WI_RK",6378377.671,298.268476462415]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",480000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.07222222222222],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",41.94444444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIRuskFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Rusk_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Rusk",DATUM["D_NAD_1983_HARN_Adj_WI_RS",SPHEROID["GRS_1980_Adj_WI_RS",6378472.751,298.272922634813]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",822000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.06666666666666],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",43.91944444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIStCroixFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_St_Croix_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_StCroix",DATUM["D_NAD_1983_HARN_Adj_WI_SC",SPHEROID["GRS_1980_Adj_WI_SC",6378412.511,298.270105665679]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",543000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.63333333333334],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWISaukFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Sauk_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Sauk",DATUM["D_NAD_1983_HARN_Adj_WI_SK",SPHEROID["GRS_1980_Adj_WI_SK",6378407.281,298.26986109814]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",609000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.9],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",42.81944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIShawanoFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Shawano_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Shawano",DATUM["D_NAD_1983_HARN_Adj_WI_SH",SPHEROID["GRS_1980_Adj_WI_SH",6378406.051,298.269803580344]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",861000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.60555555555555],PARAMETER["Scale_Factor",0.99999],PARAMETER["Latitude_Of_Origin",44.03611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWISheboyganFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Sheboygan_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Sheboygan",DATUM["D_NAD_1983_HARN_Adj_WI_KW_MT_SG",SPHEROID["GRS_1980_Adj_WI_KW_MT_SG",6378285.86,298.264183156421]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",262000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-87.55],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",43.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWITrempealeauFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Trempealeau_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Trempealeau",DATUM["D_NAD_1983_HARN_Adj_WI_TR",SPHEROID["GRS_1980_Adj_WI_TR",6378380.091,298.26858962751]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",843000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.36666666666666],PARAMETER["Scale_Factor",0.999998],PARAMETER["Latitude_Of_Origin",43.16111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIWashingtonFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Washington_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Washington",DATUM["D_NAD_1983_HARN_Adj_WI_WA",SPHEROID["GRS_1980_Adj_WI_WA",6378407.141,298.269854551399]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",394000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.06388888888888],PARAMETER["Scale_Factor",0.999995],PARAMETER["Latitude_Of_Origin",42.91805555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIWaukeshaFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Waukesha_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Waukesha",DATUM["D_NAD_1983_HARN_Adj_WI_WK",SPHEROID["GRS_1980_Adj_WI_WK",6378376.871,298.268439052467]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",685000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.225],PARAMETER["Scale_Factor",0.999997],PARAMETER["Latitude_Of_Origin",42.56944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIWaupacaFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Waupaca_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Waupaca",DATUM["D_NAD_1983_HARN_Adj_WI_WP",SPHEROID["GRS_1980_Adj_WI_WP",6378375.251,298.268363297321]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",607000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.81666666666666],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",43.42027777777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIWinnebagoFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Winnebago_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Winnebago",DATUM["D_NAD_1983_HARN_Adj_WI_CL_FL_OG_WN",SPHEROID["GRS_1980_Adj_WI_CL_FL_OG_WN",6378345.09,298.266952895494]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",803000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.5],PARAMETER["Scale_Factor",0.999996],PARAMETER["Latitude_Of_Origin",42.71944444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIBayfieldFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Bayfield_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Bayfield",DATUM["D_NAD_1983_HARN_Adj_WI_BF",SPHEROID["GRS_1980_Adj_WI_BF",6378411.351,298.270051421254]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",750000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.15277777777779],PARAMETER["Standard_Parallel_1",46.41388888888888],PARAMETER["Standard_Parallel_2",46.925],PARAMETER["Latitude_Of_Origin",45.33333333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIBurnettFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Burnett_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Burnett",DATUM["D_NAD_1983_HARN_Adj_WI_BN",SPHEROID["GRS_1980_Adj_WI_BN",6378414.96,298.270220186885]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",210000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.45777777777778],PARAMETER["Standard_Parallel_1",45.71388888888889],PARAMETER["Standard_Parallel_2",46.08333333333334],PARAMETER["Latitude_Of_Origin",45.36388888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIChippewaFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Chippewa_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Chippewa",DATUM["D_NAD_1983_HARN_Adj_WI_CP",SPHEROID["GRS_1980_Adj_WI_CP",6378412.542,298.270107115315]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",197000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.29444444444444],PARAMETER["Standard_Parallel_1",44.81388888888888],PARAMETER["Standard_Parallel_2",45.14166666666667],PARAMETER["Latitude_Of_Origin",44.58111111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIColumbiaFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Columbia_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Columbia",DATUM["D_NAD_1983_HARN_Adj_WI_CO",SPHEROID["GRS_1980_Adj_WI_CO",6378376.331,298.268413800752]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",555000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.39444444444445],PARAMETER["Standard_Parallel_1",43.33333333333334],PARAMETER["Standard_Parallel_2",43.59166666666667],PARAMETER["Latitude_Of_Origin",42.45833333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWICrawfordFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Crawford_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Crawford",DATUM["D_NAD_1983_HARN_Adj_WI_CR",SPHEROID["GRS_1980_Adj_WI_CR",6378379.031,298.268540059328]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",373000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.9388888888889],PARAMETER["Standard_Parallel_1",43.05833333333333],PARAMETER["Standard_Parallel_2",43.34166666666667],PARAMETER["Latitude_Of_Origin",42.71666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIDaneFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Dane_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Dane",DATUM["D_NAD_1983_HARN_Adj_WI_DN",SPHEROID["GRS_1980_Adj_WI_DN",6378407.621,298.269876997368]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",811000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.42222222222223],PARAMETER["Standard_Parallel_1",42.90833333333333],PARAMETER["Standard_Parallel_2",43.23055555555555],PARAMETER["Latitude_Of_Origin",41.75],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIEauClaireFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_EauClaire_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_EauClaire",DATUM["D_NAD_1983_HARN_Adj_WI_EC",SPHEROID["GRS_1980_Adj_WI_EC",6378380.381,298.268603188617]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",394000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.28888888888889],PARAMETER["Standard_Parallel_1",44.73055555555555],PARAMETER["Standard_Parallel_2",45.01388888888889],PARAMETER["Latitude_Of_Origin",44.04722222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIGreenFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Green_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Green",DATUM["D_NAD_1983_HARN_Adj_WI_GR_LF",SPHEROID["GRS_1980_Adj_WI_GR_LF",6378408.481,298.269917213063]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",558000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.83888888888889],PARAMETER["Standard_Parallel_1",42.48611111111111],PARAMETER["Standard_Parallel_2",42.78888888888888],PARAMETER["Latitude_Of_Origin",42.225],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIGreenLakeFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_GreenLake_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_GreenLake",DATUM["D_NAD_1983_HARN_Adj_WI_GL_MQ",SPHEROID["GRS_1980_Adj_WI_GL_MQ",6378375.601,298.268379664173]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",495000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.66666666666666],PARAMETER["Standard_Parallel_2",43.94722222222222],PARAMETER["Latitude_Of_Origin",43.09444444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIJacksonFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Jackson_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Jackson",DATUM["D_NAD_1983_HARN_Adj_WI_JA",SPHEROID["GRS_1980_Adj_WI_JA",6378409.151,298.269948543895]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",413000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.73888888888889],PARAMETER["Standard_Parallel_1",44.16388888888888],PARAMETER["Standard_Parallel_2",44.41944444444444],PARAMETER["Latitude_Of_Origin",43.79444444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWILafayetteFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Lafayette_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Lafayette",DATUM["D_NAD_1983_HARN_Adj_WI_GR_LF",SPHEROID["GRS_1980_Adj_WI_GR_LF",6378408.481,298.269917213063]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",558000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.83888888888889],PARAMETER["Standard_Parallel_1",42.48611111111111],PARAMETER["Standard_Parallel_2",42.78888888888888],PARAMETER["Latitude_Of_Origin",42.225],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWILangladeFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Langlade_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Langlade",DATUM["D_NAD_1983_HARN_Adj_WI_LG",SPHEROID["GRS_1980_Adj_WI_LG",6378560.121,298.277008268831]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",651000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.03333333333333],PARAMETER["Standard_Parallel_1",45.0],PARAMETER["Standard_Parallel_2",45.30833333333333],PARAMETER["Latitude_Of_Origin",44.20694444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIMarathonFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Marathon_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Marathon",DATUM["D_NAD_1983_HARN_Adj_WI_MA",SPHEROID["GRS_1980_Adj_WI_MA",6378500.6,298.274224921888]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",245000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.77],PARAMETER["Standard_Parallel_1",44.74527777777778],PARAMETER["Standard_Parallel_2",45.05638888888888],PARAMETER["Latitude_Of_Origin",44.40555555555555],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIMarquetteFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Marquette_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Marquette",DATUM["D_NAD_1983_HARN_Adj_WI_GL_MQ",SPHEROID["GRS_1980_Adj_WI_GL_MQ",6378375.601,298.268379664173]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",495000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.66666666666666],PARAMETER["Standard_Parallel_2",43.94722222222222],PARAMETER["Latitude_Of_Origin",43.09444444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIMonroeFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Monroe_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Monroe",DATUM["D_NAD_1983_HARN_Adj_WI_MR",SPHEROID["GRS_1980_Adj_WI_MR",6378438.991,298.27134393498]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",671000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.64166666666668],PARAMETER["Standard_Parallel_1",43.83888888888889],PARAMETER["Standard_Parallel_2",44.16111111111111],PARAMETER["Latitude_Of_Origin",42.90277777777778],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIOneidaFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Oneida_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Oneida",DATUM["D_NAD_1983_HARN_Adj_WI_ON",SPHEROID["GRS_1980_Adj_WI_ON",6378593.86,298.278585986653]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",230000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.54444444444444],PARAMETER["Standard_Parallel_1",45.56666666666667],PARAMETER["Standard_Parallel_2",45.84166666666667],PARAMETER["Latitude_Of_Origin",45.18611111111111],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIPepinFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Pepin_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Pepin",DATUM["D_NAD_1983_HARN_Adj_WI_PP_PC",SPHEROID["GRS_1980_Adj_WI_PP_PC",6378381.271,298.268644807185]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",550000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.52222222222222],PARAMETER["Standard_Parallel_2",44.75],PARAMETER["Latitude_Of_Origin",43.86194444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIPierceFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Pierce_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Pierce",DATUM["D_NAD_1983_HARN_Adj_WI_PP_PC",SPHEROID["GRS_1980_Adj_WI_PP_PC",6378381.271,298.268644807185]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",550000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-92.22777777777777],PARAMETER["Standard_Parallel_1",44.52222222222222],PARAMETER["Standard_Parallel_2",44.75],PARAMETER["Latitude_Of_Origin",43.86194444444445],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIPortageFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Portage_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Portage",DATUM["D_NAD_1983_HARN_Adj_WI_PT",SPHEROID["GRS_1980_Adj_WI_PT",6378344.377,298.266919538913]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",185000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.5],PARAMETER["Standard_Parallel_1",44.18333333333333],PARAMETER["Standard_Parallel_2",44.65],PARAMETER["Latitude_Of_Origin",43.96666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIRichlandFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Richland_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Richland",DATUM["D_NAD_1983_HARN_Adj_WI_RC",SPHEROID["GRS_1980_Adj_WI_RC",6378408.091,298.269898975713]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",664000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.43055555555556],PARAMETER["Standard_Parallel_1",43.14166666666667],PARAMETER["Standard_Parallel_2",43.50277777777778],PARAMETER["Latitude_Of_Origin",42.11388888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWISawyerFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Sawyer_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Sawyer",DATUM["D_NAD_1983_HARN_Adj_WI_SW",SPHEROID["GRS_1980_Adj_WI_SW",6378534.451,298.275807877103]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",711000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.11666666666666],PARAMETER["Standard_Parallel_1",45.71944444444445],PARAMETER["Standard_Parallel_2",46.08055555555556],PARAMETER["Latitude_Of_Origin",44.81388888888888],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWITaylorFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Taylor_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Taylor",DATUM["D_NAD_1983_HARN_Adj_WI_TA",SPHEROID["GRS_1980_Adj_WI_TA",6378532.921,298.275736330576]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",614000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.48333333333333],PARAMETER["Standard_Parallel_1",45.05555555555555],PARAMETER["Standard_Parallel_2",45.3],PARAMETER["Latitude_Of_Origin",44.20833333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIVernonFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Vernon_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Vernon",DATUM["D_NAD_1983_HARN_Adj_WI_VR",SPHEROID["GRS_1980_Adj_WI_VR",6378408.941,298.269938723784]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",730000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.78333333333333],PARAMETER["Standard_Parallel_1",43.46666666666667],PARAMETER["Standard_Parallel_2",43.68333333333333],PARAMETER["Latitude_Of_Origin",43.14722222222222],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIVilasFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Vilas_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Vilas",DATUM["D_NAD_1983_HARN_Adj_WI_VI",SPHEROID["GRS_1980_Adj_WI_VI",6378624.171,298.280003402845]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",441000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.48888888888889],PARAMETER["Standard_Parallel_1",45.93055555555555],PARAMETER["Standard_Parallel_2",46.225],PARAMETER["Latitude_Of_Origin",45.625],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIWalworthFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Walworth_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Walworth",DATUM["D_NAD_1983_HARN_Adj_WI_WW",SPHEROID["GRS_1980_Adj_WI_WW",6378377.411,298.268464304182]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",763000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-88.54166666666667],PARAMETER["Standard_Parallel_1",42.58888888888889],PARAMETER["Standard_Parallel_2",42.75],PARAMETER["Latitude_Of_Origin",41.66944444444444],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIWashburnFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Washburn_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Washburn",DATUM["D_NAD_1983_HARN_Adj_WI_WB",SPHEROID["GRS_1980_Adj_WI_WB",6378474.591,298.273008677695]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",768000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-91.78333333333333],PARAMETER["Standard_Parallel_1",45.77222222222222],PARAMETER["Standard_Parallel_2",46.15],PARAMETER["Latitude_Of_Origin",44.26666666666667],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIWausharaFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Waushara_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Waushara",DATUM["D_NAD_1983_HARN_Adj_WI_WS",SPHEROID["GRS_1980_Adj_WI_WS",6378405.971,298.269799839349]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",394000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-89.24166666666667],PARAMETER["Standard_Parallel_1",43.975],PARAMETER["Standard_Parallel_2",44.25277777777778],PARAMETER["Latitude_Of_Origin",43.70833333333334],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "NAD1983HARNAdjWIWoodFeet",
    `PROJCS["NAD_1983_HARN_Adj_WI_Wood_Feet",GEOGCS["GCS_NAD_1983_HARN_Adj_WI_Wood",DATUM["D_NAD_1983_HARN_Adj_WI_WD",SPHEROID["GRS_1980_Adj_WI_WD",6378437.651,298.271281273316]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",684000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-90.0],PARAMETER["Standard_Parallel_1",44.18055555555555],PARAMETER["Standard_Parallel_2",44.54444444444444],PARAMETER["Latitude_Of_Origin",43.15138888888889],UNIT["Foot_US",0.3048006096012192]]`
  ],
  [
    "GermanyZone1",
    `PROJCS["Germany_Zone_1",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",1500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",3.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GermanyZone2",
    `PROJCS["Germany_Zone_2",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",2500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",6.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GermanyZone3",
    `PROJCS["Germany_Zone_3",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",3500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",9.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GermanyZone4",
    `PROJCS["Germany_Zone_4",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",4500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "GermanyZone5",
    `PROJCS["Germany_Zone_5",GEOGCS["GCS_Deutsches_Hauptdreiecksnetz",DATUM["D_Deutsches_Hauptdreiecksnetz",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",5500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",15.0],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "WGS1984UTMGTM2010",
    `PROJCS["WGS_1984_UTM_GTM_2010",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",500000.0],PARAMETER["Central_Meridian",12.0],PARAMETER["Scale_Factor",0.9996],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA01H1",
    `PROJCS["OSGB36_National_Highways_A01H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",261910.5587031571],PARAMETER["False_Northing",70975.76209131356],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9992600288218829],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA02H1",
    `PROJCS["OSGB36_National_Highways_A02H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",252927.2843542255],PARAMETER["False_Northing",70979.593633004],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9993139726802819],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA03H1",
    `PROJCS["OSGB36_National_Highways_A03H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",243942.3084033331],PARAMETER["False_Northing",70983.2126911338],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9993649250057596],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA03H2",
    `PROJCS["OSGB36_National_Highways_A03H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",243948.4071897476],PARAMETER["False_Northing",70984.98733800034],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9993899100658244],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA04H1",
    `PROJCS["OSGB36_National_Highways_A04H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",234956.1812566491],PARAMETER["False_Northing",70986.7611456259],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999414883310307],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA05H1",
    `PROJCS["OSGB36_National_Highways_A05H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",225969.1556311239],PARAMETER["False_Northing",70990.3099549106],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9994648466099409],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA05H2",
    `PROJCS["OSGB36_National_Highways_A05H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",225974.8050718687],PARAMETER["False_Northing",70992.08477921539],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9994898341681397],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA06H1",
    `PROJCS["OSGB36_National_Highways_A06H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",215981.5338434338],PARAMETER["False_Northing",70993.93010594351],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9995158143222824],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA06H2",
    `PROJCS["OSGB36_National_Highways_A06H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",215986.9335842702],PARAMETER["False_Northing",70995.70502075547],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9995408031547217],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA07H1",
    `PROJCS["OSGB36_National_Highways_A07H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",205992.275418417],PARAMETER["False_Northing",70997.33764421167],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9995637886826517],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA07H2",
    `PROJCS["OSGB36_National_Highways_A07H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",205997.4254184274],PARAMETER["False_Northing",70999.11264421527],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999588778714495],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA08H1",
    `PROJCS["OSGB36_National_Highways_A08H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",196002.2540283714],PARAMETER["False_Northing",71000.81651027738],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996127672593187],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA08H2",
    `PROJCS["OSGB36_National_Highways_A08H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",196007.1542684811],PARAMETER["False_Northing",71002.5915972559],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996377585156724],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA09H1",
    `PROJCS["OSGB36_National_Highways_A09H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185011.1930661738],PARAMETER["False_Northing",71004.29571728832],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996617506361164],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA09H2",
    `PROJCS["OSGB36_National_Highways_A09H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",185015.8185194549],PARAMETER["False_Northing",71006.07089125027],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996867431171],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA10H1",
    `PROJCS["OSGB36_National_Highways_A10H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",173019.2914368838],PARAMETER["False_Northing",71007.91729490607],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9997127384331908],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA10H2",
    `PROJCS["OSGB36_National_Highways_A10H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",173023.6170813814],PARAMETER["False_Northing",71009.69255941088],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9997377321889175],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA11H1",
    `PROJCS["OSGB36_National_Highways_A11H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",159026.3185515824],PARAMETER["False_Northing",71011.75230919718],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9997667311819567],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA11H2",
    `PROJCS["OSGB36_National_Highways_A11H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",159030.294358639],PARAMETER["False_Northing",71013.52766958093],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9997917262875528],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA11H3",
    `PROJCS["OSGB36_National_Highways_A11H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",159034.2703644983],PARAMETER["False_Northing",71015.30311873824],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999816722642982],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA12H1",
    `PROJCS["OSGB36_National_Highways_A12H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",144031.0383232799],PARAMETER["False_Northing",71015.30361772828],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9998167296682084],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA12H2",
    `PROJCS["OSGB36_National_Highways_A12H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",144034.6392342722],PARAMETER["False_Northing",71017.07906689808],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9998417260238136],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA12H3",
    `PROJCS["OSGB36_National_Highways_A12H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",144038.2403253212],PARAMETER["False_Northing",71018.85460484587],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9998667236293144],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA13H1",
    `PROJCS["OSGB36_National_Highways_A13H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",128033.7364878587],PARAMETER["False_Northing",71018.71320810913],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9998647329200487],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA13H2",
    `PROJCS["OSGB36_National_Highways_A13H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",128036.937451307],PARAMETER["False_Northing",71020.48874252186],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9998897304757802],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA14H1",
    `PROJCS["OSGB36_National_Highways_A14H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111034.697926722],PARAMETER["False_Northing",71022.19416934469],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999137409943842],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA14H2",
    `PROJCS["OSGB36_National_Highways_A14H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111037.4738982691],PARAMETER["False_Northing",71023.96979078473],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999387397753633],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA15H1",
    `PROJCS["OSGB36_National_Highways_A15H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88032.17537165637],PARAMETER["False_Northing",71025.95967485911],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999667551366491],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA15H2",
    `PROJCS["OSGB36_National_Highways_A15H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88034.37625857393],PARAMETER["False_Northing",71027.73539044033],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999917552430316],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA16H1",
    `PROJCS["OSGB36_National_Highways_A16H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54022.17583441971],PARAMETER["False_Northing",71029.1571156259],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000011771557166],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA16H2",
    `PROJCS["OSGB36_National_Highways_A16H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54023.52643946325],PARAMETER["False_Northing",71030.93291114613],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000036772789],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA17H1",
    `PROJCS["OSGB36_National_Highways_A17H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.11134761462],PARAMETER["False_Northing",71026.9544033599],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999807598109365],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA17H2",
    `PROJCS["OSGB36_National_Highways_A17H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.7115979077],PARAMETER["False_Northing",71028.73014381027],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000005760267449],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA18H1",
    `PROJCS["OSGB36_National_Highways_A18H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58018.94295715116],PARAMETER["False_Northing",71023.1887923747],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999277441837596],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA18H2",
    `PROJCS["OSGB36_National_Highways_A18H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58020.39348511989],PARAMETER["False_Northing",71024.96443868124],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999527433148317],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA19H1",
    `PROJCS["OSGB36_National_Highways_A19H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-88023.98625017522],PARAMETER["False_Northing",71019.352542755],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999873734042937],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA19H2",
    `PROJCS["OSGB36_National_Highways_A19H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-88026.18693235706],PARAMETER["False_Northing",71021.12809315171],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9998987318237049],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA20H1",
    `PROJCS["OSGB36_National_Highways_A20H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-105023.5775148396],PARAMETER["False_Northing",71015.94289098677],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9998257299268304],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA20H2",
    `PROJCS["OSGB36_National_Highways_A20H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-105026.2032027408],PARAMETER["False_Northing",71017.718356139],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9998507265074503],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA21H1",
    `PROJCS["OSGB36_National_Highways_A21H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-122020.6822661785],PARAMETER["False_Northing",71012.03640080881],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9997707308768334],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA21H2",
    `PROJCS["OSGB36_National_Highways_A21H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-122023.7328976339],PARAMETER["False_Northing",71013.81176829511],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9997957260824256],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA22H1",
    `PROJCS["OSGB36_National_Highways_A22H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-139014.8049133809],PARAMETER["False_Northing",71007.56222194275],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9997077393995895],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA22H2",
    `PROJCS["OSGB36_National_Highways_A22H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-139018.280413835],PARAMETER["False_Northing",71009.3374775704],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9997327330303357],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA23H1",
    `PROJCS["OSGB36_National_Highways_A23H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-156008.5023814753],PARAMETER["False_Northing",71003.86967362018],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996557524136872],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA23H2",
    `PROJCS["OSGB36_National_Highways_A23H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-156012.4027402983],PARAMETER["False_Northing",71005.64483693065],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.99968074474471],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA24H1",
    `PROJCS["OSGB36_National_Highways_A24H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-165001.8975238841],PARAMETER["False_Northing",71000.81651027738],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996127672593187],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA24H2",
    `PROJCS["OSGB36_National_Highways_A24H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-165006.0227260173],PARAMETER["False_Northing",71002.5915972559],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996377585156724],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA25H1",
    `PROJCS["OSGB36_National_Highways_A25H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-175993.5763422596],PARAMETER["False_Northing",70997.40863807063],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9995647881974643],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA25H2",
    `PROJCS["OSGB36_National_Highways_A25H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-175997.9763466683],PARAMETER["False_Northing",70999.18363984914],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9995897782542964],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA26H1",
    `PROJCS["OSGB36_National_Highways_A26H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-185984.2845534612],PARAMETER["False_Northing",70994.00109298788],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9995168137411531],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA26H2",
    `PROJCS["OSGB36_National_Highways_A26H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-185988.9343349418],PARAMETER["False_Northing",70995.77600957456],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9995418025985787],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA27H1",
    `PROJCS["OSGB36_National_Highways_A27H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-195973.6419052653],PARAMETER["False_Northing",70990.45191466244],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9994668452458347],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA27H2",
    `PROJCS["OSGB36_National_Highways_A27H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-195978.5414300451],PARAMETER["False_Northing",70992.22674251634],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9994918328540012],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA28H1",
    `PROJCS["OSGB36_National_Highways_A28H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-205961.7945968577],PARAMETER["False_Northing",70986.83211833447],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9994158825273456],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA28H2",
    `PROJCS["OSGB36_National_Highways_A28H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-205966.9438348191],PARAMETER["False_Northing",70988.6068556901],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9994408688613964],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA29H1",
    `PROJCS["OSGB36_National_Highways_A29H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-214949.3800873325],PARAMETER["False_Northing",70983.28365674701],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9993659241229034],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysA30H1",
    `PROJCS["OSGB36_National_Highways_A30H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-223935.6193491957],PARAMETER["False_Northing",70979.593633004],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9993139726802819],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB15H1",
    `PROJCS["OSGB36_National_Highways_B15H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88032.17537165637],PARAMETER["False_Northing",111040.5848437938],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999667551366491],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB15H2",
    `PROJCS["OSGB36_National_Highways_B15H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88034.37625857393],PARAMETER["False_Northing",111043.3609625194],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999917552430316],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB15H3",
    `PROJCS["OSGB36_National_Highways_B15H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88036.5772555427],PARAMETER["False_Northing",111046.1372200595],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000016756599497],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB16H1",
    `PROJCS["OSGB36_National_Highways_B16H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54022.17583441971],PARAMETER["False_Northing",111045.5836596405],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000011771557166],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB16H2",
    `PROJCS["OSGB36_National_Highways_B16H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54023.52643946325],PARAMETER["False_Northing",111048.3599033411],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000036772789],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB16H3",
    `PROJCS["OSGB36_National_Highways_B16H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54024.87711204128],PARAMETER["False_Northing",111051.1362858626],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000061775270976],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB16H4",
    `PROJCS["OSGB36_National_Highways_B16H4",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54026.22785215884],PARAMETER["False_Northing",111053.9128072154],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000086779003184],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB17H1",
    `PROJCS["OSGB36_National_Highways_B17H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.11134761462],PARAMETER["False_Northing",111042.1399827176],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999807598109365],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB17H2",
    `PROJCS["OSGB36_National_Highways_B17H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.7115979077],PARAMETER["False_Northing",111044.9161403231],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000005760267449],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB18H1",
    `PROJCS["OSGB36_National_Highways_B18H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58018.94295715116],PARAMETER["False_Northing",111036.2529007548],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999277441837596],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB18H2",
    `PROJCS["OSGB36_National_Highways_B18H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58020.39348511989],PARAMETER["False_Northing",111039.0289111777],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999527433148317],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB19H1",
    `PROJCS["OSGB36_National_Highways_B19H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-88023.98625017522],PARAMETER["False_Northing",111030.2553837437],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999873734042937],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB20H1",
    `PROJCS["OSGB36_National_Highways_B20H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-105023.5775148396],PARAMETER["False_Northing",111024.9248014019],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9998257299268304],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB21H1",
    `PROJCS["OSGB36_National_Highways_B21H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-122020.6822661785],PARAMETER["False_Northing",111018.817471687],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9997707308768334],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB22H1",
    `PROJCS["OSGB36_National_Highways_B22H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-139014.9439413935],PARAMETER["False_Northing",111011.9336510408],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9997087392023101],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB23H1",
    `PROJCS["OSGB36_National_Highways_B23H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-156008.5023814753],PARAMETER["False_Northing",111006.0497714344],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996557524136872],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB24H1",
    `PROJCS["OSGB36_National_Highways_B24H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-165002.0625257816],PARAMETER["False_Northing",111001.387517344],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996137668720859],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB25H1",
    `PROJCS["OSGB36_National_Highways_B25H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-175993.5763422596],PARAMETER["False_Northing",110995.9487158569],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9995647881974643],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB26H1",
    `PROJCS["OSGB36_National_Highways_B26H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-185984.2845534612],PARAMETER["False_Northing",110990.6214270655],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9995168137411531],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB27H1",
    `PROJCS["OSGB36_National_Highways_B27H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-195973.6419052653],PARAMETER["False_Northing",110985.0727116554],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9994668452458347],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB28H1",
    `PROJCS["OSGB36_National_Highways_B28H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-205961.7945968577],PARAMETER["False_Northing",110979.4135934525],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9994158825273456],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB29H1",
    `PROJCS["OSGB36_National_Highways_B29H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-214949.5949836332],PARAMETER["False_Northing",110973.9769450385],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999366923242045],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB30H1",
    `PROJCS["OSGB36_National_Highways_B30H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-223935.8432178783],PARAMETER["False_Northing",110968.2080231451],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9993149716955489],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB31H1",
    `PROJCS["OSGB36_National_Highways_B31H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-232920.6915322958],PARAMETER["False_Northing",110962.2178544413],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9992610277292974],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysB32H1",
    `PROJCS["OSGB36_National_Highways_B32H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-241904.3280719596],PARAMETER["False_Northing",110956.117421436],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9992060907870448],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC13H1",
    `PROJCS["OSGB36_National_Highways_C13H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",128033.8645538678],PARAMETER["False_Northing",126033.3354202136],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9998657330368111],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC14H1",
    `PROJCS["OSGB36_National_Highways_C14H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111034.697926722],PARAMETER["False_Northing",126039.3868357385],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999137409943842],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC14H2",
    `PROJCS["OSGB36_National_Highways_C14H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111037.4738982691],PARAMETER["False_Northing",126042.5379385757],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999387397753633],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC14H3",
    `PROJCS["OSGB36_National_Highways_C14H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111040.2500086234],PARAMETER["False_Northing",126045.689198978],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999637398063597],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC14H4",
    `PROJCS["OSGB36_National_Highways_C14H4",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",111043.0262577955],PARAMETER["False_Northing",126048.840616957],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999887410874669],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC15H1",
    `PROJCS["OSGB36_National_Highways_C15H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88032.17537165637],PARAMETER["False_Northing",126046.0692821443],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999667551366491],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC15H2",
    `PROJCS["OSGB36_National_Highways_C15H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88034.37625857393],PARAMETER["False_Northing",126049.220552049],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999917552430316],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC15H3",
    `PROJCS["OSGB36_National_Highways_C15H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88036.5772555427],PARAMETER["False_Northing",126052.371979527],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000016756599497],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC15H4",
    `PROJCS["OSGB36_National_Highways_C15H4",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88038.77836257096],PARAMETER["False_Northing",126055.5235645902],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000041759206141],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC15H5",
    `PROJCS["OSGB36_National_Highways_C15H5",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",88040.97957966694],PARAMETER["False_Northing",126058.6753072504],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000066763063055],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC16H1",
    `PROJCS["OSGB36_National_Highways_C16H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54022.17583441971],PARAMETER["False_Northing",126051.743613646],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000011771557166],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC16H2",
    `PROJCS["OSGB36_National_Highways_C16H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54023.52643946325],PARAMETER["False_Northing",126054.8950254143],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000036772789],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC16H3",
    `PROJCS["OSGB36_National_Highways_C16H3",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54024.87711204128],PARAMETER["False_Northing",126058.046594763],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000061775270976],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC16H4",
    `PROJCS["OSGB36_National_Highways_C16H4",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",54026.22785215884],PARAMETER["False_Northing",126061.198321704],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000086779003184],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC17H1",
    `PROJCS["OSGB36_National_Highways_C17H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.11134761462],PARAMETER["False_Northing",126047.8345749767],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999807598109365],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC17H2",
    `PROJCS["OSGB36_National_Highways_C17H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-24009.7115979077],PARAMETER["False_Northing",126050.9858890154],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",1.000005760267449],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC18H1",
    `PROJCS["OSGB36_National_Highways_C18H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58018.94295715116],PARAMETER["False_Northing",126041.1519413973],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999277441837596],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC18H2",
    `PROJCS["OSGB36_National_Highways_C18H2",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-58020.39348511989],PARAMETER["False_Northing",126044.3030883639],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9999527433148317],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
  [
    "OSGB36NationalHighwaysC19H1",
    `PROJCS["OSGB36_National_Highways_C19H1",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",-88023.98625017522],PARAMETER["False_Northing",126034.3439491145],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.999873734042937],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]`
  ],
]
