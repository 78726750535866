import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardBody, CardActions } from "@progress/kendo-react-layout";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { AppNotification, NotificationMessage } from "../../shared/components/Notifications";
import { AppLoader } from "../../shared/components/Indicators";
import { FormInput } from "../../shared/components/Inputs";
import { passwordValidator, requiredValidator } from "../../shared/validators";
import accountService from "../services/accountService";

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  async function handleSubmit(model: any) {
    setIsLoading(true);

    try {
      model.userName = searchParams.get("userName");
      model.token = searchParams.get("token");
      await accountService.resetPassword(model);
      setNotificationMessage(NotificationMessage.success("Your password has been successfully changed!"));

      navigate("../login");
    } catch (error: any) {
      setNotificationMessage(NotificationMessage.error(error));
    }

    setIsLoading(false);
  }

  if (isLoading) {
    return (<AppLoader />);
  }

  return (
    <section className="container my-5">
      <Card className="col-md-6 offset-md-3">
        <CardHeader>
          <CardTitle>Reset Password</CardTitle>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={handleSubmit}
            render={(props) => (
              <FormElement id={"form"}>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                    <Field name={"newPassword"} type={"Password"} component={FormInput} label={"New Password"}
                      validator={[requiredValidator, passwordValidator]} />
                  </div>
                </FieldWrapper>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                    <Field name={"confirmPassword"} type={"Password"} component={FormInput} label={"Confirm New Password"}
                      validator={[requiredValidator, passwordValidator]} />
                  </div>
                </FieldWrapper>
              </FormElement>
            )}
          />
        </CardBody>
        <CardActions className="justify-content-center">
          <Button themeColor={"primary"} type={"submit"} form={"form"}>Reset Password</Button>
        </CardActions>
      </Card>

      <AppNotification notificationMessage={notificationMessage}></AppNotification>
    </section>
  );
}
