import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardBody, CardActions } from "@progress/kendo-react-layout";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { AppNotification, NotificationMessage } from "../../shared/components/Notifications";
import { AppLoader } from "../../shared/components/Indicators";
import { LinkButton } from "../../shared/components/Buttons";
import { FormInput } from "../../shared/components/Inputs";
import { emailValidator, passwordValidator, requiredValidator } from "../../shared/validators";
import { LoginModel, RegisterModel } from "../models/AccountModel";
import accountService from "../services/accountService";
import accountContextService from "../services/accountContextService";
import { claimProjects } from "../../projects/common/api/claimProjectsApi";

export default function Register() {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>();
  const navigate = useNavigate();

  async function handleSubmit(model: RegisterModel) {
    setIsLoading(true);

    try {
      model.email = model.userName;
      await accountService.register(model);
      setNotificationMessage(NotificationMessage.success("Congratulations! You have beed successfully registered!"));

      const loginModel: LoginModel = {
        userName: model.userName,
        password: model.password
      };
      const loginResult = await accountService.login(loginModel);
      accountContextService.finishLogin(loginResult);

      const guestCode = accountContextService.guestCode;
      if (guestCode) {
        await claimProjects(guestCode);
      }

      navigate("/");
    }
    catch (error: any) {
      setNotificationMessage(NotificationMessage.error(error));
    }

    setIsLoading(false);
  }

  if (isLoading) {
    return (<AppLoader />);
  }

  return (
    <section className="container my-5">
      <Card className="col-md-6 offset-md-3">
        <CardHeader>
          <CardTitle>Sign Up</CardTitle>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={data => handleSubmit(data as RegisterModel)}
            render={(props) => (
              <FormElement id={"form"}>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                    <Field name={"firstName"} type={"Text"} component={FormInput} label={"First Name"}
                      validator={[requiredValidator]} />
                  </div>
                </FieldWrapper>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                    <Field name={"lastName"} type={"Text"} component={FormInput} label={"Last Name"}
                      validator={[requiredValidator]} />
                  </div>
                </FieldWrapper>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                    <Field name={"userName"} type={"Email"} component={FormInput} label={"Email"}
                      validator={[requiredValidator, emailValidator]} />
                  </div>
                </FieldWrapper>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                    <Field name={"password"} type={"Password"} component={FormInput} label={"Password"}
                      validator={[requiredValidator, passwordValidator]} />
                  </div>
                </FieldWrapper>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                    <Field name={"confirmPassword"} type={"Password"} component={FormInput} label={"Confirm Password"}
                      validator={[requiredValidator, passwordValidator]} />
                  </div>
                </FieldWrapper>
              </FormElement>
            )}
          />

          <div className="mt-3 d-flex justify-content-end">
            <Link to="../forgot-password">Forgot Password?</Link>
          </div>
        </CardBody>
        <CardActions className="justify-content-center">
          <Button themeColor={"primary"} type={"submit"} form={"form"}>Sign Up</Button>
          <LinkButton to='../login'>Log In</LinkButton>
        </CardActions>
      </Card>

      <AppNotification notificationMessage={notificationMessage}></AppNotification>
    </section>
  );
}
