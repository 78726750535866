import { Fade } from "@progress/kendo-react-animation";
import { NotificationGroup, Notification } from "@progress/kendo-react-notification";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

class ApiError {
  static DEFAULT_ERROR_MESSAGE = 'Oops, something went wrong';
  static ACCESS_DENIED_ERROR_MESSAGE = 'Access denied';

  message!: string;
  code?: number;

  constructor(message: string, code?: number) {
    this.message = message;
    this.code = code;
  }

  static create(error: any): ApiError {
    if (!error) {
      return new ApiError(this.DEFAULT_ERROR_MESSAGE);
    }

    if (typeof error === "string") {
      return new ApiError(error);
    }

    const axiosError = error as AxiosError;

    if (axiosError.response) {
      if (axiosError.response.data) {
        if (typeof axiosError.response.data === "string") {
          return new ApiError(axiosError.response.data);
        }

        return axiosError.response.data as ApiError;
      }

      if (axiosError.response.status === 403) {
        return new ApiError(this.ACCESS_DENIED_ERROR_MESSAGE);
      }
    }

    if (axiosError.message) {
      return new ApiError(axiosError.message);
    }

    return new ApiError(this.DEFAULT_ERROR_MESSAGE);
  }
}

export class NotificationMessage {
  style?: "success" | "error"
  message?: string;

  static success(message: string): NotificationMessage {
    return { style: "success", message: message };
  }

  static error(error: any): NotificationMessage {
    const apiError = ApiError.create(error);
    return { style: "error", message: apiError.message };
  }
}

export function AppNotification({ notificationMessage }: any) {
  const [message, setMessage] = useState<NotificationMessage | undefined>(notificationMessage);

  useEffect(() => {
    const timeoutId = setTimeout(() => setMessage(undefined), 5000);
    return () => clearTimeout(timeoutId);
  });

  return (
    <NotificationGroup style={{ top: 48, right: 24 }}>
      <Fade>
        {message && (
          <Notification type={{ style: message.style }}>
            <span>{message.message}</span>
          </Notification>
        )}
      </Fade>
    </NotificationGroup>
  );
}
