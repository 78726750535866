import { Project } from "./ProjectModels";

export enum EntityType {
  baseMap = 'BaseMap',
  treeZones = 'Tree Zones',
  groundZones = 'Ground Zones',
  contourZones = 'Contour Zones',
  roadways = 'Roadways',
  barriers = 'Barriers',
  buildingRows = 'Bulding Rows',
  terrainLines = 'Terrain Lines',
  receivers = 'Receivers'
}

export class LegendItemSettings {
  type!: EntityType;
  isShown!: boolean;
  opacity!: number;
  disableSetting!: boolean;

  public static getLegendItemsSettings(project: Project): LegendItemSettings[] {
    const settings: LegendItemSettings[] = [];

    settings.push({
      type: EntityType.baseMap,
      opacity: 100,
      isShown: true,
      disableSetting: false
    });

    settings.push({
      type: EntityType.treeZones,
      opacity: 100,
      isShown: true,
      disableSetting: project.treeZones.length === 0
    });

    settings.push({
      type: EntityType.groundZones,
      opacity: 100,
      isShown: true,
      disableSetting: project.groundZones.length === 0
    });

    settings.push({
      type: EntityType.contourZones,
      opacity: 100,
      isShown: true,
      disableSetting: project.contourZones.length === 0
    });

    settings.push({
      type: EntityType.roadways,
      opacity: 100,
      isShown: true,
      disableSetting: project.roadways.length === 0
    });

    settings.push({
      type: EntityType.barriers,
      opacity: 100,
      isShown: true,
      disableSetting: project.barriers.length === 0
    });

    settings.push({
      type: EntityType.buildingRows,
      opacity: 100,
      isShown: true,
      disableSetting: project.buildingRows.length === 0
    });

    settings.push({
      type: EntityType.terrainLines,
      opacity: 100,
      isShown: true,
      disableSetting: project.terrainLines.length === 0
    });
    
    settings.push({
      type: EntityType.receivers,
      opacity: 100,
      isShown: true,
      disableSetting: project.receivers.length === 0
    });

    return settings;
  }
}
