import { FC } from 'react';
import { Slider } from '@progress/kendo-react-inputs';
import { SvgIcon } from '@progress/kendo-react-common';
import { eyeIcon, eyeSlashIcon } from '../../../shared/components/Icons';
import { LegendItemSettings } from '../models/LegendModels';
import LegendIcon from './LegendIcon';

const ShowItemIcon = (props: { show: boolean, disabled: boolean }) => {
  const icon = props.disabled || !props.show ? eyeIcon : eyeSlashIcon;
  return (
    <SvgIcon icon={icon} width={24} height={24} className={props.disabled ? "text-disabled" : ""} />
  );
}

interface LegendItemProps {
  setting: LegendItemSettings;
  handleShowClick: (setting: LegendItemSettings) => void;
  handleOpacityChange: (setting: LegendItemSettings, value: number) => void;
}

const LegendItem: FC<LegendItemProps> = ({ setting, handleShowClick, handleOpacityChange }) => {
  const isDisabled = setting.disableSetting || !setting.isShown;

  return (
    <div>
      <div className='row'>
        <div className='col-3'>
          <LegendIcon entityType={setting.type} disabled={isDisabled} />
        </div>

        <div className='col-6 d-flex justify-content-center align-items-center'>
          <p className={isDisabled ? "text-disabled" : ""}>{setting.type}</p>
        </div>

        <div
          className='col-3 d-flex justify-content-center align-items-center'
          style={{ cursor: setting.disableSetting ? 'default' : 'pointer' }}
          onClick={() => !setting.disableSetting && handleShowClick(setting)}
        >
          <ShowItemIcon show={setting.isShown} disabled={setting.disableSetting} />
        </div>
      </div>

      <Slider
        className='w-100'
        disabled={isDisabled}
        step={1}
        defaultValue={100}
        min={0}
        max={100}
        onChange={(e) => handleOpacityChange(setting, e.value)}
      />
    </div>
  )
}

export default LegendItem;