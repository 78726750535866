import { useEffect } from 'react';
import { AppBar, AppBarSection, AppBarSpacer, Menu, MenuItem } from "@progress/kendo-react-layout"
import { useNavigate } from 'react-router-dom';
import { LinkButton } from './Buttons';
import avatarIcon from '../../assets/images/avatar-icon.svg';
import accountContextService from '../../account/services/accountContextService';
import { ProjectsAppBar } from '../../projects/common/components/ProjectsAppBar';
import { useAppSelector } from '../hooks/commonHooks';
import { useCurrentPath } from '../../projects/common/hooks/useCurrentPath';

const viewProjectRoute = [{ path: '/projects/view/:id?' }];

export default function Header(props: any) {
  const navigate = useNavigate();
  const { project } = useAppSelector(state => state.view);
  const isViewProjectRoute = useCurrentPath(viewProjectRoute);

  let account = accountContextService.account;

  useEffect(() => {
    account = accountContextService.account;
  });

  function handleMenuSelect(event: any) {
    if (event.item.data === "logout") {
      accountContextService.logout();
      navigate("/");
    }
  }

  function handleLogoClick() {
    navigate("/");
  }

  function renderAvatar() {
    return (
      <img src={avatarIcon} />
    );
  }

  return (
    <header>
      <AppBar className="primary-app-bar">
        <AppBarSection>
          <span className='logo' onClick={handleLogoClick}>
            TNM Viewer
          </span>
        </AppBarSection>

        <AppBarSpacer />

        {isViewProjectRoute && project && <>
          <AppBarSection>
            <span className="text-20">{project.runTitle}</span>
          </AppBarSection>
          <AppBarSpacer />
        </>}

        {!account && (
          <AppBarSection>
            <LinkButton to="/account/login" themeColor={'primary'} fillMode="outline">Log in</LinkButton>
            <LinkButton to="/account/register" themeColor={'primary'} className='ms-2'>Sign up</LinkButton>
          </AppBarSection>
        )}

        {account && (
          <AppBarSection>
            <Menu openOnClick={true} onSelect={handleMenuSelect}>
              <MenuItem render={renderAvatar}>
                <MenuItem text="Logout" data={"logout"}>Log Out</MenuItem>
              </MenuItem>
            </Menu>
          </AppBarSection>
        )}
      </AppBar>

      <ProjectsAppBar />
    </header>
  );
}
